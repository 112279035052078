import React, { useState, useEffect } from "react";
import "./style.scss";
import { useChatSocket } from "./socket";
import ChatMessageWindow from "./ChatMessageWindow";
import { httpClientCron } from "../../api/module/httpClientCron";
import {
  CustomModal,
} from "components";
import IconLogo from "../../images/logo-sidebar.svg";
import { useChatMessageStore } from "./store";

// todo refactor to only trigger queries when chatbox is opened

export default function ChatBox({ userProfile, setConversation = () => {}, lastMessageSaw = () => {}, removeMessageSaw = () => {}}) {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(-1)
  const { chatSocket } = useChatSocket({ userProfile, lastMessageSaw });
  const chatMessageStore = useChatMessageStore()

  const activeMessage = async (data) => {
    setOpen(true)
    setId(data?.id)
    await httpClientCron.patch(`private/chat-message-blog/${data?.chatMessages?.[0]?.id}/seen`);
    removeMessageSaw(data?.id)
    chatMessageStore.setMessageWindows({
      img: IconLogo,
      name: "Hacking HR Team",
      id: process.env.REACT_APP_DOMAIN_URL === 'http://localhost:3001' ? 320 : 205,
      blogId: data?.id,
      UserId: data?.UserId,
      titleProfessions: '',
      isTyping: false,
    });
  }

  useEffect(() => {
    setConversation({active: activeMessage})
  },[])

  return (
    <CustomModal
      visible={open}
      title={null}
      onCancel={() => {
        chatMessageStore.removeMessageWindow(id);
        setId(-1)
        setOpen(false);
      }}
      className="modal-chat"
      width={750}
    >
      {chatMessageStore.messageWindows.map((messageWindow, index) => {
        return (
          <ChatMessageWindow
            key={`messageWindow-${messageWindow.id}`}
            index={index}
            userProfile={userProfile}
            chatSocket={chatSocket}
            {...messageWindow}
          />
        )
      })}
    </CustomModal>
  );
}