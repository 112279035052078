import { put, fork, takeLatest, call } from "redux-saga/effects";
import { notification } from "antd";

import {
    constants as filePostConstants,
    actions as filePostsActions
} from "../actions/files-post-actions";
import {
    createFilePostEndPoint,
    getAllFilePostEndPoint,
    editFilePostEndPoint,
    removeFilePostEndPoint,
    downloadFilePostEndPoint
} from "../../api";
import {
    actions as homeActions,
} from "../actions/home-actions";

export function* createFilePostSaga({ payload }) {
    const { body } = payload

    yield put(homeActions.setLoading(true));

    try {
        const response = yield call(createFilePostEndPoint, { ...body });

        if (response.status === 200) {
            const { filePost } = response?.data;

            yield put(
                filePostsActions.setNewFilePostToArray(filePost)
            );

            if (payload.callback) {
                payload.callback();
            }

            // notification.success({
            //     message: "Create file post succesfully!!",
            // });
        }
    } catch (error) {
        console.log(error)
        notification.error({
            message: "ERROR:",
            description: error?.response?.data?.msg,
        });
    } finally {
        yield put(homeActions.setLoading(false));
    }
}

export function* getAllFilePostSagas({ payload }) {

    yield put(homeActions.setLoading(true));

    try {
        const response = yield call(getAllFilePostEndPoint);

        if (response.status === 200) {

            yield put(
                filePostsActions.setAllFilePost(response.data)
            );
            yield put(homeActions.setLoading(false));
        }
    } catch (error) {
        console.log(error)
        notification.error({
            message: "ERROR:",
            description: error?.response?.data?.msg,
        });
    } finally {
        yield put(homeActions.setLoading(false));
    }
}

export function* editFilePostSagas({ payload }) {
    const { body } = payload

    yield put(homeActions.setLoading(true));

    try {
        const response = yield call(editFilePostEndPoint, { ...body });

        if (response.status === 200) {

            if (payload.callback) {
                payload.callback();
            }

            // notification.success({
            //     message: "Edit file post succesfully!!",
            // });
        }
    } catch (error) {
        console.log(error)
        notification.error({
            message: "ERROR:",
            description: error?.response?.data?.msg,
        });
    } finally {
        yield put(homeActions.setLoading(false));
    }
}

export function* removeFilePostSagas({ payload }) {
    const { id } = payload

    yield put(homeActions.setLoading(true));

    try {
        const response = yield call(removeFilePostEndPoint, { id });

        if (response.status === 200) {

            if (payload.callback) {
                payload.callback();
            }

            // notification.success({
            //     message: "Remove file post succesfully!!",
            // });
        }
    } catch (error) {
        console.log(error)
        notification.error({
            message: "ERROR:",
            description: error?.response?.data?.msg,
        });
    } finally {
        yield put(homeActions.setLoading(false));
    }
}

const getFileBuffer = (base64, mimetype) => {
    const base64str = base64.replace(`data:${mimetype};base64,`, "");

    return Buffer.from(base64str, "base64");
};

export function* downloadFilePostSagas({ payload }) {
    const { id, name } = payload

    yield put(homeActions.setLoading(true));

    try {
        const response = yield call(downloadFilePostEndPoint, { id });

        if (response.status === 200) {

            const newBuffer = getFileBuffer(response.data, 'application/pdf')

            var fileURL = window.URL.createObjectURL(new Blob([newBuffer], { type: 'application/pdf' }));
            var fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', `${name}.pdf`);
            document.body.appendChild(fileLink);

            fileLink.click();
            document.body.removeChild(fileLink);
            window.URL.revokeObjectURL(fileURL);

            yield put(homeActions.setLoading(false));

            if (payload.callback) {
                payload.callback();
            }

            notification.success({
                message: "Download file post succesfully!!",
            });
        }
    } catch (error) {
        console.log(error)
        notification.error({
            message: "ERROR:",
            description: error?.response?.data?.msg,
        });
    } finally {
        yield put(homeActions.setLoading(false));
    }
}

function* watchLogin() {
    yield takeLatest(filePostConstants.CREATE_FILE_POST, createFilePostSaga);
    yield takeLatest(filePostConstants.EDIT_FILE_POST, editFilePostSagas);
    yield takeLatest(filePostConstants.REMOVE_FILE_POST, removeFilePostSagas);
    yield takeLatest(filePostConstants.GET_ALL_FILE_POST, getAllFilePostSagas);
    yield takeLatest(filePostConstants.DOWNLOAD_FILE_POST, downloadFilePostSagas);
}

export const filePostSaga = [fork(watchLogin)];