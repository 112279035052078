import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import {
  ModalCompleteYourProfile,
  CustomButton,
  CustomInput,
} from "components";
import NoItemsMessageCard from "components/NoItemsMessageCard";
import IconMentoring from "images/icon-mentoring-new.svg";
import { MinusCircleOutlined } from "@ant-design/icons";

import { Avatar, Form, Modal } from "antd";

import "./style.scss";

const FindMentees = ({
  mentorProfile,
  menteeProfile,
  userProfile,
  getUser,
  getOneMentor,
  getOneMentee,
  // mentorListFind,
  createRequest,
  menteeListFind,
}) => {
  const [bulModal, setBulModal] = useState(false);
  const [userModal, setUserModal] = useState(userProfile || {});
  const [mentorData, setMentorData] = useState({
    textDescriptionMentor: mentorProfile?.textDescription,
    textDescriptionMentee: menteeProfile?.textDescription,
  });
  const [openModalConnect, setOpenModalConnect] = useState(false);
  const [dataSelected, setDataSelected] = useState({});
  const [initialValuesForm, setInitialValuesForm] = useState({
    aditionalGoals: [],
    mainGoals: [],
  });

  useEffect(() => {
    setInitialValuesForm({
      mainGoals: userProfile?.mentorGoals?.mainGoals || [],
      aditionalGoals: userProfile?.mentorGoals?.aditionalGoals || [],
    });
  }, [userProfile]);

  useEffect(() => {
    setMentorData({
      ...mentorData,
      textDescriptionMentor: mentorProfile?.textDescription,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentorProfile]);

  useEffect(() => {
    setMentorData({
      ...mentorData,
      textDescriptionMentee: menteeProfile?.textDescription,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menteeProfile]);

  useEffect(() => {
    setUserModal(userProfile);
  }, [userProfile]);

  const onCloseModal = () => {
    setBulModal(false);
    setUserModal(userProfile);
    setMentorData({
      textDescriptionMentor: mentorProfile?.textDescription,
      textDescriptionMentee: menteeProfile?.textDescription,
    });
  };

  const filterPorcentage = (user) => {
    let coincidence = mentorProfile?.topics?.filter((e) =>
      user.some((a) => a === e)
    );

    return (coincidence?.length * 100) / mentorProfile?.topics?.length;
  };

  const orderList = (users) => {
    return users.sort((a, b) => {
      let aCoincidence = filterPorcentage(a?.topics);
      let bCoincidence = filterPorcentage(b?.topics);

      return Number(bCoincidence) - Number(aCoincidence);
    });
  };

  const filterSituation = (data) => {
    return data?.filter((e) => {
      return (
        (e.request.length === 0 || e.request[0]?.status === "PENDING") &&
        e.idUser !== mentorProfile.idUser
      );
    });
  };

  const newType = (data) => {
    if (data === "PENDING") {
      return "primary-retart";
    }
    if (data === "REJECT") {
      return "third-retart";
    }
    if (data === "ACCEPTED") {
      return "secondary-retart";
    }
  };

  const onSendConecctionToMentee = (data, goals) => {
    if (data.request.length === 0) {
      createRequest(
        {
          idOwner: mentorProfile.idUser,
          idMentor: mentorProfile.idUser,
          idEstudiante: data.id,
          status: "PENDING",
          goals: goals,
        },
        () => {
          getOneMentor();
          getOneMentee();
          setOpenModalConnect(false);
        }
      );
    }
  };

  return (
    <div className="container-findMentee">
      <h4>
        Available Connections:{" "}
        <strong>{mentorProfile?.connectionsLimitMentoring} / 3</strong>
      </h4>
      {/* <div
        // className="container-buttom"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CustomButton
          key="PREVIEW PROFILE"
          text="PREVIEW PROFILE"
          type="primary"
          size="xl"
          className="button-modal"
          style={{ padding: "0px 10px" }}
          onClick={() => {
            setBulModal(true);
          }}
        />
      </div> */}
      <div className="container-start">
        <div className="container-text">
          <h1>Recommended mentees</h1>
          {/* <h3 style={{ paddingTop: "10px" }}>
            You can connect with up to three mentees per month
          </h3> */}
        </div>
        <div className="container-match-buttom">
          <div>% MATCH</div>
        </div>
      </div>
      <div className="container-list-mentoring">
        {filterSituation(orderList(menteeListFind))?.length !== 0 ? (
          filterSituation(orderList(menteeListFind))?.map((data, index) => {
            return (
              <div key={index} className="container-card-user-mentoring">
                <div className="first-part">
                  <div style={{ width: "100px", height: "100px" }}>
                    {data?.img ? (
                      <Avatar size={100} src={data?.img} />
                    ) : (
                      <Avatar size={100} style={{ fontSize: "1rem" }}>
                        {data?.abbrName}
                      </Avatar>
                    )}
                  </div>
                  <div className="container-name">
                    <p style={{ fontSize: "18px" }}>{data.firstName}</p>
                    <p style={{ fontSize: "18px" }}>{data.titleProfessions}</p>
                  </div>
                  <div className="container-buttons">
                    <CustomButton
                      key="VIEW PROFILE"
                      text="VIEW PROFILE"
                      type="primary"
                      size="xl"
                      className="button-modal"
                      style={{
                        padding: "0px 10px",
                        marginLeft: "20px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                      onClick={() => {
                        setUserModal(data);
                        setMentorData({
                          textDescriptionMentor: "",
                          textDescriptionMentee: data?.textDescription,
                        });
                        setBulModal(true);
                      }}
                    />
                    {data.request.length === 0 ? (
                      <CustomButton
                        key="CONNECT"
                        text="CONNECT"
                        type={
                          data.request.length === 0
                            ? "primary"
                            : "primary-retart"
                        }
                        size="xl"
                        className="button-modal"
                        style={{
                          padding: "0px 10px",
                          marginLeft: "20px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                        onClick={() => {
                          if (data.request.length === 0) {
                            setDataSelected(data);
                            setOpenModalConnect(true);
                          }
                        }}
                        // onClick={() => {
                        //   if (data.request.length === 0) {
                        //     createRequest(
                        //       {
                        //         idOwner: mentorProfile.idUser,
                        //         idMentor: mentorProfile.idUser,
                        //         idEstudiante: data.id,
                        //         status: "PENDING",
                        //       },
                        //       () => {
                        //         getOneMentor();
                        //         getOneMentee();
                        //       }
                        //     );
                        //   }
                        // }}
                      />
                    ) : (
                      <CustomButton
                        text={data.request[0].status.toUpperCase()}
                        type={newType(data.request[0].status)}
                        size="xl"
                        className="button-modal"
                        style={{ padding: "0px 30px", marginLeft: "20px" }}
                      />
                    )}
                  </div>
                </div>
                <div className="second-part">
                  <div
                    style={{
                      marginLeft: "30px",
                      height: "20px",
                      padding: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: "bold",
                      borderRadius: "20px",
                      background:
                        filterPorcentage(data?.topics) === 100
                          ? "rgb(0, 181, 116)"
                          : filterPorcentage(data?.topics) < 100 &&
                            filterPorcentage(data?.topics) > 5
                          ? "yellow"
                          : "#e61e47",
                    }}
                  >
                    {Math.round(filterPorcentage(data?.topics))} %
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div style={{ marginTop: "25px" }}>
            <NoItemsMessageCard
              icon={IconMentoring}
              message={`We have not found mentees that match your interests.`}
            />
          </div>
        )}
        <Modal
          width={500}
          visible={openModalConnect}
          onCancel={() => setOpenModalConnect(false)}
          footer={<></>}
        >
          <h4 style={{ textAlign: "center" }}>
            Your Mentor Goals with This Mentee
          </h4>
          <p>
            Please include at least three goals that you expect to accomplish
            during and after your mentoring relationship with this mentee
          </p>
          <Form
            onFinish={(goals) => onSendConecctionToMentee(dataSelected, goals)}
            initialValues={{
              mainGoals: initialValuesForm.mainGoals,
              aditionalGoals: initialValuesForm.aditionalGoals,
            }}
            style={{ padding: "10px" }}
          >
            <Form.List name="mainGoals">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.length === 3 ? (
                    fields.map((field, index) => (
                      <Form.Item
                        label={`Goal ${index + 1}`}
                        key={field.key}
                        required={true}
                        style={{ marginBottom: "0px" }}
                      >
                        <Form.Item
                          {...field}
                          rules={[
                            {
                              required: true,
                              message: "This Goal is required!",
                            },
                          ]}
                        >
                          <CustomInput autoComplete="off" size="sm" />
                        </Form.Item>
                      </Form.Item>
                    ))
                  ) : (
                    <>
                      <Form.Item
                        label={`Goal 1`}
                        key={0}
                        style={{ marginBottom: "0px" }}
                        required={true}
                      >
                        <Form.Item
                          name={0}
                          key={0}
                          isListField={true}
                          fieldKey={0}
                          rules={[
                            {
                              required: true,
                              message: "This Goal is required!",
                            },
                          ]}
                        >
                          <CustomInput autoComplete="off" size="sm" />
                        </Form.Item>
                      </Form.Item>
                      <Form.Item
                        label={`Goal 2`}
                        key={1}
                        style={{ marginBottom: "0px" }}
                        required={true}
                      >
                        <Form.Item
                          name={1}
                          key={1}
                          isListField={true}
                          fieldKey={1}
                          rules={[
                            {
                              required: true,
                              message: "This Goal is required!",
                            },
                          ]}
                        >
                          <CustomInput autoComplete="off" size="sm" />
                        </Form.Item>
                      </Form.Item>
                      <Form.Item
                        label={`Goal 3`}
                        key={2}
                        style={{ marginBottom: "0px" }}
                        required={true}
                      >
                        <Form.Item
                          name={2}
                          key={2}
                          isListField={true}
                          fieldKey={2}
                          rules={[
                            {
                              required: true,
                              message: "This Goal is required!",
                            },
                          ]}
                        >
                          <CustomInput autoComplete="off" size="sm" />
                        </Form.Item>
                      </Form.Item>
                    </>
                  )}
                </>
              )}
            </Form.List>
            <Form.List name="aditionalGoals">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item
                      label={`Goal ${index + 4}`}
                      key={field.key}
                      style={{ marginBottom: "0px" }}
                      required={true}
                    >
                      <div style={{ display: "flex", gap: "5px" }}>
                        <Form.Item
                          {...field}
                          rules={[
                            {
                              required: true,
                              message: "This Goal is required!",
                            },
                          ]}
                          style={{ width: "100%" }}
                        >
                          <CustomInput autoComplete="off" size="sm" />
                        </Form.Item>
                        {fields.length > 0 ? (
                          <div>
                            <MinusCircleOutlined
                              className="dynamic-delete-button"
                              onClick={() => remove(field.name)}
                              style={{
                                fontSize: "26px",
                                marginTop: "12px",
                                padding: "0 2px",
                                opacity: "0.7",
                              }}
                              title="Remove this Goal"
                            />
                          </div>
                        ) : null}
                      </div>
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <CustomButton
                      text="Add More Goals"
                      key="Add More Goals"
                      type="primary outlined"
                      size="sm"
                      onClick={() => add()}
                    />
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>

            <div style={{ width: "100%", textAlign: "center" }}>
              <CustomButton
                style={{ marginTop: "35px" }}
                text=" SEND CONNECTION REQUEST"
                key="SEND CONNECTION REQUEST"
                type="primary"
                size="sm"
                htmlType="submit"
              />
            </div>
          </Form>
        </Modal>
      </div>
      {/* <div className="container-buttom">
                <CustomButton
                    key="PREVIEW PROFILE"
                    text="PREVIEW PROFILE"
                    type="primary"
                    size="xl"
                    className="button-modal"
                    style={{ padding: "0px 10px" }}
                    onClick={() => { setBulModal(true) }}
                />
            </div> */}
      {bulModal === true && (
        <div className="complete-profile" onClick={() => setBulModal(false)}>
          <ModalCompleteYourProfile
            userProfile={userModal}
            get={getUser}
            edit={false}
            caseMentoring={true}
            mentee={mentorData?.textDescriptionMentee}
            mentor={mentorData?.textDescriptionMentor}
            onClose={() => onCloseModal()}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FindMentees);
