import React, { useState, useRef, useEffect } from "react";
import { Comment, Avatar, Input, Button, Form, Skeleton } from "antd";
import { useCreateCertificateComment } from "./hooks/useCreateCertificateComment";
import { useQueryClient } from "@tanstack/react-query";
import "./style.scss";
import SocketIO from "services/socket";
import { SOCKET_EVENT_TYPE } from "enum";
import DiscussionBoardCommentWrapper from "./DiscussionBoardCommentWrapper";
import httpClient from "../../api/module/httpClient";
import { useQuery } from "@tanstack/react-query";
import { useCreateGroupCertificateComment } from "./hooks/useCreateCertificateGroupComment";
import { CERTIFICATE, CERTIFICATES } from "enum/QueryKeys";

const { TextArea } = Input;

const USE_GET_CERTIFICATE_COMMENTS = "USE_GET_CERTIFICATE_COMMENTS";
const USE_GET_CERTIFICATE_GROUP_COMMENTS = "USE_GET_CERTIFICATE_GROUP_COMMENTS";

const CommentSection = ({
  slug,
  userProfile,
  certificateUser,
  certificateClassId,
  certificateId, // can be null
  isGroupDiscussionBoard = false,
  hasNotification = false,
}) => {
  const queryClient = useQueryClient();

  const queryKey = isGroupDiscussionBoard
    ? [USE_GET_CERTIFICATE_GROUP_COMMENTS, certificateId]
    : [USE_GET_CERTIFICATE_COMMENTS, certificateClassId];

  const { data: comments, isLoading } = useQuery({
    queryKey: queryKey,
    queryFn: async () => {
      if (isGroupDiscussionBoard) {
        const response = await httpClient.get(
          `private/certificate/${certificateId}/group-comments`
        );

        return response.data;
      } else {
        const response = await httpClient.get(
          `private/certificate-class/${certificateClassId}/comments`
        );

        return response.data;
      }
    },
    onSuccess: () => {
      if (hasNotification) {
        queryClient.invalidateQueries([CERTIFICATE, slug]);
        queryClient.invalidateQueries([CERTIFICATES]);
      }
    },
  });

  const [commentText, setCommentText] = useState("");

  const commentsEndRef = useRef(null);

  const scrollToBottom = (isSmooth = false) => {
    if (commentsEndRef.current) {
      commentsEndRef.current.scrollIntoView({
        behavior: isSmooth ? "smooth" : "instant",
        block: "nearest",
        inline: "start",
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [isLoading]);

  useEffect(() => {
    if (slug) {
      const socketChannel = isGroupDiscussionBoard
        ? `${
            SOCKET_EVENT_TYPE.CERTIFICATE_PROGRAM_GROUP_COMMENT
          }-${+certificateUser.groupSelect}`
        : `${SOCKET_EVENT_TYPE.CERTIFICATE_PROGRAM_COMMENT}-${certificateClassId}`;

      SocketIO.on(socketChannel, (data) => {
        queryClient.invalidateQueries(queryKey);
      });
    }

    return () => {
      SocketIO.disconnect();
    };
  }, [
    // certificateClassId,
    // certificateId,
    // isGroupDiscussionBoard,
    // queryClient,
    // queryKey,
    // userProfile.id,
    certificateUser,
    slug,
  ]);

  const handleCommentSubmit = () => {
    if (!commentText) return;

    if (isGroupDiscussionBoard) {
      createGroupCommentMutation.mutate({
        certificateId,
        data: { content: commentText },
      });
    } else {
      createCommentMutation.mutate({
        certificateClassId,
        data: { content: commentText },
      });
    }
  };

  const createCommentMutation = useCreateCertificateComment({
    onSuccess: async () => {
      setCommentText("");
    },
  });

  const createGroupCommentMutation = useCreateGroupCertificateComment({
    onSuccess: async () => {
      setCommentText("");
    },
  });

  const handleCommentChange = (e) => {
    setCommentText(e.target.value);
  };

  if (isLoading) {
    return (
      <div
        style={{
          margin: "1rem",
        }}
      >
        <Skeleton avatar paragraph={{ rows: 2 }} />
        <Skeleton avatar paragraph={{ rows: 2 }} />
        <Skeleton avatar paragraph={{ rows: 2 }} />
        <Skeleton avatar paragraph={{ rows: 2 }} />
        <Comment
          avatar={<Avatar src={userProfile.img} alt="User Avatar" />}
          content={
            <Editor
              onChange={handleCommentChange}
              onSubmit={handleCommentSubmit}
              submitting={createCommentMutation.isLoading}
              value={commentText}
            />
          }
        />
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        height: "calc(100vh - 24rem)",
      }}
    >
      <div style={{ flex: 1, overflowY: "auto", padding: "16px" }}>
        {(comments || []).map((comment, index) => {
          return (
            <DiscussionBoardCommentWrapper
              comment={comment}
              index={index}
              userProfile={userProfile}
              certificateUserId={certificateUser?.id}
              scrollToBottom={scrollToBottom}
              isGroupDiscussionBoard={isGroupDiscussionBoard}
            />
          );
        })}
        <div style={{ marginTop: "1.5rem" }} ref={commentsEndRef} />
      </div>
      <div style={{ backgroundColor: "#fff", marginBottom: "1rem" }}>
        <Comment
          avatar={<Avatar src={userProfile.img} alt="User Avatar" />}
          content={
            <Editor
              onChange={handleCommentChange}
              onSubmit={handleCommentSubmit}
              submitting={createCommentMutation.isLoading}
              value={commentText}
            />
          }
        />
      </div>
    </div>
  );
};

const Editor = ({ onChange, onSubmit, submitting, value }) => (
  <>
    <Form.Item>
      <TextArea
        rows={2}
        onChange={onChange}
        value={value}
        placeholder="Add comment"
      />
    </Form.Item>
    <Button
      htmlType="submit"
      loading={submitting}
      onClick={onSubmit}
      type="primary"
      style={{ marginTop: ".5rem" }}
    >
      Add Comment
    </Button>
  </>
);

export default CommentSection;
