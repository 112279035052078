import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
// Headers
import {
  MainHeader,
  ArticleHeader,
  PrivateRoute,
  CertificateHeader,
  PublicHeader,
  LandingHeader,
} from "components";
import StartRouteSwiftHeader from "./StartRouteSwiftHeader";
import EventsCouncilShift from "./EventsCouncilShift";
import StartRouteSwiftHeaderVerify from "./StartRouteSwiftHeaderVerify";
import LiveChatRouteSwiftHeader from "./LiveChatRouteShiftHeader";
import StartRouteBlogs1 from "./StartRouteBlogs/1";
import StartRouteBlogs2 from "./StartRouteBlogs/2";
import StartRouteBlogsNew1 from "./StartRouteBlogsNew/1";

// Enum
import { INTERNAL_LINKS } from "enum";

class TopHeader extends Component {
  render() {
    return (
      <Switch>
        <PrivateRoute
          path={INTERNAL_LINKS.HOME}
          exact
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <Route
          path={INTERNAL_LINKS.LANDING_PAGUE}
          exact
          render={(props) => <LandingHeader {...props} />}
        />
        <Route
          path={INTERNAL_LINKS.CREATORS}
          exact
          render={(props) => <LandingHeader {...props} />}
        />
        <Route
          path={INTERNAL_LINKS.FOR_HR_VENDORS}
          exact
          render={(props) => <LandingHeader {...props} />}
        />
        <Route
          path={INTERNAL_LINKS.BLOGS_PAGUE}
          exact
          render={(props) => <LandingHeader {...props} />}
        />
        <Route path={INTERNAL_LINKS.LOGIN} />
        <Route path={INTERNAL_LINKS.SIGNUP} />
        <Route path={INTERNAL_LINKS.JOIN} />
        <Route
          path={INTERNAL_LINKS.TERMSOFUSE}
          render={(props) => <PublicHeader {...props} />}
        />
        <Route
          path={INTERNAL_LINKS.PUBLIC_MARKETPLACE}
          render={(props) => <PublicHeader {...props} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.HEART}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.HEART}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.CERTIFICATES}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={`${INTERNAL_LINKS.PROFILE}`}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.CORPORATE_ADMIN}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={`${INTERNAL_LINKS.CERTIFICATES}/:id`}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.CERTIFICATES_DASHBOARD}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={`${INTERNAL_LINKS.CERTIFICATES_DASHBOARD}/:id`}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.LEADERSHIP_PROGRAMS}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.HEART}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.COUNCIL}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.CHALLENGE}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.MENTORING}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.COUNCIL_ADMIN}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <Route
          path={INTERNAL_LINKS.EVENTS_COUNCIL}
          render={(props) => <EventsCouncilShift {...props} />}
        />
        <PrivateRoute
          path={`${INTERNAL_LINKS.COUNCIL}/resouce`}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.BUSINESS_PARTNER}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={`${INTERNAL_LINKS.BUSINESS_PARTNER}/resource`}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.CLASSES}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.MICRO_CLASS}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.CHANNELS}
          exact
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.CHANNELS}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.LEARNING_LIBRARY}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />

        <PrivateRoute
          path={INTERNAL_LINKS.BONFIRES}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          exact
          path={INTERNAL_LINKS.GROUPGROWS}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.COMMUNITIES}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.EVENTS}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.CERTIFICATIONS}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.JOURNEY}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.HUB}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.PODCAST}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.FAVORITES}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.READ_LATER}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.MARKETPLACE}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={`${INTERNAL_LINKS.ARTICLE}/:id`}
          render={(props) => <ArticleHeader {...props} />}
        />
        <PrivateRoute
          path={`${INTERNAL_LINKS.CERTIFICATE}/:id`}
          render={(props) => <CertificateHeader {...props} />}
        />
        <PrivateRoute
          path={`${INTERNAL_LINKS.MICRO_CLASS_CERTIFICATE}/:id`}
          render={(props) => <CertificateHeader {...props} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.CONFERENCE_LIBRARY}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        {/* <PrivateRoute
          exact
          path={INTERNAL_LINKS.GLOBAL_CONFERENCE}
          render={(props) => <MainHeader {...props} />}
        /> */}
        <PrivateRoute
          exact
          path={`${INTERNAL_LINKS.MICRO_CONFERENCE}/:id`}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          exact
          path={INTERNAL_LINKS.GLOBAL_CONFERENCE_SPEAKERS}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />

        <PrivateRoute
          exact
          path={INTERNAL_LINKS.GLOBAL_CONFERENCE_PARTICIPANTS}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />

        <PrivateRoute
          exact
          path={INTERNAL_LINKS.GLOBAL_CONFERENCE_PARTNERS}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />

        <PrivateRoute
          exact
          path={INTERNAL_LINKS.GLOBAL_CONFERENCE_BONFIRE}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />

        <PrivateRoute
          exact
          path={INTERNAL_LINKS.GLOBAL_CONFERENCE_PERSONAL_AGENDA}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />

        <PrivateRoute
          exact
          path={INTERNAL_LINKS.GLOBAL_CONFERENCE_RECOMMENDED_AGENDA}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />

        <PrivateRoute
          exact
          path={INTERNAL_LINKS.GLOBAL_CONFERENCE_LEADERBOARD}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />

        <PrivateRoute
          path={`${INTERNAL_LINKS.SPEAKERS}`}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={`${INTERNAL_LINKS.PARTICIPANTS}/:idConference`}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.NOT_FOUND}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.NOTIFICATIONS}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.PODCAST_SERIES}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.POST}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.LIBRARY_ITEM}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={`${INTERNAL_LINKS.PROJECTX}`}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          exact
          path={`${INTERNAL_LINKS.PROJECTX}/:id`}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={`${INTERNAL_LINKS.MY_LEARNINGS}`}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.SPONSOR_DASHBOARD}
          exact
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.TALENT_MARKETPLACE}
          exact
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={`${INTERNAL_LINKS.TALENT_MARKETPLACE}/job-post/:id`}
          exact
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={`${INTERNAL_LINKS.AD_HOME_PREVIEW}/:id`}
          exact
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={`${INTERNAL_LINKS.AD_CONFERENCE_LIBRARY_PREVIEW}/:id`}
          exact
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={`${INTERNAL_LINKS.AD_EVENTS_PREVIEW}/:id`}
          exact
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={`${INTERNAL_LINKS.AD_PROJECT_X_PREVIEW}/:id`}
          exact
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={`${INTERNAL_LINKS.SPEAKER_2023}`}
          exact
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />

        <PrivateRoute
          path={INTERNAL_LINKS.SEARCH}
          exact
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />

        <Route
          path={`${INTERNAL_LINKS.CHANNELS}/:id${INTERNAL_LINKS.BLOGS}/:id`}
          exact
          render={(props) => <StartRouteBlogs2 {...props} />}
        />

        <PrivateRoute
          path={INTERNAL_LINKS.BLOGS}
          exact
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />

        <PrivateRoute
          path={INTERNAL_LINKS.BLOGS_NEW}
          exact
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />

        <PrivateRoute
          path={INTERNAL_LINKS.DASHBOARDS_REFERENCE}
          exact
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />

        <PrivateRoute
          path={INTERNAL_LINKS.BLOGS_FOR_USERAPROVED}
          exact
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />

        <PrivateRoute
          exact
          path={INTERNAL_LINKS.RESOURCE}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />

        <PrivateRoute
          path={INTERNAL_LINKS.RESOURCES_FOR_USERAPROVED}
          exact
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />

        <Route
          path={`${INTERNAL_LINKS.BLOGS}/:id`}
          exact
          render={(props) => <StartRouteBlogs1 {...props} />}
        />

        <Route
          path={`${INTERNAL_LINKS.BLOGS_NEW}/:id`}
          exact
          render={(props) => <StartRouteBlogsNew1 {...props} />}
        />

        <Route
          exact
          path={`${INTERNAL_LINKS.SIMULATION_SPRINTS}`}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />

        <Route
          path={`${INTERNAL_LINKS.SIMULATION_SPRINTS}/:id`}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
        <Route
          exact
          path={`${INTERNAL_LINKS.CONFERENCE_2023}/:data`}
          render={(props) => <PublicHeader {...props} />}
        />
        <Route
          path={`${INTERNAL_LINKS.LIVE_CHAT}`}
          render={(props) => <LiveChatRouteSwiftHeader {...props} />}
        />
        {localStorage.getItem("community") ? (
          <Route
            path={`${INTERNAL_LINKS.CERTIFICATE_DIPLOMA_VERIFY}/:uniqueCode`}
            render={(props) => <MainHeader typeStyle={'black'} {...props} />}
          />

        ): (
        <Route
          path={`${INTERNAL_LINKS.CERTIFICATE_DIPLOMA_VERIFY}/:uniqueCode`}
          render={(props) => <PublicHeader {...props} />}
        />
        )}
        <Route
          path={`${INTERNAL_LINKS.VERIFY}/:code`}
          render={(props) => <StartRouteSwiftHeaderVerify {...props} />}
        />
        <Route
          exact
          path={`${INTERNAL_LINKS.CONFERENCE_2023}`}
          render={(props) => <PublicHeader {...props} />}
        />
        <Route
          exact
          path={"/:data"}
          render={(props) => <StartRouteSwiftHeader {...props} />}
        />
      </Switch>
    );
  }
}

export default TopHeader;
