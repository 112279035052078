import { createSelector } from "reselect";

const challengeDataSelector = (state) => state.challenge;

const resultSelector = createSelector(challengeDataSelector, (payload) => {
    return {
        challenges: payload.get("challenges"),
        challenge: payload.get("challenge"),
        allCommentsChallenge: payload.get("allCommentsChallenge"),
        activitiesStatus: payload.get('activitiesStatus')
    };
});

export const challengeSelector = (state) => ({
    ...resultSelector(state),
});
