import React, { useState } from "react";
import moment from "moment";
import { Modal } from "antd";
import "./style.scss";
import { Link } from "react-router-dom";
import IconLogo from "images/logo-sidebar.svg";

const CardCertificate = ({
  certificate,
  onClick,
  userRegistered = false,
  userDashBoard = false,
  userProfile = {},
  isMyProgram = false,
  hasNotification= false
}) => {
  const [openModal, closeModal] = useState(false);
  const [openModal2, closeModal2] = useState(false);
  const { title, image, slug, startDate, draft, openDashboard } = certificate;
  let startTime = moment.utc(startDate);

  return (
    <>
      <div className="container-card-certificate">
        <div className="container-image">
          <img src={image} alt="container-situation"></img>
        </div>
        <div className="container-content">
          <p
            style={{
              color: "black",
              fontSize: "24px",
              margin: "0px",
              padding: "0px",
              marginBottom: "5px",
              textAlign: "center",
            }}
          >
            {title}
          </p>
          <p
            style={{
              color: "black",
              fontSize: "14px",
              margin: "0px",
              padding: "0px",
            }}
          >
            Start Date: {startTime?.format("MMM DD")},{" "}
            {startTime?.format("YYYY")}
          </p>
          {!userDashBoard && (
            <>
              {!draft ? (
                <Link to={`/certificates/${slug}`} onClick={onClick}>
                  {userRegistered ? (
                    <>
                      <div
                        style={{ background: "#00B574", padding: "8px 17px" }}
                        className="button-card-certificate"
                      >
                        Enrolled | Explore More
                      </div>
                    </>
                  ) : (
                    <div
                      style={{ background: "#fe5621" }}
                      className="button-card-certificate"
                    >
                      Explore More
                    </div>
                  )}
                </Link>
              ) : (
                <div
                  className="button-card-certificate"
                  onClick={() => {
                    closeModal(true);
                  }}
                >
                  Coming Soon
                </div>
              )}
            </>
          )}

          {userDashBoard && (
            <>
              {openDashboard ||
              userProfile?.email === "enrique@hackinghr.io" ||
              userProfile?.email === "morecontrol64@gmail.com" ||
              userProfile?.email === "lourencelinao13@gmail.com" ||
              userProfile?.email === "lourencelinao13+test@gmail.com" ||
              userProfile?.email === "jessa@hackinghr.io" 
              ? (
                <Link to={`/certificates-dashboard/${slug}`} onClick={onClick}>
                  <div
                    style={{ background: "#00B574", padding: "8px 17px", position: "relative" }}
                    className="button-card-certificate"
                  >
                    Program Dashboard
                    {hasNotification && (
                      <span
                        style={{
                          position: "absolute",
                          top: "-6px",
                          right: "2px",
                          width: "16px",
                          height: "16px",
                          backgroundColor: "red",
                          borderRadius: "50%",
                          border: "2px solid white",
                        }}
                      />
                    )}
                  </div>
                </Link>
              ) : (
                <div
                  style={{ background: "#00B574", padding: "8px 17px" }}
                  className="button-card-certificate"
                  onClick={() => {
                    closeModal2(true);
                  }}
                >
                  Program Dashboard
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <Modal
        className="modal-login"
        width={"480px"}
        footer={null}
        style={{ height: "auto", top: "50px" }}
        visible={openModal}
        onCancel={() => closeModal(false)}
      >
        <div className="login-page-2">
          <div
            className="login-dialog"
            style={{ paddingTop: "40px", paddingBottom: "40px" }}
          >
            <div className="login-dialog-header" style={{ padding: "10px" }}>
              <h3 style={{ textAlign: "center" }}>
                Coming Soon. Stay tuned: enrollment will be opening in a few
                weeks.
              </h3>
              <div
                className="login-dialog-logo"
                style={{ borderRadius: "50%", background: "white" }}
              >
                <img src={IconLogo} alt="login-logo" />
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        className="modal-login"
        width={"480px"}
        footer={null}
        style={{ height: "auto", top: "50px" }}
        visible={openModal2}
        onCancel={() => closeModal2(false)}
      >
        <div className="login-page-2">
          <div
            className="login-dialog"
            style={{ paddingTop: "40px", paddingBottom: "40px" }}
          >
            <div className="login-dialog-header" style={{ padding: "10px" }}>
              <h3 style={{ textAlign: "center" }}>
                This Certificate Program Dashboard will open on{" "}
                {startTime?.format("MMM DD")}, {startTime?.format("YYYY")}
              </h3>
              <div
                className="login-dialog-logo"
                style={{ borderRadius: "50%", background: "white" }}
              >
                <img src={IconLogo} alt="login-logo" />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CardCertificate;
