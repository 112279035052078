import React, { useEffect, useState } from "react";
import { MinusCircleOutlined } from "@ant-design/icons";
import { DatePicker, Form, InputNumber, Checkbox, Radio } from "antd";
import { CustomInput, CustomCheckbox, CustomRadio } from "components";

const EventTypes = [
  {
    text: "Presentation",
    value: "presentation",
  },
  {
    text: "Workshop",
    value: "workshop",
  },
  {
    text: "Panel",
    value: "panel",
  },
  {
    text: "Peer-to-Peer Conversation",
    value: "peer-to-peer",
  },
  {
    text: "Conference",
    value: "conference",
  },
];

const { RangePicker } = DatePicker;

const FormListPanelItem = ({
  restField,
  index,
  name,
  limit,
  numOfPanels,
  setNumOfPanels,
  remove,
  disableDate,
  panelsMemory,
  setPanelsMemory,
  form
}) => {

  const [question, setQuestion] = useState('')

  useEffect(() => {
    if (index + 1 >= limit && panelsMemory < index + 1) {
      remove(name);

      if (numOfPanels > limit) {
        setNumOfPanels((state) => state - 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, index, remove, name, numOfPanels, setNumOfPanels]);

  const saveNewDetail = () => {
    if (panelsMemory < index + 1) {
      setPanelsMemory(index + 2)
    }
  }

  useEffect(() => {
    let hrBool = form.getFieldsValue()
    if (hrBool?.panels[index]?.hrCreditsBoolean === 'Yes') {
      setQuestion('Yes')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div style={{ marginTop: "2rem" }}>
      <Form.Item {...restField}>
        <div className="add-panel-title">
          <h3>Panel #{index + 2}</h3>
          <MinusCircleOutlined
            onClick={() => {
              setNumOfPanels((state) => state - 1);
              remove(name);
            }}
          />
        </div>
      </Form.Item>
      <Form.Item
        {...restField}
        label="Panel name"
        name={[name, "panelName"]}
        rules={[{ required: true }]}
      >
        <CustomInput onChange={() => { saveNewDetail() }} />
      </Form.Item>
      <Form.Item
        {...restField}
        label="Start and End Date"
        name={[name, "panelStartAndEndDate"]}
        rules={[{ required: true }]}
      >
        <RangePicker
          showTime
          disabledDate={(date) => disableDate(date, true)}
          style={{ width: "100%" }}
          size="large"
          format="YYYY-MM-DD HH:mm"
          onChange={() => { saveNewDetail() }}
        />
      </Form.Item>
      <Form.Item
        {...restField}
        label="Number of panelists"
        name={[name, "numberOfPanelists"]}
        rules={[{ required: true }]}
      >
        <InputNumber size="large" min="1" style={{ width: "100%" }} onChange={() => { saveNewDetail() }} />
      </Form.Item>
      <Form.Item
        {...restField}
        label="Link to join each panel"
        name={[name, "linkToJoin"]}
        rules={[{ required: true, type: "url" }]}
      >
        <CustomInput onChange={() => { saveNewDetail() }} />
      </Form.Item>
      <Form.Item {...restField} name={[name, "type"]} label="Type" rules={[{ required: true }]}>
        <Checkbox.Group className="d-flex flex-column event-edit-form-cbgrp" onChange={(e) => setQuestion(e?.target?.value)}>
          {EventTypes.map((type) => (
            <CustomCheckbox key={type?.value} value={type?.value}>
              {type?.text}
            </CustomCheckbox>
          ))}
        </Checkbox.Group>
      </Form.Item>
      <Form.Item {...restField} name={[name, "hrCreditsBoolean"]} label="HR Credits Available?" rules={[{ required: false }]}>
        <Radio.Group className="d-flex flex-column event-edit-form-cbgrp" onChange={(e) => setQuestion(e?.target?.value)}>
          {["Yes", "No"].map((type) => (
            <CustomRadio key={type} value={type}>
              {type}
            </CustomRadio>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item
        {...restField}
        name={[name, "typeHrCredits"]}
        label="Type HR Credits"
        rules={(question === 'Yes') ? [{ required: false }] : [{ required: false }]}
        style={(question === 'Yes') ? { position: 'relative' } : { position: 'absolute', left: '-1000px' }}
      >
        <Radio.Group className="d-flex flex-column event-edit-form-cbgrp">
          {["Ethics", "Business", "HR & Global"].map((type) => (
            <CustomRadio key={type} value={type}>
              {type}
            </CustomRadio>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item
        {...restField}
        name={[name, "numberOfHRCredits"]}
        label="Number of HR Credits"
        rules={(question === 'Yes') ? [{ required: false }] : [{ required: false }]}
        style={(question === 'Yes') ? { position: 'relative' } : { position: 'absolute', left: '-1000px' }}
        initialValue="1"
      >
        <InputNumber
          size="large"
          min="1"
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item
        {...restField}
        name={[name, "numberOfIHRIMCredits"]}
        label="Number of IHRIM Credits"
        rules={(question === 'Yes') ? [{ required: false }] : [{ required: false }]}
        style={(question === 'Yes') ? { position: 'relative' } : { position: 'absolute', left: '-1000px' }}
        initialValue="0"
      >
        <InputNumber
          size="large"
          min="0"
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item
        {...restField}
        label="Description"
        name={[name, "descriptionPanel"]}
      >
        <CustomInput />
      </Form.Item>
    </div >
  );
};

export default FormListPanelItem;
