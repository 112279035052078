import { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useChatMessageStore } from "./store";

const { io } = require("socket.io-client");

const ENDPOINT = process.env.REACT_APP_API_ENDPOINT_INSTANCE;

export const useChatSocket = ({ userProfile }) => {
  const [chatSocket, setChatSocket] = useState(null);
  const chatMessageStore = useChatMessageStore();

  const queryClient = useQueryClient();

  useEffect(() => {
    const chatConnection = io(`${ENDPOINT}/chat`, {
      transports: ["websocket"],
    });

    if (chatConnection && userProfile?.id) {
      setChatSocket(chatConnection);

      chatConnection.on("connect", () => {
        chatConnection.emit("login", {
          userId: userProfile.id,
          name: `${userProfile.firstName} ${userProfile.lastName}`,
          img: userProfile.img,
          titleProfessions: userProfile.titleProfessions,
        });
      });

      chatConnection.on("private chat", (payload) => {
        const { isEdit, type, queryId } = payload;

        if (!isEdit) {
          if (type === "individual") {
            const { queryId, ...rest } = payload;

            chatMessageStore.setChatMessageWindows({
              id: queryId,
              img: rest.img,
              name: rest.name,
              titleProfessions: rest.titleProfessions,
              type,
              isTyping: false,
            });

            queryClient.invalidateQueries({ queryKey: ["LAST_CHAT_MESSAGES", userProfile.id] });
          } else {
            const { queryId, type, name } = payload;

            chatMessageStore.setChatMessageWindows({
              id: queryId,
              name: name,
              type,
              isTyping: false,
            });

            queryClient.invalidateQueries({ queryKey: ["GROUP_CHAT_LAST_MESSAGE"] });
          }
        }

        // @todo do not invalidate but instead append to the end


        queryClient.invalidateQueries({ queryKey: ["CHAT_MESSAGE_WINDOW", type, queryId] });
      });

      chatConnection.on("deleteMessage", (data) => {
        const { queryId, type } = data;

        if (type === "individual") {
          queryClient.invalidateQueries({ queryKey: ["LAST_CHAT_MESSAGES", userProfile.id] });
        } else {
          queryClient.invalidateQueries({ queryKey: ["GROUP_CHAT_LAST_MESSAGE"] });
        }


        queryClient.invalidateQueries({ queryKey: ["CHAT_MESSAGE_WINDOW", type, queryId] });

      });

      chatConnection.on("typing", (data) => {

        const { id, type } = data;

        chatMessageStore.setTyping({ id, isTyping: true });

        if (type === "individual") {
          chatMessageStore.pushChatTabItemId({ id });
        } else {
          chatMessageStore.pushGroupChatTabItemId({ id });
        }

      });

      chatConnection.on("stop typing", (data) => {

        const { id, type } = data;

        chatMessageStore.setTyping({ id, isTyping: false });
        if (type === "individual") {
          chatMessageStore.deleteChatTabItemId({ id });
        } else {
          chatMessageStore.deleteGroupChatTabItemId({ id });
        }
      });

      chatConnection.on("online", (users) => {
        chatMessageStore.setOnlineUsers(users);
      });
    }

    return () => {
      chatConnection.disconnect();
    };
  }, [userProfile, queryClient]);


  return { chatSocket };
};