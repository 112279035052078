export default {
  NEW_EVENT: "new-event",
  LIVE_CHANGE: "live-change",
  SEND_MESSAGE_GLOBAL_CONFERENCE: "SEND_MESSAGE_GLOBAL_CONFERENCE",
  USER_ONLINE: "USER_ONLINE",
  USER_OFFLINE: "USER_OFFLINE",
  CREATE_CONVERSATION: "CREATE_CONVERSATION",
  NEW_CONVERSATION: "NEW_CONVERSATION",
  CONVERSATIONS: "CONVERSATIONS",
  MESSAGE: "MESSAGE",
  SEND_MESSAGE: "SEND_MESSAGE",
  UPDATE_COUNCIL_EVENT_PANEL: "UPDATE_COUNCIL_EVENT_PANEL",
  UPDATE_COUNCIL_EVENT_COMMENTS: "UPDATE_COUNCIL_EVENT_COMMENTS",
  CERTIFICATE_PROGRAM_COMMENT: "CERTIFICATE_PROGRAM_COMMENT",
  CERTIFICATE_PROGRAM_GROUP_COMMENT: "CERTIFICATE_PROGRAM_GROUP_COMMENT",
};
