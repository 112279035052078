import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { speakerAllPanelSpeakerSelector } from "redux/selectors/speakerSelector";

import { actions as speaker } from "redux/actions/speaker-actions";
import { getUser } from "redux/actions/home-actions";

import {
  CustomButton
} from "components";

import {
  notification
} from "antd";

import "./style.scss";

const ButtonsCouncilEvents = ({
    withdraw,
    withdrawAdmin,
    join,
    panel,
    userProfile,
    logginModal,
    completeProfile,
    limit2,
    isGlobalConference
  }) => {

    const [hasJoined, setHasJoined] = useState(false)
    const [selectType, setSelectType] = useState('')

    const escapeDoubleQuotesWithinString = (cadena, startString='":"', endString='","') => {
      let dentroDelRango = false;
      let cadenaEscapada = '';
  
      for (let i = 0; i < cadena.length; i++) {
        // Comprobamos si estamos dentro del rango startString y endString
        if (cadena.substring(i, i + startString.length) === startString) {
          dentroDelRango = true;
          cadenaEscapada += startString;
          i += startString.length - 1; // Saltamos al final del startString
          continue;
        } else if (cadena.substring(i, i + endString.length) === endString) {
          dentroDelRango = false;
          cadenaEscapada += endString;
          i += endString.length - 1; // Saltamos al final del endString
          continue;
        }
  
        // Escapamos las comillas si estamos dentro del rango y no son parte de `{"` o `"}` 
        if (dentroDelRango && cadena[i] === '"' && !(cadena[i - 1] === "{" && cadena[i] === '"') && !(cadena[i] === '"' && cadena[i + 1] === "}")) {
          cadenaEscapada += '\\"';
        } else {
          cadenaEscapada += cadena[i];
        }
      }
  
      return cadenaEscapada;
    }
    
    const isFullCouncil = panel?.CouncilEventPanelists?.filter((user) => 
      user.isModerator === false || user.isModerator === 'false'
    )?.length >= +panel.numberOfPanelists;

    const isFullGlobal = (panel?.SpeakerMemberPanels?.filter((user) => 
      user.isModerator === false || user.isModerator === 'false'
    )?.length + panel?.arrayControlIdsExternal?.filter((user2) => {
      let user = JSON.parse(escapeDoubleQuotesWithinString(user2))
      return ((user.isModerator === 'false') ? false : true) === false
    })?.length) >= +panel.numberOfPanelists;
    
    let isFull = isGlobalConference ? isFullGlobal : isFullCouncil;

  useEffect(() => {
    if (isGlobalConference) {
      if(panel?.arrayControlIdsEvents?.length > 0){
  
        const councilAwait = panel?.arrayControlIdsEvents?.find((panelList2) => {
          let panelList = JSON.parse(escapeDoubleQuotesWithinString(panelList2))
          return Number(panelList.id) === Number(userProfile.id)
        })
  
        if(!councilAwait){
          const councilEventPanelist = panel?.SpeakerMemberPanels?.find(
            (panelist) => panelist?.User?.id === userProfile.id
          );
          
          setHasJoined(!!councilEventPanelist);
          setSelectType('sure')
        }else{
          setHasJoined(!!councilAwait);
          setSelectType('await')
        }
        
      }else{
        const councilEventPanelist = panel?.SpeakerMemberPanels?.find(
          (panelist) => panelist?.User?.id === userProfile.id
        );
        
        setHasJoined(!!councilEventPanelist);
        setSelectType('sure')
      }
    } else {
      if(panel?.arrayControlIdsEvents?.length > 0){
  
        const councilAwait = panel?.arrayControlIdsEvents?.find(
          (panelList) => Number(panelList.id) === Number(userProfile.id)
        )
  
        if(!councilAwait){
          const councilEventPanelist = panel?.CouncilEventPanelists?.find(
            (panelist) => panelist?.User?.id === userProfile.id
          );
          
          setHasJoined(!!councilEventPanelist);
          setSelectType('sure')
        }else{
          setHasJoined(!!councilAwait);
          setSelectType('await')
        }
        
      }else{
        const councilEventPanelist = panel?.CouncilEventPanelists?.find(
          (panelist) => panelist?.User?.id === userProfile.id
        );
        
        setHasJoined(!!councilEventPanelist);
        setSelectType('sure')
      }
    }
    },[panel, setHasJoined, setSelectType, userProfile, isGlobalConference])
  
  
    return (
      <>
        {hasJoined ? (
          <CustomButton
            text="Withdraw"
            onClick={() => {
              if(selectType === 'sure'){
                withdrawAdmin()
              }
              if(selectType === 'await'){
                withdraw()
              }
              setHasJoined(false);
            }}
            type="third"
            size="small"
          />
        ) : (
          <CustomButton
            text={isFull ? "Already Full" : "Join"}
            disabled={isFull}
            onClick={() => {
              if(localStorage.getItem("community") !== null){
                if(userProfile.percentOfCompletion !== 100){
                  completeProfile()
                }else{
                  if(limit2){
                    notification.info({
                      message: "You can only join up to 1 panels."
                    })
                  }else{
                    join()
                    setHasJoined(true);
                  }
                }
              }else{
                logginModal()
              }
            }}
            size="small"
          />
        )}
    </>
    );
  };
  
  const mapStateToProps = (state, props) => ({
    allPanelSpeakers: speakerAllPanelSpeakerSelector(state).allPanelSpeakers,
    allUserSpeaker: speakerAllPanelSpeakerSelector(state).allUserSpeakers,
  });
  
  const mapDispatchToProps = {
    removeUserSpeakerToPanel: speaker.removeUserSpeakerToPanel,
    addUserSpeakerToPanel: speaker.addUserSpeakerToPanel,
    getUser
  };

  ButtonsCouncilEvents.propTypes = {
    userProfile: PropTypes.object,
    withdraw: PropTypes.func,
    join: PropTypes.func,
    completeProfile: PropTypes.func,
    isFull: PropTypes.bool
  };
  
  ButtonsCouncilEvents.defaultProps = {
    userProfile: {},
    isFull: false,
    withdraw: () => {},
    join: () => {},
    completeProfile: () => {}
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(ButtonsCouncilEvents);
  