import { createAction } from "redux-actions";

const SET_MENTORING_INFO = "SET_MENTORING_INFO";
const UPDATE_MENTORING_INFO = "UPDATE_MENTORING_INFO";
const SAVE_MENTORING_INFO = "SAVE_MENTORING_INFO";
const SET_MENTORING_LOADING = "SET_MENTORING_LOADING";
const GET_MENTORING_INFO = "GET_MENTORING_INFO";
const GET_MENTOR_LIST = "GET_MENTOR_LIST";
const SET_MENTOR_LIST = "SET_MENTOR_LIST";
const GET_MORE_MENTOR_LIST = "GET_MORE_MENTOR_LIST";
const GET_MENTEE_LIST = "GET_MENTEE_LIST";
const SET_MENTEE_LIST = "SET_MENTEE_LIST";
const GET_MORE_MENTEE_LIST = "GET_MORE_MENTEE_LIST";
const SET_MENTOR_LOADING = "SET_MENTOR_LOADING";
const SET_MENTEE_LOADING = "SET_MENTEE_LOADING";
const SET_MATCH = "SET_MATCH";
const UPDATE_MATCH = "UPDATE_MATCH";
const CREATE_MENTOR = "CREATE_MENTOR";
const CREATE_MENTEE = "CREATE_MENTEE";
const CREATE_REQUEST = "CREATE_REQUEST";
const UPDATE_MENTOR = "UPDATE_MENTOR";
const UPDATE_MENTEE = "UPDATE_MENTEE";
const UPDATE_REQUEST = "UPDATE_REQUEST";
const GET_ONE_MENTOR = "GET_ONE_MENTOR";
const GET_ONE_MENTEE = "GET_ONE_MENTEE";
const SET_MENTOR_PROFILE = "SET_MENTOR_PROFILE";
const SET_MENTEE_PROFILE = "SET_MENTEE_PROFILE";

export const constants = {
  SET_MENTORING_INFO,
  SAVE_MENTORING_INFO,
  SET_MENTORING_LOADING,
  GET_MENTOR_LIST,
  SET_MENTOR_LIST,
  GET_MENTEE_LIST,
  SET_MENTEE_LIST,
  GET_MENTORING_INFO,
  UPDATE_MENTORING_INFO,
  GET_MORE_MENTOR_LIST,
  GET_MORE_MENTEE_LIST,
  SET_MENTOR_LOADING,
  SET_MENTEE_LOADING,
  SET_MATCH,
  UPDATE_MATCH,
  CREATE_MENTOR,
  CREATE_MENTEE,
  CREATE_REQUEST,
  UPDATE_MENTOR,
  UPDATE_MENTEE,
  UPDATE_REQUEST,
  GET_ONE_MENTOR,
  GET_ONE_MENTEE,
  SET_MENTOR_PROFILE,
  SET_MENTEE_PROFILE,
};

// ------------------------------------
// Actions
// ------------------------------------
export const setMentoringInfo = createAction(
  SET_MENTORING_INFO,
  (info, callback) => ({
    info,
    callback,
  })
);
export const updateMentoringInfo = createAction(
  UPDATE_MENTORING_INFO,
  (info, callback) => ({ info, callback })
);
export const saveMentoringInfo = createAction(SAVE_MENTORING_INFO, (info) => ({
  info,
}));
export const setMentoringLoading = createAction(
  SET_MENTORING_LOADING,
  (loading) => ({ loading })
);
export const getMentoringInfo = createAction(GET_MENTORING_INFO);
export const getMentorList = createAction(GET_MENTOR_LIST, (filter, order) => ({
  filter,
  order,
}));
export const getMoreMentorList = createAction(
  GET_MORE_MENTOR_LIST,
  (filter, order) => ({
    filter,
    order,
  })
);
export const setMentorList = createAction(
  SET_MENTOR_LIST,
  (countOfResults, currentPage, mentorList) => ({
    countOfResults,
    currentPage,
    mentorList,
  })
);
export const getMenteeList = createAction(GET_MENTEE_LIST, (filter, order) => ({
  filter,
  order,
}));
export const getMoreMenteeList = createAction(
  GET_MORE_MENTEE_LIST,
  (filter, order) => ({
    filter,
    order,
  })
);
export const setMenteeList = createAction(
  SET_MENTEE_LIST,
  (countOfResults, currentPage, menteeList) => ({
    countOfResults,
    currentPage,
    menteeList,
  })
);
export const setMentorLoading = createAction(
  SET_MENTOR_LOADING,
  (mentorLoading) => ({ mentorLoading })
);
export const setMenteeLoading = createAction(
  SET_MENTEE_LOADING,
  (menteeLoading) => ({ menteeLoading })
);
export const setMatch = createAction(SET_MATCH, (source, match, target) => ({
  source,
  match,
  target,
}));
export const updateMatch = createAction(UPDATE_MATCH, (member) => ({ member }));

export const createMentor = createAction(CREATE_MENTOR, (data, callback) => ({ data, callback }))
export const createMentee = createAction(CREATE_MENTEE, (data, callback) => ({ data, callback }))
export const createRequest = createAction(CREATE_REQUEST, (data, callback) => ({ data, callback }))
export const updateMentor = createAction(UPDATE_MENTOR, (data, callback) => ({ data, callback }))
export const updateMente = createAction(UPDATE_MENTEE, (data, callback) => ({ data, callback }))
export const updateRequest = createAction(UPDATE_REQUEST, (data, callback) => ({ data, callback }))
export const getOneMentor = createAction(GET_ONE_MENTOR, (callback) => ({ callback }))
export const getOneMentee = createAction(GET_ONE_MENTEE, (callback) => ({ callback }))
export const setMentor = createAction(SET_MENTOR_PROFILE, (data) => ({ data }))
export const setMentee = createAction(SET_MENTEE_PROFILE, (data) => ({ data }))

export const actions = {
  setMentoringInfo,
  saveMentoringInfo,
  setMentoringLoading,
  getMentorList,
  getMoreMentorList,
  setMentorList,
  getMenteeList,
  getMoreMenteeList,
  setMenteeList,
  getMentoringInfo,
  updateMentoringInfo,
  setMentorLoading,
  setMenteeLoading,
  setMatch,
  updateMatch,
  createMentor,
  createMentee,
  createRequest,
  updateMentor,
  updateMente,
  updateRequest,
  getOneMentor,
  getOneMentee,
  setMentor,
  setMentee,
};
