/* eslint-disable no-template-curly-in-string */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import { useParams, useHistory } from "react-router-dom";
import AdvertisementDrawer from "containers/AdvertisementDrawer";

import ProfileStatusBar from "./ProfileStatusBar";
import {
  // PostsFilterPanel, 
  CustomButton,CustomModal
} from "components";

import Posts from "containers/Posts";
import moment from "moment";
import TimeZoneList from "enum/TimezoneList";
// import FilterDrawer from "../Home/FilterDrawer";

import { getUser, setFirstRedirect, confirmStatusPaid } from "redux/actions/home-actions";
import { getAllPost } from "redux/actions/post-actions";
import { getRecommendations } from "redux/actions/library-actions";

import { homeSelector } from "redux/selectors/homeSelector";
import { librarySelector } from "redux/selectors/librarySelector";
import { postSelector } from "redux/selectors/postSelector";
import { liveSelector } from "redux/selectors/liveSelector";
import { advertisementSelector } from "redux/selectors/advertisementsSelector";
import { eventSelector } from "redux/selectors/eventSelector";
import { envSelector } from "redux/selectors/envSelector";
import { Collapse } from 'antd'
import {
  getAdvertisementsTodayByPage,
  getAdvertisementById,
  createAdvertisementClick,
} from "redux/actions/advertisment-actions";

import {
  getEvent,
  verifySuscribedUser,
  suscriptionSendingBlue,
} from "redux/actions/event-actions"
import {
  getChallenge,
} from "redux/actions/challenge-actions";
import { challengeSelector } from "redux/selectors/challengeSelector";

import Emitter from "services/emitter";
import { EVENT_TYPES, INTERNAL_LINKS } from "enum";

import "./style.scss";
import TrendingItem from "./TrendingItem";

const { Panel } = Collapse

const HomePage = ({
  history,
  location,
  userProfile,
  recommendations,
  getRecommendations,
  getUser,
  currentPage,
  getAllPost,
  getAdvertisementsTodayByPage,
  advertisementsByPage,
  getAdvertisementById,
  setFirstRedirect,
  firstRedirect,
  advertisementById,
  isAdPreview = false,
  createAdvertisementClick,
  getEvent,
  liveEvent,
  live,
  getChallenge,
  challenges,
  isMobile,
  verifySuscribedUser,
  suscriptionSendingBlue,
  confirmStatusPaid
}) => {
  const [filters] = useState({});
  const [text] = useState("");
  const [visible, setVisible] = useState(false);
  const [modalResponsePay, setModalResponsePay] = useState("")
  const [hasAdvertisementData, setHasAdvertisementData] = useState(null);
  const [bulSuscribed, setBulSuscribed] = useState(true)
  const { refreshPremium, refreshPremiumBad, refreshCorporateAdmin
    // refreshMore, refreshMoreBad
  } = queryString.parse(location.search);
  const { id } = useParams();

  const history2 = useHistory();

  const onUpgrade = () => {
    Emitter.emit(EVENT_TYPES.OPEN_PAYMENT_MODAL);
  };

  useEffect(() => {
    if (!firstRedirect) {
      setFirstRedirect(true)
      // history2.push(INTERNAL_LINKS.LOGIN)
    }

  }, [firstRedirect, setFirstRedirect])

  useEffect(() => {
    getChallenge();
  }, [getChallenge])

  useEffect(() => {
    if(refreshPremium && refreshPremium === 'true' && (userProfile?.email !== '' && userProfile?.email)){
      getUser();
      setModalResponsePay("Wowza! You are now a PREMIUM MEMBER of the Hacking HR LAB! 🎉")
      confirmStatusPaid(userProfile?.email,userProfile)
      history2.push(INTERNAL_LINKS.HOME)
    }
  },[refreshPremium,userProfile])

  useEffect(() => {
    if(refreshPremiumBad && refreshPremiumBad === 'true'){
      setModalResponsePay("Ouh, payment could not be processed")
      history2.push(INTERNAL_LINKS.HOME)
    }
  },[refreshPremiumBad])

  // useEffect(() => {
  //   if(refreshMore && refreshMore === 'true' && userProfile?.email){
  //     getUser();
  //     setModalResponsePay("Wowza! You and your TEAM are now a PREMIUM MEMBERS of the Hacking HR LAB! 🎉")
  //     confirmStatusPaid({email: userProfile?.email})
  //     history2.push(INTERNAL_LINKS.HOME)
  //   }
  // },[refreshMore, userProfile])

  // useEffect(() => {
  //   if(refreshMoreBad && refreshMoreBad === 'true'){
  //     setModalResponsePay("Ouh, payment could not be processed")
  //     history2.push(INTERNAL_LINKS.HOME)
  //   }
  // },[refreshMoreBad])

  
  useEffect(() => {
    if ((refreshCorporateAdmin === 'true' || refreshCorporateAdmin === true) && (userProfile?.email !== '' && userProfile?.email)) {
      getUser();
      setModalResponsePay("Wowza! You are now a PREMIUM MEMBER of the Hacking HR LAB! 🎉")
      confirmStatusPaid(userProfile?.email,userProfile)
      history2.push(INTERNAL_LINKS.HOME)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCorporateAdmin,userProfile]);

  useEffect(() => {
    const { refresh } = queryString.parse(location.search);
    if (refresh === 1) {
      getUser();
    }

    getRecommendations();
    getAllPost({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAdPreview) {
      getAdvertisementById(id);
    } else {
      getAdvertisementsTodayByPage("home");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (
      advertisementsByPage !== undefined &&
      advertisementsByPage.home !== undefined
    ) {
      if (advertisementsByPage?.home[0] !== undefined) {
        setHasAdvertisementData(true);
      } else {
        setHasAdvertisementData(false);
      }
    } else {
      setHasAdvertisementData(false);
    }
  }, [advertisementsByPage]);

  // const onFilterChange = (filter) => {
  //   getAllPost({ ...filter, text });
  //   setFilters(filter);
  // };

  // const showFilterPanel = () => {
  //   Emitter.emit(EVENT_TYPES.OPEN_FILTER_PANEL);
  // };

  // const onSearch = (value) => {
  //   getAllPost({
  //     ...filters,
  //     text: value,
  //   });
  //   setText(value);
  // };

  const onShowMore = () => {
    getAllPost({
      ...filters,
      text,
      page: currentPage + 1,
    });
  };

  const searchTimeZone = (timezone) => {
    let currentTimezone = TimeZoneList.find((item) => item.value === timezone);

    if (currentTimezone) {
      currentTimezone = currentTimezone.utc[0];
    } else {
      currentTimezone = timezone;
    }

    return currentTimezone
  }

  const showButtonFunction = (startAndEndTimes, timezone) => {
    let bul = false

    if (startAndEndTimes !== undefined && timezone !== undefined) {
      let dateNow = moment(moment.utc(), 'YYYY-MM-DD hh:mm a').tz(searchTimeZone(timezone))?.format('YYYYMMDDHHmm')

      startAndEndTimes.forEach((time) => {
        if (bul === false) {
          if ((Number(dateNow) >= Number(moment(time?.startTime, "YYYY-MM-DD hh:mm a")?.subtract(5, 'minute')?.format('YYYYMMDDHHmm'))) === true
            && (Number(dateNow) < Number(moment(time?.endTime, "YYYY-MM-DD hh:mm a")?.add(1, 'minute')?.format('YYYYMMDDHHmm'))) === true) {
            bul = true
          }
        }
      })
    }

    return bul

  }

  const liveAds = showButtonFunction(liveEvent?.startAndEndTimes, liveEvent?.timezone) ? (
    <div
      className="live-button"
      onClick={() => {
        if (userProfile.completed === true) {
          history2.push(INTERNAL_LINKS.LIVE);
        } else {
          Emitter.emit(EVENT_TYPES.SHOW_FIREWALL, "live");
        }
      }}
    >
      <div className="live-container">
        <div className="live-circle"></div>
        <div>LIVE</div>
        <p>: {liveEvent?.title}</p>
      </div>
    </div>
  ) : (
    <div style={{ display: 'none' }}></div>
  )

  useEffect(() => {
    if (live.event) {
      getEvent(Number(live.event));
    }
  }, [live.event, getEvent])

  useEffect(() => {
    verifySuscribedUser((data) => {
      if (!data) {
        setBulSuscribed(true)
      } else {
        setBulSuscribed(false)
      }
    })
  }, [verifySuscribedUser, setBulSuscribed])

  const esEnlace = (texto) => {
    let patronEnlaceLinkedIn = /^(https|http|ftp):\/\/[\w\-]+(\.[\w\-]+)+[^\s/$.?#].*$/i;
    return patronEnlaceLinkedIn.test(texto);
  }

  const displayAds = () => {

    if (!isAdPreview) {
      if (hasAdvertisementData) {
        return (
          <div className="home-advertisement-wrapper" style={{ marginRight: 10, width: '22%', paddingTop: '20px' }}>
            {liveAds}
            {advertisementsByPage?.home?.map((advertisement) => {
              return (
                <div
                  className="home-advertisement-wrapper-content"
                  style={{ margin: '0px', marginBottom: '20px', padding: '20px' }}
                  key={advertisement?.id}
                >
                  <div
                    className="advertisement"
                    onClick={() => {
                      createAdvertisementClick(advertisement?.id);
                      window.open(advertisement?.advertisementLink, "_blank");
                    }}
                  >
                    <img
                      src={advertisement?.adContentLink}
                      alt="advertisement"
                      className="advertisement-img"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )
      } else if (showButtonFunction(liveEvent?.startAndEndTimes, liveEvent?.timezone)) {
        return (
          <div className="home-advertisement-wrapper" style={{ marginRight: 10, width: '22%' }}>
            {liveAds}
          </div>
        )
      } else {
        return <div className="home-advertisement-wrapper" style={{ marginRight: 10, width: '22%' }}></div>
      }
    } else {
      return <div></div>
    }

  }

  const displayPreviewAd = isAdPreview && (
    <div className="home-advertisement-wrapper" style={{ marginRight: 10, width: '22%', paddingTop: '20px' }} >
      <div className="home-advertisement-wrapper-content" style={{ margin: '0px', marginBottom: '20px', padding: '20px' }}>
        <div
          className="advertisement"
          onClick={() =>
            window.open(advertisementById?.advertisementLink, "_blank")
          }
        >
          <img
            src={advertisementById?.adContentLink}
            alt="advertisement"
            className="advertisement-img"
          />
        </div>
      </div>
    </div>
  );

  const hadleFilterDates = (data) => {

    let newData = data.filter((e) => {
      let dateNow = moment(moment.utc(), 'YYYY-MM-DD hh:mm a').tz(searchTimeZone(e?.timezone))?.format('YYYYMMDDHHmm')
      return (Number(dateNow) < Number(moment(e?.startDate, "YYYY-MM-DD hh:mm a")?.add(24, 'hours')?.format('YYYYMMDDHHmm'))) === true
    })

    return newData
  }

  const displayChallenges = ((!isMobile) ? (
    <div className="home-container-data" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start' }}>
      <div className="home-advertisement-wrapper" style={
        { height: 'auto', paddingTop: '1.5rem' }
      }>
        {!bulSuscribed && <div
          className="home-advertisement-wrapper-content"
          style={{ margin: '0px', padding: '20px' }}
        >
          <div
            className="home-advertisement-wrapper-content"
            style={{ margin: '0px', marginBottom: '0px', padding: '20px', background: '#fff', border: '1px solid #e1e2ee', borderRadius: '0.5rem' }}

          >
            <div>
              <p style={{
                fontFamily: 'roboto,sans-serif', fontStyle: 'normal', fontWeight: '400',
                fontSize: '22px', lineHeight: '30px', color: '#3c4752', textAlign: 'center'
              }}>Please subscribe to the Hacking HR LAB notification email to receive relevant information</p>
              <div style={{ display: 'flex', justifyContent: 'center', width: '100%', zIndex: '100px' }}>
                <CustomButton
                  className="button-speaker font-size-more"
                  text="Subscribe here"
                  size="SM"
                  style={{ width: '150px' }}
                  type={"third"}
                  onClick={() => {
                    suscriptionSendingBlue(() => {
                      setBulSuscribed(true)
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </div>}
        {(hadleFilterDates(challenges) || [])?.map((e, index) => (
          <div
            className="home-advertisement-wrapper-content"
            key={index}
            style={{ margin: '0px', marginBottom: '20px', padding: '20px' }}
            onClick={() =>
              window.open(`${INTERNAL_LINKS.CHALLENGE}/${e?.id}`, "_blank")
            }
          >
            <div
              className="advertisement"
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', position: 'relative' }}
            >
              <img
                src={e?.image}
                alt="advertisement"
                className="advertisement-img"
              />
              <p style={{
                height: 'auto',
                color: 'black',
                fontSize: '25px',
                fontWeight: 'bold',
                textAlign: 'center',
                position: 'absolute',
                width: '100%',
              }}>{e?.title}</p>
              <div style={{ display: 'flex', justifyContent: 'center', position: 'relative', bottom: '50px', width: '100%', zIndex: '100px', height: '0px' }}>
                <CustomButton
                  className="button-speaker font-size-more"
                  text="JOIN CHALLENGE"
                  size="SM"
                  style={{ width: '150px' }}
                  type={"primary"}
                  onClick={() => {
                    // window.open(`${INTERNAL_LINKS.CHALLENGE}/${e?.id}`, "_blank")
                  }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div >
  ) : (
    <Collapse className="home-container-data" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', marginTop: '20px', background: 'white' }}>
      <Panel className="home-advertisement-wrapper" header="Challenge" style={{ color: 'white', fontWeight: 'bold' }}>
        {hadleFilterDates(challenges)?.map((e, index) => (
          <div
            className="home-advertisement-wrapper-content"
            key={index}
            style={{ margin: '0px', marginBottom: '0px', padding: '20px' }}
            onClick={() =>
              window.open(`${INTERNAL_LINKS.CHALLENGE}/${e?.id}`, "_blank")
            }
          >
            <div
              className="advertisement"
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <img
                src={e?.image}
                alt="advertisement"
                className="advertisement-img"
              />
              <div style={{ display: 'flex', justifyContent: 'center', position: 'relative', bottom: '175px', width: '100%', zIndex: '100px', height: '0px' }}>
                <p style={{ height: '20px', color: 'black', fontSize: '25px', fontWeight: 'bold' }}>{e?.title}</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', position: 'relative', bottom: '50px', width: '100%', zIndex: '100px', height: '0px' }}>
                <CustomButton
                  className="button-speaker font-size-more"
                  text="JOIN CHALLENGE"
                  size="SM"
                  style={{ width: '150px' }}
                  type={"primary"}
                  onClick={() => {
                    // window.open(`${INTERNAL_LINKS.CHALLENGE}/${e?.id}`, "_blank")
                  }}
                />
              </div>
            </div>
          </div>
        ))}
      </Panel>
    </Collapse>
  ));

  const onOpenPostFormModal = () => {
    if (userProfile.completed === true && userProfile.memberShip === "premium") {
      Emitter.emit(EVENT_TYPES.OPEN_POST_MODAL);
    } else {
      Emitter.emit(EVENT_TYPES.SHOW_FIREWALL, "story");
    }
  };

  return (
    <div className="home-page-container">
      <div className="home-page-container--trending">
        <h3>Trending</h3>
        <div className="items">
          {recommendations.podcasts && (
            <>
              {recommendations.podcasts.map((item, index) => (
                <TrendingItem
                  key={`trending-podcast-${index}`}
                  type="podcast"
                  element={item}
                />
              ))}
            </>
          )}

          {recommendations.conferenceLibrary && (
            <>
              {recommendations.conferenceLibrary.map((item, index) => (
                <TrendingItem
                  key={`trending-conference-${index}`}
                  type="conference"
                  element={item}
                />
              ))}
            </>
          )}

          {recommendations.libraries && (
            <>
              {recommendations.libraries.map((item, index) => (
                <TrendingItem
                  key={`trending-library-${index}`}
                  type="library"
                  element={item}
                />
              ))}
            </>

          )}
          {recommendations.events && (
            <>
              {recommendations.events.map((item, index) => (
                <TrendingItem
                  key={`trending-event-${index}`}
                  type="event"
                  element={item}
                />
              ))}
            </>
          )}
        </div>
      </div>
      <div className="home-page-container--posts">
        <div className="home-page-container--posts-central-panel">
          <div className="home-page-container--upgrade">
            {displayChallenges}
            <div className="container-post-center">
              <div className="home-page-container--upgrade container-upgrade-premium">
                {isMobile && (
                  <CustomButton
                    type="primary"
                    onClick={() => onOpenPostFormModal()}
                    text="Add Story"
                  />
                )}
                {userProfile && (userProfile.percentOfCompletion !== 100 || !esEnlace(userProfile?.personalLinks?.linkedin?.toLowerCase())) && (
                  <div className="home-page-container--profile">
                    <ProfileStatusBar user={userProfile} />
                  </div>
                )}
                {userProfile && userProfile.memberShip === "free" && (
                  <div className="recommend-card">
                    <h2 className="recommend-card-label">
                      Upgrade to a PREMIUM Membership and get unlimited access to
                      the LAB features
                    </h2>
                    <CustomButton
                      text="Upgrade to PREMIUM"
                      type="primary"
                      size="xl"
                      className="recommend-card-upgrade-2"
                      onClick={onUpgrade}
                    />
                  </div>
                )}
              </div>
              <Posts onShowMore={onShowMore} history={history} />
            </div>
            {displayAds()}
            {displayPreviewAd}
          </div>
        </div>
      </div>
      <AdvertisementDrawer
        page="home"
        visible={visible}
        setVisible={setVisible}
      />
      <CustomModal
        visible={modalResponsePay !== ''}
        onCancel={() => {setModalResponsePay('')}}
        title={[]}
        footer={[]}
        width={480}
      >
        <p style={{margin:'0px',padding:'0px',marginTop:'10px',textAlign:'center',width:'100%', fontSize:'22px', fontWeight:'bold'}}>{modalResponsePay}</p>
        <p style={{margin:'0px',padding:'0px',marginTop:'30px',textAlign:'center',width:'100%', fontSize:'22px', fontWeight:'bold'}}>Thank you so much!</p>
      </CustomModal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userProfile: homeSelector(state).userProfile,
  firstRedirect: homeSelector(state).firstRedirect,
  recommendations: librarySelector(state).recommendations,
  currentPage: postSelector(state).currentPage,
  live: liveSelector(state).live,
  isMobile: envSelector(state).isMobile,
  liveEvent: eventSelector(state).liveEvent,
  challenges: challengeSelector(state).challenges,
  ...advertisementSelector(state),
});

const mapDispatchToProps = {
  getRecommendations,
  setFirstRedirect,
  getUser,
  confirmStatusPaid,
  getAllPost,
  getAdvertisementsTodayByPage,
  getAdvertisementById,
  createAdvertisementClick,
  getChallenge,
  getEvent,
  verifySuscribedUser,
  suscriptionSendingBlue,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
