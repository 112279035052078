import httpClient from "./httpClient";

export const createFilePostEndPoint = (payload) => {
    return httpClient.post("private/files/create/", {
        ...payload
    })
}

export const getAllFilePostEndPoint = () => {
    return httpClient.get("private/files/get/")
}

export const editFilePostEndPoint = (payload) => {
    return httpClient.post("private/files/edit/", {
        ...payload
    })
}

export const removeFilePostEndPoint = (payload) => {
    return httpClient.delete(`private/files/remove/${payload.id}`)
}

export const downloadFilePostEndPoint = (payload) => {
    return httpClient.get(`private/files/download/${payload.id}`)
}