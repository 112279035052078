import { createSelector } from "reselect";

const eventConfirmCodeSelectorData = (state) => state.eventCode;

const resultSelector = createSelector(eventConfirmCodeSelectorData, (payload) => {
    return {
        eventVerificationCode: payload.get("eventVerificationCode")
    };
});

export const eventConfirmCodeSelector = (state) => ({
    ...resultSelector(state),
});
