import React from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import httpClient from "../../../api/module/httpClient";
import moment from "moment-timezone";
import { Skeleton } from "antd";
import { CERTIFICATE, CERTIFICATES } from "enum/QueryKeys";

export default function AnnouncementTab({ certificateId, slug, hasAnnouncementNotification = false }) {
  const queryClient = useQueryClient()


  const { data: announcements, isLoading } = useQuery({
    queryKey: ["announcements", certificateId],
    queryFn: async () => {
      const response = await httpClient.get(
        `private/certificate-announcements/${certificateId}`
      );

      return response.data;
    },
    enabled: !!certificateId,
    onSuccess: () => {
      if (hasAnnouncementNotification) {
        queryClient.invalidateQueries([CERTIFICATE, slug])
        queryClient.invalidateQueries([CERTIFICATES])
      }

    },
  });

  if (isLoading) {
    return (
      <>
        {Array(3)
          .fill()
          .map(() => {
            return (
              <div
                style={{
                  padding: "1rem",
                }}
              >
                <Skeleton />
              </div>
            );
          })}
      </>
    );
  }

  if (!isLoading && (announcements || []).length === 0) {
    return (
      <div
        style={{
          padding: "1rem",
        }}
      >
        No Announcements.
      </div>
    );
  }

  return (
    <div
      style={{
        padding: "1rem",
      }}
    >
      {(announcements || []).map((announcement) => {
        return (
          <div>
            <h2>{announcement.title}</h2>
            <p style={{ whiteSpace: "pre-wrap" }}>{announcement.description}</p>
            <p>
              (Posted on {moment.utc(announcement.createdAt).format("LL")} PST)
            </p>
          </div>
        );
      })}
    </div>
  );
}