import React from "react";
import { Avatar, Modal } from "antd";

// import IconClose from "../../../public/img/icon-close-modal-summit.svg";
// import Arrow from "../../../public/img/arrow-conference-Summit.svg";
// import CollapseArrow from "../../../public/img/arrowexpanmodules.svg"
// import IconLogo from "../../../public/img/logo-sidebar.svg";
// import ButtonsCalendar from "../../containers/templateCertificate/Button";
// import CollapseModules from "../../containers/templateCertificate/CollapseModules";

// import iconDown2 from "images/icon-linkedin.svg";

// import Image from "next/image";
// import CollapseComponent from "../CollapseComponent";
import { convertToLocalTime } from "../../../utils/format";
import moment from "moment";
// import MemberSpeakers from "../../containers/templateEvent/MembersSpeakers";
// import downloadDropdownOptions from "../../containers/downloadDropdownOptions";
// import { SpeakerProps } from "../../../interfaces/speakers";
// import { Context } from "../../../context";
// import Style from "./index.module.css";
import "./style.scss";
// import Link from "next/link";
// import { INTERNAL_LINKS } from "../../../enum";
import CollapseModules from "../CollapseModules";
// import CollapseModules from "pages/CertificatePage/CollapseModules";

const PopupSpeakerSummit = ({
  speaker,
  // updatedEvent,
  open = false,
  funModal,
  // speakerId,
  // idConferenceRelation,
  // certificateSlug,
}) => {
  // const {
  //   // speakers, getAllPanelsOfOneUser,
  //   certificate,
  //   getAllClassesOfOneUser,
  // } = useContext(Context);
  // const [openModal, closeModal] = useState(false)

  // useEffect(() => {
  //   if (speaker.email) {
  //     getAllClassesOfOneUser(speaker.email, certificateSlug);
  //   }
  // }, [speakerId, idConferenceRelation, speaker]);

  let userTimezone = moment.tz.guess();
  if (userTimezone.includes("_")) {
    userTimezone = userTimezone?.split("_")?.join(" ");
  }

  const orderClassCertificates = (data) => {
    return Array.isArray(data) ? (data || []).sort((a, b) => {
      let aTime = convertToLocalTime(a?.startTime, a?.timezone).format("YYYYMMDDHHmm");
      let bTime = convertToLocalTime(b?.startTime, b?.timezone).format("YYYYMMDDHHmm");

      return Number(aTime) - Number(bTime);
    }) : [];
  }

  const allPanelsConcil = orderClassCertificates([{...speaker}])?.map((cl) => {

    return cl?.classes?.map((e,index) => {
      let startDate = convertToLocalTime(e?.startDate, e?.timezone);
      let startTime = convertToLocalTime(e?.startTime, e?.timezone);
      let endTime = convertToLocalTime(e?.endTime, e?.timezone);

      return (
        <CollapseModules
          title={e?.title}
          key={index}
          background={'transparent'}
          content={
            <div className={"container-collapse-module"} style={{background:'#e0e0e0',borderRadius:'15px', padding:'20px'}}>
                <div className={"container-collapse-module-content"}>
                  <div className={"left-segment-collapse"}>
                    <div style={{ margin: "0px", marginTop: "5px", marginBottom: "5px" }}>
                      <div className={"container-descriptionnn"} dangerouslySetInnerHTML={{__html: e?.descriptionForCertificate}}/>
                    </div>
                    <p style={{ margin: "0px", marginTop: "5px", marginBottom: "5px" }}>
                      <b>Session Date</b>:{` ${(startDate || moment())?.format("LL")}`}
                    </p>
                    <p style={{ margin: "0px", marginTop: "5px", marginBottom: "5px" }}>
                      <b>Session Start Time:</b> {(startTime || moment())?.format("HH:mm a")} {moment.tz.guess()}
                    </p>
                    <p style={{ margin: "0px", marginTop: "5px", marginBottom: "5px" }}>
                      <b>Session End Time:</b> {(endTime || moment())?.format("HH:mm a")} {moment.tz.guess()}
                    </p>
                  </div>
                </div>
                <div className={"container-background-subclass"} style={{background:'#fafafa'}}>
                  {e?.guestLectures?.map((panel, index2) => {
                    let startDate2 = convertToLocalTime(panel?.startDate, e?.timezone);
                    let startTime2 = convertToLocalTime(panel?.startTime, e?.timezone);
                    let endTime2 = convertToLocalTime(panel?.endTime, e?.timezone);
                    return (<CollapseModules
                      title={panel?.title}
                      background={'transparent'}
                      key={index2}
                      content={
                        <div className={"container-collapse-module"}>
                          <div className={"container-collapse-module-content"}>
                              <div className={"left-segment-collapse"}>
                                <div style={{ margin: "0px", marginTop: "5px", marginBottom: "5px" }}>
                                  <div className={"container-descriptionnn"} dangerouslySetInnerHTML={{__html: panel?.description}}/>
                                </div>
                                <p style={{ margin: "0px", marginTop: "5px", marginBottom: "5px" }}>
                                  <b>Session Date</b>:{` ${startDate2?.format("LL")}`}
                                </p>
                                <p style={{ margin: "0px", marginTop: "5px", marginBottom: "5px" }}>
                                  <b>Session Start Time:</b> {startTime2?.format("HH:mm a")} {moment.tz.guess()}
                                </p>
                                <p style={{ margin: "0px", marginTop: "5px", marginBottom: "5px" }}>
                                  <b>Session End Time:</b> {endTime2?.format("HH:mm a")} {moment.tz.guess()}
                                </p>
                              </div>
                          </div>
                      </div>
                      }
                    />)
                  })}
                </div>
            </div>
          }
        />
      )
    })
  })

  return (
    <Modal
      visible={open}
      footer={<></>}
      onCancel={() => funModal()}
      className={"modal-speaker-sssss"}
      // closeIcon={
      //   <img
      //     src={IconClose}
      //     alt="icon-close"
      //     className={"icon-close"}
      //   />
      // }
    >
      <div className={"container"}>
        <div className={"part-top"}>
          <div className={"img"}>
            {speaker?.img ? (
              <Avatar src={speaker?.img} alt="image-profile" className={"image-profile"} />
            ) : (
              <Avatar
                alt="picture-profile"
                className={"image-profile"}
                style={{
                  borderRadius: "50%",
                  fontSize: "30px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textTransform: "uppercase",
                }}
              >{`${speaker?.abbrName}`}</Avatar>
            )}
          </div>
          <div className={"text-right"}>
            <p className={"name"}>{`${speaker?.firstName} ${speaker?.lastName}`}</p>
            {/* <p className={"profession"}>{speaker?.titleProfessions}</p> */}
          </div>
        </div>
        <div className={"part-bottom"}>
          {/* <div className={"link"]}>
            {speaker?.personalLinks?.linkedin ? (
              <>
                <p style={{ userSelect: "none" }}>Link:</p> <p>{speaker?.personalLinks?.linkedin}</p>
              </>
            ) : (
              <>
                <p style={{ userSelect: "none" }}>Link:</p> <p>{speaker?.linkedin}</p>
              </>
            )}
          </div> */}
          {/* <div className={"biography"]}>
            <p>Biography:</p>
            <p>{speaker?.about}</p>
          </div> */}
        </div>
      </div>
      <div className={"title-agenda"}>
        <p style={{color:"#000"}} className={"title"}>{`${speaker?.firstName} ${speaker?.lastName}'s Class`}</p>
      </div>
      <div style={{width:'100%',height:'auto',marginTop:'40px'}}>{allPanelsConcil}</div>
      <div className={"background"}></div>
    </Modal>
  );
};

export default PopupSpeakerSummit;
