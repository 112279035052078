import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  // Select,
  // Form,
  Table,
  Avatar,
  Dropdown,
  Menu,
} from "antd";
import { notification } from "antd";
import { useLocation } from "react-router-dom";
import { filesPostSelector } from "redux/selectors/filesPostSelector";
import {
  editFilePost,
  removeFilePost,
  getAllFilePost,
  downloadFilePost,
} from "redux/actions/files-post-actions";

// import clsx from "clsx";
import { INTERNAL_LINKS } from "enum";
import moment from "moment";
// import { Modal, DatePicker } from "antd";
import {
  // CloseCircleOutlined,
  HistoryOutlined,
  FolderOpenOutlined,
  HomeOutlined,
  // FileDoneOutlined,
  PlusOutlined,
  // EyeOutlined,
  // EllipsisOutlined,
  InteractionOutlined,
  DownloadOutlined,
  CloseOutlined,
  // CheckOutlined,
  // DownOutlined
} from "@ant-design/icons";

// import {
//   CustomInput,
//   // FroalaEdit,
//   ImageUpload,
//   CustomSelect,
// } from "components";

// import { Tabs } from "components";
// import { Link } from "react-router-dom";
import NoItemsMessageCard from "components/NoItemsMessageCard";
import { homeSelector } from "redux/selectors/homeSelector";
import { CustomButton, CustomModal } from "components";
import { categorySelector } from "redux/selectors/categorySelector";
import { envSelector } from "redux/selectors/envSelector";
import { notificationEmailToNewContentCreators } from "redux/actions/channel-actions";
import { ReactComponent as IconPencil } from "images/icon-pencil.svg";
import { ReactComponent as IconShowPassword } from "images/icon-show-password.svg";
// import { ReactComponent as IconTrash } from "images/icon-trash.svg";

import "./style.scss";
import { Link } from "react-router-dom/cjs/react-router-dom";
import FormCreateOrEditResource from "./FormCreateOrEditResource";

const ResourcesForUser = ({
  // isOwner,
  // isEditor,
  userProfile,
  // s3Hash,
  isMobile,
  siderMenuCollapsed,
  removeFilePost,
  allFilePosts,
  editFilePost,
  getAllFilePost,
  downloadFilePost,
  // limit = "all",
  // buttomEdit
}) => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const [currentTab, setCurrentTab] = useState(query.get("tab") || "0");
  // const [visibleModal, setVisibleModal] = useState(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [editOrDeleteResource, setEditOrDeleteResource] = useState({});
  const [functionForm, setFunctionForm] = useState({ function: () => {} });
  const [campsCase, setCampsCase] = useState([]);
  // const id = query?.get("id");
  // const accepted = query?.get("accepted");
  const [bulAdminResources, setBulAdminResources] = useState(2);
  const [collapsed, setCollapsed] = useState(
    isMobile ? siderMenuCollapsed : false
  );

  useEffect(() => {
    setCollapsed(isMobile ? siderMenuCollapsed : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  const handleCollapse = () => {
    setCollapsed((prev) => !prev);
  };

  useEffect(() => {
    if (isMobile) {
      const buttonCollapse = document.getElementById("main-header-left-menu");

      const handleClick = () => {
        handleCollapse();
      };

      buttonCollapse.addEventListener("click", handleClick);

      return () => {
        buttonCollapse.removeEventListener("click", handleClick);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapsed]);

  useEffect(() => {
    getAllFilePost();
  }, [getAllFilePost]);

  useEffect(() => {
    if (currentTab === "0") {
      setCampsCase([
        {
          title: "Published / Shedule",
          dataIndex: "status",
          key: "status",
          render: (text, d) => {
            return (
              <h5>
                {d.datePublic !== "" && d.status === "draft"
                  ? "Shedule"
                  : "published"}
              </h5>
            );
          },
        },
      ]);
    }
    if (currentTab === "1") {
      setCampsCase([
        {
          title: "Status for aprove",
          dataIndex: "statusRequest",
          key: "datePublic",
          render: (text) => (
            <h5>
              {text === "waiting"
                ? "pending"
                : text === "reject"
                ? "rejected"
                : text}
            </h5>
          ),
        },
      ]);
    }
    if (currentTab === "2") {
      setCampsCase([]);
    }
  }, [currentTab]);

  const onCancelModal = () => {
    setEditOrDeleteResource({});
    setVisibleDeleteModal(false);
  };

  const onCloseSidebar = () => {
    if (isMobile) {
      setCollapsed((prev) => !prev);
    }
  };

  const handleDeleteResource = (resourcePostId) => {
    removeFilePost(resourcePostId, (error) => {
      if (error) {
        return notification.error({
          message: error,
        });
      }
      getAllFilePost();

      return notification.success({
        message: "Resource Deleted Successfully",
      });
    });

    setVisibleDeleteModal(false);
    setEditOrDeleteResource({});
  };

  const filterResources = (data, type) => {
    let arrayOrderTime = data.sort((a, b) => {
      let aTime = moment(a.startDate, "YYYYMMDDHHmm").format("YYYYMMDDHHmm");
      let bTime = moment(b.startDate, "YYYYMMDDHHmm").format("YYYYMMDDHHmm");

      return Number(bTime) - Number(aTime);
    });

    let bulAdmin = (userProfile?.email === "morecontrol64@gmail.com" || userProfile?.email === "enrique@hackinghr.io" || userProfile?.email === "erubio2013@gmail.com" || userProfile?.email === "barbara@hackinghr.io" || userProfile?.email === "nahema.cossette2702@gmail.com")

    if (type !== "pending") {
      return arrayOrderTime?.filter((resource) => {
        if (type === "published") {
          return (
            (bulAdmin ? true : resource?.owner === userProfile?.email) &&
            resource?.status === "published" &&
            resource.statusRequest === "accepted"
          );
        } else {
          return (
            resource?.owner === userProfile?.email &&
            resource?.status === "draft"
          );
        }
      });
    } else {
      return arrayOrderTime?.filter((resource) => {
        return (
          (bulAdmin ? true : resource?.owner === userProfile?.email) &&
          (resource.statusRequest === "pending" ||
            resource.statusRequest === "waiting" ||
            resource.statusRequest === "reject") &&
          resource?.status !== "draft"
        );
      });
    }
  };

  const changeStatusResource = (e, idS) => {
    e.preventDefault();
    e.stopPropagation();

    editFilePost({ statusRequest: e.target.textContent, id: idS }, (error) => {
      if (error) {
        return notification.error({
          message: error,
        });
      }
      getAllFilePost();

      return notification.success({
        message: "Resource Updated Successfully",
      });
    });
  };

  const onPreviuwData = (data) => {
    localStorage.setItem("previuwR", JSON.stringify(data));

    window.open(`${INTERNAL_LINKS.RESOURCE}`, "_blank");
  };

  useEffect(() => {

    if(userProfile?.email === "morecontrol64@gmail.com" || userProfile?.email === "enrique@hackinghr.io" || userProfile?.email === "erubio2013@gmail.com" || userProfile?.email === "barbara@hackinghr.io" || userProfile?.email === "nahema.cossette2702@gmail.com"){
      setBulAdminResources(0)

      setTimeout(() => {
        setBulAdminResources(1);
        setTimeout(() => {
          setBulAdminResources(2);
        }, 1000);
      }, 1000);
    }
  }, [userProfile, setBulAdminResources]);

  const downloadDropdownOptions = (id) => (
    <Menu style={{ position: "relative", bottom: "70px" }}>
      <Menu.Item key="1">
        <a
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            background: "#00b574",
            color: "white",
            fontWeight: "bold",
          }}
          href="/#"
          onClick={(e) => changeStatusResource(e, id)}
        >
          accepted
        </a>
      </Menu.Item>
      <Menu.Item key="2">
        <a
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            background: "#e61e47",
            color: "white",
            fontWeight: "bold",
          }}
          href="/#"
          onClick={(e) => changeStatusResource(e, id)}
        >
          reject
        </a>
      </Menu.Item>
      <Menu.Item key="3">
        <a
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            background: "#ff9f29",
            color: "white",
            fontWeight: "bold",
          }}
          href="/#"
          onClick={(e) => changeStatusResource(e, id)}
        >
          pending
        </a>
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "Post Code",
      dataIndex: "id",
      key: "id",

      render: (id) => (
        <p
          style={{ fontWeight: 600, fontSize: "15px", minWidth: "maxContent" }}
        >
          {id}
        </p>
      ),
    },
    {
      title: "Thumb",
      dataIndex: "documentFileImageUrl",
      key: "documentFileImageUrl",
      render: (url) => <Avatar shape="square" size={55} src={url} />,
    },
    {
      title: "Headline",
      width: 200,
      dataIndex: "name",
      key: "name",
      render: (text) => <h5>{text}</h5>,
    },
    ...campsCase,
    {
      title: "Manage Content",
      dataIndex: "id",
      key: "manageContent",
      render: (id, data) => (
        <div
          style={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <CustomButton
            style={{
              background: "#F75B27",
              borderColor: "#F75B27",
            }}
            onClick={() => {
              setEditOrDeleteResource(data);
              setCurrentTab("3");
            }}
            type="primary"
            icon={<IconPencil fill="white" />}
            size={"medium"}
          />
          <CustomButton
            style={{
              background: "rgb(0, 181, 116)",
              borderColor: "rgb(0, 181, 116)",
            }}
            onClick={() => {
              downloadFilePost(data.id, data.name);
            }}
            type="primary"
            icon={
              <DownloadOutlined
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: "22px",
                  transform: "translateY(-2px)",
                }}
                fill="white"
              />
            }
            size={"medium"}
          />
          <CustomButton
            style={{
              background: "rgb(0, 181, 116)",
              borderColor: "rgb(0, 181, 116)",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              onPreviuwData(data);
            }}
            type="primary"
            icon={<IconShowPassword fill="white" />}
            size={"medium"}
          />

          {((userProfile?.email === "morecontrol64@gmail.com" || userProfile?.email === "enrique@hackinghr.io" || userProfile?.email === "erubio2013@gmail.com" || userProfile?.email === "barbara@hackinghr.io" || userProfile?.email === "nahema.cossette2702@gmail.com") && currentTab !== 2) && (
            <Dropdown overlay={() => downloadDropdownOptions(id)}>

                <a
                  href="/#"
                  style={{
                    width: "32px",
                    height: "32px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#0080DC",
                    borderColor: "#0080DC",
                    borderRadius: "5px",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <InteractionOutlined
                    style={{ fontSize: "24px", color: "white" }}
                  />
                </a>
              </Dropdown>
            )}
        </div>
      ),
    },
  ];

  const TabData = [
    {
      content: () => (
        <>
          <Table
            columns={columns}
            dataSource={filterResources(allFilePosts, "published")}
            pagination={{ position: ["bottomCenter"] }}
          />
        </>
      ),
    },
    {
      content: () => (
        <>
          <Table
            columns={columns}
            dataSource={filterResources(allFilePosts, "pending")}
            pagination={{ position: ["bottomCenter"] }}
          />
        </>
      ),
    },
    {
      content: () => (
        <>
          <Table
            columns={columns}
            dataSource={filterResources(allFilePosts, "draft")}
            pagination={{ position: ["bottomCenter"] }}
          />
        </>
      ),
    },
    {
      content: () => (
        <FormCreateOrEditResource
          getAllFilePost={getAllFilePost}
          editOrDeleteResource={editOrDeleteResource}
          setEditOrDeleteResource={(data) => setEditOrDeleteResource(data)}
          setCurrentTab={(data) => setCurrentTab(data)}
          resetData={(data) => {
            setFunctionForm(data);
          }}
        />
      ),
    },
  ];

  return (
    <div className="resource-post__list-wrap" style={{ background: "white" }}>
      <>
        <CustomModal
          visible={visibleDeleteModal}
          title={`Are you sure to delete this resource?`}
          subTitle="this option is not reversible"
          onCancel={() => setVisibleDeleteModal(false)}
          width={500}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CustomButton
              type="primary outlined"
              text="No"
              onClick={() => onCancelModal()}
            />
            <CustomButton
              type="primary"
              text="Yes"
              style={{ marginLeft: "5px" }}
              onClick={() => handleDeleteResource(editOrDeleteResource?.id)}
            />
          </div>
        </CustomModal>
        {!userProfile.acceptedForBlogPostUser ? (
          <NoItemsMessageCard
            message={`you need permissions for this section`}
          />
        ) : (
          <>
            <div
              className={`container-sidebar ${
                isMobile && collapsed ? "sidebar-collapsed" : ""
              }`}
            >
              <Link to={INTERNAL_LINKS.HOME}>
                <div className="container-logo"></div>
              </Link>
              {isMobile && (
                <div className="layout-sidebar-close">
                  <CloseOutlined onClick={onCloseSidebar} />
                </div>
              )}
              <div className="container-tabs">
                <div
                  className={
                    currentTab === "0"
                      ? "container-iconsps"
                      : "container-iconsp"
                  }
                  onClick={() => {
                    setCurrentTab("0");
                    onCloseSidebar();
                  }}
                >
                  <HomeOutlined style={{ margin: "0px", marginRight: "5px" }} />{" "}
                  Published
                </div>
                <div
                  className={
                    currentTab === "1"
                      ? "container-iconsps"
                      : "container-iconsp"
                  }
                  onClick={() => {
                    setCurrentTab("1");
                    onCloseSidebar();
                  }}
                >
                  <HistoryOutlined
                    style={{ margin: "0px", marginRight: "5px" }}
                  />{" "}
                  Pending
                </div>
                <div
                  className={
                    currentTab === "2"
                      ? "container-iconsps"
                      : "container-iconsp"
                  }
                  onClick={() => {
                    setCurrentTab("2");
                    onCloseSidebar();
                  }}
                >
                  <FolderOpenOutlined
                    style={{ margin: "0px", marginRight: "5px" }}
                  />{" "}
                  Draft
                </div>
                <div
                  className="container-iconspsb"
                  onClick={() => {
                    setCurrentTab("3");
                    localStorage.setItem("contentZ", "[]");
                    functionForm.function();
                    onCloseSidebar();
                  }}
                >
                  <PlusOutlined style={{ margin: "0px", marginRight: "5px" }} />{" "}
                  Add Resources
                </div>
              </div>
            </div>
            <div
              className="container-tables"
              id="container-scroll"
              style={{
                marginLeft: isMobile ? "0px" : "250px",
                width: isMobile ? "100%" : "calc(100% - 250px)",
              }}
            >
              <div style={{ height: "auto", width: "100%" }}>
                {TabData[currentTab]?.content()}
              </div>
              <div id="container-see-finish"></div>
            </div>
            <div className="container-modals-image">
              <div className="container-distribution"></div>
            </div>
          </>
        )}
      </>
      <div
        className="container-modal-background"
        style={
          bulAdminResources === 2
            ? { display: "none" }
            : bulAdminResources === 0
            ? { opacity: "1" }
            : { opacity: "0" }
        }
      >
        <div className="container-modal-message">
          <p>Welcome Admin!!</p>
        </div>
      </div>
    </div>
  );
};

ResourcesForUser.propTypes = {
  filter: PropTypes.object,
};

ResourcesForUser.defaultProps = {
  filter: {},
};

const mapStateToProps = (state) => ({
  userProfile: homeSelector(state).userProfile,
  allCategories: categorySelector(state).categories,
  s3Hash: envSelector(state).s3Hash,
  allFilePosts: filesPostSelector(state).allFilePosts,
  isMobile: envSelector(state).isMobile,
  siderMenuCollapsed: envSelector(state).siderMenuCollapsed,
});

const mapDispatchToProps = {
  notificationEmailToNewContentCreators,
  getAllFilePost,
  editFilePost,
  removeFilePost,
  downloadFilePost,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResourcesForUser);
