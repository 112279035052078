import React from "react";
import PropTypes from "prop-types";
// import { useLocation } from "react-router-dom";

import clsx from "clsx";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
// import { SpecialtyItem } from "components";
import { useHistory } from 'react-router-dom';
import { Link } from "react-router-dom";
import { transformNames } from "utils/format";
import { CARD_TYPE, INTERNAL_LINKS } from "enum";
import { Avatar } from "antd";
import { categorySelector } from "redux/selectors/categorySelector";

import { ReactComponent as IconPlus } from "images/icon-plus.svg";
import IconLogo from "images/logo-sidebar.svg";
// import Save1 from "images/save1.svg"
// import Save2 from "images/save2.svg"

import "./style.scss";
import moment from "moment";

const BlogCard2 = ({
  id,
  title,
  type,
  summary,
  onAdd,
  onMenuClick,
  image,
  data,
  // allCategories,
  // categories,
  userProfile,
  date,
  statusRequest,
  isOwner,
  isEditor,
  isDraft,
  isBlogging,
  saveBlogPost = () => {},
}) => {

  const location = useLocation();
  const history = useHistory();

  let thirdCondition = (isDraft) ? '#' : `${location.pathname}${INTERNAL_LINKS.BLOGS_NEW}/${id}`
  let fiveCondition = (isDraft) ? '#' : `${INTERNAL_LINKS.BLOGS_NEW}/${id}`

  let idProd = [205,30974,209,40826,41804]

  
  const functionCaseTeam = () => {
    if(data?.aut?.firstName){
      return `${transformNames(data?.aut?.firstName || userProfile?.img)} ${transformNames(data?.aut?.lastName || userProfile?.img)}`
    }

    if (!idProd.includes(data?.owner?.id)) {
      return `${transformNames(data?.aut?.firstName || data?.owner?.firstName || userProfile?.firstName || "Indira")} ${transformNames(data?.aut?.lastName || data?.owner?.lastName || userProfile?.lastName || "Shree")}`;
    } else {
      return "Hacking HR Team";
    }
  };

  return (
    <div
        className={clsx("blog-card", { add: type === CARD_TYPE.ADD })}
     >
      <Link
        to={
          type === CARD_TYPE.ADD
            ? "#"
            : location.pathname.includes("channels")
              ? (window.location.pathname.substring(0, 15) === INTERNAL_LINKS.BLOGS_NEW) ? "#" : thirdCondition
              : (window.location.pathname.substring(0, 15) === INTERNAL_LINKS.BLOGS_NEW) ? "#" : fiveCondition
        }
        onClick={() => {
          if(isBlogging){
            localStorage.setItem("returnBlogging", 'true')
          }
          if (onAdd) {
            if (isDraft) {
              onMenuClick('edit', id)
            } else {
              onAdd(id)
            }
          }
        }}
        className="blog-card-link-container"
      >
        {type === CARD_TYPE.ADD ? (
          <div className="blog-card-container">
            <IconPlus />
          </div>
        ) : (
          <>
            <div className="blog-card-header">
              {data.image2 ? (
                <img fill={true} objectFit="cover" src={data.image2} alt={data?.altText || ''} />
              ) : (
                <div className="blog-card-header-default" />
              )}
            </div>
            <div className="blog-card-content">
              <div className="blog-card-content-date">
                <span>{!data?.datePublic ? moment(date).format("ll") : ``}</span>
              </div>
              <h3 className="blog-card-title" >{title}</h3>
              {isDraft &&
                (!data?.datePublic ? (
                  <span>Saved as a draft</span>
                ) : (
                  <span>
                    Scheduled for {moment(data?.datePublic, "YYYY-MM-DD HH:mm").startOf("hour").format("MM/DD/YYYY")} at{" "}
                    {moment(data?.datePublic, "YYYY-MM-DD HH:mm").startOf("hour").format("HH:mm a")} (Pacific Time)
                  </span>
                ))}
              {statusRequest && <span>{statusRequest}</span>}
              <div className="d-flex items-center">
                <p style={{ color: "#0009" }}>{summary}</p>
              </div>
            </div>
            <div className="blog-card-footer" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                history.push(`${INTERNAL_LINKS.PROFILE}/${!data?.id 
                  ? (userProfile?.id) 
                  : (data?.aut?.id || data?.owner?.id || userProfile?.id || "")}`
                )
              }}>
              {!idProd.includes(userProfile.id) ? (<Avatar src={!data?.id 
                ? (userProfile?.img) 
                : (data?.aut?.img || data?.owner?.img || userProfile?.img || "")
              } size="large" alt={"user-avatar"} className="blog-card-footer-user-img" />) :
              (<img width={40} height={40} src={IconLogo} alt="user-avatar" className="blog-card-footer-user-img"/>)}
              <div>
                <h5>{functionCaseTeam()}</h5>
                <h6 style={{ color: "#0009" }}>{!idProd.includes(userProfile.id) ? userProfile?.titleProfessions : ""}</h6>
              </div>
            </div>
            <div className="blog-card-actionss" onClick={(e) => {e.preventDefault(); e.stopPropagation();}}>
              <div className={`block-action ${data?.saveBlogsForUser ? 'save' : 'unsave'}`} onClick={() => saveBlogPost({idBlog: data?.id})}></div>
            </div>
          </>
        )}
      </Link>
    </div>
  );
};

BlogCard2.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  summary: PropTypes.string,
  image: PropTypes.string,
  categories: PropTypes.array,
  type: PropTypes.string,
  onAdd: PropTypes.func,
  date: PropTypes.string,
  isOwner: PropTypes.bool,
  isEditor: PropTypes.bool,
  isDraft: PropTypes.bool,
  onMenuClick: PropTypes.func,
};

BlogCard2.defaultProps = {
  title: "",
  summary: "",
  type: CARD_TYPE.VIEW,
  onAdd: () => { },
  categories: [],
  isOwner: false,
  isEditor: false,
  isDraft: false,
  date: `${moment().format("MM/DD/YYYY")}`,
  onMenuClick: () => { },
};

const mapStateToProps = (state) => ({
  allCategories: categorySelector(state).categories,
});

export default connect(mapStateToProps)(BlogCard2);
