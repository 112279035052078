export default [
    {
        label: "Messages",
        value: 3,
    },
    {
        label: "Other Challenges",
        value: 2,
    },
    {
        label: "Participants",
        value: 1,
    },
    {
        label: "Overview",
        value: 0,
    },
];