import React from "react";

const gradIcon = ({color = 'black', width = '24px', height = '24px'}) => {
  return (
    <div style={{width:width,height:height}}>
        <svg width="55" height="39" viewBox="0 0 55 39" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.9872 36.9995L5.62921 24.3804M9.07832 36.9995L7.4792 24.3804M13.0477 20.5872L11.9259 30.0027C11.7821 30.7513 11.8051 32.4732 13.0477 33.3715C14.6009 34.4945 20.0372 36.9995 27.6307 36.9995C35.2242 36.9995 39.3662 35.6331 41.696 33.3715C42.9041 32.1989 43.0421 31.1775 42.904 30.0027C42.766 28.8279 42.0411 23.2362 41.696 20.5872M27.6307 9.01223C26.4226 9.1562 25.646 9.78966 21.5041 9.78966C18.978 9.78966 10.0451 8.20374 6.67978 19.2604M6.67978 19.2604C4.67416 19.2604 3.81461 20.3108 3.81461 21.9693C3.81461 23.6278 5.00843 24.4722 6.39326 24.4722C8.39888 24.4722 9.01966 23.8028 9.01966 22.0815C9.01966 20.5872 8.44663 19.2604 6.67978 19.2604ZM3.9872 13.2449L24.2654 20.5872C25.3009 20.9039 27.9759 21.3474 30.392 20.5872C32.8081 19.8271 45.5503 15.3756 51.6194 13.2449C52.0796 13.1009 53 12.5711 53 11.6036C53 10.6362 52.0796 10.2216 51.6194 10.1352L30.392 2.5337C29.2127 2.13059 26.3363 1.56624 24.2654 2.5337C22.1944 3.50116 9.88369 7.77411 3.9872 9.78966C3.3544 10.02 2.07156 10.7053 2.00253 11.6036C1.93349 12.502 3.29688 13.0721 3.9872 13.2449Z" stroke={color} stroke-width="3" stroke-linecap="round"/>
        </svg>
    </div>
  );
};

export default gradIcon;
