export default {
  EVENT_VIEW_PROFILE: "EVENT_VIEW_PROFILE",
  OPEN_PAYMENT_MODAL: "OPEN_PAYMENT_MODAL",
  OPEN_FILTER_PANEL: "OPEN_FILTER_PANEL",
  MEMBER_CHANGED: "MEMBER_CHANGED",
  EVENT_CHANGED: "EVENT_CHANGED",
  OPEN_ATTENDANCE_DISCLAIMER: "OPEN_ATTENDANCE_DISCLAIMER",
  OPEN_EVENT_FILTER_DRAWER: "OPEN_EVENT_FILTER_DRAWER",
  MY_PAST_EVENT_CHANGED: "MY_PAST_EVENT_CHANGED",
  OPEN_SHARE_CONTENT: "OPEN_SHARE_CONTENT",
  OPEN_COUNCIL_SHARE_CONTENT: "OPEN_COUNCIL-SHARE-CONTENT",
  OPEN_CHANNELS_FILTER_PANEL: "OPEN_CHANNELS_FILTER_PANEL",
  OPEN_INVITE_FRIEND_MODAL: "OPEN_INVITE_FRIEND_MODAL",
  OPEN_BLOGS_POST_FILTER_PANEL: "OPEN_BLOGS_POST_FILTER_PANEL",
  OPEN_POST_MODAL: "OPEN_POST_MODAL",
  CLOSE_POST_MODAL: "CLOSE_POST_MODAL",
  SHOW_FIREWALL: "SHOW_FIREWALL",
};
