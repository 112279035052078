import { handleActions } from "redux-actions";
import { Map } from "immutable";

import { constants as filesContants } from "../actions/files-post-actions";

export const reducers = {
    [filesContants.SET_ALL_FILE_POST]: (state, { payload }) => {
        return state.merge({
            allFilePosts: payload.filePosts,
        });
    },
    [filesContants.SET_NEW_POST_FILE_TO_ARRAY]: (state, { payload }) => {
        const allFilePosts = state.get("allFilePosts");
        const { filePost } = payload

        return state.merge({
            allFilePosts: [filePost, ...allFilePosts],
        });
    }
};

export const initialState = () => {
    return Map({
        allFilePosts: []
    });
};

export default handleActions(reducers, initialState());