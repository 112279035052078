import { createSelector } from "reselect";

const filesPostReducer = (state) => state.files;

const resultSelector = createSelector(filesPostReducer, (payload) => ({
    allFilePosts: payload.get("allFilePosts"),
}));

export const filesPostSelector = (state) => ({
    ...resultSelector(state),
});