import { createAction } from "redux-actions";

const LOGIN = "LOGIN";
const SIGNUP = "SIGNUP";
const LOGOUT = "LOGOUT";
const SET_AUTH = "SET_AUTH";
const CHECKSESSION = "CHECKSESSION";

export const constants = {
  LOGIN,
  LOGOUT,
  SIGNUP,
  SET_AUTH,
  CHECKSESSION,
};

// ------------------------------------
// Actions
// ------------------------------------
export const login = createAction(LOGIN, (email, password, callback, bulCase) => ({
  email,
  password,
  callback,
  bulCase,
}));
export const checkSession = createAction(CHECKSESSION, (email,code,callback) => ({email,code,callback}))
export const logout = createAction(LOGOUT, () => ({}));
export const setAuth = createAction(SET_AUTH);
export const signUp = createAction(SIGNUP, (payload, callback) => ({payload,callback}));

export const actions = {
  login,
  logout,
  signUp,
  setAuth,
  checkSession
};
