import React from "react";

import "./style.scss";

class Signup extends React.Component {
  render() {
    return (
      <div className="signup-page">
        Signup Page
      </div>
    );
  }
}

export default Signup;
