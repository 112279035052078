import { io } from "socket.io-client";

const ENDPOINT = process.env.REACT_APP_API_ENDPOINT_INSTANCE;

let socketInstance = null;
// const ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const createSocketConnection = (callback = () => {}) => {
  try{
    if (socketInstance) {
      if (callback) callback();
      return;
    }

    if (localStorage.getItem("community") !== null) {
        socketInstance = io(ENDPOINT, {transports: ['websocket']});

        // Manejar eventos de conexión
        socketInstance.on('connect', () => {
            console.log('Conexión establecida con éxito');
            if (callback) callback();
        });

        // Manejar eventos de desconexión
        socketInstance.on('disconnect', () => {
            console.log('Desconectado del servidor Socket.io');
            socketInstance = null;
        });

        // Manejar eventos de errores
        socketInstance.on('connect_error', (error) => {
          console.error('Error en la conexión de Socket.io:', error);
        });

    }else {
      if (callback) callback();
    }
  }catch(er){
    console.log(er)
  }
};

const SocketIO = {

  disconnect: () => {
    createSocketConnection(() => {
      if(socketInstance){
        socketInstance.disconnect();
      }
    })
  },

  connect: () => {
    createSocketConnection();
  },

  off: () => {
    if (socketInstance) {
      socketInstance.disconnect();
      socketInstance = null;
    }
  },

  on: (event, fn) => {
    createSocketConnection(() => {
      if (socketInstance) {
        socketInstance.on(event, fn);
      }
    });
      
  },

  emit: (event, data) => {
    createSocketConnection(() => {
      if (socketInstance) {
        socketInstance.emit(event, data);
      }
    })
  }   
}

export default SocketIO;