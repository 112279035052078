import React, {
  useEffect,
  useState,
  useRef
} from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import {
  // Col, 
  Pagination,
  Select
  // Row 
} from "antd";
import { envSelector } from "redux/selectors/envSelector";
import { blogPostSelector } from "redux/selectors/blogPostSelector";
import { categorySelector } from "redux/selectors/categorySelector";
import { homeSelector } from "redux/selectors/homeSelector";
import {
  searchBlogPosts,
  setCurrentPage,
  saveBlogPost
} from "redux/actions/blog-post-action";
// import BlogsFilterPanel from "./BlogsFilterPanel";
// import FilterDrawer from "./FilterDrawer";
// import Emitter from "services/emitter";
import {
  BlogCard2
} from "components"

import "./style.scss";
import { TAXONOMYS_SKILLS } from "enum";

const Blogs = ({
  blogsPosts,
  searchBlogPosts,
  currentPage,
  setCurrentPage,
  userProfile,
  totalBlogPosts,
  countTaxonomys,
  saveBlogPost,
  isMobile
}) => {
  const [checkBoxResponse, setCheckBoxResponse] = useState([])
  const [showFilter,setShowFilters] = useState(isMobile ? false : true)
  const [text,setText] = useState('')
  const [checkboxData, setCheckboxData] = useState([])
  const [tab, setTab] = useState(1)
  const clock = useRef(null)

  useEffect(() => {
    let taxonomys = Object.keys(TAXONOMYS_SKILLS)
    setCheckboxData(taxonomys)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    setShowFilters(isMobile ? false : true)
  },[isMobile])

  useEffect(() => {
    let taxonomys = Object.keys(TAXONOMYS_SKILLS)
    setCheckboxData(taxonomys)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    searchBlogPosts({
      taxonomys: checkBoxResponse,
      text,
      idUser: userProfile?.id,
      onlySaves: tab === 1 ? false : true,
    },currentPage); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[checkBoxResponse,userProfile,tab])

  const searchForText = () => {
    searchBlogPosts({
      taxonomys: checkBoxResponse,
      text,
      idUser: userProfile?.id,
      onlySaves: tab === 1 ? false : true,
    }, currentPage);  
  }

  const orderBlogPostConfirm = (blogs) => {

    let arrayOrderTime = blogs.sort((a, b) => {

      let aTime = moment(a.createdAt, "YYYYMMDDHHmm").format("YYYYMMDDHHmm")
      let bTime = moment(b.createdAt, "YYYYMMDDHHmm").format("YYYYMMDDHHmm")

      return Number(bTime) - Number(aTime)

    })

    if(userProfile?.email !== "enrique@hackinghr.io" && userProfile?.email !== "erubio2013@gmail.com"){
      arrayOrderTime = arrayOrderTime.filter((a) => {
        return Number(a.UserId) !== 205 && Number(a.UserId) !== 207
      })
    }

    return arrayOrderTime
  }

  const checkboxContainer = checkboxData?.map((data) => {
    return {
      label: (
        <div className="container-select-t">
          <p>{data}</p>
          <div className="container-number">{countTaxonomys[data]}</div>
        </div>
      ),
      value: data,
    }
  })

  const filterOption = (input, option) => (option?.value ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <div className="blogs-page">
      {/* <BlogsFilterPanel onChange={onFilterChange} /> */}
      {/* <FilterDrawer onChange={onFilterChange} /> */}
      <div style={{display: "flex",flexDirection: "row",justifyContent: "flex-start",alignItems: "center",gap: "20px",marginLeft:'35px'}}>
          <div
            className={tab === 1 ? "title-list-blogs" : "title-list-blogs2"}
            onClick={() => setTab(1)}
          >
            All Blogs
        </div>
        <div
          className={tab === 2 ? "title-list-blogs": "title-list-blogs2"}
          onClick={() => setTab(2)}
        >
          My Blogs
        </div>
      </div>
      <div className="blogs-page__container">
        <div className={showFilter ? "filters-blogs" : "filters-blogs-h"} style={isMobile ? {position:'absolute',height:'100vh'} : {position:'relative'}}>
          <div className={"container-blogs-searchtext"}>
            <h2>Search by Category</h2>
            <p>Select the category on the dropdown menu.</p>
          </div>
          <div className={"container-search-keywords"}>
            <h2>Search by Keywords</h2>
            <p>Type the keywords related to the topics of your interest.</p>
            <div className={"container-input-search"}>
              <div></div>
              <input placeholder='Search Article' onChange={(e) => {
                setText(e.target.value)
              }} />
            </div>
            <div style={{cursor:'pointer'}} className="button-search-key" onClick={() => searchForText()} >Search</div>
          </div>
          <div className={"container-br"}></div>
          <div className={"container-post-categories"}>
            <Select
              mode="multiple"
              showSearch
              optionFilterProp="children"
              placeholder={'Select category'}
              size="large"
              filterOption={filterOption}
              style={{width:'100%'}}
              options={checkboxContainer}
              onChange={(e) => setCheckBoxResponse(e)}
            />
          </div>
        </div>
        {isMobile && <div className="container-expand-data" onClick={() => setShowFilters(!showFilter)}>
          <div className="filter-icon"></div>
        </div>}
        <div className="search-results-container">
          {/* <Row>
            <Col span={24}>
              <div className="search-results-container-mobile-header">
                <h3 className="filters-btn" onClick={() => showFilterPanel()}>
                  Filters
                </h3>
              </div>
            </Col>
          </Row> */}

          <div className="blogs-list">
            {orderBlogPostConfirm(blogsPosts
              ?.filter((blogPost) => blogPost.status === "published" && blogPost.statusRequest === 'accepted'))
              ?.map((blogPost) => (
                <BlogCard2
                  data={blogPost}
                  key={blogPost?.id}
                  id={blogPost?.id}
                  image={blogPost?.imageUrl}
                  date={blogPost?.createdAt}
                  title={blogPost?.title}
                  summary={blogPost?.summary}
                  userProfile={userProfile}
                  saveBlogPost={saveBlogPost}
                />
              ))}
          </div>

          <div className="blogs-pagination" style={{paddingBottom:'100px'}}>
            <Pagination
              defaultCurrent={1}
              current={currentPage}
              showSizeChanger={false}
              total={(Math.ceil(Number(totalBlogPosts) / 20)) * 10}
              onChange={(value) => {
                setCurrentPage(value);
                if(clock.current){
                  clearTimeout(clock.current)
                }

                clock.current = setTimeout(() => {
                  searchBlogPosts({
                    taxonomys: checkBoxResponse,
                    text,
                    idUser: userProfile?.id,
                    onlySaves: tab === 1 ? false : true,
                  }, value);  
                }, 200);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userProfile: homeSelector(state).userProfile,
  allCategories: categorySelector(state).categories,
  currentPage: blogPostSelector(state).currentPage,
  blogsPosts: blogPostSelector(state).blogsPosts,
  totalBlogPosts: blogPostSelector(state).totalBlogPosts,
  isMobile: envSelector(state).isMobile,
  countTaxonomys: blogPostSelector(state).countTaxonomys,
});

const mapDispatchToProps = {
  searchBlogPosts,
  setCurrentPage,
  saveBlogPost
};

export default connect(mapStateToProps, mapDispatchToProps)(Blogs);
