import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";

import "./style.scss";
import { homeSelector } from "redux/selectors/homeSelector";
import queryString from "query-string";
import { debounce } from "lodash";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { INTERNAL_LINKS } from "enum";
import IconLoading from "images/icon-loading.gif";
import { getUser, confirmStatusPaid, getAllLicences, setUserInALicence, changeMembresy, deleteMembresy } from "redux/actions/home-actions";

import { Alert, Form, Select, Spin, notification, InputNumber, Switch, Button,Modal, Tooltip } from "antd";
import { loadStripe } from "@stripe/stripe-js";
import { envSelector } from "redux/selectors/envSelector";
import { actions as councilEventActions } from "redux/actions/council-events-actions";
import { councilEventSelector } from "redux/selectors/councilEventSelector";
import { CustomModal, CustomButton,CustomInput} from "components";
import { STRIPE_PRICES } from "enum";
import UserProfileModal from "containers/UserProfileModal";
import { getCheckoutSession } from "api/module/stripe";
import NoItemsMessageCard from "components/NoItemsMessageCard";
import { categorySelector } from "redux/selectors/categorySelector";
import { getOneUserById } from "api";
// import CollapseModules from "./CollapseModules";
import IconBack from "images/icon-back.svg";
import CardUser from "./CardUser";
import { convertToLocalTime } from "utils/format";
import {
    DeleteOutlined,
    PlusOutlined,
    ExclamationCircleOutlined,
  } from "@ant-design/icons";

const { confirm } = Modal;

const CorporateAdmin = ({
    userProfile,
    searchUserForCouncilEventPanelist, 
    searchedUsersForCouncilEvent, 
    getUser, 
    confirmStatusPaid, 
    location, 
    getAllLicences,
    licences,
    licencesPays,
    allCategories,
    setUserInALicence,
    changeMembresy,
    isMobile,
    deleteMembresy,
}) => {

    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const [form3] = Form.useForm();
    const [fetching, setFetching] = useState(false);
    const [tab,setTab] = useState(1)
    const [stripe, setStripe] = useState(null);
    const [bulModalSelectUsers, setBulModalSelectUsers] = useState(false)
    const [bulModalSelectUsers2, setBulModalSelectUsers2] = useState(false)
    const [bulModalSelectUsers3, setBulModalSelectUsers3] = useState(false)
    const [modalResponsePay, setModalResponsePay] = useState("")
    const [checkoutSessionError, setCheckoutSessionError] = useState(false);
    const [checkoutSessionErrorMsg, setCheckoutSessionErrorMsg] = useState("");
    const [relationEmail, setRelationEmail] = useState(false)
    const [idLicence, setIdLicence] = useState(-1)
    const [loading, setLoading] = useState(false);
    const [usersCount, setUsersCount] = useState({'0':''})
    const history2 = useHistory();
    const [numberSelect,setNumberSelect] = useState(0)
    const [arrayUsers,setArrayUsers] = useState([])
    const { refreshMore, refreshMoreBad } = queryString.parse(location.search);
    const formSelect = useRef(null)
    const formSelect2 = useRef(null)
    const formSelect3 = useRef(null)
    const [userProfileToShow, setUserProfileToShow] = useState({});
    const [activitys, setActivitys] = useState({})
    const [showProfile, setShowProfile] = useState(false);
    const [loadingg, setLoadingg] = useState(false)
    const num = useRef(0)
    const options = useRef({})

    const obtenerLicencias = () => {
        if(!loading){
            setLoadingg(true)
            getAllLicences(() => {
                setLoadingg(false)   
            }) 
        }
    }

    useEffect(() => {
        obtenerLicencias()
    },[])

    const getOneUser = async (id) => {
        const response = await getOneUserById(id);
        setUserProfileToShow(response?.data?.user || {});
        setShowProfile(true);
    };

    const handleSelectChange = (newValue, index) => {
        if (newValue.length > 1) {
            options.current = {...options.current,[index]:[newValue[1]]} 
            formSelect.current.setFieldsValue({
                [index]:{email: [newValue[1]]}
            });
        }else{
            options.current = {...options.current,[index]:newValue} 
        }
    };

    const handleSelectChange2 = (newValue) => {
        if (newValue.length > 1) {
            formSelect2.current.setFieldsValue({
                email: [newValue[1]]
            });
        }
    };

    useEffect(() => {
        if(refreshMoreBad && refreshMoreBad === 'true'){
          setModalResponsePay("Ouh, payment could not be processed")
          history2.push(INTERNAL_LINKS.CORPORATE_ADMIN)
        }
    },[refreshMoreBad])

    useEffect(() => {
        getUser()
    }, [])

    useEffect(() => {
        if((userProfile || {}).corporateAdmin !== undefined && (userProfile || {}).corporateAdmin !== "accepted"){
            history2.push(INTERNAL_LINKS.HOME);
        }
    },[userProfile])

    useEffect(() => {
        if(refreshMore && refreshMore === 'true' && userProfile?.firstName !== ''){
            setModalResponsePay("Wowza! Your team memberships have already been added! 🎉")
            setLoadingg(true)
            confirmStatusPaid({email: userProfile?.email},userProfile, () => {
                // console.log("jummmmmmmmm")
                obtenerLicencias()
                num.current = 0;
                options.current = {};
                setNumberSelect(0);
                setUsersCount({'0':''});
                history2.push(INTERNAL_LINKS.CORPORATE_ADMIN);
            })
        }
    },[refreshMore, userProfile])

    useEffect(() => {
        setFetching(false)
      },[searchedUsersForCouncilEvent])

    useEffect(() => {
        instanceStripe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    const instanceStripe = async () => {
        let dataF = loadStripe(process.env.REACT_APP_STRIPE_PK_KEY)
        let data = await dataF;
        setStripe(data);
    };

    const handleSearchUser = (values) => {
        const runDebounce = debounce(() => {
            setFetching(true)
            searchUserForCouncilEventPanelist(values);
        }, 500);

        runDebounce();
    };

    const requestCheckoutSessionTable = async ({
        isBuyMoreThatOneSuscription = false,
        emailUsers = [],
    }) => {
        setLoading(true);
        setCheckoutSessionError(false);
        setCheckoutSessionErrorMsg("");
        if(isBuyMoreThatOneSuscription === true){
          if(emailUsers){
            try {
              let sessionData2 = await getCheckoutSession({
                isBuyMoreThatOneSuscription: true,
                emailUsers: emailUsers,
                prices: [STRIPE_PRICES.STRIPE_PRICES[3].priceId],
                callback_url: `${process.env.REACT_APP_DOMAIN_URL}/corporate-admin/?refreshMore=true`,
                callback_error: `${process.env.REACT_APP_DOMAIN_URL}/corporate-admin/?refreshMoreBad=true`,
              });
            
              return stripe.redirectToCheckout({ sessionId: sessionData2.data.id });
            }catch(err){
              console.log(err)
              setLoading(false);
              setCheckoutSessionError(true);
              setCheckoutSessionErrorMsg(err?.response?.data?.msg);
              notification.error({
                message: "Error",
                description: err?.response?.data?.msg,
              });
            }
          }
        }
    
        
    };

    const handleOnFinishSelect = (value) => {

        let objectsUsers = []
        if(!relationEmail){
            for(let i = 0; i < value?.number; i++){
                objectsUsers = [...objectsUsers,{
                    email: '',
                    firstName: '',
                    lastName: ''
                }]
            }
        }else{
            objectsUsers = Object.values(value).map(e => {return {...e,email:e.email[0]}})

            const regexCorreo = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            if(!objectsUsers.every(user => regexCorreo.test(user?.email))){
                notification.error({
                    message: "Error",
                    description: "Please select a valid emails.",
                });
                return 
            }
        
        }
        
        if(licences.length === 0){
            if(objectsUsers.length < 5){
                notification.error({
                    message: "Error",
                    description: "Please select minimum five accounts.",
                });
                return 
            }
        }
        
        requestCheckoutSessionTable({
          premium: false,
          recruiter: false,
          creator: false,
          isBuyMoreThatOneSuscription: true,
          emailUsers: objectsUsers
        });
    }

    const handleOnFinishSelect2 = (value) => {

        setUserInALicence({
            email:value.email[0],
            firstName: value.firstName,
            lastName: value.lastName,
            id: idLicence
        },() => {
            obtenerLicencias()
            setIdLicence(-1)
            setBulModalSelectUsers2(false)
            notification.success({
                title: "Success",
                description: "Membership assigned!!",
            });
        })
    
    }

    const handleOnFinishSelect3 = (value) => {

        changeMembresy({
            email:value.email[0],
            firstName: value.firstName,
            lastName: value.lastName,
            id: idLicence
        },() => {
            obtenerLicencias()
            setIdLicence(-1)
            setBulModalSelectUsers3(false)
            notification.success({
                title: "Success",
                description: "Membership assigned!!",
            });
        })
    
    }

    const handleRenew = (value) => {
        setUserInALicence(value,() => {
            obtenerLicencias()
            setIdLicence(-1)
            setBulModalSelectUsers2(false)
            notification.success({
                title: "Success",
                description: "Autorenew updated!!",
            });
        })
    }

    const filterSearch = (data) => {
        let optionF = (Object.values(options.current || {})).map(e => e[0])
        let response = data?.filter((o) => {return !optionF?.includes(o.email) && o.memberShip === 'free'})
        return response
    }

    useEffect(() => {
        if(licences.length > 0){
            let newArrays = licences.filter(f => f.email).map(a => {
                return {
                    ...(a.User || {}),
                    email:a?.email,
                    firstName: a?.firstName,
                    lastName: a?.lastName,
                }
            })
            setArrayUsers(newArrays)
        }
    },[licences])

    const calcularPorcentajeEntreFechas = (fechaInicial, fechaFinal, zonaHorariaUsuario) => {
        const fechaInicialMoment = moment.tz(fechaInicial, zonaHorariaUsuario);
        const fechaFinalMoment = moment.tz(fechaFinal, zonaHorariaUsuario);
        
        const fechaActualMoment = moment.tz(moment(), zonaHorariaUsuario);
      
        const diferenciaTotal = fechaFinalMoment.diff(fechaInicialMoment);
      
        const diferenciaActual = fechaActualMoment.diff(fechaInicialMoment);
      
        const diasRestantes = fechaFinalMoment.diff(fechaActualMoment, 'days');
        const porcentaje = (diferenciaActual / diferenciaTotal) * 100;
      
        return {porcentaje: Math.round(porcentaje * 100) / 100, days: diasRestantes}
    }

    const handleDeleteCorporateAdmin = (e) => {
        deleteMembresy({
            id: e?.id
        },() => {
            obtenerLicencias()
            setIdLicence(-1)
            notification.success({
                title: "Success",
                description: "Assignment deleted!",
            });
        })
    }

    const onDeleteLicence = (e) => {
        confirm({
          title: "Are you sure delete this Assignment?",
          icon: <ExclamationCircleOutlined />,
          okText: "Yes",
          okType: "danger",
          cancelText: "No",
          onOk() {
            handleDeleteCorporateAdmin(e);
          },
        });
    };
    
    const orgLicences = (e) => {
        let arrayFixed = [];
        // let num = -1;
        // let titlesDateReady;

        if (e !== undefined) {
            const arrayOrderTime = e.sort((a, b) => {
                let aTime = convertToLocalTime(a.startDate, a.timeZone).format("YYYY");
                let bTime = convertToLocalTime(b.startDate, b.timeZone).format("YYYY");

                let aTimeRest = convertToLocalTime(a.startDate, a.timeZone).format(
                "MMDDHHmm"
                );
                let bTimeRest = convertToLocalTime(b.startDate, b.timeZone).format(
                "MMDDHHmm"
                );

                return Number(bTime - bTimeRest) - Number(aTime - aTimeRest);
            });

            // for (let i = 0; i < arrayOrderTime.length; i++) {
            //     let dateNow = arrayOrderTime[i].startDate;
            //     let timezone = arrayOrderTime[i].timeZone;
            //     if (
            //     titlesDateReady !==
            //     convertToLocalTime(dateNow, timezone).format().substring(0, 10)
            //     ) {
            //     titlesDateReady = convertToLocalTime(dateNow, timezone)
            //         .format()
            //         .substring(0, 10);
            //     num++;
            //     if (!arrayFixed[num]) {
            //         arrayFixed.push([]);
            //     }
            //     arrayFixed[num].push(arrayOrderTime[i]);
            //     } else {
            //     arrayFixed[num].push(arrayOrderTime[i]);
            //     }
            // }

            arrayFixed = [arrayOrderTime]
        }

        return arrayFixed

    }

    const orgAccepted = (e) => {
        return [
            {startDateFile:'Premium Memberships Dates', status:'Status', email:'Actions'},
            ...e.sort((a, b) => (!!b?.LicenceUserId) - (!!a?.LicenceUserId))
        ]
    }

    const orderListLastLogin = (data) => {
        let arrayOrderTime = data.sort((a, b) => {
          let aTime = moment(a.date, "YYYYMMDDHHmm").format("YYYYMMDDHHmm");
          let bTime = moment(b.date, "YYYYMMDDHHmm").format("YYYYMMDDHHmm");
    
          return Number(bTime) - Number(aTime);
        });
    
        return arrayOrderTime;
    };

    return (
        <div className="container-corporate" style={isMobile ? {padding:'10px'} : {}}>
            {(loadingg === true) && (
                <div className="loading-container" style={{zIndex:'100000',position:'fixed',left:'0px',top:'0px'}}>
                    <Spin indicator={<img src={IconLoading} alt="loading-img" />} />
                </div>
            )}
            <div className="container-one">
                <div className="container-two">
                    <div className="container-title-corpoate">
                    </div>

                    <div style={isMobile 
                        ? {display: 'flex', flexDirection:'column',justifyContent:'flex-start',alignItems:'center',gap:'0px'} 
                        : {display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center',gap:'20px'}
                    }>
                        <div style={isMobile ? {
                            width:'100%',marginTop:'0px',marginTop:'10px'
                        } : {}} className={tab === 1 ? "title-list-corporate" : "title-list-corporate2"} onClick={() => setTab(1)}>Memberships</div>
                        <div style={isMobile ? {
                            width:'100%',marginTop:'0px'
                        } : {}} className={tab === 2 ? "title-list-corporate" : "title-list-corporate2"} onClick={() => setTab(2)}>Team Members</div>
                        <CustomButton
                            text="Add Memberships"
                            type="primary-retart"
                            className="button-add-memberships"
                            onClick={() => {
                                setBulModalSelectUsers(true)
                            }}
                            style={isMobile ? {
                                color: "white",
                                cursor: 'pointer',
                                width:'100%',
                                marginBottom:'20px'
                            } : {
                                color: "white",
                                cursor: 'pointer'
                            }}
                        />
                        <h3 style={tab === 1 ? {opacity:'1', zIndex:'2'} : {opacity:'0', zIndex:'1'}}>Available Memberships: {licences.filter(e => e.email === '' || e.email === null || e.email === 'null').length}/{licences.length}</h3>
                    </div>
                </div>
            </div>
            <div className="container-relative-corporate">
                <div className="container-content-corporate" style={tab === 1 ? {opacity:'1', zIndex:'2'} : {opacity:'0', zIndex:'1'}}>
                    {licences.length > 0 ? (
                        <div className="container-licences-list">
                            {orgLicences(licences)?.map((a,index2) => {
                                // let filterPays = licencesPays.filter(kiol => {
                                //     return moment(kiol.fechaPago,'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD') === moment(a[1]?.startDate,'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')
                                // })
                                return (
                                    <div style={{height:'100%',width:'100%',position:'relative'}} key={index2}>
                                        {orgAccepted(a)?.map((e,index) => {

                                            // const zonaHorariaUsuario = moment.tz.guess();

                                            // const {porcentaje,days} = calcularPorcentajeEntreFechas(e.startDate, e.endDate, zonaHorariaUsuario);

                                            return (
                                                <div className={`card-user-licence-list ${index === 0 && 'header-li'}`} style={(e?.status === 'Status' && isMobile) ? {display:'none'} : {marginTop:'20px'}} key={index}>
                                                    <div className={`${isMobile && 'isBottom'}`} style={{minWidth:'200px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                        {e?.startDateFile ? (
                                                            <p className="p-lince">{e?.startDateFile}</p>
                                                        ) : (
                                                            <div className="container-dates-li">
                                                                <p className="p-lince"><b>Start Date:</b> {e?.isManual !== 'true' 
                                                                    ? moment(e.startDate,'YYYY-MM-DD HH:mm:ss').format('MMMM DD, YYYY')
                                                                    : moment(e?.User?.subscription_startdate,'YYYY-MM-DD HH:mm:ss+00').format('MMMM DD, YYYY')
                                                                }</p>
                                                                <p className="p-lince"><b>End Date:</b> {e?.isManual !== 'true' 
                                                                    ? moment(e.endDate,'YYYY-MM-DD HH:mm:ss').format('MMMM DD, YYYY')
                                                                    : moment(e?.User?.subscription_enddate,'YYYY-MM-DD HH:mm:ss+00').format('MMMM DD, YYYY')
                                                                }</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div style={{minWidth:'200px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                        {e?.status === 'Status' ? (
                                                            <p className="p-lince">
                                                                {e?.status}
                                                            </p>
                                                        ) : (
                                                            <div className="container-user-li">
                                                                <div className="member-avatar">
                                                                    {e?.User?.img ? (
                                                                        <div className="member-avatar-img">
                                                                        <img src={e?.User?.img} alt="user-img" />
                                                                        </div>
                                                                    ) : (
                                                                        <span className="member-avatar-name">{e?.User?.abbrName || '?'}</span>
                                                                    )}
                                                                </div>
                                                                <p className="p-lince">{e.email}</p>
                                                                <p className="p-lince">{e.firstName} {e.lastName}</p>
                                                                <p className="p-lince" style={e?.deneged ? {color:'red'} : !e?.User?.id ? {color: '#fe5621'} : {color:'green'}}>{e?.deneged 
                                                                    ? 'Reject'
                                                                    : (!e?.User?.id) ? `pending to ${e.email ? 'confirm' : 'assign'}` : 'accepted'}</p>
                                                                {!!e?.User?.id && <CustomButton
                                                                    text="View Profile"
                                                                    type="primary-retart"
                                                                    size="sm"
                                                                    onClick={() => {getOneUser(e?.User?.id)}}
                                                                    style={{
                                                                        padding: "10px 18px!important",
                                                                        borderRadius: "10px",
                                                                        color: "white",
                                                                        cursor: 'pointer',
                                                                        width:'100%'
                                                                    }}
                                                                />}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div style={{minWidth:'200px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                        {e?.actions !== 'true' && <>    
                                                            {e?.email === 'Actions' ? (
                                                                <p className="p-lince">Actions</p>
                                                            ) : e.email ? (
                                                                <div className="container-boton-status">
                                                                    <CustomButton
                                                                        text="Reassign User"
                                                                        type="primary-retart"
                                                                        size="sm"
                                                                        onClick={() => {
                                                                            setIdLicence(e?.id)
                                                                            setBulModalSelectUsers3(true);
                                                                        }}
                                                                        style={{
                                                                            padding: "10px 18px!important",
                                                                            borderRadius: "10px",
                                                                            color: "white",
                                                                            cursor: 'pointer',
                                                                            width:'200px',
                                                                            marginTop:'10px'
                                                                        }}
                                                                    />
                                                                    <CustomButton
                                                                        text="Remove assignment"
                                                                        type="primary-retart"
                                                                        size="sm"
                                                                        onClick={() => {
                                                                            onDeleteLicence(e)
                                                                        }}
                                                                        style={{
                                                                            padding: "10px 18px!important",
                                                                            borderRadius: "10px",
                                                                            color: "white",
                                                                            cursor: 'pointer',
                                                                            width:'200px',
                                                                            marginTop:'10px'
                                                                        }}
                                                                    />
                                                                </div>
                                                            ) :  (
                                                                <div className="container-boton-status">
                                                                    <CustomButton
                                                                        text="Assign User"
                                                                        type="primary-retart"
                                                                        size="sm"
                                                                        onClick={() => {
                                                                            setIdLicence(e?.id)
                                                                            setBulModalSelectUsers2(true);
                                                                        }}
                                                                        style={{
                                                                            padding: "10px 18px!important",
                                                                            borderRadius: "10px",
                                                                            color: "white",
                                                                            cursor: 'pointer',
                                                                            width:'200px',
                                                                            marginTop:'10px'
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}
                                                        </>}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        {/* <CollapseModules
                                            title={<div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                                <p style={{fontSize:'18px',fontWeight:'bold',margin:'0px',padding:'0px',paddingLeft:'10px'}}>Membership payment at {moment(a[0]?.startDate,'YYYY-MM-DD HH:mm:ss').format('MMMM DD, YYYY')}</p>
                                                <div className="container-on-of" style={{fontSize:'18px',fontWeight:'bold',margin:'0px',padding:'0px',paddingLeft:'10px'}} onClick={(e) => {e.preventDefault(); e.stopPropagation()}}>
                                                    Auto Renew:{" "}
                                                    <Switch
                                                        checkedChildren="On"
                                                        unCheckedChildren="Off"
                                                        defaultChecked={(filterPays[0]?.renew === true || filterPays[0]?.renew === 'true')}
                                                        style={{ marginLeft: "5px", marginRight: "5px" }}
                                                        onChange={() => handleRenew({
                                                            paymentId: filterPays.map(ty => ty.id),
                                                            renew: filterPays[0]?.renew
                                                        })}
                                                    />{" "}
                                                    {`( $${Number(STRIPE_PRICES.STRIPE_PRICES[3].price) * Number(a.length-1)} USD )`}
                                                </div>
                                            </div>}
                                            background={"transparent"}
                                            key={index2}
                                            content={orgAccepted(a)?.map((e,index) => {

                                                const zonaHorariaUsuario = moment.tz.guess();
                
                                                const {porcentaje,days} = calcularPorcentajeEntreFechas(e.startDate, e.endDate, zonaHorariaUsuario);

                                                return (
                                                    <div className={`card-user-licence-list ${index === 0 && 'header-li'}`} style={{marginTop:'20px'}}>
                                                        <div style={{minWidth:'200px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                            {e?.startDateFile ? (
                                                                <p className="p-lince">{e?.startDateFile}</p>
                                                            ) : (
                                                                <div className="container-dates-li">
                                                                    <p className="p-lince"><b>Start Date:</b> {e?.isManual !== 'true' 
                                                                        ? moment(e.startDate,'YYYY-MM-DD HH:mm:ss').format('MMMM DD, YYYY')
                                                                        : moment(e?.User?.subscription_startdate,'YYYY-MM-DD HH:mm:ss+00').format('MMMM DD, YYYY')
                                                                    }</p>
                                                                    <p className="p-lince"><b>End Date:</b> {e?.isManual !== 'true' 
                                                                        ? moment(e.endDate,'YYYY-MM-DD HH:mm:ss').format('MMMM DD, YYYY')
                                                                        : moment(e?.User?.subscription_enddate,'YYYY-MM-DD HH:mm:ss+00').format('MMMM DD, YYYY')
                                                                    }</p>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div style={{minWidth:'200px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                            {e?.status === 'Status Assigned' ? (
                                                                <p className="p-lince">
                                                                    {e?.status}
                                                                </p>
                                                            ) : (
                                                                <div className="container-user-li">
                                                                    <div className="member-avatar">
                                                                        {e?.User?.img ? (
                                                                            <div className="member-avatar-img">
                                                                            <img src={e?.User?.img} alt="user-img" />
                                                                            </div>
                                                                        ) : (
                                                                            <span className="member-avatar-name">{e?.User?.abbrName || '?'}</span>
                                                                        )}
                                                                    </div>
                                                                    <p className="p-lince">{e.email}</p>
                                                                    <p className="p-lince">{e.firstName} {e.lastName}</p>
                                                                    <p className="p-lince" style={e?.deneged ? {color:'red'} : !e?.User ? {color: '#fe5621'} : {color:'green'}}>{e?.deneged 
                                                                        ? 'Reject'
                                                                        : !e?.User ? `pending to ${e.email ? 'confirm' : 'assign'}` : 'accepted'}</p>
                                                                    {!!e?.User && <CustomButton
                                                                        text="View Profile"
                                                                        type="primary-retart"
                                                                        size="sm"
                                                                        onClick={() => {getOneUser(e?.User?.id)}}
                                                                        style={{
                                                                            padding: "10px 18px!important",
                                                                            borderRadius: "10px",
                                                                            color: "white",
                                                                            cursor: 'pointer',
                                                                            width:'100%'
                                                                        }}
                                                                    />}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div style={{minWidth:'200px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                            {e?.actions !== 'true' && <>    
                                                                {e?.email === 'Actions' ? (
                                                                    <p className="p-lince"></p>
                                                                ) : e.email ? (
                                                                    <div className="container-boton-status">
                                                                        <CustomButton
                                                                            text="Reassign User"
                                                                            type="primary-retart"
                                                                            size="sm"
                                                                            onClick={() => {
                                                                                setIdLicence(e?.id)
                                                                                setBulModalSelectUsers3(true);
                                                                            }}
                                                                            style={{
                                                                                padding: "10px 18px!important",
                                                                                borderRadius: "10px",
                                                                                color: "white",
                                                                                cursor: 'pointer',
                                                                                width:'200px',
                                                                                marginTop:'10px'
                                                                            }}
                                                                        />
                                                                        <CustomButton
                                                                            text="Remove assignment"
                                                                            type="primary-retart"
                                                                            size="sm"
                                                                            onClick={() => {
                                                                                onDeleteLicence(e)
                                                                            }}
                                                                            style={{
                                                                                padding: "10px 18px!important",
                                                                                borderRadius: "10px",
                                                                                color: "white",
                                                                                cursor: 'pointer',
                                                                                width:'200px',
                                                                                marginTop:'10px'
                                                                            }}
                                                                        />
                                                                    </div>
                                                                ) :  (
                                                                    <div className="container-boton-status">
                                                                        <CustomButton
                                                                            text="Assign User"
                                                                            type="primary-retart"
                                                                            size="sm"
                                                                            onClick={() => {
                                                                                setIdLicence(e?.id)
                                                                                setBulModalSelectUsers2(true);
                                                                            }}
                                                                            style={{
                                                                                padding: "10px 18px!important",
                                                                                borderRadius: "10px",
                                                                                color: "white",
                                                                                cursor: 'pointer',
                                                                                width:'200px',
                                                                                marginTop:'10px'
                                                                            }}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </>}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        /> */}
                                    </div>
                                )
                            })}
                        </div>
                    ) : (
                        <div className="container-icon-corporate"></div>
                    )}
                </div>
                <div className="container-content-corporate" style={tab === 2 ? {opacity:'1', zIndex:'2'} : {opacity:'0', zIndex:'1'}}>
                    {!(activitys || {}).activitys ? <>
                        {arrayUsers.length === 0 ? (<>
                            <div className="container-icon-corporate-team"></div>
                            <div style={{width:'100%',height:'auto',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <h3>You have not yet assigned your team</h3>
                            </div>
                        </>) : (
                            <div className="container-users-corporate">
                                {arrayUsers.map((yu,index) => {
                                    return (
                                        <CardUser
                                            key={index}
                                            user={yu}
                                            getOneUser={(id) => getOneUser(id)}
                                            setActivitys={(e) => setActivitys(e)}
                                        />
                                    )
                                })}
                            </div>
                        )}
                    </> : <>
                        <div className="container-activitys">
                            <div className="container-user">
                                <div className="corporate_admin__content-top" onClick={() => setActivitys({})}>
                                    <div className="corporate_admin__content-top-back">
                                        <img src={IconBack} alt="icon-back" />
                                    </div>
                                    <h4>Back to Team</h4>
                                </div>
                                <CardUser
                                    user={(activitys || {})}
                                    getOneUser={() => {}}
                                    setActivitys={(e) => {}}
                                    lastTime={orderListLastLogin(((activitys || {}).activitys || []).filter(e => e.action === 'login'))?.[0] || 'no hay'}
                                />
                            </div>   
                            <div className="container-history">
                                {((activitys || {}).activitys || []).filter(e => e.action !== 'login').length > 0 
                                    ? orderListLastLogin(((activitys || {}).activitys || []).filter(e => e.action !== 'login')).map(e => {
                                        return (
                                            <div className="container-history-data"><b>- {moment(e?.date,'YYYY-MM-DD HH:mm:ss').format('MMMM DD, YYYY')}:</b>{e.action}</div>
                                        )
                                    }) : (
                                        <NoItemsMessageCard
                                            message={`There aren't activity logs.`}
                                        />
                                    )
                            }
                            </div>
                        </div>
                        
                    </>}
                </div>
            </div>
            <CustomModal
                visible={bulModalSelectUsers3}
                onCancel={() => {
                    setBulModalSelectUsers3(false);
                    setIdLicence(-1)
                }}
                title={(<>
                    <p style={{textAlign:'center'}}>Select your team!</p>
                </>)}
                width={isMobile ? 320 : 980}
            >
                <Form form={form3} layout="vertical" onFinish={handleOnFinishSelect3} ref={formSelect3}>
                    <div className="container-cards-user">
                        <div className="container-form-users" >
                            <Form.Item
                                name="email"
                                required={[{ required: true }]}
                                label="Email"
                                rules={[
                                    {
                                        required: true,
                                        message: "Email is required",
                                    },
                                ]}
                            >
                                <Select
                                    size="large"
                                    mode="tags"
                                    className="custom-corporate-select"
                                    placeholder="Search User By Email"
                                    onSearch={handleSearchUser}
                                    notFoundContent={fetching ? <Spin size="small" /> : null}
                                    options={filterSearch(searchedUsersForCouncilEvent)?.map(e => {
                                        return {label:e?.email,value:e?.email}
                                    })}
                                    style={{width:'250px'}}
                                    onChange={(e) => handleSelectChange2(e)}
                                />
                            </Form.Item>
                            <Form.Item
                                name="firstName"
                                label="First Name"
                                rules={[
                                    {
                                        required: true,
                                        message: "First Name is required",
                                    },
                                ]}
                            >
                                <CustomInput size="sm" />
                            </Form.Item>
                            <Form.Item
                                name="lastName"
                                label="Last Name"
                                rules={[
                                    {
                                    required: true,
                                    message: "Last Name is required",
                                    },
                                ]}
                            >
                                <CustomInput size="sm" />
                            </Form.Item>
                            <Form.Item style={{marginBottom:'0px'}}>
                                <CustomButton loading={loading} htmlType="submit" text="Reassign User" block />
                            </Form.Item>
                        </div>
                    </div>
                </Form>
                <p style={{fontSize:"15px", width:'100%',textAlign:'center',margin:'0px',padding:'0px',fontWeight:'100'}}>We will inform your team via email. Each member assigned will receive a unique code, which they can then use to claim their membership.</p>
            </CustomModal>
            <CustomModal
                visible={bulModalSelectUsers2}
                onCancel={() => {
                    setBulModalSelectUsers2(false);
                    setIdLicence(-1)
                }}
                title={(<>
                    <p style={{textAlign:'center'}}>Select your team!</p>
                </>)}
                width={980}
            >
                <Form form={form2} layout="vertical" onFinish={handleOnFinishSelect2} ref={formSelect2}>
                    <div className="container-cards-user">
                        <div className="container-form-users" >
                            <Form.Item
                                name="email"
                                required={[{ required: true }]}
                                label="Email"
                                rules={[
                                    {
                                        required: true,
                                        message: "Email is required",
                                    },
                                ]}
                            >
                                <Select
                                    size="large"
                                    mode="tags"
                                    className="custom-corporate-select"
                                    placeholder="Search User By Email"
                                    onSearch={handleSearchUser}
                                    notFoundContent={fetching ? <Spin size="small" /> : null}
                                    options={filterSearch(searchedUsersForCouncilEvent)?.map(e => {
                                        return {label:e?.email,value:e?.email}
                                    })}
                                    style={{width:'250px'}}
                                    onChange={(e) => handleSelectChange2(e)}
                                />
                            </Form.Item>
                            <Form.Item
                                name="firstName"
                                label="First Name"
                                rules={[
                                    {
                                        required: true,
                                        message: "First Name is required",
                                    },
                                ]}
                            >
                                <CustomInput size="sm" />
                            </Form.Item>
                            <Form.Item
                                name="lastName"
                                label="Last Name"
                                rules={[
                                    {
                                    required: true,
                                    message: "Last Name is required",
                                    },
                                ]}
                            >
                                <CustomInput size="sm" />
                            </Form.Item>
                            <Form.Item style={{marginBottom:'0px'}}>
                                <CustomButton loading={loading} htmlType="submit" text="Add" block />
                            </Form.Item>
                        </div>
                    </div>
                </Form>
                <p style={{fontSize:"15px", width:'100%',textAlign:'center',margin:'0px',padding:'0px',fontWeight:'100'}}>We will inform your team via email. Each member assigned will receive a unique code, which they can then use to claim their membership.</p>
            </CustomModal>
            <CustomModal
                visible={bulModalSelectUsers}
                onCancel={() => {
                    setBulModalSelectUsers(false);
                    num.current = 0;
                    options.current = {};
                    setNumberSelect(0);
                    setUsersCount({'0':''});
                }}
                title={(<>
                    <p style={{textAlign:'center'}}>{relationEmail ? 'Upgrade your team to PREMIUM!' : 'Choose the licenses required for your team!'}</p>
                    <p style={{fontSize:"26px", width:'100%',textAlign:'center'}}>Total: {relationEmail ? (Number(STRIPE_PRICES.STRIPE_PRICES[3].price) * Object.keys(usersCount)?.length) : (Number(STRIPE_PRICES.STRIPE_PRICES[3].price) * Number(numberSelect))} $</p>
                    {licences.length === 0 && <p style={{fontSize:"15px", width:'100%',textAlign:'center',margin:'0px',padding:'0px',fontWeight:'100'}}>minimum five accounts.</p>}
                </>)}
                width={relationEmail ? 980 : 480}
            >
                {relationEmail ? <>
                    <Form form={form} layout="vertical" onFinish={handleOnFinishSelect} ref={formSelect}>
                        <Form.Item>
                            <Button
                                type="dashed"
                                onClick={() => {
                                    num.current = num.current + 1
                                    setUsersCount({...usersCount,[`${num.current}`]:''})
                                }}
                                block
                                style={{padding:'20px',height:'60px'}}
                                icon={<PlusOutlined />}
                            >
                            Add User
                            </Button>
                        </Form.Item>
                        <div className="container-cards-user">
                            {Object.keys(usersCount)?.map((indexx) => (
                                <div className="container-form-users" key={indexx}>
                                    <Form.Item
                                        name={[indexx, "email"]}
                                        required={[{ required: true }]}
                                        label="Email"
                                        rules={[
                                            {
                                            required: true,
                                            message: "Email is required",
                                            },
                                        ]}
                                    >
                                        <Select
                                            size="large"
                                            mode="tags"
                                            className="custom-corporate-select"
                                            placeholder="Search User By Email"
                                            onSearch={handleSearchUser}
                                            notFoundContent={fetching ? <Spin size="small" /> : null}
                                            options={filterSearch(searchedUsersForCouncilEvent)?.map(e => {
                                                return {label:e?.email,value:e?.email}
                                            })}
                                            style={{width:'250px'}}
                                            onChange={(e) => handleSelectChange(e,indexx)}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name={[indexx, "firstName"]}
                                        label="First Name"
                                        rules={[
                                        {
                                            required: true,
                                            message: "First Name is required",
                                        },
                                        ]}
                                    >
                                        <CustomInput size="sm" />
                                    </Form.Item>
                                    <Form.Item
                                        name={[indexx, "lastName"]}
                                        label="Last Name"
                                        rules={[
                                            {
                                            required: true,
                                            message: "Last Name is required",
                                            },
                                        ]}
                                    >
                                        <CustomInput size="sm" />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            onClick={() => {
                                                let data = usersCount;
                                                delete data[indexx]
                                                setUsersCount({...data})   
                                            }}
                                            block
                                            style={{display:'flex',justifyContent:'center',alignItems:'center'}}
                                            icon={<DeleteOutlined />}
                                        >
                                            Delete
                                        </Button>
                                    </Form.Item>
                                    
                                </div>
                            ))}
                        </div>
                        <Form.Item>
                            <CustomButton loading={loading} htmlType="submit" text="Add" block />
                        </Form.Item>
                    </Form>
                    <p style={{fontSize:"15px", width:'100%',textAlign:'center',margin:'0px',padding:'0px',fontWeight:'100'}}>We will inform your team via email. Each member will receive a unique code, which they can then use to claim their membership.</p>
                </> : <>
                    <Form form={form} layout="vertical" onFinish={handleOnFinishSelect}>
                        <Form.Item 
                            name="number"
                            label="Number of licenses"
                        >
                            <InputNumber 
                                style={{width:'100%',height:'auto',marginTop:'20px',marginBottom:'20px'}} 
                                onChange={(e) => {
                                    setNumberSelect(e)
                                }} 
                            />
                        </Form.Item>
                        <Form.Item>
                            <CustomButton loading={loading} htmlType="submit" text="Add" block />
                        </Form.Item>
                    </Form>
                </>}
                <div style={{width:'100%',height:'auto',display:'flex',flexDirection:'row',gap:'10px',justifyContent:'center',alignItems:'center', marginTop:'20px',marginBottom:'20px'}}>
                    <Switch onChange={(e) => {setRelationEmail(e)}}/>
                    <p style={{margin:'0px',padding:'0px'}}>Assign memberships at once</p>
                </div>
                {checkoutSessionError && (
                <Alert
                    message="Error"
                    description={checkoutSessionErrorMsg}
                    type="error"
                    showIcon
                />
                )}
            </CustomModal>
            <CustomModal
                visible={modalResponsePay !== ''}
                onCancel={() => {setModalResponsePay('')}}
                title={[]}
                footer={[]}
                width={480}
            >
                <p style={{margin:'0px',padding:'0px',marginTop:'10px',textAlign:'center',width:'100%', fontSize:'22px', fontWeight:'bold'}}>{modalResponsePay}</p>
                <p style={{margin:'0px',padding:'0px',marginTop:'30px',textAlign:'center',width:'100%', fontSize:'22px', fontWeight:'bold'}}>Thank you so much!</p>
            </CustomModal>
            <UserProfileModal
                modalUserProfile={showProfile}
                setModalUserProfile={setShowProfile}
                userProfile={userProfileToShow}
                allCategories={allCategories}
            />
        </div>
        
    );
};

const mapStateToProps = (state) => ({
    ...councilEventSelector(state),
    userProfile: homeSelector(state).userProfile,
    licences: homeSelector(state).licences,
    licencesPays: homeSelector(state).licencesPays,
    allCategories: categorySelector(state).categories,
    isMobile: envSelector(state).isMobile,
  });

const mapDispatchToProps = {
    ...councilEventActions,
    getUser,
    confirmStatusPaid,
    getAllLicences,
    setUserInALicence,
    changeMembresy,
    deleteMembresy,
};

export default connect(mapStateToProps, mapDispatchToProps)(CorporateAdmin);
