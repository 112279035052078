import React, { useEffect, useState, useCallback, useMemo } from "react";
import "./style.scss";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import moment from "moment";
import { homeSelector } from "redux/selectors/homeSelector";
import { getUser } from "redux/actions/home-actions";
import { actions as courseActions } from "redux/actions/course-actions";
import IconLogo from "images/logo-sidebar.svg";
import { courseSelector } from "redux/selectors/courseSelector";
import { Avatar, Divider, Modal, Tabs } from "antd";
import iconDown from "images/icon-linkedin.svg";
import ButtonsCalendar from "./Button";
import { CustomButton, ModalCompleteYourProfile } from "components";
import TimeZoneList from "enum/TimezoneList";
import MicroConferenceVideoWrapper from "./MicroConferenceVideoWrapper";
import { useHistory } from "react-router-dom";
import { convertToLocalTime } from "utils/format";
import CollapseModules from "./CollapseModules";
import CardUser from "./CardUser";
import { Switch, Badge } from "antd";
import DiscussionBoard from "./DiscussionBoard";
import { envSelector } from "redux/selectors/envSelector";
import queryString from "query-string";
import { Dropdown, Menu, Space, notification } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import httpClient from "../../api/module/httpClient";
import { Collapse } from "antd";
import CollapseArrow from "../../images/arrowexpanmodules.svg";
import {
  CERTIFICATE,
  CERTIFICATE_ACTIVITY_STATUS_INFO,
  CERTIFICATE_USER,
} from "enum/QueryKeys";
import {
  getCertificateBySlug,
  getCertificateUsersBySlug,
  getCourseActivityStatusInfo,
  updateActivityStatus,
} from "api/module/course";
import { useCertificateStore } from "store/certificate";
import ClosedCertificateProgramModal from "./Modals/ClosedCertificateProgramModal";
import NotYetOpenedProgramModal from "./Modals/NotYetOpenedProgramModal";
import DiplomaModal from "./Modals/DiplomaModal";
import AnnouncementTab from "./TabItems/AnnouncementTab";
import { INTERNAL_LINKS } from "enum";
import ChatBox from "../../containers/ChatBox";
import DownloadProgramActivityDropdown from "./Button/DownloadProgramActivityDropdown";

const { Panel } = Collapse;

const CertificatesPage = ({ userProfile, match, location }) => {
  const [currentTab, setCurrentTab] = useState("0");
  const [enrolled, setEnrolled] = useState(false);
  const [openModal2, closeModal2] = useState(false);
  const [bulCompleteProfile, setBulCompleteProfile] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [diplomaModal, setDiplomaModal] = useState(false);
  const [classThatIsLive, setClassThatIsLive] = useState(null);
  const [certificateUser, setCertificateUser] = useState(null);
  const [notYetOpenedText, setNotYetOpenedText] = useState("");
  const [currentDiscussionBoardKey, setCurrentDiscussionBoardKey] =
    useState("Preparation Week");

  const slug = match.params.id;
  const history = useHistory();

  const certificateStore = useCertificateStore();
  const queryClient = useQueryClient();

  const updateActivityStatusMutation = useMutation({
    mutationFn: async (id) => {
      return updateActivityStatus(id);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([CERTIFICATE, slug]);
      await queryClient.invalidateQueries([
        CERTIFICATE_ACTIVITY_STATUS_INFO,
        slug,
      ]);
    },
  });

  const { data: courseSelected } = useQuery({
    queryKey: [CERTIFICATE, slug],
    queryFn: async () => {
      const { data } = await getCertificateBySlug({ slug: slug });

      const currentCertificate = data?.certificate || {};

      certificateStore.setCurrentCertificate(currentCertificate);

      return currentCertificate;
    },
    enabled: !!slug,
  });

  const { data: certificateUsersInfo } = useQuery({
    queryKey: [CERTIFICATE_USER, slug],
    queryFn: async () => {
      const { data } = await getCertificateUsersBySlug({ slug });

      return data;
    },
    enabled: !!slug,
  });

  const { data: certificateActivityStatusInfo } = useQuery({
    queryKey: [CERTIFICATE_ACTIVITY_STATUS_INFO, slug],
    queryFn: async () => {
      const { data } = await getCourseActivityStatusInfo({ slug });

      return data.courseActivityStatusInfo;
    },
    enabled: !!slug,
  });

  const { data: certificateDiplomaInformation } = useQuery({
    queryKey: ["CERTIFICATE_DIPLOMA_INFORMATION", slug],
    queryFn: async () => {
      const { data } = await httpClient.get(
        `private/certificate-diploma-info/${courseSelected.id}`,
      );

      return data.certificateDiploma;
    },
    enabled: !!courseSelected?.id,
  });

  useEffect(() => {
    if (
      courseSelected &&
      courseSelected?.CertificateUsers &&
      userProfile &&
      userProfile?.id !== -1
    ) {
      const userIndex = (courseSelected?.CertificateUsers || [])?.findIndex(
        (user) => user.UserId === userProfile.id && user.status === "active",
      );

      if (userIndex !== -1) {
        setCertificateUser(courseSelected.CertificateUsers[userIndex]);
      }

      const userRegistered =
        userIndex !== -1
          ? courseSelected.CertificateUsers[userIndex]
          : undefined;

      setEnrolled(!!userRegistered);
    }
  }, [courseSelected, userProfile, TabData]);

  const { certificateCommentReplyId, certificateCommentId } = queryString.parse(
    location.search,
  );

  useEffect(() => {
    if (certificateCommentReplyId && certificateCommentId) {
      const discussionBoardTabDataIndex = TabData.findIndex(
        (item) => item.title === "Discussion Board",
      );

      if (discussionBoardTabDataIndex !== -1) {
        setCurrentTab(String(discussionBoardTabDataIndex));
      }
    }
  }, [certificateCommentReplyId, certificateCommentId, TabData]);

  const searchTimeZone = (timezone) => {
    let currentTimezone = TimeZoneList.find((item) => item.value === timezone);

    if (currentTimezone) {
      currentTimezone = currentTimezone.utc[0];
    } else {
      currentTimezone = timezone;
    }

    return currentTimezone;
  };
  const orderClassCertificates = (data) => {
    return Array.isArray(data)
      ? (data || []).sort((a, b) => {
        let aTime = convertToLocalTime(a?.startTime, a?.timezone).format(
          "YYYYMMDDHHmm",
        );
        let bTime = convertToLocalTime(b?.startTime, b?.timezone).format(
          "YYYYMMDDHHmm",
        );

        return Number(aTime) - Number(bTime);
      })
      : [];
  };

  const isToggleActive = useCallback((data, timezone, aTime, bTime) => {
    const pstTimeNow = moment.tz("America/Los_Angeles");

    const timeNow = pstTimeNow.format("YYYY-MM-DDTHH:mm:ss") + "Z";
    const startTime = moment.utc(aTime);
    const endTime = moment.utc(bTime).endOf("day");

    if (moment.utc(timeNow).isBetween(startTime, endTime)) {
      return true;
    } else {
      return false;
    }
  }, []);

  const clickVideoActivities = useCallback((data, timezone, aTime, bTime) => {
    const pstTimeNow = moment.tz("America/Los_Angeles");

    const timeNow = pstTimeNow.format("YYYY-MM-DDTHH:mm:ss") + "Z";
    const startTime = moment.utc(aTime);
    // const endTime = moment.utc(bTime).endOf("day");

    if (moment.utc(timeNow).isAfter(startTime)) {
      window.open(data?.linkActivity, "_blank");
    }

    if (moment.utc(timeNow).isBefore(startTime)) {
      setNotYetOpenedText(
        `This activity is not open yet. It will open on ${moment(aTime)
          .startOf("day")
          .format("MMMM D, YYYY")}`,
      );
    }
  }, []);

  const classesWithVideos = orderClassCertificates(
    courseSelected?.CourseClasses || [],
  ).filter((item) => {
    return item.videoLink;
  });

  function customSort(items) {
    const order = {
      "accountabilityPartnerMeetUp": 0,
      "collaborativeWhiteboarding": 1,
      "generalActivity": 2,
      "Group work": 3,
      "Individual assessment": 4,
    };

    return items.sort((a, b) => {
      return order[a.typeActivity] - order[b.typeActivity];
    });
  }

  const scheduleContainer = useMemo(() => {
    return orderClassCertificates(courseSelected?.CourseClasses || [])?.map(
      (e, index) => {
        let startDate = convertToLocalTime(e?.startDate, e?.timezone);
        let startTime = convertToLocalTime(e?.startTime, e?.timezone);
        let endTime = convertToLocalTime(e?.endTime, e?.timezone);

        return (
          <Collapse
            ghost
            expandIcon={({ isActive }) => (
              <div className="truc-shadow-module">
                <img
                  src={CollapseArrow}
                  style={
                    isActive
                      ? {
                        transform: "rotate(0deg)",
                        transition: "all 0.4s ease-in-out",
                        padding: "0px",
                        marginRight: "5px",
                      }
                      : {
                        transform: "rotate(90deg)",
                        transition: "all 0.4s ease-in-out",
                        padding: "0px",
                        marginRight: "5px",
                      }
                  }
                  alt="arrow-collapse"
                />
              </div>
            )}
          >
            <Panel
              header={
                <div style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                  {startDate.format("dddd, MMMM DD")}
                  <sup>{startTime.format("Do").slice(-2)}</sup>
                </div>
              }
              key={index}
              // className="site-collapse-custom-panel"
            >
              <div
                // style={{
                //   maxHeight: "calc(100svh - 104px)",
                //   overflowY: "auto"
                // }}
                // className={"container-collapse-module"}
              >
                <div
                  // className={"container-collapse-module-content"}
                >
                  <div
                    // className={"left-segment-collapse"}
                  >
                    <h2>{e.title}</h2>
                    <div
                      style={{
                        margin: "0px",
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <div
                        className={"container-descriptionnn"}
                        dangerouslySetInnerHTML={{
                          __html: e?.descriptionForCertificate,
                        }}
                      />
                    </div>
                    <>
                      <p
                        style={{
                          margin: "0px",
                          marginTop: "1rem",
                          marginBottom: "5px",
                        }}
                      >
                        <b>Class Date</b>:
                        {` ${(startDate || moment())?.format("LL")}`}
                      </p>
                      <p
                        style={{
                          margin: "0px",
                          marginTop: "5px",
                          marginBottom: "5px",
                        }}
                      >
                        <b>Class Start Time:</b>{" "}
                        {(startTime || moment())?.format("HH:mm")}{" "}
                        {moment.tz.guess()}
                      </p>
                      <p
                        style={{
                          margin: "0px",
                          marginTop: "5px",
                          marginBottom: "5px",
                        }}
                      >
                        <b>Class End Time:</b>{" "}
                        {(endTime || moment())?.format("HH:mm")}{" "}
                        {moment.tz.guess()}
                      </p>
                      {enrolled &&
                        moment
                          .utc(
                            moment
                              .tz("America/Los_Angeles")
                              .format("YYYY-MM-DDTHH:mm:ss") + "Z",
                          )
                          .isBefore(moment.utc(e?.endTime)) && (
                          <ButtonsCalendar
                            panels={e}
                            slug={courseSelected?.slug}
                            linkToJoin={e?.linkToJoin}
                          />
                        )}
                    </>
                    {e?.videoLink && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                          marginTop: "1rem",
                        }}
                      >
                        <div
                          className="title-list-certificates-dash"
                          onClick={() => setModal3(e)}
                          // style={{
                          //   marginTop: "1rem",
                          //   marginRight: "1rem",
                          // }}
                        >
                          CLASS VIDEO
                        </div>
                      </div>
                    )}
                    <div style={{ marginTop: "1rem" }}>
                      <h2>This week's activities:</h2>
                    </div>
                    {customSort(e.CertificateActivities ?? []).map(
                      (ac, activityIndex) => {
                        // const certificateActivityStatuses = (
                        //   ac?.CertificateActivityStatuses ?? []
                        // ).filter(
                        //   (status) =>
                        //     status.CertificateUser.UserId === userProfile.id
                        // );

                        // console.log(certificateActivityStatuses, 'certificateActivityStatuses')

                        let timezoneS =
                          e?.timezone === ""
                            ? "America/Los_Angeles"
                            : e?.timezone;
                        let startTime = convertToLocalTime(
                          ac?.startTime,
                          timezoneS,
                        );
                        let endTime = convertToLocalTime(
                          ac?.endTime,
                          timezoneS,
                        );

                        const activityDateText = `(Open Date: ${startTime.format(
                          "MMM DD YYYY",
                        )} | Due Date: ${endTime
                          .endOf("day")
                          .format("MMM DD YYYY")})`;

                        const activityTextList = {
                          "Individual assessment": `ASSESSMENT ${activityDateText}`,
                          collaborativeWhiteboarding: `ASSESSMENT ${activityDateText}`,
                          "Group work": `GROUPS ACTIVITY ${activityDateText}`,
                          accountabilityPartnerMeetUp: `Accountability Partner Meet Up ${activityDateText}`,
                          generalActivity: `General Activity ${activityDateText}`,
                          liveClass: "Live Class",
                        };

                        const activityTypeText = {
                          "Individual assessment": `Individual Assessment`,
                          collaborativeWhiteboarding: `Collaborative Whiteboarding`,
                          "Group work": `Group Work`,
                          accountabilityPartnerMeetUp: `Accountability Partner Meet Up`,
                          generalActivity: `General Activity`,
                        };

                        const text = activityTextList[ac.typeActivity];

                        const activityStatus =
                          ac?.CertificateActivityStatuses.find(
                            (status) =>
                              status.CertificateActivityId === ac.id &&
                              status.CertificateUser.UserId === userProfile.id,
                          );

                        const isCompleted =
                          activityStatus?.status === "completed";

                        const pstTimeNow = moment.tz("America/Los_Angeles");

                        const timeNow =
                          pstTimeNow.format("YYYY-MM-DDTHH:mm:ss") + "Z";

                        const isActivityMissed =
                          activityStatus?.status === "pending" &&
                          moment
                            .utc(timeNow)
                            .isAfter(moment.utc(ac.endTime).endOf("day"));

                        return (
                          <div
                            style={{
                              // display: "flex",
                              // alignItems: "center",
                              // height: "100%",
                              // width: "100%",
                              marginTop: "1rem",
                            }}
                          >
                            <div>
                              <h3>{`Activity ${activityIndex + 1}: ${
                                ac.title
                              }`}</h3>
                              <div>{activityTypeText[ac.typeActivity]}</div>
                              <div>{ac.description}</div>
                              {ac.linkActivity && (
                                <div
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  Click here:{" "}
                                  <div
                                    onClick={() => {
                                      clickVideoActivities(
                                        ac,
                                        timezoneS,
                                        startTime,
                                        endTime,
                                      );
                                    }}
                                    style={{
                                      color: "#0000ff",
                                      cursor: "pointer",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    Link
                                  </div>
                                </div>
                              )}
                              <div>Start date: {startTime.format("LL")}</div>
                              <div>
                                {` Due date: ${endTime
                                  .endOf("day")
                                  .format("LL")}`}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    marginRight: "10px",
                                  }}
                                >
                                  Activity Status
                                </div>
                                {isActivityMissed ? (
                                  <div
                                    style={{
                                      backgroundColor: "#b91c1c",
                                      color: "white",
                                      borderRadius: "999px",
                                      padding: "4px 8px",
                                    }}
                                  >
                                    <div
                                      style={
                                        {
                                          // padding: "4px",
                                        }
                                      }
                                    >
                                      Missed
                                    </div>
                                  </div>
                                ) : (
                                  <Switch
                                    className="custom-switch"
                                    style={{
                                      backgroundColor: "black",
                                    }}
                                    disabled={
                                      !isToggleActive(
                                        ac,
                                        timezoneS,
                                        startTime,
                                        endTime,
                                      )
                                    }
                                    defaultChecked={isCompleted}
                                    checked={isCompleted}
                                    onChange={() =>
                                      updateActivityStatusMutation.mutate(
                                        activityStatus.id,
                                      )
                                    }
                                    checkedChildren="Completed"
                                    unCheckedChildren="Pending"
                                  />
                                )}
                              </div>
                              <div>
                                {ac?.requirement === "required"
                                  ? "Required Activity"
                                  : "Optional Activity"}
                              </div>
                              {activityIndex !== ac.length - 1 && <hr />}
                            </div>
                          </div>
                        );
                      },
                    )}
                  </div>
                  <div className={"right-segment-collapse"}>
                    {(e?.intructors || []).map((ins, index) => {
                      return (
                        <div
                          className={"module-card-user-collapse"}
                          key={index}
                        >
                          <Avatar src={ins?.img} size={120}></Avatar>
                          <Divider className={"module-divider-collapse"} />
                          <h3
                            className={"module-name-collapse"}
                          >{`${ins?.firstName} ${ins?.lastName}`}</h3>
                          <p style={{ fontSize: "15px" }}>
                            {ins?.titleProfessions}
                          </p>
                          <a
                            href={ins?.linkedin || "#"}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              className={"module-icon-linkedin"}
                              src={iconDown}
                              alt="linkedin"
                            />
                          </a>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div
                  className={"container-background-subclass"}
                  style={{ background: "#fafafa" }}
                >
                  {e?.guestLectures?.map((panel, index2) => {
                    let startDate2 = convertToLocalTime(
                      panel?.startDate,
                      e?.timezone,
                    );
                    let startTime2 = convertToLocalTime(
                      panel?.startTime,
                      e?.timezone,
                    );
                    let endTime2 = convertToLocalTime(
                      panel?.endTime,
                      e?.timezone,
                    );
                    return (
                      <CollapseModules
                        title={
                          <>
                            {startDate2.format("dddd, MMMM DD")}
                            <sup>{startTime2.format("Do").slice(-2)}</sup>
                          </>
                        }
                        background={"transparent"}
                        key={index2}
                        content={
                          <div className={"container-collapse-module"}>
                            <div
                              className={"container-collapse-module-content"}
                            >
                              <div className={"left-segment-collapse"}>
                                <h2>{panel?.title}</h2>
                                <div
                                  style={{
                                    margin: "0px",
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div
                                    className={"container-descriptionnn"}
                                    dangerouslySetInnerHTML={{
                                      __html: panel?.description,
                                    }}
                                  />
                                </div>
                                <p
                                  style={{
                                    margin: "0px",
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <b>Class Date</b>:
                                  {` ${startDate2?.format("LL")}`}
                                </p>
                                <p
                                  style={{
                                    margin: "0px",
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <b>Class Start Time:</b>{" "}
                                  {startTime2?.format("HH:mm a")}{" "}
                                  {moment.tz.guess()}
                                </p>
                                <p
                                  style={{
                                    margin: "0px",
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <b>Class End Time:</b>{" "}
                                  {endTime2?.format("HH:mm a")}{" "}
                                  {moment.tz.guess()}
                                </p>
                                {enrolled && (
                                  <ButtonsCalendar
                                    panels={panel}
                                    slug={courseSelected?.slug}
                                    linkToJoin={e?.linkToJoin}
                                  />
                                )}
                              </div>
                              <div className={"right-segment-collapse"}>
                                {panel?.intructors.map((ins, index) => {
                                  return (
                                    <div
                                      className={"module-card-user-collapse"}
                                      key={index}
                                    >
                                      <Avatar
                                        src={ins?.img}
                                        size={120}
                                      ></Avatar>
                                      <Divider
                                        className={"module-divider-collapse"}
                                      />
                                      <h3
                                        className={"module-name-collapse"}
                                      >{`${ins?.firstName} ${ins?.lastName}`}</h3>
                                      <p style={{ fontSize: "15px" }}>
                                        {ins?.titleProfessions}
                                      </p>
                                      <a
                                        href={ins?.linkedin || "#"}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <img
                                          className={"module-icon-linkedin"}
                                          src={iconDown}
                                          alt="linkedin"
                                        />
                                      </a>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        }
                      />
                    );
                  })}
                </div>
              </div>
            </Panel>
          </Collapse>
        );
      },
    );
  }, [
    clickVideoActivities,
    courseSelected,
    enrolled,
    isToggleActive,
    updateActivityStatusMutation,
    userProfile.id,
  ]);

  const todoContainer = orderClassCertificates(
    courseSelected?.CourseClasses || [],
  )?.map((e, index) => {
    if ((e.CertificateActivities ?? []).length <= 0) return null;

    return (
      <div
        style={{
          width: "100%",
          marginBottom: "1rem",
        }}
      >
        <h1>{e.title}</h1>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {customSort(e.CertificateActivities ?? []).map((ac, activityIndex) => {
            let timezoneS =
              e?.timezone === "" ? "America/Los_Angeles" : e?.timezone;
            let startTime = convertToLocalTime(ac?.startTime, timezoneS);
            let endTime = convertToLocalTime(ac?.endTime, timezoneS);
            const activityDateText = `(Open Date: ${startTime.format("LL")} | Due Date: ${endTime.endOf("day").format("LL")})`;

            const activityTextList = {
              "Individual assessment": `ASSESSMENT ${activityDateText}`,
              collaborativeWhiteboarding: `ASSESSMENT ${activityDateText}`,
              "Group work": `GROUPS ACTIVITY ${activityDateText}`,
              accountabilityPartnerMeetUp: `Accountability Partner Meet Up ${activityDateText}`,
              generalActivity: `General Activity ${activityDateText}`,
              liveClass: "Live Class",
            };

            let text = activityTextList[ac.typeActivity];

            const activityStatus = ac?.CertificateActivityStatuses.find(
              (status) =>
                status.CertificateActivityId === ac.id &&
                status.CertificateUser.UserId === userProfile.id,
            );

            const isCompleted = activityStatus?.status === "completed";

            const pstTimeNow = moment.tz("America/Los_Angeles");

            const timeNow = pstTimeNow.format("YYYY-MM-DDTHH:mm:ss") + "Z";

            const isActivityMissed =
              activityStatus?.status === "pending" &&
              moment.utc(timeNow).isAfter(moment.utc(ac.endTime).endOf("day"));

            return (
              <div
                style={{
                  marginTop: "1rem",
                  width: "100%",
                }}
              >
                <div>
                  <h3>{`Activity ${activityIndex + 1}: ${ac.title}`}</h3>
                  <div>{text}</div>
                  <div>{ac.description}</div>
                  {ac.linkActivity && (
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      Click here:{" "}
                      <div
                        onClick={() => {
                          clickVideoActivities(
                            ac,
                            timezoneS,
                            startTime,
                            endTime,
                          );
                        }}
                        style={{
                          color: "#0000ff",
                          cursor: "pointer",
                          marginLeft: "5px",
                        }}
                      >
                        Link
                      </div>
                    </div>
                  )}
                  <div>Start date: {startTime.format("LL")}</div>
                  <div>{` Due date: ${endTime.endOf("day").format("LL")}`}</div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        marginRight: "10px",
                      }}
                    >
                      Activity Status
                    </div>
                    {isActivityMissed ? (
                      <div
                        style={{
                          backgroundColor: "#b91c1c",
                          color: "white",
                          borderRadius: "999px",
                          padding: "4px 8px",
                        }}
                      >
                        <div
                          style={
                            {
                              // padding: "4px",
                            }
                          }
                        >
                          Missed
                        </div>
                      </div>
                    ) : (
                      <Switch
                        className="custom-switch"
                        style={{
                          backgroundColor: "black",
                        }}
                        disabled={
                          !isToggleActive(ac, timezoneS, startTime, endTime)
                        }
                        defaultChecked={isCompleted}
                        checked={isCompleted}
                        onChange={() =>
                          updateActivityStatusMutation.mutate(activityStatus.id)
                        }
                        checkedChildren="Completed"
                        unCheckedChildren="Pending"
                      />
                    )}
                  </div>
                  <div>
                    {ac?.requirement === "required"
                      ? "Required Activity"
                      : "Optional Activity"}
                  </div>
                  {ac.linkActivity && (
                    <DownloadProgramActivityDropdown
                      panels={{
                        id: ac.id,
                        title: `TOMORROW - Due Date! Activity: ${ac.title}`,
                        startTime: moment.utc(ac.endTime).subtract(1, 'day').startOf('day').add(7, 'hours').format("YYYY-MM-DDTHH:mm:ss") + "Z",
                        endTime: moment.utc(ac.endTime).subtract(1, 'day').startOf('day').add(7, 'hours').add(15, 'minutes').format("YYYY-MM-DDTHH:mm:ss") + "Z",
                        timezone: "Pacific Standard Time"
                      }}
                      slug={courseSelected?.slug}
                      linkToJoin={`${process.env.REACT_APP_DOMAIN_URL}/certificates-dashboard/${courseSelected?.slug}`}
                    />
                  )}

                  {activityIndex !==
                  (e.CertificateActivities ?? []).length - 1 ? (
                    <hr style={{ width: "25%" }} />
                  ) : (
                    <hr
                      style={{
                        width: "25%",
                        borderTopWidth: "4px", // Increase the border-top-width for a thicker line
                      }}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  });

  const instructors = courseSelected?.CourseClasses?.reduce(
    (acc, currClass) => {
      const { CertificateClassInstructors, ...rest } = currClass;

      currClass.CertificateClassInstructors.forEach((instructor) => {
        const existingInstructor = acc.find(
          (inst) => +inst.instructor.UserId === +instructor.UserId,
        );

        if (existingInstructor) {
          existingInstructor.classes.push(rest);
        } else {
          acc.push({
            instructor,
            classes: [rest],
          });
        }
      });

      return acc;
    },
    [],
  );

  const instructorContainer = (instructors || []).map((item, index) => {
    const { instructor, classes } = item;

    // const sortedClasses = classes.sort((a, b) => moment.utc(a.startTime) - moment.utc(b.startTime))

    return (
      <CardUser
        instructor={{
          ...instructor,
          classes: classes,
        }}
        key={index}
      />
    );
  });

  const orderAlpha = (data) => {
    return data.sort(function(a, b) {
      if (a?.title?.toLowerCase() > b?.title?.toLowerCase()) {
        return 1;
      }
      if (a?.title?.toLowerCase() < b?.title?.toLowerCase()) {
        return -1;
      }
      return 0;
    });
  };

  const fixUsersForGroups = (data) => {
    let idGroupIContent =
      certificateUsersInfo?.certificateUsers?.filter(
        (k) => Number(k.UserId) === Number(userProfile.id),
      )[0] || {};
    let partnerts = [];
    let arrayParejasSelect = (
      courseSelected?.arrayParejas?.length ? courseSelected?.arrayParejas : []
    ).filter((e) => {
      return e.includes(idGroupIContent.id);
    })[0];
    (arrayParejasSelect || []).forEach((p) => {
      if (Number(p) !== Number(idGroupIContent.id)) {
        partnerts.push(
          certificateUsersInfo?.certificateUsers?.filter((k) => {
            return Number(k.id) === Number(p);
          })[0],
        );
      }
    });
    let newData = {};
    let indexS = undefined;
    let orderData = orderAlpha(data || []);
    orderData.forEach((y, index) => {
      if (Number(y.id) === Number(idGroupIContent.groupSelect)) {
        newData = {
          ...y,
          users: certificateUsersInfo?.certificateUsers?.filter(
            (a) => Number(a.groupSelect) === Number(y.id),
          ),
        };
        indexS = index;
      } else {
        orderData[index] = {
          ...y,
          users: certificateUsersInfo?.certificateUsers?.filter(
            (a) => Number(a.groupSelect) === Number(y.id),
          ),
        };
      }
    });
    orderData.splice(indexS, 1);
    orderData.unshift({ ...newData, partnerts });
    return orderData || [];
  };

  const cohortContainer = (
    fixUsersForGroups(certificateUsersInfo?.certificateGroups) || []
  )?.map((e, index) => {
    return (
      <>
        {e.partnerts && (
          <>
            <div
              style={{
                width: "100%",
                height: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: "20px",
              }}
            >
              {e.partnerts?.length > 0 && (
                <>
                  <h1
                    style={{
                      width: "100%",
                      textAlign: "center",
                      paddingBottom: "30px",
                    }}
                  >
                    My Accountability Partner
                  </h1>
                  {e.partnerts.map((s, index2) => {
                    return (
                      <CardUser
                        instructor={s}
                        key={`${index}-${index2}`}
                        btnClass={false}
                      />
                    );
                  })}
                  <div
                    style={{
                      width: "100%",
                      height: "2px",
                      backgroundColor: "black",
                    }}
                  />
                </>
              )}
            </div>
          </>
        )}
        {e.users?.length > 0 && (
          <div
            style={{
              width: "100%",
              height: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "20px",
            }}
          >
            {index === 0 && e.title && (
              <h1
                style={{
                  width: "100%",
                  textAlign: "center",
                  paddingBottom: "30px",
                }}
              >
                My Group: {e.title}
              </h1>
            )}
            {index === 1 && (
              <>
                <h1
                  style={{
                    width: "100%",
                    textAlign: "center",
                    paddingBottom: "30px",
                  }}
                >
                  Other Groups
                </h1>
                <h2
                  style={{
                    width: "100%",
                    textAlign: "center",
                    paddingBottom: "30px",
                  }}
                >
                  {e.title}
                </h2>
              </>
            )}
            {index > 1 && (
              <h2
                style={{
                  width: "100%",
                  textAlign: "center",
                  paddingBottom: "30px",
                }}
              >
                {e.title}
              </h2>
            )}
            {e.users?.map((s, index2) => {
              return (
                <CardUser
                  instructor={s}
                  key={`${index}-${index2}`}
                  btnClass={false}
                />
              );
            })}
            <div
              style={{
                width: "100%",
                height: "0.1px",
                backgroundColor: "gray",
              }}
            />
          </div>
        )}
      </>
    );
  });

  const calendarListData = orderClassCertificates(
    courseSelected?.CourseClasses || [],
  )
    ?.map((data) => {
      let startDate = convertToLocalTime(data?.startDate, data?.timezone);

      const date = startDate.format("yyyy/MM/DD");

      const activities = (data?.CertificateActivities || []).map(
        (activity, index) => {
          const startTime = convertToLocalTime(
            activity.startTime,
            data?.timezone,
          );
          const endTime = convertToLocalTime(activity.endTime, data?.timezone);

          const activityColors = {
            collaborativeWhiteboarding: "#7e22ce",
            "Individual assessment": "#172554",
            "Group work": "#c2410c",
            accountabilityPartnerMeetUp: "#374151",
            generalActivity: "#374151",
          };

          const startTimeNum = startTime.format("yyyy/MM/DD");
          const endTimeNum = endTime.format("yyyy/MM/DD");

          const activityText = "Mandatory Class Activity";

          return {
            startTimeNum,
            startTime,
            endTimeNum,
            endTime,
            color: activityColors[activity.typeActivity],
            content: activityText,
            classData: {
              id: data.id,
              title: `REMINDER! You have a pending activity for ${data.title}`,
              startTime: startTime,
              endTime: endTime,
              timezone: data.timezone,
            },
          };
        },
      );

      const val = {
        item: {
          date,
          title: data.title,
          activities,
          startDate,
          color: "#052e16",
          classData: {
            id: data.id,
            title: data.title,
            startTime: data.startTime,
            endTime: data.endTime,
            timezone: data.timezone,
            linkToJoin: data.linkToJoin,
          },
        },
      };

      return val;
    })
    .reduce((accumulator, currentValue) => {
      const isSameDay = moment().isSame(currentValue.item.startDate, "day");

      const content = isSameDay ? `Live Class` : `Class`;

      const classItem = {
        type: isSameDay ? "success" : "default",
        content,
        classData: currentValue.item.classData,
        color: currentValue.item.color,
      };

      if (!accumulator[currentValue.item.date]) {
        accumulator[currentValue.item.date] = [classItem];
      } else {
        accumulator[currentValue.item.date].push(classItem);
      }

      (currentValue.item.activities ?? []).forEach((activity, index) => {
        const startDateActivityItem = {
          type: "default",
          content: activity.content,
          itemType: "activity",
          color: activity.color,
          classData: activity.classData,
        };

        if (!accumulator[activity.startTimeNum]) {
          accumulator[activity.startTimeNum] = [startDateActivityItem];
        } else {
          accumulator[activity.startTimeNum].push(startDateActivityItem);
        }

        // const endDateActivityItem = {
        //   type: "warning",
        //   content: `Activity Due Date: ${activity.endTime.format("h:mm A")}`,
        //   color: activity.color
        // };

        // if (!accumulator[activity.endTimeNum]) {
        //   accumulator[activity.endTimeNum] = [endDateActivityItem];
        // } else {
        //   accumulator[activity.endTimeNum].push(endDateActivityItem);
        // }
      });

      return accumulator;
    }, {});

  const classesThatHasStarted = (courseSelected?.CourseClasses || [])
    .filter((courseClass) => {
      const courseClassStartDate = moment.utc(courseClass.startTime);

      const pstTimeNow = moment.tz("America/Los_Angeles");
      const timeNow = pstTimeNow.format("YYYY-MM-DDTHH:mm:ss") + "Z";

      return (
        moment.utc(timeNow).isAfter(courseClassStartDate) &&
        (courseClass.classType === "normal" ||
          courseClass.classType === "preparation")
      );
    })
    .sort((a, b) => moment.utc(a.startTime).diff(b.startTime));

  const announcementNotifications = (
    courseSelected?.CertificateNotifications || []
  ).filter(
    (certificateNotification) =>
      certificateNotification.notificationType === "announcement",
  );

  const discussionBoardNotifications = (
    courseSelected?.CertificateNotifications || []
  ).filter(
    (certificateNotification) =>
      certificateNotification.notificationType === "discussionBoard",
  );

  const hasDiscussionBoardNotification = discussionBoardNotifications.some(
    (certificateNotification) => {
      return !certificateNotification.readers.includes(userProfile.id);
    },
  );

  const hasAnnouncementNotification = announcementNotifications.some(
    (certificateNotification) => {
      return !certificateNotification.readers.includes(userProfile.id);
    },
  );

  const getClassThatIsLive = useCallback((classs) => {
    const showButtonFunction = (startTime, endTime) => {
      const pstTimeNow = moment.tz("America/Los_Angeles");

      const timeNow = pstTimeNow.format("YYYY-MM-DDTHH:mm:ss") + "Z";
      const _startTime = moment.utc(startTime);
      const _endTime = moment.utc(endTime);

      return moment.utc(timeNow).isBetween(_startTime, _endTime);
    };

    const classThatIsLive = classs.find((data) => {
      const isLive = showButtonFunction(
        data.startTime,
        data.endTime,
        data.timezone,
      );

      return isLive;
    });

    return classThatIsLive;
  }, []);

  const [classLive, setClassLive] = useState(
    !!getClassThatIsLive(courseSelected?.CourseClasses || []),
  );

  const downloadAllCalendarInvites = useMutation({
    mutationFn: () => {
      const { data } = httpClient.post(`private/certificate/${slug}/download-activity-reminder`);

      return data;
    },
    onSuccess: () => {
      notification.success({
        placement: "topRight",
        message: "Success! Please check your email"
      })
    },
    onError: () => {
      notification.error({
        placement: "topRight",
        message: "Something went wrong."
      })
    }
  });


  const TabData = [
    {
      title: "Description",
      content: () => (
        <div className="container-content-tabs">
          <div
            className="description-camp"
            style={{ margin: "0px" }}
            dangerouslySetInnerHTML={{
              __html: courseSelected?.descriptionCertificate,
            }}
          ></div>
          {/* {classesContainer} */}
        </div>
      ),
    },
    {
      title: (
        <div
          style={{
            position: "relative",
          }}
        >
          Announcements
          {hasAnnouncementNotification && (
            <span
              style={{
                position: "absolute",
                right: "-16px",
                width: "16px",
                height: "16px",
                backgroundColor: "red",
                borderRadius: "50%",
                border: "2px solid white",
              }}
            />
          )}
        </div>
      ),
      content: () => (
        <AnnouncementTab
          certificateId={courseSelected?.id}
          hasAnnouncementNotification={hasAnnouncementNotification}
          slug={slug}
        />
      ),
    },
    {
      title: "Faculty",
      content: () => (
        <div className="container-content-tabs-intructors">
          {instructorContainer}
        </div>
      ),
    },
    {
      title: "Program Activities",
      content: () => <div>{scheduleContainer}</div>,
    },
    {
      title: "To-do",
      content: () => (
        <div className="container-content-tabs">
          <div>
            <CustomButton text="Download calendar reminders"
                          onClick={() => {
                            window.open(
                              `${process.env.REACT_APP_API_ENDPOINT}/public/course/activity/ics/all?userTimezone=${userProfile.timezone}&slug=${slug}&userId=${userProfile.id}`,
                              "_blank",
                            );
                          }}
                          // loading={downloadAllCalendarInvites.isLoading}
            />
          </div>
          {todoContainer}
        </div>
      ),
    },
    {
      title: "Resources and Videos",
      content: () => (
        <div className="container-content-tabs">
          <div
            style={{
              textAlign: "left",
              width: "100%",
            }}
          >
            <div>
              <h2>Relevant Permanent Links</h2>
              <ul>
                {courseSelected?.welcomingVideoUrl && (
                  <li
                    style={{
                      fontSize: "1rem",
                      cursor: "pointer",
                      color: "#0000EE",
                    }}
                    onClick={() => {
                      window.open(courseSelected.welcomingVideoUrl, "_blank");
                    }}
                  >
                    Welcoming Video
                  </li>
                )}
                {courseSelected?.certificateProgramGuidelinesLink && (
                  <li
                    style={{
                      fontSize: "1rem",
                      cursor: "pointer",
                      color: "#0000EE",
                    }}
                    onClick={() => {
                      window.open(
                        courseSelected.certificateProgramGuidelinesLink,
                        "_blank",
                      );
                    }}
                  >
                    Certificate Program Guidelines
                  </li>
                )}
                {courseSelected?.workBookUrl && (
                  <li
                    style={{
                      fontSize: "1rem",
                      cursor: "pointer",
                      color: "#0000EE",
                    }}
                    onClick={() => {
                      window.open(courseSelected.workBookUrl, "_blank");
                    }}
                  >
                    Certificate Program Workbook
                  </li>
                )}
                {courseSelected?.whatNowActionPlan && (
                  <li
                    style={{
                      fontSize: "1rem",
                      cursor: "pointer",
                      color: "#0000EE",
                    }}
                    onClick={() => {
                      window.open(courseSelected.whatNowActionPlan, "_blank");
                    }}
                  >
                    What-Now Action Plan
                  </li>
                )}
                {courseSelected?.groupWorkUrl && (
                  <li
                    style={{
                      fontSize: "1rem",
                      cursor: "pointer",
                      color: "#0000EE",
                    }}
                    onClick={() => {
                      window.open(courseSelected.groupWorkUrl, "_blank");
                    }}
                  >
                    In-class/Post-Class Group Activities
                  </li>
                )}
              </ul>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: "20px",
              }}
            >
              {classesWithVideos.map((item) => {
                return (
                  <div>
                    <MicroConferenceVideoWrapper
                      key={item.id} // Add a unique key for each item
                      url={item.videoLink}
                    />
                    {item.classSlidesUrl && (
                      <div
                        style={{
                          marginTop: "1rem",
                          fontSize: "1.5rem",
                          cursor: "pointer",
                          color: "#0000EE",
                        }}
                        onClick={() => {
                          window.open(item.classSlidesUrl, "_blank");
                        }}
                      >
                        Class Slides: Click Here
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: (
        <div
          style={{
            position: "relative",
          }}
        >
          Discussion Board
          {hasDiscussionBoardNotification && (
            <span
              style={{
                position: "absolute",
                right: "-16px",
                width: "16px",
                height: "16px",
                backgroundColor: "red",
                borderRadius: "50%",
                border: "2px solid white",
              }}
            />
          )}
        </div>
      ),
      content: () => (
        <div
          style={{
            padding: "1rem 0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {/*<Dropdown*/}
          {/*  trigger="click"*/}
          {/*  overlay={*/}
          {/*    <Menu*/}
          {/*      onClick={(item) => {*/}
          {/*        setCurrentDiscussionBoardKey(item.key);*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      {[*/}
          {/*        ...classesThatHasStarted.slice(0, 1),*/}
          {/*        ...(!!certificateUser?.groupSelect*/}
          {/*          ? [*/}
          {/*              {*/}
          {/*                title: "My Group Discussion Board",*/}
          {/*                classType: "groupDiscussion",*/}
          {/*              },*/}
          {/*            ]*/}
          {/*          : []),*/}
          {/*        ...classesThatHasStarted.slice(1),*/}
          {/*      ].map((item) => {*/}
          {/*        const hasNotification = discussionBoardNotifications.some(*/}
          {/*          (notif) => +notif.meta.certificateClassId === item.id*/}
          {/*        );*/}

          {/*        return (*/}
          {/*          <Menu.Item*/}
          {/*            key={item.title}*/}
          {/*            style={{*/}
          {/*              position: "relative",*/}
          {/*            }}*/}
          {/*          >*/}
          {/*            {item.classType === "preparation"*/}
          {/*              ? "General Discussion"*/}
          {/*              : `${item.title}`}*/}
          {/*            {hasNotification && (*/}
          {/*              <span*/}
          {/*                style={{*/}
          {/*                  position: "absolute",*/}
          {/*                  width: "16px",*/}
          {/*                  height: "16px",*/}
          {/*                  backgroundColor: "red",*/}
          {/*                  borderRadius: "50%",*/}
          {/*                  border: "2px solid white",*/}
          {/*                }}*/}
          {/*              />*/}
          {/*            )}*/}
          {/*          </Menu.Item>*/}
          {/*        );*/}
          {/*      })}*/}
          {/*    </Menu>*/}
          {/*  }*/}
          {/*>*/}
          {/*  <Space*/}
          {/*    style={{*/}
          {/*      cursor: "pointer",*/}
          {/*      position: "relative",*/}
          {/*      alignItems: "center",*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <h1>*/}
          {/*      {currentDiscussionBoardKey.startsWith("Preparation Week")*/}
          {/*        ? "General Discussion"*/}
          {/*        : currentDiscussionBoardKey}*/}
          {/*    </h1>*/}
          {/*    <DownOutlined />*/}
          {/*    {hasDiscussionBoardNotification && (*/}
          {/*      <span*/}
          {/*        style={{*/}
          {/*          position: "absolute",*/}
          {/*          right: "1px",*/}
          {/*          top: 0,*/}
          {/*          width: "16px",*/}
          {/*          height: "16px",*/}
          {/*          backgroundColor: "red",*/}
          {/*          borderRadius: "50%",*/}
          {/*          border: "2px solid white",*/}
          {/*        }}*/}
          {/*      />*/}
          {/*    )}*/}
          {/*  </Space>*/}
          {/*</Dropdown>*/}
          <div style={{ width: "100%", marginTop: "1rem" }}>
            {[
              ...classesThatHasStarted.slice(0, 1),
              {
                title: "My Group Discussion Board",
                classType: "groupDiscussion",
              },
              ...classesThatHasStarted.slice(1),
            ].map((item) => {
              const hasNotification = discussionBoardNotifications.some(
                (notif) => +notif.meta.certificateClassId === item.id,
              );

              return (
                <>
                  {String(item.title).startsWith(currentDiscussionBoardKey) && (
                    <div
                      style={{
                        border: ".5px solid black",
                        borderRadius: "16px",
                        padding: "1rem",
                        width: "100%",
                      }}
                    >
                      {item.classType === "groupDiscussion" ? (
                        <DiscussionBoard
                          slug={match.params.id}
                          userProfile={userProfile}
                          certificateUser={certificateUser}
                          certificateId={courseSelected?.id}
                          isGroupDiscussionBoard={true}
                          hasNotification={hasNotification}
                        />
                      ) : (
                        <DiscussionBoard
                          slug={match.params.id}
                          userProfile={userProfile}
                          certificateUser={certificateUser}
                          certificateClassId={item.id}
                          hasNotification={hasNotification}
                        />
                      )}
                    </div>
                  )}
                </>
              );
            })}
          </div>
        </div>
      ),
    },
    enrolled && {
      title: "Cohort Groups",
      content: () => (
        <div className="container-content-tabs-cohort-participants">
          {cohortContainer}
        </div>
      ),
    },
    {
      title: "Completion Status",
      content: () => (
        <div
          // className="container-activity-status-info"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "2rem",
              width: "100%",
            }}
          >
            <h3>Your Required Activities Completion Status</h3>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: ".5rem",
              width: "100%",
            }}
          >
            <h4>Total Required Activities By Now:</h4>
            <div
              style={{
                marginLeft: "5px",
              }}
            >
              {
                certificateActivityStatusInfo?.totalRequiredActivitiesByToday
              }
            </div>
          </div>
          {certificateActivityStatusInfo?.totalRequiredActivitiesByToday >
            0 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: ".5rem",
                  width: "100%",
                }}
              >
                <h4>Total Completed Activities (Required):</h4>
                <div
                  style={{
                    marginLeft: "5px",
                  }}
                >
                  {`${certificateActivityStatusInfo?.totalCompletedRequiredActivities} (${completedRequiredActivityPercentage}% of ${certificateActivityStatusInfo?.totalRequiredActivitiesByToday} total activities by now)`}
                </div>
              </div>
            )}
          {certificateActivityStatusInfo?.totalRequiredActivitiesByToday >
            0 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: ".5rem",
                  width: "100%",
                }}
              >
                <h4>Total Pending Activities (Required):</h4>
                <div
                  style={{
                    marginLeft: "5px",
                  }}
                >
                  {
                    certificateActivityStatusInfo?.totalPendingRequiredActivities
                  }
                </div>
              </div>
            )}
          {certificateActivityStatusInfo?.totalRequiredActivitiesByToday >
            0 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: ".5rem",
                  width: "100%",
                }}
              >
                <h4>Total Missed Activities (Required):</h4>
                <div
                  style={{
                    marginLeft: "5px",
                  }}
                >
                  {
                    certificateActivityStatusInfo?.totalMissedRequiredActivities
                  }
                </div>
              </div>
            )}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "2rem",
              width: "100%",
            }}
          >
            <h3>Your Optional Activities Completion Status</h3>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: ".5rem",
              width: "100%",
            }}
          >
            <h4>Total Optional Activities By Now:</h4>
            <div
              style={{
                marginLeft: "5px",
              }}
            >
              {
                certificateActivityStatusInfo?.totalOptionalActivitiesByToday
              }
            </div>
          </div>
          {certificateActivityStatusInfo?.totalOptionalActivitiesByToday >
            0 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: ".5rem",
                  width: "100%",
                }}
              >
                <h4>Total Completed Activities (Optional):</h4>
                <div
                  style={{
                    marginLeft: "5px",
                  }}
                >
                  {`${certificateActivityStatusInfo?.totalCompletedOptionalActivities} (${completedOptionalActivityPercentage}% of ${certificateActivityStatusInfo?.totalOptionalActivitiesByToday} total activities by now)`}
                </div>
              </div>
            )}
          {certificateActivityStatusInfo?.totalOptionalActivitiesByToday >
            0 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: ".5rem",
                  width: "100%",
                }}
              >
                <h4>Total Pending Activities (Optional):</h4>
                <div
                  style={{
                    marginLeft: "5px",
                  }}
                >
                  {
                    certificateActivityStatusInfo?.totalPendingOptionalActivities
                  }
                </div>
              </div>
            )}
          {certificateActivityStatusInfo?.totalOptionalActivitiesByToday >
            0 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: ".5rem",
                  width: "100%",
                }}
              >
                <h4>Total Missed Activities (Optional):</h4>
                <div
                  style={{
                    marginLeft: "5px",
                  }}
                >
                  {
                    certificateActivityStatusInfo?.totalMissedOptionalActivities
                  }
                </div>
              </div>
            )}
          {programProgress()}
        </div>
      ),
    },
    {
      title: "Diploma",
      content: () => (
        <div
          style={{
            marginTop: "1rem",
          }}
        >
          {certificateDiplomaInformation?.CertificateUser.hasDiploma && (
            <CustomButton
              type="primary"
              style={{
                width: "100%",
              }}
              size="md"
              text="VIEW DIPLOMA"
              onClick={() => {
                history.push(
                  `${INTERNAL_LINKS.CERTIFICATE_DIPLOMA_VERIFY}/${certificateDiplomaInformation.code}`,
                );
              }}
            />
          )}
          {!certificateDiplomaInformation?.CertificateUser
            .hasGeneratedDiploma && (
            <CustomButton
              type="tertiary"
              style={{
                background: "#d1d5db",
                color: "#374151",
                width: "100%",
              }}
              size="md"
              text="DOWNLOAD DIPLOMA"
              onClick={() => {
                if (completedRequiredActivityPercentage >= 78) {
                  setDiplomaModal(true);
                } else {
                  notification.warning({
                    placement: "topRight",
                    message:
                      "You did not meet the activity completion requirement",
                  });
                }
              }}
            />
          )}
        </div>
      ),
    },
    classLive && {
      title: "LIVE CLASS NOW",
      content: () => {
        return (
          <div className="container-right-certificate-infos">

            <div
              className="button-class-live"
              onClick={() => {
                window.open(classThatIsLive?.linkToJoin, "_blank");
              }}
            >
              JOIN LIVE CLASS NOW
            </div>
          </div>
        );
      },
    },
  ].filter(Boolean);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const classThatIsLive = getClassThatIsLive(
        courseSelected?.CourseClasses || [],
      );

      if (classThatIsLive) {
        setClassLive(!!classThatIsLive);
        setClassThatIsLive(classThatIsLive);
        clearInterval(intervalId);
      }
    }, 1000); // checks every minute

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [courseSelected, setClassLive, getClassThatIsLive]);

  const completedRequiredActivityPercentage = (
    (+certificateActivityStatusInfo?.totalCompletedRequiredActivities /
      +certificateActivityStatusInfo?.totalRequiredActivitiesByToday) *
    100
  ).toFixed(2);

  const completedOptionalActivityPercentage = (
    (+certificateActivityStatusInfo?.totalCompletedOptionalActivities /
      +certificateActivityStatusInfo?.totalOptionalActivitiesByToday) *
    100
  ).toFixed(2);

  const programProgress = () => {
    const parsedValue = Number(completedRequiredActivityPercentage);

    if (!parsedValue) return;

    const breakWeekClass = (courseSelected?.CourseClasses || []).find(
      (item) => item.classType === "break",
    );

    if (!breakWeekClass) return;

    const pstTimeNow = moment.tz("America/Los_Angeles");

    const timeNow = pstTimeNow.format("YYYY-MM-DDTHH:mm:ss") + "Z";

    if (moment.utc(timeNow).isBefore(breakWeekClass.startTime)) return;

    if (parsedValue >= 80) {
      return (
        <div
          style={{
            backgroundColor: "#00b574",
            color: "white",
            padding: "2px 5px",
            borderRadius: "10px",
            marginTop: "10px",
            fontWeight: "bold",
          }}
        >
          Congrats! You are on track to complete this Certificate Program!
        </div>
      );
    } else if (parsedValue >= 70 && parsedValue <= 79) {
      return (
        <div
          style={{
            backgroundColor: "#eab308",
            color: "white",
            padding: "2px 5px",
            borderRadius: "10px",
            marginTop: "10px",
            fontWeight: "bold",
          }}
        >
          Keep up the good work! Make sure you complete all upcoming activities!
        </div>
      );
    } else if (parsedValue <= 69) {
      return (
        <div
          style={{
            backgroundColor: "#b91c1c",
            color: "white",
            padding: "2px 5px",
            borderRadius: "10px",
            marginTop: "10px",
            fontWeight: "bold",
          }}
        >
          Complete all pending and future activities to meet the requirements of
          the program
        </div>
      );
    }
  };

  const finalAssessment = (courseSelected?.CourseClasses ?? []).find(
    (item) => item.classType === "finalAssessment",
  );

  return (
    <div
      className="container-certificate-dashboard"
      style={{
        marginTop: "-2.5rem",
        paddingBottom: "15rem",
      }}
    >
      <ChatBox userProfile={userProfile} slug={match.params.id} />
      <div 
      className="container-firstsegment-certificate"
      >
        <div className="container-left-certificate">
          <div
            // className="container-tabs-certificates"
            className="certificate-dashboard-left-mobile"
          >
            <Tabs
              activeKey={currentTab}
              // activeKey="4"
              tabPosition="left"
              className="certificate-program-tabs"
              onChange={(e) => setCurrentTab(e)}
              destroyInactiveTabPane={true}
              items={TabData.map((item, index) => {
                return {
                  label: item.title,
                  key: index.toString(),
                  children: item.content(),
                };
              })}
            />
          </div>
        </div>
        <div className="certificate-right-container">
          {/*<div className="container-right-certificate">*/}
          {/*  /!*<div*!/*/}
          {/*  /!*  className="container-banner-logo"*!/*/}
          {/*  /!*  style={{ width: "100%" }}*!/*/}
          {/*  /!*></div>*!/*/}
          {/*  <div*/}
          {/*    className="container-card-certificate"*/}
          {/*    style={{ width: "100%" }}*/}
          {/*  >*/}
          {/*    <div className="container-image" style={{ width: "100%" }}>*/}
          {/*      <img src={courseSelected?.image} alt="img"></img>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div className="container-right-certificate-infos">*/}

          {/*  {classLive && (*/}
          {/*    <div*/}
          {/*      className="button-class-live"*/}
          {/*      onClick={() => {*/}
          {/*        window.open(classThatIsLive?.linkToJoin, "_blank");*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      JOIN LIVE CLASS NOW*/}
          {/*    </div>*/}
          {/*  )}*/}
          {/*</div>*/}
        </div>
      </div>
      <ClosedCertificateProgramModal
        visible={openModal2}
        onClose={() => closeModal2(false)}
      />
      <NotYetOpenedProgramModal
        visible={!!notYetOpenedText}
        onCancel={() => setNotYetOpenedText("")}
        text={notYetOpenedText}
      />
      <DiplomaModal
        visible={diplomaModal}
        onClose={() => setDiplomaModal(false)}
        finalAssessment={finalAssessment}
        certificateId={courseSelected?.id}
        slug={slug}
        userProfile={userProfile}
      />
      <Modal
        className="modal-login"
        width={"1080px"}
        footer={null}
        style={{ height: "auto", top: "0px" }}
        visible={modal3 !== false}
        onCancel={() => setModal3(false)}
      >
        <div className="login-page-2" style={{ width: "100%" }}>
          <div
            className="login-dialog"
            style={{
              paddingTop: "40px",
              paddingBottom: "40px",
              width: "100%",
            }}
          >
            <div className="login-dialog-header" style={{ padding: "10px" }}>
              <h3 style={{ textAlign: "center" }}>{modal3?.title}</h3>
              <div style={{ width: "100%", height: "auto" }}>
                <MicroConferenceVideoWrapper
                  url={modal3?.videoLink ? modal3?.videoLink : null}
                  id={modal3?.id}
                />
              </div>
              <div
                className="login-dialog-logo"
                style={{ borderRadius: "50%", background: "white" }}
              >
                <img src={IconLogo} alt="login-logo" />
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {bulCompleteProfile && (
        <div className="complete-profile">
          <ModalCompleteYourProfile
            userProfile={userProfile}
            get={getUser}
            setBulKnowRegister={(data) => {
              console.log("aaa", data);
            }}
            setBulCompleteProfile={setBulCompleteProfile}
            onClose={() => setBulCompleteProfile(false)}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  userProfile: homeSelector(state).userProfile,
  certificateUsersInfo: courseSelector(state).certificateUsersInfo,
  s3Hash: envSelector(state).s3Hash,
});

const mapDispatchToProps = {
  ...courseActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(CertificatesPage);
