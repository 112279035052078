import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { CustomDrawer } from "components";
import { EVENT_TYPES } from "enum";
import Emitter from "services/emitter";
import { updateUser } from "redux/actions/home-actions";
import { TIMEZONE_LIST, COUNTRIES } from "enum";

import ProfileEditPanel from "./ProfileEditPanel";
import ProfileViewPanel from "./ProfileViewPanel";

import { homeSelector } from "redux/selectors/homeSelector";

class ProfileDrawer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      edit: false,
    };
  }

  componentDidMount() {
    Emitter.on(EVENT_TYPES.EVENT_VIEW_PROFILE, (edit) => {
      this.setState({ visible: true, edit: !!edit });
    });
  }

  onDrawerClose = () => {
    this.setState({ visible: false, edit: false });
  };

  onEdit = () => {
    this.setState({ edit: true });
  };

  onSave = (userInfo) => {
    this.props.updateUser(userInfo);
    this.setState({ edit: false });
  };

  render() {
    const { visible, edit } = this.state;
    const { userProfile: user } = this.props;

    const timezone = (
      TIMEZONE_LIST.find((item) => item.value === user.timezone) || {}
    ).text;
    const location = (
      COUNTRIES.find((item) => item.value === user.location) || {}
    ).text;

    const esEnlace = (texto) => {
      let patronEnlaceLinkedIn = /^(https|http|ftp):\/\/[\w\-]+(\.[\w\-]+)+[^\s/$.?#].*$/i;
      return patronEnlaceLinkedIn.test(texto);
    }

    const userRequired = {
      img: !user.img ? true : false,
      firstName: !user.firstName ? true : false,
      lastName: !user.lastName ? true : false,
      titleProfessions: !user.titleProfessions ? true : false,
      company: !user.company ? true : false,
      location: !location ? true : false,
      city: !user.city ? true : false,
      timezone: !timezone ? true : false,
      languages: (!user.languages || Object.keys(user?.languages)?.length === 0) ? true : false,
      about: !user.about ? true : false,
      topicsOfInterest: (user.topicsOfInterest && Object.keys(user.topicsOfInterest).length === 0)  ? true : false,
      personalLinks: !user?.personalLinks?.hasOwnProperty("linkedin") ? true : false,
      personalLinksBad: !esEnlace(user?.personalLinks?.linkedin?.toLowerCase()),
      isOpenReceivingEmail: (user.isOpenReceivingEmail === -1) ? true : false,
      recentJobLevel: !user.recentJobLevel ? true : false,
      recentWorkArea: (Array.isArray(user.recentWorkArea) && user.recentWorkArea.length === 0) ? true : false,
      sizeOfOrganization: !user.sizeOfOrganization ? true : false,
    }

    return (
      <CustomDrawer
        title={edit ? "Edit profile" : "Profile"}
        visible={visible}
        onClose={this.onDrawerClose}
      >
        {edit && (
          <ProfileEditPanel
            user={user}
            onSave={this.onSave}
            onCancel={() => this.setState({ edit: false })}
            userRequired={userRequired}
          />
        )}
        {!edit && <ProfileViewPanel user={user} onEdit={this.onEdit} caseMentoring={false} mentee={''} mentor={''} />}
      </CustomDrawer>
    );
  }
}

ProfileDrawer.propTypes = {
  title: PropTypes.string,
};

ProfileDrawer.defaultProps = {
  title: "",
};

const mapStateToProps = (state, props) => ({
  userProfile: homeSelector(state).userProfile,
});

const mapDispatchToProps = {
  updateUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDrawer);
