import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { homeSelector } from "redux/selectors/homeSelector";
import { Link } from "react-router-dom";
import { INTERNAL_LINKS } from "enum";
import IconBack from "images/icon-back.svg";
import { Avatar } from "antd";
import "./style.scss";
import { useMutation } from "@tanstack/react-query";
import httpClient from "../../../api/module/httpClient";
import IconLoading from "images/icon-loading.gif";
import { CustomButton } from "components";

function CertificateCrashCourse({ userProfile, idCrash, slug, onlyCertificate=false }) {
  const { code:uniqueCode } = useParams();

    const { isLoading , mutate,  data: diploma }  = useMutation({
        mutationFn: async ({ uniqueCode,idCrash }) => {
            const prefix = userProfile?.id ? "private" : "public";
            let data = await httpClient.get(
                `${prefix}/crash-course/view/${uniqueCode}`,{
                    params: {
                        CertificateId: idCrash   
                    }
                }
            );
            return data.data
        },
    });

    useEffect(() => {
        mutate({uniqueCode,idCrash})
    },[uniqueCode,idCrash])

    const downloadCertificate = () => {
        // Create a data URL from the base64 string
        const dataUrl = `data:image/png;base64,${diploma?.image}`;

        // Create a temporary link element
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = `${diploma?.firstName} ${diploma?.lastName} Certificate.png`; // The name of the downloaded file

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);
    };

    return (<>
        {!onlyCertificate ? <div
            className="container-crashcourse-verify"
            style={{
                ...(localStorage.getItem("community") ? {} : { marginTop: "3rem" }),
            }}
        >
            {localStorage.getItem("community") !== null && (
                <Link to={INTERNAL_LINKS.CERTIFICATES}>
                <div className="verify-page__content-top">
                    <div className="verify-page__content-top-back">
                    <img src={IconBack} alt="icon-back" />
                    </div>
                    <h4>Back to Certificates</h4>
                </div>
                </Link>
            )}
            <div className="box-title-verify-certificate">
                <p className="course-certificate-p">Certificate Program</p>
                <p className="name-course">{diploma?.title}</p>
            </div>
            <div className="container-profile-certificate">
                <div className="container-user">
                <div className="container-data-member-certificate">
                    {!isLoading && (
                    <Avatar
                        size={150}
                        style={{ minWidth: "150px", fontSize: "4rem" }}
                        src={diploma?.userImg}
                    >
                        {diploma?.abbrName}
                    </Avatar>
                    )}
                    <div className="container-p-certficate">
                    <p className="p-title-conference">
                        Completed by{" "}
                        <b>
                        {diploma?.firstName} {diploma?.lastName}
                        </b>
                    </p>
                    </div>
                </div>
                </div>
                <div className="container-certificate">
                <div>
                    {isLoading ? (
                        <div
                            style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            }}
                        >
                            <img
                                src={IconLoading}
                                style={{ width: "40px", height: "40px" }}
                                alt="loader-digital-certificate"
                            />
                        </div>
                    ) : (
                        <img
                            src={`data:image/png;base64,${diploma?.image}`}
                            alt="ceritficate-case"
                            style={{ width: "100%", height: "100%" }}
                        />
                    )}
                </div>
                {userProfile?.id === diploma?.userId && (
                    <CustomButton
                        type="primary"
                        size="sm"
                        text="Download Certificate"
                        style={{
                            position: "absolute",
                            left: "50%",
                            top: "calc(100% + 20px)",
                            transform: "translateX(-50%)",
                        }}
                        onClick={() => downloadCertificate()}
                        loading={isLoading}
                    />
                )}
                <div
                    style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginTop: "20px",
                    }}
                >
                    <p
                    style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        margin: "0px",
                        padding: "0px",
                        marginLeft: "10px",
                        whiteSpace: "nowrap",
                    }}
                    >
                    Unique digital certificate ID:{" "}
                    </p>{" "}
                    <p
                    style={{
                        margin: "0px",
                        padding: "0px",
                        fontSize: "14px",
                        paddingLeft: "10px",
                    }}
                    >
                    {diploma?.uniqueCode}
                    </p>
                </div>
                <div
                    style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    }}
                >
                    <p
                    style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        margin: "0px",
                        padding: "0px",
                        marginLeft: "10px",
                        whiteSpace: "nowrap",
                    }}
                    >
                    Unique URL:{" "}
                    </p>{" "}
                    <p
                    style={{
                        margin: "0px",
                        padding: "0px",
                        fontSize: "14px",
                        paddingLeft: "10px",
                    }}
                    >
                    {diploma?.uniqueUrl}
                    </p>
                </div>
                <div style={{ marginLeft: "10px", marginTop: "1rem" }}>
                    {diploma?.shrmCode && (
                    <p>
                        <b>SHRM: {diploma.shrmCode}</b>
                    </p>
                    )}
                    {diploma?.hrciCode && (
                    <p>
                        <b>HRCI: {diploma.hrciCode}</b> | Credit Type{" "}
                        {diploma.hrciCreditType}
                    </p>
                    )}
                    {diploma?.ihrimCode && (
                    <p>
                        <b>HRCI: {diploma.ihrimCode}</b>
                    </p>
                    )}
                </div>
                </div>
            </div>
        </div> :  <div className="container-certificate" style={{maxWidth:'600px'}}>
                <div>
                    {isLoading ? (
                        <div
                            style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            }}
                        >
                            <img
                                src={IconLoading}
                                style={{ width: "40px", height: "40px" }}
                                alt="loader-digital-certificate"
                            />
                        </div>
                    ) : (
                        <img
                            src={`data:image/png;base64,${diploma?.image}`}
                            alt="ceritficate-case"
                            style={{ width: "100%", height: "100%" }}
                        />
                    )}
                </div>
                {(userProfile?.id === diploma?.userId) && <div style={{width:'100%',height:'auto',display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <CustomButton
                        type="primary"
                        size="sm"
                        text="Download Certificate Of Completion"
                        style={{ background: "#F05C27", width: "320px", padding:'0px', color: "white", textAlign: "center", display: "flex", alignItems: "center", gap: "5px", flexDirection: "row-reverse", justifyContent: "space-around", marginTop: '10px' }}
                        onClick={() => downloadCertificate()}
                        loading={isLoading}
                    />
                </div>}
                <div
                    style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginTop: "20px",
                    }}
                >
                    <p
                    style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        margin: "0px",
                        padding: "0px",
                        marginLeft: "10px",
                        whiteSpace: "nowrap",
                    }}
                    >
                    Unique digital certificate ID:{" "}
                    </p>{" "}
                    <p
                    style={{
                        margin: "0px",
                        padding: "0px",
                        fontSize: "14px",
                        paddingLeft: "10px",
                    }}
                    >
                    {diploma?.uniqueCode}
                    </p>
                </div>
                <div
                    style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    }}
                >
                    <p
                    style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        margin: "0px",
                        padding: "0px",
                        marginLeft: "10px",
                        whiteSpace: "nowrap",
                        textWrap:'nowrap',
                    }}
                    >
                    Unique URL:{" "}
                    </p>{" "}
                    <p
                    style={{
                        margin: "0px",
                        padding: "0px",
                        fontSize: "14px",
                        paddingLeft: "10px",
                        textWrap:'nowrap'
                    }}
                    >
                    {diploma?.uniqueUrl}
                    </p>
                </div>
                <div style={{ marginLeft: "10px", marginTop: "1rem" }}>
                    {diploma?.shrmCode && (
                    <p style={{textWrap:'nowrap'}}>
                        <b>SHRM: {diploma.shrmCode}</b>
                    </p>
                    )}
                    {diploma?.hrciCode && (
                    <p style={{textWrap:'nowrap'}}>
                        <b>HRCI: {diploma.hrciCode}</b> | Credit Type{" "}
                        {diploma.hrciCreditType}
                    </p>
                    )}
                    {diploma?.ihrimCode && (
                    <p style={{textWrap:'nowrap'}}>
                        <b>HRCI: {diploma.ihrimCode}</b>
                    </p>
                    )}
                </div>
        </div>}
    </>);
}

const mapStateToProps = (state) => ({
  userProfile: homeSelector(state).userProfile,
});

export default connect(mapStateToProps)(CertificateCrashCourse);
