import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Avatar } from "antd";
import { CustomButton } from "components";
import UserProfileModal from "containers/UserProfileModal";

import "./style.scss";
import { categorySelector } from "redux/selectors/categorySelector";
import { getOneUserById } from "api";

const MemberSpeakers = ({
    usersPanel,
    isAdmin,
    remove,
    removeAdmin,
    removeExternal,
    joinAdmin,
    emailOfAdmin = undefined,
    allCategories
}) => {
  const [showProfile, setShowProfile] = useState(false);
  const [userProfile, setUserProfile] = useState({});

    const { id, isModerator, UserId, buttonsAccept, pending, isExternalUser, isKeynote} = usersPanel
    const { firstName, img, titleProfessions, lastName, abbrName } = usersPanel?.User || {};

    let bul1 = (pending === true && isAdmin)
    let bul2 = (pending === undefined)
    let bul3 = (pending === false && isAdmin)
    let bul4 = (bul1 || bul2 || bul3)
  
    const getOneUser = async (id) => {
      const response = await getOneUserById(id);
      setUserProfile(response?.data?.user || {})
      setShowProfile(true)
  }

    return (
      <>
        {bul4 && <div className="container-users" key={id} id={UserId}>
          <div className="container-data-member">
            {img ? (
              <Avatar size={55} src={img} />
            ) : (
              <Avatar size={55} style={{ fontSize: "1rem" }}>
                {abbrName}
              </Avatar>
            )}
            <div className="container-p">
              <p className="p-title">{firstName} {lastName}</p>
              <p className="p-profession">{titleProfessions}{isModerator ? " / Session Moderator" : ""}{(isKeynote === true || isKeynote === 'true') ? " / Keynote Speakers" : ""}</p>
            </div>
          </div>
          {(isAdmin && !buttonsAccept) &&
            <CustomButton
              className="button-speaker"
              text="Remove"
              size="sm"
              type="third"
              onClick={() => {
                if(isExternalUser){
                  removeExternal()
                }else{
                  removeAdmin()
                }
              }}
            />
          }
          {(isAdmin && buttonsAccept) && 
            <CustomButton
              className="button-speaker"
              text="Approve"
              size="sm"
              type="secondary"
              onClick={() => {joinAdmin()}}
            />
          }
          {(isAdmin && buttonsAccept) && 
            <CustomButton
              className="button-speaker"
              text="Reject"
              size="sm"
              type="third"
              onClick={() => {remove()}}
            />
          }
          {((isAdmin && emailOfAdmin !== undefined && (emailOfAdmin === 'Enrique@hackinghr.io' || emailOfAdmin === 'mkeivy.mora@gmail.com')) && !isExternalUser) && 
            <CustomButton
              className="button-speaker"
              text="Show Profile"
              size="sm"
              // type=""
            onClick={() => {
            getOneUser(UserId)}}
            />
          }
        </div>}

        {(pending === true && !isAdmin) && <div className="container-users" key={id} id={UserId} style={{opacity: '60%'}}>
          <div className="container-data-member">
            {img ? (
              <Avatar size={55} src={img} />
            ) : (
              <Avatar size={55} style={{ fontSize: "1rem" }}>
                {abbrName}
              </Avatar>
            )}
            <div className="container-p">
              <p className="p-title">{firstName} {lastName}</p>
              <p className="p-profession">{titleProfessions}{isModerator ? " / Moderator" : ""}</p>
              <p className="p-profession" style={{fontWeight: 'bold'}}>Pending</p>
            </div>
          </div>
        </div>}

        <UserProfileModal modalUserProfile={showProfile} setModalUserProfile={setShowProfile} userProfile={userProfile} allCategories={allCategories} />
      </>
    );
  };
  
  const mapStateToProps = (state, props) => ({
    allCategories: categorySelector(state).categories,
  });
  
  const mapDispatchToProps = {

  };

  MemberSpeakers.propTypes = {
    usersPanel: PropTypes.object,
    isAdmin: PropTypes.bool,
    remove: PropTypes.func,
    emailOfAdmin: PropTypes.string
  };
  
  MemberSpeakers.defaultProps = {
    usersPanel: {},
    isAdmin: false,
    remove: () => { },
    emailOfAdmin: undefined
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(MemberSpeakers);
  