import React from "react";

const CoheteIcon = ({color = 'black', width = '24px', height = '24px'}) => {
  return (
    <div style={{width:width,height:height}}>
        <svg width="452" height="451" viewBox="0 0 452 451" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M123 245.844L108.626 274.805C108.542 274.974 108.438 275.126 108.313 275.267C104.664 279.403 99.8484 289.407 108.5 298.059C117.265 306.824 140.317 329.876 150.875 340.434C150.961 340.52 151.047 340.591 151.147 340.66C156.066 344.004 167.58 349.518 175.5 345.559C183.5 341.559 196.833 332.229 203.5 328.729M147.5 140.559L96.5 218.559M96.5 218.559L230.409 356.436C231.05 357.096 232.062 357.231 232.855 356.766C280.28 328.948 317.701 299.293 347 269.343M96.5 218.559L17.6255 228.237C16.3085 228.398 15.1968 227.29 15.4274 225.983C24.0366 177.18 68.2714 88.9951 181.509 106.404C182.121 106.498 182.743 106.329 183.195 105.906C229.983 62.0977 344.939 -16.1218 437.509 15.2191C438.129 15.4288 438.601 15.9113 438.772 16.5422C452.128 65.6756 444.999 169.164 347 269.343M227 398.559L222.361 434.28C222.184 435.642 223.364 436.78 224.704 436.481C278.127 424.594 373.349 375.749 347 269.343M51.5 345.559C26.91 360.781 12.6987 414.058 8.3086 440.631C8.13066 441.708 8.85477 442.71 9.93823 442.844C41.9832 446.786 90.3095 415.709 105.5 398.559C117.9 384.559 108.333 363.392 102 354.559C95.6667 345.726 76.7 329.959 51.5 345.559Z" stroke={color} stroke-width="15"/>
            <circle cx="296.5" cy="156.559" r="51.5" stroke={color} stroke-width="15"/>
        </svg>
    </div>
  );
};

export default CoheteIcon;
