import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ModalCompleteYourProfile } from "components";
import PhotoUploadForm from "./PhotoUploadForm";
import { Checkbox, Radio, Modal } from "antd";
import clsx from "clsx";

import {
  CustomInput,
  CustomButton,
  CustomCheckbox,
  CustomSelect,
  CustomRadio,
} from "components";

import { CONTACT_ICONS, COUNTRIES, PROFILE_SETTINGS } from "enum";

import { EditOutlined, CheckOutlined } from "@ant-design/icons";
import { categorySelector } from "redux/selectors/categorySelector";
import { updateUser } from "redux/actions/home-actions";

import "./style.scss";

const JobLevels = PROFILE_SETTINGS.JOB_LEVELS;

const WorkAreas = PROFILE_SETTINGS.WORK_AREAS;

const OrgSizes = PROFILE_SETTINGS.ORG_SIZES;

const GeneralProfile = ({
  mentorProfile,
  menteeProfile,
  createMentor,
  createMentee,
  updateMentor,
  updateMente,
  userProfile,
  getOneMentor,
  getOneMentee,
  allCategories,
  updateUser,
  getUser,
}) => {
  // const inputText1 = useRef();
  // const inputText2 = useRef();
  const [text1, setText1] = useState("");
  const [text2, setText2] = useState("");
  const [onChange, setOnChange] = useState(false);
  const [fields, setFields] = useState({});
  const [modalPhoto, setModalPhoto] = useState(false);
  const [bulArrarys, setBulArrays] = useState({
    email: false,
    titleProfessions: false,
    company: false,
    location: false,
    city: false,
    about: false,
    topicsOfInterest: false,
    personalLinks: false,
    isOpenReceivingEmail: false,
    recentJobLevel: false,
    recentWorkArea: false,
    sizeOfOrganization: false,
  });
  const [bulModal, setBulModal] = useState(false);
  const [informationPhoto, setInformationPhoto] = useState({
    editImageUrl: "",
    imageStr: "",
  });

  const onFieldChange = (field, value, subField) => {
    setOnChange(true);
    if (field === "personalLinks" || field === "languages") {
      setFields({
        ...fields,
        [field]: { ...fields[field], [subField]: value },
      });
    } else {
      setFields({ ...fields, [field]: value });
    }
  };

  const onSave = (userInfo) => {
    setBulArrays({
      email: false,
      titleProfessions: false,
      company: false,
      location: false,
      city: false,
      about: false,
      topicsOfInterest: false,
      personalLinks: false,
      isOpenReceivingEmail: false,
      recentJobLevel: false,
      recentWorkArea: false,
      sizeOfOrganization: false,
    });
    updateUser({
      ...userProfile,
      ...fields,
      imageStr: informationPhoto.imageStr,
    });
  };

  const onPhotoSave = (url, base64) => {
    setModalPhoto(false);
    setOnChange(true);
    setInformationPhoto({
      editImageUrl: url,
      imageStr: base64,
    });
  };

  useEffect(() => {
    setInformationPhoto({
      editImageUrl: userProfile.img,
      imageStr: userProfile.imageStr,
    });
  }, [userProfile]);

  useEffect(() => {
    if (text1 === "" && mentorProfile?.status === "active") {
      setText1(mentorProfile?.textDescription);
    }
    if (text2 === "" && menteeProfile?.status === "active") {
      setText2(menteeProfile?.textDescription);
    }
    if (mentorProfile?.status === "close") {
      setText1("");
    }
    if (menteeProfile?.status === "close") {
      setText2("");
    }
  }, [mentorProfile, menteeProfile, text1, text2]);

  useEffect(() => {
    if (mentorProfile?.textDescription !== text1 && text1 !== "") {
      setText1(mentorProfile?.textDescription);
    }
    if (menteeProfile?.textDescription !== text2 && text2 !== "") {
      setText2(menteeProfile?.textDescription);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentorProfile, menteeProfile]);

  const profileData =
    userProfile?.id !== undefined && userProfile?.id !== "" ? (
      <div className="profile-edit-panel-content">
        <h5 className="textfield-label">
          Email
          {!bulArrarys["email"] ? (
            <EditOutlined
              onClick={() => {
                setBulArrays({ ...bulArrarys, email: true });
              }}
              style={{
                cursor: "pointer",
                fontSize: "17px",
                margin: "0px",
                padding: "0px",
                transform: "translateY(-4px) translateX(4px)",
              }}
            />
          ) : (
            <CheckOutlined
              onClick={() => {
                setBulArrays({ ...bulArrarys, email: false });
                updateUser({ ...userProfile, email: fields["email"] });
              }}
              style={{
                cursor: "pointer",
                fontSize: "17px",
                margin: "0px",
                padding: "0px",
                transform: "translateY(-4px) translateX(4px)",
              }}
            />
          )}
        </h5>
        {bulArrarys["email"] ? (
          <CustomInput
            className="textfield-input"
            defaultValue={userProfile?.email}
            onChange={(value) => onFieldChange("email", value)}
          />
        ) : (
          <h3 className="textfield-value completed">{userProfile?.email}</h3>
        )}
        <h5 className="textfield-label">
          Title
          {!bulArrarys["titleProfessions"] ? (
            <EditOutlined
              onClick={() => {
                setBulArrays({ ...bulArrarys, titleProfessions: true });
              }}
              style={{
                cursor: "pointer",
                fontSize: "17px",
                margin: "0px",
                padding: "0px",
                transform: "translateY(-4px) translateX(4px)",
              }}
            />
          ) : (
            <CheckOutlined
              onClick={() => {
                setBulArrays({ ...bulArrarys, titleProfessions: false });
                updateUser({
                  ...userProfile,
                  titleProfessions: fields["titleProfessions"],
                });
              }}
              style={{
                cursor: "pointer",
                fontSize: "17px",
                margin: "0px",
                padding: "0px",
                transform: "translateY(-4px) translateX(4px)",
              }}
            />
          )}
        </h5>
        {bulArrarys["titleProfessions"] ? (
          <CustomInput
            className="textfield-input"
            defaultValue={userProfile?.titleProfessions}
            onChange={(value) => onFieldChange("titleProfessions", value)}
          />
        ) : (
          <h3
            className={clsx("textfield-value", {
              completed: !!userProfile?.titleProfessions,
            })}
          >
            {userProfile?.titleProfessions || "-"}
          </h3>
        )}
        <h5 className="textfield-label">
          Company
          {!bulArrarys["company"] ? (
            <EditOutlined
              onClick={() => {
                setBulArrays({ ...bulArrarys, company: true });
              }}
              style={{
                cursor: "pointer",
                fontSize: "17px",
                margin: "0px",
                padding: "0px",
                transform: "translateY(-4px) translateX(4px)",
              }}
            />
          ) : (
            <CheckOutlined
              onClick={() => {
                setBulArrays({ ...bulArrarys, company: false });
                updateUser({ ...userProfile, company: fields["company"] });
              }}
              style={{
                cursor: "pointer",
                fontSize: "17px",
                margin: "0px",
                padding: "0px",
                transform: "translateY(-4px) translateX(4px)",
              }}
            />
          )}
        </h5>
        {bulArrarys["company"] ? (
          <CustomInput
            className="textfield-input"
            defaultValue={userProfile?.company}
            onChange={(value) => onFieldChange("company", value)}
          />
        ) : (
          <h3
            className={clsx("textfield-value", {
              completed: !!userProfile?.company,
            })}
          >
            {userProfile?.company || "-"}
          </h3>
        )}
        <h5 className="textfield-label">
          Location
          {!bulArrarys["location"] ? (
            <EditOutlined
              onClick={() => {
                setBulArrays({ ...bulArrarys, location: true });
              }}
              style={{
                cursor: "pointer",
                fontSize: "17px",
                margin: "0px",
                padding: "0px",
                transform: "translateY(-4px) translateX(4px)",
              }}
            />
          ) : (
            <CheckOutlined
              onClick={() => {
                setBulArrays({ ...bulArrarys, location: false });
                updateUser({ ...userProfile, location: fields["location"] });
              }}
              style={{
                cursor: "pointer",
                fontSize: "17px",
                margin: "0px",
                padding: "0px",
                transform: "translateY(-4px) translateX(4px)",
              }}
            />
          )}
        </h5>
        {bulArrarys["location"] ? (
          <CustomSelect
            className="border"
            showSearch
            options={COUNTRIES}
            value={userProfile?.location}
            optionFilterProp="location"
            onChange={(value) => onFieldChange("location", value)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          />
        ) : (
          <h3
            className={clsx("textfield-value", {
              completed: !!userProfile?.location,
            })}
          >
            {userProfile?.location || "-"}
          </h3>
        )}
        <h5 className="textfield-label">
          City
          {!bulArrarys["city"] ? (
            <EditOutlined
              onClick={() => {
                setBulArrays({ ...bulArrarys, city: true });
              }}
              style={{
                cursor: "pointer",
                fontSize: "17px",
                margin: "0px",
                padding: "0px",
                transform: "translateY(-4px) translateX(4px)",
              }}
            />
          ) : (
            <CheckOutlined
              onClick={() => {
                setBulArrays({ ...bulArrarys, city: false });
                updateUser({ ...userProfile, city: fields["city"] });
              }}
              style={{
                cursor: "pointer",
                fontSize: "17px",
                margin: "0px",
                padding: "0px",
                transform: "translateY(-4px) translateX(4px)",
              }}
            />
          )}
        </h5>
        {bulArrarys["city"] ? (
          <CustomInput
            className="textfield-input"
            defaultValue={userProfile.city}
            onChange={(value) => onFieldChange("city", value)}
          />
        ) : (
          <h3
            className={clsx("textfield-value", {
              completed: !!userProfile.city,
            })}
          >
            {userProfile.city || "-"}
          </h3>
        )}
        <h5 className="textfield-label">
          Tell us more about you
          {!bulArrarys["about"] ? (
            <EditOutlined
              onClick={() => {
                setBulArrays({ ...bulArrarys, about: true });
              }}
              style={{
                cursor: "pointer",
                fontSize: "17px",
                margin: "0px",
                padding: "0px",
                transform: "translateY(-4px) translateX(4px)",
              }}
            />
          ) : (
            <CheckOutlined
              onClick={() => {
                setBulArrays({ ...bulArrarys, about: false });
                updateUser({ ...userProfile, about: fields["about"] });
              }}
              style={{
                cursor: "pointer",
                fontSize: "17px",
                margin: "0px",
                padding: "0px",
                transform: "translateY(-4px) translateX(4px)",
              }}
            />
          )}
        </h5>
        {bulArrarys["about"] ? (
          <CustomInput
            className="textfield-input"
            multiple={true}
            defaultValue={userProfile.about}
            onChange={(value) => onFieldChange("about", value)}
          />
        ) : (
          <h3
            className={clsx("textfield-value", {
              completed: !!userProfile.about,
            })}
          >
            {userProfile.about || "-"}
          </h3>
        )}
        <h5 className="textfield-label">
          Topics of interest
          {!bulArrarys["topicsOfInterest"] ? (
            <EditOutlined
              onClick={() => {
                setBulArrays({ ...bulArrarys, topicsOfInterest: true });
              }}
              style={{
                cursor: "pointer",
                fontSize: "17px",
                margin: "0px",
                padding: "0px",
                transform: "translateY(-4px) translateX(4px)",
              }}
            />
          ) : (
            <CheckOutlined
              onClick={() => {
                setBulArrays({ ...bulArrarys, topicsOfInterest: false });
                updateUser({
                  ...userProfile,
                  topicsOfInterest: fields["topicsOfInterest"],
                });
              }}
              style={{
                cursor: "pointer",
                fontSize: "17px",
                margin: "0px",
                padding: "0px",
                transform: "translateY(-4px) translateX(4px)",
              }}
            />
          )}
        </h5>
        {bulArrarys["topicsOfInterest"] ? (
          <Checkbox.Group
            defaultValue={userProfile.topicsOfInterest}
            className="custom-checkbox-group"
            onChange={(values) => onFieldChange("topicsOfInterest", values)}
          >
            {allCategories?.map((topic) => (
              <CustomCheckbox key={topic.value} value={topic.value}>
                {topic.title}
              </CustomCheckbox>
            ))}
          </Checkbox.Group>
        ) : (
          <h3
            className={clsx("textfield-value", {
              completed:
                userProfile?.topicsOfInterest &&
                userProfile?.topicsOfInterest?.length,
            })}
          >
            {userProfile.topicsOfInterest
              ?.map((item) => {
                const index = allCategories.findIndex((t) => t.value === item);
                if (index >= 0) {
                  return allCategories[index].title;
                }
                return item;
              })
              .join(", ") || "-"}
          </h3>
        )}
        <h5 className="textfield-label">
          Personal links
          {!bulArrarys["personalLinks"] ? (
            <EditOutlined
              onClick={() => {
                setBulArrays({ ...bulArrarys, personalLinks: true });
              }}
              style={{
                cursor: "pointer",
                fontSize: "17px",
                margin: "0px",
                padding: "0px",
                transform: "translateY(-4px) translateX(4px)",
              }}
            />
          ) : (
            <CheckOutlined
              onClick={() => {
                setBulArrays({ ...bulArrarys, personalLinks: false });
                updateUser({
                  ...userProfile,
                  personalLinks: fields["personalLinks"],
                });
              }}
              style={{
                cursor: "pointer",
                fontSize: "17px",
                margin: "0px",
                padding: "0px",
                transform: "translateY(-4px) translateX(4px)",
              }}
            />
          )}
        </h5>
        {bulArrarys["personalLinks"] ? (
          <div className="personal-links">
            {Object.keys(CONTACT_ICONS)?.map((contact) => (
              <div className="personal-link" key={contact}>
                <div className="personal-link-icon">
                  <i className={CONTACT_ICONS[contact]} />
                </div>
                <CustomInput
                  addonBefore="https://"
                  defaultValue={
                    userProfile?.personalLinks !== undefined &&
                    userProfile?.personalLinks[contact]
                      ? userProfile?.personalLinks[contact].replace(
                          "https://",
                          ""
                        )
                      : ""
                  }
                  onChange={(value) =>
                    onFieldChange("personalLinks", value, contact)
                  }
                />
              </div>
            ))}
          </div>
        ) : (
          Object.keys(userProfile?.personalLinks).map((contact) => {
            if (contact === "linkedin") {
              return (
                <div className="personal-link" key={contact}>
                  <div className="personal-link-icon">
                    <i className={CONTACT_ICONS[contact]} />
                  </div>
                  <h3 className="textfield-value completed">
                    {userProfile.personalLinks[contact] || ""}
                  </h3>
                </div>
              );
            } else {
              return null;
            }
          })
        )}
        <h5 className="textfield-label">
          Are open to receiving information/being contacted via email about open
          job positions?
          {!bulArrarys["isOpenReceivingEmail"] ? (
            <EditOutlined
              onClick={() => {
                setBulArrays({ ...bulArrarys, isOpenReceivingEmail: true });
              }}
              style={{
                cursor: "pointer",
                fontSize: "17px",
                margin: "0px",
                padding: "0px",
                transform: "translateY(-4px) translateX(4px)",
              }}
            />
          ) : (
            <CheckOutlined
              onClick={() => {
                setBulArrays({ ...bulArrarys, isOpenReceivingEmail: false });
                updateUser({
                  ...userProfile,
                  isOpenReceivingEmail: fields["isOpenReceivingEmail"],
                });
              }}
              style={{
                cursor: "pointer",
                fontSize: "17px",
                margin: "0px",
                padding: "0px",
                transform: "translateY(-4px) translateX(4px)",
              }}
            />
          )}
        </h5>
        {bulArrarys["isOpenReceivingEmail"] ? (
          <Radio.Group
            className="open-receive-email"
            value={userProfile?.isOpenReceivingEmail}
            onChange={(e) =>
              onFieldChange("isOpenReceivingEmail", e.target.value)
            }
          >
            <CustomRadio value={1}>Yes</CustomRadio>
            <CustomRadio value={0}>No</CustomRadio>
          </Radio.Group>
        ) : (
          <h3
            className={clsx("textfield-value", {
              completed: userProfile.isOpenReceivingEmail !== -1,
            })}
          >
            {userProfile.isOpenReceivingEmail === 1
              ? "Yes"
              : userProfile.isOpenReceivingEmail === 0
              ? "No"
              : "-"}
          </h3>
        )}
        <h5 className="textfield-label">
          What best defines your current or most recent job level?
          {!bulArrarys["recentJobLevel"] ? (
            <EditOutlined
              onClick={() => {
                setBulArrays({ ...bulArrarys, recentJobLevel: true });
              }}
              style={{
                cursor: "pointer",
                fontSize: "17px",
                margin: "0px",
                padding: "0px",
                transform: "translateY(-4px) translateX(4px)",
              }}
            />
          ) : (
            <CheckOutlined
              onClick={() => {
                setBulArrays({ ...bulArrarys, recentJobLevel: false });
                updateUser({
                  ...userProfile,
                  recentJobLevel: fields["recentJobLevel"],
                });
              }}
              style={{
                cursor: "pointer",
                fontSize: "17px",
                margin: "0px",
                padding: "0px",
                transform: "translateY(-4px) translateX(4px)",
              }}
            />
          )}
        </h5>
        {bulArrarys["recentJobLevel"] ? (
          <Radio.Group
            className="d-flex flex-column radio-group"
            value={userProfile?.recentJobLevel}
            onChange={(e) => onFieldChange("recentJobLevel", e.target.value)}
          >
            {JobLevels.map((job) => (
              <CustomRadio key={job.value} value={job.value}>
                {job.label}
              </CustomRadio>
            ))}
          </Radio.Group>
        ) : (
          <h3
            className={clsx("textfield-value", {
              completed: !!userProfile?.recentJobLevel,
            })}
          >
            {userProfile?.recentJobLevel || "-"}
          </h3>
        )}
        <h5 className="textfield-label">
          In what area of HR do you currently work or most recently worked?
          {!bulArrarys["recentWorkArea"] ? (
            <EditOutlined
              onClick={() => {
                setBulArrays({ ...bulArrarys, recentWorkArea: true });
              }}
              style={{
                cursor: "pointer",
                fontSize: "17px",
                margin: "0px",
                padding: "0px",
                transform: "translateY(-4px) translateX(4px)",
              }}
            />
          ) : (
            <CheckOutlined
              onClick={() => {
                setBulArrays({ ...bulArrarys, recentWorkArea: false });
                updateUser({
                  ...userProfile,
                  recentWorkArea: fields["recentWorkArea"],
                });
              }}
              style={{
                cursor: "pointer",
                fontSize: "17px",
                margin: "0px",
                padding: "0px",
                transform: "translateY(-4px) translateX(4px)",
              }}
            />
          )}
        </h5>
        {bulArrarys["recentWorkArea"] ? (
          <Checkbox.Group
            className="d-flex flex-column custom-checkbox-group"
            defaultValue={userProfile.recentWorkArea}
            onChange={(values) => onFieldChange("recentWorkArea", values)}
          >
            {WorkAreas.map((area, index) => (
              <CustomCheckbox key={index} value={area.value}>
                {area.label}
              </CustomCheckbox>
            ))}
          </Checkbox.Group>
        ) : (
          <h3
            className={clsx("textfield-value", {
              completed:
                userProfile.recentWorkArea && userProfile.recentWorkArea.length,
            })}
          >
            {userProfile.recentWorkArea.includes("all")
              ? WorkAreas.filter((item) => item.value !== "all")
                  .map((item) => item.label)
                  .join(", ")
              : userProfile.recentWorkArea.join(", ") || "-"}
          </h3>
        )}
        <h5 className="textfield-label">
          What is the size of the organization your work for?
          {!bulArrarys["resizeOfOrganization"] ? (
            <EditOutlined
              onClick={() => {
                setBulArrays({ ...bulArrarys, sizeOfOrganization: true });
              }}
              style={{
                cursor: "pointer",
                fontSize: "17px",
                margin: "0px",
                padding: "0px",
                transform: "translateY(-4px) translateX(4px)",
              }}
            />
          ) : (
            <CheckOutlined
              onClick={() => {
                setBulArrays({ ...bulArrarys, sizeOfOrganization: false });
                updateUser({
                  ...userProfile,
                  sizeOfOrganization: fields["sizeOfOrganization"],
                });
              }}
              style={{
                cursor: "pointer",
                fontSize: "17px",
                margin: "0px",
                padding: "0px",
                transform: "translateY(-4px) translateX(4px)",
              }}
            />
          )}
        </h5>
        {bulArrarys["resizeOfOrganization"] ? (
          <Radio.Group
            className="d-flex flex-column radio-group"
            value={userProfile.sizeOfOrganization}
            onChange={(e) =>
              onFieldChange("sizeOfOrganization", e.target.value)
            }
          >
            {OrgSizes?.map((org) => (
              <CustomRadio key={org.value} value={org.value}>
                {org.label}
              </CustomRadio>
            ))}
          </Radio.Group>
        ) : (
          <h3
            className={clsx("textfield-value", {
              completed: !!userProfile.sizeOfOrganization,
            })}
          >
            {userProfile.sizeOfOrganization || "-"}
          </h3>
        )}
      </div>
    ) : (
      <div></div>
    );

  return (
    <div className="container-mentoring-general-profile">
      <div className="container-right-general-profile">
        <div
          //   className="container-buttom"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {/* <CustomButton
            key="PREVIEW PROFILE"
            text="PREVIEW PROFILE"
            type="primary"
            size="xl"
            className="button-modal"
            style={{ padding: "0px 10px" }}
            onClick={() => {
              setBulModal(true);
            }}
          /> */}
          {/* {onChange && (
            <CustomButton
              key="SAVE"
              text="SAVE"
              type="primary"
              size="xl"
              className="button-modal"
              style={{ padding: "0px 10px", marginLeft: "20px" }}
              onClick={() => {
                if (text1 !== mentorProfile?.textDescription) {
                  updateMentor(
                    {
                      id: userProfile?.id,
                      textDescription: text1,
                    },
                    () => {
                      getOneMentor();
                    }
                  );
                }
                if (text2 !== menteeProfile?.textDescription) {
                  updateMente(
                    {
                      id: userProfile?.id,
                      textDescription: text2,
                    },
                    () => {
                      getOneMentee();
                    }
                  );
                }
                if (Object.keys(fields)?.length > 0) {
                  onSave();
                }

                setOnChange(false);
              }}
            />
          )} */}
        </div>
        {/* <div
          className="profile-edit-panel-content"
          style={{
            height: "auto",
            marginBottom: "0px",
            paddingBottom: "0px",
            paddingTop: "0px",
          }}
        >
          <div className="container-footer">
            <div className="footer-left">
              <p className="textfield-label">
                Do you want to activate your mentor profile?
              </p>
              <Switch
                checkedChildren="On"
                unCheckedChildren="Off"
                style={{ width: "100px" }}
                checked={
                  mentorProfile?.id !== undefined
                    ? mentorProfile?.status === "active"
                    : false
                }
                onChange={() => {
                  if (mentorProfile?.id === undefined) {
                    createMentor(
                      {
                        idUser: userProfile?.id,
                        status: "active",
                      },
                      () => {
                        getOneMentor();
                      }
                    );
                  } else {
                    console.log(mentorProfile?.status === "active");
                    if (mentorProfile?.status === "active") {
                      updateMentor(
                        {
                          id: userProfile?.id,
                          status: "close",
                        },
                        () => {
                          getOneMentor();
                        }
                      );
                    } else {
                      updateMentor(
                        {
                          id: userProfile?.id,
                          status: "active",
                        },
                        () => {
                          getOneMentor();
                        }
                      );
                    }
                  }
                }}
              />
              {(mentorProfile?.id !== undefined
                ? mentorProfile?.status === "active"
                : false) && (
                <>
                  <p className="textfield-label">
                    Describe your interests as a mentor and the types of the
                    types of mentees you want to connect with
                  </p>
                  <CustomInput
                    multiple={true}
                    ref={inputText1}
                    style={{ width: "100%" }}
                    defaultValue={mentorProfile?.textDescription}
                    value={text1 || ""}
                    onChange={(e) => {
                      setOnChange(true);
                      setText1(e);
                    }}
                  />
                </>
              )}
            </div>
            <div className="footer-right">
              <p className="textfield-label">
                Do you want to activate your mentee profile?
              </p>
              <Switch
                checkedChildren="On"
                unCheckedChildren="Off"
                style={{ width: "100px" }}
                checked={
                  menteeProfile?.id !== undefined
                    ? menteeProfile?.status === "active"
                    : false
                }
                onChange={() => {
                  if (menteeProfile?.id === undefined) {
                    createMentee(
                      {
                        idUser: userProfile?.id,
                        status: "active",
                      },
                      () => {
                        getOneMentee();
                      }
                    );
                  } else {
                    if (menteeProfile?.status === "active") {
                      updateMente(
                        {
                          id: userProfile?.id,
                          status: "close",
                        },
                        () => {
                          getOneMentee();
                        }
                      );
                    } else {
                      updateMente(
                        {
                          id: userProfile?.id,
                          status: "active",
                        },
                        () => {
                          getOneMentee();
                        }
                      );
                    }
                  }
                }}
              />
              {(menteeProfile?.id !== undefined
                ? menteeProfile?.status === "active"
                : false) && (
                <>
                  <p className="textfield-label">
                    Describe your interests as a mentee and the types of the
                    types of mentors you want to connect with
                  </p>
                  <CustomInput
                    multiple={true}
                    ref={inputText2}
                    style={{ width: "100%" }}
                    defaultValue={menteeProfile?.textDescription}
                    value={text2 || ""}
                    onChange={(e) => {
                      setOnChange(true);
                      setText2(e);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div> */}
      </div>
      <div className="container-left-general-profile">
        <div className="profile-edit-panel-header">
          <div className="profile-user-img-new">
            <div className="profile-user-img-new-container">
              {informationPhoto?.editImageUrl ? (
                <div className="profile-user-img-new-wrapper">
                  <img src={informationPhoto?.editImageUrl} alt="user-avatar" />
                </div>
              ) : (
                <h1 className="profile-user-img-new-name">
                  {userProfile?.abbrName}
                </h1>
              )}
              <div
                className="profile-user-img-new-camera"
                onClick={() => setModalPhoto(true)}
              >
                <i className="fal fa-camera" />
              </div>
            </div>
          </div>
        </div>
        {profileData}
      </div>
      <div
        //   className="container-buttom"
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "50px",
        }}
      >
        <CustomButton
          key="PREVIEW PROFILE"
          text="PREVIEW PROFILE"
          type="primary"
          size="xl"
          className="button-modal"
          style={{ padding: "0px 10px" }}
          onClick={() => {
            setBulModal(true);
          }}
        />
        {onChange && (
          <CustomButton
            key="SAVE"
            text="SAVE"
            type="primary"
            size="xl"
            className="button-modal"
            style={{ padding: "0px 10px", marginLeft: "20px" }}
            onClick={() => {
              if (text1 !== mentorProfile?.textDescription) {
                updateMentor(
                  {
                    id: userProfile?.id,
                    textDescription: text1,
                  },
                  () => {
                    getOneMentor();
                  }
                );
              }
              if (text2 !== menteeProfile?.textDescription) {
                updateMente(
                  {
                    id: userProfile?.id,
                    textDescription: text2,
                  },
                  () => {
                    getOneMentee();
                  }
                );
              }
              if (Object.keys(fields)?.length > 0) {
                onSave();
              }

              setOnChange(false);
            }}
          />
        )}
      </div>
      {/* <div className="container-buttom">
                <CustomButton
                    key="PREVIEW PROFILE"
                    text="PREVIEW PROFILE"
                    type="primary"
                    size="xl"
                    className="button-modal"
                    style={{ padding: "0px 10px" }}
                    onClick={() => { setBulModal(true) }}
                />
                {onChange && <CustomButton
                    key="SAVE"
                    text="SAVE"
                    type="primary"
                    size="xl"
                    className="button-modal"
                    style={{ padding: "0px 10px", marginLeft: '20px' }}
                    onClick={() => {
                        if (text1 !== mentorProfile?.textDescription) {
                            updateMentor({
                                id: userProfile?.id,
                                textDescription: text1
                            }, () => { getOneMentor() })
                        }
                        if (text2 !== menteeProfile?.textDescription) {
                            updateMente({
                                id: userProfile?.id,
                                textDescription: text2
                            }, () => { getOneMentee() })
                        }
                        if (Object.keys(fields)?.length > 0) {
                            onSave()
                        }

                        setOnChange(false)
                    }}
                />}
            </div> */}
      {bulModal === true && (
        <div className="complete-profile" onClick={() => setBulModal(false)}>
          <ModalCompleteYourProfile
            userProfile={userProfile}
            get={getUser}
            edit={false}
            caseMentoring={true}
            mentee={text2}
            mentor={text1}
            onClose={() => setBulModal(false)}
          />
        </div>
      )}
      <Modal
        className="photo-upload-modal"
        title={
          <div className="photo-upload-modal-title">Select your photo.</div>
        }
        centered
        visible={modalPhoto}
        width={500}
        closable={true}
        maskClosable={false}
        footer={[]}
        onCancel={() => setModalPhoto(false)}
      >
        <PhotoUploadForm onSave={onPhotoSave} />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  allCategories: categorySelector(state).categories,
});

const mapDispatchToProps = {
  updateUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralProfile);
