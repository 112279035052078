import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Form, Table, Modal, Select, Space } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { CustomButton, CustomInput, FilesUpload } from "components";
import { getSession } from "redux/actions/session-actions";
import { actions as annualConferenceClassActions } from "redux/actions/annualConferenceClass-actions";
import { annualConferenceClassSelector } from "redux/selectors/annualConferenceClassSelector";
import { categorySelector } from "redux/selectors/categorySelector";

import "./style.scss";

const { confirm } = Modal;

const ConferenceClassPage = ({
  match,
  annualConferenceClasses,
  allCategories,
  annualConferenceClass,
  getSession,
  getAnnualConferenceClasses,
  addAnnualConferenceClass,
  updateAnnualConferenceClass,
  deleteAnnualConferenceClass,
  session,
  type,
  refresh
}) => {
  // const { search } = useLocation();
  // const query = new URLSearchParams(search);
  const [courseClassForm] = Form.useForm();
  const [isUpdate, setIsUpdate] = useState(0);
  const [modalFormVisible, setModalFormVisible] = useState(false);
  const [classToUpdate, setClassToUpdate] = useState({});
  const dataSourceColumns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 200,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Video Link",
      dataIndex: "videoUrl",
      key: "videoUrl",
      render: (url) => (
        <a rel="noopener noreferrer" href={url} target="_blank">
          {url}
        </a>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: 250,
      render: (_, data) => (
        <Space>
          <CustomButton
            text="Edit"
            size="xs"
            onClick={() => {
              onUpdateCourseClass(data);
            }}
          />
          <CustomButton
            type="primary outlined"
            size="xs"
            text="Delete"
            onClick={() => {
              onDeleteAnnualConferenceClass(data);
            }}
          />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (session !== undefined && session?.id !== undefined) {
      if(type !== "2022" || type !== undefined || type !== null){
        getSession(session?.id,type);
        getAnnualConferenceClasses(session?.id,type);
      }else{
        getSession(session?.id,"2022");
        getAnnualConferenceClasses(session?.id,"2022");
      }
    } 
    // eslint-disable-next-line
  }, [session,refresh]);

  const onAddCourseClass = () => {
    setModalFormVisible(true);
    setIsUpdate(0);
    courseClassForm.resetFields();
  };

  const onUpdateCourseClass = (data) => {
    setModalFormVisible(true);
    setIsUpdate(data.id);
    setClassToUpdate(data);
    courseClassForm.setFieldsValue(data);
  };

  const onCancelModalForm = () => {
    setModalFormVisible(false);
    setIsUpdate(0);
    courseClassForm.resetFields();
  };

  const handleAnnualConferenceClass = (data) => {
    setModalFormVisible(false);
    data["AnnualConferenceId"] = session?.id;
    if(type !== "2022" || type !== undefined || type !== null){
      data["type"] = type
    }else{
      data["type"] = '2022'
    }

    if (isUpdate > 0) {
      data["id"] = isUpdate;
      updateAnnualConferenceClass(data);
    } else {
      addAnnualConferenceClass(data);
    }
    courseClassForm.resetFields();
  };

  const onDeleteAnnualConferenceClass = (data) => {
    confirm({
      title: "Are you sure delete this element?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        data["AnnualConferenceId"] = session?.id;
        handleDeleteAnnualConferenceClass(data);
      },
    });
  };

  const handleDeleteAnnualConferenceClass = (data) => {
    deleteAnnualConferenceClass({
      ...data,
      type: (type !== "2022" || type !== undefined || type !== null) ? type : '2022'
    });
  };

  return (
    <div className="course-class-page">
      <div className="course-class-page-wrapper">
        <div className="course-class-page-header">
          {/* <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={INTERNAL_LINKS.GLOBAL_CONFERENCE}>All Sessions</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{annualConferenceClass.title}</Breadcrumb.Item>
          </Breadcrumb> */}
          <CustomButton
            text="Add"
            onClick={() => {
              onAddCourseClass();
            }}
          />
        </div>
        <Table
          columns={dataSourceColumns}
          dataSource={annualConferenceClasses}
          rowKey="id"
        ></Table>
        <Modal
          visible={modalFormVisible}
          onCancel={() => {
            onCancelModalForm();
          }}
          onOk={() => {
            courseClassForm.submit();
          }}
        >
          <Form
            form={courseClassForm}
            layout="vertical"
            onFinish={(data) => {
              handleAnnualConferenceClass(data);
            }}
          >
            <Form.Item
              label="Title"
              name="title"
              rules={[{ required: true, message: "Title is required." }]}
            >
              <CustomInput />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: true, message: "Description is required." }]}
            >
              <CustomInput />
            </Form.Item>
            <Form.Item
              label="Video URL"
              name="videoUrl"
              rules={[{ required: true, message: "Video URL is required." }]}
            >
              <CustomInput />
            </Form.Item>
            <Form.Item
              label="Duration"
              name="duration"
              rules={[{ required: true, message: "Duration is required." }]}
            >
              <CustomInput />
            </Form.Item>
            <Form.Item
              label="Categories"
              name="topics"
              rules={[{ required: true, message: "Categories is required." }]}
            >
              <Select mode="multiple">
                {allCategories.map((item) => {
                  return (
                    <Select.Option key={item.value} value={item.value}>
                      {item.title}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item name="audioFileUrl">
              <FilesUpload
                type="Audio"
                accept=".mp3,.mp4,.ogg,.wav"
                fileName={classToUpdate.audioFileName}
              />
            </Form.Item>

            <Form.Item name="documentFileUrl">
              <FilesUpload
                type="File"
                accept=".doc,.docx,.pdf"
                fileName={classToUpdate.documentFileName}
              />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  allCategories: categorySelector(state).categories,
  annualConferenceClasses:
    annualConferenceClassSelector(state).annualConferenceClasses,
  annualConferenceClass:
    annualConferenceClassSelector(state).annualConferenceClass,
});

const mapDispatchToProps = {
  ...annualConferenceClassActions,
  getSession,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConferenceClassPage);
