import { notification, Tooltip, Space } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Emitter from "services/emitter";
import { EVENT_TYPES } from "enum";
import { isEmpty } from "lodash";
import {
  StarOutlined,
  BookOutlined,
  BookFilled,
  SaveOutlined,
  SaveFilled,
  CaretRightOutlined
} from "@ant-design/icons";
import { envSelector } from "redux/selectors/envSelector";
import { actions as councilEventActions } from "redux/actions/council-events-actions";

import { getAllEvent } from "redux/actions/event-actions";

import LibraryClaimModal from "components/LibraryCard/LibraryClaimModal";

import { homeSelector } from "redux/selectors/homeSelector";
import {
  setLibraryViewed,
  claimLibrary,
  saveForLaterLibrary,
} from "redux/actions/library-actions";

import "./styles.scss";
import { CustomButton } from "components";

const EventVideos = ({
  library,
  userProfile,
  claimLibrary,
  setLibraryViewed,
  saveForLaterLibrary,
  getAllEvent,
  getCouncilEvents,
  isInHRCredits = false,
  isPublicEvent = false,
  eventCouncil = {},
  isMobile
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [showFilter,setShowFilters] = useState(isMobile ? false : true)
  const [showFirewall, setShowFirewall] = useState(false);

  const onClaimCredits = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (userProfile && userProfile.memberShip === "premium") {
      setModalVisible(true);
    } else {
      setShowFirewall(true);
    }
  };

  const planUpgrade = () => {
    Emitter.emit(EVENT_TYPES.OPEN_PAYMENT_MODAL);
  };

  const onHRClaimOffered = async () => {
    claimLibrary(library.id, (err) => {
      if (err) {
        notification.error({
          message: "Error",
          description: (err || {}).msg,
        });
      } else {
        notification.info({
          message: "Email was sent successfully.",
        });
        setModalVisible(false);
        getAllEvent()
      }
    });
  };

  const handleSaveForLater = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const isSavedForLater =
      !isEmpty(library.saveForLater) &&
      library.saveForLater.includes(userProfile.id);
    const status = isSavedForLater ? "not saved" : "saved";

    saveForLaterLibrary(library.id, userProfile.id, status, isInHRCredits, () => {
      getAllEvent()
    });
  };

  return (<>
    {!isPublicEvent ? (
      <div className={"event-video"} style={isMobile ? {width:'100%', flexDirection:'column',border:'1px solid #f0f0f0'} : {}}>
        <a
          // href={`/my-learnings/event-videos/${library.EventId}/${library.title}`}
          href={library.link}
          target="_blank"
          rel="noopener noreferrer"
          className="event-video-content"
          style={isMobile ? {width:'100%', textAlign:'center',marginBottom:'10px'} : {}}
        >
          {library.title}
        </a>
        {!isMobile && <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'stretch',flexWrap: 'nowrap',width:'320px'}}>
          <p style={{margin:'0px',padding:'0px', width:'80px',textAlign:'center',border:'1px solid #f0f0f0',display:'flex',alignItems:'center',justifyContent:'center'}}>{eventCouncil?.hrCreditsBoolean || 'No' }</p>
          <p style={{margin:'0px',padding:'0px', width:'50px',textAlign:'center',border:'1px solid #f0f0f0',display:'flex',alignItems:'center',justifyContent:'center'}}>{eventCouncil?.numberOfHRCredits || 0}</p>
          <p style={{margin:'0px',padding:'0px', width:'120px',textAlign:'center',border:'1px solid #f0f0f0',display:'flex',alignItems:'center',justifyContent:'center'}}>{eventCouncil?.numberOfHRCredits || 0} {eventCouncil?.typeHrCredits && `(Credit Type: ${eventCouncil?.typeHrCredits})`}</p>
          <p style={{margin:'0px',padding:'0px', width:'50px',textAlign:'center',border:'1px solid #f0f0f0',display:'flex',alignItems:'center',justifyContent:'center'}}>{eventCouncil?.numberOfIHRIMCredits || 0}</p>
          <div style={{width:'20px'}}></div>
        </div>}
        <div className="event-video-btns" style={isMobile ? {width:'100%',display:'flex',justifyContent:'center',marginBottom:'10px'} : {}}>
          {library.contentType === "video" && (
            <Tooltip
              title={
                library.viewed && library.viewed[userProfile.id] === "mark"
                  ? "Viewed"
                  : "Mark As Completed"
              }
            >
              {library.viewed && library.viewed[userProfile.id] === "mark" ? (
                <SaveFilled
                  className="icon-btn"
                  style={{minHeight:'24px',minWidth:'24px'}}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setLibraryViewed(
                      library.id,
                      library.viewed && library.viewed[userProfile.id] === "mark"
                        ? "unmark"
                        : "mark"
                    );
                  }}
                />
              ) : (
                <SaveOutlined
                  className="icon-btn"
                  style={{minHeight:'24px',minWidth:'24px'}}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setLibraryViewed(
                      library.id,
                      library.viewed && library.viewed[userProfile.id] === "mark"
                        ? "unmark"
                        : "mark"
                    );
                  }}
                />
              )}
            </Tooltip>
          )}
          {library.showClaim === 1 && (
            <Tooltip title="Claim HR Credits">
              <StarOutlined onClick={onClaimCredits} className="icon-btn" />
            </Tooltip>
          )}
          {library.contentType === "video" &&
            library.viewed &&
            library.viewed[userProfile.id] !== "mark" && (
              <Tooltip
                title={
                  !isEmpty(library.saveForLater) &&
                    library.saveForLater.includes(userProfile.id)
                    ? "Unsave"
                    : "Save for later"
                }
              >
                {!isEmpty(library.saveForLater) &&
                  library.saveForLater.includes(userProfile.id) ? (
                  <BookFilled
                    className="icon-btn"
                    style={{minHeight:'24px',minWidth:'24px'}}
                    onClick={handleSaveForLater}
                  />
                ) : (
                  <BookOutlined
                    className="icon-btn"
                    style={{minHeight:'24px',minWidth:'24px'}}
                    onClick={handleSaveForLater}
                  />
                )}
              </Tooltip>
            )}
          {showFirewall && (
            <div
              className="library-card-firewall"
              onClick={() => setShowFirewall(false)}
            >
              <div className="upgrade-notification-panel" onClick={planUpgrade}>
                <h3>
                  Upgrade to a PREMIUM Membership and get unlimited access to
                  the LAB features
                </h3>
              </div>
            </div>
          )}
          <LibraryClaimModal
            visible={modalVisible}
            title="HR Credit Offered"
            destroyOnClose={true}
            data={library}
            onClaim={onHRClaimOffered}
            onCancel={() => setModalVisible(false)}
          />
        </div>
        {isMobile && <><div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'stretch',flexWrap: 'nowrap',width:'auto'}}>
          <p style={{margin:'0px',padding:'0px', width:'80px',textAlign:'center',border:'1px solid #f0f0f0',display:'flex',alignItems:'center',justifyContent:'center'}}>HR Credits</p>
          <p style={{margin:'0px',padding:'0px', width:'55px',textAlign:'center',border:'1px solid #f0f0f0',display:'flex',alignItems:'center',justifyContent:'center'}}>SHRM</p>
          <p style={{margin:'0px',padding:'0px', width:'125px',textAlign:'center',border:'1px solid #f0f0f0',display:'flex',alignItems:'center',justifyContent:'center'}}>HRCI</p>
          <p style={{margin:'0px',padding:'0px', width:'50px',textAlign:'center',border:'1px solid #f0f0f0',display:'flex',alignItems:'center',justifyContent:'center'}}>IHRIM</p>
        </div>
        <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'stretch',flexWrap: 'nowrap',width:'auto'}}>
          <p style={{margin:'0px',padding:'0px', width:'80px',textAlign:'center',border:'1px solid #f0f0f0',display:'flex',alignItems:'center',justifyContent:'center'}}>{eventCouncil?.hrCreditsBoolean || 'No' }</p>
          <p style={{margin:'0px',padding:'0px', width:'55px',textAlign:'center',border:'1px solid #f0f0f0',display:'flex',alignItems:'center',justifyContent:'center'}}>{eventCouncil?.numberOfHRCredits || 0}</p>
          <p style={{margin:'0px',padding:'0px', width:'125px',textAlign:'center',border:'1px solid #f0f0f0',display:'flex',alignItems:'center',justifyContent:'center'}}>{eventCouncil?.numberOfHRCredits || 0} {eventCouncil?.typeHrCredits && `(Credit Type: ${eventCouncil?.typeHrCredits})`}</p>
          <p style={{margin:'0px',padding:'0px', width:'50px',textAlign:'center',border:'1px solid #f0f0f0',display:'flex',alignItems:'center',justifyContent:'center'}}>{eventCouncil?.numberOfIHRIMCredits || 0}</p>
        </div>
        </>}
      </div>
    ) : (
      <div className="event-video" style={isMobile ? {width:'100%'} : {}}>
        <Space>
          {library.contentType === "video" && (
            <Tooltip
              title={
                library.viewed && library.viewed[userProfile.id] === "mark"
                  ? "Viewed"
                  : "Mark As Completed"
              }
            >
              {library.viewed && library.viewed[userProfile.id] === "mark" ? (
                <SaveFilled
                  className="icon-btn"
                  style={{ color: '#3c4752', fontSize: '30px' }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setLibraryViewed(
                      library.id,
                      library.viewed && library.viewed[userProfile.id] === "mark"
                        ? "unmark"
                        : "mark",
                      () => {
                        getAllEvent()
                        getCouncilEvents(() => {},'cached');
                      }
                    );
                  }}
                />
              ) : (
                <SaveOutlined
                  className="icon-btn"
                  style={{ color: '#3c4752', fontSize: '30px' }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setLibraryViewed(
                      library.id,
                      library.viewed && library.viewed[userProfile.id] === "mark"
                        ? "unmark"
                        : "mark",
                      () => {
                        getAllEvent()
                        getCouncilEvents(() => {},'cached');
                      }
                    );
                  }}
                />
              )}
            </Tooltip>
          )}
          {library.link && (
            <a
              // href={`/my-learnings/event-videos/${library.EventId}/${library.title}`}
              href={library.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Tooltip title="Redirect">
                <CaretRightOutlined className="icon-btn" style={{ color: '#3c4752', fontSize: '30px' }} />
              </Tooltip>
            </a>
          )}
          {library.showClaim === 1 && (
            <Tooltip title="Claim HR Credits">
              <StarOutlined onClick={onClaimCredits} className="icon-btn" style={{ color: '#3c4752', fontSize: '30px' }} />
            </Tooltip>
          )}
          {library.contentType === "video" &&
            library.viewed &&
            library.viewed[userProfile.id] !== "mark" && (
              <Tooltip
                title={
                  !isEmpty(library.saveForLater) &&
                    library.saveForLater.includes(userProfile.id)
                    ? "Unsave"
                    : "Save for later"
                }
              >
                {!isEmpty(library.saveForLater) &&
                  library.saveForLater.includes(userProfile.id) ? (
                  <BookFilled
                    className="icon-btn"
                    style={{ color: '#3c4752', fontSize: '30px' }}
                    onClick={handleSaveForLater}
                  />
                ) : (
                  <BookOutlined
                    className="icon-btn"
                    style={{ color: '#3c4752', fontSize: '30px' }}
                    onClick={handleSaveForLater}
                  />
                )}
              </Tooltip>
            )}
          {showFirewall && (
            <div
              className="library-card-firewall"
              onClick={() => setShowFirewall(false)}
            >
              <div className="upgrade-notification-panel" onClick={planUpgrade}>
                <h3>
                  Upgrade to a PREMIUM Membership and get unlimited access to
                  the LAB features
                </h3>
              </div>
            </div>
          )}
          <LibraryClaimModal
            visible={modalVisible}
            title="HR Credit Offered"
            destroyOnClose={true}
            data={library}
            onClaim={onHRClaimOffered}
            onCancel={() => setModalVisible(false)}
          />
        </Space>
      </div>
    )}
  </>);
};

const mapStateToProps = (state) => ({
  userProfile: homeSelector(state).userProfile,
  isMobile: envSelector(state).isMobile,
});

const mapDispatchToProps = {
  claimLibrary,
  setLibraryViewed,
  getAllEvent,
  saveForLaterLibrary,
  ...councilEventActions
};
export default connect(mapStateToProps, mapDispatchToProps)(EventVideos);
