import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Avatar, Image, Collapse } from "antd";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import { CaretRightOutlined } from "@ant-design/icons";

import { setLoading } from "redux/actions/home-actions";
import {FacebookShareButton, TwitterShareButton, WhatsappShareButton, LinkedinShareButton, FacebookMessengerShareButton, EmailShareButton } from 'react-share'
import {
  setFollowChannel,
  unsetFollowChannel,
} from "redux/actions/channel-actions";
import {
  setBlogPostLike,
  deleteBlogPostLike,
} from "redux/actions/blog-post-action";
import IconLogo from "../../images/logo-sidebar.svg";
import {
  BlogCard2
} from "components"
import { envSelector } from "redux/selectors/envSelector";
import { homeSelector } from "redux/selectors/homeSelector";
import { getBlogPost, getChannel } from "api";
import { transformNames } from "utils/format";
import { INTERNAL_LINKS } from "enum";
import {
  updateBlogPost,
  searchBlogPosts,
  saveBlogPost,
} from "redux/actions/blog-post-action";
import { Link } from "react-router-dom/cjs/react-router-dom";
// import Login from "../Login2";

import { ReactComponent as IconHeartOutline } from "images/icon-heart-outline.svg";
import { ReactComponent as IconCalendar } from "images/icon-calendar-1.svg";
import "./style.scss";

const { Panel } = Collapse;

const BlogNew = ({
  setLoading,
  userProfile,
  isMobile,
  saveBlogPost,
  // setFollowChannel,
  // unsetFollowChannel,
  // setBlogPostLike,
  // deleteBlogPostLike,
}) => {

  let idProd = [205, 30974, 209, 40826, 41804];
  const history = useHistory();
  const { id } = useParams();
  const [blog, setBlog] = useState({});
  const [channel, setChannel] = useState({});
  const [textMap, setTextMap] = useState("Document Map")
  // const [bulModal, setBulModal] = useState(false);
  const [mapSituation, setMapSituation] = useState(<div></div>)
  const [bulViuw, setBulViuw] = useState(false)
  const [scrollPor,setScrollPor] = useState(0)

  const [blogPreviuw, setBlogPreviuw] = useState({});
  const [redirect, setRedirect] = useState(false);

  const finishScroll = useRef(null);

  const setMapAndReturnData = (blogD) => {
    let data = blogPreviuw?.id
      ? blogPreviuw?.description?.html
      : blogD?.description?.html;
    //update map html
    if (data) {
      const containerMap = document.createElement("div");
      containerMap.innerHTML = data;

      const childrenIterect = containerMap.children[0].children;

      let numIterect = 0;
      let mapSituation = [];
      let H3Object = [];

      for (let i = 0; i < childrenIterect.length; i++) {
        if (childrenIterect[i].tagName === "H2") {
          numIterect++;
          H3Object = [];
          mapSituation[numIterect - 1] = {
            key: numIterect,
            label: (
              <a
                style={{ textDecoration: "none", color: "black" }}
                href={`#${childrenIterect[i].id}`}
              >
                {childrenIterect[i].textContent}
              </a>
            ),
          };
        }
        if (childrenIterect[i].tagName === "H3") {
          H3Object = [
            ...H3Object,
            <a
              style={{ textDecoration: "none", color: "black" }}
              href={`#${childrenIterect[i].id}`}
            >
              {childrenIterect[i].textContent}
            </a>,
          ];
          mapSituation[numIterect - 1] = {
            ...mapSituation[numIterect - 1],
            children: H3Object,
          };
        }
      }

      setMapSituation(
        mapSituation.map((e) => {
          return e.children ? (
            <Panel
              header={e.label}
              key={e.key}
              className="site-collapse-custom-panel"
              style={{ background: "white" }}
            >
              {e.children.map((a,index) => <p key={index}>{a}</p>)}
            </Panel>
          ) : (
            <div
              key={e.key}
              style={{
                padding: "15px",
                background: "white",
                borderBottom: "#cdcdcdb3 solid 1px",
              }}
            >
              {e.label}
            </div>
          );
        })
      );
    }
  };

  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("previuw"));
    setTextMap(data?.title);
    setBlogPreviuw(data);
    setMapAndReturnData(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (redirect) {
      setRedirect(false);
      history.push(`${INTERNAL_LINKS.BLOGS}/${id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirect]);

  const getBlog = async () => {
    setLoading(true);
    if (isNaN(Number(id))) {
      return history.push("/blogs");
    }
    const { data, status } = await getBlogPost(id,userProfile?.id);

    if (status === 200) {

      setTextMap(data?.blogPost?.title)
      setMapAndReturnData(data?.blogPost)

      return setBlog(data?.blogPost);
    }

    return history.push("/blogs");
  };

  useEffect(() => {

    getBlog();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, history, setLoading, userProfile]);

  useEffect(() => {
    const getChannelApi = async () => {
      const { data, status } = await getChannel({ id: blog?.ChannelId });

      if (status === 200) {
        return setChannel(data?.channel);
      }
    };

    if (blog?.id && !channel?.id) {
      getChannelApi();
    }
  }, [blog, channel]);

  // const followChannel = () => {
  //   if (localStorage.getItem("community") !== null) {
  //     if (channel?.followedUsers?.includes(userProfile?.id)) {
  //       unsetFollowChannel(channel, () => {
  //         setChannel({
  //           ...channel,
  //           followedUsers: channel?.followedUsers?.filter(
  //             (userId) => userId !== userProfile?.id
  //           ),
  //         });
  //       });
  //     } else {
  //       setFollowChannel(channel, () => {
  //         setChannel({
  //           ...channel,
  //           followedUsers: [...channel.followedUsers, userProfile?.id],
  //         });
  //       });
  //     }
  //   } else {
  //     setBulModal(true);
  //   }
  // };

  // const handleLike = () => {
  //   if (localStorage.getItem("community") !== null) {
  //     if (
  //       !blog?.BlogPostLikes.some((like) => like.ownerId === userProfile?.id)
  //     ) {
  //       setBlogPostLike(
  //         {
  //           BlogPostId: blog?.id,
  //           blogPostOwnerUserId: blog?.ownerId,
  //         },
  //         (like) => {
  //           setBlog({
  //             ...blog,
  //             BlogPostLikes: [...blog?.BlogPostLikes, like],
  //           });
  //         }
  //       );
  //     } else {
  //       deleteBlogPostLike(blog?.id, () => {
  //         setBlog({
  //           ...blog,
  //           BlogPostLikes: blog?.BlogPostLikes.filter(
  //             (likes) => likes?.ownerId !== userProfile?.id
  //           ),
  //         });
  //       });
  //     }
  //   } else {
  //     setBulModal(true);
  //   }
  // };

  // const avatarCase = () => {
  //   if (!blogPreviuw?.title) {
  //     return <Avatar size={40}>DP</Avatar>;
  //   } else {
  //     return <Avatar size={40} src={userProfile?.img} />;
  //   }
  // };

  useEffect(() => {
    //porcentage scroll page

    const searchContainerInputScroll = () => {
      let containerScroll = document.getElementById("container-scroll")
      if (containerScroll) {
        containerScroll.addEventListener("scroll", () => {
          
          const scrollTop = containerScroll.scrollTop;
          const clientHeight = containerScroll.clientHeight;

          let elementPosition = 0
          if(finishScroll.current){
            elementPosition = finishScroll.current.getBoundingClientRect().top - 250;
          }
          

          if (scrollTop > 300 && elementPosition > (clientHeight - 800)
            ) {
            setBulViuw(true);
          } else {
            setBulViuw(false);
          }

          const nuevoValor = ((scrollTop - 300) / (((elementPosition + containerScroll.pageYOffset) - containerScroll.innerHeight) - 300)) * 100;
          setScrollPor(Math.min(Math.max(nuevoValor, 0), 100));
          
        })

      } else {
        setTimeout(() => {
          searchContainerInputScroll();
        }, 100);
      }
    };

    searchContainerInputScroll();
  },[])

  if (!blog?.id && !blogPreviuw?.title) {
    return <></>;
  }

  // if (blog?.status === "draft") {
  //   history.goBack();
  //   return <></>;
  // }

  const functionCaseTeam = () => {
    if(blog?.aut?.firstName){
      return `${transformNames(blog?.aut?.firstName || userProfile?.img)} ${transformNames(blog?.aut?.lastName || userProfile?.img)}`
    }

    if (!idProd.includes(blog?.owner?.id)) {
      return `${transformNames(blog?.aut?.firstName || blog?.owner?.firstName || userProfile?.firstName || "Indira")} ${transformNames(blog?.aut?.lastName || blog?.owner?.lastName || userProfile?.lastName || "Shree")}`;
    } else {
      return "Hacking HR Team";
    }
  };

  return (
    <div className="blog-new-page" id="container-scroll">
      <div style={{height:'auto',width:'100%'}}>
        <div className="container-blogs-new">
          <div className={"container-blogs-new-title"}>
            <div className="container-blogs-new-title-h1">
              <h1 className="container-blogs-new-title-h1" dangerouslySetInnerHTML={{ __html: !blog?.id ? blogPreviuw?.title.replaceAll(/\n/g, '<br>') : blog?.title.replaceAll(/\n/g, '<br>') }}>
                {/* {!blog?.id ? blogPreviuw?.title : blog?.title} */}
              </h1>
            </div>
            <div className={"container-blogs-new-title-line-design"}></div>
            {/* <p style={{margin:'0px',padding:'0px', fontSize:'16px',fontWeight:'500'}}>{`Home > Single Blog`}</p> */}
            <div className={"container-links"}>
              <Link to={`${INTERNAL_LINKS.HOME}`}>
                <p className={"link"}>
                  Home
                </p>
              </Link>
              <p className={"separator"}>{'>'}</p>
              <Link to={`${INTERNAL_LINKS.BLOGS}`}>
                <p className={"link"}>
                  Blog
                </p>
              </Link>
              <p className={"separator"}>{'>'}</p>
              <p className={"separator"}>
                {!blog?.id ? blogPreviuw?.title : blog?.title}
              </p>
            </div>
          </div>
        </div>
        <div className="blog-page-content">
          <div className="blog-page-content-header">
            {(blog?.imageUrl || blogPreviuw?.imageUrl) && (
              <div className={"blog-page-content-header-image"}>
                <Image
                  preview={false}
                  src={!blog?.id ? blogPreviuw?.imageUrl : blog?.imageUrl}
                />
              </div>
            )}

            <div className={"blog-page-content-header-avatar-date"}>
              <div className={"blog-page-content-header-avatar-date-1"} style={{cursor:'pointer'}} onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                history.push(`${INTERNAL_LINKS.PROFILE}/${!blog?.id 
                  ? (blogPreviuw?.aut?.id || userProfile?.id) 
                  : (blog?.aut?.id || blog?.owner?.id || userProfile?.id || "")}`
                )
              }}>
                {!idProd.includes(blog?.owner?.id) ? <Avatar
                  style={{ minWidth: "40px" }}
                  src={!blog?.id 
                    ? (blogPreviuw?.aut?.img || userProfile?.img) 
                    : (blog?.aut?.img || blog?.owner?.img || userProfile?.img || "")
                  }
                  size="large"
                  alt={"user-avatar"}
                /> : <Avatar
                  style={{ minWidth: "50px",minHeight:'50px' }}
                  src={IconLogo}
                  size="large"
                  alt={"user-avatar"}
                />}
                <h5>{functionCaseTeam()}
                </h5>
              </div>
              <div className={"blog-page-content-header-avatar-date-2"}>
                <IconCalendar
                  style={{ fill: "#F75B27", width: "40px", stroke: "#F75B27" }}
                />
                <p>
                  {moment(!blog?.id ? moment() : blog?.createdAt).format(
                    "dddd, L"
                  )}
                </p>
              </div>
              <div className="blog-card-actions" onClick={(e) => {e.preventDefault(); e.stopPropagation();}}>
                <div className={`block-action ${blog?.saveBlogsForUser ? 'save' : 'unsave'}`} onClick={() => saveBlogPost({idBlog: blog?.id},(bul) => {
                  setBlog({...blog,saveBlogsForUser:bul});
                })}></div>
              </div>
            </div>

            {/* <div>
              <div>
                {blog?.owner?.img && <Avatar size={40} src={blog?.owner?.img} />}
              </div>
            </div> */}
          </div>

          <div className="content-blogs">
            <div
              className="content-max-width"
              dangerouslySetInnerHTML={{
                __html: !blog?.id
                  ? blogPreviuw?.description?.html
                  : blog?.description?.html,
              }}
            />
          </div>

          <div className="blog-page-content-likes-container">
            <div>
              <IconHeartOutline width={20} />
            </div>

            <span>{!blog?.id ? 0 : blog?.BlogPostLikes?.length}</span>
          </div>
        </div>
        {!isMobile && <div className={bulViuw ? "childSensory" : "childSensoryH"}>
          <div className="container-header-child">
            <h3>{textMap}</h3>
            <div style={{width:`${scrollPor}%`}}></div>
          </div>
          <div className="container-list-map">
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} style={{minHeight:'12px',minWidth:'12px'}}/>
              )}
              className="site-collapse-custom-collapse"
            >
              {mapSituation}
            </Collapse>
          </div>
        </div>}
      </div>
      <div id="container-see-finish"></div>
      <div style={{ width: '100%', height: 'auto' }}>
        <div className={"container-share-redes-sociales-2"} ref={finishScroll}>
          <div className={"container-red"}>
            <div className={"container-text-red"}>
              <h3>Share the Article</h3>
              <p>on every platform</p>
              <div className={"line-h1-design"}></div>
            </div>
            <div className={"container-sharebuttons"}>
                <FacebookShareButton className={"c-r-b"} url={`${process.env.NEXT_PUBLIC_BASE_URL}${INTERNAL_LINKS.BLOGS_PAGUE}/${blog.slug}`}>
                  <div/>Facebook
                </FacebookShareButton>
                {/* <a href='https://www.facebook.com/HackingHR' target='_blank' rel="noopener noreferrer" ><div className={"c-r-b"]} onClick={() => updateBlogPost(blog.id,{countShare: (Number(blog.countShare) + 1) ? (Number(blog.countShare) + 1) : blog.countShare})}><div></div>Facebook</div></a> */}
                {/* <a href='https://twitter.com/hacking_hr' target='_blank' rel="noopener noreferrer" ><div className={"c-r-b"]} onClick={() => updateBlogPost(blog.id,{countShare: (Number(blog.countShare) + 1) ? (Number(blog.countShare) + 1) : blog.countShare})}><div></div>Twitter</div></a> */}
                <TwitterShareButton className={"c-r-b"} url={`${process.env.NEXT_PUBLIC_BASE_URL}${INTERNAL_LINKS.BLOGS_PAGUE}/${blog.slug}`} title={blog.title} >
                  <div/>Twitter
                </TwitterShareButton>
                <WhatsappShareButton className={"c-r-b"} windowWidth={800} windowHeight={700} url={`${process.env.NEXT_PUBLIC_BASE_URL}${INTERNAL_LINKS.BLOGS_PAGUE}/${blog.slug}`} title={blog.title} >
                  <div/>Whats App
                </WhatsappShareButton>
                <LinkedinShareButton className={"c-r-b"} url={`${process.env.NEXT_PUBLIC_BASE_URL}${INTERNAL_LINKS.BLOGS_PAGUE}/${blog.slug}`} title={blog.title} >
                  <div/>Linkedin
                </LinkedinShareButton>
                <FacebookMessengerShareButton className={"c-r-b"} url={`${process.env.NEXT_PUBLIC_BASE_URL}${INTERNAL_LINKS.BLOGS_PAGUE}/${blog.slug}`} title={blog.title} appId="" >
                  <div/>Messenger
                </FacebookMessengerShareButton>
                {/* <div className={"c-r-b"]} onClick={() => updateBlogPost(blog.id,{countShare: (Number(blog.countShare) + 1) ? (Number(blog.countShare) + 1) : blog.countShare})}><div></div>Whats App</div> */}
                {/* <div className={"c-r-b"]} onClick={() => updateBlogPost(blog.id,{countShare: (Number(blog.countShare) + 1) ? (Number(blog.countShare) + 1) : blog.countShare})}><div></div>Linkedin</div> */}
                {/* <div className={"c-r-b"]} onClick={() => updateBlogPost(blog.id,{countShare: (Number(blog.countShare) + 1) ? (Number(blog.countShare) + 1) : blog.countShare})}><div></div>Messenger</div> */}
                <div className={"c-r-b"} onClick={() => updateBlogPost(blog.id,{countShare: (Number(blog.countShare) + 1) ? (Number(blog.countShare) + 1) : blog.countShare})}><div></div>Instagram</div>
                <div className={"c-r-b"} onClick={() => updateBlogPost(blog.id,{countShare: (Number(blog.countShare) + 1) ? (Number(blog.countShare) + 1) : blog.countShare})}><div></div>Tiktok</div>
                <EmailShareButton className={"c-r-b"} url={`${process.env.NEXT_PUBLIC_BASE_URL}${INTERNAL_LINKS.BLOGS_PAGUE}/${blog.slug}`} subject={blog.title}>
                  <div/>Share by email
                </EmailShareButton>
                {/* <div className={"c-r-b"]} onClick={() => updateBlogPost(blog.id,{countShare: (Number(blog.countShare) + 1) ? (Number(blog.countShare) + 1) : blog.countShare})}><div></div>Share by email</div> */}
            </div>
          </div>
        </div>
          <div className="blogs-page-2">
            <div className="blogs-page__container" style={{background:'none'}}>
              <div className="search-results-container" style={{background:'none'}}>
                <div className="container-father">
                  <div className='container-quit-center'>
                      <div className='container-text-tranding'>
                          <h1>Related Posts</h1>
                          <div></div>
                      </div>
                  </div>
                  <div className="blogs-list">
                    <BlogCard2
                      data={blog || blogPreviuw}
                      key={blog?.id || blogPreviuw?.id}
                      id={blog?.id || blogPreviuw?.id}
                      image={blog?.imageUrl || blogPreviuw?.imageUrl}
                      date={blog?.createdAt || blogPreviuw?.createdAt}
                      title={blog?.title || blogPreviuw?.title}
                      summary={blog?.summary || blogPreviuw?.summary}
                      userProfile={userProfile}
                      saveBlogPost={saveBlogPost}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userProfile: homeSelector(state).userProfile,
  isMobile: envSelector(state).isMobile,
});

const mapDispatchToProps = {
  setLoading,
  setFollowChannel,
  unsetFollowChannel,
  setBlogPostLike,
  deleteBlogPostLike,
  updateBlogPost,
  searchBlogPosts,
  saveBlogPost
};

export default connect(mapStateToProps, mapDispatchToProps)(BlogNew);
