import React, { useEffect, useState,useRef } from "react";
import { connect } from "react-redux";
import {Form} from "antd";
  //  import { Image } from 'image-js';
import { notification } from "antd";
import { useLocation } from "react-router-dom";
// import httpClient from "../../../api/module/httpClient";
import {
  createBlogPost,
  updateBlogPost,
  getImagesCarg,
  searchBlogPostLinkBuilder,
} from "redux/actions/blog-post-action";
import { CaretRightOutlined } from '@ant-design/icons';

import clsx from "clsx";
import { actions as councilEventActions } from "redux/actions/council-events-actions";
import { councilEventSelector } from "redux/selectors/councilEventSelector";
import { INTERNAL_LINKS, TAXONOMYS_SKILLS } from "enum";
import moment from "moment";
import { Modal, DatePicker,TreeSelect,Spin,AutoComplete } from "antd";

// import {
//   CloseCircleOutlined,
// } from "@ant-design/icons";

import {
  CustomInput,
  // FroalaEdit,
  InputBlog,
  // ImageUpload,
  ImageUploadPersonalized
} from "components";
import { Collapse,Select} from 'antd';


// import { Link } from "react-router-dom";
import { homeSelector } from "redux/selectors/homeSelector";
import { CustomButton, CustomModal,} from "components";
import { categorySelector } from "redux/selectors/categorySelector";
import { notificationEmailToNewContentCreators } from "redux/actions/channel-actions";
// import { ReactComponent as IconPencil } from "images/icon-pencil.svg";
// import { ReactComponent as IconShowPassword } from "images/icon-show-password.svg";
// import { ReactComponent as IconTrash } from "images/icon-trash.svg";

import "./style.scss";
// import { Link } from "react-router-dom/cjs/react-router-dom";

const { Panel } = Collapse;
const { SHOW_PARENT } = TreeSelect;

const FormCreateOrEditBlog = ({
  userProfile,
  createBlogPost,
  getBlogsPostsByUser,
  // allCategories,
  updateBlogPost,
  getImagesCarg,
  notificationEmailToNewContentCreators,
  setEditOrDeleteBlogPost,
  editOrDeleteBlogPost,
  searchUserForCouncilEventPanelist,
  // searchBlogPostLinkBuilder,
  ChannelIdSelect=84,
  setCurrentTab = () => {},
  resetData = () => {},
  finish = () => {},
}) => {

  const { search } = useLocation();
  const query = new URLSearchParams(search);

//   const [visibleModal, setVisibleModal] = useState(false);
//   const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const id = query?.get("id");
  const accepted = query?.get("accepted");
  const [bulLoad,setBulLoad] = useState(false)
  const [bulLoad2, setBulLoad2] = useState(false)
  const [usersAuthor, setUsersAuthor] = useState([])
  const [usersSelected, setUsersSelected] = useState({})
  const [blogForm] = Form.useForm();
  const [blogForm2] = Form.useForm();
  const [summary1,setSummary1] = useState("")
  const [summary2,setSummary2] = useState("")
  const [summary3,setSummary3] = useState("")
  const [summary4,setSummary4] = useState("")
  const [bulModal2, setBulModal2] = useState(false);
  const [bulModal3, setBulModal3] = useState(false);
  const [bulRequired, setBulRequired] = useState({
    altText: false,
    metaDescriptions: false,
    slug: 0,
    metaTitle:0,
    body: false
  })
  const [textMap, setTextMap] = useState("Document Map")
  // const [body,setBody] = useState("")
  const [images,setImages] = useState([])
  const [mapSituation, setMapSituation] = useState(<div></div>)
  const [date, setDate] = useState("");
  const [value, setValue] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [file1, setFile1] = useState('')
  const [file2, setFile2] = useState('')
  const [scrollPor,setScrollPor] = useState(0)
  const [required, setRequirect] = useState({
    title: "title",
    description: "descrption",
    summary: "summary",
    categories: [],
    date: "date",
    altText: "altText",
    metaDescriptions: "metaDescriptions",
    slug: "slug",
    metaTitle: "metaTitle",
  });
  const [typeS,setTypeS] = useState("draft")
  const [taxon,setTaxon] = useState([])
  const [funcBodyInput, setFuncBodyInput] = useState({})
  const [bulRepeatReact, setBulRepeatReact] = useState(false)
  const clock = useRef(null);
  const clock2 = useRef(null)
  // const clock2 = useRef(null)
  const clock3 = useRef(null);

  const convertFormat = (input,initial = '') => {
  
    const output = [];
    let output2 = {}

    for (const key in input) {
      if (input.hasOwnProperty(key)) {
        const item = {
          title: key,
          value: `${(initial !== '') ? initial : '0-'}${output.length}`,
          key: `${(initial !== '') ? initial : '0-'}${output.length}`,
        };
        output2[`${(initial !== '') ? initial : '0-'}${output.length}`] = key

        const child = input[key];
        if (typeof child === 'object' && !Array.isArray(child)) {
          item.children = convertFormat(child,`${(initial !== '') ? initial : '0-'}${output.length}-`).output;
          output2 = {
            ...output2,
            ...convertFormat(child,`${(initial !== '') ? initial : '0-'}${output.length}-`).output2
          }
        }

        if(Array.isArray(child)){
          item.children = child.map((e,index) => {
            if(child.hasOwnProperty(index)) {
              output2 = {
                ...output2,
                [`${initial}${output.length}-${index}`]: e
              } 
              return {
                title: e,
                value: `${initial}${output.length}-${index}`,
                key: `${initial}${output.length}-${index}`,
              };
            }else{
              return e
            }
          })
        }

        output.push(item);
      }
    }

    return {output,output2};
  }

  function caracteresDesdeSimboloAlFinal(cadena, simbolo) {
    const partes = cadena.split(simbolo);
    const ultimoElemento = partes.pop();
    const caracteresDesdeSimbolo = ultimoElemento.length;
    return caracteresDesdeSimbolo;
}

  const getTitleByKeys = (keys) => {
    try{
      let keysFixed = keys?.map(e => {
        let responseKeys = []
        let key = e
        responseKeys.push(e)
        while((key.split('-').length - 1) === 1){
          const resultado = caracteresDesdeSimboloAlFinal(key, '-');
          responseKeys.push(key.slice(0,key?.length - (resultado + 1)))
          key = key.slice(0,key?.length - (resultado + 1))
        }
        return responseKeys
      })
      let names = keysFixed.map(e => {
        let name = ''
        e.forEach((a) => {
          name = `${name} ${convertFormat(TAXONOMYS_SKILLS).output2[a]}`
        })
        return name
      })
      return names
    }catch(er){
      console.log(er)
      notification.error({
        message: "Please re-select categories ",
      })
    }
  }

  const getTitleTop = (keys) => {
    try{
      let taxonomysTop = convertFormat(TAXONOMYS_SKILLS)
      let data = keys.map(e => {
        return taxonomysTop.output2[e.slice(0,3)]
      })

      return data.filter((item,index)=>{
        return data.indexOf(item) === index;
      })
    }catch(er){
      console.log(er)
    }
  }

  const getAllTitles = (keys) => {
    try{
      let data = convertFormat(TAXONOMYS_SKILLS).output2
      let keysFixed = keys?.map(e => {
        let caseR = Object.keys(data).filter(a => {
          return a.startsWith(e)
        })

        return [e,...caseR]
      })

      let names = ''
      
      keysFixed.forEach(e => {
        e.forEach((a) => {
          names = `${names} ${data[a]}`
        })
      })

      return names
    }catch{
      notification.error({
        message: "Error with categories.",
      })
    }
  }

  useEffect(() => {
    setTaxon(convertFormat(TAXONOMYS_SKILLS).output)
  },[])

  const onChangeTree = (newValue) => {
    setValue(newValue);
  };

  const handleSummary = (value,type) => {
    if(type === 1){
      setSummary1(value.length);
    }
    if(type === 2){
      setSummary2(value.length);
    }
    if(type === 3){
      setSummary3(value.length);
    }
    if(type === 4){
      setSummary4(value.length);
    }
  };

  const handleSaveDraftBlogPost = (type) => {
    let values = blogForm.getFieldsValue();
    let values2 = blogForm2.getFieldsValue();
    let expSlug = /^[a-zA-Z0-9\s-]*$/
    let altText = (values.altText === "" || values.altText === undefined || !values.altText) ? true : false
    let metaDescriptions = (values2.metaDescriptions === "" || values2.metaDescriptions === undefined || !values2.metaDescriptions) ? true : false
    let slug = (values2.slug === "" || values2.slug === undefined || !values2.slug) ? 1 : 0
    let slugCaracteres = (slug === 0) ? (expSlug.test(values2.slug) ? 0 : 2) : slug

    let response = funcBodyInput.data()
    let newDiv = document.createElement("div");
    newDiv.innerHTML = response.body
    let pData = newDiv.querySelectorAll('p')

    let body = (pData[0]) ? (newDiv.children[0].children.length === 1 && (pData[0].textContent === '' || pData[0].textContent === ' ') ? true : false) : false

    let bul = (altText || metaDescriptions || slugCaracteres !== 0 || body) ? true : false

    setBulRequired({altText,metaDescriptions,slug: slugCaracteres,body})

    if(bul){return}

    handleCreateOrEditBlog({ ...values, ...values2, status: type });
  };

  const handleSaveLaterBlogPost = (type) => {
    let values = blogForm.getFieldsValue();
    let values2 = blogForm2.getFieldsValue();
    let expSlug =/^[a-zA-Z0-9\s-]*$/
    let altText = (values.altText === "" || values.altText === undefined || !values.altText) ? true : false
    let metaDescriptions = (values2.metaDescriptions === "" || values2.metaDescriptions === undefined || !values2.metaDescriptions) ? true : false
    let slug = (values2.slug === "" || values2.slug === undefined || !values2.slug) ? 1 : 0
    let slugCaracteres = (slug === 0) ? (expSlug.test(values2.slug) ? 0 : 2) : slug

    let response = funcBodyInput.data()
    let newDiv = document.createElement("div");
    newDiv.innerHTML = response.body
    let pData = newDiv.querySelectorAll('p')

    let body = (pData[0]) ? (newDiv.children[0].children.length === 1 && (pData[0].textContent === '' || pData[0].textContent === ' ') ? true : false) : false

    let bul = (altText || metaDescriptions || slugCaracteres !== 0 || body || date === "") ? true : false

    setBulRequired({altText,metaDescriptions,slug: slugCaracteres,body})

    setRequirect({ ...values, ...values2, date: date });
    setTimeout(() => {
      setRequirect({
        title: "title",
        description: "descrption",
        summary: "summary",
        categories: [],
        altText: "altText",
        date: "date",
        metaDescriptions: "metaDescriptions",
        slug: "slug"
      });
    }, 3000);

    if(bul){return}
    
    handleCreateOrEditBlog({
      ...values,
      status: type,
      date: moment.utc(date.format("YYYY-MM-DD HH:mm")).format(),
    });
  };

  const functionPreviuw = () => {
    let values = blogForm.getFieldValue();
    let values2 = blogForm2.getFieldValue();

    let response = funcBodyInput.data()
    let newDiv = document.createElement("div");
    newDiv.innerHTML = response.body
    let pData = newDiv.querySelectorAll('p')

    let body = (pData[0]) ? (newDiv.children[0].children.length === 1 && (pData[0].textContent === '' || pData[0].textContent === ' ') ? true : false) : false

    let cases = ['', ' ', undefined]

    let firstBul = (cases.includes(values?.imageUrl) && cases.includes(values?.altText) && cases.includes(values?.title) && cases.includes(values?.categories))
    let secondBul = (cases.includes(values2?.metaDescriptions) && cases.includes(values2?.slug))

    if (!body && !firstBul && !secondBul) {
      getImagesCarg(images,(e) => {
        const responseHTML = funcBodyInput.functionReplaceImage(e)
        const description = {
          html: responseHTML
        }
        
        localStorage.setItem("previuw", JSON.stringify({...values, ...values2, description}));
  
        window.open(`${INTERNAL_LINKS.BLOGS_NEW}/-1`, "_blank");
      })
    }else {
      setBulModal3(true);
    }
  };

  const customFormat = (value) =>
    `${moment(value.format("YYYY/MM/DD HH"), "YYYY/MM/DD HH").format(
      "YYYY/MM/DD"
    )} at ${moment(value.format("YYYY/MM/DD HH"), "YYYY/MM/DD HH").format(
      "HH a"
    )} (Pacific Time)`;

  useEffect(() => {
    if (id !== null) {
      if (accepted === "true" || accepted === "false") {
        updateBlogPost(
          id,
          { statusRequest: accepted === "true" ? "accepted" : "reject" },
          (error) => {
            if (error) {
              return notification.error({
                message: error,
              });
            }
            getBlogsPostsByUser();

            return notification.success({
              message: "Blog Updated Successfully",
            });
          }
        );
      }
    }
  }, [id, accepted, updateBlogPost, getBlogsPostsByUser]);

  const onCancelModal = (type,bul=true) => {
    setEditOrDeleteBlogPost({});
    blogForm.resetFields();
    blogForm2.resetFields();
    if(typeof funcBodyInput.reset === "function" ){
      funcBodyInput.reset()
    }
    if(bul){
      setCurrentTab(type === "draft" ? "1" : "2")
    }
    // setVisibleDeleteModal(false);
  };

  const handleCreateOrEditBlog = (data) => {

    try{
      if(!data.date){
        setBulLoad(true)
      }else{
        setBulLoad2(true)
      }
  
      if(data.slug.length > 60){
        notification.error({
          message: "Slug (max 60 characters)",
        });
      }
  
      if(data.metaDescriptions.length > 155){
        notification.error({
          message: "Meta Descriptions (max 155 characters)",
        });
      }
  
      if(data.altText.length > 125){
        notification.error({
          message: "Alt-text (max 125 characters)",
        });
      }
      
      getImagesCarg(images,(e) => {
        const containerMap = document.createElement("div");
        const responseHTML = funcBodyInput.functionReplaceImage(e)
        const description = {
          html: responseHTML
        }
  
        containerMap.innerHTML = responseHTML
        let getTitleByKey = getTitleByKeys(value)
        let titleTop = getTitleTop(value)
        let shareForTaxonomysText = getAllTitles(value)
  
        if(!getTitleByKey){
          setBulLoad(false)
          setBulLoad2(false)
          return
        }
  
        if (editOrDeleteBlogPost.id) {
          updateBlogPost(editOrDeleteBlogPost.id, {
            ...data,
            description,
            author: usersSelected[data?.author],
            shareForTaxonomys: getTitleByKey,
            shareForTopTaxonomys: titleTop,
            shareForTaxonomysText,
            shareForText: containerMap.textContent,
            textSlug: data.slug.replaceAll("-"," "),
            image2: file1,
            imageUrl: file2,
            statusRequest: (data.status === "draft" && !data.date) ? "waiting" : data.statusRequest
          }, (error) => {
            if (error) {
              return notification.error({
                message: error,
              });
            }
  
            setEditOrDeleteBlogPost({});
            onCancelModal(data.type);
  
            getBlogsPostsByUser();
            finish();
    
            return notification.success({
              message: "Blog Updated Successfully",
            });
          });
        } else {
          createBlogPost(
            {
              ...data,
              author: usersSelected[data?.author],
              shareForTaxonomys: getTitleByKey,
              shareForText: containerMap.textContent,
              shareForTaxonomysText,
              shareForTopTaxonomys: titleTop,
              textSlug: data.slug.replaceAll("-"," "),
              UserId: userProfile?.id,
              image2: file1,
              imageUrl: file2,
              description,
              ChannelId: ChannelIdSelect,
              statusRequest: (data.status === "draft" && !data.date) ? "waiting" : "pending",
            },
            (error) => {
              if (error) {
                return notification.error({
                  message: error,
                });
              }
  
              setEditOrDeleteBlogPost({});
              onCancelModal(data.type);
    
              getBlogsPostsByUser();
              finish();
              if (data.date === undefined) {
                notificationEmailToNewContentCreators({
                  channelName: "Blog Post User",
                  channelAdmin: userProfile?.firstName,
                  channelAdminEmail: userProfile?.email,
                  contentType: "blogs",
                  name: data.title,
                  link: "blogs not have link",
                });
              }
    
              return notification.success({
                message: "Blog Posted Successfully",
              });
            }
          );
        }
        
        setBulLoad(false)
        setBulLoad2(false)
      })
    }catch(er){
      notification.error({
        message: er,
      });
    }
  };

  const handleUpdateMap = (data) => {
    try{
      //format slug camp
      let slug = blogForm2.getFieldValue("slug")
      let slugFormated = slug?.replaceAll(" ", "-")
      slugFormated = slugFormated?.toLowerCase();
      slugFormated = slugFormated?.replaceAll(/[^a-z0-9-]+/g, "");
      blogForm2.setFieldsValue({
        slug: slugFormated
      })

      //update map html
      const containerMap = document.createElement("div");
      containerMap.innerHTML = data

      const childrenIterect = containerMap.children[0].children

      let numIterect = 0
      let mapSituation = []
      let H3Object = []

      for (let i = 0; i < childrenIterect.length; i++) {
        if(childrenIterect[i].tagName === "H2"){
          numIterect++
          H3Object = []
          mapSituation[numIterect-1] =  {
            key: numIterect,
            label: <a style={{textDecoration:'none',color:'black'}} href={`#${childrenIterect[i].id}`}>{childrenIterect[i].textContent}</a>,
          }
        }
        if(childrenIterect[i].tagName === "H3"){
          H3Object = [
            ...H3Object,
            <div style={{borderBottom:'1px #cdcdcdb3 solid',position:'relative',left:'0px',width:'100%',padding:'10px'}}><a style={{textDecoration:'none',color:'black'}} href={`#${childrenIterect[i].id}`}>{childrenIterect[i].textContent}</a></div>
          ]
          mapSituation[numIterect-1] =  {
            ...mapSituation[numIterect-1],
            children: H3Object
          }
        }
      }

      setMapSituation(mapSituation.map(e => {
        return e.children ? (<Panel header={e.label} key={e.key} className="site-collapse-custom-panel" style={{background:"white"}}>
        {e.children.map(a => a)} 
        </Panel>) : (
          <div key={e.key} style={{padding:'15px',background:'white',borderBottom:'#cdcdcdb3 solid 1px'}}>{e.label}</div>
        )
      }))
    }catch(er){
      console.log(er)
    }
  }

  useEffect(() => {
    try{
      if (clock.current) {
        clearTimeout(clock.current)  
      }
  
      if(funcBodyInput !== undefined){
        if(typeof funcBodyInput.data === "function" ){
          let response = funcBodyInput.data()
  
          setImages(response.images)
          handleUpdateMap(response.body)
        }
      }

      clock.current = setTimeout(() => {
        setBulRepeatReact(!bulRepeatReact)
      }, 2000); 
    }catch(er){
      console.log(er)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setBulRepeatReact,bulRepeatReact,funcBodyInput])

  useEffect(() => {
    if(editOrDeleteBlogPost?.id){
      if(funcBodyInput !== undefined){
        if(typeof funcBodyInput.data === "function" ){
          const containerMap = document.createElement("div");
          containerMap.innerHTML = editOrDeleteBlogPost?.description?.html
          if(containerMap?.children.length === 1){
            funcBodyInput.functionValueDefault(containerMap?.children[0]?.innerHTML)
          }else{
            funcBodyInput.functionValueDefault(containerMap?.innerHTML)
          }
        }
      }

      setTextMap(editOrDeleteBlogPost?.title)
      setSummary1(editOrDeleteBlogPost?.altText?.length)
      setSummary2(editOrDeleteBlogPost?.metaDescriptions?.length)
      setSummary3(editOrDeleteBlogPost?.slug?.length)
      setSummary4(editOrDeleteBlogPost?.metaTitle?.length)
      setValue(editOrDeleteBlogPost?.categories)
      setFile1(editOrDeleteBlogPost?.image2)
      setFile2(editOrDeleteBlogPost?.imageUrl)

      blogForm.setFieldsValue({
        ...editOrDeleteBlogPost,
      })
      blogForm2.setFieldsValue({
        ...editOrDeleteBlogPost,
        author: editOrDeleteBlogPost?.aut?.email
      })
    }
  },[editOrDeleteBlogPost,funcBodyInput,blogForm,blogForm2])

  useEffect(() => {
    resetData({
      function: () => onCancelModal("",false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[funcBodyInput])

  useEffect(() => {
    //porcentage scroll page

    const searchContainerInputScroll = () => {
      let containerScroll = document.getElementById("container-scroll")
      if (containerScroll) {
        containerScroll.addEventListener("scroll", () => {
          let scrollActual = containerScroll.scrollTop;
          let altoVisible = containerScroll.clientHeight;
          let altoTotal = containerScroll.scrollHeight;

          setScrollPor((scrollActual / (altoTotal - altoVisible)) * 100);
          
        })

      } else {
        setTimeout(() => {
          searchContainerInputScroll();
        }, 100);
      }
    };

    searchContainerInputScroll();
  },[])

  const searchIAResults = (e) => {
    try{
      // if(clock2.current){
      //   clearTimeout(clock2.current)
      // }
      // clock2.current = setTimeout(async () => {
      //   if(e !== undefined){
      //     if(typeof e.data === "function" ){
      //       let responseD = e.data()

            // const containerMap = document.createElement("div");
            // containerMap.innerHTML = responseD.body

            // const response = await httpClient({
            //   method: 'POST',
            //   url: `public/openai/prompt`,
            //   headers: { 'Content-Type': 'application/json' },
            //   data: {
            //     prompt: `Cual de la de las siguientes taxonomias: {community}. Crees que se acoplen con respecto al siguiente texto: ${containerMap.textContent}`,
            //   }
            //   // params: {
            //   //   prompt: `Cual de la de las siguientes taxonomias: ${JSON.stringify(convertFormat(TAXONOMYS_SKILLS).output)}. Crees que se acoplen con respecto al siguiente texto: ${containerMap.textContent}`,
            //   // },
            // })

            // if (response.status === 200) {
            //   const parsedData = response.data.bot.trim(); // trims any trailing spaces/'\n
        
            //   // typeText(messageDiv, parsedData);
        
            //   // // active the input
            //   // chatBox.disabled = false;
            //   console.log("DATA RESPONSE:",parsedData)
            // } else {
            //   const err = await response.data.text();
        
            //   console.log("ERROR RESPONSE:",err)
            //   // alert(err);
            // }
        //   }
        // }
      // }, 2500);
    }catch(er){
      console.log(er)
    }
  }

  const searchBlogsCoincidence = (e) => {
    try{
      if (clock3.current) {
        clearTimeout(clock3.current);
      }
      clock3.current = setTimeout(() => {
        if(e !== undefined){
          if(typeof e.data === "function" ){
            // let responseD = e.data()

            // const containerMap = document.createElement("div");
            // containerMap.innerHTML = responseD.body

            // searchBlogPostLinkBuilder(containerMap.textContent,(b) => {
            //   console.log(b)
            // })
          }
        }
      }, 5000);
    }catch(er){
      console.log(er)
    }
  }

  const handleSearchUser = (values) => {
    if(clock2.current){clearTimeout(clock2.current)}
    clock2.current = setTimeout(() => {
      setFetching(true)
      searchUserForCouncilEventPanelist(values,(data) => {
        let newData = {}
        let information = data.map(e => {
          newData[e?.email] = e?.id;
          return {label:e?.email,value:e?.email};
        })
        setUsersSelected({...usersSelected || {}, ...newData})
        setUsersAuthor(information)
        setFetching(false)
      });
    }, 500); 
  };

  return (<>
      <div className="container-form-blogpost-for-user">
        <div className="container-fixed-see-porcentage-scroll"></div>
        <div className="container-header-blog-form">Add Blogs Details</div>
        <div className="container-forms-blogs">
          <div className="container-first-form">
            <Form
              form={blogForm}
              layout="vertical"
              onFinish={() => {
                // let values2 = blogForm2.getFieldsValue();
                if(typeS === "draft" || typeS === "published"){
                  handleSaveDraftBlogPost(typeS)
                }
                if(typeS === "shedule"){
                  handleSaveLaterBlogPost("draft")
                }
                setTypeS("draft")
              }}
              style={{ maxWidth:'1000px',width:'100%' }}
            >
              <Form.Item style={{ marginBottom: 0 }}>
                <Form.Item 
                  name="image2" 
                  label="Image"
                  rules={[{ required: true, message: "Image is required." }]}
                  style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                >
                  {/* <ImageUpload type="blog-style" className="image-upload-2" aspect={755 / 755} textScale="755 * 755 px" /> */}
                  <ImageUploadPersonalized aspect={755 / 755} textScale="755 * 755 px" onChange={(e,file) => {
                    setFile1(e)
                  }}/>
                </Form.Item>
                <Form.Item 
                  name="imageUrl" 
                  label="Image Page"
                  rules={[{ required: true, message: "Image is required." }]}
                  style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                >
                  <ImageUploadPersonalized aspect={755 / 305} textScale="755 * 305 px" onChange={(e,file) => {
                    setFile2(e)
                    // console.log(file.type,file)
                    // let fileURL = window.URL.createObjectURL(new Blob([file], { type: file.type }));
                    // let fileLink = document.createElement('a');

                    // fileLink.href = fileURL;
                    // fileLink.setAttribute('download', file.name);
                    // document.body.appendChild(fileLink);

                    // fileLink.click();
                    // document.body.removeChild(fileLink);
                    // window.URL.revokeObjectURL(fileURL);
                    
                    // setImagesPreviuw(URL.createObjectURL(imgBlog))
                    // donwloadEventCertificate()
                  }} />
                </Form.Item>
              </Form.Item>
              <Form.Item
                name="altText"
                label={<p style={{margin:'0px',padding:'0px'}}>
                  <span style={{fontSize:'18px',transform:'translateY(5px)',color:'#f75b27',marginRight:'3px',display:'inline-block'}}>*</span>Type ALT-text
                </p>}
              >
                <CustomInput
                  multiple={true}
                  placeholder="Child's Sensory andMotor Development"
                  className="custom-input-blog-for-user"
                  onChange={(e) => handleSummary(e, 1)}
                  style={{ maxHeight: '100px'}}
                />
              </Form.Item>
              <div className="container-blog-form-limits">
                <div className={!bulRequired["altText"] ? "container-inter-case" : "container-inter-case-h"}>
                  <p className={(summary1 > 125) ? "response-limits-case" : "response-limits-case-blog"}>
                    ALT-text (max 125 characters)
                  </p>
                  <p style={{margin: "0px"}}>
                    <span style={summary1 > 125 ? { color: "#f75b27" } : {}}>{125 - summary1}</span>{" "}/ 125
                  </p>
                </div>
                <div className={bulRequired["altText"] ? "container-inter-case" : "container-inter-case-h"}>
                  <p className="response-limits-case">Type ALT-text is required</p>
                </div>
              </div>

              <Form.Item
                name="title"
                label="Title"
                rules={[{ required: true, message: "Title is required." }]}
              >
                <CustomInput
                  multiple={true} 
                  style={{ maxHeight: '100px'}}
                  placeholder="Child's Sensory andMotor Development"
                  className="custom-input-blog-for-user"
                  onChange={(e) => setTextMap(e)}
                />
              </Form.Item>

              <p style={{margin:'0px',padding:'0px',marginBottom:'10px'}}>
                <span style={{fontSize:'18px',transform:'translateY(5px)',color:'#f75b27',marginRight:'3px',display:'inline-block'}}>*</span>Body
              </p>
              <InputBlog 
                functionData={(e) => setFuncBodyInput(e)}
                onChange={(e) => {
                  searchBlogsCoincidence(e)
                  searchIAResults(e)
                }}
                email={userProfile?.email}
              />
              <div className="container-blog-form-limits"  style={{transform:'translateY(0px)'}}>
                <div className={bulRequired["body"] ? "container-inter-case" : "container-inter-case-h"}>
                  <p className="response-limits-case">Body is required</p>
                </div>
              </div>

              <Form.Item
                name="categories"
                label="Categories"
                rules={[{ required: true, message: "Categories is required." }]}
              >
                {/* <Select
                  mode="multiple"
                  className={clsx("custom-select", { border: "bordered" })}
                  style={{ background: "white" }}
                >
                  {allCategories?.map((item) => {
                    return (
                      <Select.Option key={item?.value} value={item?.value}>
                        {item?.title}
                      </Select.Option>
                    );
                  })}
                </Select> */}
                <TreeSelect 
                   treeData={taxon}
                   value={value}
                   onChange={(e) => onChangeTree(e)}
                   treeCheckable={true}
                   showCheckedStrategy={SHOW_PARENT}
                   className="container-input-tree"
                   bordered={true}
                   style= {{
                     width: '100%',
                   }}
                   dropdownStyle={{
                    paddingLeft:'20px',
                    paddingRight:'20px'
                   }}
                />
              </Form.Item>

              <div
                className="container-btns"
              >
                <CustomButton
                  text="Cancel"
                  type="third outlined"
                  size="lg"
                  onClick={() => onCancelModal()}
                />
                <CustomButton
                  text="Save As Draft"
                  type="primary"
                  size="lg"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()

                    setTypeS("draft")
                    blogForm.submit();
                  }}
                  style={{ marginLeft: "10px" }}
                />
                <CustomButton
                  text="Post"
                  type="secondary"
                  size="lg"
                  style={{ marginLeft: "10px" }}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    
                    setBulModal2(true);
                  }}
                />
                <CustomButton
                  text="PREVIEW"
                  type="secondary"
                  size="lg"
                  onClick={() => {
                    functionPreviuw();
                  }}
                  style={{ marginLeft: "10px" }}
                />
              </div>
            </Form>
          </div>
          <div className="container-second-form">
            <Form
                form={blogForm2}
                layout="vertical"
                style={{ maxWidth:'1000px',width:'100%',display:'flex',flexDirection:'column',height:'100%' }}
              >
                <Form.Item
                  name="canonicalTags"
                  label="Canonical Tags"
                  rules={[{ required: false }]}
                >
                  <CustomInput
                    placeholder="Canonical Tag"
                    className="custom-input-blog-for-user"
                  />
                </Form.Item>
                {(userProfile?.email === "morecontrol64@gmail.com" || userProfile?.email === "enrique@hackinghr.io" || userProfile?.email === "jessa@hackinghr.io" || userProfile?.email === "erubio2013@gmail.com" || userProfile?.email === "barbara@hackinghr.io" || userProfile?.email === "nahema.cossette2702@gmail.com") && <Form.Item
                  name="author"
                  label="Author"
                  rules={[{ required: false }]}
                >
                  <AutoComplete
                    size="large"
                    className="custom-input-blog-for-user"
                    placeholder="Search User By Email"
                    onSearch={(e) => handleSearchUser(e)}
                    // // notFoundContent={fetching ? <Spin size="small" /> : null}
                    options={usersAuthor}
                  />
                </Form.Item>}
                <Form.Item
                  label={<p style={{margin:'0px',padding:'0px'}}>
                    <span style={{fontSize:'18px',transform:'translateY(5px)',color:'#f75b27',marginRight:'3px',display:'inline-block'}}>*</span>Meta Descriptions
                  </p>}
                  name="metaDescriptions"
                >
                  <CustomInput 
                    multiple={true} 
                    className="custom-input-blog-for-user" 
                    placeholder="Add meta-description" 
                    style={{fontSize:'13px !important',padding:'5px !important'}} 
                    onChange={(e) => handleSummary(e,2)}  
                  />
                </Form.Item>
                <div className="container-blog-form-limits">
                  <div className={!bulRequired["metaDescriptions"] ? "container-inter-case" : "container-inter-case-h"}>
                    <p className={(summary2 > 155) ? "response-limits-case" : "response-limits-case-blog"}>
                      Meta Descriptions (max 155 characters)
                    </p>
                    <p style={{margin: "0px"}}>
                      <span style={summary2 > 155 ? { color: "#f75b27" } : {}}>{155 - summary2}</span>{" "}/ 155
                    </p>
                  </div>
                  <div className={bulRequired["metaDescriptions"] ? "container-inter-case" : "container-inter-case-h"}>
                    <p className="response-limits-case">Meta Descriptions is required</p>
                  </div>
                </div>
                <Form.Item
                  label={<p style={{margin:'0px',padding:'0px'}}>
                    <span style={{fontSize:'18px',transform:'translateY(5px)',color:'#f75b27',marginRight:'3px',display:'inline-block'}}>*</span>Slug
                  </p>}
                  name="slug"
                >
                  <CustomInput 
                    className="custom-input-blog-for-user" 
                    placeholder="Add Slug" 
                    style={{fontSize:'13px !important',padding:'5px !important'}} 
                    onChange={(e) => handleSummary(e,3)}
                  />
                </Form.Item>
                <div className="container-blog-form-limits" >
                  <div className={(bulRequired["slug"] === 0) ? "container-inter-case" : "container-inter-case-h"}>
                    <p className={(summary3 > 60) ? "response-limits-case" : "response-limits-case-blog"}>
                      Slug (max 60 characters)
                    </p>
                    <p style={{margin: "0px"}}>
                      <span style={summary3 > 60 ? { color: "#f75b27" } : {}}>{60 - summary3}</span>{" "}/ 60
                    </p>
                  </div>
                  <div className={(bulRequired["slug"] === 1) ? "container-inter-case" : "container-inter-case-h"}>
                    <p className="response-limits-case">Slug is required</p>
                  </div>
                  <div className={(bulRequired["slug"] === 2) ? "container-inter-case" : "container-inter-case-h"}>
                    <p className="response-limits-case">Slug cannot contain special characters</p>
                  </div>
                </div>
                <Form.Item
                  label={<p style={{margin:'0px',padding:'0px'}}>
                    <span style={{fontSize:'18px',transform:'translateY(5px)',color:'#f75b27',marginRight:'3px',display:'inline-block'}}>*</span>Meta Title
                  </p>}
                  name="metaTitle"
                >
                  <CustomInput 
                    className="custom-input-blog-for-user" 
                    placeholder="Meta Title" 
                    style={{ fontSize: '13px !important', padding: '5px !important' }}
                    onChange={(e) => handleSummary(e, 4)}
                  />
                </Form.Item>
                <div className="container-blog-form-limits" >
                  <div className={(bulRequired["metaTitle"] === 0) ? "container-inter-case" : "container-inter-case-h"}>
                    <p className={(summary4 > 60) ? "response-limits-case" : "response-limits-case-blog"}>
                      Meta Title (max 60 characters)
                    </p>
                    <p style={{margin: "0px"}}>
                      <span style={summary4 > 60 ? { color: "#f75b27" } : {}}>{60 - summary4}</span>{" "}/ 60
                    </p>
                  </div>
                  <div className={(bulRequired["metaTitle"] === 1) ? "container-inter-case" : "container-inter-case-h"}>
                    <p className="response-limits-case">Meta Title is required</p>
                  </div>
                  {/* <div className={(bulRequired["metaTitle"] === 2) ? "container-inter-case" : "container-inter-case-h"}>
                    <p className="response-limits-case">Meta Title cannot contain special characters</p>
                  </div> */}
                </div>
                {/* <Form.Item
                  label="Yoast SEO"
                  name="yoastSeo"
                  className="container-label-orange"
                >
                  <div className="container-link-builder">Yoast SEO</div>
                </Form.Item> */}
                <Form.Item
                  label="LinkBuilder"
                  name="linkBuilder"
                  className="container-label-orange"
                >
                  <div className="container-link-builder">LinkBuilder (Comparing...)</div>
                </Form.Item>
                <div style={{width:'100%',flexGrow:'1',position:'relative'}}>
                  <div className="childSensory">
                    <div className="container-header-child">
                      <h3>{textMap}</h3>
                      <div style={{width:`${scrollPor}%`}}></div>
                    </div>
                    <div className="container-list-map">
                      <Collapse
                        bordered={false}
                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                        className="site-collapse-custom-collapse"
                      >
                        {mapSituation}
                      </Collapse>
                    </div>
                  </div>
                  <div style={{height:'100%',width:'100%'}}></div>
                </div>
              </Form>
          </div>
        </div>
      </div>
      <Modal
          visible={bulModal2}
          footer={null}
          width={400}
          bodyStyle={{
          marginBottom: "40px",
          padding: "20px",
          display: "flex",
          justifyContent: "center",
          }}
          onCancel={() => {
          setBulModal2(false);
          }}
      >
        <div
          style={{
              width: "100%",
              height: "auto",
              display: "flex",
              justifyContent: "center",
          }}
        >
        <div
            style={{
            marginTop: "0px",
            width: "470px",
            background: "white",
            height: "auto",
            borderRadius: "5px",
            display: "flex",
            padding: "40px",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            }}
        >
            <h3 style={{ margin: "0px", paddingTop: "20px" }}>Post Now</h3>
            <div
            style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "10px",
            }}
            >
            <CustomButton
                className="button-speaker font-size-more"
                text="Post Now"
                size="md"
                loading={bulLoad}
                style={{
                padding: "10px",
                height: "40px",
                width: "320px",
                marginTop: "10px",
                }}
                type={"primary"}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()

                  setTypeS("published")
                  blogForm.submit();
                }}
            />
            </div>
            <h3 style={{ margin: "0px", paddingTop: "40px" }}>
              Schedule Your Post
            </h3>
            <DatePicker
              className={clsx("custom-input")}
              style={{ width: "100%" }}
              onChange={(date) => {
                  setDate(date);
              }}
              format={customFormat}
              showTime
              panelRender={(data) => {
                  return (
                  <div
                      className="container-ant-picker"
                      style={{ position: "relative" }}
                  >
                      <div
                      style={{
                          position: "absolute",
                          right: "0px",
                          height: "40.89px",
                          width: "170px",
                          background: "white",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontWeight: "bold",
                      }}
                      >
                      Hour ( in Pacific Time )
                      </div>
                      {data}
                  </div>
                  );
              }}
            />
            {!required.title && (
            <p style={{ color: "#fe5621", margin: "5px" }}>
                Title is required
            </p>
            )}
            {!required.altText && (
            <p style={{ color: "#fe5621", margin: "5px" }}>
                Alt Text is required
            </p>
            )}
            {!required.metaDescriptions && (
            <p style={{ color: "#fe5621", margin: "5px" }}>
                Meta Description is required
            </p>
            )}
            {!required.slug && (
            <p style={{ color: "#fe5621", margin: "5px" }}>
                Slug is required
            </p>
            )}
            {!required.categories && (
            <p style={{ color: "#fe5621", margin: "5px" }}>
                Categories is required
            </p>
            )}
            {!required.date && (
            <p style={{ color: "#fe5621", margin: "5px" }}>
                Date is required
            </p>
            )}
            <div
            style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "10px",
            }}
            >
            <CustomButton
                className="button-speaker font-size-more"
                text="Schedule Your Post"
                size="md"
                loading={bulLoad2}
                style={{
                padding: "10px",
                height: "40px",
                width: "320px",
                marginTop: "20px",
                }}
                type={"primary"}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()

                  setTypeS("shedule")
                  blogForm.submit();
                }}
            />
            </div>
        </div>
        </div>
      </Modal>
      <CustomModal
          visible={bulModal3}
          footer={null}
          width={400}
          bodyStyle={{
          marginBottom: "40px",
          padding: "20px",
          display: "flex",
          justifyContent: "center",
          }}
          onCancel={() => {
          setBulModal3(false);
          }}
      >
          <h3>All fields are required</h3>
      </CustomModal>
      {/* <div className="container-modals-image" id="modal-container-image">
        
      </div> */}
  </>)
}

const mapStateToProps = (state) => ({
  ...councilEventSelector(state),  
  userProfile: homeSelector(state).userProfile,
  allCategories: categorySelector(state).categories,
  // s3Hash: envSelector(state).s3Hash,
});

const mapDispatchToProps = {
  ...councilEventActions,
  createBlogPost,
  updateBlogPost,
  notificationEmailToNewContentCreators,
  getImagesCarg,
  searchBlogPostLinkBuilder,
  // getBlogsPostsByUser,
};
  
  export default connect(mapStateToProps, mapDispatchToProps)(FormCreateOrEditBlog);