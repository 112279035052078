import httpClient from "./httpClient";
import moment from "moment";

export const addUserSpeakerToPanelEndPoint = (payload) => {
  return httpClient.post("private/speakers/add-speaker-to-panel", {
    ...payload,
  });
};

export const joinSpeakerAwaitEvent = (payload) => {
  const userTimezone = moment.tz.guess();

  return httpClient.post(
    `private/speaker/event/await/panelist?userTimezone=${userTimezone}`,
    payload
  );
};

export const joinExternalAwaitEvent = (payload) => {
  const userTimezone = moment.tz.guess();

  return httpClient.post(
    `private/externalspeaker/event/await/panelist?userTimezone=${userTimezone}`,
    payload
  );
};

export const getAllExternalSpeakers = (idConfetence) => {
  return httpClient.post(
    `private/externalspeaker/event/await/getAllExternalSpeakers/${idConfetence}`
  );
};

export const allPanelSpeakersEndPonit = (payload) => {
  return httpClient.get(`public/speakers/all-panel/${payload.type}`);
};

export const getAllUserSpeakerEndPoint = (payload) => {
  return httpClient.get(`public/speakers/all-users-speakers/${payload.UserId}`);
};

export const getAllParrafsEndPoint = (payload) => {
  return httpClient.get(`public/speakers/get-all-parrafs/${payload.type}`);
};

export const setStatusUserLibraryEndPoint = (payload) => {
  return httpClient.post("private/speakers/set-status-user", {
    ...payload,
  });
};

export const getStatusUserLibraryEndPoint = (payload) => {
  return httpClient.get(`private/speakers/get-status-user`);
};

export const removeUserSpeakerToPanelEndPoint = (payload) => {
  return httpClient.post("private/speakers/remove-user-panel", {
    ...payload,
  });
};

export const changeStatusEndPoint = (payload) => {
  const { status, PanelId } = payload;
  return httpClient.post("private/speakers/changue_status", {
    status,
    PanelId,
  });
};

export const getAllPanelSpeakersAdminEndPonit = (payload) => {
  return httpClient.get(`private/speakers/all-panel/${payload.type}`);
};

export const deletePanelSpeakersEndPonit = (payload) => {
  return httpClient.delete(`private/speakers/delete-panel/${payload.PanelId}`);
};

export const registerUserIfNotAreRegisterConference2023EndPoint = () => {
  return httpClient.post("private/speakers/send-email-register-conference");
};

export const getAllPanelsOfOneUserEndPoint = (UserId) => {
  return httpClient.get(`public/speakers/all-panel-of-user`, {
    params: {
      id: UserId.UserId.id,
      type: UserId.UserId.type,
    },
  });
};

export const getAllPanelsOfOneGlobalConferenceEndPoint = (
  id,
  isAdmin = false
) => {
  return httpClient.get(
    `public/speakersss/all-panel-of-one-global-conference/${id}&${isAdmin}`
  );
};

export const getPanelsForIdEndPoint = (payload) => {
  return httpClient.get(`private/speakers/get-one-panel/${payload.PanelId}`);
};

export const getAllMyPanels = () => {
  return httpClient.get(`private/speakers/all-my-panel-of-user`);
};

export const getAllMemberSpeakerPanelEndPoint = () => {
  return httpClient.get(`private/speakers/get-member-speakers-of-one-user`);
};

export const getAllSponsors2023EndPoint = () => {
  return httpClient.get(`public/speakers/get-all-sponsor`);
};

export const addedToPersonalAgendaEndPoint = (payload) => {
  return httpClient.post("private/speakers/added-to-agenda", {
    ...payload,
  });
};

export const addPanelSpeakersEndPoint = (payload) => {
  return httpClient.post("private/speakers/add-new-panel", {
    ...payload,
  });
};

export const editPanelSpeakersEndPonit = (payload) => {
  const { panels } = payload;
  return httpClient.post("private/speakers/edit-panel", {
    panels,
  });
};
