import { handleActions } from "redux-actions";
import { Map } from "immutable";

// Action Type Imports
import { constants as liveChatConstants } from "../actions/live-chat-actions";

// Events's Reducer
export const reducers = {
    [liveChatConstants.SET_CHAT_LIVE]: (state, { payload }) => {
        return state.merge({ liveChat: payload.liveChat });
    },
};

export const initialState = () =>
    Map({
        liveChat: [],
    });

export default handleActions(reducers, initialState());
