import { create } from "zustand";

export const useChatMessageStore = create(set => ({
  messageWindows: [],
  chatTabItemIds: [],
  onlineUsers: [],
  setOnlineUsers: (users) => set(() => ({ onlineUsers: users })),
  setMessageWindows: (user) => set(() => {

    const window = {
      img: user.img,
      name: user.name,
      id: user.id,
      blogId: user?.blogId,
      UserId: user?.UserId,
      titleProfessions: user.titleProfessions,
    };

    return {
      messageWindows: [window],
    };
  }),
  removeMessageWindow: (id) => set(state => {
    const windows = [...state.messageWindows];

    const filteredWindows = windows.filter(item => item.blogId !== id);

    return { messageWindows: filteredWindows };
  }),
  setTyping: ({ senderId, isTyping }) => set(state => {
    const windows = [...state.messageWindows];
    const windowIndex = windows.findIndex(item => item.id === senderId);

    if (windowIndex === -1) {
      return { messageWindows: state.messageWindows };
    }
    ;

    windows[windowIndex] = {
      ...windows[windowIndex],
      isTyping,
    };

    return { messageWindows: windows };
  }),
  pushChatTabItemId: ({ id }) => set(state => {
    const ids = [...state.chatTabItemIds, id];

    return {
      chatTabItemIds: ids,
    };
  }),
  deleteChatTabItemId: ({ id }) => set(state => {
    const ids = [...state.chatTabItemIds];

    return { chatTabItemIds: ids.filter(i => i !== id) };
  }),
}));