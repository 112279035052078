import { createSelector } from "reselect";

const annualConferenceClassDataSelector = (state) => state.annualSessionClass;

const resultSelector = createSelector(
  annualConferenceClassDataSelector,
  (payload) => {
    return {
      annualConferenceClasses: payload.get("annualConferenceClasses"),
      annualConferenceClass: payload.get("annualConferenceClass"),
    };
  }
);

export const annualConferenceClassSelector = (state) => ({
  ...resultSelector(state),
});
