import { createAction } from "redux-actions";

const GET_ALL_COURSES = "GET_ALL_COURSES";
const SET_ALL_COURSES = "SET_ALL_COURSES";
const GET_COURSES = "GET_COURSES";
const GET_COURSE_NEW = "GET_COURSE_NEW";
const GET_COURSE = "GET_COURSE";
const SET_COURSE = "SET_COURSE";
const GET_COURSE_CLASSES = "GET_COURSE_CLASSES";
const SET_COURSE_CLASSES = "SET_COURSE_CLASSES";
const GET_COURSE_INSTRUCTORS = "GET_COURSE_INSTRUCTORS";
const SET_COURSE_INSTRUCTORS = "SET_COURSE_INSTRUCTORS";
const GET_COURSE_SPONSORS = "GET_COURSE_SPONSORS";
const SET_COURSE_SPONSORS = "SET_COURSE_SPONSORS";
const SET_LOADING = "SET_COURSE_LOADING";
const CLAIM_COURSE = "CLAIM_COURSE";
const SET_COURSES = "SET_COURSES";
const SET_COURSE_NEW = "SET_COURSE_NEW";
const REGISTER_IN_A_CERTIFICATE = "REGISTER_IN_A_CERTIFICATE";
const SET_USERS_OF_A_CERTIFICATE = "SET_USERS_OF_A_CERTIFICATE";
const UPDATE_ACTIVITY_STATUS = "UPDATE_ACTIVITY_STATUS"
const GET_COURSE_ACTIVITY_STATUS_INFO = "GET_COURSE_ACTIVITY_STATUS_INFO"
const SET_COURSE_ACTIVITY_STATUS_INFO = "SET_COURSE_ACTIVITY_STATUS_INFO"
const GET_CERTIFICATE_USERS_BY_SLUG = "GET_CERTIFICATE_USERS_BY_SLUG"
const SET_CERTIFICATE_USERS_BY_SLUG = "SET_CERTIFICATE_USERS_BY_SLUG"

export const constants = {
  GET_ALL_COURSES,
  SET_ALL_COURSES,
  GET_COURSE,
  SET_COURSE,
  GET_COURSE_CLASSES,
  SET_COURSE_CLASSES,
  GET_COURSE_INSTRUCTORS,
  SET_COURSE_INSTRUCTORS,
  GET_COURSE_SPONSORS,
  SET_COURSE_SPONSORS,
  SET_LOADING,
  CLAIM_COURSE,
  GET_COURSES,
  GET_COURSE_NEW,
  SET_COURSES,
  SET_COURSE_NEW,
  REGISTER_IN_A_CERTIFICATE,
  SET_USERS_OF_A_CERTIFICATE,
  UPDATE_ACTIVITY_STATUS,
  GET_COURSE_ACTIVITY_STATUS_INFO,
  SET_COURSE_ACTIVITY_STATUS_INFO,
  GET_CERTIFICATE_USERS_BY_SLUG,
  SET_CERTIFICATE_USERS_BY_SLUG
};

// ------------------------------------
// Actions
// ------------------------------------
export const getAllCourses = createAction(GET_ALL_COURSES, (filter) => ({
  filter,
}));
export const setAllCourses = createAction(SET_ALL_COURSES, (courses) => ({
  courses,
}));
export const getCourseNew = createAction(GET_COURSE_NEW, (id,slug) => ({ id,slug }));
export const getCourses = createAction(GET_COURSES, (type,page) => ({
  type,page
}));

export const registrationInACertificate = createAction(REGISTER_IN_A_CERTIFICATE, ({ certificateId, callback }) => ({ certificateId, callback }));

export const setCourses = createAction(SET_COURSES, (courses) => ({
  courses,
}));
export const setCourseNew = createAction(SET_COURSE_NEW, (course) => ({
  course,
}));
export const setUsersOfACertificate = createAction(SET_USERS_OF_A_CERTIFICATE, (certificate) => ({
  certificate,
}));
export const getCourse = createAction(GET_COURSE, (id) => ({ id }));
export const setCourse = createAction(SET_COURSE, (course) => ({ course }));
export const getCourseClasses = createAction(GET_COURSE_CLASSES, (id) => ({
  id,
}));
export const setCourseClasses = createAction(SET_COURSE_CLASSES, (classes) => ({
  classes,
}));
export const getCourseInstructors = createAction(
  GET_COURSE_INSTRUCTORS,
  (id) => ({ id })
);
export const setCourseInstructors = createAction(
  SET_COURSE_INSTRUCTORS,
  (instructors) => ({ instructors })
);
export const getCourseSponsors = createAction(GET_COURSE_SPONSORS, (id) => ({
  id,
}));
export const setCourseSponsors = createAction(
  SET_COURSE_SPONSORS,
  (sponsors) => ({ sponsors })
);
export const setLoading = createAction(SET_LOADING, (loading) => ({ loading }));
export const claimCourse = createAction(CLAIM_COURSE, (id, pdf, callback) => ({
  id,
  pdf,
  callback,
}));

export const updateActivityStatus = createAction(UPDATE_ACTIVITY_STATUS, (id, slug, callback) => ({
  id,
  slug,
  callback
}))

export const getCourseActivityStatusInfo = createAction(GET_COURSE_ACTIVITY_STATUS_INFO, (courseId) => ({
  courseId,
}))

export const setCourseActivityStatusInfo = createAction(SET_COURSE_ACTIVITY_STATUS_INFO, (courseActivityStatusInfo) => ({ courseActivityStatusInfo }))

export const getCertificateUserBySlug = createAction(GET_CERTIFICATE_USERS_BY_SLUG, (slug) => ({
  slug
}))

export const setCertificateUsersBySlug = createAction(SET_CERTIFICATE_USERS_BY_SLUG, (certificateUsersInfo) => ({
  certificateUsersInfo
}))

export const actions = {
  getAllCourses,
  setAllCourses,
  getCourseNew,
  getCourses,
  setCourses,
  getCourse,
  setCourse,
  getCourseClasses,
  setCourseClasses,
  getCourseInstructors,
  setCourseInstructors,
  getCourseSponsors,
  setCourseSponsors,
  setLoading,
  claimCourse,
  setCourseNew,
  registrationInACertificate,
  setUsersOfACertificate,
  updateActivityStatus,
  getCourseActivityStatusInfo,
  setCourseActivityStatusInfo,
  getCertificateUserBySlug,
  setCertificateUsersBySlug
};
