import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Upload } from "antd";
import ImgCrop from "antd-img-crop";

import { CustomButton } from "components";

import "./style.scss";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const ImageUpload3 = ({ value, aspect, onChange, type, maxCount, multiple }) => {
  const [fileList, setFileList] = useState([]);
  const [src, setSrc] = useState("");
  const [src2, setSrc2] = useState([])
  const [fileObject, setFileObject] = useState({})
  const clock = useRef(null);

  const funcion = async () => {
    return await Promise.all(fileList.map(async (item, index) => {
      let Base64Data = await getBase64(item.originFileObj);
      return Base64Data;
    }))
  };

  const imageUpload = async ({ file, onSuccess }) => {
    if (maxCount > 1) {
      const base64FormatFile = file.name;//await getBase64(file);
      let dataS = src2
      let dataS2 = fileObject
      if (dataS.length < maxCount) {
        dataS.push(base64FormatFile)
        setSrc2(dataS)
        if (clock.current) {
          clearTimeout(clock.current);
        }
        clock.current = setTimeout(async () => {
          let vari = await funcion()
          onChange(vari)
        }, 500);
        dataS2[file.uid] = dataS.length - 1
        setFileObject(dataS2)
        onSuccess("ok");
      }
    } else {
      const base64FormatFile = await getBase64(file);
      onChange(base64FormatFile);
      setSrc(base64FormatFile);
      onSuccess("ok");
    }
  };

  const handleChange = ({ fileList }) => {
    setFileList(fileList);
  };

  const removeFile = (data) => {
    let dataS = src2
    let newDataS = dataS.filter((_, index) =>
      fileObject[data.uid] !== index
    )

    setSrc2(newDataS)
    onChange(newDataS)
  };

  useEffect(() => {
    setSrc(value);
    return () => { };
  }, [value]);

  return (
    <div className="image-upload-3-container">
      {type !== 'all' ? (<ImgCrop rotate aspect={aspect}>
        <Upload
          customRequest={imageUpload}
          listType="image-upload-control"
          fileList={fileList}
          maxCount={1}
          onChange={handleChange}
          onRemove={removeFile}
        >
          <CustomButton text="Upload image" size="sm" type="primary outlined" />
        </Upload>
      </ImgCrop>) : (
        <Upload
          customRequest={imageUpload}
          listType="picture-card"
          fileList={fileList}
          maxCount={maxCount}
          onChange={handleChange}
          onRemove={removeFile}
          multiple={multiple}
        >
          <CustomButton text="Upload image" size="sm" type="primary outlined" style={{
            width: '100%',
            height: '100%',
            padding: '0px'
          }} />
        </Upload>
      )}

      <>{src && <img alt="upload-3" src={src} />}</>
    </div>
  );
};

ImageUpload3.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  aspect: PropTypes.number,
  onChange: PropTypes.func,
  previewSrc: PropTypes.bool,
  type: PropTypes.string,
  maxCount: PropTypes.number
};

ImageUpload3.defaultProps = {
  className: "",
  value: "",
  type: "",
  aspect: 1 / 1,
  onChange: () => { },
  previewSrc: true,
  maxCount: 1
};

export default ImageUpload3;
