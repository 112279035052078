import React, { 
  useEffect,
  // useEffect, 
  useRef, 
  useState } from "react";
import "../style.scss";
import { connect } from "react-redux";
import { useMutation, 
  // useQuery, 
  useQueryClient } from "@tanstack/react-query";
import {
  getImagesCarg,
} from "redux/actions/blog-post-action";
// import moment from "moment";
import { CustomInput, InputBlog } from "../../../components";
// import ChatMessage from "../ChatMessage";
import { notification, 
  // Skeleton
} from "antd";
import { SendOutlined } from "@ant-design/icons";
import { useChatMessageStore } from "../store";
// import { httpClientCron } from "../../../api/module/httpClientCron";
import ChatMessages from "../ChatMessages";

const ChatMessageWindow = ({
  img,
  name,
  id,
  getImagesCarg,
  isTyping,
  userProfile,
  chatSocket,
  UserId,
  blogId,
}) => {
  const [open, setOpen] = useState(true);
  const [funcBodyInput, setFuncBodyInput] = useState({})
  const chatMessageStore = useChatMessageStore();
  const timerSend = useRef(null)
  const typingTimer = useRef(null);
  const refScroll = useRef(<div></div>)

  const queryClient = useQueryClient();

  const sendMessageMutation = useMutation({
    mutationFn: async (payload) => {
      chatSocket.emit("private chat", payload, async (err, data) => {

        if (err) {
          notification.error({
            message: "Something went wrong",
            position: "top-right",
          });
        } else {
          if(typeof funcBodyInput?.reset === 'function'){
            funcBodyInput.reset()
          }
        }
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["CHAT_MESSAGE_WINDOW", {id,blogId}] });
      if(refScroll.current){
        refScroll.current.scroll(0,0)
      }
    },
  });

  function onClickSend() {
    if(timerSend.current){clearTimeout(timerSend.current)}
    timerSend.current = setTimeout(() => {
      let response = funcBodyInput.data()
  
      getImagesCarg(response.images, (e) => {
        const responseHTML = funcBodyInput.functionReplaceImage(e)
        let newDiv = document.createElement('div')
        newDiv.innerHTML = responseHTML;
        if (!responseHTML || newDiv.textContent.trim() === '') return;

        const payload = {
          senderId: userProfile.role === 'admin' 
            ? (process.env.REACT_APP_DOMAIN_URL === 'http://localhost:3001' ? 320 : 205) 
            : userProfile.id,
          receiverId: userProfile.role === 'admin' 
            ? UserId
            : (process.env.REACT_APP_DOMAIN_URL === 'http://localhost:3001' ? 320 : 205),
          content: responseHTML,
          blogId: blogId,
        };
    
        sendMessageMutation.mutate(payload);
      })      
    }, 100);
  }

  const handleInputOnChange = () => {

    chatSocket.emit("typing", { senderId: userProfile.id, receiverId: id });

    if(typingTimer.current){clearTimeout(typingTimer.current)};
    typingTimer.current = setTimeout(() => {
      chatSocket.emit("stop typing", { senderId: userProfile.id, receiverId: id });
    }, 100); // Adjust the delay as needed
  };

  useEffect(() => {
    if(refScroll.current){
      refScroll.current.scroll(0,0)
    }
  },[])

  return (
    <div
      className={"chat-message-modal-open"}
    >
      <div
        className={"chat-message-header-container"}
        onClick={() => setOpen(prev => !prev)}
      >
        <div className="chat-message-modal-header">
          <div style={{ position: "relative", display: "inline-block", height: "100%" }}>
            <img src={img} alt={name} style={{ borderRadius: "9999px", width: "2rem", height: "2rem" }} />
            {chatMessageStore.onlineUsers.some(user => user.userId === id) && (
              <div
                style={{
                  position: "absolute",
                  bottom: "0",
                  right: "0",
                  width: "0.8rem",
                  height: "0.8rem",
                  backgroundColor: "green",
                  borderRadius: "50%",
                  border: "2px solid white", // Add a white border for better visibility
                }}
              />
            )}
          </div>
          <h4
            className={(isTyping && !open) ? "loading" : "none"}>{`${name} ${(isTyping && !open) ? "is typing" : ""}`}</h4>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "80vh",
          padding: "5px 10px",
        }}
        ref={refScroll}
      >
        <ChatMessages 
          id={id} 
          senderName={name} 
          senderImg={img} 
          isTyping={isTyping} 
          blogId={blogId} 
          userProfile={userProfile} 
        />
        <div
          style={{
            padding: "5px 10px",
            marginTop: "auto",
            marginBottom: "10px",
            display:'flex',
            flexDirection:'row'
          }}
        >
          <div style={{width:'calc(100% - 50px)',height:'auto'}}>
            <InputBlog 
              functionData={(e) => setFuncBodyInput(e)}
              onChange={() => {handleInputOnChange()}}
              email={userProfile?.email}
            />
          </div>
          <div 
            onClick={() => onClickSend()}
            style={{width:'50px',height:'auto',padding:'5px',display:'flex',justifyContent:'center',alignItems:'center',background:'white',border:'#fe552184 1px solid',borderRadius:'10px'}}>
            <SendOutlined style={{fontSize:'25px',color:'#fe5621'}}/>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
  getImagesCarg,
};
  
export default connect(mapStateToProps,mapDispatchToProps)(ChatMessageWindow);