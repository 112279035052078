import React from "react";

const IconHeadsetOutline = ({color = 'black', width = '24px', height = '24px'}) => {
  return (
    <div style={{width:width,height:height}}>
        <svg viewBox="0 0 509 486" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M152.607 462.5C117.607 447.667 41.8065 396.5 18.6065 310.5C-10.3935 203 32.1065 116 68.6065 76.5C97.8065 44.9 134.106 23.6667 148.606 17M148.606 17L140.606 63M148.606 17L102.606 9M362.606 17C399.273 35.6667 476.606 93.4 492.606 175C512.606 277 489.106 360.5 433.606 409C389.206 447.8 367.773 460.833 362.606 462.5M362.606 462.5L373.606 417.5M362.606 462.5L410.106 477M376.606 190C378.606 179.5 379.206 165.8 377.606 157M366.606 111C354.773 87.5 316.406 40.5 257.606 40.5C184.106 40.5 156.106 78.5 140.606 111C130.114 133 112.606 199.5 163.606 250.5C172.606 258.667 190.906 284.4 192.106 322M192.106 322H314.606M192.106 322C187.773 320.833 179.106 328.5 179.106 340C179.106 357.2 194.773 361.833 202.606 362M314.606 322C320.273 303.667 334.706 263.7 347.106 250.5C359.506 237.3 362.606 230.333 362.606 228.5M314.606 322C320.106 322.833 331.106 327.6 331.106 340C331.106 355.5 320.606 362 309.106 362M254.106 322V182M254.106 182C247.273 181 233.606 175.5 233.606 161.5C233.606 144 246.106 141 254.106 141C262.106 141 274.606 143 274.606 161.5C274.606 176.3 260.94 181.333 254.106 182ZM202.606 213.5C212.44 215.333 230.606 239.6 224.606 322M309.106 213.5C300.106 210.833 283.006 228.8 286.606 322M309.106 362H202.606M309.106 362C316.44 363.5 331.106 370.6 331.106 387C331.106 403.4 316.44 408.5 309.106 409H202.606C194.773 409 179.106 404.3 179.106 385.5C179.106 366.7 194.773 362 202.606 362" stroke={color} stroke-width="18" stroke-linecap="round"/>
        </svg>
    </div>
  );
};

export default IconHeadsetOutline;
