
import {
  Collapse, Modal, Switch
  // Row, Col
} from "antd";


import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation, Redirect } from "react-router-dom";

import { connect } from "react-redux";
import clsx from "clsx";
import moment from 'moment'

import { CloseCircleFilled } from "@ant-design/icons";
import ImgCertificateStamp from "images/img-certificate-stamp.png";
import ImgHHRLogo from "images/img-certificate-logo.png";
import ImgSignature from "images/img-signature.png";

import {
  orderLibrariesAction,
  changueStatusEventLibrary
} from "redux/actions/library-actions";

import {
  CustomButton,
  Tabs,
  LibraryFilterPanel,
  InputBlog,
  // CustomInput
} from "components";
import Emitter from "services/emitter";
import { EVENT_TYPES, INTERNAL_LINKS } from "enum";
import IconLogo from "images/logo-sidebar.svg";
import html2canvas from "html2canvas";
import { convertToLocalTime } from "utils/format";

import { myLearningSelector } from "redux/selectors/myLearningSelector";
import { certificateSelector } from "redux/selectors/certificateSelector"
import { homeSelector } from "redux/selectors/homeSelector";
import BoxItered from "./BoxItered";
import {
  getEventVerification,
  addEventVerification,
} from "redux/actions/event-verfication-code"
import { speakerAllPanelSpeakerSelector } from "redux/selectors/speakerSelector";
import { getAllEventAchivements } from "redux/actions/event-actions";
// import { eventConfirmCodeSelector } from "redux/selectors/eventConfirmCodeSelector"
import { eventSelector } from "redux/selectors/eventSelector";
import { actions as myLearningActions } from "redux/actions/myLearning-actions";
import {
  getIdBoxesGridEndPoint
} from "../../api";
import { actions as conferenceActions } from "redux/actions/conference-actions";

import {
  createControlCertificate,
  getAllCertificateType,
  getCodeCertificate
} from "redux/actions/certificate-ations";

import {
  getChallenge,
} from "redux/actions/challenge-actions";

import { challengeSelector } from "redux/selectors/challengeSelector";
import { actions as councilEventActions } from "redux/actions/council-events-actions";

// import LearningFilterDrawer from "./LearningFilterDrawer";

import ObjectCheckbox from "./objectCheckbox";
import { envSelector } from "redux/selectors/envSelector";
// import EventCertificate from "../EventCertificate";
import DisplaySavedItems from "./displaySavedItems"
import EventVideo from "./EventVideo";
import "./style.scss";


const { Panel } = Collapse;

const MyLearingPage = ({
  loading,
  // getEventVerification,
  // eventVerificationCode,
  // addEventVerification,
  searchConferenceLibraries,
  getAllEventVideos,
  allEventVideos,
  userProfile,
  createControlCertificate,
  isMobile,
  orderLibrariesAction,
  getAllCertificateType,
  certificateLearning,
  getCodeCertificate,
  changueStatusEventLibrary,
  getChallenge,
  challenges,
  getAllEventAchivements,
  allEventsAchivements,
  getCouncilEvents,

}) => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const history = useHistory();

  const [currentTab, setCurrentTab] = useState(query.get("tab") || "0");
  const [listOfYears, setListOfYears] = useState([2020]);

  const [allCouncilEventsCached,setAllCouncilEventsCached] = useState({})
  const [bulPopUpDownloadEventCertificate, setBulPopUpDownloadEventCertificate] = useState(false)
  const [dataEvent, setDataEvent] = useState({})
  const [durationEvent, setDurationEvent] = useState(0)

  const [loading2, setLoading2] = useState(false);
  const [allEventFilter, setAllEventFilter] = useState(allEventVideos)
  const certificateRef = useRef(null);
  const [checked, setChecked] = useState(false)
  const [bulReset, setBulReset] = useState(false)
  const [bulOpenButton, setBulOpenButton] = useState(true)
  const [bulOpenFirePopUp, setBulOpenFirePopUp] = useState(false)
  const [urlRedirect, setUrlRedirect] = useState('')
  const [dataEventFire, setDataEventFire] = useState({})
  const [boxesCards, setBoxesCards] = useState({})
  const [valueFilter, setValueFilter] = useState('')
  const [showFilter,setShowFilters] = useState(false)
  const [topiscFilter,setTopicsFilter] = useState({})

  const idEvent = query?.get("id");

  let checkedObject = {}

  console.log(allEventVideos)

  useEffect(() => {
    getCouncilEvents((data) => {
      setAllCouncilEventsCached(data.reduce((content, data) => {
        content[data?.relationEventAdmin] = {...data,CouncilEventPanels: (data?.CouncilEventPanels || []).reduce((content2, data2) => {
          content2[data2?.id] = data2
          return content2
        },{})};
        return content;
      }, {}))
    },'cached'); 
  }, [getCouncilEvents]);

  // useEffect(() => {
  //   getEventVerification()
  // }, [getEventVerification])

  useEffect(() => {
    setAllEventFilter(allEventVideos)
  },[allEventVideos])

  useEffect(() => {
    getChallenge()
  }, [getChallenge])

  useEffect(() => {
    getAllEventVideos({});
    getAllCertificateType('all')
    getAllEventAchivements()
    if (isMobile) { setBulOpenButton(false) }

    const getListOfYears = (startYear) => {
      const currentYear = new Date().getFullYear();
      const years = [];

      while (startYear <= currentYear) {
        years.push(startYear++);
      }

      return years;
    };

    const listOfYears = getListOfYears([2020]);
    searchConferenceLibraries({}, listOfYears);
    setListOfYears(listOfYears.reverse());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!bulPopUpDownloadEventCertificate) {
    } else {
      let duration = 0

      dataEvent.startAndEndTimes.forEach((date) => {
        duration = (date !== null)
          ? duration + moment(date?.endTime, 'YYYY-MM-DDTHH:mm:ssZ').diff(date?.startTime, 'hours')
          : duration
      })

      setDurationEvent(duration)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulPopUpDownloadEventCertificate]);

  const onDownloadCertificateEvent = async () => {
    if (userProfile?.memberShip === "premium") {
      setLoading2(true);
      const domElement = certificateRef.current;
      const canvas = await html2canvas(domElement, {
        scale: 4,
      });

      let a = document.createElement("a");
      a.href = canvas.toDataURL("image/png");
      a.download = `${dataEvent?.title} - ${userProfile?.firstName} ${userProfile?.lastName} Certificate.png`;
      a.click();
      createControlCertificate({
        type: 'learning',
        idTypeCertificate: dataEvent?.id,
        title: dataEvent.title
      }, () => {
        getAllCertificateType('all')
        getAllEventAchivements()
      })
      setLoading2(false);
      setBulPopUpDownloadEventCertificate(false)
      setBulReset(true)
    } else {
      Emitter.emit(EVENT_TYPES.SHOW_FIREWALL, "learningCertificate");
    }
  };

  const handleTabChange = (tab) => {
    history.replace({
      pathname: window.location.pathname,
      search: `tab=${tab}`,
    });
    setCurrentTab(tab);
  };

  const dataURLtoFile = (dataurl, filename) => {

    if (dataurl !== undefined && dataurl !== null && dataurl !== '') {
      let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    } else {
      return ''
    }

  }

  useEffect(() => {
    let allEventsD = (orderAllEventVideos(allEventVideos) || [])
    let BoxesFix

    (allEventsD || []).map(async (data, index) => {

      const response = await getIdBoxesGridEndPoint({
        idReference: data.id,
        typeReference: 'Certificate'
      });

      if (response.status === 200) {
        BoxesFix = { ...BoxesFix, [`${data.id}`]: response.data.BoxGrid };
        if (allEventsD.length === index + 1) { setBoxesCards(BoxesFix) };
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allEventVideos])

  const imageRectificate = (image, type) => {
    let fileBlobTicket = new Blob([dataURLtoFile(image, moment().format())], { type: type })

    return URL.createObjectURL(fileBlobTicket)
  }

  const functionFixBoxes = (datas) => {
    let newBoxes = (datas || []).map((data) => {

      let rowSelect = 1
      let rowSelect2 = 1
      let columnSelect = 1
      let columnSelect2 = 1

      if (data.TopBox !== '') {
        rowSelect = (data.TopBox.match(/\d{1,2}\//)[0].match(/\d{1,2}/)[0])
        rowSelect2 = (data.TopBox.match(/\/\d{1,2}/)[0].match(/\d{1,2}/)[0])
      }
      if (data.LeftBox !== '') {
        columnSelect = (data.LeftBox.match(/\d{1,2}\//)[0].match(/\d{1,2}/)[0])
        columnSelect2 = (data.LeftBox.match(/\/\d{1,2}/)[0].match(/\d{1,2}/)[0])
      }

      return {
        showColor: 'false',
        bulShowBox: data.bulShowBox,
        boldTextBox: data.boldTextBox,
        fontSizeBox: data.fontSizeBox,
        lineHeightBox: data.lineHeightBox,
        lineTextBox: data.lineTextBox,
        colorTextBox: data.colorTextBox,
        rowSelect: rowSelect,
        columnSelect: columnSelect,
        rowSelect2: rowSelect2,
        columnSelect2: columnSelect2,
        textName: data.textName,
        LeftBox: data.LeftBox,
        TopBox: data.TopBox,
      }
    })

    return newBoxes
  
  }

  const changePosition = (e, index, id) => {
    if (e.target.className === 'iconTop') {

      orderLibrariesAction({
        position: {
          change: Number(e.target.parentElement.parentElement.parentElement.children[index - 1].children[0].id),
          id: Number(id)
        },
        position2: {
          change: Number(e.target.parentElement.id),
          id: Number(e.target.parentElement.parentElement.parentElement.children[index - 1].id)
        }
      }, () => {
        getAllEventVideos({});
      })

    }
    if (e.target.className === 'iconBottom') {

      orderLibrariesAction({
        position: {
          change: Number(e.target.parentElement.parentElement.parentElement.children[index + 1].children[0].id),
          id: Number(id)
        },
        position2: {
          change: Number(e.target.parentElement.id),
          id: Number(e.target.parentElement.parentElement.parentElement.children[index + 1].id)
        }
      }, () => {
        getAllEventVideos({});
      })

    }

  }

  const orderAllEventVideos = (data) => {

    let arrayOrderTime = data.sort((a, b) => {

      let aTime = moment(a?.startDate, "YYYYMMDDHHmm").format("YYYYMMDDHHmm")
      let bTime = moment(b?.startDate, "YYYYMMDDHHmm").format("YYYYMMDDHHmm")

      return Number(bTime) - Number(aTime)

    })

    return arrayOrderTime

  }

  // const bulSituationCodeEvent = (event) => {
  //   return (event.id !== undefined)
  //     ? true
  //     : false
  // }

  useEffect(() => {
    let newData = allEventVideos
    let topics = topiscFilter.topics ? JSON.parse(topiscFilter.topics) : []

    if(valueFilter !== ''){
      const filterText = valueFilter.toLowerCase();
      newData = newData.filter(video =>
        video?.title?.toLowerCase()?.includes(filterText) ||
        video?.description?.toLowerCase()?.includes(filterText) ||
        (video?.Libraries || [])?.some(library =>
          library?.title?.toLowerCase()?.includes(filterText) ||
          library?.description?.toLowerCase()?.includes(filterText) ||
          library?.meta?.toLowerCase()?.includes(filterText) ||
          library?.link?.toLowerCase()?.includes(filterText)
        )
      );
    }

    if(topics.length > 0){
      newData = newData?.filter(video => {
        return (video?.Libraries || [])?.some(library =>{
          return (library?.topics || [])?.some(topicss => {
            return topics?.includes(topicss)
          }) 
        })
      })
    }

    setAllEventFilter(newData)
  },[valueFilter,topiscFilter])

  const orderForDate = (events) => {

    let arrayFixed = [];
    let num = -1;
    let titlesDateReady;

    for (let i = 0; i < events.length; i++) {
      let dateNow = events[i].startDate;
      let timezone = events[i].timeZone;
      if (
        titlesDateReady !== convertToLocalTime(dateNow, timezone).format().substring(0, 4)
      ) {
        titlesDateReady = convertToLocalTime(dateNow, timezone).format().substring(0, 4);
        num++;
        if (!arrayFixed[num]) {
          arrayFixed.push([]);
        }
        arrayFixed[num].push(events[i]);
      } else {
        arrayFixed[num].push(events[i]);
      }
    }

    return arrayFixed
  }

  const displayEventVideos = () => {

    return (<div style={{display:'flex', flexDirection:'row', height:'auto'}}>
      {!isMobile && <LibraryFilterPanel onChange={(e) => setTopicsFilter(e)} onSearch={(e) => setValueFilter(e)} quitButtonShareContent={true} />}
      <div className={"event-videos2"} style={isMobile ? {width:'100%'} : {}}>
        <Collapse style={{padding:'0px'}}>
          {(orderAllEventVideos(orderForDate(allEventFilter)) || []).map((as,indexx) => (
            <Panel header={<div>
              {`${moment(as[0].startDate).format('YYYY')}`}
            </div>} key={indexx} className="panelDataHeight" style={{padding:'0px'}}>
                <div className="container-second-collapse">
                  <Collapse defaultActiveKey={[idEvent]}  style={{padding:'0px'}}>
                    {(as || []).map((event) => {
                      const isEventPremium = event.ticket === "premium";
                      const isUserPremium = userProfile.memberShip === "premium";
                      const eventCouncil = allCouncilEventsCached[event.id]

                      const newEventsOrder = event?.Libraries?.sort((a, b) => {

                        return a?.orderLibraries - b?.orderLibraries

                      })

                      let collapsible = "disabled";

                      if (isEventPremium && isUserPremium) {
                        collapsible = "header";
                      } else if (!isEventPremium) {
                        collapsible = "header";
                      }

                      const header = collapsible === "disabled" ? (
                        <div>
                          {`${event.title} - `}
                          <b>FOR PREMIUM USERS ONLY</b>
                        </div>
                      ) : (
                        <div style={{ display: 'flex', flexDirection: "row" }}>
                          <p style={{ margin: "0px",    padding: '0px', textWrap: 'wrap', width: '240px' }}>{event.title}</p>
                          <div
                            className={bulOpenButton ? "containerButtonLearning" : "containerButtonLearningData"}
                            id="downloadCollapse"
                            onClick={(e) => {
                              if (e.target.className === 'ant-switch-inner' || e.target.className === 'ant-switch-handle' || e.target.className === 'ant-switch' || e.target.className === 'ant-switch ant-switch-checked' || e.target.className === 'shadowDataButton') {
                                e.preventDefault()
                                e.stopPropagation()
                              } else {
                                e.preventDefault()
                                e.stopPropagation()
                                if (userProfile?.memberShip === "premium") {
                                  if (bulOpenButton) {
                                    if (event.bulCodeEvent === "true") {
                                      // if (bulSituationCodeEvent(filterEventVerification(eventVerificationCode, event.id))) {
                                      if (!event?.showPopupMyLearning) {
                                        setBulOpenFirePopUp(true)
                                        setDataEventFire(event)
                                      } else {
                                        setBulPopUpDownloadEventCertificate(true)
                                        setDataEvent(event)
                                      }
                                      // } else {
                                      //   setDataEvent(event)
                                      //   setBulPopupConfirmAttend(true)
                                      // }
                                    } else {
                                      if (!event?.showPopupMyLearning) {
                                        setBulOpenFirePopUp(true)
                                        setDataEventFire(event)
                                      } else {
                                        setBulPopUpDownloadEventCertificate(true)
                                        setDataEvent(event)
                                      }
                                    }
                                  }
                                } else {
                                  Emitter.emit(EVENT_TYPES.SHOW_FIREWALL, "learningCertificate");
                                }
                              }

                            }}
                          >

                            {((userProfile?.email === "morecontrol64@gmail.com" || userProfile?.email === "enrique@hackinghr.io") && !isMobile) &&
                              <div className="continerSwift">
                                <Switch
                                  checkedChildren="On"
                                  unCheckedChildren="Off"
                                  defaultChecked={event?.showPopupMyLearning}
                                  style={{ marginLeft: '5px', marginRight: '5px' }}
                                  onChange={() => {
                                    changueStatusEventLibrary({
                                      id: event?.id,
                                      bul: !event?.showPopupMyLearning
                                    }, () => {
                                      getAllEventVideos({});
                                    })
                                  }}
                                />
                              </div>}
                            <div className="shadowDataButton"></div>
                            <CustomButton
                              type="primary"
                              size="sm"
                              text="Download Event Certificate"
                              className="butonLearningSelectTag"
                              style={bulOpenButton ? { opacity: '100%', transition: 'all 1s ease-in-out', position: "absolute" } : { opacity: '0%', transition: 'all 1s ease-in-out', position: "absolute" }}
                            />
                            <div
                              className="containerArrowMore"
                              onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                setBulOpenButton(!bulOpenButton)
                              }}
                            >
                              <div className="arrowMore">

                              </div>
                            </div>
                          </div>
                        </div>
                      );

                      return (
                        <Panel header={header} key={event.id} collapsible={collapsible} className="panelDataHeight" style={{paddingTop:'0px'}} >
                          {newEventsOrder?.map((library, index) => {
                            return (<>
                              {(index === 0 && !isMobile) && (
                                <div style={{display: 'flex',justifyContent: 'space-between',alignItems: 'baseline',flexWrap: 'nowrap',marginBottom: '1rem',background:'#fafafa',border:'1px solid #f0f0f0',position:'absolute',left:'0px',width:'calc(100% + 32px)',
                                  position:'sticky', top:'16px',zIndex:'10',transform:'translateY(-16px) translateX(-16px)',paddingLeft:'16px',paddingRight:'16px'
                                }}>
                                  <div style={{width:'calc(100% - 430px)',marginRight:'1rem'}}></div>
                                  <div style={{width:'320px', display:'flex',flexDirection:'row'}}>
                                    <p style={{margin:'0px',padding:'0px', width:'80px',textAlign:'center'}}>HR Credits</p>
                                    <p style={{margin:'0px',padding:'0px', width:'50px',textAlign:'center'}}>SHRM</p>
                                    <p style={{margin:'0px',padding:'0px', width:'120px',textAlign:'center'}}>HRCI</p>
                                    <p style={{margin:'0px',padding:'0px', width:'50px',textAlign:'center'}}>IHRIM</p>
                                    <div style={{width:'20px'}}></div>
                                  </div>
                                  <div style={{width:'110px'}}></div>
                                </div>
                              )}
                              <div style={{ width: '100%', height: '100%', position: 'relative' }} id={library.id}>
                                {(userProfile?.email === "morecontrol64" || userProfile?.email === "enrique@hackinghr.io") &&
                                  (<div className="iconPanelDrag" id={library.orderLibraries}>
                                    {(index !== 0) && <div className="iconTop"
                                      onClick={(e) => changePosition(e, index, library.id)}
                                    ></div>}
                                    {(index !== newEventsOrder.length - 1) && <div className="iconBottom"
                                      onClick={(e) => changePosition(e, index, library.id)}
                                    ></div>}
                                  </div>)
                                }
                                <div style={(userProfile?.email === "morecontrol64" || userProfile?.email === "enrique@hackinghr.io") ? {
                                  width: 'calc(100% - 40px)',
                                  marginLeft: '40px'
                                } : {
                                  width: 'calc(100%)'
                                }}>
                                  <EventVideo library={library} key={library.id} eventCouncil={eventCouncil?.CouncilEventPanels?.[library?.idPanelCouncilRelation]}/>
                                </div>
                              </div>
                            </>);
                          })}
                        </Panel>
                      );
                    })}
                  </Collapse>
                </div>
            </Panel>
          ))}
        </Collapse>
     
      </div>
    </div>);
  };

  const redirectToCertificate = (event) => {
    getCodeCertificate({
      idTypeCertificate: event?.id
    }, (urlCode) => {
      if (urlCode) {
        setUrlRedirect(`${INTERNAL_LINKS.VERIFY}/${urlCode}`)
      }
    })
  }

  const filterCertificatesSelects = (data, type) => {

    let arrayIds = certificateLearning?.filter(e => e.type === type)?.map((data) => data.idTypeCertificate)

    let newArray = data.filter((info) => arrayIds?.includes(info.id))

    return newArray

  }

  const displayButtonsCertificates = () => {
    return (
      <div style={{ width: '100%', height: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom:'50px'}}>
        <p
          style={{
            paddingTop: '20px',
            paddingLeft: '10%',
            paddingRight: '10%'
          }}
        >
          In this section you can see the history of Hacking HR learning programs that you have joined and confirmed your participation in. For items to show in here you first need to go to the next tab: Event Videos. Select the event, click on “Download Digital Certificate”, confirm that you attended live and/or watched the recorded videos, and then the event and the digital certificate will show in your “My Achievements” tab.
        </p>
        {(orderAllEventVideos(filterCertificatesSelects(allEventsAchivements,'learning')) || []).map((event,index) => {
          return (
            <CustomButton
            key={index}
              text={event.title}
              size="md"
              type="primary"
              onClick={(e) => {
                if (userProfile?.memberShip === "premium") {
                  redirectToCertificate(event)
                } else {
                  e.preventDefault()
                  e.stopPropagation()
                  Emitter.emit(EVENT_TYPES.SHOW_FIREWALL, "learningCertificate");
                }
              }}
              style={{ marginTop: '1rem', width: '80%', textDecoration: 'none' }}
            />
          )
        })}
        {(orderAllEventVideos(filterCertificatesSelects(challenges,'challenge')) || []).map((event) => {
          return (
            <CustomButton
              text={event?.title}
              size="md"
              type="primary"
              onClick={(e) => {
                redirectToCertificate(event)
              }}
              style={{ marginTop: '1rem', width: '80%', textDecoration: 'none' }}
            />
          )
        })}
        {certificateLearning.filter(e => e?.type === "crashcourse").map((certificate) => {
          return (
            <CustomButton
              text={certificate?.title}
              size="md"
              type="primary"
              onClick={() => {
                setUrlRedirect(`${INTERNAL_LINKS.VERIFY}/${certificate?.code}`)
              }}
              style={{ marginTop: '1rem', width: '80%', textDecoration: 'none' }}
            />
          )
        })}
        {certificateLearning.filter(e => e?.type === "certificatePrograms").map((certificate) => {
          return (
            <CustomButton
              text={certificate?.title}
              size="md"
              type="primary"
              onClick={() => {
                setUrlRedirect(`/certificate-diploma/verify/${certificate?.code}`)
              }}
              style={{ marginTop: '1rem', width: '80%', textDecoration: 'none' }}
            />
          )
        })}
      </div>
    )
  }

  const TabData = [
    {
      title: (<p style={{
        margin:'0px',marginLeft:'5px',marginRight:'5px',padding:'0px', fontSize: '20px'
      }}>My Achievements</p>),
      content: displayButtonsCertificates
    },
    {
      title: (<p style={{
        margin:'0px',marginLeft:'5px',marginRight:'5px',padding:'0px', fontSize: '20px'
      }}>Event Videos</p>),
      content: displayEventVideos,
    },
    // {
    //   title: "Items w/ HR Credits",
    //   content: displayItemsWithHRCredits,
    // },
    {
      title: (<p style={{
        margin:'0px',marginLeft:'5px',marginRight:'5px',padding:'0px', fontSize: '20px'
      }}>Saved Items</p>),
      content: () => (<DisplaySavedItems 
        listOfYears={listOfYears}
        loading={loading}
        currentTab={currentTab}
      />),
    },
    // {
    //   title: "Completed Items",
    //   content: displayCompletedItems,
    // },
  ]

  const groupCheckbox = (data) => {
    if (data.Libraries !== undefined) {

      if (data.Libraries !== undefined) {
        data.Libraries.forEach((data) => {
          checkedObject[data.id] = true
        })
      }

      return data?.Libraries?.map((library, index) => (
        <ObjectCheckbox
          functionState={(data, bul) => {
            checkedObject[data] = bul

            isPosibleConfirm()
          }}
          message={library.title}
          id={library.id}
          key={index}
          bulReset={bulReset}
          functionReset={() => { setBulReset(false) }}
        />
      ))
    } else {
      return (<div></div>)
    }
  }

  const isPosibleConfirm = () => {
    let confirmData = dataEvent.Libraries.filter((data) => {
      return checkedObject[data.id] === true
    })
    if (confirmData.length === 0) {
      setChecked(true)
    } else {
      setChecked(false)
    }
  }

  const functionOrder = (boxesG) => {
    let newBoxes

    if (boxesG.length > 0) {
      newBoxes = ['', '', '', '']
    } else {
      newBoxes = []
    }

    boxesG.map((data) => {
      if (data.textName === '$#duration$#') {
        return newBoxes[0] = data
      }
      if (data.textName === '$#nameevent$#') {
        return newBoxes[1] = data
      }
      if (data.textName === '$#user$#') {
        return newBoxes[2] = data

      }
      if (data.textName === '$#durationHour$#') {
        return newBoxes[3] = data

      }

      return newBoxes.push(data)

    })

    return newBoxes
  }

  return (<>
    {(isMobile && Number(currentTab) === 1) && <div className="container-expand-dataa" onClick={() => setShowFilters(!showFilter)}>
      <div className="filter-icon"></div>
    </div>}
    <div className={(showFilter && Number(currentTab) === 1) ? "filters-blogs" : "filters-blogs-h"}>
      <LibraryFilterPanel onChange={(e) => setTopicsFilter(e)} onSearch={(e) => setValueFilter(e)} quitButtonShareContent={true} />
    </div>
    <div className="my-learnings-page">
      <div className="my-learnings-page-container">
      
          <div className="search-results-container" style={isMobile ? {padding: '0px'} : {padding:'2rem', paddingBottom:'0px'}}>
            <Tabs
              data={TabData}
              className={isMobile ? 'mobile-tab' : ''}
              current={currentTab}
              onChange={handleTabChange}
            />
          </div>
        </div>
      {(urlRedirect !== '') &&
        <Redirect to={urlRedirect} />
      }
      <Modal
        className={clsx("custom-modal")}
        wrapClassName={clsx("custom-modal-wrap")}
        centered
        onCancel={() => {
          setBulPopUpDownloadEventCertificate(false)
          setBulReset(true)
        }}
        visible={bulPopUpDownloadEventCertificate}
        closable={true}
        footer={[]}
        width="500px"
        style={{ top: "100px" }}
        title={
          <div
            className="custom-modal-title"
            style={{
              paddingLeft: '20px',
              paddingRight: '20px',
              display: 'flex',
              justifyContent: "center",
              alignItems: 'start'
            }}
          >
            <p
              style={{
                margin: '0px',
                marginTop: '5px',
                fontWeight: 'bold',
                fontSize: '22px',
                width: '100%',
                textAlign: 'center',
                color: "#3c4752",
              }}
            >Download Event Certificate</p>
            <div className="custom-modal-logo">
              <img src={IconLogo} alt="custom-logo" />
            </div>
          </div>
        }
        closeIcon={<CloseCircleFilled className="custom-modal-close" />}
      >
        <div className="library-claim-modal">
          <p>
            Thank you for attending Hacking HR’s event <strong>{dataEvent?.title}</strong>
          </p>
          <p>
            To download your Digital Certificate of Attendance, please verify that you attended
            LIVE or watched all the sessions below and then click on CONFIRM.
          </p>
          <p>
            When you click CONFIRM an image PNG will download. In addition, we will send you
            the certificate automatically via email and also the certificate ID and unique URL so
            that you can share your badge on LinkedIn or any other platform.
          </p>
          {groupCheckbox(dataEvent)}
          <p>
            Thank you!
          </p>
          <div className="podcast-claim-modal-footer">
            <CustomButton
              disabled={!checked}
              text="Confirm/Download"
              size="md"
              onClick={() => onDownloadCertificateEvent()}
              loading={loading2}
            />
          </div>
        </div>
      </Modal>
      <Modal
        className={clsx("custom-modal")}
        wrapClassName={clsx("custom-modal-wrap")}
        centered
        onCancel={() => {
          setBulOpenFirePopUp(false)
          setDataEventFire({})
        }}
        visible={bulOpenFirePopUp}
        closable={true}
        footer={[]}
        width="500px"
        style={{ top: "100px" }}
        title={
          <div
            className="custom-modal-title"
            style={{
              paddingLeft: '20px',
              paddingRight: '20px',
              display: 'flex',
              justifyContent: "center",
              alignItems: 'start'
            }}
          >
            {/* <p
              style={{
                margin: '0px',
                marginTop: '5px',
                fontWeight: 'bold',
                fontSize: '22px',
                width: '100%',
                textAlign: 'center',
                color: "#3c4752",
              }}
            >The event certificate is not yet ready</p> */}
            <div className="custom-modal-logo">
              <img src={IconLogo} alt="custom-logo" />
            </div>
          </div>
        }
        closeIcon={<CloseCircleFilled className="custom-modal-close" />}
      >
        <div className="library-claim-modal">
          <p style={{ textAlign: 'center', fontSize: '18px' }}>
            The Digital Certificate of Participation is not ready yet for this event. It will be available on {convertToLocalTime(dataEventFire?.endDate, dataEventFire.timezone).add(24, 'hours').format("MM/DD/YYYY")}
          </p>
          <p style={{ textAlign: 'center', fontSize: '18px' }}>
            Thank you!
          </p>
        </div>
      </Modal>
      {(dataEvent.bulCertificateCustomized === 'true') ? (
        <div style={{ position: 'absolute', left: '-1000px', width: '650px', padding: '24px', }}><div
          id="certificate-panel"
          ref={certificateRef}
          style={{
            height: '562px',
            width: `1000px`,
            position: 'relative'
          }}
        >
          <img
            className="challenge-compro-img-aaa"
            src={`${imageRectificate(dataEvent.imageCertificate, dataEvent.ImageCertificateType)}`}
            alt="badge"
          />
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'grid',
              gridTemplateColumns: '2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222%',
              gridTemplateRows: '2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222%',
              position: 'absolute',
              top: '0px'
            }}
            draggable="false"
          >
            {dataEvent.id !== undefined && functionOrder((functionFixBoxes(boxesCards[dataEvent.id]) || [])).map((data) => {

              const textNameFunction = () => {
                if (data.textName === '$#duration$#') {
                  return `${moment(dataEvent.startDate, "YYYY-MM-DDTHH:mm:ss").format("ll")} - ${moment(dataEvent.endDate, "YYYY-MM-DDTHH:mm:ss").format("ll")}`
                } else if (data.textName === '$#nameevent$#') {
                  return dataEvent.title
                } else if (data.textName === '$#user$#') {
                  return userProfile.firstName + ' ' + userProfile.lastName
                } else if (data.textName === '$#durationHour$#') {
                  return `Duration: ${durationEvent} hours`
                } else {
                  return data.textName
                }
              }

              return (
                <BoxItered
                  box={{
                    showColor: 'false',
                    bulShowBox: data.bulShowBox,
                    boldTextBox: data.boldTextBox,
                    fontSizeBox: data.fontSizeBox,
                    lineHeightBox: data.lineHeightBox,
                    lineTextBox: data.lineTextBox,
                    colorTextBox: data.colorTextBox,
                    textName: textNameFunction()
                  }}
                  columnSelect={data.columnSelect}
                  columnSelect2={data.columnSelect2}
                  rowSelect={data.rowSelect}
                  rowSelect2={data.rowSelect2}
                />
              )
            })}
            {Array.from({ length: 45 }, (_, index) => index + 1).map((data) => (
              <>
                {Array.from({ length: 45 }, (_, rowIndex) => rowIndex + 1).map((row) => (
                  <div
                    style={{
                      gridColumn: `${data}/${data}`,
                      gridRow: `${row}/${row}`,
                      border: '0.25px solid transparent',
                      background: 'rgba(217, 217, 217, 0.04)',
                      zIndex: '10'
                    }}
                    id={`${data}`}
                    className={`${row}`}
                  ></div>
                ))}
              </>
            ))}
          </div>
        </div></div>
      ) : (< div style={{ position: 'absolute', left: '0%' }}><div
        className="skill-cohort-certificate-page-wrapperr"
        id="certificate-panel"
        ref={certificateRef}
      >
        <div className="certificate">
          <div className="certificate-top">
            <div className="certificate-logo">
              <img src={ImgHHRLogo} alt="sidebar-logo" />
            </div>
            <h3 className="certificate-title">
              Hacking HR's Certificate of Participation To
            </h3>
            <h1 className="certificate-username">{`${userProfile?.firstName} ${userProfile?.lastName}`}</h1>
          </div>
          <div className="certificate-center">
            <h5 className="certificate-text1 organizer">
              For Successfully Participating in the Hacking HR Event:
            </h5>
            <h3 className="certificate-text2" style={{ textAlign: 'center' }}>{dataEvent.title}</h3>
            <h5 className="certificate-text1 organizer">Duration: {durationEvent} hours</h5>
          </div>
          <div className="certificate-bottom">
            <div className="certificate-bottom-sign">
              <h5 className="certificate-text1 date">{`${moment(
                dataEvent.startDate, "YYYY-MM-DDTHH:mm:ss"
              ).format("ll")} - ${moment(dataEvent.endDate, "YYYY-MM-DDTHH:mm:ss").format("ll")}`}</h5>
              <div className="certificate-divider" />
              <h5 className="certificate-text1">Date</h5>
            </div>
            <div className="certificate-bottom-image">
              <img src={ImgCertificateStamp} alt="certificate-img" />
            </div>
            <div className="certificate-bottom-sign">
              <div className="certificate-signature">
                <img src={ImgSignature} alt="certificate-signature" />
              </div>
              <div className="certificate-divider" />
              <h5 className="certificate-text1 signature">
                Founder at Hacking HR
              </h5>
            </div>
          </div>
        </div>
      </div> </div >)}

    </div >
  </>);
};

const mapStateToProps = (state) => ({
  ...myLearningSelector(state),
  userProfile: homeSelector(state).userProfile,
  isMobile: envSelector(state).isMobile,
  certificateLearning: certificateSelector(state).certificateLearning,
  challenges: challengeSelector(state).challenges,
  allPanelSpeakers: speakerAllPanelSpeakerSelector(state).allPanelSpeakers,
  allPanelSpeakersStatus: speakerAllPanelSpeakerSelector(state).allPanelSpeakersStatus,
  // eventVerificationCode: eventConfirmCodeSelector(state).eventVerificationCode,
  allEvents: eventSelector(state).allEvents,
  allEventsAchivements: eventSelector(state).allEventsAchivements
});

const mapDispatchToProps = {
  ...myLearningActions,
  ...conferenceActions,
  ...councilEventActions,
  createControlCertificate,
  orderLibrariesAction,
  getAllCertificateType,
  getCodeCertificate,
  getChallenge,
  changueStatusEventLibrary,
  getEventVerification,
  addEventVerification,
  getAllEventAchivements,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyLearingPage);