import httpClient from "./httpClient";
import { SETTINGS } from "enum";

export const get = async () => {
    return await httpClient.get(`public/challenge/get`);
};

export const getOne = async (data) => {
    return await httpClient.get(`private/challenge/getone/${data}`);
};

export const send = async (data) => {
    return await httpClient.post(`private/challenge/send`, { ...data });
}

export const joinOrLeave = async (data) => {
    return await httpClient.post(`private/challenge/join`, { challenge: data });
}

export const setStatusChallengeEndPoint = (payload) => {
    return httpClient.post("private/challenge-status/", {
        ...payload,
    });
}

export const getStatusChallengeEndPoint = (payload) => {
    return httpClient.get(`private/challenge-get-status/`)
}

export const getAllCommentsChallenge = async (data) => {
    let newFilter = {
        page: 1,
        num: SETTINGS.MAX_SEARCH_ROW_NUM,
    };

    if (data.filter) {
        newFilter = { ...newFilter, ...data.filter };
    }

    const parsedFilter = Object.keys(newFilter)
        .map((item) => `${item}=${newFilter[item]}`)
        .join("&");

    return await httpClient.get(`private/challengeComment/?${parsedFilter}`);
};

export const postChallenge = async (data) => {
    return await httpClient.post(`private/challengeComment/`, data);
};

export const removeChallengeMessage = async (data) => {
    return await httpClient.delete(`private/challengeComment/${data.id}`);
};
