import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";

import { ModalCompleteYourProfile, CustomButton } from "components";
import NoItemsMessageCard from "components/NoItemsMessageCard";
import IconMentoring from "images/icon-mentoring-new.svg";

import { Avatar } from "antd";

import "./style.scss";

const RequestFromMentors = ({
  requestListMentee,
  updateRequest,
  mentorProfile,
  menteeProfile,
  userProfile,
  getOneMentee,
  getUser,
  markNotificationToRead,
  notifications,
  getNotifications
}) => {
  const [bulModal, setBulModal] = useState(false);
  const [menteeData, setMenteeData] = useState({
    textDescriptionMentor: mentorProfile?.textDescription,
    textDescriptionMentee: menteeProfile?.textDescription,
  });

  useEffect(() => {
    setMenteeData({
      ...menteeData,
      textDescriptionMentor: mentorProfile?.textDescription,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentorProfile, setMenteeData]);

  useEffect(() => {
    setMenteeData({
      ...menteeData,
      textDescriptionMentee: menteeProfile?.textDescription,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menteeProfile, setMenteeData]);

  useEffect(()=>{

    if(userProfile?.id !== undefined && notifications?.length !== undefined && notifications.length > 0){
      const idsNotifications = notifications?.map((notif)=> notif?.id)
            
      markNotificationToRead(idsNotifications, userProfile?.id);

      setTimeout(()=>{
        getNotifications(1,50)
      },500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[userProfile])

  const onCloseModal = () => {
    setBulModal(false);
    setMenteeData({
      textDescriptionMentor: mentorProfile?.textDescription,
      textDescriptionMentee: menteeProfile?.textDescription,
    });
  };

  const filterSituation = (data) => {
    return data?.filter((e) => {
      return (
        e.status.toUpperCase() === "PENDING" &&
        // e.idUser !== menteeProfile.idUser
        e.idUser !== menteeProfile.idUser &&
        e.idOwner !== menteeProfile.idUser
      );
    });
  };

  return (
    <div className="container-request">
      {/* <div
        // className="container-buttom"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <CustomButton
          key="PREVIEW PROFILE"
          text="PREVIEW PROFILE"
          type="primary"
          size="xl"
          className="button-modal"
          style={{ padding: "0px 10px" }}
          onClick={() => {
            setBulModal(true);
          }}
        />
      </div> */}
      <div className="container-list-request">
        {requestListMentee &&
        filterSituation(requestListMentee)?.length !== 0 ? (
          filterSituation(requestListMentee)?.map((data) => {
            return (
              <div className="container-card-user-mentoring">
                <div className="first-part">
                  <div style={{ width: "100px", height: "100px" }}>
                    {data?.img ? (
                      <Avatar size={100} src={data?.img} />
                    ) : (
                      <Avatar size={100} style={{ fontSize: "1rem" }}>
                        {data?.abbrName}
                      </Avatar>
                    )}
                  </div>
                  <div className="container-name">
                    <p style={{ fontSize: "18px" }}>{data.firstName}</p>
                    <p style={{ fontSize: "18px" }}>{data.titleProfessions}</p>
                  </div>
                  <div className="container-buttons">
                    <CustomButton
                      key="ACCEPT"
                      text="ACCEPT"
                      type="primary"
                      size="xl"
                      className="button-modal"
                      style={{
                        padding: "0px 10px",
                        marginLeft: "20px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                      onClick={() => {
                        updateRequest(
                          {
                            id: data.id,
                            status: "ACCEPTED",
                          },
                          () => {
                            getOneMentee();
                          }
                        );
                      }}
                    />
                    <CustomButton
                      key="REJECT"
                      text="REJECT"
                      type="primary"
                      size="xl"
                      className="button-modal"
                      style={{
                        padding: "0px 10px",
                        marginLeft: "20px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                      onClick={() => {
                        updateRequest(
                          {
                            id: data.id,
                            status: "REJECT",
                            dateBloqued: moment()
                              .add(12, "M")
                              .format("YYYYMMDDHHmm"),
                          },
                          () => {
                            getOneMentee();
                          }
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div style={{ marginTop: "25px" }}>
            <NoItemsMessageCard
              icon={IconMentoring}
              message={`There are no new requests.`}
            />
          </div>
        )}
      </div>
      {/* <div className="container-buttom">
                <CustomButton
                    key="PREVIEW PROFILE"
                    text="PREVIEW PROFILE"
                    type="primary"
                    size="xl"
                    className="button-modal"
                    style={{ padding: "0px 10px" }}
                    onClick={() => { setBulModal(true) }}
                />
            </div> */}
      {bulModal === true && (
        <div className="complete-profile" onClick={() => setBulModal(false)}>
          <ModalCompleteYourProfile
            userProfile={userProfile}
            get={getUser}
            edit={false}
            caseMentoring={true}
            mentee={menteeData?.textDescriptionMentee}
            mentor={menteeData?.textDescriptionMentor}
            onClose={() => onCloseModal()}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RequestFromMentors);
