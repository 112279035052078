import React from "react";
// import { connect } from "react-redux";
import { INTERNAL_LINKS } from "enum";
import { Route } from "react-router-dom";
import { PrivateRoute } from "components";
import LiveChat from "pages/LiveChat"

const SwiftRouteLiveChat = () => {

    return (
        <>
            {localStorage.getItem("community") !== null &&
                <PrivateRoute
                    path={`${INTERNAL_LINKS.LIVE_CHAT}`}
                    render={(props) => <LiveChat {...props} />}
                />
            }
            {localStorage.getItem("community") === null &&
                <Route
                    path={`${INTERNAL_LINKS.LIVE_CHAT}`}
                    render={(props) => <LiveChat {...props} />}
                />
            }
        </>
    );
};

// const mapStateToProps = (state) => ({
// });

// const mapDispatchToProps = {
// };

export default SwiftRouteLiveChat;