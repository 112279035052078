import React from "react";
import { Link } from "react-router-dom";
// import { useRouter } from "next/router";

// import { INTERNAL_LINKS } from "enum";

// import IconPlus from "../../../public/img/icon-plus.svg";

import "./style.scss";
// import moment from "moment";
// import { Avatar } from "antd";

const ResourceCard = ({data}) => {
    return (
        <Link href={`#`} className="container-card-resources">
            <div className="container-image">
                {/* <div className="container-date-series">{moment(data?.startDate,"YYYY-MM-DD hh:mm a").format("MMMM")} Series</div> */}
                <img fill={true} objectFit="cover" src={data?.documentFileImageUrl} alt={data?.altText || ''} />
            </div>
            <div className="container-content-card">
                <p className="container-p-img"> <b>{data?.name}</b></p >
                {/* <p>{data?.description}</p> */}
                <div className="resource-card-button" style={{width:'150px'}} >Explore  More</div>
            </div>
        </Link>
    );
};

export default ResourceCard;