import React from 'react';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';

const PieChartComponent = ({ data }) => {
    // Contar las acciones
    const actionCounts = data.reduce((counts, item) => {
        counts[item.action] = (counts[item.action] || 0) + 1;
        return counts;
    }, {});

    // Datos para el gráfico
    const labels = Object.keys(actionCounts);
    const counts = Object.values(actionCounts);

    const chartData = {
        labels: labels,
        datasets: [{
            data: counts,
            backgroundColor: [
                '#1a284290', // Color para "click"
                '#FFD1C2', // Color para "register"
                '#00b5738c',  // Color para "premium"
                '#8d1b8d', // Color para "login"
            ],
            borderColor: [
                '#1a284290', // Color para "click"
                '#FFD1C2', // Color para "register"
                '#00b5738c',  // Color para "premium"
                '#8d1b8d', // Color para "login"
            ],
            borderWidth: 1
        }]
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        let label = context.label || '';
                        let value = context.raw || 0;
                        return `${label}: ${value}`;
                    }
                }
            }
        }
    };

    return (
        <div>
            <Pie data={chartData} options={options} />
        </div>
    );
};

export default PieChartComponent;