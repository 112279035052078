import { put, fork, takeLatest, call } from "redux-saga/effects";
import {
  constants as crashCourseConstants,
  actions as crashCourseActions,
} from "../actions/crash-course-actions";
import {
  constants as crashCourseProgressConstants,
  actions as crashCourseProgressActions,
} from "../actions/crash-course-user-progress-actions";
import { actions as homeActions } from "../actions/home-actions";
import {
  getAllCrashCoursesEndpoint,
  getCrashCourseBySlugEndpoint,
  getCrashCourseSlidesEndpoint,
  createCrashCourseProgressEndpoint,
  getCrashCourseProgressEndpoint,
  updateCrashCourseProgressEndpoint,
} from "api/module/crashCourse";

export function* getAllCrashCoursesSaga({ payload }) {
  yield put(homeActions.setLoading(true));

  try {
      const response = yield call(getAllCrashCoursesEndpoint, payload);

      if (response.status === 200) {
        yield put(crashCourseActions.setAllCrashCourses(response.data.CrashCourses));
        yield put(homeActions.setLoading(false));
      }

  } catch (error) {
    console.log(error);
  } finally {
    yield put(homeActions.setLoading(false));
  }
};

export function* getCrashCourseBySlugSaga({ payload }) {
  yield put(homeActions.setLoading(true));

  try {
    const response = yield call(getCrashCourseBySlugEndpoint, payload);
    if (response.status === 200) {
      yield put(crashCourseActions.setCrashCourse(response.data.crashCourse));
      yield put(homeActions.setLoading(false));
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put(homeActions.setLoading(false));
  }
};

export function* getCrashCourseSlidesSaga({ payload }) {
  yield put(homeActions.setLoading(true));

  try {
    const response = yield call(getCrashCourseSlidesEndpoint, payload);
    if (response.status === 200) {
      if(payload.callback){payload.callback()}
      yield put(crashCourseActions.setCrashCourseSlides(response.data.crashCourseSlides));
      yield put(homeActions.setLoading(false));
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put(homeActions.setLoading(false));
  }
};

export function* createCrashCourseUserProgressSaga({ payload }) {
  yield put(homeActions.setLoading(true));

  try {
    const response = yield call(createCrashCourseProgressEndpoint, payload);
    if (response.status === 200) {
      yield put(crashCourseProgressActions.setCrashCourseUserProgress(response.data.crashCourseProgress));
      yield put(homeActions.setLoading(false));
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put(homeActions.setLoading(false));
  }
};

export function* updateCrashCourseUserProgressSaga({ payload }) {
  // yield put(homeActions.setLoading(true));

  try {

    const response = yield call(updateCrashCourseProgressEndpoint, payload);
    if (response.status === 200) {
      if(response.data.crashCourseProgress){
        yield put(crashCourseProgressActions.setCrashCourseUserProgress(response.data.crashCourseProgress));
      }
    }
  } catch (error) {
    console.log(error);
  } finally {
    // yield put(homeActions.setLoading(false));
  }
};

export function* getCrashCourseUserProgressSaga({ payload }) {
  yield put(homeActions.setLoading(true));

  try {
    const response = yield call(getCrashCourseProgressEndpoint, payload);
    if (response.status === 200) {
      if(payload.callback){payload.callback(response.data.crashCourseProgress)}
      yield put(crashCourseProgressActions.setCrashCourseUserProgress(response.data.crashCourseProgress));
      yield put(homeActions.setLoading(false));
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put(homeActions.setLoading(false));
  }
};

function* watchLogin() {
  yield takeLatest(crashCourseConstants.GET_ALL_CRASH_COURSES, getAllCrashCoursesSaga);
  yield takeLatest(crashCourseConstants.GET_CRASH_COURSE_BY_SLUG, getCrashCourseBySlugSaga);
  yield takeLatest(crashCourseConstants.GET_CRASH_COURSE_SLIDES, getCrashCourseSlidesSaga);
  yield takeLatest(crashCourseProgressConstants.CREATE_CRASH_COURSE_USER_PROGRESS, createCrashCourseUserProgressSaga);
  yield takeLatest(crashCourseProgressConstants.GET_CRASH_COURSE_USER_PROGRESS, getCrashCourseUserProgressSaga);
  yield takeLatest(crashCourseProgressConstants.UPDATE_CRASH_COURSE_USER_PROGRESS, updateCrashCourseUserProgressSaga);
}

export const crashCourseSaga = [fork(watchLogin)];
