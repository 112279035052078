import React, { useState, useEffect } from "react";
import { notification, Tabs as TabsAntd } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import { useParams, useHistory } from "react-router-dom";
import { isEmpty } from "lodash";
// import moment from "moment";
// import TimeZoneList from "enum/TimezoneList";

import ConferenceLibraryFilterDrawer from "./ConferenceLibraryFilterDrawer";
import MemberSpeakers from "./MembersSpeakers";
import { speakerAllPanelSpeakerSelector } from "redux/selectors/speakerSelector";
import {
  AnnualConferenceCard,
  ConferenceCard,
  CustomButton,
  Tabs,
  CollapseComponent
} from "components";
import ConferenceLibraryFilterPanel from "containers/ConferenceLibraryFilterPanel";
import { INTERNAL_LINKS, SETTINGS } from "enum";
import { setLoading } from "redux/actions/home-actions";
import { actions as speaker } from "redux/actions/speaker-actions";
import {
  getAllSessions,
  getSessionsUserJoined,
} from "redux/actions/session-actions";
import {
  getMoreConferenceLibraries,
  searchConferenceLibraries,
} from "redux/actions/conference-actions";
import { categorySelector } from "redux/selectors/categorySelector";
import {
  getAdvertisementsTodayByPage,
  getAdvertisementById,
  createAdvertisementClick,
} from "redux/actions/advertisment-actions";
import { sessionSelector } from "redux/selectors/sessionSelector";
import { homeSelector } from "redux/selectors/homeSelector";
import { advertisementSelector } from "redux/selectors/advertisementsSelector";
import { conferenceSelector } from "redux/selectors/conferenceSelector";

import IconLoadingMore from "images/icon-loading-more.gif";

import "./style.scss";
import Certificate from "pages/GlobalConference/Certificate";

const { TabPane } = TabsAntd;

const ConferenceLibrary = ({
  loading,
  allConferenceLibraries,
  getMoreConferenceLibraries,
  searchConferenceLibraries,
  getAdvertisementsTodayByPage,
  getAdvertisementById,
  advertisementsByPage,
  advertisementById,
  isAdPreview = false,
  getAllSessions,
  getSessionsUserJoined,
  sessionsUserJoined,
  allSessions,
  userProfile,
  getAllPanelSpeakers,
  allPanelSpeakers,
  allCategories,
  getStatusUserSessionLibrary,
  setStatusUserSessionLibrary,
  allPanelSpeakersStatus
}) => {
  const [filters, setFilters] = useState({});
  const [visible, setVisible] = useState(false);
  const [sessionData, setSessionData] = useState([]);
  const [sessionJoinedData, setSessionJoinedData] = useState([]);
  const [session2023Data, setSession2023Data] = useState([])
  const [currentTab, setCurrentTab] = useState("0");
  const [meta, setMeta] = useState("");
  const [listOfYears, setListOfYears] = useState([2020]);
  const [modalVisibleCertificate, setModalVisibleCertificate] = useState(false);
  const [dataCategoriesState, setDataCategoriesState] = useState()
  const [hasAdvertisementData, setHasAdvertisementData] = useState(null);
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (isAdPreview) {
      getAdvertisementById(id);
    } else {
      getAdvertisementsTodayByPage("conference-library");
    }

    getAllSessions({ type: "conference" });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // const searchTimeZone = (timezone) => {
  //   let currentTimezone = TimeZoneList.find((item) => item.value === timezone);

  //   if (currentTimezone) {
  //     currentTimezone = currentTimezone.utc[0];
  //   } else {
  //     currentTimezone = timezone;
  //   }

  //   return currentTimezone
  // }

  useEffect(() => {
    let objectAllCategories = {}

    allCategories.forEach((category) => {
      objectAllCategories[`${category.value}`] = category.title
    })

    setDataCategoriesState(objectAllCategories)
  }, [allCategories, setDataCategoriesState])

  useEffect(() => {
    getAllPanelSpeakers("All")
  }, [getAllPanelSpeakers])

  useEffect(() => {
    if (userProfile?.sessionsJoined?.length > 0) {
      getSessionsUserJoined(userProfile.sessionsJoined);
    }
  }, [getSessionsUserJoined, userProfile]);

  useEffect(() => {
    if (advertisementsByPage !== undefined && advertisementsByPage["conference-library"] !== undefined) {
      if (advertisementsByPage?.["conference-library"][0] !== undefined) {
        setHasAdvertisementData(true);
      } else {
        setHasAdvertisementData(false);
      }
    } else {
      setHasAdvertisementData(false);
    }
  }, [advertisementsByPage]);

  const displayAds = !isEmpty(advertisementsByPage["conference-library"]) && (
    <div className="conference-library-advertisement-wrapper">
      {advertisementsByPage["conference-library"].map((advertisement) => {
        return (
          <div
            className="conference-library-advertisement-wrapper-content"
            key={advertisement.id}
          >
            <div
              className="advertisement"
              onClick={() => {
                createAdvertisementClick(advertisement.id);
                window.open(advertisement.advertisementLink, "_blank");
              }}
            >
              <img
                src={advertisement.adContentLink}
                alt="advertisement"
                className="advertisement-img"
              />
            </div>
          </div>
        );
      })}
    </div>
  );

  const displayPreviewAd = isAdPreview && (
    <div className="conference-library-advertisement-wrapper-preview">
      <div
        className="advertisement"
        onClick={() =>
          window.open(advertisementById.advertisementLink, "_blank")
        }
      >
        <img
          src={advertisementById.adContentLink}
          alt="advertisement"
          className="advertisement-img"
        />
      </div>
    </div>
  );

  const onShowMore = () => {
    getMoreConferenceLibraries(
      {
        ...filters,
        page: allConferenceLibraries?.[+currentTab].currentPage + 1,
        meta,
      },
      [listOfYears[+currentTab]],
      +currentTab
    );
  };

  const onFilterChange = (filters) => {
    searchConferenceLibraries(
      {
        ...filters,
        meta,
      },
      listOfYears
    );
    setFilters(filters);
  };

  const onSearch = (value) => {
    searchConferenceLibraries(
      {
        ...filters,
        meta: value,
      },
      listOfYears
    );
    setMeta(value);
  };

  useEffect(() => {
    const getListOfYears = (startYear) => {
      const currentYear = new Date().getFullYear();
      const years = [];

      while (startYear <= currentYear) {
        years.push(startYear++);
      }

      return years;
    };

    const listOfYears = getListOfYears(2020);
    setListOfYears(listOfYears.reverse());
    searchConferenceLibraries({}, listOfYears);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filters.topics && JSON.parse(filters.topics).length > 0) {
      const topics = JSON.parse(filters.topics);

      // allConferenceLibraries
      const sessionsFiltered = allSessions.filter((session) => {
        let canFiltered = false;
        for (const topic of topics) {
          if (session.categories.some((categorie) => categorie === topic)) {
            canFiltered = true;
            break;
          }
        }

        if (
          !session.title.includes(meta) &&
          !session?.description?.includes(meta) &&
          !session?.recertification_credits.includes(meta) &&
          !session?.speakers?.some(
            (speaker) =>
              speaker?.name?.includes(meta) ||
              speaker?.description?.includes(meta)
          )
        ) {
          canFiltered = false;
        }
        if (canFiltered) {
          return session;
        }

        return null;
      });

      const session2023Filtered = allPanelSpeakers.filter((session) => {
        let canFiltered = false;
        for (const topic of topics) {
          if (session.category.some((categorie) => categorie === topic)) {
            canFiltered = true;
            break;
          }
        }

        if (
          session.panelName.includes(meta) ||
          session?.description?.includes(meta) ||
          session?.recertificactionCredits.includes(meta)
        ) {
          canFiltered = false;
        }
        if (canFiltered) {
          return session;
        }

        return null;
      })

      const sessionsJoinedFiltered = sessionsUserJoined.filter((session) => {
        let canFiltered = false;
        for (const topic of topics) {
          if (session.categories.some((categorie) => categorie === topic)) {
            canFiltered = true;
            break;
          }
        }
        if (
          !session.title.includes(meta) &&
          !session?.description?.includes(meta) &&
          !session?.recertification_credits.includes(meta) &&
          !session?.speakers?.some(
            (speaker) =>
              speaker?.name?.includes(meta) ||
              speaker?.description?.includes(meta)
          )
        ) {
          canFiltered = false;
        }

        if (canFiltered) {
          return session;
        }

        return null;
      });

      setSession2023Data(session2023Filtered)
      setSessionData(sessionsFiltered);
      setSessionJoinedData(sessionsJoinedFiltered);
    } else if (meta && meta !== null) {
      const sessionsFiltered = allSessions.filter((session) => {
        if (
          session.title.includes(meta) ||
          session?.recertification_credits.includes(meta) ||
          session?.description?.includes(meta) ||
          session?.speakers?.some(
            (speaker) =>
              speaker?.name?.includes(meta) ||
              speaker?.description?.includes(meta)
          )
        ) {
          return session;
        }

        return null;
      });

      const session2023Filtered = allPanelSpeakers.filter((session) => {

        console.log(session.panelName.includes(meta))
        if (
          session?.panelName?.includes(meta) ||
          session?.description?.includes(meta) ||
          session?.recertificactionCredits?.includes(meta)
        ) {
          return session;
        }

        return null;
      })

      const sessionsJoinedFiltered = sessionsUserJoined.filter((session) => {
        if (
          session.title.includes(meta) ||
          session?.description?.includes(meta) ||
          session?.speakers?.some(
            (speaker) =>
              speaker?.name?.includes(meta) ||
              speaker?.description?.includes(meta)
          )
        ) {
          return session;
        }

        return null;
      });

      setSession2023Data(session2023Filtered)
      setSessionData(sessionsFiltered);
      setSessionJoinedData(sessionsJoinedFiltered);
    } else {
      setSession2023Data(allPanelSpeakers)
      setSessionData(allSessions);
      setSessionJoinedData(sessionsUserJoined);
    }
  }, [filters, meta, allSessions, sessionsUserJoined, allPanelSpeakers]);

  // useEffect(() => {
  //   setCountOfResults(allConferenceLibraries[+currentTab]?.count || 0);

  //   // eslint-disable-next-line
  // }, [currentTab, allConferenceLibraries]);

  const displayData = (index) => {
    const libraries = allConferenceLibraries[index]?.rows || []
    // case next conference : allConferenceLibraries[index + 1]?.rows || [];

    return libraries.map((item, indx) => {
      let frequency = 0;

      if (item.meta && meta) {
        frequency = [...item.meta.toLowerCase().matchAll(meta)].length;
      }

      return (
        <ConferenceCard
          listOfYearsIndex={index}
          key={indx}
          data={item}
          keyword={meta}
          frequency={frequency}
        />
      );
    });
  };

  const dataIterated = (panels) => (
    <div className="ajust-contain-conference">
      {panels?.SpeakerMemberPanels?.map((user) => {
        return (
          <MemberSpeakers
            key={user?.id}
            usersPanel={user}
          />
        )
      })}
    </div>
  )

  const dataStatic = (panels) => (
    <p className="container-panel-speaker-parraf" style={{ marginBottom: "40px", fontSize: "18px" }}>
      Description: <span className="not-bold">{panels?.description}</span>
    </p>
  )

  const filterOptionPanels = (panel) => {

    let newPanel = panel.filter(e => e.type === 'Panels')

    return newPanel
  }

  const displayData2023 = () => {

    return (filterOptionPanels(allPanelSpeakers) || []).map((item) => {

      return (
        <CollapseComponent
          index={item?.id}
          informationCollapse={content(item)}
          buttons={<div></div>}
          className={"container-panel"}
          dataIterated={dataIterated(item)}
          dataStatic={dataStatic(item)}
          bulArrow={false}
          bulMessage={(item?.type === "Simulations") ? false : true}
        />
      );
    });
  };

  const setOnlyRows = (data) => {

    let newData = []

    data.forEach((datas) => {
      newData = [...newData, ...datas.rows]
    })

    return newData
  }

  useEffect(() => {
    getStatusUserSessionLibrary()
  }, [getStatusUserSessionLibrary])

  const content = (panels) => {

    let categories

    if (dataCategoriesState !== undefined) {
      categories = panels?.category?.map((data, index) => {
        if (panels?.category?.length !== index + 1) {
          return (<span className="date-panels" key={index}> {dataCategoriesState[data]} |</span>)
        } else {
          return (<span className="date-panels" key={index}> {dataCategoriesState[data]}</span>)
        }
      })
    }


    let situation = allPanelSpeakersStatus.filter((data) => data.idSession === panels.id)

    return (
      <div className="content-collapse" key={panels?.id}>
        <p className="title-collapse">{panels?.panelName}</p>
        <div className="content-information">
          <div className="content-first-information">
            <p className="p-content">Session type:
              <span className="date-panels"> {panels?.type}</span>
            </p>
            {(panels.type === "Panels") &&
              <p className="p-content">Panel Topics: {categories}</p>
            }
            {(panels.type === "Simulations") &&
              <p className="p-content">Simulation Topics: {categories}</p>
            }
            {(panels?.hrCreditsBoolean !== undefined && panels?.hrCreditsBoolean !== '')
              &&
              <p className="p-content">Does this session offer HR credits?:  <span className="date-panels">{(panels?.hrCreditsBoolean === 'No')
                ? 'NO'
                : `YES (${panels?.numberOfHRCredits} SHRM + ${panels?.numberOfHRCredits} HRCI | Credit Type: ${panels?.typeHrCredits})`
              }</span></p>}
          </div>
          <div className="content-bottom">
            <CustomButton
              className="claim-credits"
              type="primary"
              size="xs"
              text="Watch"
              style={{ width: '100%', marginBottom: '10px' }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                window.open(`${INTERNAL_LINKS.MICRO_CONFERENCE}/${panels?.id}?type=2023`, "_blank");
              }}
            />
            <CustomButton
              className="mark-viewed"
              type={(situation[0]?.viewed === true) ? "remove" : "secondary"}
              size="xs"
              style={{ width: '100%' }}
              text={(situation[0]?.viewed === true) ? "Viewed" : "Mark As Completed"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setStatusUserSessionLibrary({
                  idSession: panels?.id,
                  viewed: situation[0]?.viewed ? false : true,
                  saveForLater: (situation[0]?.saveForLater !== undefined) ? situation[0]?.saveForLater : false
                }, () => getStatusUserSessionLibrary())
              }}
            />
            {(situation[0]?.viewed !== true) && (
              <CustomButton
                className="save-for-later"
                type={(situation[0]?.saveForLater === true) ? "remove" : "third"}
                size="xs"
                text={(situation[0]?.saveForLater === true) ? "Unsave" : "Save for later"}
                style={{ width: '100%' }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setStatusUserSessionLibrary({
                    idSession: panels?.id,
                    viewed: (situation[0]?.viewed !== undefined) ? situation[0]?.viewed : false,
                    saveForLater: situation[0]?.saveForLater ? false : true
                  }, () => getStatusUserSessionLibrary())
                }}
              />
            )}
          </div>
        </div>
      </div>
    )
  }

  const newLibraries = setOnlyRows(allConferenceLibraries).map((item, indx) => {
    let frequency = 0;

    if (item.meta && meta) {
      frequency = [...item.meta.toLowerCase().matchAll(meta)].length;
    }

    return (
      <ConferenceCard
        key={indx}
        data={item}
        keyword={meta}
        frequency={frequency}
      />
    );
  });

  const onWatch = (id) => {
    history.push(`${INTERNAL_LINKS.MICRO_CONFERENCE}/${id}`);
  };

  const filterEmptyPanelsYears = (data) => {

    // let newData = data?.filter((year) => {
    //   return year !== 2023
    // })

    return data
  }


  const TabData =
    filterEmptyPanelsYears(listOfYears)?.map((year, index) => {
      return {
        title: year,
        content: () => (
          <>
            {year === 2022 ? (
              <>
                <CustomButton
                  size="xs"
                  text="Download Digital Certificate"
                  style={{
                    marginTop: "12px",
                    padding: "0px 46px",
                  }}
                  onClick={() => {
                    if (userProfile?.sessionsJoined?.length < 1) {
                      return notification.info({
                        message:
                          "Available only for those who joined during the week of the conference",
                      });
                    }
                    setModalVisibleCertificate(true);
                  }}
                />
                <div>
                  <TabsAntd
                    defaultActiveKey="1"
                  //  onChange={callback}
                  >
                    <TabPane tab="All Sessions" key="1">
                      <div className="sessions-2022-container">
                        {sessionData.map((session) => (
                          <AnnualConferenceCard
                            key={session.id}
                            session={session}
                            typeConference="conference-library"
                            onWatch={() => onWatch(session.id)}
                          />
                        ))}
                      </div>
                    </TabPane>
                    <TabPane
                      tab="Sessions You Joined During The Conference"
                      key="2"
                    >
                      <div className="sessions-2022-container">
                        {sessionJoinedData.map((session, i) => (
                          <AnnualConferenceCard
                            key={session.id}
                            session={session}
                            typeConference="conference-library"
                            onWatch={() => onWatch(session.id)}
                          />
                        ))}
                      </div>
                    </TabPane>
                  </TabsAntd>
                </div>
              </>
            ) : (year === 2023) ? (
              <div style={{ width: '100%', height: 'auto' }}>{displayData2023()}</div>
            ) : (
              <div className="search-results-list">{displayData(index)}</div>
            )}
          </>
        ),
      };
    }) || [];

  return (
    <div className="conference-library-page">
      {hasAdvertisementData ? <div></div> :
        <ConferenceLibraryFilterPanel
          onChange={onFilterChange}
          onSearch={onSearch}
        />}
      <ConferenceLibraryFilterDrawer
        visible={visible}
        onClose={() => setVisible(false)}
        onChange={onFilterChange}
        onSearch={setMeta}
      />
      <div className="search-results-container">
        <Row>
          <Col span={24}>
            <div className={`search-results-container${hasAdvertisementData ? "-advertsiment-wrapper" : "-mobile-header"}`} onClick={() => setVisible(true)}>
              <h3 className="filters-btn">
                Filters
              </h3>
            </div>
          </Col>
        </Row>
        <div className="conference-library-content">
          {(meta === '') ? (<div className="tabs-container">
            <Tabs
              data={TabData}
              current={currentTab}
              onChange={setCurrentTab}
            />
            {allConferenceLibraries[+currentTab]?.currentPage *
              SETTINGS.MAX_SEARCH_ROW_NUM <
              allConferenceLibraries[+currentTab]?.count && (
                <div className="conference-library-footer d-flex justify-center items-center">
                  {loading && (
                    <div className="conference-library-page-loading-more">
                      <img src={IconLoadingMore} alt="loading-more-img" />
                    </div>
                  )}
                  {!loading && (
                    <CustomButton
                      text="Show more"
                      type="primary outlined"
                      size="lg"
                      onClick={onShowMore}
                    />
                  )}
                </div>
              )}
          </div>) : (<div className="tabs-container">
            <div className="sessions-2022-container-2" style={{ paddingBottom: '10px' }}>
              {(filterOptionPanels(session2023Data) || []).map((item) => {
                return (
                  <CollapseComponent
                    index={item?.id}
                    informationCollapse={content(item)}
                    buttons={<div></div>}
                    className={"container-panel"}
                    dataIterated={dataIterated(item)}
                    dataStatic={dataStatic(item)}
                    bulArrow={false}
                    bulMessage={(item?.type === "Simulations") ? false : true}
                  />
                );
              })}
            </div>
            <div className="sessions-2022-container-2">
              {sessionData.map((session) => (
                <AnnualConferenceCard
                  key={session.id}
                  session={session}
                  typeConference="conference-library"
                  onWatch={() => onWatch(session.id)}
                />
              ))}
            </div>
            <div className="search-results-list">
              {newLibraries}
            </div>
          </div>)}
          {displayAds}
          {displayPreviewAd}
        </div>
      </div>

      <Certificate
        visible={modalVisibleCertificate}
        onCancel={() => setModalVisibleCertificate(false)}
        sessionsUserJoined={sessionsUserJoined}
      />
    </div>
  );
};

ConferenceLibrary.propTypes = {
  title: PropTypes.string,
};

ConferenceLibrary.defaultProps = {
  title: "",
};

const mapStateToProps = (state, props) => ({
  loading: conferenceSelector(state).loading,
  allConferenceLibraries: conferenceSelector(state).allConferenceLibraries,
  allSessions: sessionSelector(state).allSessions,
  sessionsUserJoined: sessionSelector(state).sessionsUserJoined,
  allPanelSpeakers: speakerAllPanelSpeakerSelector(state).allPanelSpeakers,
  allPanelSpeakersStatus: speakerAllPanelSpeakerSelector(state).allPanelSpeakersStatus,
  countOfResults: conferenceSelector(state).countOfResults,
  currentPage: conferenceSelector(state).currentPage,
  userProfile: homeSelector(state).userProfile,
  allCategories: categorySelector(state).categories,
  ...advertisementSelector(state),
});

const mapDispatchToProps = {
  getMoreConferenceLibraries,
  searchConferenceLibraries,
  getAllPanelSpeakers: speaker.getAllPanelSpeakers,
  getStatusUserSessionLibrary: speaker.getStatusUserSessionLibrary,
  setStatusUserSessionLibrary: speaker.setStatusUserSessionLibrary,
  getAdvertisementsTodayByPage,
  getAdvertisementById,
  getSessionsUserJoined,
  getAllSessions,
  setLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConferenceLibrary);
