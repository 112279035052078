import React from "react";

const PopupPremiumUser = ({ onclick, text }) => {
  return (
    <div className="skill-cohort-firewall" onClick={() => onclick()}>
      <div className="upgrade-notification-panel">
        <h3>{text !== undefined ? text : "You must be a premium member."}</h3>
      </div>
    </div>
  );
};

export default PopupPremiumUser;
