import React, { useState } from "react";
import "./style.scss";
import { useChatSocket } from "./socket";
import ChatMessageWindow from "./ChatMessageWindow";
import { useChatMessageStore } from "./store";
import LastChatMessages from "./LastChatMessages";

// todo refactor to only trigger queries when chatbox is opened

export default function ChatBox({ userProfile, slug }) {
  const [open, setOpen] = useState(true);
  const { chatSocket } = useChatSocket({ userProfile });
  const chatMessageStore = useChatMessageStore()

  return (
    <div>
      <div
        className={!open ? "chat-box-container" : "chat-box-container-open"}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div
            className="chat-box-container-header"
            onClick={() => setOpen(prev => !prev)}
          >
            Chat Messages
          </div>
          {open && (
            <LastChatMessages userProfile={userProfile} slug={slug} />
          )}
        </div>
      </div>
      {chatMessageStore.chatMessageWindows.map((messageWindow, index) => {
        return (
          <ChatMessageWindow
            key={`messageWindow-${messageWindow.id}`}
            index={index}
            userProfile={userProfile}
            chatSocket={chatSocket}
            {...messageWindow}
          />
        );
      })}
    </div>
  );
}