import React from "react";
import moment from "moment/moment";
import { useChatMessageStore } from "../store";

export default function GroupChatTabItem({ lastMessage, userProfile }) {
  const chatMessageStore = useChatMessageStore();

  const isTyping = chatMessageStore.groupChatTabItemIds.includes(lastMessage.GroupChat.id);

  return (
    <div
      style={{
        // backgroundColor: !hasSeen ? "#93c5fd" : "unset",
        padding: "0 16px",
        paddingTop: "12px",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "1rem",
          cursor: "pointer",
          borderBottom: "1px solid rgba(219, 219, 219)",
          paddingBottom: "10px",
        }}
        onClick={(event) => {
          event.stopPropagation();

          // if (!hasSeen) {
          //   updateSeenMutation.mutate({ chatMessageId: lastMessage.id });
          // }
          //

          chatMessageStore.setChatMessageWindows({
            img: null,
            name: lastMessage.GroupChat.name,
            id: lastMessage.groupChatId,
            isTyping: false,
            type: "group",
          });
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <span style={{ fontWeight: "bold" }}>{lastMessage.GroupChat.name}</span>
            <span
            >
                      {moment(lastMessage.createdAt).format("MMMM D")}
                    </span>
          </div>
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: "200px",
            }}
          >
            {isTyping ? (
              <span className="loading">Someone is typing</span>
            ) : (
              <>
                {!lastMessage.senderId && (
                  <>{lastMessage.content}</>
                )}
                {lastMessage.senderId === userProfile.id && (
                  <>
                    {`You: ${lastMessage.content}`}
                  </>
                )}
                {(lastMessage.senderId && lastMessage.senderId !== userProfile.id) && (
                  <>
                    {`${lastMessage.sender.firstName}: ${lastMessage.content}`}
                  </>
                )}

              </>
            )}
            </span>
        </div>
      </div>
    </div>
  );
}