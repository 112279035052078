import React from "react";

const IconTvOutline = ({color = 'black', width = '24px', height = '24px'}) => {
  return (
    <div style={{width:width,height:height}}>
        <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Tv</title><rect x='32' y='96' width='448' height='272' rx='32.14' ry='32.14' fill='none' stroke={color} stroke-linejoin='round' stroke-width='32'/><path stroke={color} stroke-linecap='round' stroke-miterlimit='10' stroke-width='32' d='M128 416h256'/></svg>
    </div>
  );
};

export default IconTvOutline;
