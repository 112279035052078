import { createAction } from "redux-actions";

// Action Types
const ADD_EVENT_VERIFICATION = "ADD_EVENT_VERIFICATION";
const GET_EVENT_VERIFICATION = "GET_EVENT_VERIFICATION";
const SET_EVENT_VERIFICATION = "SET_EVENT_VERIFICATION";

export const constants = {
    ADD_EVENT_VERIFICATION,
    GET_EVENT_VERIFICATION,
    SET_EVENT_VERIFICATION,
};

// ------------------------------------
// Actions
// ------------------------------------
export const addEventVerification = createAction(ADD_EVENT_VERIFICATION, (body, callback) => ({ body, callback }));
export const getEventVerification = createAction(GET_EVENT_VERIFICATION, (callback) => ({ callback }));
export const setEventVerification = createAction(SET_EVENT_VERIFICATION, (data) => ({ data }));

export const actions = {
    addEventVerification,
    getEventVerification,
    setEventVerification,
};
