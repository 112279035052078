export default {
  HOME: "/",
  LANDING_PAGUE: "/homee",
  LOGIN: "/login",
  LOGIN_CODE: "/login-code",
  LOGIN_BRIDGE: "/loginbridge",
  SIGNUP_BRIDGE: "/signupbridge",
  CREATORS: "/creators",
  BLOGS_PAGUE: "/blog",
  BLOGS_FOR_USERAPROVED: "/blog-user",
  RESOURCES_FOR_USERAPROVED: "/resources-user",
  RESOURCE: "/resource",
  COUNCIL: "/council",
  PROFILE: "/profile",
  COUNCIL_ADMIN: "/council-event-admin",
  EVENTS_COUNCIL: "/events-council",
  BUSINESS_PARTNER: "/business-partner",
  GROUPGROWS: "/grow-groups",
  SIGNUP: "/signup",
  DASHBOARDS_REFERENCE: '/dashboard-reference',
  PASSWORD_RECOVERY: "/password-recovery",
  RESET_PASSWORD: "/reset-password/:token",
  LEARNING_LIBRARY: "/learning-library",
  LIVE_CHAT: "/livechat",
  CHALLENGE: "/challenge",
  MENTORING: "/mentoring",
  COMMUNITIES: "/communities",
  EVENTS: "/events",
  FOR_HR_VENDORS: "/forhrvendors",
  VERIFY: "/verify",
  CERTIFICATIONS: "/certifications",
  CONFIRM_APPLY_EMAIL: "/user/confirm-apply-business/:id",
  HUB: "/hub",
  FAVORITES: "/favorites",
  READ_LATER: "/read-later",
  ARTICLE: "/article",
  CERTIFICATE: "/certificate",
  CERTIFICATES: "/certificates",
  LEADERSHIP_PROGRAMS: "/crash-course",
  CERTIFICATES_DASHBOARD: "/certificates-dashboard",
  EVENT_CERTIFICATE: "/event-certificate",
  MICRO_CLASS_CERTIFICATE: "/micro-class-certificate",
  JOURNEY: "/journey",
  HEART: "/heart",
  CLASSES: "/classes",
  MICRO_CLASS: "/class",
  PODCAST: "/podcast",
  PODCAST_SERIES: "/podcast-series",
  CHANNELS: "/channels",
  PUBLIC_EVENT: "",
  CHANNEL_PAGE: "",
  JOIN: "/join",
  TERMSOFUSE: "/terms-of-use",
  MARKETPLACE: "/marketplace",
  PUBLIC_MARKETPLACE: "/public-marketplace",
  CONFERENCE_LIBRARY: "/conference-library",
  NOT_FOUND: "/not-found",
  CORPORATE_ADMIN: "/corporate-admin",
  NOTIFICATIONS: "/notifications",
  GLOBAL_CONFERENCE: "/global-conference",
  MICRO_CONFERENCE: "/session",
  GLOBAL_CONFERENCE_SPEAKERS: "/global-conference/speakers",
  GLOBAL_CONFERENCE_PARTICIPANTS: "/global-conference/participants",
  GLOBAL_CONFERENCE_PARTNERS: "/global-conference/partners",
  GLOBAL_CONFERENCE_BONFIRE: "/global-conference/bonfires",
  GLOBAL_CONFERENCE_PERSONAL_AGENDA: "/global-conference/personal-agenda",
  GLOBAL_CONFERENCE_RECOMMENDED_AGENDA: "/global-conference/recommended-agenda",
  GLOBAL_CONFERENCE_LEADERBOARD: "/global-conference/leaderboard",
  SPEAKERS: "/speakers",
  LIVE: "/live",
  POST: "/post",
  LIBRARY_ITEM: "/library-item",
  PARTICIPANTS: "/participants",
  MY_LEARNINGS: "/my-learnings",
  EVENT_VIDEOS: "/my-learnings/event-videos",
  PROJECTX: "/projectx",
  INVITATION: "/invitation",
  SPONSOR_DASHBOARD: "/sponsor-dashboard",
  TALENT_MARKETPLACE: "/talent-marketplace",
  AD_HOME_PREVIEW: "/ad/home/preview",
  AD_PROJECT_X_PREVIEW: "/ad/project-x/preview",
  AD_EVENTS_PREVIEW: "/ad/events/preview",
  AD_CONFERENCE_LIBRARY_PREVIEW: "/ad/conference-library/preview",
  SEARCH: "/search",
  SPEAKER_2023: "/speakers2023",
  CONFERENCE_2023: "/conference2023",
  BLOGS: "/blogs",
  BLOGS_NEW: "/blogs-new",
  BONFIRES: "/bonfires",
  SIMULATION_SPRINTS: "/simulation-sprints",
  CERTIFICATE_DIPLOMA_VERIFY: "/certificate-diploma/verify"
};
