import React, { useEffect, useState, useRef, useCallback } from "react";
import { connect } from "react-redux";
import { Select, Form, Modal, Button, Progress } from "antd";
// import moment from "moment";
import { useParams, useHistory } from "react-router-dom";
import { authSelector } from "redux/selectors/authSelector";
import { getUser } from "redux/actions/home-actions";
import {
    getOneChallenge,
    getChallenge,
    joinOrLeaveChallenge,
    sendInvitation,
    setStatusChallenge,
    getStatusChallenge,
    getAllCommentsChallenge,
} from "redux/actions/challenge-actions";
import html2canvas from "html2canvas";
import clsx from "clsx";
import { homeSelector } from "redux/selectors/homeSelector";
import { challengeSelector } from "redux/selectors/challengeSelector";
import { CustomButton, CardMenu, ModalCompleteYourProfile } from "components";
import { convertToLocalTime } from "utils/format";
import NoItemsMessageCard from "components/NoItemsMessageCard";
import IconMenu from "images/icon-menu.svg";
import MemberSpeakers from "./MembersSpeakers";
import { INTERNAL_LINKS, TABS_CHALLENGES } from "enum";
import TimeZoneList from "enum/TimezoneList";
import moment from "moment";
import PostComment from "components/PostComment";
import PostCommentForm from "containers/PostCommentForm";
import { CloseCircleFilled, SnippetsOutlined, CheckCircleOutlined } from "@ant-design/icons";
import IconLogo from "images/logo-sidebar.svg";
// import ImgCertificateStamp from "images/img-certificate-stamp.png";
// import ImgHHRLogo from "images/img-certificate-logo.png";
// import ImgSignature from "images/img-signature.png";

import {
    createControlCertificate,
} from "redux/actions/certificate-ations";

import "./style.scss";

const Challenge = ({
    userProfile,
    getOneChallenge,
    getChallenge,
    joinOrLeaveChallenge,
    challenge,
    challenges,
    sendInvitation,
    getUser,
    isAuthenticated,
    activitiesStatus,
    setStatusChallenge,
    getStatusChallenge,
    createControlCertificate,
    getAllCommentsChallenge,
    allCommentsChallenge,
}) => {
    const [emailsForms] = Form.useForm();
    const formControlEmail = useRef(null);
    const selectDiv = useRef()
    const certificateRef = useRef(null);
    const firstSelect = useRef()
    const widthTab = useRef()
    const history = useHistory();

    const [tabData, setTabData] = useState(0)
    const [onHover, setOnHover] = useState(false)
    const [openPopUpEmails, setOpenPopUpEmails] = useState(false);
    const [bulKnowRegister, setBulKnowRegister] = useState(false)
    const [valueTestEmail, setValueTestEmail] = useState('')
    const [bulModalCompleteProfile, setBulModalCompleteProfile] = useState(false)
    const [porcentCompletion, setPorcentCompletion] = useState(-1)
    const [tryingJoin, setTriyingJoin] = useState(false)
    const [bulPopUp, setBulPopUp] = useState(false)
    const [bulPopUp2, setBulPopUp2] = useState(false)
    const [loading2, setLoading2] = useState(false);
    const [percent, setPercent] = useState(0)
    const [numSelected, setNumSelected] = useState(0)
    const [modalConfirm, setModalConfirm] = useState(false)

    const { id } = useParams();

    useEffect(() => {
        getStatusChallenge()
        if (challenge?.id !== undefined) {
            getAllCommentsChallenge({ postId: challenge?.id })
        }

    }, [getStatusChallenge, challenge, getAllCommentsChallenge])

    useEffect(() => {
        let numChallenges = challenge?.Activities?.length
        let numConfirm = 0

        for (const l of (challenge?.Activities || [])) {
            let situation = activitiesStatus.filter((data) => data.idActivity === l.id)

            if (situation[0]?.completed) {
                numConfirm++
            }
        }

        setNumSelected(numConfirm)
        setPercent((numConfirm * 100) / numChallenges)
    }, [challenge, activitiesStatus])

    useEffect(() => {
        if (id) {
            setTabData(0)
            if (firstSelect.current !== undefined) {
                selectDiv.current.style.cssText = `left: ${firstSelect.current.offsetLeft}px; width: ${firstSelect.current.clientWidth}px; display: 'block'`
            }
            getOneChallenge(id)
        }
    }, [id, getOneChallenge])

    useEffect(() => {
        getChallenge()
    }, [getChallenge])

    const selectTabs = (e, index) => {
        setTabData(index)
        if (e !== undefined) {
            selectDiv.current.style.cssText = `left: ${e.target.offsetLeft}px; width: ${e.target.clientWidth}px; display: 'block'`
        }
    }

    useEffect(() => {
        if (isAuthenticated !== false) {
            getUser();
        }
    }, [isAuthenticated, getUser]);

    const functionPorcentProfile = useCallback(() => {
        if (userProfile.percentOfCompletion !== undefined) {
            if (userProfile.percentOfCompletion === 100) {
                return true
            } else {
                return false
            }
        } else {
            return
        }
    }, [userProfile])

    const functionActive = useCallback(async () => {
        const bulWait = functionPorcentProfile()

        if (!bulWait && tryingJoin) {
            setBulModalCompleteProfile(true)
            setPorcentCompletion(userProfile.percentOfCompletion)
        }
    }, [functionPorcentProfile, setBulModalCompleteProfile, setPorcentCompletion, userProfile, tryingJoin])

    useEffect(() => {

        const bulWait = functionPorcentProfile()

        if (bulWait) {
            setBulModalCompleteProfile(false)
            if (bulKnowRegister) {
                // getUser();
            }
        }

        if (userProfile.percentOfCompletion !== undefined) {
            if (userProfile.percentOfCompletion !== 100 && userProfile.percentOfCompletion !== 0) {
                if (porcentCompletion !== userProfile.percentOfCompletion) {
                    functionActive()
                }
            }
            if (userProfile.percentOfCompletion === 100 && tryingJoin) {
                joinOrLeaveChallenge(id, () => {
                    getOneChallenge(id)
                })
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        userProfile,
        setBulModalCompleteProfile,
        porcentCompletion,
        setPorcentCompletion,
        functionActive,
        functionPorcentProfile
    ])

    const searchTimeZone = (timezone) => {
        let currentTimezone = TimeZoneList.find((item) => item.value === timezone);

        if (currentTimezone) {
            currentTimezone = currentTimezone.utc[0];
        } else {
            currentTimezone = timezone;
        }

        return currentTimezone
    }

    const bulDate = () => {

        let dateNow = moment(moment.utc(), 'YYYY-MM-DD hh:mm a').tz(searchTimeZone(challenge?.timezone))?.format('YYYYMMDDHHmm')
        let dateChallenge = moment(challenge?.startDate, "YYYY-MM-DD hh:mm a")?.add(24, 'hours')?.format('YYYYMMDDHHmm')

        return (Number(dateNow) > Number(dateChallenge)) ? true : false

    }

    const bulDate2 = (date) => {

        let dateFilter = moment(date, "YYYY-MM-DD hh:mm a")?.format('YYYYMMDDHHmm')

        let dateNow = moment(moment.utc().add(1, 'minute'), 'YYYY-MM-DD hh:mm a').tz(searchTimeZone(challenge?.timezone))?.format('YYYYMMDDHHmm')

        return (Number(dateFilter) < Number(dateNow)) ? true : false
    }

    const bulDate3 = () => {

        let dateFilter = moment(challenge?.endDate, "YYYY-MM-DD hh:mm a")?.format('YYYYMMDDHHmm')

        let dateNow = moment(moment.utc().subtract(24, 'hours'), 'YYYY-MM-DD hh:mm a').tz(searchTimeZone(challenge?.timezone))?.format('YYYYMMDDHHmm')

        return (Number(dateFilter) < Number(dateNow)) ? true : false
    }

    const orderActivitiesDates = (activities) => {
        let arrayOrderTime = activities.sort((a, b) => {

            let aTime = moment(a.date, "YYYYMMDDHHmm").format("YYYYMMDDHHmm")
            let bTime = moment(b.date, "YYYYMMDDHHmm").format("YYYYMMDDHHmm")

            return Number(aTime) - Number(bTime)

        })

        return arrayOrderTime
    }

    const bulBadge = () => {
        const arrayIds = challenge?.Activities?.map(e => e.id)

        return (activitiesStatus?.filter(e => arrayIds?.some(a => Number(a) === Number(e?.idActivity)) && e?.completed === true)?.length >= challenge?.Activities?.length)
    }

    function dataURLtoFile(dataurl, filename) {

        if (dataurl !== undefined && dataurl !== null && dataurl !== '') {
            let arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }

            return new File([u8arr], filename, { type: mime });
        } else {
            return ''
        }

    }

    const downloadBadge = async () => {
        setLoading2(true)
        const domElement = certificateRef.current;
        const canvas = await html2canvas(domElement, {
            scale: 4,
        });

        let a = document.createElement("a");
        a.href = canvas.toDataURL("image/png");
        a.download = `${challenge?.title} - ${userProfile?.firstName} ${userProfile?.lastName} Badge.png`;
        a.click();
        createControlCertificate({
            type: 'challenge',
            idTypeCertificate: challenge?.id,
            title: challenge?.title
        })
        setLoading2(false)
    }

    const afterSaveComment = () => {
        getAllCommentsChallenge({ postId: challenge?.id });
    };

    const blobImageBadgeChallenge = () => {
        let fileBlob = new Blob([dataURLtoFile(challenge?.imageBadge, moment().format())], { type: challenge?.imageBadgeType })
        return URL.createObjectURL(fileBlob)
    }

    const hadleFilterDates = (data) => {

        let newData = data.filter((e) => {
          let dateNow = moment(moment.utc(), 'YYYY-MM-DD hh:mm a').tz(searchTimeZone(e?.timezone))?.format('YYYYMMDDHHmm')
          return (Number(dateNow) < Number(moment(e?.startDate, "YYYY-MM-DD hh:mm a")?.add(24, 'hours')?.format('YYYYMMDDHHmm'))) === true
        })
    
        return newData
      }

    return (
        <>
            <div className="challenge-page">
                <div className="container-image">
                    <img src={challenge?.image2} alt="asddcas-container" className="image-container" />
                    <p className="contaimer-image-title-2" >{challenge?.title}</p>
                </div>
                <div className="container-button-responsive">
                    <CustomButton
                        className="button-challenge font-size-more"
                        text={(challenge?.join) ? ((onHover) ? ((bulDate()) ? "JOINED" : "LEAVE CHALLENGE") : "JOINED") : "JOIN CHALLENGE"}
                        size="sm"
                        style={{ width: '175px', padding: '0px', marginRight: '10px', marginLeft: '10px' }}
                        type={(challenge?.join) ? ((onHover) ? ((bulDate()) ? "secondary" : "third") : "secondary") : "primary"}
                        onMouseEnter={() => {
                            setOnHover(true)
                        }}
                        onMouseLeave={() => {
                            setOnHover(false)
                        }}
                        onClick={() => {
                            if (!bulDate()) {
                                if (challenge?.join) {
                                    setModalConfirm(true)
                                } else {
                                    if (userProfile?.percentOfCompletion !== 100) {
                                        setBulModalCompleteProfile(true)
                                        setTriyingJoin(true)
                                    } else {
                                        joinOrLeaveChallenge(id, () => {
                                            getOneChallenge(id)
                                        })
                                    }
                                }
                            } else {
                                if (!challenge?.join) {
                                    setBulPopUp(true)
                                }
                            }
                        }}
                    />
                    <CustomButton
                        className="button-challenge font-size-more"
                        text="INVITE OTHERS TO JOIN"
                        size="sm"
                        style={{ width: '205px', padding: '0px', marginRight: '10px', marginLeft: '10px' }}
                        type={"primary"}
                        onClick={() => {
                            setOpenPopUpEmails(true)
                        }}
                    />
                    {((bulBadge() && bulDate3()) || (userProfile.email === 'morecontrol64@gmail.com' || userProfile.email === 'enrique@hackinghr.io' || userProfile.email === "barb1971clement@gmail.com")) && <CustomButton
                        className="button-challenge font-size-more"
                        text="MY BADGE"
                        size="sm"
                        style={{ width: '205px', padding: '0px', marginRight: '10px', marginLeft: '10px' }}
                        type={"primary"}
                        loading={loading2}
                        onClick={() => {
                            downloadBadge()
                        }}
                    />}
                </div>
                <div className="container-data">
                    <div className="tabs-distribution">
                        <div className="tabs-channels" ref={widthTab}>
                            <div className="calc-with-tabs">
                                <p
                                    ref={firstSelect}
                                    className={(tabData === 0) ? "select" : ""}
                                    onClick={(e) => { selectTabs(e, 0) }}
                                >Overview</p>
                                <p
                                    className={(tabData === 1) ? "select" : ""}
                                    onClick={(e) => { selectTabs(e, 1) }}
                                >Participants</p>
                                <p
                                    className={(tabData === 2) ? "select" : ""}
                                    onClick={(e) => { selectTabs(e, 2) }}
                                >Other Challenges</p>
                                <p
                                    className={(tabData === 3) ? "select" : ""}
                                    onClick={(e) => { selectTabs(e, 3) }}
                                >Messages</p>
                                <CustomButton
                                    className="button-challenge font-size-more"
                                    text={(challenge?.join) ? ((onHover) ? ((bulDate()) ? "JOINED" : "LEAVE CHALLENGE") : "JOINED") : "JOIN CHALLENGE"}
                                    size="sm"
                                    style={{ width: '175px', padding: '0px', marginRight: '10px', marginLeft: '10px' }}
                                    type={(challenge?.join) ? ((onHover) ? ((bulDate()) ? "secondary" : "third") : "secondary") : "primary"}
                                    onMouseEnter={() => {
                                        setOnHover(true)
                                    }}
                                    onMouseLeave={() => {
                                        setOnHover(false)
                                    }}
                                    onClick={() => {
                                        if (!bulDate()) {
                                            if (challenge?.join) {
                                                setModalConfirm(true)
                                            } else {
                                                if (userProfile?.percentOfCompletion !== 100) {
                                                    setBulModalCompleteProfile(true)
                                                    setTriyingJoin(true)
                                                } else {
                                                    joinOrLeaveChallenge(id, () => {
                                                        getOneChallenge(id)
                                                    })
                                                }
                                            }
                                        } else {
                                            if (!challenge?.join) {
                                                setBulPopUp(true)
                                            }
                                        }
                                    }}
                                />
                                <CustomButton
                                    className="button-challenge font-size-more"
                                    text="INVITE OTHERS TO JOIN"
                                    size="sm"
                                    style={{ width: '205px', padding: '0px', marginRight: '10px', marginLeft: '10px' }}
                                    type={"primary"}
                                    onClick={() => {
                                        setOpenPopUpEmails(true)
                                    }}
                                />
                                {((bulBadge() && bulDate3()) || (userProfile.email === 'morecontrol64@gmail.com' || userProfile.email === 'enrique@hackinghr.io')) && <CustomButton
                                    className="button-challenge font-size-more"
                                    text="MY BADGE"
                                    size="sm"
                                    style={{ width: '205px', padding: '0px', marginRight: '10px', marginLeft: '10px' }}
                                    type={"primary"}
                                    loading={loading2}
                                    onClick={() => {
                                        downloadBadge()
                                    }}
                                />}
                            </div>
                            <div className="box-select" ref={selectDiv} style={{ left: "15px", width: "80px", display: 'block' }}></div>
                            <div
                                className="container-card-menu"
                                style={(widthTab?.current?.clientWidth > widthTab?.current?.children[0]?.clientWidth) ?
                                    { display: 'none' } :
                                    { display: "flex" }
                                }
                            >
                                <CardMenu
                                    menus={TABS_CHALLENGES}
                                    onClick={(value) => {
                                        selectDiv.current.style.cssText = `left: 15px; width: 80px; display: none;`

                                        selectTabs(undefined, value)
                                    }}
                                    container={widthTab.current}
                                >
                                    <div className="library-card-menu-tab">
                                        <img src={IconMenu} alt="icon-menu" />
                                    </div>
                                </CardMenu>
                            </div>
                        </div>
                        {(tabData === 0) && (
                            <>
                                <div className="container-overview">
                                    <p style={{ fontSize: '25px' }}><b style={{ fontSize: '30px' }}>Challenge Dates: </b> {convertToLocalTime(
                                        challenge?.startDate,
                                        challenge?.timezone
                                    )?.format("MM/DD/YYYY")} to {convertToLocalTime(
                                        challenge?.endDate,
                                        challenge?.timezone
                                    )?.format("MM/DD/YYYY")}</p>
                                    <p><b style={{ fontSize: '30px' }}>Description</b></p>
                                    <div dangerouslySetInnerHTML={{ __html: ((challenge?.description || '')[0] === '<') ? challenge?.description : `<p>${challenge?.description}</p>` }} ></div>
                                </div>
                                {((challenge?.Activities?.length > 0) && challenge?.join) && < div className="container-overview" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                    <p><b style={{ fontSize: '30px' }}>Progress</b></p>
                                    <div style={{ position: 'relative', width: '100%', height: 'auto', marginTop: '20px' }}>
                                        {challenge?.Activities?.map((_, index) => <div style={{
                                            background: (numSelected >= (index + 1)) ? '#fe5621' : '#cdd5db',
                                            width: '25px',
                                            height: '25px',
                                            position: 'absolute',
                                            top: '-30px',
                                            borderRadius: '50%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            padding: '2px',
                                            left: `calc(${((index + 1) * 100) / challenge?.Activities?.length}% - 40px)`
                                        }}>
                                            {(numSelected >= (index + 1))
                                                ? <CheckCircleOutlined style={{ color: 'white', fontSize: '18px' }} />
                                                : <SnippetsOutlined style={{ color: 'white', fontSize: '18px' }} />}
                                        </div>)}
                                        <Progress percent={Math.floor(percent)} strokeColor='#fe5621' trailColor="#cdd5db" />
                                    </div>
                                </div>}
                                {(challenge?.Activities?.length > 0) && <div className="container-overview">
                                    <p><b style={{ fontSize: '30px' }}>{(challenge?.Activities?.length > 1) ? 'Activities' : 'Activity'}</b></p>
                                    {orderActivitiesDates(challenge?.Activities)?.map((l, index) => {

                                        let situation = activitiesStatus.filter((data) => data.idActivity === l.id)

                                        let bulButtom = bulDate2(l?.date)

                                        let bulButtom2 = bulDate3()

                                        return (
                                            <>
                                                {bulButtom && <div key={index} style={{ width: '100%', position: 'relative' }}>
                                                    <p style={{ fontSize: '20px', fontWeight: 'bold', marginLeft: '30px', position: 'relative' }}>
                                                        < div style={{
                                                            background: (situation[0]?.completed === true) ? '#fe5621' : '#cdd5db',
                                                            width: '30px',
                                                            height: '30px',
                                                            position: 'absolute',
                                                            top: '2.5px',
                                                            borderRadius: '50%',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            padding: '2px',
                                                            left: '-40px'
                                                        }}>
                                                            {(situation[0]?.completed === true)
                                                                ? <CheckCircleOutlined style={{ color: 'white', fontSize: '22px' }} />
                                                                : <SnippetsOutlined style={{ color: 'white', fontSize: '22px' }} />
                                                            }
                                                        </div>
                                                        {`Activity #${index + 1}`}
                                                    </p>
                                                    <p style={{ fontSize: '16px', marginLeft: '30px' }}><b>Title: </b>{l?.title}
                                                    </p>
                                                    <p style={{ fontSize: '16px', marginLeft: '30px' }}><b>Date: </b>{convertToLocalTime(l?.date, challenge?.timezone).format("MM/DD/YYYY")}
                                                    </p>
                                                    <p style={{ fontSize: '16px', marginLeft: '30px' }}><b>Description: </b> <div dangerouslySetInnerHTML={{ __html: l?.description }} ></div>
                                                    </p>

                                                    {(bulButtom && !bulButtom2) && <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                        <CustomButton
                                                            className="button-challenge"
                                                            text={(situation[0]?.completed === true) ? "Marked As Completed" : "Mark As Completed"}
                                                            size="sm"
                                                            style={{ width: '205px', padding: '0px', marginBottom: '20px' }}
                                                            type={(situation[0]?.completed === true) ? "secondary" : "primary"}
                                                            onClick={(e) => {

                                                                e.preventDefault();
                                                                e.stopPropagation();

                                                                if (challenge?.join) {
                                                                    setStatusChallenge({
                                                                        idActivity: l?.id,
                                                                        completed: situation[0]?.completed ? false : true,
                                                                    }, () => getStatusChallenge())
                                                                } else {
                                                                    setBulPopUp2(true)
                                                                }

                                                            }}
                                                        />
                                                    </div>}
                                                    {(bulButtom2 && (situation[0]?.completed === true)) && <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                        <CustomButton
                                                            className="button-challenge"
                                                            text={"Marked As Completed"}
                                                            size="sm"
                                                            style={{ width: '205px', padding: '0px', marginBottom: '20px' }}
                                                            type={"secondary"}
                                                            onClick={(e) => {

                                                                e.preventDefault();
                                                                e.stopPropagation();

                                                            }}
                                                        />
                                                    </div>}
                                                </div>}
                                            </>
                                        )
                                    })}

                                </div>}
                            </>
                        )}
                        {(tabData === 1) && (
                            <div className="container-users-2">
                                <div className="ajust-contain">
                                    {(challenge?.challengeuser?.length > 0) ? challenge?.challengeuser?.map((user) => {
                                        return (
                                            <MemberSpeakers
                                                key={user?.id}
                                                usersPanel={user}
                                            />
                                        )
                                    }) : (
                                        <NoItemsMessageCard
                                            message={`There are no participants yet for this challenge.`}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                        {(tabData === 2) && (
                            <div className="container-overview">
                                {(hadleFilterDates(challenges)?.filter(e => Number(e.id) !== Number(id)).length > 0) ? challenges?.filter(e => Number(e.id) !== Number(id))?.map(data => (
                                    <div className="container-image-2" onClick={() => {
                                        history.push(`${INTERNAL_LINKS.CHALLENGE}/${data.id}`)
                                    }}>
                                        <img src={data?.image2} alt="asdasdas-container" className="image-container" />
                                        <div style={{ display: 'flex', justifyContent: 'center', position: 'relative', bottom: 'calc(calc(calc(100vw - 108px) * 0.575) * 0.14)', width: '100%', zIndex: '100px', height: '0px' }}>
                                            <p style={{ height: '20px', color: 'black', fontSize: '40px', fontWeight: 'bold' }}>{data?.title}</p>
                                        </div>
                                    </div>
                                )) : (
                                    <NoItemsMessageCard
                                        message={`No other challenges at this time.`}
                                    />
                                )}
                            </div>
                        )}
                        {(tabData === 3) && (
                            <div className="container-overview">
                                <PostCommentForm type="challenge" postId={challenge.id} afterSave={afterSaveComment} postOwnerUserId={-100} />
                                <div className="comments-container">
                                    {allCommentsChallenge.length > 0 ? (
                                        <>
                                            {allCommentsChallenge.map((item) => (
                                                <PostComment
                                                    key={`post-comment-${item.id}`}
                                                    type="challenge"
                                                    data={item}
                                                    postId={challenge.id}
                                                    afterSave={afterSaveComment}
                                                    postOwnerUserId={-100}
                                                />
                                            ))}
                                        </>
                                    ) : (
                                        <div className="empty-message">
                                            Comments not found.
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    <Modal
                        visible={openPopUpEmails}
                        width={500}
                        style={{ top: '90px' }}
                        onCancel={() => setOpenPopUpEmails(false)}
                        footer={[
                            <Button key="back" onClick={() => {
                                setOpenPopUpEmails(false)
                                emailsForms.resetFields();
                            }}>
                                Cancel
                            </Button>,
                            <Button key="submit" type="primary" onClick={() => {
                                emailsForms.submit()
                            }}>
                                Send
                            </Button>,
                        ]}
                        closable={true}
                        closeIcon={<CloseCircleFilled className="custom-modal-close" id="close-email" />}
                    >
                        <Form
                            form={emailsForms}
                            layout="vertical"
                            ref={formControlEmail}
                            onFinish={() => {
                                sendInvitation(valueTestEmail, id, () => {
                                    setOpenPopUpEmails(false)
                                })
                            }}
                        >
                            <Form.Item label="Send Test Message To">
                                <Select
                                    mode="tags"
                                    style={{
                                        width: '100%',
                                    }}
                                    onChange={(e) => { setValueTestEmail(e) }}
                                    tokenSeparators={[',']}
                                    dropdownStyle={{
                                        display: 'none'
                                    }}
                                />
                            </Form.Item>
                            <p><b>Message: </b> {userProfile?.firstName} wants you to check out {challenge?.title}</p>
                        </Form>
                    </Modal>
                    <Modal
                        className={clsx("custom-modal")}
                        wrapClassName={clsx("custom-modal-wrap")}
                        centered
                        onCancel={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            setBulPopUp(false)
                        }}
                        visible={bulPopUp}
                        closable={true}
                        footer={[]}
                        width="500px"
                        style={{ top: "100px" }}
                        title={
                            <div
                                className="custom-modal-title"
                                style={{
                                    paddingLeft: '20px',
                                    paddingRight: '20px',
                                    display: 'flex',
                                    justifyContent: "center",
                                    alignItems: 'start'
                                }}
                            >
                                <div className="custom-modal-logo">
                                    <img src={IconLogo} alt="custom-logo" />
                                </div>
                            </div>
                        }
                        closeIcon={<CloseCircleFilled className="custom-modal-close" />}
                    >
                        <div className="library-claim-modal">
                            <p style={{ textAlign: 'center', fontSize: '18px' }}>
                                Challenge is close.
                            </p>
                        </div>
                    </Modal>
                    <Modal
                        className={clsx("custom-modal")}
                        wrapClassName={clsx("custom-modal-wrap")}
                        centered
                        onCancel={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            setBulPopUp2(false)
                        }}
                        visible={bulPopUp2}
                        closable={true}
                        footer={[]}
                        width="500px"
                        style={{ top: "100px" }}
                        title={
                            <div
                                className="custom-modal-title"
                                style={{
                                    paddingLeft: '20px',
                                    paddingRight: '20px',
                                    display: 'flex',
                                    justifyContent: "center",
                                    alignItems: 'start'
                                }}
                            >
                                <div className="custom-modal-logo">
                                    <img src={IconLogo} alt="custom-logo" />
                                </div>
                            </div>
                        }
                        closeIcon={<CloseCircleFilled className="custom-modal-close" />}
                    >
                        <div className="library-claim-modal">
                            <p style={{ textAlign: 'center', fontSize: '18px' }}>
                                You need to be part of this challenge first.
                            </p>
                        </div>
                    </Modal>
                    <Modal

                        centered
                        onCancel={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            setModalConfirm(false)
                        }}
                        onOk={() => {
                            joinOrLeaveChallenge(id, () => {
                                setModalConfirm(false)
                                getOneChallenge(id)
                            })
                        }}
                        visible={modalConfirm}
                        closable={true}
                        width="500px"
                        style={{ top: "100px" }}
                        title={
                            <div
                                className="custom-modal-title"
                                style={{
                                    paddingLeft: '20px',
                                    paddingRight: '20px',
                                    display: 'flex',
                                    justifyContent: "center",
                                    alignItems: 'start'
                                }}
                            >
                                <div className="custom-modal-logo">
                                    <img src={IconLogo} alt="custom-logo" />
                                </div>
                            </div>
                        }
                        closeIcon={<CloseCircleFilled className="custom-modal-close" />}
                    >
                        <div className="library-claim-modal">
                            <p style={{ textAlign: 'center', fontSize: '18px' }}>
                                Are you sure to leave this challenge.
                            </p>
                        </div>
                    </Modal>
                </div >

            </div >
            {((userProfile?.firstName !== undefined && userProfile?.firstName !== '' && bulModalCompleteProfile)) && <div className="complete-profile">
                <ModalCompleteYourProfile
                    userProfile={userProfile}
                    get={getUser}
                    setBulKnowRegister={setBulKnowRegister}
                    setBulCompleteProfile={setBulModalCompleteProfile}
                />
            </div>}
            <div style={{ position: 'absolute', left: '-100%', width: '650px', padding: '24px', }}>
                <div
                    style={{
                        width: 'auto',
                        height: 'auto',
                        position: 'relative'
                    }}
                    ref={certificateRef}
                >
                    <img
                        className="challenge-compro-img-aaa"
                        src={`${blobImageBadgeChallenge()}`}
                        alt="badge"
                    />
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            display: 'grid',
                            gridTemplateColumns: '2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222%',
                            gridTemplateRows: '2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222%',
                            position: 'absolute',
                            top: '0px'
                        }}
                        draggable="false"
                    >

                        {challenge?.bulShowName && <div
                            style={{
                                gridColumn: `${challenge?.LeftImageBadge !== '' ? challenge?.LeftImageBadge?.match(/\d{1,2}\//)[0]?.match(/\d{1,2}/)[0] : '0/0'}/${challenge?.LeftImageBadge ? challenge?.LeftImageBadge?.match(/\/\d{1,2}/)[0]?.match(/\d{1,2}/)[0] : '0/0'}`,
                                gridRow: `${challenge?.TopImageBadge ? challenge?.TopImageBadge?.match(/\d{1,2}\//)[0]?.match(/\d{1,2}/)[0] : '0/0'}/${challenge?.TopImageBadge ? challenge?.TopImageBadge?.match(/\/\d{1,2}/)[0]?.match(/\d{1,2}/)[0] : '0/0'}`,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                zIndex: '20',
                            }}
                        >
                            <p style={{
                                color: challenge?.colorText,
                                boxSizing: 'content-box',
                                whiteSpace: challenge?.lineText === 'true' ? 'pre' : '',
                                fontWeight: `${challenge?.boldImageBadge === 'true' ? 'bold' : 'normal'}`,
                                padding: challenge?.lineText === 'true' ? '0px' : '45%',
                                margin: '0px',
                                fontSize: `${Number(challenge?.fontSizeImageBadge)}%`,
                                lineHeight: challenge?.lineHeightBadge,
                                textAlign: 'center'
                            }}>{userProfile.firstName} {userProfile.lastName}</p>

                        </div>}
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45].map((data) => {
                            return (
                                <>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `1/1`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='1' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `2/2`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='2' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `3/3`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='3' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `4/4`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='4' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `5/5`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='5' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `6/6`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='6' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `7/7`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='7' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `8/8`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='8' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `9/9`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='9' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `10/10`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='10' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `11/11`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='11' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `12/12`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='12' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `13/13`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='13' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `14/14`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='14' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `15/15`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='15' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `16/16`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='16' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `17/17`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='17' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `18/18`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='18' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `19/19`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='19' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `20/20`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='20' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `21/21`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='21' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `22/22`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='22' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `23/23`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='23' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `24/24`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='24' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `25/25`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='25' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `26/26`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='26' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `27/27`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='27' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `28/28`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='28' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `29/29`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='29' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `30/30`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='30' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `31/31`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='31' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `32/32`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='32' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `33/33`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='33' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `34/34`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='34' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `35/35`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='35' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `36/36`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='36' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `37/37`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='37' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `38/38`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='38' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `39/39`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='39' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `40/40`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='40' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `41/41`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='41' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `42/42`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='42' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `43/43`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='43' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `44/44`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='44' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `45/45`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='45' ></div>
                                </>
                            )
                        })}

                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    userProfile: homeSelector(state).userProfile,
    challenge: challengeSelector(state).challenge,
    challenges: challengeSelector(state).challenges,
    activitiesStatus: challengeSelector(state).activitiesStatus,
    allCommentsChallenge: challengeSelector(state).allCommentsChallenge,
    isAuthenticated: authSelector(state).isAuthenticated,
});

const mapDispatchToProps = {
    getOneChallenge,
    getUser,
    getChallenge,
    joinOrLeaveChallenge,
    sendInvitation,
    setStatusChallenge,
    createControlCertificate,
    getStatusChallenge,
    getAllCommentsChallenge,
};

export default connect(mapStateToProps, mapDispatchToProps)(Challenge);
