import React from 'react';
import { Skeleton } from "antd";
import ChatTabItem from "../LastChatMessages/ChatTabItem";
import { useQuery } from "@tanstack/react-query";
import { httpClientCron } from "../../../api/module/httpClientCron";
import GroupChatTabItem from "./GroupChatTabItem";

export default function GroupChatTab({ userProfile}) {

  const { data: lastMessages, isLoading} = useQuery({
    queryKey: ["GROUP_CHAT_LAST_MESSAGE"],
    queryFn: async () => {
      const { data } = await httpClientCron.get(`private/group-chat-last-message`)

      return data
    }
  })

  return (
    <div
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {isLoading && (
          <div style={{ padding: "12px 16px" }}>
            {new Array(5).fill(null).map(() => <Skeleton avatar paragraph={{ rows: 1 }} />)}
          </div>
        )}
        {(lastMessages || []).map((lastMessage, index) => {
          return (
            <GroupChatTabItem
             lastMessage={lastMessage}
             userProfile={userProfile}
            />
          );
        })}
      </div>
    </div>
  )
}