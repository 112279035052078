import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { ModalCompleteYourProfile } from "components";
import NoItemsMessageCard from "components/NoItemsMessageCard";
import IconMentoring from "images/icon-mentoring-new.svg";

import { Avatar, Button } from "antd";
import { LinkedinOutlined, MailOutlined } from "@ant-design/icons";

import "./style.scss";

const YourCurrentMentors = ({
  requestListMentee,
  mentorProfile,
  menteeProfile,
  userProfile,
  getUser,
}) => {
  const [bulModal, setBulModal] = useState(false);
  const [menteeData, setMenteeData] = useState({
    textDescriptionMentor: mentorProfile?.textDescription,
    textDescriptionMentee: menteeProfile?.textDescription,
  });

  useEffect(() => {
    setMenteeData({
      ...menteeData,
      textDescriptionMentor: mentorProfile?.textDescription,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentorProfile]);

  useEffect(() => {
    setMenteeData({
      ...menteeData,
      textDescriptionMentee: menteeProfile?.textDescription,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menteeProfile]);

  const onCloseModal = () => {
    setBulModal(false);
    setMenteeData({
      textDescriptionMentor: mentorProfile?.textDescription,
      textDescriptionMentee: menteeProfile?.textDescription,
    });
  };

  const filterSituation = (data) => {
    return data?.filter((e) => {
      return (
        e.status.toUpperCase() === "ACCEPTED" &&
        e.idUser !== menteeProfile.idUser
      );
    });
  };

  return (
    <div className="container-current">
      {/* <div
        //   className="container-buttom"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <CustomButton
          key="PREVIEW PROFILE"
          text="PREVIEW PROFILE"
          type="primary"
          size="xl"
          className="button-modal"
          style={{ padding: "0px 10px" }}
          onClick={() => {
            setBulModal(true);
          }}
        />
      </div> */}
      <div className="container-list-request">
        {requestListMentee &&
        filterSituation(requestListMentee)?.length !== 0 ? (
          filterSituation(requestListMentee)?.map((data, index) => {
            return (
              <div key={index} className="container-card-user-mentoring">
                <div className="first-part">
                  <div style={{ width: "100px", height: "100px" }}>
                    {data?.img ? (
                      <Avatar size={100} src={data?.img} />
                    ) : (
                      <Avatar size={100} style={{ fontSize: "1rem" }}>
                        {data?.abbrName}
                      </Avatar>
                    )}
                  </div>
                  <div className="container-name">
                    <p style={{ fontSize: "18px" }}>{data.firstName}</p>
                    <p style={{ fontSize: "18px" }}>{data.titleProfessions}</p>
                  </div>
                  <div className="container-buttons-linkedin-email">
                    <a
                      href={data?.personalLinks?.linkedin || "#"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button
                        shape="circle"
                        type="link"
                        icon={<LinkedinOutlined />}
                        className="icon-linkedin"
                      />
                    </a>
                    <a href={`mailto:${data?.email}`}>
                      <Button
                        shape="circle"
                        type="link"
                        icon={<MailOutlined />}
                        className="icon-email"
                      />
                    </a>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div style={{ marginTop: "25px" }}>
            <NoItemsMessageCard
              icon={IconMentoring}
              message={`There aren't current mentors`}
            />
          </div>
        )}
      </div>
      {/* <div className="container-buttom">
                <CustomButton
                    key="PREVIEW PROFILE"
                    text="PREVIEW PROFILE"
                    type="primary"
                    size="xl"
                    className="button-modal"
                    style={{ padding: "0px 10px" }}
                    onClick={() => { setBulModal(true) }}
                />
            </div> */}
      {bulModal === true && (
        <div className="complete-profile" onClick={() => setBulModal(false)}>
          <ModalCompleteYourProfile
            userProfile={userProfile}
            get={getUser}
            edit={false}
            caseMentoring={true}
            mentee={menteeData?.textDescriptionMentee}
            mentor={menteeData?.textDescriptionMentor}
            onClose={() => onCloseModal()}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(YourCurrentMentors);
