import { handleActions } from "redux-actions";
import { Map } from "immutable";

import { constants as annualConferenceClassConstants } from "../actions/annualConferenceClass-actions";

export const reducers = {
  [annualConferenceClassConstants.SET_ANNUAL_CONFERENCE_CLASSES]: (
    state,
    { payload }
  ) => {
    return state.merge({
      annualConferenceClasses: payload.annualConferenceClasses,
    });
  },
  [annualConferenceClassConstants.SET_ANNUAL_CONFERENCE_CLASS]: (
    state,
    { payload }
  ) => {
    return state.merge({
      annualConferenceClass: payload.annualConferenceClass,
    });
  },
};

export const initialState = () =>
  Map({
    annualConferenceClasses: [],
    annualConferenceClass: {},
  });

export default handleActions(reducers, initialState());
