import React, { useCallback, useEffect, useRef } from "react";
import { connect } from "react-redux";
import html2canvas from "html2canvas";
import moment from "moment";

import ImgCertificateStamp from "images/img-certificate-stamp.png";
import ImgHHRLogo from "images/img-certificate-logo.png";
import ImgSignature from "images/img-signature.png";

import { eventSelector } from "redux/selectors/eventSelector";
import BoxItered from "./BoxItered";
import {
    getIdBoxes,
} from "redux/actions/event-actions";

import "./style.scss";

const CertificateLearning = ({
    userCertificate,
    event,
    father,
    calcDuration,
    functionCargCertificate,
    getIdBoxes,
    boxesSaves,
    setCanvas = () => {},
}) => {
    const certificateRef = useRef(null);

    const uploadImage = useCallback(async (eventD) => {
        const domElement = certificateRef.current;
        functionCargCertificate(certificateRef)
        const canvas = await html2canvas(domElement, {
            scale: 4,
        });
        canvas.style.cssText = `width: 100%; height: auto;`

        setCanvas(canvas)
        // if (father?.current?.children.length === 0) {
        //     father.current.appendChild(canvas);
        // } else {
        //     if(father.current){
        //         father.current.removeChild(father.current?.children[0]);
        //         father.current.appendChild(canvas);  
        //     }
        // }
    }, [father, functionCargCertificate,event])

    useEffect(() => {

        if (userCertificate.firstName !== undefined && event.id !== undefined ) {
            uploadImage(event)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userCertificate, event, boxesSaves])

    const dataURLtoFile = (dataurl, filename) => {

        if (dataurl !== undefined && dataurl !== null && dataurl !== '') {
            let arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }

            return new File([u8arr], filename, { type: mime });
        } else {
            return ''
        }

    }

    useEffect(() => {
        getIdBoxes({
            idReference: event.id,
            typeReference: 'Certificate'
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const imageRectificate = (image, type) => {
        let fileBlobTicket = new Blob([dataURLtoFile(image, moment().format())], { type: type })

        return URL.createObjectURL(fileBlobTicket)

    }

    const functionBoxes = (datas) => {
        if (datas[event.id] !== undefined) {
            let newBoxes = (datas[event.id] || [])?.map((data) => {

                let rowSelect = 1
                let rowSelect2 = 1
                let columnSelect = 1
                let columnSelect2 = 1

                if (data.TopBox !== '') {
                    rowSelect = (data.TopBox.match(/\d{1,2}\//)[0].match(/\d{1,2}/)[0])
                    rowSelect2 = (data.TopBox.match(/\/\d{1,2}/)[0].match(/\d{1,2}/)[0])
                }
                if (data.LeftBox !== '') {
                    columnSelect = (data.LeftBox.match(/\d{1,2}\//)[0].match(/\d{1,2}/)[0])
                    columnSelect2 = (data.LeftBox.match(/\/\d{1,2}/)[0].match(/\d{1,2}/)[0])
                }

                return {
                    showColor: 'false',
                    bulShowBox: data.bulShowBox,
                    boldTextBox: data.boldTextBox,
                    fontSizeBox: data.fontSizeBox,
                    lineHeightBox: data.lineHeightBox,
                    lineTextBox: data.lineTextBox,
                    colorTextBox: data.colorTextBox,
                    rowSelect: rowSelect,
                    columnSelect: columnSelect,
                    rowSelect2: rowSelect2,
                    columnSelect2: columnSelect2,
                    textName: data.textName,
                    LeftBox: data.LeftBox,
                    TopBox: data.TopBox,
                }
            })

            return newBoxes
        } else {
            return []
        }
    }

    return (
        <div style={{position:'absolute',width:'100%',height:'100%',left:'100000px',top:'-1000px'}}>
            {(event.bulCertificateCustomized === 'true') ? (
                <div
                    id="certificate-panel"
                    ref={certificateRef}
                    className="container-ticket-eeee"
                >
                    <img
                        className="challenge-compro-img-aaa"
                        src={`${imageRectificate(event.imageCertificate, event.ImageCertificateType)}`}
                        alt="badge"
                    />
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            display: 'grid',
                            gridTemplateColumns: '2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222%',
                            gridTemplateRows: '2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222%',
                            position: 'absolute',
                            top: '0px'
                        }}
                        draggable="false"
                    >
                        {functionBoxes(boxesSaves).map((data) => {

                            const calcDuration = (data) => {
                                let duration = 0

                                if (data !== undefined) {
                                    data.forEach((date) => {
                                        duration = (date !== null)
                                            ? duration + moment(date?.endTime, 'YYYY-MM-DDTHH:mm:ssZ').diff(date?.startTime, 'hours')
                                            : duration
                                    })
                                }

                                return duration
                            }

                            const textNameFunction = () => {
                                if (data.textName === '$#duration$#') {
                                    return `${moment(event?.startDate, "YYYY-MM-DDTHH:mm:ss").format("ll")} - ${moment(event?.endDate, "YYYY-MM-DDTHH:mm:ss").format("ll")}`
                                } else if (data.textName === '$#nameevent$#') {
                                    return event?.title
                                } else if (data.textName === '$#user$#') {
                                    return userCertificate?.firstName + ' ' + userCertificate?.lastName
                                } else if (data.textName === '$#durationHour$#') {
                                    return `Duration: ${calcDuration(event?.startAndEndTimes)} hours`
                                } else {
                                    return data.textName
                                }
                            }

                            return (
                                <BoxItered
                                    box={{
                                        showColor: 'false',
                                        bulShowBox: data.bulShowBox,
                                        boldTextBox: data.boldTextBox,
                                        fontSizeBox: data.fontSizeBox,
                                        lineHeightBox: data.lineHeightBox,
                                        lineTextBox: data.lineTextBox,
                                        colorTextBox: data.colorTextBox,
                                        textName: textNameFunction()
                                    }}
                                    columnSelect={data.columnSelect}
                                    columnSelect2={data.columnSelect2}
                                    rowSelect={data.rowSelect}
                                    rowSelect2={data.rowSelect2}
                                />
                            )
                        })}
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45].map((data) => {
                            return (
                                <>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `1/1`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='1' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `2/2`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='2' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `3/3`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='3' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `4/4`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='4' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `5/5`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='5' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `6/6`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='6' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `7/7`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='7' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `8/8`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='8' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `9/9`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='9' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `10/10`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='10' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `11/11`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='11' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `12/12`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='12' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `13/13`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='13' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `14/14`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='14' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `15/15`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='15' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `16/16`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='16' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `17/17`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='17' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `18/18`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='18' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `19/19`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='19' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `20/20`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='20' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `21/21`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='21' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `22/22`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='22' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `23/23`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='23' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `24/24`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='24' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `25/25`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='25' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `26/26`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='26' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `27/27`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='27' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `28/28`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='28' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `29/29`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='29' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `30/30`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='30' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `31/31`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='31' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `32/32`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='32' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `33/33`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='33' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `34/34`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='34' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `35/35`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='35' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `36/36`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='36' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `37/37`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='37' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `38/38`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='38' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `39/39`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='39' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `40/40`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='40' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `41/41`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='41' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `42/42`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='42' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `43/43`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='43' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `44/44`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='44' ></div>
                                    <div style={{ gridColumn: `${data}/${data}`, gridRow: `45/45`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '10' }} id={`${data}`} className='45' ></div>
                                </>
                            )
                        })}

                    </div>
                </div>
            ) : (
                <div
                    className="skill-cohort-certificate-page-wrapperr-learning"
                    id="certificate-panel"
                    ref={certificateRef}
                >
                    <div className="certificate">
                        <div className="certificate-top">
                            <div className="certificate-logo">
                                <img src={ImgHHRLogo} alt="sidebar-logo" />
                            </div>
                            <h3 className="certificate-title">
                                Hacking HR's Certificate of Participation To
                            </h3>
                            <h1 className="certificate-username">{`${userCertificate?.firstName} ${userCertificate?.lastName}`}</h1>
                        </div>
                        <div className="certificate-center">
                            <h5 className="certificate-text1 organizer">
                                For Successfully Participating in the Hacking HR Event:
                            </h5>
                            <h3 className="certificate-text2" style={{ textAlign: 'center' }}>{event?.title}</h3>
                            <h5 className="certificate-text1 organizer">Duration: {calcDuration(event?.startAndEndTimes)} hours</h5>
                        </div>
                        <div className="certificate-bottom">
                            <div className="certificate-bottom-sign">
                                <h5 className="certificate-text1 date">{`${moment(
                                    event?.startDate, "YYYY-MM-DDTHH:mm:ss"
                                ).format("ll")} - ${moment(event?.endDate, "YYYY-MM-DDTHH:mm:ss").format("ll")}`}</h5>
                                <div className="certificate-divider" />
                                <h5 className="certificate-text1">Date</h5>
                            </div>
                            <div className="certificate-bottom-image">
                                <img src={ImgCertificateStamp} alt="certificate-img" />
                            </div>
                            <div className="certificate-bottom-sign">
                                <div className="certificate-signature">
                                    <img src={ImgSignature} alt="certificate-signature" />
                                </div>
                                <div className="certificate-divider" />
                                <h5 className="certificate-text1 signature">
                                    Founder at Hacking HR
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            )
            }
        </div>
    );
};

const mapStateToProps = (state) => ({
    boxesSaves: eventSelector(state).boxesSaves
});

const mapDispatchToProps = {
    getIdBoxes
};

export default connect(mapStateToProps, mapDispatchToProps)(CertificateLearning);