import { notification, Tooltip, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import Emitter from "services/emitter";
import { EVENT_TYPES } from "enum";
import { isEmpty } from "lodash";
import {
  StarOutlined,
  BookOutlined,
  BookFilled,
  SaveOutlined,
  SaveFilled,
  CaretRightOutlined,
} from "@ant-design/icons";
import { envSelector } from "redux/selectors/envSelector";
import { actions as councilEventActions } from "redux/actions/council-events-actions";
import { getAllEventAchivements } from "redux/actions/event-actions";
import { myLearningSelector } from "redux/selectors/myLearningSelector";
import { challengeSelector } from "redux/selectors/challengeSelector";
import { speakerAllPanelSpeakerSelector } from "redux/selectors/speakerSelector";

import { actions as myLearningActions } from "redux/actions/myLearning-actions";
import { actions as conferenceActions } from "redux/actions/conference-actions";

import { getChallenge } from "redux/actions/challenge-actions";

import {
  createControlCertificate,
  getAllCertificateType,
  getCodeCertificate,
} from "redux/actions/certificate-ations";

import {
  getEventVerification,
  addEventVerification,
} from "redux/actions/event-verfication-code";

import {
  orderLibrariesAction,
  changueStatusEventLibrary,
} from "redux/actions/library-actions";

import { getAllEvent } from "redux/actions/event-actions";

import LibraryClaimModal from "components/LibraryCard/LibraryClaimModal";

import { homeSelector } from "redux/selectors/homeSelector";
import {
  setLibraryViewed,
  claimLibrary,
  saveForLaterLibrary,
} from "redux/actions/library-actions";

import "./styles.scss";
import { CustomButton } from "components";
import { eventSelector } from "redux/selectors/eventSelector";
import { certificateSelector } from "redux/selectors/certificateSelector";
import { useHistory } from "react-router-dom";

const EventVideos = ({
  loading,
  // getEventVerification,
  // eventVerificationCode,
  // addEventVerification,
  searchConferenceLibraries,
  getAllEventVideos,
  allEventVideos,
  userProfile,
  createControlCertificate,
  isMobile,
  orderLibrariesAction,
  getAllCertificateType,
  certificateLearning,
  getCodeCertificate,
  changueStatusEventLibrary,
  getChallenge,
  challenges,
  getAllEventAchivements,
  allEventsAchivements,
  getCouncilEvents,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [bulOpenButton, setBulOpenButton] = useState(true);

  const { id, title } = useParams();

  const history = useHistory();

  useEffect(() => {
    getAllEventVideos({});
    getAllCertificateType("all");
    getAllEventAchivements();
    if (isMobile) {
      setBulOpenButton(false);
    }

    const getListOfYears = (startYear) => {
      const currentYear = new Date().getFullYear();
      const years = [];

      while (startYear <= currentYear) {
        years.push(startYear++);
      }

      return years;
    };

    const listOfYears = getListOfYears([2020]);
    searchConferenceLibraries({}, listOfYears);
  }, []);

  const videoSelect = () => {
    const matchingEvent = allEventVideos.find((event) => event.id == id);
    if (!matchingEvent) {
      console.error("No event found with id:", id);
      return null;
    }

    const matchingVideo = matchingEvent.Libraries.find(
      (video) => video.title === title
    );
    if (!matchingVideo) {
      console.error(
        "No video found with title:",
        title,
        "in event",
        matchingEvent.id
      );
      return null;
    }

    let newString = matchingVideo.link.replace(
      "/vimeo.com",
      "/player.vimeo.com/video"
    );

    if (matchingVideo) {
      return (
        <div>
          <iframe src={newString} frameborder="0" allowfullscreen />
          <h1>{matchingVideo.title}</h1>
        </div>
      );
    } else {
      return <div>Video not found</div>;
    }
  };

  const goTo = (id, title) => {
    history.push(`/my-learnings/event-videos/${id}/${title}`);
  };

  const getAllMatchingVideos = () => {
    const matchingEvent = allEventVideos.find((event) => event.id == id);
    if (!matchingEvent) {
      console.error("No event found with id:", id);
      return [];
    }
    const matchingVideos = matchingEvent.Libraries.filter(
      (video) => video.title !== title
    );

    if (!matchingVideos.length) {
      console.error(
        "No videos found with title:",
        title,
        "in event",
        matchingEvent.id
      );
      return [];
    }

    return matchingVideos.map((video) => {
      const newString = video.link.replace(
        "/vimeo.com",
        "/player.vimeo.com/video"
      );
      return (
        <div className="ContainerVideo">
          <div className="videosEvent">
            <iframe src={newString} frameborder="0" allowfullscreen />
            <h2>{video.title}</h2>
          </div>
          <div
            className="videoEnlace"
            onClick={() => goTo(id, video.title)}
          ></div>
        </div>
      );
    });
  };

  const titleEvent = () => {
    const matchingEvent = allEventVideos.find((event) => event.id == id);
    if (!matchingEvent) {
      console.error("No event found with id:", id);
      return [];
    }
    const datePart = matchingEvent.endDate.split('T')[0];
    const year = datePart.split('-')[0];

    return (
      <h2>
        - {year} - {matchingEvent.title}
      </h2>
    )
  }

  return (
    <>
      <div className="titleEvent">
        {titleEvent()}
      </div>
      <div className="contanierSectionHeaderEvents">
        <a href="/my-learnings?tab=1">Back to main menu</a>
        <CustomButton
          type="primary"
          size="sm"
          text="Download Event Certificate"
          className="butonLearningSelectTag"
          style={
            bulOpenButton
              ? {
                  opacity: "100%",
                  transition: "all 1s ease-in-out",
                  position: "absolute",
                }
              : {
                  opacity: "0%",
                  transition: "all 1s ease-in-out",
                  position: "absolute",
                }
          }
        />
      </div>
      <div className="ContainerEventsVideos">
        <div className="videoSelect">{videoSelect()}</div>
        <div className="ContainerVideosEvent">{getAllMatchingVideos()}</div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  ...myLearningSelector(state),
  userProfile: homeSelector(state).userProfile,
  isMobile: envSelector(state).isMobile,
  certificateLearning: certificateSelector(state).certificateLearning,
  challenges: challengeSelector(state).challenges,
  allPanelSpeakers: speakerAllPanelSpeakerSelector(state).allPanelSpeakers,
  allPanelSpeakersStatus:
    speakerAllPanelSpeakerSelector(state).allPanelSpeakersStatus,
  allEvents: eventSelector(state).allEvents,
  allEventsAchivements: eventSelector(state).allEventsAchivements,
});

const mapDispatchToProps = {
  ...myLearningActions,
  ...conferenceActions,
  ...councilEventActions,
  createControlCertificate,
  orderLibrariesAction,
  getAllCertificateType,
  getCodeCertificate,
  getChallenge,
  changueStatusEventLibrary,
  getEventVerification,
  addEventVerification,
  getAllEventAchivements,
};
export default connect(mapStateToProps, mapDispatchToProps)(EventVideos);
