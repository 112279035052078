import React from "react";

const IconReader = ({color = 'black', width = '24px', height = '24px'}) => {
  return (
    <div style={{width:width,height:height}}>
        <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Reader</title><rect x='96' y='48' width='320' height='416' rx='48' ry='48' fill='none' stroke={color} stroke-linejoin='round' stroke-width='32'/><path fill='none' stroke={color} stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M176 128h160M176 208h160M176 288h80'/></svg>
    </div>
  );
};

export default IconReader;
