import React, { useCallback, useEffect, useRef } from "react";
import { connect } from "react-redux";
import html2canvas from "html2canvas";
import { homeSelector } from "redux/selectors/homeSelector";
import moment from "moment";

import "./style.scss";

const CertificateChallenge = ({
    userCertificate,
    challenge,
    father,
    functionCargCertificate,
    userProfile,
    setCanvas = () => {},
}) => {
    const certificateRef = useRef(null);

    function dataURLtoFile(dataurl, filename) {

        if (dataurl !== undefined && dataurl !== null && dataurl !== '') {
            let arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }

            return new File([u8arr], filename, { type: mime });
        } else {
            return ''
        }

    }

    const blobImageBadgeChallenge = () => {
        let fileBlob = new Blob([dataURLtoFile(challenge?.imageBadge, moment().format())], { type: challenge?.imageBadgeType })
        return URL.createObjectURL(fileBlob)
    }

    const uploadImage = useCallback(async () => {
        const domElement = certificateRef.current;
        functionCargCertificate(certificateRef)
        const canvas = await html2canvas(domElement, {
            scale: 4,
        });
        canvas.style.cssText = `width: 100%; height: auto;`

        setCanvas(canvas)
        // if (father?.current?.children.length === 0) {
        //     father.current.appendChild(canvas);
        // } else {
        //     console.log(father.current?.children[0])
        //     father.current.removeChild(father.current?.children[0]);
        //     father.current.appendChild(canvas);
        // }
    }, [functionCargCertificate])

    useEffect(() => {
        if (userCertificate?.firstName !== undefined && challenge?.id !== undefined) {
            uploadImage()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userCertificate, challenge])

    return (
        <div style={{position:'absolute',width:'100%',height:'100%',left:'100000px',top:'-1000px'}}>
            <div
                style={{
                    width: 'auto',
                    height: 'auto',
                    position: 'relative'
                }}
                ref={certificateRef}
            >
                <img
                    className="challenge-compro-img-aaaa"
                    src={`${blobImageBadgeChallenge()}`}
                    alt="badge"
                />
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        display: 'grid',
                        gridTemplateColumns: '2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222%',
                        gridTemplateRows: '2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222%',
                        position: 'absolute',
                        top: '0px'
                    }}
                    draggable="false"
                >

                    {challenge?.bulShowName &&
                        <div
                            style={{
                                gridColumn: `${challenge?.LeftImageBadge !== '' ? challenge?.LeftImageBadge?.match(/\d{1,2}\//)[0]?.match(/\d{1,2}/)[0] : '0/0'}/${challenge?.LeftImageBadge ? challenge?.LeftImageBadge?.match(/\/\d{1,2}/)[0]?.match(/\d{1,2}/)[0] : '0/0'}`,
                                gridRow: `${challenge?.TopImageBadge ? challenge?.TopImageBadge?.match(/\d{1,2}\//)[0]?.match(/\d{1,2}/)[0] : '0/0'}/${challenge?.TopImageBadge ? challenge?.TopImageBadge?.match(/\/\d{1,2}/)[0]?.match(/\d{1,2}/)[0] : '0/0'}`,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                zIndex: '20',
                            }}
                        >
                            <p style={{
                                color: challenge?.colorText,
                                boxSizing: 'content-box',
                                whiteSpace: challenge?.lineText === 'true' ? 'pre' : '',
                                fontWeight: `${challenge?.boldImageBadge === 'true' ? 'bold' : 'normal'}`,
                                padding: challenge?.lineText === 'true' ? '0px' : '45%',
                                margin: '0px',
                                fontSize: `${Number(challenge?.fontSizeImageBadge)}%`,
                                lineHeight: challenge?.lineHeightBadge,
                                textAlign: 'center'
                            }}>{userProfile.firstName} {userProfile.lastName}</p>

                        </div>
                    }
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45].map((data) => {
                        return (
                            <>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `1/1`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='1' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `2/2`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='2' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `3/3`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='3' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `4/4`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='4' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `5/5`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='5' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `6/6`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='6' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `7/7`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='7' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `8/8`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='8' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `9/9`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='9' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `10/10`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='10' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `11/11`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='11' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `12/12`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='12' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `13/13`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='13' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `14/14`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='14' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `15/15`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='15' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `16/16`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='16' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `17/17`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='17' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `18/18`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='18' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `19/19`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='19' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `20/20`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='20' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `21/21`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='21' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `22/22`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='22' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `23/23`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='23' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `24/24`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='24' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `25/25`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='25' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `26/26`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='26' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `27/27`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='27' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `28/28`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='28' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `29/29`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='29' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `30/30`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='30' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `31/31`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='31' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `32/32`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='32' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `33/33`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='33' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `34/34`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='34' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `35/35`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='35' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `36/36`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='36' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `37/37`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='37' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `38/38`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='38' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `39/39`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='39' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `40/40`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='40' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `41/41`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='41' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `42/42`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='42' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `43/43`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='43' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `44/44`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='44' ></div>
                                <div style={{ gridColumn: `${data}/${data}`, gridRow: `45/45`, border: `0.25px solid transparent`, background: 'rgba(217, 217, 217, 0.04)', zIndex: '1' }} id={`${data}`} className='45' ></div>
                            </>
                        )
                    })}

                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    userProfile: homeSelector(state).userProfile,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(CertificateChallenge);