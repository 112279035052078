import React from "react";
import IconLogo from "images/logo-sidebar.svg";
import { Checkbox, Input, InputNumber, Modal, Space, notification } from "antd";
import "../style.scss";
import moment from "moment-timezone";
import { CustomButton, CustomCheckbox } from "components";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import httpClient from "../../../api/module/httpClient";
import { CERTIFICATE } from "enum/QueryKeys";
import { Form } from "antd";

export default function DiplomaModal({
  visible,
  onClose,
  finalAssessment,
  certificateId,
  slug,
  userProfile,
}) {
  const dateToday =
    moment.tz("America/Los_Angeles").format("YYYY-MM-DDTHH:mm:ss") + "Z";

  const finalAssessmentIsOver = moment
    .utc(finalAssessment?.startTime)
    .isBefore(dateToday);

  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const generateDiplomaMutation = useMutation({
    mutationFn: async () => {
      const { data } = await httpClient.post(
        `private/generate-diploma/${certificateId}`,
      );

      return data;
    },
    onSuccess: async ({ status, msg, description, base64Image }) => {
      queryClient.invalidateQueries(["CERTIFICATE_DIPLOMA_INFORMATION", slug]);
      
      onClose();

      if (status === "FAIL") {
        return notification.info({
          message: msg,
          description: description,
          placement: "topRight",
        });
      } else {
        // Create a data URL from the base64 string
        const dataUrl = `data:image/png;base64,${base64Image}`;

        // Create a temporary link element
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = `${userProfile?.firstName} ${userProfile?.lastName} Certificate.png`; // The name of the downloaded file

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);
      }
    },
  });

  const handleSubmit = ({ confirm }) => {
    if (!confirm) {
      return notification.warn({
        message: "Please check confirm to generate diploma",
        placement: "topRight"
      })
    }
    generateDiplomaMutation.mutate()
  };

  return (
    <Modal
      className="modal-login"
      width={"480px"}
      footer={null}
      style={{ height: "auto", top: "50px" }}
      visible={visible}
      onCancel={onClose}
    >
      <div className="login-page-2">
        <div
          className="login-dialog"
          style={{ paddingTop: "40px", paddingBottom: "40px" }}
        >
          <div style={{ padding: "10px", width: "100%" }}>
            {finalAssessmentIsOver ? (
              <Space direction="vertical" style={{ width: "100%" }}>
                <h3>Confirmation of Completion of Your Final Assignment</h3>
                <p>
                Please confirm below that you have fully completed your final assignment. You must keep a record of the completion of your final assignment in your files in case of audits by our organization. Thank you!
                </p>
                <Form form={form} onFinish={handleSubmit}>
                  <Form.Item name="confirm" valuePropName="checked">
                    <CustomCheckbox
                      value="checked"
                      size="sm"
                      disabled={userProfile.memberShip === "free"}
                    >
                      <h4>Confirm</h4>
                    </CustomCheckbox>
                  </Form.Item>
                  <Form.Item>
                    <CustomButton
                      htmlType="submit"
                      text="Generate Diploma"
                      loading={generateDiplomaMutation.isLoading}
                    />
                  </Form.Item>
                </Form>
              </Space>
            ) : (
              <>
                <h3 style={{ textAlign: "center" }}>
                  The Certificate Program Diploma and HR credits are not
                  available yet. They will be available on graduation day and
                  only upon completion of the program’s requirements. Please
                  check again on graduation day.
                </h3>
                <div
                  className="login-dialog-logo"
                  style={{ borderRadius: "50%", background: "white" }}
                >
                  <img src={IconLogo} alt="login-logo" />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}
