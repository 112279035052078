import { handleActions } from "redux-actions";
import { Map } from "immutable";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
// import storage from "store";
// import {
//   getEventPeriod,
//   getEventDescription,
//   convertToLocalTime,
// } from "utils/format";

// Action Type Imports
import { constants as eventConstants } from "../actions/event-actions";

// Events's Reducer
export const reducers = {
  [eventConstants.SET_ALL_EVENTS]: (state, { payload }) => {
    return state.merge({
      allEvents: cloneDeep(payload.events.events),
      allEventsUsersSketch: payload.events.users,
    });
  },
  [eventConstants.SET_ALL_EVENTS_ACHIVEMENTS]: (state, { payload }) => {
    return state.merge({
      allEventsAchivements: payload.payload.events,
    });
  },
  [eventConstants.SET_ALL_GROWS]: (state, { payload }) => {
    return state.merge({
      allGrows: cloneDeep(payload.events.events),
      allGrowsUsersSketch: payload.events.users,
    });
  },
  [eventConstants.SET_ALL_EVENT_CHANNELS]: (state, { payload }) => {
    return state.merge({
      allEventsChannels: cloneDeep(payload.eventsChannels),
    });
  },
  [eventConstants.SET_METADATA]: (state, { payload }) => {
    return state.merge({ metadata: cloneDeep(payload.metadata) });
  },
  [eventConstants.SET_MY_LIVE_EVENTS]: (state, { payload }) => {
    return state.merge({ allLiveEvents: cloneDeep(payload.myEvents) });
  },
  [eventConstants.UPDATE_BOXES]: (state, { payload }) => {
    const boxesSaves = state.get("boxesSaves");

    return state.merge({
      boxesSaves: { ...boxesSaves, [`${payload.data.id}`]: payload.data.info },
    });
  },
  [eventConstants.SET_EVENT]: (state, { payload }) => {
    const allEvents = state.get("allEvents");
    let index = allEvents.findIndex((item) => item.id === payload.event.id);
    if (index >= 0) {
      allEvents[index] = payload.event;
    }
    let myEvents = state.get("myEvents");
    if (!payload.event.status || payload.event.status === "attend") {
      myEvents = myEvents.filter((item) => item.id !== payload.event.id);
    } else {
      index = myEvents.findIndex((item) => item.id === payload.event.id);
      if (index >= 0) {
        myEvents[index] = payload.event;
      } else {
        myEvents.push(payload.event);
        myEvents.sort((a, b) => {
          return moment(a.date, "YYYY.MM.DD h:mm a").isBefore(
            moment(b.date, "YYYY.MM.DD h:mm a")
          )
            ? -1
            : 1;
        });
      }
    }

    // const community = storage.get("community");
    //   const { id: userId } = community || {};

    // const getEventStatus = (data, userId) => {
    //   let res = data.status[userId];
    
    //   const last = data.startAndEndTimes[data.startAndEndTimes?.length - 1];
    
    //   if (res === "going") {
    //     res = moment().isBefore((last || {}).endTime) ? res : "past";
    //   } else if (!res) {
    //     res = moment().isBefore((last || {}).endTime) ? "attend" : "";
    //   }
    //   return res;
    // };

    return state.merge({
      // allEvents: allEvents.map((item) => {
      //       return {
      //         ...item,
      //         key: item.id,
      //         date: moment(item.startDate).utc().format("YYYY.MM.DD h:mm a"),
      //         date2: moment(item.endDate).utc().format("YYYY.MM.DD h:mm a"),
      //         period: getEventPeriod(
      //           item.startDate,
      //           item.endDate,
      //           item.timezone
      //         ),
      //         period2: `${convertToLocalTime(
      //           item?.startDate,
      //           item?.timezone
      //         ).format("MMMM DD, YYYY")} - ${convertToLocalTime(
      //           item?.endDate,
      //           item?.timezone
      //         ).format("MMMM DD, YYYY")}`,
      //         about: getEventDescription(item.description),
      //         status: getEventStatus(item, userId),
      //       };
      //     })
      //     .sort((a, b) => {
      //       return moment(a.startDate).isAfter(moment(b.startDate)) ? 1 : -1;
      //     }),
      myEvents: [...myEvents],
      updatedEvent: { ...payload.event },
      liveEvent: { ...payload.event },
    });
  },
  [eventConstants.SET_GROWGROUP]: (state, { payload }) => {
    const allGrows = state.get("allGrows");
    let allGrowsUsersSketch = state.get("allGrowsUsersSketch");

    if (payload.users && allGrowsUsersSketch.hasOwnProperty(payload.event.id)) {
      allGrowsUsersSketch[payload.event.id] = allGrowsUsersSketch[
        payload.event.id
      ].filter((user) => payload.users.includes(user.id));
    }

    let index = allGrows.findIndex((item) => item.id === payload.event.id);
    if (index >= 0) {
      allGrows[index] = payload.event;
    }
    let myEvents = state.get("myEvents");
    if (!payload.event.status || payload.event.status === "attend") {
      myEvents = myEvents.filter((item) => item.id !== payload.event.id);
    } else {
      index = myEvents.findIndex((item) => item.id === payload.event.id);
      if (index >= 0) {
        myEvents[index] = payload.event;
      } else {
        myEvents.push(payload.event);
        myEvents.sort((a, b) => {
          return moment(a.date, "YYYY.MM.DD h:mm a").isBefore(
            moment(b.date, "YYYY.MM.DD h:mm a")
          )
            ? -1
            : 1;
        });
      }
    }
    return state.merge({
      allGrows: [...allGrows],
      myEvents: [...myEvents],
      updatedEvent: { ...payload.event, users: payload.users },
      liveEvent: { ...payload.event },
    });
  },
  [eventConstants.SET_MY_LIVE_EVENT]: (state, { payload }) => {
    return state.merge({ ...payload.event });
  },
  [eventConstants.SET_ERROR]: (state, { payload }) => {
    return state.merge({ ...payload });
  },
  [eventConstants.SET_LOADING]: (state, { payload }) => {
    return state.merge({ ...payload });
  },
  [eventConstants.SET_MY_EVENTS]: (state, { payload }) => {
    return state.merge({ ...payload });
  },
  [eventConstants.SET_CHANNEL_EVENTS]: (state, { payload }) => {
    return state.merge({ ...payload });
  },
};

export const initialState = () =>
  Map({
    loading: false,
    error: null,
    allEvents: [],
    allGrows: [],
    liveEvent: [],
    metadata: "",
    myEvents: [],
    updatedEvent: {},
    channelEvents: [],
    allEventsUsersSketch: [],
    allGrowsUsersSketch: [],
    allEventsChannels: [],
    allEventsAchivements: [],
    boxesSaves: {},
  });

export default handleActions(reducers, initialState());
