import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { 
  // Select,
  Form } from "antd";
import { categorySelector } from "redux/selectors/categorySelector";
import { envSelector } from "redux/selectors/envSelector";
import clsx from "clsx";
import { INTERNAL_LINKS } from "enum";
import moment from "moment";
import { Modal, DatePicker } from "antd";
import FormCreateOrEditBlog from "../../../BlogPostForUser/FormCreateOrEditBlog";
import {
  // getBlogsPostsByUser,
  // deleteBlogPost,
  // updateBlogPost
} from "redux/actions/blog-post-action";

import {
  CustomButton,
  // CustomInput,
  CustomModal,
  // FroalaEdit,
  // ImageUpload,
} from "components";

import "./style.scss";

const ModalCreateOrEdit = ({
  onCancelModal,
  handleCreateOrEditBlog,
  idChannel,
  getBlogsPostsByChannel = () => {},
  editOrDeleteBlogPost,
  setEditOrDeleteBlogPost = () => {},
  // allCategories,
  // s3Hash,
}) => {
  const [blogForm] = Form.useForm();
  const [summary, setSummary] = useState("");
  const [bulModal2, setBulModal2] = useState(false);
  const [functionForm, setFunctionForm] = useState({ function: () => {} });
  const [bulModal3, setBulModal3] = useState(false);
  const [date, setDate] = useState("");
  const [required, setRequirect] = useState({
    title: "title",
    description: "descrption",
    summary: "summary",
    categories: [],
    date: "date",
  });

  const handleSummary = (value) => {
    setSummary(value.length);
  };

  const handleSaveDraftBlogPost = (type) => {
    let values = blogForm.getFieldsValue();

    for (const key in values) {
      if (!values[key] && key !== "imageUrl") {
        blogForm.submit();
        return;
      }
    }
    handleCreateOrEditBlog({ ...values, status: type });
    onCancelModal();
    functionForm.function();
  };

  const handleSaveLaterBlogPost = (type) => {
    let values = blogForm.getFieldsValue();

    setRequirect({ ...values, date: date });
    setTimeout(() => {
      setRequirect({
        title: "title",
        description: "descrption",
        summary: "summary",
        categories: [],
        date: "date",
      });
    }, 3000);

    if (date === "") {
      return;
    }
    for (const key in values) {
      if (!values[key] && key !== "imageUrl") {
        blogForm.submit();
        return;
      }
    }

    handleCreateOrEditBlog({
      ...values,
      status: type,
      date: moment.utc(date.format("YYYY-MM-DD HH:mm")).format(),
    });
    functionForm.function();
    onCancelModal();
  };

  const customFormat = (value) =>
    `${moment(value.format("YYYY/MM/DD HH"), "YYYY/MM/DD HH").format(
      "YYYY/MM/DD"
    )} at ${moment(value.format("YYYY/MM/DD HH"), "YYYY/MM/DD HH").format(
      "HH a"
    )} (Pacific Time)`;

  return (
    <>
      <CustomModal
        visible={true}
        title={null}
        width={'100%'}
        style={{top:'60px', paddingBottom:'60px'}}
        onCancel={() => {onCancelModal()}}
      >
        <FormCreateOrEditBlog
          getBlogsPostsByUser={() => getBlogsPostsByChannel()}
          editOrDeleteBlogPost={editOrDeleteBlogPost}
          setEditOrDeleteBlogPost={(data) => setEditOrDeleteBlogPost(data)}
          ChannelIdSelect={idChannel}
          resetData={(data) => {setFunctionForm(data)}}
          finish={() => {
            onCancelModal();
            functionForm.function();
          }}
        />
      </CustomModal>
      <Modal
        visible={bulModal2}
        footer={null}
        width={400}
        bodyStyle={{
          marginBottom: "40px",
          padding: "20px",
          display: "flex",
          justifyContent: "center",
        }}
        onCancel={() => {
          setBulModal2(false);
        }}
      >
        <div
          style={{
            width: "100%",
            height: "auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              marginTop: "0px",
              width: "470px",
              background: "white",
              height: "auto",
              borderRadius: "5px",
              display: "flex",
              padding: "40px",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <h3 style={{ margin: "0px", paddingTop: "20px" }}>Post Now</h3>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "10px",
              }}
            >
              <CustomButton
                className="button-speaker font-size-more"
                text="Post Now"
                size="md"
                style={{
                  padding: "10px",
                  height: "40px",
                  width: "320px",
                  marginTop: "10px",
                }}
                type={"primary"}
                onClick={() => {
                  handleSaveDraftBlogPost("published");
                }}
              />
            </div>
            <h3 style={{ margin: "0px", paddingTop: "40px" }}>
              Schedule Your Post
            </h3>
            <DatePicker
              className={clsx("custom-input")}
              style={{ width: "100%" }}
              onChange={(date) => {
                setDate(date);
              }}
              format={customFormat}
              showTime
              panelRender={(data) => {
                return (
                  <div
                    className="container-ant-picker"
                    style={{ position: "relative" }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        right: "0px",
                        height: "40.89px",
                        width: "170px",
                        background: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "bold",
                      }}
                    >
                      Hour ( in Pacific Time )
                    </div>
                    {data}
                  </div>
                );
              }}
            />
            {!required.title && (
              <p style={{ color: "#fe5621", margin: "5px" }}>
                Title is required
              </p>
            )}
            {!required.summary && (
              <p style={{ color: "#fe5621", margin: "5px" }}>
                Summary is required
              </p>
            )}
            {!required.description && (
              <p style={{ color: "#fe5621", margin: "5px" }}>
                Description is required
              </p>
            )}
            {!required.categories && (
              <p style={{ color: "#fe5621", margin: "5px" }}>
                Categories is required
              </p>
            )}
            {!required.date && (
              <p style={{ color: "#fe5621", margin: "5px" }}>
                Date is required
              </p>
            )}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "10px",
              }}
            >
              <CustomButton
                className="button-speaker font-size-more"
                text="Schedule Your Post"
                size="md"
                style={{
                  padding: "10px",
                  height: "40px",
                  width: "320px",
                  marginTop: "20px",
                }}
                type={"primary"}
                onClick={() => {
                  handleSaveLaterBlogPost("draft");
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
      <CustomModal
        visible={bulModal3}
        footer={null}
        width={400}
        bodyStyle={{
          marginBottom: "40px",
          padding: "20px",
          display: "flex",
          justifyContent: "center",
        }}
        onCancel={() => {
          setBulModal3(false);
        }}
      >
        <h3>All fields are required</h3>
      </CustomModal>
    </>
  );
};

const mapStateToProps = (state) => ({
  allCategories: categorySelector(state).categories,
  s3Hash: envSelector(state).s3Hash,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModalCreateOrEdit);
