import httpClient from "./httpClient";

export const getCourses = ({ type, page }) => {
  //The type can be "course" or "certificate".
  return httpClient.get(`public/coursesAll/?type=${type}&page=${page}`);
};

export const getCertificates = () => {
  return httpClient.get(`private/certificates`)
}

export const getCourse = ({ id, slug }) => {
  return httpClient.get(`public/certificateBySlug?slug=${slug}`);
};

export const getCertificateBySlug = ({ slug }) => {
  return httpClient.get(`private/certificate/${slug}`)
}

export const getCertificateUsersBySlug = ({ slug }) => {
  return httpClient.get(`private/certificate-users/${slug}`)
}

export const getAllInstructorsOneCertificateEndPoint = ({ slug }) => {
  return httpClient.get(`public/certificate-getAllInstructors?slug=${slug}`);
};

export const registrationInACertificateEndPoint = (certificateId) => {
  return httpClient.post(`private/certificate-add-user/${certificateId}`);
};

export const updateActivityStatus = (id) => {
  return httpClient.patch(`private/certificate-activity-status/${id}`);
};

export const getCourseActivityStatusInfo = ({ slug }) => {
  return httpClient.get(`private/course/${slug}/certificate-activity-info`);
};

export const exportAllUsersOfOneConference = (certificateId) => {
  return httpClient.get(`private/export-users-one-conference/${certificateId}`);
};

export const getAll = async (data) => {
  let newFilter = {};

  if (data.filter) {
    newFilter = { ...data.filter };
  }

  const parsedFilter = Object.keys(newFilter)
    .map((item) => `${item}=${newFilter[item]}`)
    .join("&");

  return await httpClient.get(`private/courses?${parsedFilter}`);
};

export const get = async (id) => {
  return await httpClient.get(`private/course/${id}`);
};

export const getCourseClasses = async (course) => {
  return await httpClient.get(`private/course-classes/${course}`);
};

export const getCourseInstructors = async (course) => {
  return await httpClient.get(`private/course-instructors/${course}`);
};

export const getCourseSponsors = async (course) => {
  return await httpClient.get(`private/course-sponsors/${course}`);
};

export const claimCourse = ({ id, pdf }) => {
  return httpClient.post(`private/course/claim`, { id, pdf });
};
