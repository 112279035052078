import React from "react";
import { Avatar, Divider } from "antd";
// import Image from "next/image";
import linkedInIcon from "images/icon-linkedin.svg";
import IconMail from "images/icon-mail-outline.svg";
import { CustomButton } from "components";
import moment from "moment";

// import { useRouter } from "next/router";
import "./style.scss";

const CardUser = ({ user, title, btnClass = true, getOneUser = () => {}, lastTime = false, setActivitys = () => {} }) => {

  return (
    <div className={"card-user"} style={{position:'relative',paddingBottom:'62px'}}>
      {(user?.img || user?.abbrName) ? (<>
        {user?.img ? (
          <Avatar src={user?.img} size={180}></Avatar>
        ) : (
          <Avatar size={180}><span style={{color:'white', fontSize:'30px',fontWeight:'bold'}}>{user?.abbrName}</span></Avatar>
        )}
      </>) : (
        <Avatar size={180}><span style={{color:'white', fontSize:'30px',fontWeight:'bold'}}>Pending</span></Avatar>
      )}
      <Divider className={"divider"} />
      <h3 className={"name"}>{`${user?.firstName || ""} ${
        user?.lastName || ""
      }`}</h3>
      <p style={{ fontSize: "15px" }}>{user?.titleProfessions}</p>
      {(lastTime && lastTime !== 'no hay') && <p style={{fontSize: "15px"}}>Last time logged in: {moment(lastTime?.date,'YYYY-MM-DD HH:mm:ss').format('MMMM DD, YYYY')}</p>}
      <div className={"container"} style={{ display: "flex", alignItems: "center"}}>
        <a href={user?.linkedin || ""} target="_blank" rel="noopener noreferrer">
          <img className={"icon-linkedin"} src={linkedInIcon} alt="linkedin" />
        </a>
        <a href={`mailto:${user?.email || ""}`}>
          <img
            src={IconMail}
            alt="email"
            style={{
              width: "2.4rem",
            }}
          />
        </a>
      </div>
     {((user?.img || user?.abbrName) && !lastTime ) && <> <CustomButton
        text="View Profile"
        type="primary-retart"
        size="sm"
        onClick={() => {getOneUser(user?.id)}}
        style={{
            padding: "10px 18px!important",
            borderRadius: "10px",
            color: "white",
            cursor: 'pointer',
            width:'calc(100% - 40px)',
            width:'250px',
            marginTop:'20px',
            position:'absolute',
            bottom:'20px',
            // left:'0px',
        }}
      />
      {/* <CustomButton
          text="View Activity"
          type="primary-retart"
          size="sm"
          onClick={() => setActivitys(user)}
          style={{
              padding: "10px 18px!important",
              borderRadius: "10px",
              color: "white",
              cursor: 'pointer',
              width:'calc(100% - 40px)',
              width:'250px',
              marginTop:'10px',
          }}
        /> */}
      </>
      }
      
    </div>
  );
};

export default CardUser;
