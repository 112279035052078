import { createSelector } from "reselect";

const crashCourseDataSelector = (state) => state.crashCourse;

const resultSelector = createSelector(crashCourseDataSelector, (payload) => {
  return {
    allCrashCourses: payload.get("allCrashCourses"),
    crashCourse: payload.get("crashCourse"),
    crashCourseSlides: payload.get("crashCourseSlides"),
    loading: payload.get("loading"),
    crashCourseProgress: payload.get("crashCourseProgress"),
  };
});

export const crashCourseSelector = (state) => ({
  ...resultSelector(state),
});
