import React, {useEffect,useState} from "react";
import { Spin, notification } from "antd";
import { connect } from "react-redux";

import {
  CustomButton,
} from "components";
import IconLoading from "images/icon-loading.gif";
import { donwloadNewPremiumUsers, downloadNewUserSigned } from "api";
import { categorySelector } from "redux/selectors/categorySelector";
import { homeSelector } from "redux/selectors/homeSelector";
import { getActivityUserTrack } from "redux/actions/home-actions";
import PieChartComponent from "./components/pie";
import LineChartComponent from './components/line';

import "./style.scss";

const DashboardReference = ({
  activityStatus,
  getActivityUserTrack
}) => {
  const [loadingg, setLoadingg] = useState(false)

  useEffect(() => {
    getActivityUserTrack()
  },[])

  const downloadData = (buffer, title) => {
    let fileURL = window.URL.createObjectURL(
      new Blob([buffer.data], { type: "application/vnd.ms-excel" })
    );
    let fileLink = document.createElement("a");

    fileLink.href = fileURL;
    fileLink.setAttribute("download", `${title}.xlsx`);
    document.body.appendChild(fileLink);

    fileLink.click();
    document.body.removeChild(fileLink);
    window.URL.revokeObjectURL(fileURL);
    setLoadingg(false)
  }

  return (<div className="container-dashboard-reference">
    {(loadingg === true) && (
      <div className="loading-container" style={{zIndex:'100000',position:'fixed',left:'0px',top:'0px'}}>
          <Spin indicator={<img src={IconLoading} alt="loading-img" />} />
      </div>
    )}
    <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center',gap:'20px',width:'100%'}}>
      <div className="title-list-reference">Referenced Link</div>
    </div>
    <div style={{display:'flex',flexDirection:'row',justifyContent:'space-around',gap:'20px',alignItems:'center',gap:'20px',width:'100%'}}>
      <div className="card-chart">
        <h1 style={{paddingBottom:'20px',width:'100%',textAlign:'center'}}>Clicks: {activityStatus?.filter(e => e?.action === 'click')?.length}</h1>
        <LineChartComponent data={{click: [{label:'',data:activityStatus}]}} />
      </div>
      <div className="card-chart">
        <div style={{display:'flex',height:'auto',width:'100%',flexDirection:'column',alignItems:'center'}}>
          <h1 style={{paddingBottom:'20px',width:'100%',textAlign:'center'}}>New Users Signed Up: {activityStatus?.filter(e => e?.action === 'register')?.length}</h1> 
          <CustomButton
            size="small"
            text="Download Report"
            type="primary-retart"
            style={{color: "white",cursor: 'pointer',width:'160px'}}
            onClick={async () => {
              try{
                setLoadingg(true)
                const buffer = await downloadNewUserSigned()
                downloadData(buffer,'New Users Signed Up')
              }catch(er){
                console.log(er)
                setLoadingg(false)  
                notification.error({
                  message: "Error",
                  description: "Document was not saved",
                });
              }
              
            }}
          />
        </div>
        <LineChartComponent data={{register: [{label:'',data:activityStatus}]}} />
      </div>
      <div className="card-chart">
        <h1 style={{paddingBottom:'20px',width:'100%',textAlign:'center'}}>Existing Users Logged In: {activityStatus?.filter(e => e?.action === 'login')?.length}</h1>
        <LineChartComponent data={{login:[
          {label:'premium',data:activityStatus.filter(item => item?.features?.memberShip === 'premium')},
          {label:'free',data:activityStatus.filter(item => item?.features?.memberShip === 'free')}, 
        ]}} />
      </div>
    </div>
    <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',gap:'20px',width:'100%',marginTop:'40px',paddingBottom:'100px'}}>
      <div className="card-chart">
        <div style={{display:'flex',height:'auto',width:'100%',flexDirection:'column',alignItems:'center'}}>
          <h1 style={{paddingBottom:'20px',width:'100%',textAlign:'center'}}>New Premium Users: {activityStatus?.filter(e => e?.action === 'premium')?.length}</h1>
          <CustomButton
            size="small"
            text="Download Report"
            type="primary-retart"
            style={{color: "white",cursor: 'pointer',width:'160px'}}
            onClick={async () => {
              try{
                setLoadingg(true)
                const buffer = await donwloadNewPremiumUsers()
                downloadData(buffer,'New Premium Users')
              }catch(er){
                console.log(er)
                setLoadingg(false)  
                notification.error({
                  message: "Error",
                  description: "Document was not saved",
                });
              }
              
            }}
          />
        </div>
        <LineChartComponent data={{premium: [{label:'',data:activityStatus}]}} />
      </div>
      <div className="card-chart" style={{width:'340px'}}>
        <PieChartComponent data={activityStatus} />  
      </div>
    </div>
  </div>);
};

const mapStateToProps = (state, props) => ({
  allCategories: categorySelector(state).categories,
  activityStatus: homeSelector(state).activityStatus,
});

const mapDispatchToProps = {
    getActivityUserTrack
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardReference);