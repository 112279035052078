import { createAction } from "redux-actions";

const SET_ALL_CRASH_COURSES = "SET_ALL_CRASH_COURSES";
const GET_ALL_CRASH_COURSES = "GET_ALL_CRASH_COURSES";
const SET_CRASH_COURSE = "SET_CRASH_COURSE";
const GET_CRASH_COURSE_BY_SLUG = "GET_CRASH_COURSE_BY_SLUG";
const SET_CRASH_COURSE_SLIDES = "SET_CRASH_COURSE_SLIDES";
const GET_CRASH_COURSE_SLIDES = "GET_CRASH_COURSE_SLIDES";
const SET_LOADING = "SET_COURSE_LOADING";

export const constants = {
  SET_ALL_CRASH_COURSES,
  GET_ALL_CRASH_COURSES,
  SET_CRASH_COURSE,
  GET_CRASH_COURSE_BY_SLUG,
  SET_CRASH_COURSE_SLIDES,
  GET_CRASH_COURSE_SLIDES,
  SET_LOADING,
};

// ------------------------------------
// Actions
// ------------------------------------
export const getAllCrashCourses = createAction(GET_ALL_CRASH_COURSES, (filter) => ({
  filter,
}));
export const setAllCrashCourses = createAction(SET_ALL_CRASH_COURSES, (allCrashCourses) => ({
  allCrashCourses,
}));
export const getCrashCourseBySlug = createAction(GET_CRASH_COURSE_BY_SLUG, (slug) => ({
  slug,
}));
export const setCrashCourse = createAction(SET_CRASH_COURSE, (crashCourse) => ({
  crashCourse,
}));
export const getCrashCourseSlides = createAction(GET_CRASH_COURSE_SLIDES, (id) => ({ id }));
export const setCrashCourseSlides = createAction(SET_CRASH_COURSE_SLIDES, (crashCourseSlides) => ({ crashCourseSlides }));

export const setLoading = createAction(SET_LOADING, (loading) => ({ loading }));

export const actions = {
  getAllCrashCourses,
  setAllCrashCourses,
  // getCrashCourse,
  getCrashCourseBySlug,
  setCrashCourse,
  getCrashCourseSlides,
  setCrashCourseSlides,
  setLoading,
};
