import React from "react";
// import { connect } from "react-redux";
import { INTERNAL_LINKS } from "enum";
import { Route } from "react-router-dom";
// import { PrivateRoute } from "components";
import { LandingHeader, MainHeader } from "components";

const startRouteBlogs2 = () => {

  return (
    <>
      {localStorage.getItem("community") !== null ?
          <Route
              path={`${INTERNAL_LINKS.CHANNELS}/:id${INTERNAL_LINKS.BLOGS}/:id`}
              render={(props) => <MainHeader typeStyle={'black'} {...props} />}
          />
          :
          <Route
              path={`${INTERNAL_LINKS.CHANNELS}/:id${INTERNAL_LINKS.BLOGS}/:id`}
              exact
              render={(props) => <LandingHeader {...props} />}
          />
      }
    </>
  );
};

export default startRouteBlogs2;