import { createAction } from "redux-actions";

const GET_ANNUAL_CONFERENCE_CLASSES = "GET_ANNUAL_CONFERENCE_CLASSES";
const SET_ANNUAL_CONFERENCE_CLASSES = "SET_ANNUAL_CONFERENCE_CLASSES";
const GET_ANNUAL_CONFERENCE_CLASS = "GET_ANNUAL_CONFERENCE_CLASS";
const SET_ANNUAL_CONFERENCE_CLASS = "SET_ANNUAL_CONFERENCE_CLASS";
const ADD_ANNUAL_CONFERENCE_CLASS = "ADD_ANNUAL_CONFERENCE_CLASS";
const UPDATE_ANNUAL_CONFERENCE_CLASS = "UPDATE_ANNUAL_CONFERENCE_CLASS";
const DELETE_ANNUAL_CONFERENCE_CLASS = "DELETE_ANNUAL_CONFERENCE_CLASS";

export const constants = {
  GET_ANNUAL_CONFERENCE_CLASSES,
  SET_ANNUAL_CONFERENCE_CLASSES,
  GET_ANNUAL_CONFERENCE_CLASS,
  SET_ANNUAL_CONFERENCE_CLASS,
  ADD_ANNUAL_CONFERENCE_CLASS,
  UPDATE_ANNUAL_CONFERENCE_CLASS,
  DELETE_ANNUAL_CONFERENCE_CLASS,
};

// ------------------------------------
// Actions
// ------------------------------------
export const getAnnualConferenceClasses = createAction(
  GET_ANNUAL_CONFERENCE_CLASSES,
  (annualConferenceId, type,callback) => ({ annualConferenceId, type, callback })
);
export const setAnnualConferenceClasses = createAction(
  SET_ANNUAL_CONFERENCE_CLASSES,
  (annualConferenceClasses) => ({
    annualConferenceClasses,
  })
);
export const getAnnualConferenceClass = createAction(
  GET_ANNUAL_CONFERENCE_CLASS,
  (id) => ({
    id
  })
);
export const setAnnualConferenceClass = createAction(
  SET_ANNUAL_CONFERENCE_CLASS,
  (annualConferenceClass) => ({
    annualConferenceClass,
  })
);
export const addAnnualConferenceClass = createAction(
  ADD_ANNUAL_CONFERENCE_CLASS,
  (annualConferenceClass, callback) => ({
    annualConferenceClass, callback
  })
);
export const updateAnnualConferenceClass = createAction(
  UPDATE_ANNUAL_CONFERENCE_CLASS,
  (annualConferenceClass, callback) => ({
    annualConferenceClass, callback
  })
);
export const deleteAnnualConferenceClass = createAction(
  DELETE_ANNUAL_CONFERENCE_CLASS,
  (annualConferenceClass, callback) => ({
    annualConferenceClass, callback
  })
);

export const actions = {
  getAnnualConferenceClasses,
  setAnnualConferenceClasses,
  getAnnualConferenceClass,
  setAnnualConferenceClass,
  addAnnualConferenceClass,
  updateAnnualConferenceClass,
  deleteAnnualConferenceClass,
};
