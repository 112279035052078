import React, { useEffect, useRef } from "react";
import { Skeleton } from "antd";
import ChatMessage from "../ChatMessage";
import "../style.scss";
import { useQuery } from "@tanstack/react-query";
import { httpClientCron } from "../../../api/module/httpClientCron";
import moment from "moment/moment";

export default function ChatMessages({ id,blogId, senderName, senderImg, isTyping = false, userProfile }) {

  const { data: groupedChatMessages, isLoading } = useQuery({
    queryKey: ["CHAT_MESSAGE_WINDOW", {id,blogId}],
    queryFn: async () => {
      const { data } = await httpClientCron.get(`private/chat-messages-blog/${id}?blogId=${blogId}`);

      let newData = data.reduce((acc, message) => {
        const date = moment(message.createdAt).format("YYYY-MM-DD");
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(message);
        return acc;
      }, {});

      return newData
    },
  });

  const ref = useRef(null);

  const scrollToBottom = (isSmooth = false) => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: isSmooth ? "smooth" : "instant",
        block: "nearest",
        inline: "start",
      });
    }
  };


  useEffect(() => {
    scrollToBottom();
  }, [isLoading, isTyping, groupedChatMessages]);

  return (
    <div className="chat-message-modal-message-content">
      {isLoading && (
        <>
          {new Array(5).fill(null).map((_,index) => <Skeleton key={index} avatar paragraph={{ rows: 1 }} />)}
        </>
      )}
      {(Object.entries(groupedChatMessages || {}) ?? []).map(([date, chatMessages],indexx) => {
        let reference = []
        return (
          <div key={indexx}>
            {chatMessages.map((chatMessage, index) => {
              const { sender, content, createdAt } = chatMessage;
              const senderName = `${sender.firstName} ${sender.lastName}`;
              reference = [...(reference || []),sender?.id]

              return (
                <ChatMessage
                  key={index}
                  index={index}
                  date={date}
                  sender={sender}
                  createdAt={createdAt}
                  content={content}
                  senderName={senderName}
                  header={reference[index-1] === sender?.id}
                  rigth={(userProfile.role === 'admin' && Number(sender?.id) === Number(process.env.REACT_APP_DOMAIN_URL === 'http://localhost:3001' ? 320 : 205))
                    || (Number(userProfile.id) === Number(sender?.id))
                  }
                />
              );
            })}
          </div>
        );

      })}
      {isTyping && (
        <div style={{ display: "flex", gap: "10px", alignItems: "start" }}>
          <img
            src={senderImg}
            alt={senderName}
            style={{ borderRadius: "9999px", width: "2rem", height: "2rem" }}
          />
          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div className="loading">is typing</div>
            </div>
          </div>
        </div>
      )}
      <div ref={ref} />
    </div>
  );
}