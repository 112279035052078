import { createSelector } from "reselect";

const councilEventDataSelector = (state) => state.councilEvent;

const resultSelector = createSelector(councilEventDataSelector, (payload) => {
  return {
    allCouncilEvents: payload.get("allCouncilEvents"),
    allCouncilEventsT: payload.get("allCouncilEventsT"),
    allCouncilEventsCached: payload.get("allCouncilEventsCached"),
    councilEvent: payload.get("councilEvent"),
    allPanelList: payload.get("allPanelList"),
    arrayControlIdsEvents: payload.get("arrayControlIdsEvents"),
    searchedUsersForCouncilEvent: payload.get("searchedUsersForCouncilEvent"),
    emailDraftChannel: payload.get("emailDraftChannel"),
    allEmailSendEvent: payload.get("allEmailSendEvent"),
  };
});

export const councilEventSelector = (state) => ({
  ...resultSelector(state),
});
