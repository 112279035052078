import { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useChatMessageStore } from "./store";
import IconLogo from "../../images/logo-sidebar.svg";

const { io } = require("socket.io-client");

const ENDPOINT = process.env.REACT_APP_API_ENDPOINT_INSTANCE;

export const useChatSocket = ({ userProfile,lastMessageSaw }) => {
  const [chatSocket, setChatSocket] = useState(null);
  const chatMessageStore = useChatMessageStore()

  const queryClient = useQueryClient()

  useEffect(() => {
    const chatConnection = io(`${ENDPOINT}/chatBlog`, {
      transports: ["websocket"],
    });

    if (chatConnection && userProfile?.id) {
      setChatSocket(chatConnection);

      chatConnection.on("connect", () => {
        chatConnection.emit("login", {
          userId: userProfile.id,
          name: `${userProfile.firstName} ${userProfile.lastName}`,
          img: userProfile.img,
          titleProfessions: userProfile.titleProfessions
        });
      });

      chatConnection.on("private chat", ({ senderId, blogId, ...sender }) => {
        chatMessageStore.setMessageWindows({
          id: senderId,
          img: IconLogo,
          name: "Hacking HR Team",
          blogId: blogId,
          UserId: userProfile?.id,
          titleProfessions: '',
          isTyping: false,
        })

        // @todo do not invalidate but instead append to the end
        queryClient.invalidateQueries({ queryKey: ["CHAT_MESSAGE_WINDOW", {senderId,blogId}]})
        lastMessageSaw(senderId,blogId)
      });

      chatConnection.on("typing", (data) => {
        // console.log("on typing", data)
        const { senderId } = data;

        chatMessageStore.setTyping({ senderId, isTyping: true})
        chatMessageStore.pushChatTabItemId({ id: senderId })
      });

      chatConnection.on("stop typing", (data) => {
        // console.log("stop typing", data)
        const { senderId } = data;

        chatMessageStore.setTyping({ senderId, isTyping: false})
        chatMessageStore.deleteChatTabItemId({ id: senderId })
      });

      chatConnection.on("online", (users) => {
        // console.log(users, 'zz')
        chatMessageStore.setOnlineUsers(users)
      })
    }

    return () => {
      chatConnection.disconnect();
    };
  }, [userProfile, queryClient, lastMessageSaw]);


  return { chatSocket };
};