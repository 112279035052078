import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";

import { homeSelector } from "redux/selectors/homeSelector";
import { councilSelector } from "redux/selectors/councilSelector";
import { getUser } from "redux/actions/home-actions";


import { actions as councilConversation } from "redux/actions/councilConversation-actions";
import { actions as councilEventActions } from "redux/actions/council-events-actions";
import { councilEventSelector } from "redux/selectors/councilEventSelector";
import { councilConversationSelector } from "redux/selectors/councilConversationSelector";

// import Login from "../Login2";
import { isEmpty } from "lodash";
import moment from "moment-timezone";

// import ButtonsCouncilEvents from "./ButtonsCouncilEvents";
// import MemberSpeakers from "./MembersSpeakers";
// import ModalCompleteYourProfile2 from "./ModalCompleteYourProfile2";

import { convertToLocalTime } from "utils/format";

// import Emitter from "services/emitter";

import { CollapseComponent } from "components";

import { Space, notification } from "antd";

import IconBack from "images/icon-back.svg";

import "./style.scss";
// import Login2 from "pages/Login2";
import ButtonsCouncilEvents from "pages/CouncilEvent/ButtonsCouncilEvents";
import MemberSpeakers from "pages/CouncilEvent/MembersSpeakers";
// import ModalCompleteYourProfile2 from "pages/LandingPague/ModalCompleteYourProfile2";
// import { eventSelector } from "redux/selectors/eventSelector";

const EventDetails = ({
  event2,
  setEvent2,
  setIsEventActive,
  userProfile,
  getCouncilEvents,
  joinCouncilEvent,
  joinCouncilEventWait,
  allCouncilEventsT,
  allPanelList:alp,
  arrayControlIdsEvents:acid,
  // getUser,
}) => {
  const [event, setEvent] = useState({});
  const [limit, setLimit] = useState(0);
  const [allPanelList, setAllPanelList] = useState({})
  const [arrayControlIdsEvents,setArrayControlIdsEvents] = useState({})
  const bulQuery = useRef({})

  useEffect(() => {
    
      let data = JSON.parse(localStorage.getItem('callforspeakerscache') || '{}')
      if(!data[event2.id]){
        if(Object.keys(alp).length > 0 && bulQuery.current[event2.id]){
          data[event2.id] = {allPanelList: alp,arrayControlIdsEvents: acid}
          localStorage.setItem('callforspeakerscache', JSON.stringify(data))
          setAllPanelList(alp)
          setArrayControlIdsEvents(acid)
        }else{
          if(!bulQuery.current[event2.id]){
            bulQuery.current = {...bulQuery.current,[event2.id]:true}
            getCouncilEvents(() => {},'panelist',{id: event2.id});
          }
        }
      }else{
        setAllPanelList(data[event2.id].allPanelList)
        setArrayControlIdsEvents(data[event2.id].arrayControlIdsEvents)
      }
    
  },[alp,acid,event2,bulQuery])

  // const [bulModal5, setBulModal5] = useState(false);

  // const [changueModal, setChangueModal] = useState(false);
  // const [changueModal2, setChangueModal2] = useState(false);

  // const [showProfileCompletionFirewall, setShowProfileCompletionFirewall] =
  //   useState(false);
  const userTimezone = moment.tz.guess();
  // const [isLogged, setIsLogged] = useState(true);

  // const firstName = userProfile?.firstName;
  const timezone = !isEmpty(event) && event?.timezone;

  //   useEffect(() => {
  //     getUser();
  //   }, [getUser]);

  //   useEffect(() => {
  //     if (changueModal === true && firstName !== "" && firstName !== undefined) {
  //       setTimeout(() => {
  //         if (
  //           userProfile.percentOfCompletion === "100" ||
  //           userProfile.percentOfCompletion === 100
  //         ) {
  //           setIsLogged(true);
  //         } else {
  //           setBulModal5(true);
  //           setIsLogged(true);
  //         }
  //       }, 200);
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [firstName, changueModal, setBulModal5, userProfile]);

  //   useEffect(() => {
  //     if (changueModal2 === true && firstName !== "" && firstName !== undefined) {
  //       setTimeout(() => {
  //         setChangueModal2(false);
  //         if (
  //           userProfile.percentOfCompletion === "100" ||
  //           userProfile.percentOfCompletion === 100
  //         ) {
  //           setBulModal5(false);
  //         }
  //       }, 200);
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [firstName, changueModal2, setBulModal5]);

  //   useEffect(() => {
  //     getCouncilEvents();
  //   }, [getCouncilEvents]);

  //   console.log(
  //     userProfile,
  //     getCouncilEvents,
  //     "allEvents:",
  //     allEvents,
  //     joinCouncilEvent,
  //     joinCouncilEventWait,
  //     getUser
  //   );
  //   useEffect(() => {
  //     const _event = allEvents?.find((eve) => eve.id === Number(7)); //cambiar 7 por id
  //     // console.log("hereeee", allEvents);
  //     // console.log("hereeee", _event);
  //     setEvent(event2);
  //   }, [allEvents, event2, id, setEvent]);

  // useEffect(() => {
  //   if (event2?.id !== undefined) {
  //     setEvent(event2);
  //   }
  // }, [event2, setEvent]);

  const onBack = () => {
    setEvent2({});
    setIsEventActive(false);
  };

  //   useEffect(() => {
  //     if (userProfile !== undefined) {
  //       const limit1 = event?.CouncilEventPanels?.filter((panel) => {
  //         return panel?.arrayControlIdsEvents?.some((users) => {
  //           return Number(users.id) === Number(userProfile.id);
  //         });
  //       });

  //       const limit2 = event?.CouncilEventPanels?.filter((panel) => {
  //         return panel?.CouncilEventPanelists?.some((users) => {
  //           return Number(users.UserId) === Number(userProfile.id);
  //         });
  //       });

  //       if (limit1 !== undefined && limit2 !== undefined) {
  //         setLimit([...limit1, ...limit2]?.length);
  //       }
  //     }
  //   }, [event, userProfile]);

  const content = (panels) => {
    let startTime = convertToLocalTime(panels?.startDate, event?.timezone);
    let endTime = convertToLocalTime(panels?.endDate, event?.timezone);

    return (
      <div className="content-collapse" key={panels?.id}>
        <p
          style={{
            margin: "0px",
            marginTop: "3px",
            fontSize: "30px",
            fontWeight: "bold",
          }}
        >
          {panels?.panelName}
        </p>
        <p style={{ margin: "0px", marginTop: "3px" }}>
          <b>Panel Date</b>:{` ${startTime?.format("LL")}`}
        </p>
        <p
          className="title-collapse"
          style={{ margin: "0px", marginTop: "3px" }}
        >
          <b>Panel Start Time:</b> {startTime?.format("HH:mm a")}{" "}
          {moment.tz.guess()}
        </p>
        <p
          className="title-collapse"
          style={{ margin: "0px", marginTop: "3px", marginBottom: "10px" }}
        >
          <b>Panel End Time:</b> {endTime?.format("HH:mm a")} {moment.tz.guess()}
        </p>
      </div>
    );
  };

  const updateDataInformation = () => {
    let data = JSON.parse(localStorage.getItem('callforspeakerscache') || '{}')
    delete data[event2.id]
    localStorage.setItem('callforspeakerscache', JSON.stringify(data))
    bulQuery.current = {...bulQuery.current,[event2.id]:false}
    getCouncilEvents(() => {},'panelist',{id: event2.id});
  };

  useEffect(() => {
    if(allCouncilEventsT.length > 0 && Object.keys(allPanelList).length > 0 && Object.keys(arrayControlIdsEvents).length > 0){
      let _event = allCouncilEventsT.find((eve) => eve?.id === event2.id)
      _event.CouncilEventPanels = _event.CouncilEventPanels.map(a => {
        console.timeLog(a?.id)
        return { 
          ...a,
          arrayControlIdsEvents:arrayControlIdsEvents[a?.id],
          CouncilEventPanelists:allPanelList[a?.id]
        }
      }); 

      const limit1 = event?.CouncilEventPanels?.filter((panel) => {
        return panel?.arrayControlIdsEvents?.some((users) => {
          return Number(users?.id) === Number(userProfile?.id);
        });
      });

      const limit2 = event?.CouncilEventPanels?.filter((panel) => {
        return panel?.CouncilEventPanelists?.some((users) => {
          return Number(users?.UserId) === Number(userProfile?.id);
        });
      });

      if (limit1 !== undefined && limit2 !== undefined) {
        setLimit([...limit1, ...limit2]?.length);
      }

      setEvent(_event);
    }
  },[allCouncilEventsT,event2,event,allPanelList,arrayControlIdsEvents])

  const dataIterated = (panels, users) => {
    let userSelects = users?.map((data) => {
      if (Number(data?.id) === Number(userProfile?.id)) {
        return {
          id: panels?.id,
          isModerator: false,
          buttonsAccept: true,
          UserId: data?.id,
          User: data,
          pending: true,
        };
      } else {
        return {
          id: panels?.id,
          isModerator: false,
          buttonsAccept: true,
          UserId: data?.id,
          User: data,
          pending: userProfile?.role !== "admin" ? false : true,
        };
      }
    }) || [];

    let usersSelectss = [];
    if (userSelects.length > 0) {
      usersSelectss = [...(panels?.CouncilEventPanelists || []), ...userSelects];
    } else {
      usersSelectss = [...(panels?.CouncilEventPanelists || [])];
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyDontent: "space-around",
          flexWrap: "wrap",
        }}
      >
        {usersSelectss?.map((user, index) => {
          return (
            <MemberSpeakers
              key={index}
              usersPanel={user}
              isAdmin={userProfile?.role === "admin" ? true : false}
              remove={() => {
                joinCouncilEventWait(
                  panels?.id,
                  user?.User,
                  "Unjoin",
                  false,
                  false,
                  event,
                  {
                    rejectWithEmail: false
                  },
                  () => updateDataInformation()
                );
              }}
              removeAdmin={() => {
                joinCouncilEvent(
                  panels?.id,
                  Number(user?.UserId),
                  "Unjoin",
                  false,
                  false,
                  event?.id,
                  () => updateDataInformation()
                );
              }}
              joinAdmin={() => {
                joinCouncilEvent(
                  panels?.id,
                  Number(user?.UserId),
                  "Join",
                  false,
                  false,
                  event?.id,
                  () => updateDataInformation()
                );
              }}
            />
          );
        })}
      </div>
    );
  };

  //   const completeProfile = () => {
  //     Emitter.emit(EVENT_TYPES.EVENT_VIEW_PROFILE);
  //   };

  const functionOrderPanels = (panels) => {
    if (panels !== undefined) {
      let newPanels = panels?.filter((t) =>
        t?.typePanel.length !== 0 ? t?.typePanel?.includes("panel") : true
      );

      let arrayOrderNumer = newPanels.sort((a, b) => {
        let aTime = convertToLocalTime(a?.startDate, event?.timezone)?.format(
          "YYYYMMDDHHmm"
        );
        let bTime = convertToLocalTime(a?.startDate, event?.timezone)?.format(
          "YYYYMMDDHHmm"
        );

        return Number(aTime) - Number(bTime);
      });

      return arrayOrderNumer;
    } else {
      return panels;
    }
  };

  const displayPanels = functionOrderPanels(event?.CouncilEventPanels)?.map(
    (panel, index) => {
      return (
        <CollapseComponent
          key={panel?.id}
          informationCollapse={content(panel)}
          className={"container-panel"}
          dataIterated={dataIterated(panel, panel.arrayControlIdsEvents)}
          buttons={
            <ButtonsCouncilEvents
              index={index}
              panel={panel}
              limit2={limit >= event.maxNumberOfPanelsUsersCanJoin}
              withdraw={() => {
                joinCouncilEventWait(
                  panel.id,
                  userProfile,
                  "Unjoin",
                  false,
                  false,
                  event,
                  {
                    rejectWithEmail: false
                  },
                  () => updateDataInformation()
                );
              }}
              join={() => {
                if (event?.status !== "closed") {
                  joinCouncilEventWait(
                    panel?.id,
                    userProfile,
                    "Join",
                    false,
                    false,
                    event,
                    {
                      rejectWithEmail: false
                    },
                    () => updateDataInformation()
                  );
                } else {
                  notification.info({
                    message: "Sorry, this event is closed",
                  });
                }
              }}
              withdrawAdmin={() => {
                joinCouncilEvent(
                  panel?.id,
                  Number(userProfile?.id),
                  "Unjoin",
                  false,
                  false,
                  event?.id,
                  () => updateDataInformation()
                );
              }}

              completeProfile={() => {}
                // setBulModal5(true)
              }
              logginModal={() => {}
                // setIsLogged(false)
              }

              userProfile={userProfile}
            />
          }
        />
      );
    }
  );

  return (
    <>
      <div className="council-page-events-3">
        <div className="search-results-containerrr" style={{overflow:'hidden',height:'auto', paddingBottom:'70px'}}>
          <div className="council-page__container">
            <div className="council-page__results">
              <div className="council-page__row">
                <div className="council-page__info-column"></div>
                <div className="council-page__content">
                  <div
                  //   className="council-filter-panel"
                  >
                    <div
                      className="council-page__content-top"
                      onClick={() => onBack()}
                    >
                      <div className="council-page__content-top-back">
                        <img src={IconBack} alt="icon-back" />
                      </div>
                      <h4>Back</h4>
                    </div>
                    <Space direction="vertical" className="new-detail">
                      <h2 style={{ paddingTop: "20px" }}>
                        Event Name: {event?.eventName}
                      </h2>
                      <h4>
                        Date:{" "}
                        {convertToLocalTime(event?.startDate, timezone).format(
                          "LL"
                        )}{" "}
                        -{" "}
                        {convertToLocalTime(event?.endDate, timezone).format(
                          "LL"
                        )}{" "}
                        ({userTimezone})
                      </h4>
                      <h4>
                        Description:{" "}
                        {typeof event?.description === "string"
                          ? event?.description
                          : null}
                      </h4>
                    </Space>
                    <div className="fix-width">{displayPanels}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {showProfileCompletionFirewall && (
        <div
          className="skill-cohort-firewall"
          onClick={() => {
            setShowProfileCompletionFirewall(false);
          }}
        >
          <div className="upgrade-notification-panel" onClick={completeProfile}>
            <h3>
              You must fully complete your profile before joining an event.
            </h3>
          </div>
        </div>
      )} */}
      {/* <Modal
        visible={!isLogged || bulModal5}
        footer={null}
        width={400}
        bodyStyle={{
          marginBottom: "40px",
          padding: "20px",
          display: "flex",
          justifyContent: "center",
        }}
        className="modal-container-login"
      >
        {!isLogged && (
          <Login2
            login={true}
            signup={false}
            type={"ladingPague"}
            history={null}
            confirm={() => {
              getUser();
              if (!isLogged) {
                setChangueModal(true);
              }
            }}
            match={{ params: {} }}
            modal={() => {
              setIsLogged(false);
            }}
            onClose={() => {
              // setIsLogged(false);
            }}
          />
        )}
        {bulModal5 && (
          <ModalCompleteYourProfile2
            style={{ marginTop: "100px" }}
            userProfile={userProfile}
            onOk={() => {
              getUser(userProfile?.id, () => {
                setChangueModal2(true);
              });
            }}
          />
        )}
      </Modal> */}
    </>
  );
};

const mapStateToProps = (state, props) => ({
  userProfile: homeSelector(state).userProfile,
  councilResources: councilSelector(state).councilResources,
  allPanelList: councilSelector(state).allPanelList,
  arrayControlIdsEvents: councilSelector(state).arrayControlIdsEvents,
  ...councilEventSelector(state),
  ...councilConversationSelector(state),
});

const mapDispatchToProps = {
  getUser,
  ...councilConversation,
  ...councilEventActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventDetails);
