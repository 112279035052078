import moment from "moment/moment";
import React, { useRef, useState } from "react";
import { EllipsisOutlined } from "@ant-design/icons";
import { Divider, Dropdown, notification, Popconfirm } from "antd";
import { useChatMessageStore } from "../store";
import { useQueryClient } from "@tanstack/react-query";

export default function ChatMessage({
                                      index,
                                      date,
                                      sender,
                                      senderName,
                                      createdAt,
                                      content,
                                      chatMessageId,
                                      userProfile,
                                      chatSocket,
                                      chatMessageWindowId,
                                      chatMessageIndex,
                                      handleEditMessage,
                                      type,
                                    }) {
  const [isHovering, setIsHovering] = useState(false);
  const chatMessageStore = useChatMessageStore();
  const queryClient = useQueryClient();


  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };


  const formattedCreatedAt = moment(date).format("LL");
  const formattedDateToday = moment().format("LL");

  const isToday = formattedDateToday === formattedCreatedAt;

  const items = [
    {
      key: "1",
      label: "Edit",
      onClick: () => {
        handleEditMessage({ content, id: chatMessageId });
      },
    },
    {
      key: "2",
      label: `Delete`,
      onClick: () => {
        chatSocket.emit("deleteMessage", {
          id: chatMessageId,
          receiverId: chatMessageWindowId,
          senderId: userProfile.id,
          type,
        }, async (err, data) => {

          console.log("deleted", type, chatMessageWindowId);


          if (err) {
            notification.error({
              message: "Something went wrong",
              position: "top-right",
            });
          } else {

            if (type === "individual") {
              await queryClient.invalidateQueries({ queryKey: ["LAST_CHAT_MESSAGES", userProfile.id] });
            } else {
              await queryClient.invalidateQueries({ queryKey: ["GROUP_CHAT_LAST_MESSAGE"] });
            }

            await queryClient.invalidateQueries({ queryKey: ["CHAT_MESSAGE_WINDOW", type, chatMessageWindowId] });
          }
        });
      },
    },
  ];

  return (
    <div>
      {(chatMessageIndex === 0 && index === 0) && (
        <Divider>{isToday ? "Today" : formattedCreatedAt}</Divider>
      )}
      <div style={{
        display: "flex",
        gap: "10px",
        alignItems: "start",
        marginTop: (index === 0) ? "10px" : 0,
      }}>
        {(index === 0 && sender.img) ? (
          <>
            <img
              src={sender.img}
              alt={senderName}
              style={{ borderRadius: "9999px", width: "2rem", height: "2rem" }}
            />
          </>
        ) : (
          <div
            style={{ borderRadius: "9999px", width: "2.3rem", height: "2rem" }}
          />
        )}
        <div style={{ width: "100%" }}>
          {index === 0 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
                width: "100%",
              }}
            >
              <h4>{senderName}</h4>
              <div>{moment(createdAt).format("HH:mm")}</div>
            </div>
          )}
          <div
            style={{
              width: "100%",
              display: "flex",
              gap: "10px",
              alignItems: "center",
              backgroundColor: isHovering ? "#f3f4f6" : "unset",
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div style={{ width: "80%", whiteSpace: "break-spaces", padding: "5px 0" }}>{content}</div>
            {(isHovering && userProfile.id === sender?.id) && (
              <Dropdown
                menu={{
                  items,
                }}
                placement="topRight"
                arrow
                trigger={"click"}
              >
                <EllipsisOutlined rotate={90} style={{ fontSize: "18px" }} />
              </Dropdown>
            )}
          </div>
        </div>
      </div>
    </div>
  )
    ;
}