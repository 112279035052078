import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Emitter from "services/emitter";
import { Space } from "antd";

import { EVENT_TYPES, INTERNAL_LINKS } from "enum";
import { homeSelector } from "redux/selectors/homeSelector";
import { actions as homeActions } from "redux/actions/home-actions";
import { CustomButton } from "components";

const GeneralInformation = ({ userProfile, applyToMentoring }) => {
  const history = useHistory();

  const [showPremiumFirewall, setShowPremiumFirewall] = useState(false);
  const [showProfileCompletionFirewall, setShowProfileCompletionFirewall] =
    useState(false);

  const completeProfile = () => {
    Emitter.emit(EVENT_TYPES.EVENT_VIEW_PROFILE);
  };

  const onApplyToMentoring = () => {
    if (userProfile.completed) {
      applyToMentoring({ userId: userProfile.id });
    }
    if (!userProfile.completed) {
      return setShowProfileCompletionFirewall(true);
    }
    // if (userProfile.completed && userProfile.memberShip !== "premium") {
    //   return setShowPremiumFirewall(true);
    // }
    if (
      userProfile.isMentoringMember === "accepted"
    ) {
      history.push(INTERNAL_LINKS.MENTORING);
    }
  };

  return (
    <div className="display-general-information">
      <div className="information">
        <Space direction="vertical" size="large">
          <div
            className="container-parraf"
            style={{ marginTop: "30px", fontSize: "19px" }}
          >
            <p>Welcome to the Hacking HR’s Mentoring (Beta) Feature!</p>
            <p>
              This feature is still in beta and we are still developing and
              improving several elements.
            </p>
            <p>
              Currently we are only inviting a small number of Hacking HR LAB
              members to the Mentoring feature. This means that you may or may
              not find good matches for mentors and/or mentees. If that is the
              case, once your application is approved, please keep checking
              while we invite more users to this Mentoring beta feature.
            </p>
            <p>
              You will receive a response within 48 hours of your application to
              this feature. Then you will receive more information about how to
              use the feature.
            </p>
            <p>
              We are hoping to release this feature to all our users by the
              beginning of 2024.
            </p>
          </div>
        </Space>
        <div style={{ display: "flex", justifyContent: "end" }}>
          {userProfile.isMentoringMember === "pending" ? (
            <CustomButton text="Pending" type="primary" size="lg" disabled />
          ) : (
            <CustomButton
              text="Apply Now"
              type="primary"
              size="lg"
              onClick={onApplyToMentoring}
            />
          )}
        </div>
      </div>
      {showProfileCompletionFirewall && (
        <div
          className="skill-cohort-firewall"
          onClick={() => setShowProfileCompletionFirewall(false)}
        >
          <div className="upgrade-notification-panel" onClick={completeProfile}>
            <h3>
              You must fully complete your profile before applying to Mentoring.
            </h3>
          </div>
        </div>
      )}
      {showPremiumFirewall && (
        <div
          className="skill-cohort-firewall"
          onClick={() => setShowPremiumFirewall(false)}
        >
          <div className="upgrade-notification-panel">
            <h3>You must be a premium member before applying to Mentoring.</h3>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  userProfile: homeSelector(state).userProfile,
});

const mapDispatchToProps = {
  applyToMentoring: homeActions.applyToMentoring,
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInformation);
