import React, { useEffect, useState } from "react";
import { Alert, Avatar, Modal } from "antd";
import Languages from "../../enum/Languages";
import ProfileSettings from "../../enum/ProfileSettings";
import ContactIcons from "../../enum/ContactIcons";
import "./style.scss";

const LANGUAGES = Languages.ParsedLanguageData;
const WorkAreas = ProfileSettings.WORK_AREAS;

const UserProfileModal = ({
  setModalUserProfile,
  modalUserProfile,
  userProfile,
  allCategories,
}) => {
  const [data, setData] = useState(userProfile || {});

  useEffect(() => {
    setData(userProfile);
  }, [userProfile]);

  const {
    abbrName,
    img,
    firstName,
    lastName,
    email,
    titleProfessions,
    company,
    location,
    city,
    timezone,
    languages,
    about,
    topicsOfInterest,
    personalLinks,
    isOpenReceivingEmail,
    percentOfCompletion,
    recentJobLevel,
    recentWorkArea,
    sizeOfOrganization,
    mentor,
    mentee,
  } = data || {};

  const getLanguage = (value) => {
    const language = (LANGUAGES.find((item) => item.value === value) || {})
      .text;

    return language;
  };

  return (
    <Modal
      visible={modalUserProfile}
      maskClosable
      cancelText={<></>}
      okText="Close"
      onCancel={() => setModalUserProfile(false)}
      onOk={() => setModalUserProfile(false)}
      className="modal-container"
    >
      <div className="profile-view-panel" style={{width: '100%'}}>
        <div className="profile-view-panel-header">
          {img !== undefined &&
          typeof img === "string" &&
          (img || "").trim().length > 2 ? (
            <Avatar src={img || ""} size={100} className="user-info-avatar" />
          ) : (
            abbrName !== undefined && (
              <Avatar
                size={100}
                style={{ fontSize: "2.5rem", textTransform: "uppercase" }}
                className="user-info-avatar"
              >
                {abbrName}
              </Avatar>
            )
          )}

          <h1 className="user-info-name">{`${firstName} ${lastName}`}</h1>
        </div>
        <Alert
          style={{ marginTop: "20px" }}
          type={percentOfCompletion !== 100 ? "error" : "success"}
          message={
            percentOfCompletion !== 100 ? (
              <div>
                <strong>Missing information:</strong>
                <ul
                  className="inline-comma"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  {!img ? <li>Profile Picture</li> : null}
                  {!firstName ? <li>First name</li> : null}
                  {!lastName ? <li>Last name</li> : null}
                  {!titleProfessions ? <li>Title</li> : null}
                  {!company ? <li>Company</li> : null}
                  {!location ? <li>Location</li> : null}
                  {!city ? <li>City</li> : null}
                  {!timezone ? <li>Time zone</li> : null}
                  {!languages ? (
                    <li>Main language</li>
                  ) : Object.keys(languages).length === 0 ? (
                    <li>Main languages</li>
                  ) : null}
                  {!about ? <li>Tell us more about you</li> : null}
                  {topicsOfInterest ? (
                    Object.keys(topicsOfInterest).length === 0 ? (
                      <li>Topics of interest</li>
                    ) : null
                  ) : (
                    <li>Topics of interest</li>
                  )}
                  {personalLinks ? (
                    !personalLinks.hasOwnProperty("linkedin") ? (
                      <li>Linkedin Profile</li>
                    ) : (personalLinks.linkedin.toString() || "").trim() ===
                        "" ||
                      (personalLinks.linkedin.toString() || "").trim() ===
                        "https://" ? (
                      <li>Linkedin Profile</li>
                    ) : null
                  ) : null}
                  {isOpenReceivingEmail === -1 ? (
                    <li>
                      Are open to receiving information/being contacted via
                      email about open job positions?
                    </li>
                  ) : null}
                  {!recentJobLevel ? (
                    <li>
                      What best defines your current or most recent job level?
                    </li>
                  ) : null}
                  {Array.isArray(recentWorkArea) ? (
                    recentWorkArea.length === 0 ? (
                      <li>
                        In what area of HR do you currently work or most
                        recently worked?
                      </li>
                    ) : null
                  ) : null}
                  {!sizeOfOrganization ? (
                    <li>What is the size of the organization your work for?</li>
                  ) : null}
                </ul>
              </div>
            ) : (
              <div>This user profile is completed</div>
            )
          }
        />

        <div className="profile-view-panel-content">
          <h5 className="textfield-label">Email</h5>
          <h3 className="textfield-value">{email || "-"}</h3>
          <h5 className="textfield-label">Title</h5>
          <h3 className="textfield-value">{titleProfessions || "-"}</h3>
          <h5 className="textfield-label">Company</h5>
          <h3 className="textfield-value">{company || "-"}</h3>
          <h5 className="textfield-label">Location</h5>
          <h3 className="textfield-value">{location || "-"}</h3>
          <h5 className="textfield-label">City</h5>
          <h3 className="textfield-value">{city || "-"}</h3>
          <h5 className="textfield-label">Time zone</h5>
          <h3 className="textfield-value">{timezone || "-"}</h3>
          <h5 className="textfield-label">Main language</h5>
          {languages && languages.length > 0 ? (
            languages.map((lang, index) => (
              <h3 key={index} className="textfield-value">
                {getLanguage(lang)}
              </h3>
            ))
          ) : (
            <h3 className="textfield-value">-</h3>
          )}
          <h5 className="textfield-label">Tell us more about you</h5>
          <h3 className="textfield-value">{about || "-"}</h3>
          <h5 className="textfield-label">Topics of interest</h5>
          <h3 className="textfield-value">
            {(topicsOfInterest || [])
              .map((item) => {
                const index = allCategories.findIndex((t) => t.value === item);
                if (index >= 0) {
                  return allCategories[index].title;
                }
                return item;
              })
              .join(", ") || "-"}
          </h3>
          <h5 className="textfield-label">Personal links</h5>
          {Object.keys(personalLinks || []).map((contact) => {
            if (contact === "linkedin") {
              return (
                <div className="personal-link" key={contact}>
                  <div className="personal-link-icon">
                    <i className={ContactIcons[contact]} />
                  </div>
                  <h3 className="textfield-value">
                    {personalLinks[contact] || "-"}
                  </h3>
                </div>
              );
            } else {
              return null;
            }
          })}
          <h5 className="textfield-label">
            Are open to receiving information/being contacted via email about
            open job positions?
          </h5>
          <h3 className="textfield-value">
            {isOpenReceivingEmail === 1
              ? "Yes"
              : isOpenReceivingEmail === 0
              ? "No"
              : "-"}
          </h3>
          <h5 className="textfield-label">Job Level</h5>
          <h3 className="textfield-value">{recentJobLevel || "-"}</h3>
          <h5 className="textfield-label">
            In what area of HR do you currently work or most recently worked?
          </h5>
          <h3 className="textfield-value">
            {recentWorkArea !== undefined &&
            recentWorkArea.length &&
            recentWorkArea.includes("all")
              ? WorkAreas.filter((item) => item.value !== "all").map(
                  (item) => item.label
                )
              : // .join(", ")
                recentWorkArea}
          </h3>
          <h5 className="textfield-label">Size of the organization</h5>
          <h3 className="textfield-value">{sizeOfOrganization || "-"}</h3>
          {mentor !== "" && mentor !== null && (
            <>
              <h5 className="textfield-label">
                Describe your interests as a mentor and the types of the types
                of mentees you want to connect with
              </h5>
              <h3 className="textfield-value completed">{mentor}</h3>
            </>
          )}
          {mentee !== "" && mentee !== null && (
            <>
              <h5 className="textfield-label">
                Describe your interests as a mentee and the types of the types
                of mentors you want to connect with
              </h5>
              <h3 className="textfield-value completed">{mentee}</h3>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default UserProfileModal;
