import { createAction } from "redux-actions";

const GET_ALL_LIVE = "GET_ALL_LIVE";
const SET_CHAT_LIVE = "SET_CHAT_LIVE"
const ORDER_CHAT_LIVE = "ORDER_CHAT_LIVE"

export const constants = {
    GET_ALL_LIVE,
    SET_CHAT_LIVE,
    ORDER_CHAT_LIVE
};

// ------------------------------------
// Actions
// ------------------------------------
export const getChatLive = createAction(GET_ALL_LIVE, (callback) => ({ callback }));
export const orderChatLive = createAction(ORDER_CHAT_LIVE, (data, callback) => ({ data, callback }));
export const setChatLive = createAction(SET_CHAT_LIVE, (liveChat, callback) => ({ liveChat, callback }))

export const actions = {
    getChatLive,
    setChatLive
};
