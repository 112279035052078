import { Skeleton } from "antd";
import React from "react";
import { useChatMessageStore } from "../store";

export default function SearchUserList({ isLoading, users, setText, setIsSearching, setUsers }) {
  const chatMessageStore = useChatMessageStore()

  return (
    <>
      {isLoading && (
        <div style={{ padding: "12px 16px" }}>
          {new Array(5).fill(null).map(() => <Skeleton avatar paragraph={{ rows: 1 }} />)}
        </div>
      )}
      {users.map(item => {
        const user = item.User;

        const name = `${user.firstName} ${user.lastName}`;

        return (
          <div
            key={`searchchatmessage-${user.id}`}
            style={{
              padding: "0 16px",
              paddingTop: "12px",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "1rem",
                cursor: "pointer",
                borderBottom: "1px solid rgba(219, 219, 219)",
                paddingBottom: "10px",
              }}
              onClick={(event) => {
                event.stopPropagation();

                chatMessageStore.setChatMessageWindows({
                  img: user.img,
                  name: name,
                  id: user.id,
                  titleProfessions: user.titleProfessions,
                  type: "individual"
                });

                // go back to last chat messages
                setText("");
                setIsSearching(false);
                setUsers([]);
              }}
            >
              <img src={user.img} alt={name}
                   style={{ borderRadius: "9999px", width: "3rem", height: "3rem" }} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>{name}</span>
                </div>
                <span
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    width: "200px",
                  }}
                >
                    {user.titleProfessions}
                  </span>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}