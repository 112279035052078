import React, { useState } from "react";
import "./style.scss";
import { Collapse } from "antd";
import CollapseArrow from "../../../images/arrowexpanmodules.svg"
const { Panel } = Collapse;

const CollapseModules = ({content, title, background = 'rba(255,255,255)'}) => {

    const [expandArrowAnimation, setExpandArrowAnimation] = useState(false)
  
    return (
        <div className="container-certificateee-collapse-module" style={{background:background}}>
            <Collapse bordered={false} expandIcon={() => (<div></div>)}>
                <Panel key={1} header={<div style={{display:'inline-flex', background:background}} className="container-header-collapse-certificate-module" onClick={() => {setExpandArrowAnimation(!expandArrowAnimation)}} >
                    <div className="truc-shadow-module" style={{background:background}}>
                        <img src={CollapseArrow} style={expandArrowAnimation ? {transform:'rotate(0deg)', transition:'all 0.4s ease-in-out', padding:'0px'}:{transform:'rotate(90deg)',transition:'all 0.4s ease-in-out',padding:'0px'}} alt="collapse-aarrow" />
                        <p className="p-collap-certificate-module">{title}</p> 
                    </div>
                </div>} collapsible={true}>
                <div className="container-collapse-card-module" style={{background:background}}>
                    <div className="container-collapse-card-shadow-module" style={{background:background}}>
                        {content}
                    </div>
                </div>
                </Panel>
            </Collapse>
        </div>
    );
};


export default CollapseModules;
