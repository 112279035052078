import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import clsx from "clsx";
import { Alert, Button, Table, Form, Select, Spin, notification } from "antd";
import { loadStripe } from "@stripe/stripe-js";
import { CustomModal, CustomButton } from "components";
import { debounce } from "lodash";

import { envSelector } from "redux/selectors/envSelector";
import { homeSelector } from "redux/selectors/homeSelector";
import { councilEventSelector } from "redux/selectors/councilEventSelector";
import { actions as councilEventActions } from "redux/actions/council-events-actions";
import { getCheckoutSession } from "api/module/stripe";

import { STRIPE_PRICES } from "enum";

import IconLogo from "images/logo-sidebar.svg";
import { ReactComponent as IconDoubleCheckmark } from "images/icon-double-checkmark.svg";
import { ReactComponent as IconCloseOutline } from "images/icon-close-outline.svg";
import imageDown from 'images/okhomelandin.svg';

import "./style.scss";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_KEY);

const PaymentForm = ({ isMobile, userProfile, handleSubmit, hidePanel,searchUserForCouncilEventPanelist, searchedUsersForCouncilEvent}) => {

  const [form] = Form.useForm();
  const [stripe, setStripe] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [checkoutSessionError, setCheckoutSessionError] = useState(false);
  const [emailUsers, setEmailsUsers] = useState([]);
  const [bulModalSelectUsers, setBulModalSelectUsers] = useState(false)
  const [checkoutSessionErrorMsg, setCheckoutSessionErrorMsg] = useState("");
  const [value, setValue] = useState([userProfile.email])
  const [loading, setLoading] = useState(false);

  const paymentColumns = [
    {
      title: "",
      key: "text",
      fixed: "left",
      width: 120,
      dataIndex: "text",
      className: "payment-table-column-text",
      render: (value, record) => (
        <span className="antd-table-payment-text">{record.text}</span>
      ),
    },
    {
      title: "Free",
      key: "free",
      dataIndex: "free",
      align: "center",
      width: 150,
      className: "payment-table-column",
      render: (value, record) => {
        if (record.hasOwnProperty("buttonSection")) {
          if (record.buttonSection === true) {
            return "Free";
          }
        } else {
          return getIcon(value);
        }
      },
    },
    {
      title: "Premium (Individual)",
      key: "premium",
      dataIndex: "premium",
      align: "center",
      className: "payment-table-column",
      width: 150,
      render: (value, record) => {
        if (record.hasOwnProperty("buttonSection")) {
          if (record.buttonSection === true) {
            if (userProfile.memberShip === "premium") {
              return "Premium";
            } else {
              return (
                <Button
                  loading={loading}
                  onClick={() => {
                    requestCheckoutSessionTable({
                      premium: true,
                      recruiter: false,
                      creator: false,
                    });
                  }}
                  className="pay-buttton"
                >
                  Pay ${STRIPE_PRICES.STRIPE_PRICES[0].price}
                </Button>
              );
            }
          }
        } else {
          return getIcon(value);
        }
      },
    },
    {
      title: () => (<div><p style={{margin:'0px', padding:'0px', display: "flex", flexDirection: "column", height:'65px'}}>Premium (Corporate)</p><span style={{fontSize:'12px'}}>Minimum five accounts</span></div>),
      key: "premium",
      dataIndex: "premium",
      align: "center",
      className: "payment-table-column",
      width: 150,
      render: (value, record) => {
        if (record.hasOwnProperty("buttonSection")) {
          if (record.buttonSection === true) {
            if (userProfile.memberShip === "premium") {
              return "Premium";
            } else {
              return (
                <Button
                  loading={loading}
                  onClick={() => {
                    requestCheckoutSessionTable({
                      premium: true,
                      recruiter: false,
                      creator: false,
                    });
                  }}
                  className="pay-buttton"
                >
                  Pay ${STRIPE_PRICES.STRIPE_PRICES[0].price}
                </Button>
              );
            }
          }
        } else {
          return getIcon(value);
        }
      },
    },
    // {
    //   title: "Creator",
    //   key: "creator",
    //   dataIndex: "creator",
    //   align: "center",
    //   width: 150,
    //   className: "payment-table-column",
    //   render: (value, record) => {
    //     if (record.hasOwnProperty("buttonSection")) {
    //       if (record.buttonSection === true) {
    //         if (userProfile.memberShip === "channel_admin") {
    //           return "Creator";
    //         } else {
    //           if (userProfile.memberShip === "free") {
    //             return (
    //               <Button
    //                 loading={loading}
    //                 onClick={() => {
    //                   requestCheckoutSessionTable({
    //                     premium: true,
    //                     recruiter: false,
    //                     creator: true,
    //                   });
    //                 }}
    //                 className="pay-buttton"
    //               >
    //                 Pay $
    //                 {parseFloat(STRIPE_PRICES.CHANNELS_STRIPE_PRICES[0].price) +
    //                   parseFloat(STRIPE_PRICES.STRIPE_PRICES[0].price)}
    //               </Button>
    //             );
    //           } else {
    //             if (userProfile.channelsSubscription === true) {
    //               return "Creator";
    //             } else {
    //               return (
    //                 <Button
    //                   loading={loading}
    //                   onClick={() => {
    //                     requestCheckoutSessionTable({
    //                       premium: false,
    //                       recruiter: false,
    //                       creator: true,
    //                     });
    //                   }}
    //                   className="pay-buttton"
    //                 >
    //                   Pay ${STRIPE_PRICES.CHANNELS_STRIPE_PRICES[0].price}
    //                 </Button>
    //               );
    //             }
    //           }
    //         }
    //       }
    //     } else {
    //       return getIcon(value);
    //     }
    //   },
    // },
  ];

  const addOnsColumns = [
    {
      title: "",
      key: "text",
      fixed: "left",
      width: 120,
      dataIndex: "text",
      className: "payment-table-column-text",
      render: (value, record) => (
        <span className="antd-table-payment-text">{record.text}</span>
      ),
    },
    // {
    //   title: "Add-on Recruiter",
    //   key: "recruiter",
    //   dataIndex: "recruiter",
    //   align: "center",
    //   className: "payment-table-column",
    //   width: 150,
    //   render: (value, record) => {
    //     if (record.hasOwnProperty("buttonSection")) {
    //       if (record.buttonSection === true) {
    //         if (
    //           userProfile.memberShip === "premium" &&
    //           userProfile.recruiterSubscription === false
    //         ) {
    //           return (
    //             <Button
    //               loading={loading}
    //               onClick={() => {
    //                 requestCheckoutSessionTable({
    //                   premium: false,
    //                   recruiter: true,
    //                   creator: false,
    //                 });
    //               }}
    //               className="pay-buttton"
    //             >
    //               Annual Subscription ${STRIPE_PRICES.RECRUITER_STRIPE_PRICES[0].price}
    //             </Button>
    //           );
    //         } else {
    //           return "You need to upgrade to PREMIUM before adding the RECRUITER feature";
    //         }
    //       }
    //     } else {
    //       return getIcon(value);
    //     }
    //   },
    // },
  ];

  const paymentsDatasource = [
    {
      text: "Participation in all Hacking HR online events",
      free: true,
      premium: true,
      recruiter: true,
      creator: true,
    },
    {
      text: "Access to: learning library, mentoring, conference library, classes, podcast and podcast series",
      free: true,
      premium: true,
      recruiter: true,
      creator: true,
    },
    {
      text: "Access to ProjectX: Hacking HR's cohort based learning",
      free: false,
      premium: true,
      recruiter: true,
      creator: true,
    },
    {
      text: "HR certification credits (for applicable learning items in the learning library, conference library, podcast series or classes)",
      free: false,
      premium: true,
      recruiter: true,
      creator: true,
    },
    // {
    //   text: "Content sharing with the community: events, podcasts, videos, classes and other resources",
    //   free: false,
    //   premium: false,
    //   recruiter: false,
    //   creator: true,
    // },
    {
      text: "",
      buttonSection: true,
    },
  ];

  const addOnsDatasource = [
    // {
    //   text: "Post jobs, access to profiles in Talent Marketplace and connect with candidates in the talent marketplace",
    //   free: false,
    //   premium: false,
    //   recruiter: true,
    //   creator: false,
    // },
    {
      text: "",
      buttonSection: true,
    },
  ];

  useEffect(() => {
    setFetching(false)
  },[searchedUsersForCouncilEvent])

  const instanceStripe = async () => {
    let dataF = loadStripe(process.env.REACT_APP_STRIPE_PK_KEY)
    let data = await dataF;
    setStripe(data);
  };

  const handleSearchUser = (values) => {
    const runDebounce = debounce(() => {
      setFetching(true)
      searchUserForCouncilEventPanelist(values);
    }, 500);

    runDebounce();
  };

  useEffect(() => {
    instanceStripe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const requestCheckoutSessionTable = async ({
    premium = false,
    creator = false,
    recruiter = false,
    isBuyMoreThatOneSuscription = false,
    emailUsers = [],
  }) => {
    setLoading(true);
    setCheckoutSessionError(false);
    setCheckoutSessionErrorMsg("");
    let checkoutSessionPrices = [];
    if (premium === true) {
      checkoutSessionPrices.push(STRIPE_PRICES.STRIPE_PRICES[0].priceId);
    }
    if (recruiter === true) {
      checkoutSessionPrices.push(
        STRIPE_PRICES.RECRUITER_STRIPE_PRICES[0].priceId
      );
    }
    if (creator === true) {
      checkoutSessionPrices.push(
        STRIPE_PRICES.CHANNELS_STRIPE_PRICES[0].priceId
      );
    }
    if(isBuyMoreThatOneSuscription === true){
      if(emailUsers){
        try {
          let sessionData2 = await getCheckoutSession({
            isBuyMoreThatOneSuscription: true,
            emailUsers: emailUsers,
            prices: [STRIPE_PRICES.STRIPE_PRICES[3].priceId],
            callback_url: `${process.env.REACT_APP_DOMAIN_URL}?refreshMore=true`,
            callback_error: `${process.env.REACT_APP_DOMAIN_URL}?refreshMoreBad=true`,
          });
        
          return stripe.redirectToCheckout({ sessionId: sessionData2.data.id });
        }catch(err){
          console.log(err)
          setLoading(false);
          setCheckoutSessionError(true);
          setCheckoutSessionErrorMsg(err?.response?.data?.msg);
          notification.error({
            message: "Error",
            description: err?.response?.data?.msg,
          });
        }
      }
    }else{
      try {
        let sessionData = await getCheckoutSession({
          prices: checkoutSessionPrices,
          callback_url: `${process.env.REACT_APP_DOMAIN_URL}?refreshPremium=true`,
          callback_error: `${process.env.REACT_APP_DOMAIN_URL}?refreshPremiumBad=true`,
        });
        return stripe.redirectToCheckout({ sessionId: sessionData.data.id });
      } catch (err) {
        console.log(err)
        setLoading(false);
        setCheckoutSessionError(true);
        setCheckoutSessionErrorMsg(err?.response?.data?.msg);
      }
    }

    
  };

  const getIcon = (value) => {
    if (value === true) {
      return <IconDoubleCheckmark className="green-icon" />;
    } else {
      return <IconCloseOutline className="red-icon" />;
    }
  };

  const handleOnFinishSelect = () => {
    //reviuw if all is emails


    if(value.length < 5){
      notification.error({
        message: "Error",
        description: "Please select minimum five accounts.",
      });
      return 
    }

    requestCheckoutSessionTable({
      premium: false,
      recruiter: false,
      creator: false,
      isBuyMoreThatOneSuscription: true,
      emailUsers: value
    });
  }

  return (
    <div
      className={`payment-form-table-container ${clsx("plan-ugrade-form", {
        mobile: isMobile,
      })}`}
    >
      {isMobile && (
        <>
          <div className="plan-ugrade-form-header">
            <div className="plan-ugrade-form-header-logo">
              <img src={IconLogo} alt="payment-logo" />
            </div>
            <h3>Plan update</h3>
            <h5>Access to all content</h5>
          </div>
          <div className="plan-ugrade-form-close" onClick={hidePanel}>
            <i className="fas fa-times" />
          </div>
        </>
      )}
      <div className="container-second-pricing">
        <div className="container-pricing">
          <div className="subcontainer-pricing">
            <div className="container-h3-icon">
              <h3>Choose A Perfect Plan</h3> <img src={imageDown} alt="icon down" className="subcontainer-pricing-icon" />
            </div>
          </div>
          <div className="subcontainer-1-pricing">
            <div className="subcontainer-1-cards">
              <div className="subcontainer-1-cards-main-content-2" style={{display: 'flex',alignItems: 'flex-start', flexDirection:'column'}}>
                <p className="h5-p-pricing">Basic</p>
                <p className="h3-p-pricing">FREE</p>
                <ul>
                  <li className="li-content-2">Participation in all Hacking HR online events. Access to: learning library, conference library, podcast and podcast series</li>
                </ul>
              </div>
              <div onClick={() => {}} className="subcontainer-1-cards-button">
                <p style={{margin:'0px',padding:'0px'}}>Free</p>
              </div>
            </div>

            <div className="subcontainer-1-cards">
              <div className="container-arrow-img"></div>

              <div className="subcontainer-1-cards-main-content-2" style={{display: 'flex',alignItems: 'flex-start', flexDirection:'column'}}>
                <p className="h5-p-pricing">Premium (Individual)</p>
                <div>
                  <p className="h3-p-pricing">${STRIPE_PRICES.STRIPE_PRICES[0].price}/ year</p>
                  <span>Billed every year</span>
                </div>
                <ul>
                  <li>Participation in all Hacking HR online events.</li>
                  <li>Learning library, conference library, podcast and podcast series</li>
                  <li>ProjectX: Hacking HR's cohort based learning HR certification credits (for applicable learning items in the learning library, conference library, podcast series or classes)</li>
                </ul>
              </div>
              <div className="subcontainer-1-cards-button">
                { (userProfile.memberShip === "premium") ?
                  (<p  style={{margin:'0px',padding:'0px'}} >Premium</p>) : (
                  <Button
                    loading={loading}
                    style={{background:'transparent',border:'none',boxShadow:'none'}}
                    onClick={() => {
                      requestCheckoutSessionTable({
                        premium: true,
                        recruiter: false,
                        creator: false,
                      });
                    }}
                    className="pay-buttton"
                  >
                    Get Started!
                  </Button>
                )}
              </div>
            </div>

            <div className="subcontainer-1-cards">
              <div className="container-figure-img"></div>
              <div className="subcontainer-1-cards-main-content-2" style={{display: 'flex',alignItems: 'flex-start', flexDirection:'column'}}>
                <p className="h5-p-pricing">Premium Corporate</p>
                <div>
                  {/* <p className="h3-p-pricing">$139/ year</p> */}
                  <p className="h3-p-pricing">${STRIPE_PRICES.STRIPE_PRICES[0].price}/ year</p>
                  <span className="span-2">Billed every year</span>
                </div>
                <ul>
                  <li>Participation in all Hacking HR online events.</li>
                  <li>Learning library, conference library, podcast and podcast series</li>
                  <li>ProjectX: Hacking HR's cohort based learning HR certification credits (for applicable learning items in the learning library, conference library, podcast series or classes)</li>
                  <li>Content sharing with the community: events, podcasts, videos, classes and other resources</li>
                  {/* <li>Minimum five accounts</li> */}
                </ul>
              </div>
              <div onClick={() => {}} className="subcontainer-1-cards-button">
                { (userProfile.memberShip === "premium") ?
                  (<p style={{margin:'0px',padding:'0px'}} >Premium</p>) : (
                  <Button
                    loading={loading}
                    style={{background:'transparent',border:'none',boxShadow:'none',color:'black'}}
                    onClick={() => {
                      requestCheckoutSessionTable({
                        premium: true,
                        recruiter: false,
                        creator: false,
                      });
                    }}
                    className="pay-buttton"
                  >
                    Get Started!
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="effect-background"></div> */}
      </div>
      {/* <Table
        className="antd-table-payment"
        rowClassName="payment-table-row"
        bordered={false}
        columns={paymentColumns}
        dataSource={paymentsDatasource}
        pagination={false}
        scroll={isMobile && { x: "100vw" }}
      ></Table> */}
      <br></br>
      {/* <>
        <Table
          className="antd-table-payment"
          rowClassName="payment-table-row"
          bordered={false}
          columns={addOnsColumns}
          dataSource={addOnsDatasource}
          pagination={false}
          scroll={isMobile && { x: "100vw" }}
        ></Table>
        <br></br>
      </> */}
      {checkoutSessionError && (
        <Alert
          message="Error"
          description={checkoutSessionErrorMsg}
          type="error"
          showIcon
        />
      )}

      <CustomModal
        visible={bulModalSelectUsers}
        onCancel={() => {setBulModalSelectUsers(false)}}
        title={(<>
          <p >Upgrade your team to PREMIUM!</p>
          <p style={{fontSize:"26px", width:'100%',textAlign:'center'}}>Total: {Number(STRIPE_PRICES.STRIPE_PRICES[3].price) * value.length}</p>
          <p style={{fontSize:"15px", width:'100%',textAlign:'center',margin:'0px',padding:'0px',fontWeight:'100'}}>minimum five accounts.</p>
        </>)}
        width={480}
      >
        <>
          <Form form={form} layout="vertical" onFinish={handleOnFinishSelect}>
            <Form.Item
              name="user"
              label="Search user"
              required={[{ required: true }]}
            >
              <Select
                size="large"
                mode="tags"
                onSearch={handleSearchUser}
                notFoundContent={fetching ? <Spin size="small" /> : null}
                options={searchedUsersForCouncilEvent.map(e => {
                  return {label:e?.email,value:e?.email}
                })}
                defaultValue={[userProfile.email]}
                value={value} 
                onChange={(e) => setValue(e)}
              />
            </Form.Item>
            <Form.Item>
              <CustomButton loading={loading} htmlType="submit" text="Add" block />
            </Form.Item>
          </Form>
          </>
        {checkoutSessionError && (
          <Alert
            message="Error"
            description={checkoutSessionErrorMsg}
            type="error"
            showIcon
          />
        )}
      </CustomModal>
    </div>
  );
};

PaymentForm.propTypes = {
  handleSubmit: PropTypes.func,
  hidePanel: PropTypes.func,
};

PaymentForm.defaultProps = {
  handleSubmit: () => {},
  hidePanel: () => {},
};

const mapStateToProps = (state) => ({
  ...councilEventSelector(state),
  isMobile: envSelector(state).isMobile,
  userProfile: homeSelector(state).userProfile,
});

const mapDispatchToProps = {
  ...councilEventActions,
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentForm);