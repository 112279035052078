import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ModalCompleteYourProfile } from "components";

import { CustomInput, CustomButton, CategoriesSelect } from "components";

import { EditOutlined, CheckOutlined } from "@ant-design/icons";
import { Switch } from "antd";
import { categorySelector } from "redux/selectors/categorySelector";

import "./style.scss";

const MenteeProfile = ({
  userProfile,
  mentorProfile,
  menteeProfile,
  createMentee,
  updateMente,
  getOneMentee,
  getUser,
  allCategories,
}) => {
  const [text1, setText1] = useState("");
  const [check1, setCheck1] = useState([]);
  const [bulModal, setBulModal] = useState(false);
  const [onChange, setOnChange] = useState(false);
  const [bulArrarysMentee, setBulArraysMentee] = useState({
    textDescripton: false,
  });

  useEffect(() => {
    if (menteeProfile?.textDescription !== text1) {
      setText1(menteeProfile?.textDescription);
    }
    if (menteeProfile?.topics?.length !== check1?.length) {
      setCheck1(menteeProfile?.topics);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menteeProfile, setText1, setCheck1]);

  return (
    <div className="container-mentor-new">
      <div
        // className="container-buttom"
        style={{ display: "flex", justifyContent: "center" }}
      >
        {/* <CustomButton
          key="PREVIEW PROFILE"
          text="PREVIEW PROFILE"
          type="primary"
          size="xl"
          className="button-modal"
          style={{ padding: "0px 10px" }}
          onClick={() => {
            setBulModal(true);
          }}
        /> */}
        {onChange && (
          <CustomButton
            key="SAVE"
            text="SAVE"
            type="primary"
            size="xl"
            className="button-modal"
            style={{ padding: "0px 10px", marginLeft: "20px" }}
            onClick={() => {
              updateMente(
                {
                  id: userProfile?.id,
                  topics: check1,
                  textDescription: text1,
                },
                () => {
                  getOneMentee();
                }
              );

              setBulArraysMentee({
                textDescripton: false,
              });
              setOnChange(false);
            }}
          />
        )}
      </div>
      <div className="profile-edit-panel-content">
        <p className="textfield-label first-textfield-label">
          Do you want to activate your mentee profile?
        </p>
        <Switch
          checkedChildren="On"
          unCheckedChildren="Off"
          style={{ width: "100px" }}
          checked={
            menteeProfile?.id !== undefined
              ? menteeProfile?.status === "active"
              : false
          }
          onChange={() => {
            if (menteeProfile?.id === undefined) {
              createMentee(
                {
                  idUser: userProfile?.id,
                  status: "active",
                },
                () => {
                  getOneMentee();
                }
              );
            } else {
              if (menteeProfile?.status === "active") {
                updateMente(
                  {
                    id: userProfile?.id,
                    status: "close",
                  },
                  () => {
                    getOneMentee();
                  }
                );
              } else {
                updateMente(
                  {
                    id: userProfile?.id,
                    status: "active",
                  },
                  () => {
                    getOneMentee();
                  }
                );
              }
            }
          }}
        />
        <h5 className="textfield-label">
          Describe your interests as a mentor and the types of the types of
          mentees you want to connect with
          {!bulArrarysMentee["textDescripton"] ? (
            <EditOutlined
              onClick={() => {
                setBulArraysMentee({
                  ...bulArrarysMentee,
                  textDescripton: true,
                });
                setOnChange(true);
              }}
              style={{
                cursor: "pointer",
                fontSize: "17px",
                margin: "0px",
                padding: "0px",
                transform: "translateY(-4px) translateX(4px)",
              }}
            />
          ) : (
            <CheckOutlined
              onClick={() => {
                if (text1 !== menteeProfile?.textDescription) {
                  updateMente(
                    {
                      id: userProfile?.id,
                      textDescription: text1,
                    },
                    () => {
                      getOneMentee();
                      setOnChange(false);
                    }
                  );
                }
                setBulArraysMentee({
                  ...bulArrarysMentee,
                  textDescripton: false,
                });
              }}
              style={{
                cursor: "pointer",
                fontSize: "17px",
                margin: "0px",
                padding: "0px",
                transform: "translateY(-4px) translateX(4px)",
              }}
            />
          )}
        </h5>
        {bulArrarysMentee["textDescripton"] ? (
          <CustomInput
            multiple
            style={{ width: "600px" }}
            className="textfield-input"
            defaultValue={menteeProfile?.textDescription}
            onChange={(value) => setText1(value)}
          />
        ) : (
          <h3 className="textfield-value completed">
            {menteeProfile?.textDescription}
          </h3>
        )}
        <h5 className="textfield-label">
          What areas in HR are you interested in?{" "}
          <strong
            title="Required field"
            style={{ fontSize: "22px", cursor: "help" }}
          >
            *
          </strong>
        </h5>
        <CategoriesSelect
          options={allCategories}
          maxValues={allCategories.length}
          value={check1}
          onChange={(e) => {
            setCheck1(e);
            setOnChange(true);
          }}
          style={{
            width: "100%",
            maxWidth: "600px",
            height: "auto",
            marginTop: "1.1rem",
          }}
        />
      </div>
      {/* <div className="container-buttom">
                <CustomButton
                    key="PREVIEW PROFILE"
                    text="PREVIEW PROFILE"
                    type="primary"
                    size="xl"
                    className="button-modal"
                    style={{ padding: "0px 10px" }}
                    onClick={() => { setBulModal(true) }}
                />
                {onChange && <CustomButton
                    key="SAVE"
                    text="SAVE"
                    type="primary"
                    size="xl"
                    className="button-modal"
                    style={{ padding: "0px 10px", marginLeft: '20px' }}
                    onClick={() => {

                        updateMente({
                            id: userProfile?.id,
                            topics: check1,
                            textDescription: text1
                        }, () => { getOneMentee() })

                        setBulArraysMentee({
                            textDescripton: false
                        })
                        setOnChange(false)
                    }}
                />}
            </div> */}
      {bulModal === true && (
        <div className="complete-profile" onClick={() => setBulModal(false)}>
          <ModalCompleteYourProfile
            userProfile={userProfile}
            get={getUser}
            edit={false}
            caseMentoring={true}
            mentor={mentorProfile?.textDescription}
            mentee={text1}
            onClose={() => setBulModal(false)}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  allCategories: categorySelector(state).categories,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MenteeProfile);
