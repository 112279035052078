import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  // Select,
  // Form,
  Table,
  Avatar,
  Dropdown,
  Menu
} from "antd";
import { notification } from "antd";
import { blogPostSelector } from "redux/selectors/blogPostSelector";
import { useLocation } from "react-router-dom";
import ChatBlogging from "../../containers/ChatBlogging";
import {
  createBlogPost,
  getBlogsPostsByUser,
  updateBlogPost,
  deleteBlogPost,
} from "redux/actions/blog-post-action";

// import clsx from "clsx";
import { INTERNAL_LINKS } from "enum";
import moment from "moment";
// import { Modal, DatePicker } from "antd";
import {
  // CloseCircleOutlined,
  HistoryOutlined,
  FolderOpenOutlined,
  HomeOutlined,
  // FileDoneOutlined,
  PlusOutlined,
  DownloadOutlined,
  // EyeOutlined,
  // EllipsisOutlined,
  InteractionOutlined,
  CloseOutlined,
  MessageOutlined,
  // CheckOutlined,
  // DownOutlined
} from "@ant-design/icons";

// import {
//   CustomInput,
//   // FroalaEdit,
//   InputBlog,
//   ImageUpload,
//   CustomSelect,
// } from "components";

// import { Tabs } from "components";
// import { Link } from "react-router-dom";
import NoItemsMessageCard from "components/NoItemsMessageCard";
import { homeSelector } from "redux/selectors/homeSelector";
import {
  CustomButton,
  CustomModal,
  // BlogCard
} from "components";
import { categorySelector } from "redux/selectors/categorySelector";
import { envSelector } from "redux/selectors/envSelector";
import { notificationEmailToNewContentCreators } from "redux/actions/channel-actions";
import { ReactComponent as IconPencil } from "images/icon-pencil.svg";
import { ReactComponent as IconShowPassword } from "images/icon-show-password.svg";
import FormCreateOrEditBlog from "./FormCreateOrEditBlog";
// import { ReactComponent as IconTrash } from "images/icon-trash.svg";

import "./style.scss";
import { Link } from "react-router-dom/cjs/react-router-dom";

const BlogPostForUser = ({
  // isOwner,
  // isEditor,
  userProfile,
  getBlogsPostsByUser,
  // s3Hash,
  isMobile,
  siderMenuCollapsed,
  blogsPostByUser,
  updateBlogPost,
  deleteBlogPost,
  // limit = "all",
  // buttomEdit
}) => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const [currentTab, setCurrentTab] = useState(query.get("tab") || "0");
  // const [visibleModal, setVisibleModal] = useState(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [editOrDeleteBlogPost, setEditOrDeleteBlogPost] = useState({});
  
  const [functionForm, setFunctionForm] = useState({ function: () => {} });
  const [campsCase, setCampsCase] = useState([]);
  const id = query?.get("id");
  const accepted = query?.get("accepted");
  // const [blogForm] = Form.useForm();
  const [bulAdminBlogging, setBulAdminBlogging] = useState(2);
  const [tabData, setTabData] = useState(<div></div>)
  const [chatChange, setChatChange] = useState({})
  const [collapsed, setCollapsed] = useState(
    isMobile ? siderMenuCollapsed : false
  );
  const functionsModalChat = useRef({});

  useEffect(() => {
    setCollapsed(isMobile ? siderMenuCollapsed : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  const handleCollapse = () => {
    setCollapsed((prev) => !prev);
  };

  useEffect(() => {
    if (isMobile) {
      const buttonCollapse = document.getElementById("main-header-left-menu");

      const handleClick = () => {
        handleCollapse();
      };

      buttonCollapse.addEventListener("click", handleClick);

      return () => {
        buttonCollapse.removeEventListener("click", handleClick);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapsed]);

  useEffect(() => {
    if (id !== null) {
      if (accepted === "true" || accepted === "false") {
        updateBlogPost(
          id,
          { statusRequest: accepted === "true" ? "accepted" : "reject" },
          (error) => {
            if (error) {
              return notification.error({
                message: error,
              });
            }
            getBlogsPostsByUser();

            return notification.success({
              message: "Blog Updated Successfully",
            });
          }
        );
      }
    }
  }, [id, accepted, updateBlogPost, getBlogsPostsByUser]);

  useEffect(() => {
    if (currentTab === "0") {
      setCampsCase([
        {
          title: "Published / Shedule",
          dataIndex: "status",
          key: "status",
          render: (text, d) => {
            return (
              <h5>
                {d.datePublic !== "" && d.status === "draft"
                  ? "Shedule"
                  : "published"}
              </h5>
            );
          },
        },
        {
          title: "Date Shedule",
          dataIndex: "datePublic",
          key: "datePublic",
          render: (text) => (
            <span>
              {moment(text).format("L") === "Invalid date"
                ? "--/--/--"
                : moment(text).format("L")}
            </span>
          ),
        },
      ]);
    }
    if (currentTab === "1") {
      setCampsCase([
        {
          title: "Date Shedule",
          dataIndex: "datePublic",
          key: "datePublic",
          render: (text) => (
            <span>
              {moment(text).format("L") === "Invalid date"
                ? "--/--/--"
                : moment(text).format("L")}
            </span>
          ),
        },
        {
          title: "Status for aprove",
          dataIndex: "statusRequest",
          key: "datePublic",
          render: (text) => (
            <h5>
              {text === "waiting"
                ? "pending"
                : text === "reject"
                ? "rejected"
                : text}
            </h5>
          ),
        },
      ]);
    }
    if (currentTab === "2") {
      setCampsCase([]);
    }
  }, [currentTab]);

  // const onShowBlogPosstModal = () => {
  //   setVisibleModal(true);
  // };

  const onCancelModal = () => {
    setEditOrDeleteBlogPost({});
    // blogForm.resetFields();
    // setVisibleModal(false);
    setVisibleDeleteModal(false);
  };

  const onCloseSidebar = () => {
    if (isMobile) {
      setCollapsed((prev) => !prev);
    }
  };

  // const handleEditOrDelete = (option, blogId) => {
  //   const blogPost = blogsPostByUser.find(
  //     (blogPost) => blogPost?.id === blogId
  //   );

  //   if (!blogPost) return;
  //   setEditOrDeleteBlogPost(blogPost);
  //   blogForm.setFieldsValue(blogPost);
  //   if (option === "edit") {
  //     // return setVisibleModal(true);
  //   }
  //   setVisibleDeleteModal(true);
  // };

  const handleDeleteBlogPost = (blogPostId) => {
    deleteBlogPost(blogPostId, (error) => {
      if (error) {
        return notification.error({
          message: error,
        });
      }
      getBlogsPostsByUser();

      return notification.success({
        message: "Blog Deleted Successfully",
      });
    });

    setVisibleDeleteModal(false);
    setEditOrDeleteBlogPost({});
  };

  const filterBlogPosts = (data, type) => {

    let bulAdmin = (userProfile?.email === "morecontrol64@gmail.com" || userProfile?.email === "enrique@hackinghr.io" || userProfile?.email === "jessa@hackinghr.io" || userProfile?.email === "erubio2013@gmail.com" || userProfile?.email === "barbara@hackinghr.io" || userProfile?.email === "nahema.cossette2702@gmail.com")

    if (type !== "pending") {
      return data?.filter((blogPost) => {
        if (type === "published") {
          return (
            (bulAdmin ? true : blogPost?.UserId === userProfile?.id) &&
            (blogPost?.status === "published" ||
              (blogPost?.status === "draft" && blogPost?.datePublic !== "")) &&
            blogPost.statusRequest === "accepted"
          );
        } else {
          return (
            ((blogPost?.UserId === userProfile?.id) || ((userProfile?.email === "barbara@hackinghr.io" || userProfile?.email === "morecontrol64@gmail.com") && Number(blogPost?.UserId) === 45511)) &&
            blogPost?.status === "draft" &&
            blogPost?.datePublic === ""
          );
        }
      });
    } else {
      return data?.filter((blogPost) => {
        return (
          (bulAdmin ? true : blogPost?.UserId === userProfile?.id) &&
          (blogPost.statusRequest === "pending" ||
            blogPost.statusRequest === "waiting" ||
            blogPost.statusRequest === "reject") &&
          (blogPost?.status !== "draft" ||
            (blogPost?.status === "draft" && blogPost?.datePublic !== ""))
        );
      });
    }
  };

  useEffect(() => {
    getBlogsPostsByUser();
  }, [getBlogsPostsByUser]);

  const changeStatusBlog = (e, idS) => {
    e.preventDefault();
    e.stopPropagation();

    updateBlogPost(idS, { statusRequest: e.target.textContent }, (error) => {
      if (error) {
        return notification.error({
          message: error,
        });
      }
      getBlogsPostsByUser();

      return notification.success({
        message: "Blog Updated Successfully",
      });
    });
  };

  useEffect(() => {

    if(userProfile?.email === "morecontrol64@gmail.com" || userProfile?.email === "enrique@hackinghr.io" || userProfile?.email === "erubio2013@gmail.com" || userProfile?.email === "barbara@hackinghr.io" || userProfile?.email === "nahema.cossette2702@gmail.com"){
      setBulAdminBlogging(0)
      setTimeout(() => {
        setBulAdminBlogging(1);
        setTimeout(() => {
          setBulAdminBlogging(2);
        }, 1000);
      }, 1000);
    }
  }, [userProfile, setBulAdminBlogging]);

  const downloadDropdownOptions = (id) => (
    <Menu style={{ position: "relative", bottom: "70px" }}>
      <Menu.Item key="1">
        <a
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            background: "#00b574",
            color: "white",
            fontWeight: "bold",
          }}
          href="/#"
          onClick={(e) => changeStatusBlog(e, id)}
        >
          accepted
        </a>
      </Menu.Item>
      <Menu.Item key="2">
        <a
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            background: "#e61e47",
            color: "white",
            fontWeight: "bold",
          }}
          href="/#"
          onClick={(e) => changeStatusBlog(e, id)}
        >
          reject
        </a>
      </Menu.Item>
      <Menu.Item key="3">
        <a
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            background: "#ff9f29",
            color: "white",
            fontWeight: "bold",
          }}
          href="/#"
          onClick={(e) => changeStatusBlog(e, id)}
        >
          pending
        </a>
      </Menu.Item>
    </Menu>
  );

  const columns = (dataC) => {
    return [
    {
      title: "Post Code",
      dataIndex: "id",
      key: "id",

      render: (id) => (
        <p
          style={{ fontWeight: 600, fontSize: "15px", minWidth: "maxContent" }}
        >
          {id}
        </p>
      ),
    },
    {
      title: "Thumb",
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (url) => <Avatar shape="square" size={55} src={url} />,
    },
    {
      title: "Headline",
      width: 200,
      dataIndex: "title",
      key: "title",
      render: (text) => <h5>{text}</h5>,
    },
    ...campsCase,
    {
      title: "Manage Content",
      dataIndex: "id",
      key: "manageContent",
      render: (id, data) => (
        <div
          style={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <CustomButton
            style={{
              background: "#F75B27",
              borderColor: "#F75B27",
            }}
            onClick={() => {
              setEditOrDeleteBlogPost(data);
              setCurrentTab("3");
            }}
            type="primary"
            icon={<IconPencil fill="white" />}
            size={"medium"}
          />
          <Link to={`${INTERNAL_LINKS.BLOGS_NEW}/${id}`}>
            <CustomButton
              style={{
                background: "rgb(0, 181, 116)",
                borderColor: "rgb(0, 181, 116)",
                display: "flex",
                alignItems: "center",
              }}
              type="primary"
              icon={<IconShowPassword fill="white" />}
              size={"medium"}
            />
          </Link>

        {((userProfile?.email === "mkeivy.mora@gmail.com" || userProfile?.email === "morecontrol64@gmail.com" || userProfile?.email === "enrique@hackinghr.io" || userProfile?.email === "erubio2013@gmail.com" || userProfile?.email === "barbara@hackinghr.io" || userProfile?.email === "nahema.cossette2702@gmail.com") 
        || data?.chatMessages?.length > 0 || dataC?.[data?.id]) && <div
          style={{
            width:'auto',
            height:'auto',
            position:'relative',
          }}
        >
          {(dataC?.[data?.id]) && <div style={{
            position:'absolute',
            display:'flex',
            borderRadius:'100%',
            background:'red',
            width:'15px',
            height:'15px',
            top:'-7.5px',
            right:'-7.5px',
            zIndex:'10'
          }}></div>}
          <CustomButton
            style={{
              background: "rgb(0, 181, 116)",
              borderColor: "rgb(0, 181, 116)",
              display: "flex",
              alignItems: "center",
            }}
            type="primary"
            icon={<MessageOutlined style={{fontSize:'25px'}}/>}
            size={"medium"}
            onClick={() => {
              if(typeof (functionsModalChat.current || {}).active === 'function'){
                functionsModalChat.current.active(data);
              }
            }}
          />
        </div>}

        {((userProfile?.email === "mkeivy.mora@gmail.com" || userProfile?.email === "morecontrol64@gmail.com" || userProfile?.email === "enrique@hackinghr.io" || userProfile?.email === "erubio2013@gmail.com" || userProfile?.email === "barbara@hackinghr.io" || userProfile?.email === "nahema.cossette2702@gmail.com") && currentTab !== 2) && ( 
          <Dropdown overlay={() => downloadDropdownOptions(id)}>

              <a
                href="/#"
                style={{
                  width: "32px",
                  height: "32px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#0080DC",
                  borderColor: "#0080DC",
                  borderRadius: "5px",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <InteractionOutlined
                  style={{ fontSize: "24px", color: "white" }}
                />
              </a>
            </Dropdown>
          )}
        </div>
      ),
    },
  ]};

  useEffect(() => {
    let chatChangeo = {};
    blogsPostByUser.forEach(blogPost => {
      if(!blogPost?.chatMessages?.[0]?.seen?.includes(userProfile?.id) && blogPost?.chatMessages?.[0]){
        chatChangeo = {
          ...chatChangeo,
          [blogPost?.id]: true
        };
      }
    })
    setChatChange(chatChangeo)
  },[blogsPostByUser])

  const TabData = (currentTab, chang) => {
    let object = [
      {
        content: (data) => (
          <>
            <Table
              columns={columns(data)}
              dataSource={filterBlogPosts(blogsPostByUser, "published")}
              pagination={{ position: ["bottomCenter"] }}
            />
          </>
        ),
      },
      {
        content: (data) => {
          return (
          <>
            <Table
              columns={columns(data)}
              dataSource={filterBlogPosts(blogsPostByUser, "pending")}
              pagination={{ position: ["bottomCenter"] }}
            />
          </>
        )},
      },
      {
        content: (data) => (
          <>
            <Table
              columns={columns(data)}
              dataSource={filterBlogPosts(blogsPostByUser, "draft")}
              pagination={{ position: ["bottomCenter"] }}
            />
          </>
        ),
      },
      {
        content: () => (
          <FormCreateOrEditBlog
            getBlogsPostsByUser={getBlogsPostsByUser}
            editOrDeleteBlogPost={editOrDeleteBlogPost}
            setEditOrDeleteBlogPost={(data) => setEditOrDeleteBlogPost(data)}
            setCurrentTab={(data) => setCurrentTab(data)}
            resetData={(data) => {
              setFunctionForm(data);
            }}
          />
        ),
      },
    ]

    return object[currentTab].content(chang)
  };

  useEffect(() => {
    setTabData(TabData(currentTab,chatChange))
  },[chatChange,currentTab])

  return (
    <div className="blog-post__list-wrap" style={{ background: "white" }}>
      <>
        <ChatBlogging 
          userProfile={userProfile} 
          setConversation={(data) => functionsModalChat.current = data} 
          lastMessageSaw={(_, idB) => {
            setChatChange({
              ...chatChange,
              [idB]: true
            })
          }}
          removeMessageSaw={(idD) => {
            let newChatChange = {...chatChange};
            delete newChatChange[idD]
            setChatChange(newChatChange);
          }}
        />
        {(!userProfile.acceptedForBlogPostUser && userProfile.email !== "jessa@hackinghr.io") ? (
          <NoItemsMessageCard
            message={`you need permissions for this section`}
          />
        ) : (
          <>
            <div
              className={`container-sidebar 
              ${isMobile && collapsed ? "sidebar-collapsed" : ""}
              `}
            >
              <Link to={INTERNAL_LINKS.HOME}>
                <div className="container-logo"></div>
              </Link>
              {isMobile && (
                <div className="layout-sidebar-close">
                  <CloseOutlined onClick={onCloseSidebar} />
                </div>
              )}

              <div className="container-tabs">
                <div
                  className={
                    currentTab === "0"
                      ? "container-iconsps"
                      : "container-iconsp"
                  }
                  onClick={() => {
                    setCurrentTab("0");
                    onCloseSidebar();
                  }}
                >
                  <HomeOutlined style={{ margin: "0px", marginRight: "5px" }} />{" "}
                  Published
                </div>
                <div
                  className={
                    currentTab === "1"
                      ? "container-iconsps"
                      : "container-iconsp"
                  }
                  onClick={() => {
                    setCurrentTab("1");
                    onCloseSidebar();
                  }}
                >
                  <HistoryOutlined
                    style={{ margin: "0px", marginRight: "5px" }}
                  />{" "}
                  Pending
                </div>
                <div
                  className={
                    currentTab === "2"
                      ? "container-iconsps"
                      : "container-iconsp"
                  }
                  onClick={() => {
                    setCurrentTab("2");
                    onCloseSidebar();
                  }}
                >
                  <FolderOpenOutlined
                    style={{ margin: "0px", marginRight: "5px" }}
                  />{" "}
                  Draft
                </div>
                <div
                  className="container-iconspsb"
                  onClick={() => {
                    setCurrentTab("3");
                    localStorage.setItem("contentZ", "[]");
                    functionForm.function();
                    onCloseSidebar();
                  }}
                >
                  <PlusOutlined style={{ margin: "0px", marginRight: "5px" }} />{" "}
                  Add Blog Posts
                </div>
                <div className="container-iconspsb" style={{fontSize:'14px'}}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const link = document.createElement('a');
                    link.href = 'https://hackinghr-lab-assets.s3.amazonaws.com/pdfs/Tutorial+Guide+For+Hacking+HR+Contributors+(2).pdf';
                    link.download = 'Tutorial Guide For Hacking HR Contributors.pdf';
                    link.setAttribute('target', '_blank');

                    // Añadir el enlace al documento y hacer clic en él
                    document.body.appendChild(link);
                    link.click();

                    // Eliminar el enlace del documento
                    document.body.removeChild(link);
                  }}
                >
                  <DownloadOutlined style={{ margin: "0px", marginRight: "5px" }} />{" "}
                  How to upload content
                </div>
              </div>
            </div>
            <div
              className="container-tables"
              id="container-scroll"
              style={{
                marginLeft: isMobile ? "0px" : "250px",
                width: isMobile ? "100%" : "calc(100% - 250px)",
              }}
            >
              <div style={{ height: "auto", width: "100%" }}>
                {tabData}
              </div>
              <div id="container-see-finish"></div>
            </div>
            <div className="container-modals-image">
              <div className="container-distribution"></div>
            </div>
          </>
        )}
      </>
      <div
        className="container-modal-background"
        style={
          bulAdminBlogging === 2
            ? { display: "none" }
            : bulAdminBlogging === 0
            ? { opacity: "1" }
            : { opacity: "0" }
        }
      >
        <div className="container-modal-message">
          <p>Welcome Admin!!</p>
        </div>
      </div>
      <CustomModal
          visible={visibleDeleteModal}
          title={`Are you sure to delete this blog?`}
          subTitle="this option is not reversible"
          onCancel={() => setVisibleDeleteModal(false)}
          width={500}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CustomButton
              type="primary outlined"
              text="No"
              onClick={() => onCancelModal()}
            />
            <CustomButton
              type="primary"
              text="Yes"
              style={{ marginLeft: "5px" }}
              onClick={() => handleDeleteBlogPost(editOrDeleteBlogPost?.id)}
            />
          </div>
        </CustomModal>
    </div>
  );
};

BlogPostForUser.propTypes = {
  blogPosts: PropTypes.array,
  filter: PropTypes.object,
};

BlogPostForUser.defaultProps = {
  blogPosts: [],
  filter: {},
};

const mapStateToProps = (state) => ({
  blogsPostByUser: blogPostSelector(state).blogsPostByUser,
  userProfile: homeSelector(state).userProfile,
  allCategories: categorySelector(state).categories,
  s3Hash: envSelector(state).s3Hash,
  isMobile: envSelector(state).isMobile,
  siderMenuCollapsed: envSelector(state).siderMenuCollapsed,
});

const mapDispatchToProps = {
  createBlogPost,
  updateBlogPost,
  deleteBlogPost,
  notificationEmailToNewContentCreators,
  getBlogsPostsByUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(BlogPostForUser);
