import React, { useState } from "react";
import { Avatar, Divider } from "antd";
// import Image from "next/image";
import linkedInIcon from "images/icon-linkedin.svg";
import IconMail from "images/icon-mail-outline.svg";

// import { useRouter } from "next/router";
import "./style.scss";
import PopupSpeakerSummit from "../PopupInstructor";

const CardUser = ({ instructor, title, btnClass = true }) => {
  const [openModal, setOpenModal] = useState(false);
  const [userSelected, setUserSelected] = useState({});
  // const { query } = useRouter();
  // const { name: slug } = query;

  const handleModalShowUser = async (visible = false, speaker = {}) => {
    setOpenModal(visible);
    setUserSelected(speaker);
  };

  return (
    <div className={"card-user"}>
      {/* <h2 className={"title"}>{title}</h2> */}
      <Avatar src={instructor?.img || ""} className="avatar"></Avatar>
      <Divider className={"divider"} />
      <h3 className={"name"}>{`${instructor?.firstName || ""} ${
        instructor?.lastName || ""
      }`}</h3>
      <p style={{ fontSize: "15px" }}>{instructor?.titleProfessions}</p>
      <p className={"text-content"}>{/* Co-Founder Hacking HR */}</p>

      <div
        className={"container"}
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <a
          href={instructor?.linkedin || ""}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className={"icon-linkedin"} src={linkedInIcon} alt="linkedin" />
        </a>
        <a href={`mailto:${instructor?.email || ""}`}>
          <img
            src={IconMail}
            alt="email"
            style={{
              width: "2.4rem",
            }}
          />
        </a>
        {btnClass && (
          <p
            className={"classes-btn"}
            onClick={() => handleModalShowUser(true, instructor)}
          >
            Class
          </p>
        )}
      </div>
      {btnClass && (
        <>
          <PopupSpeakerSummit
            updatedEvent={{}}
            open={openModal}
            funModal={handleModalShowUser}
            speaker={userSelected}
            speakerId={userSelected?.id}
            // certificateSlug={slug} //only for classes of the certificate that is now
            certificateSlug={undefined}
          />
        </>
      )}
    </div>
  );
};

export default CardUser;
