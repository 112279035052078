import React, { useState } from "react";
import "./style.scss";
import CollapseArrow from "../../../images/collapsearrow.svg"
import { CheckOutlined } from "@ant-design/icons";

const HeaderCollapseMap = ({data, pagesVisitedMap, inPage, onClick = () => {}}) => {

    const [expandArrowAnimation, setExpandArrowAnimation] = useState(true)
 
    return (
        <div id="header-collapse" style={{display:'inline-flex'}} className={"container-header-collapse-crashcourse"} onClick={(e) => {
            if(data?.type === "completed"){
                e.preventDefault();
                e.stopPropagation();
                onClick();
            }else{
                if(e.target.id === "header-collapse" ||
                e.target.parentElement.id === "header-collapse" ||
                e.target.parentElement.parentElement.id){
                    onClick();
                }
                setExpandArrowAnimation(!expandArrowAnimation)   
            }
            
        }} >
            <div className="truc-shadow">
                {<div className="check-map" style={!pagesVisitedMap ? {opacity:'0',transform:"translateY(0px)"} : {opacity:'1', transform:"translateY(0px)"}}>
                    <CheckOutlined style={{color:"white",fontSize:'8px'}}/>
                </div>}
                <p className="p-collap-certificate" style={{direction: "ltr", textAlign:'start'}}>{data?.type === "completed" ? 'Certificate of Completion' : data?.title}</p> 
                <img src={CollapseArrow} width={20} height={20} style={expandArrowAnimation ? {transform:'rotate(-90deg)', transition:'all 0.4s ease-in-out', padding:'0px'}:{transform:'rotate(0deg)',transition:'all 0.4s ease-in-out',padding:'0px'}} alt="arrow-colapse" />
                <div style={(inPage && !pagesVisitedMap) ? {opacity:'1',transform:"translateY(0px)"} : {opacity:'0',transform:"translateY(0px)"}} className="circul-map"></div>
            </div>
            <div className="container-border-card-shadow"></div>
            <div className="container-border-card"></div>
        </div>
    );
};


export default HeaderCollapseMap;
