import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { 
  // Link,
   useLocation, useHistory } from "react-router-dom";

import { Col, Input, Modal, Row, Space } from "antd";

import { homeSelector } from "redux/selectors/homeSelector";
import { speakerAllPanelSpeakerSelector } from "redux/selectors/speakerSelector";
import { actions as homeActions } from "redux/actions/home-actions";
import { getAllParafs } from "redux/actions/speaker-actions";
import {
  setConversations,
  setCurrentConversations,
} from "redux/actions/conversation-actions";
import Emitter from "services/emitter";

// import BusinessPartnerList from "./BusinessPartnerList";
import BusinessPartnerMembers from "./BusinessPartnerMembers";

// import FilterDrawer from "pages/Library/FilterDrawer";
// import BusinessPartnerDocuments from "containers/BusinessPartnerDocuments";

import { CustomButton, 
  // LibraryFilterPanel, 
  Tabs } from "components";

// import NoItemsMessageCard from "components/NoItemsMessageCard";

import { EVENT_TYPES, INTERNAL_LINKS } from "enum";

// import IconBack from "images/icon-back.svg";

import "./style.scss";

const BusinessPartnerPage = ({
  userProfile,
  confirmApply,
  getUser,
  acceptApply,
  getAllParafs,
  allParrafs,
  ...rest
}) => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const history = useHistory();

  const [currentTab, setCurrentTab] = useState(query.get("tab") || "0");
  const accepted = query.get("accepted");
  const id = query.get("id");
  // const [, setFilter] = useState({});

  const [visibleConfirmApply, setVisibleConfirmApply] = useState(false);
  const [showPremiumFirewall, setShowPremiumFirewall] = useState(false);
  const [showProfileCompletionFirewall, setShowProfileCompletionFirewall] =
    useState(false);

  useEffect(() => {
    getAllParafs("HRBPs");
  }, [getAllParafs]);

  const handleTabChange = (tab) => {
    history.replace({
      pathname: window.location.pathname,
      search: `tab=${tab}`,
    });
    setCurrentTab(tab);
  };

  const showFilterPanel = () => {
    Emitter.emit(EVENT_TYPES.OPEN_FILTER_PANEL);
  };

  useEffect(() => {
    if (accepted != null && id)
      confirmApply(id, accepted === "true" ? true : false);
    history.push("business-partner");
  }, [id, confirmApply, accepted, getUser, history]);

  // const onFilterChange = (values) => {
  //   setFilter(values);
  // };
  const TabData = [
    {
      title: "Members",
      content: () => <BusinessPartnerMembers />,
    },
    // {
    //   title: "Relevant Content",
    //   content: () => (
    //     <BusinessPartnerList
    //       type="article"
    //       refresh={currentTab === "0"}
    //       setCurrentValue={setCurrentTab}
    //       // filter={filter}
    //       currentTab={currentTab}
    //     />
    //   ),
    // },
    // {
    //   title: "Download/Upload Resources",
    //   content: () => <BusinessPartnerDocuments currentTab={currentTab} />,
    // },
    // {
    //   title: "Projects",
    //   content: () => (
    //     <div className="cooming-soon-container">
    //       <h3>Coming Soon</h3>
    //     </div>
    //   ),
    // },
    // {
    //   title: "Roundtable",
    //   content: () => (
    //     <div className="cooming-soon-container">
    //       <h3>Coming Soon</h3>
    //     </div>
    //   ),
    // },
  ];

  const onApplyBusinessPartner = () => {
    if (
      userProfile.percentOfCompletion === 100 &&
      userProfile.isBusinessPartner === "reject"
    ) {
      setVisibleConfirmApply(true);
    } else {
      setShowProfileCompletionFirewall(true);
    }
    if (
      userProfile.isBusinessPartner === "accepted" &&
      userProfile.memberShip !== "premium"
    ) {
      setShowPremiumFirewall(true);
    }
    if (
      userProfile.isBusinessPartner === "accepted" &&
      userProfile.memberShip === "premium"
    ) {
      history.push(INTERNAL_LINKS.BUSINESS_PARTNER);
    }
  };

  const completeProfile = () => {
    Emitter.emit(EVENT_TYPES.EVENT_VIEW_PROFILE);
  };

  const displayGeneralInformation = () => {
    return (
      <div className="display-general-information">
        <div className="information">
          <Space direction="vertical" size="large">
            <div className="container-parraf" style={{ marginTop: "30px" }}>
              {allParrafs.map((parraf, index) => {
                return (
                  <div
                    key={index}
                    dangerouslySetInnerHTML={{ __html: parraf.text }}
                  ></div>
                );
              })}
            </div>
          </Space>
          <div style={{ display: "flex", justifyContent: "end" }}>
            {userProfile.isBusinessPartner === "pending" ? (
              <CustomButton text="Pending" type="primary" size="lg" disabled />
            ) : (
              <CustomButton
                text="Apply Now"
                type="primary"
                size="lg"
                onClick={onApplyBusinessPartner}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  let applyState;

  const handleChange = (value) => {
    applyState = value;
  };

  const onApplyBusness = () => {
    acceptApply({ userId: userProfile.id, applyState });
  };

  return (
    <>
      {userProfile.isBusinessPartner === "accepted" ? (
        <div className="businessPartner-page">
          {/* <div className="container-space-left"></div> */}
          {/* <LibraryFilterPanel
            onChange={onFilterChange}
            currentTab={currentTab}
            isBusiness={true}
          />
          <FilterDrawer onChange={onFilterChange} /> */}
          <div className="search-results-container">
            <Row>
              <Col span={24} style={{ display: "none" }}>
                <div className="search-results-container-mobile-header">
                  <h3 className="filters-btn" onClick={showFilterPanel}>
                    Filters
                  </h3>
                  {/* <h3>{`${numberWithCommas(countOfResults)} result${
                countOfResults > 1 ? "s" : ""
              }`}</h3> */}
                </div>
              </Col>
            </Row>
            <div className="businessPartner-page__container">
              <div className="businessPartner-page__results">
                <div className="businessPartner-page__row">
                  <div className="businessPartner-page__info-column"></div>
                  <div className="businessPartner-page__content">
                    {/* <Link to={INTERNAL_LINKS.HOME}>
                      <div className="businessPartner-page__content-top">
                        <div className="businessPartner-page__content-top-back">
                          <img src={IconBack} alt="icon-back" />
                        </div>
                        <h4>Back</h4>
                      </div>
                    </Link> */}
                    <Tabs
                      data={TabData}
                      current={currentTab}
                      onChange={handleTabChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="container-space-right"></div> */}
        </div>
      ) : (
        // <div className="businessPartner-page__list-wrap">
        //   <NoItemsMessageCard
        //     message={
        //       userProfile.isBusinessPartner === 'accepted'
        //         ? "You must be a premium member to see this view"
        //         : `You must be a business partner and premium member to see this view.`
        //     }
        //   />
        // </div>
        <div style={{ height: "90vh", overflow: "scroll" }}>
          {displayGeneralInformation()}
        </div>
      )}
      <div className="profile-popover-content-menu">
        {userProfile.percentOfCompletion === 100 ? (
          <Modal
            visible={visibleConfirmApply}
            title="Application to the HR Business Partner Community"
            width={500}
            onCancel={() => setVisibleConfirmApply(false)}
            onOk={() => {
              onApplyBusness();
              setVisibleConfirmApply(false);
            }}
            okText="Confirm"
          >
            <p>
              Your application will be sent to Hacking HR when you click on
              confirm. Before confirming, please make sure your profile reflects
              your current title, company and other relevant information. We use
              the information in your profile to determine your participation in
              the Hacking HR's HR Business Partner Community. You will be
              notified within the next 48 hours.
            </p>
            <h5 className="business-partner-title">
              If you don't have the "HR Business Partner Title":
            </h5>
            <p>
              Please let us know here if you don't have the "official" title of
              HR Business Partner but still perform the high-level, strategic
              functions of an HR Business Partners. We will consider your
              application as well:
            </p>
            <Input.TextArea
              {...rest}
              rows={4}
              // className={clsx("custom-input", className, "mutiple", size)}
              onChange={(e) => handleChange(e.target.value)}
            />
          </Modal>
        ) : (
          <>
            {showProfileCompletionFirewall && (
              <div
                className="skill-cohort-firewall"
                onClick={() => setShowProfileCompletionFirewall(false)}
              >
                <div
                  className="upgrade-notification-panel"
                  onClick={completeProfile}
                >
                  <h3>
                    You must fully complete your profile before applying for the
                    HR Business Partners Community.
                  </h3>
                </div>
              </div>
            )}
          </>
        )}
        <>
          {showPremiumFirewall && (
            <div
              className="skill-cohort-firewall"
              onClick={() => setShowPremiumFirewall(false)}
            >
              <div className="upgrade-notification-panel">
                <h3>
                  You must be a premium member to see the business member
                  community page.
                </h3>
              </div>
            </div>
          )}
        </>
      </div>
    </>
  );
};

const mapStateToProps = (state, props) => ({
  userProfile: homeSelector(state).userProfile,
  allParrafs: speakerAllPanelSpeakerSelector(state).allParrafs,
});

const mapDispatchToProps = {
  confirmApply: homeActions.confirmInvitationApply,
  acceptApply: homeActions.acceptInvitationApply,
  getAllParafs,
  setConversations,
  setCurrentConversations,
  ...homeActions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessPartnerPage);
