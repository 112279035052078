import React, { Component } from "react";
import { connect } from "react-redux";
import { CustomButton, CustomInput } from "components";
import clsx from "clsx";
import { Modal } from 'antd'
import IconLogo from "images/logo-sidebar.svg";
import { CloseCircleFilled } from "@ant-design/icons";
import { homeSelector } from "redux/selectors/homeSelector";

import IconChevronDown from "images/icon-chevron-down.svg";

import { sendEmail } from "api/module/feedback";
import { authSelector } from "redux/selectors/authSelector";

import {
  verifySuscribedUser,
  suscriptionSendingBlue
} from "redux/actions/event-actions";

import "./style.scss";

class FeedbackBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      message: "",
      loadingMessage: "Sending...",
      heightFeedbackBox: "40px",
      rotateArrow: "rotate(0deg)",
      bulModal: false,
      bulModal2: false
    };
  }
  onChangeMessage(text) {
    this.setState({ message: text });
  }
  onSend() {
    this.setState(
      {
        loading: true,
      },
      async () => {
        let data = {
          message: this.state.message,
        };
        let result = await sendEmail(data);
        if (result.status === 200) {
          this.props.verifySuscribedUser((data) => {
            if (data) {
              this.setState({
                bulModal: true
              })
            }
          })
          this.setState(
            {
              message: "",
              loadingMessage: "Thank you!",
            },
            () => {
              setTimeout(() => {
                this.setState({
                  loading: false,
                  loadingMessage: "Sending...",
                });
              }, 500);
            }
          );
        }
      }
    );
  }
  changeModal() {
    this.setState({
      bulModal2: !this.state.bulModal2
    });
  }
  changeHeightFeedbackBox() {
    if (this.state.heightFeedbackBox === "auto") {
      this.setState({
        heightFeedbackBox: "40px",
        rotateArrow: "rotate(180deg)",
      });
    } else {
      this.setState({ heightFeedbackBox: "auto", rotateArrow: "rotate(0deg)" });
    }
  }

  render() {
    return (
      <>
        <div className={this.state.bulModal2 ? "container-chatbot-hr" : "container-chatbot-hr-none"}>
          <iframe
            src="https://www.chatbase.co/chatbot-iframe/3DAkzapGFIqV9ed5IlYtG"
            title="Chatbot"
            width="100%"
            style={{height: "100%", minHeight: "600px"}}
            frameborder="0"
          ></iframe>
        </div>
        {this.props.userId ? (<>
          {(this.props?.userProfile?.email === "enrique@hackinghr.io" ||
            this.props?.userProfile?.email === "morecontrol64@gmail.com" ||
            this.props?.userProfile?.email === "lourencelinao13+test@gmail.com" ||
            this.props?.userProfile?.email === "barbara@hackinghr.io"
          ) && <div className="container-chatbot" onClick={() => this.changeModal()}>
            <svg style={{color:'white', fontSize:'30px'}} viewBox="64 64 896 896" focusable="false" data-icon="wechat" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M690.1 377.4c5.9 0 11.8.2 17.6.5-24.4-128.7-158.3-227.1-319.9-227.1C209 150.8 64 271.4 64 420.2c0 81.1 43.6 154.2 111.9 203.6a21.5 21.5 0 019.1 17.6c0 2.4-.5 4.6-1.1 6.9-5.5 20.3-14.2 52.8-14.6 54.3-.7 2.6-1.7 5.2-1.7 7.9 0 5.9 4.8 10.8 10.8 10.8 2.3 0 4.2-.9 6.2-2l70.9-40.9c5.3-3.1 11-5 17.2-5 3.2 0 6.4.5 9.5 1.4 33.1 9.5 68.8 14.8 105.7 14.8 6 0 11.9-.1 17.8-.4-7.1-21-10.9-43.1-10.9-66 0-135.8 132.2-245.8 295.3-245.8zm-194.3-86.5c23.8 0 43.2 19.3 43.2 43.1s-19.3 43.1-43.2 43.1c-23.8 0-43.2-19.3-43.2-43.1s19.4-43.1 43.2-43.1zm-215.9 86.2c-23.8 0-43.2-19.3-43.2-43.1s19.3-43.1 43.2-43.1 43.2 19.3 43.2 43.1-19.4 43.1-43.2 43.1zm586.8 415.6c56.9-41.2 93.2-102 93.2-169.7 0-124-120.8-224.5-269.9-224.5-149 0-269.9 100.5-269.9 224.5S540.9 847.5 690 847.5c30.8 0 60.6-4.4 88.1-12.3 2.6-.8 5.2-1.2 7.9-1.2 5.2 0 9.9 1.6 14.3 4.1l59.1 34c1.7 1 3.3 1.7 5.2 1.7a9 9 0 006.4-2.6 9 9 0 002.6-6.4c0-2.2-.9-4.4-1.4-6.6-.3-1.2-7.6-28.3-12.2-45.3-.5-1.9-.9-3.8-.9-5.7.1-5.9 3.1-11.2 7.6-14.5zM600.2 587.2c-19.9 0-36-16.1-36-35.9 0-19.8 16.1-35.9 36-35.9s36 16.1 36 35.9c0 19.8-16.2 35.9-36 35.9zm179.9 0c-19.9 0-36-16.1-36-35.9 0-19.8 16.1-35.9 36-35.9s36 16.1 36 35.9a36.08 36.08 0 01-36 35.9z"></path></svg>
          </div>}
          <div
            className="feedback-box-container"
            style={{ height: this.state.heightFeedbackBox }}
          >
            <div
              className="feedback-box-container__header"
              onClick={() => {
                this.changeHeightFeedbackBox();
              }}
            >
              <h4>
                <img
                  src={IconChevronDown}
                  style={{ transform: this.state.rotateArrow }}
                  alt="icon-chevron"
                />{" "}
                Please Help Us Improve The LAB
              </h4>
            </div>
            <div className="feedback-box-container__container">
              <CustomInput
                className="feedback-box-container__container--textarea"
                multiple={true}
                value={this.state.message}
                onChange={(text) => {
                  this.onChangeMessage(text);
                }}
              ></CustomInput>
            </div>
            <div className="feedback-box-container__button">
              {this.state.loading ? (
                this.state.loadingMessage
              ) : (
                <CustomButton
                  text="Send"
                  type="primary"
                  size="sm"
                  className=""
                  onClick={() => {
                    this.onSend();
                  }}
                ></CustomButton>
              )}
            </div>
            <Modal
              className={clsx("custom-modal")}
              wrapClassName={clsx("custom-modal-wrap")}
              title={
                <div className="custom-modal-title">
                  <h3>Thank you for your inquiry or feedback.</h3>
                  <div className="custom-modal-logo">
                    <img src={IconLogo} alt="custom-logo" />
                  </div>
                </div>
              }
              centered
              onCancel={() => this.setState({ bulModal: false })}
              visible={this.state.bulModal}
              closable={true}
              footer={[]}
              width={"300px"}
              closeIcon={<CloseCircleFilled className="custom-modal-close" />}
            >
              <p>Please keep in mind that you must be subscribed to our email list to get further information in response to your inquiry or feedback (if applicable).</p>
              <div className="container-buttons">
                <CustomButton
                  key="Cancel"
                  text="Cancel"
                  type="third"
                  size="xs"
                  className="button-modal"
                  style={{ padding: "0px 10px", marginLeft: "10px" }}
                  onClick={() => { this.setState({ bulModal: false }) }}
                />
                <CustomButton
                  key="Subscribe to email list"
                  text="Subscribe to email list"
                  type="primary"
                  size="xs"
                  className="button-modal"
                  style={{ padding: "0px 10px", marginLeft: "10px" }}
                  onClick={() => {
                    this.props.suscriptionSendingBlue(() => {
                      this.setState({ bulModal: false })
                    })
                  }}
                />
              </div>
            </Modal>
          </div>
        </>) : null}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  userId: authSelector(state).id,
  userProfile: homeSelector(state).userProfile,
});

const mapDispatchToProps = {
  verifySuscribedUser,
  suscriptionSendingBlue
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackBox);
