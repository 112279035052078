export default [
    {
        label: "Admin Tools",
        value: 7,
    },
    {
        label: "Followers",
        value: 6,
    },
    {
        label: "Blogs",
        value: 5,
    },
    {
        label: "Events",
        value: 4,
    },
    {
        label: "Videos",
        value: 3,
    },
    {
        label: "Podcasts",
        value: 2,
    },
    {
        label: "Resources",
        value: 1,
    },
    {
        label: "Home",
        value: 0,
    },
  ];