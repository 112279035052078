import moment from "moment/moment";
import React, { useEffect, useRef, useState } from "react";
import IconLogo from "../../../images/logo-sidebar.svg";

export default function ChatMessage({ index, date, sender, senderName, createdAt, content, header, rigth }) {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const formattedCreatedAt = moment(date).format("LL");
  const formattedDateToday = moment().format("LL");

  const isToday = formattedDateToday === formattedCreatedAt;
  const headerStyle = !header 
    ? {paddingTop:'10px', paddingBottom: '8px'} 
    : {paddingTop:'0px', paddingBottom: '8px'}

  let isReveserse = rigth 
    ? {flexDirection: 'row-reverse'} 
    : {flexDirection: 'row'}

  let box = rigth
    ? {background:'rgba(247,91,39,0.09)'}
    : {background:'rgba(26,39,67,0.09)'}

  return (
    <div>
      {index === 0 && <div style={{
        display: "flex",
        justifyContent: "center",
        marginBottom: "1rem",
      }}>
        {isToday ? "Today" : formattedCreatedAt}
      </div>}
      <div style={{ display: "flex", gap: "10px", alignItems: "start", ...headerStyle,...isReveserse }}>
        {!header ? (<>
          {sender.role === 'admin' ? (
            <img
              src={IconLogo}
              alt={senderName}
              style={{ borderRadius: "9999px", width: "32px", height: "32px" }}
            />
          ) : (
            <img
              src={sender.img}
              alt={senderName}
              style={{ borderRadius: "9999px", width: "32px", height: "32px" }}
            />
          )}
        </>) : <div style={{ borderRadius: "9999px", width: "35px", height: "auto",minHeight:'2px',minWidth:'35px',maxWidth:'35px' }}></div>}
        <div style={{ width: "auto",padding:'5px',borderRadius:'10px',...box }}>
          {!header && <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              width: "100%",
              flex:'1',
              paddingLeft:'10px',
              paddingRight:'10px'
            }}
          >
            <h4>{sender.role === 'admin' ? "Hacking HR Team" : senderName}</h4>
            <div>{moment(createdAt).format("HH:mm")}</div>
          </div>}
          <div
            style={{
              width: "100%",
              display: "flex",
              gap: "10px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div style={{ width: "100%" }} className="message-container-data" dangerouslySetInnerHTML={{__html:content?.replaceAll('margin-top:20px !important;','margin-top:20px;')?.replaceAll('margin-bottom:20px !important;','margin-bottom:20px;')}}></div>
          </div>
        </div>
      </div>
    </div>
  );
}