import React from "react";

const IconMicrophone = ({color = 'black', width = '24px', height = '24px'}) => {
  return (
    <div style={{width:width,height:height}}>
        <svg viewBox="-10 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M60 0C43.4375 0 30 13.4375 30 30V80C30 96.5625 43.4375 110 60 110C76.5625 110 90 96.5625 90 80V30C90 13.4375 76.5625 0 60 0ZM20 67.5C20 63.3437 16.6563 60 12.5 60C8.34375 60 5 63.3437 5 67.5V80C5 107.844 25.6875 130.844 52.5 134.5V145H37.5C33.3437 145 30 148.344 30 152.5C30 156.656 33.3437 160 37.5 160H60H82.5C86.6563 160 90 156.656 90 152.5C90 148.344 86.6563 145 82.5 145H67.5V134.5C94.3125 130.844 115 107.844 115 80V67.5C115 63.3437 111.656 60 107.5 60C103.344 60 100 63.3437 100 67.5V80C100 102.094 82.0937 120 60 120C37.9063 120 20 102.094 20 80V67.5Z" fill={color}/>
        </svg>
    </div>
  );
};

export default IconMicrophone;
