import { useMutation } from "@tanstack/react-query";
import httpClientCron from "../../../api/module/httpClientCron";

const createComment = async ({ certificateClassId, data }) => {
  const response = await httpClientCron.post(
    `private/certificate-class/${certificateClassId}/comment`,
    data
  );

  return response.data;
};

export const useCreateCertificateComment = ({
  onSuccess,
  onMutate,
  onError,
  onSettled,
}) => {
  return useMutation({
    mutationFn: createComment,
    onSuccess,
    onMutate,
    onError,
    onSettled,
  });
};
