import React from "react";
import { Card, Avatar, Tooltip, Button } from "antd";

import { LinkedinFilled } from "@ant-design/icons";

import { connect } from "react-redux";
import { homeSelector } from "redux/selectors/homeSelector";
import { CustomButton, SpecialtyItem } from "components";

import "./style.scss";

const CouncilParticipantCard = ({ participant, 
  // marketplaceProfile 
}) => {
  return (
    <>
      <div
        // className="council-participant-container"
        style={{
          display: "flex",
          alignItems: "center",
          gap: 10,
          border: "none",
        }}
      >
        {participant.img ? (
          <Avatar
            size={65}
            src={participant.img}
            style={{ width: 65, minWidth: "65px" }}
          />
        ) : (
          <Avatar size={65} style={{ fontSize: "1.8rem", minWidth: "65px" }}>
            {participant.abbrName}
          </Avatar>
        )}

        <div>
          <p
            style={{
              fontWeight: 450,
              fontSize: "23px",
              margin: 0,
              wordWrap: "break-word",
              lineHeight: 1,
              marginBottom: "5px",
            }}
          >
            {participant.firstName} {participant.lastName}
          </p>
          {participant.lookingFor ? (
            <div style={{ margin: 0 }}>
              <span className="participant-card-marketplaceprofile--title">
                Looking For:
              </span>
              {participant.lookingFor.map((profesion, i) => (
                <SpecialtyItem key={i} title={profesion} />
              ))}
            </div>
          ) : (
            <p style={{ margin: 0, fontSize: "16px" }}>
              {participant.titleProfessions}
            </p>
          )}
          {/* <p style={{ margin: 0 }}>{participant.company}</p> */}
          {/* <p
            style={{ margin: 0, fontSize: "11px" }}
          >{`${participant.city}, ${participant.location}`}</p> */}

          {/* {participant.location && marketplaceProfile && (
            <div style={{ marginBottom: "50px" }}>
              <span className="participant-card-marketplaceprofile--title">
                Available locations:
              </span>

              {participant.location.map((location, i) => (
                <SpecialtyItem key={i} title={location} />
              ))}
            </div>
          )} */}
          {participant && (
            // <div className="participant-card-marketplaceprofile">
            <div>
              <Tooltip title="Linkedin">
                <Button
                  shape="circle"
                  type="link"
                  icon={<LinkedinFilled style={{ color: "#000" }} />}
                  onClick={() =>
                    window.open(participant.personalLinks.linkedin, "_blank")
                  }
                  className="participant-card-marketplaceprofile-icon"
                />
              </Tooltip>
            </div>
            // </div>
          )}
        </div>
      </div>
      {/* {participant && (
        <div className="participant-card-marketplaceprofile">
          <Tooltip title="Linkedin">
            <Button
              shape="circle"
              type="link"
              icon={<LinkedinOutlined />}
              onClick={() =>
                window.open(participant.personalLinks.linkedin, "_blank")
              }
              className="participant-card-marketplaceprofile-icon"
            />
          </Tooltip>

          <Tooltip title="Contact Email">
            <Button
              shape="circle"
              type="link"
              icon={<MailOutlined />}
              onClick={() =>
                window.open(`mailto:${participant.email}`, "_blank")
              }
              className="participant-card-marketplaceprofile-icon"
            />
          </Tooltip>
        </div>
      )} */}
    </>
  );
};

const ParticipantCard = ({
  participant,
  onOpenModalBonfires,
  invitedAllBonfires,
  marketplaceProfile,
  userProfile,
}) => {
  return (
    <Card
      // hoverable
      bordered={false}
      type="inner"
      // extra={<UserOutlined />}
      bodyStyle={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        width: "250px",
        padding: "20px 2px",
        // alignItems: "center",
      }}
      className="participant-card-container"
    >
      {invitedAllBonfires === false && invitedAllBonfires !== null ? (
        <CustomButton
          size="sm"
          text="Invite to bonfire"
          style={{ marginTop: "-30px", marginBottom: "30px" }}
          onClick={() => onOpenModalBonfires(participant.id)}
        />
      ) : null}

      {marketplaceProfile ? (
        <>
          <CouncilParticipantCard
            participant={participant}
            marketplaceProfile
            userProfile={userProfile}
          />
        </>
      ) : (
        <CouncilParticipantCard participant={participant} />
      )}
    </Card>
  );
};

const mapStateToProps = (state) => ({
  userProfile: homeSelector(state).userProfile,
});

export default connect(mapStateToProps)(ParticipantCard);
