import React, { useEffect, useRef, useState } from "react";
import { Skeleton } from "antd";
import ChatMessage from "../ChatMessage";
import "../style.scss";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { httpClientCron } from "../../../api/module/httpClientCron";
import moment from "moment/moment";
import { useInView } from "react-intersection-observer";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { scrollIntoView } from "seamless-scroll-polyfill";

export default function ChatMessages({
                                       id,
                                       type,
                                       userProfile,
                                       chatSocket,
                                       handleEditMessage,
                                       titleProfessions,
                                     }) {
  // const {
  //   isLoading,
  //   data,
  //   isFetchingNextPage,
  //   fetchNextPage,
  //   hasNextPage,
  // } = useInfiniteQuery(
  //   ["CHAT_MESSAGE_WINDOW", type, id],
  //   async ({ pageParam = 0 }) => {
  //     let data = [];
  //     let nextCursor = null;
  //
  //     if (type === "individual") {
  //       const response = await httpClientCron.get(`private/chat-messages/${id}?cursor=${pageParam}`);
  //       data = response.data.data;
  //       nextCursor = response.data.nextCursor;
  //
  //     } else {
  //       const response = await httpClientCron.get(`private/group-chat-messages/${id}?cursor=${pageParam}`);
  //
  //       data = response.data.data;
  //       nextCursor = response.data.nextCursor;
  //     }
  //
  //
  //     const messagesUnsorted = data.reduce((acc, message) => {
  //       const date = moment(message.createdAt).format("YYYY-MM-DD");
  //
  //       if (!acc[date]) {
  //         acc[date] = [];
  //       }
  //
  //       const lastGroup = acc[date][acc[date].length - 1];
  //
  //       if (lastGroup && lastGroup.senderId === message.senderId) {
  //         lastGroup.messages.push(message);
  //       } else {
  //         acc[date].push({
  //           senderId: message.senderId,
  //           receiverId: message.receiverId,
  //           messages: [message],
  //         });
  //       }
  //
  //       return acc;
  //     }, {});
  //
  //     const messages = Object.keys(messagesUnsorted)
  //       .sort((a, b) => moment(b).valueOf() - moment(a).valueOf())
  //       .reduce((obj, key) => {
  //         obj[key] = messagesUnsorted[key];
  //         return obj;
  //       }, {});
  //
  //     return {
  //       data: messages,
  //       nextCursor,
  //     };
  //   },
  //   {
  //     getNextPageParam: (lastPage) => lastPage.nextCursor ?? undefined,
  //   },
  // );

  const { data: groupedChatMessages, isLoading } = useQuery({
    queryKey: ["CHAT_MESSAGE_WINDOW", type, id],
    queryFn: async () => {

      let data = [];

      if (type === "individual") {
        const response = await httpClientCron.get(`private/chat-messages/${id}`);
        data = response.data

      } else {
        const response = await httpClientCron.get(`private/group-chat-messages/${id}`);

        data = response.data
      }

      const messagesUnsorted = data.reduce((acc, message) => {
        const date = moment(message.createdAt).format("YYYY-MM-DD");

        if (!acc[date]) {
          acc[date] = [];
        }

        const lastGroup = acc[date][acc[date].length - 1];

        if (lastGroup && lastGroup.senderId === message.senderId) {
          lastGroup.messages.push(message);
        } else {
          acc[date].push({
            senderId: message.senderId,
            receiverId: message.receiverId,
            messages: [message],
          });
        }

        return acc;
      }, {});

      const messages = Object.keys(messagesUnsorted)
        .sort((a, b) => moment(a).valueOf() - moment(b).valueOf()) // Sorting in ascending order
        .reduce((obj, key) => {
          obj[key] = messagesUnsorted[key];
          return obj;
        }, {});

      return messages;
    },
  });

  // const { ref: viewRef, inView } = useInView();
  //
  // useEffect(() => {
  //   if (inView) {
  //     fetchNextPage();
  //   }
  // }, [inView]);
  //
  const ref = useRef(null);

  const scrollToBottom = (isSmooth = false) => {
    scrollIntoView(ref.current, { behavior: "instant", block: "center", inline: "center" });
  };

  useEffect(() => {
    scrollToBottom();

  }, [isLoading, groupedChatMessages]);

  return (
    <div
      className="chat-message-window-message-content-parent"
      style={{
        height: titleProfessions ? "550px" : "600px",
      }}
    >
      <div
        className="chat-message-window-message-content"
      >
        {isLoading && (
          <>
            {new Array(20).fill(null).map(() => <Skeleton avatar paragraph={{ rows: 1 }} />)}
          </>
        )}
        {(Object.entries(groupedChatMessages || {}) ?? []).map(([date, chatMessages]) => {

          return (
            <>
              {chatMessages.map((chatMessage, chatMessageIndex) => {
                return (
                  <>
                    {chatMessage.messages.map((message, index) => {
                      const { sender, content, createdAt } = message;
                      const senderName = sender ? `${sender.firstName} ${sender.lastName}` : "System Generated Message";

                      return (
                        <ChatMessage
                          index={index}
                          date={date}
                          sender={sender || { img: null, id: null }}
                          createdAt={createdAt}
                          content={content}
                          senderName={senderName}
                          chatMessageId={message.id}
                          userProfile={userProfile}
                          chatSocket={chatSocket}
                          chatMessageWindowId={id}
                          chatMessageIndex={chatMessageIndex}
                          handleEditMessage={handleEditMessage}
                          type={type}
                        />
                      );
                    })}
                  </>
                );
              })}
            </>
          );
        })}
        <div ref={ref} />

        {/*{(data?.pages || []).map((page, pageIndex) => {*/}
        {/*  return (*/}
        {/*    <>*/}
        {/*      {(Object.entries(page.data || {}) ?? []).map(([date, chatMessages], groupIndex) => {*/}

        {/*        return (*/}
        {/*          <div>*/}
        {/*            {chatMessages.map((chatMessage, chatMessageIndex) => {*/}
        {/*              return (*/}
        {/*                <>*/}
        {/*                  {chatMessage.messages.map((message, index) => {*/}

        {/*                    const { sender, content, createdAt } = message;*/}
        {/*                    const senderName = sender ? `${sender.firstName} ${sender.lastName}` : "System Generated Message";*/}

        {/*                    return (*/}
        {/*                      <>*/}
        {/*                        <ChatMessage*/}
        {/*                          index={index}*/}
        {/*                          date={date}*/}
        {/*                          sender={sender || { img: null, id: null }}*/}
        {/*                          createdAt={createdAt}*/}
        {/*                          content={content}*/}
        {/*                          senderName={senderName}*/}
        {/*                          chatMessageId={message.id}*/}
        {/*                          userProfile={userProfile}*/}
        {/*                          chatSocket={chatSocket}*/}
        {/*                          chatMessageWindowId={id}*/}
        {/*                          chatMessageIndex={chatMessageIndex}*/}
        {/*                          groupIndex={groupIndex}*/}
        {/*                          handleEditMessage={handleEditMessage}*/}
        {/*                          type={type}*/}
        {/*                          pageIndex={pageIndex}*/}
        {/*                        />*/}
        {/*                      </>*/}
        {/*                    );*/}
        {/*                  })}*/}
        {/*                </>*/}
        {/*              );*/}
        {/*            })}*/}
        {/*          </div>*/}
        {/*        );*/}

        {/*      })}*/}
        {/*    </>*/}
        {/*  );*/}
        {/*})}*/}


        {/*{isFetchingNextPage &&*/}
        {/*  <div*/}
        {/*    style={{ display: "flex", justifyContent: "center" }}*/}
        {/*  >*/}
        {/*    <Spin*/}
        {/*      indicator={<LoadingOutlined spin />}*/}
        {/*      size="small"*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*}*/}
        {/*<div ref={viewRef}  />*/}
      </div>
    </div>
  );
}