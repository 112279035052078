import React from "react";
import { INTERNAL_LINKS } from "enum";
import { Route } from "react-router-dom";
import { PrivateRoute } from "components";
import BlogNewPage from "pages/BlogNew";

const startRouteBlogs1 = () => {
  return (
    <>
      {localStorage.getItem("community") !== null && (
        <PrivateRoute
          path={`${INTERNAL_LINKS.BLOGS_NEW}/:id`}
          exact
          render={(props) => <BlogNewPage {...props} />}
        />
      )}
      {localStorage.getItem("community") === null && (
        <Route
          path={`${INTERNAL_LINKS.BLOGS_NEW}/:id`}
          exact
          render={(props) => <BlogNewPage {...props} />}
        />
      )}
    </>
  );
};

export default startRouteBlogs1;
