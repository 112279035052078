import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import {
  Form,
  Upload,
} from "antd";
import { notification } from "antd";
import { InboxOutlined, FileDoneOutlined } from "@ant-design/icons";
import { createFilePost, editFilePost } from "redux/actions/files-post-actions";

import { getImagesCarg } from "redux/actions/blog-post-action";

// import clsx from "clsx";
// import moment from "moment";
// import { Modal, DatePicker,TreeSelect } from "antd";
import { INTERNAL_LINKS } from "enum";
// import {
//   CloseCircleOutlined,
// } from "@ant-design/icons";

import {
  CustomInput,
  InputBlog,
  // FroalaEdit,
  // ImageUpload,
  ImageUploadPersonalized,
} from "components";

// import { Link } from "react-router-dom";
import { homeSelector } from "redux/selectors/homeSelector";
import { CustomButton, CustomModal } from "components";
import { categorySelector } from "redux/selectors/categorySelector";
import { notificationEmailToNewContentCreators } from "redux/actions/channel-actions";
// import { ReactComponent as IconPencil } from "images/icon-pencil.svg";
// import { ReactComponent as IconShowPassword } from "images/icon-show-password.svg";
// import { ReactComponent as IconTrash } from "images/icon-trash.svg";

import "./style.scss";
// import { Link } from "react-router-dom/cjs/react-router-dom";

const FormCreateOrEditResource = ({
  userProfile,
  getAllFilePost,
  notificationEmailToNewContentCreators,
  setEditOrDeleteResource,
  editOrDeleteResource,
  setCurrentTab,
  resetData = () => {},
  createFilePost,
  editFilePost,
  getImagesCarg,
}) => {
  // const { search } = useLocation();
  // const query = new URLSearchParams(search);

  //   const [visibleModal, setVisibleModal] = useState(false);
  //   const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  // const id = query?.get("id");
  // const accepted = query?.get("accepted");
  // const [bulLoad, setBulLoad] = useState(false);
  // const [bulLoad2, setBulLoad2] = useState(false);
  const [resourceForm] = Form.useForm();
  const [resourceForm2] = Form.useForm();
  const [summary1, setSummary1] = useState("");
  const [summary2, setSummary2] = useState("");
  const [summary3, setSummary3] = useState("");
  const [summary4, setSummary4] = useState("");

  const [file1, setFile1] = useState('')
  const [file2, setFile2] = useState('')
  const [file3, setFile3] = useState('')
  // const [bulModal2, setBulModal2] = useState(false);

  // const [images, setImages] = useState([]);
  const [bulModal3, setBulModal3] = useState(false);
  const [bulRemove, setBulRemove] = useState("");
  const [bulEdit, setBulEdit] = useState(-1);
  // const [bulAddFile, setBulAddFile] = useState(false);
  // const [activeMessages, setActiveMessages] = useState("");
  const [fileList, setFileList] = useState([]);
  const [color, setColor] = useState("#17253e");
  const [fileNameSelect, setFileNameSelect] = useState("");
  const [funcBodyInput, setFuncBodyInput] = useState({});
  const [bulRequired, setBulRequired] = useState({
    altText: false,
    metaDescriptions: false,
    slug: 0,
    metaTitle:0,
    description: false,
  });
  // const [body,setBody] = useState("")
  // const [date, setDate] = useState("");
  // const [required, setRequirect] = useState({
  //   title: "title",
  //   description: "description",
  //   // date: "date",
  //   altText: "altText",
  //   metaDescriptions: "metaDescriptions",
  //   slug: "slug",
  //   metaTitle: "metaTitle",
  // });
  const [typeS, setTypeS] = useState("draft");
  const [bulRepeatReact, setBulRepeatReact] = useState(false);
  const clock = useRef(null);

  let clock2 = useRef(null);

  const handleSummary = (value, type) => {
    if (type === 1) {
      setSummary1(value.length);
    }
    if (type === 2) {
      setSummary2(value.length);
    }
    if (type === 3) {
      setSummary3(value.length);
    }
    if (type === 4) {
      setSummary4(value.length);
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const validateFileType = ({ type, name }, allowedTypes) => {
    if (!allowedTypes) {
      return true;
    }

    if (type) {
      return allowedTypes.includes(type);
    }
  };

  const bef = (file) => {
    const isAllowedType = validateFileType(file, "application/pdf");

    if (!isAllowedType) {
      setBulRemove(`${file.name} is not PDF file`);
      setTimeout(() => {
        setBulRemove(``);
      }, 2000);
      return false;
    } else {
      setFileNameSelect(file.name);
      setFileList([file]);
      return false;
    }
  };

  const handleSaveDraftResourcePost = (type) => {
    let values = resourceForm.getFieldsValue();
    let values2 = resourceForm2.getFieldsValue();
    let expSlug = /^[a-zA-Z0-9\s-]*$/;
    let altText =
      values.altText === "" || values.altText === undefined || !values.altText
        ? true
        : false;
    let metaDescriptions =
      values2.metaDescriptions === "" ||
      values2.metaDescriptions === undefined ||
      !values2.metaDescriptions
        ? true
        : false;
    let slug =
      values2.slug === "" || values2.slug === undefined || !values2.slug
        ? 1
        : 0;
    let slugCaracteres =
      slug === 0 ? (expSlug.test(values2.slug) ? 0 : 2) : slug;

    let response = funcBodyInput.data();
    let newDiv = document.createElement("div");
    newDiv.innerHTML = response.body;
    let pData = newDiv.querySelectorAll("p");

    let description = pData[0]
      ? newDiv.children[0].children.length === 1 &&
        (pData[0].textContent === "" || pData[0].textContent === " ")
        ? true
        : false
      : false;

    let bul =
      altText || metaDescriptions || slugCaracteres !== 0 || description
        ? true
        : false;

    setBulRequired({
      altText,
      metaDescriptions,
      slug: slugCaracteres,
      description,
    });

    // setRequirect({ ...values, ...values2 });

    if (bul) {
      return;
    }

    handleCreateOrEditResource({
      ...values,
      ...values2,
      status: type,
      images: response.images,
    });
  };

  const handleSaveLaterResourcePost = (type) => {
    let values = resourceForm.getFieldsValue();
    let values2 = resourceForm2.getFieldsValue();
    let expSlug = /^[a-zA-Z0-9\s-]*$/;
    let altText =
      values.altText === "" || values.altText === undefined || !values.altText
        ? true
        : false;
    let metaDescriptions =
      values2.metaDescriptions === "" ||
      values2.metaDescriptions === undefined ||
      !values2.metaDescriptions
        ? true
        : false;
    let slug =
      values2.slug === "" || values2.slug === undefined || !values2.slug
        ? 1
        : 0;
    let slugCaracteres =
      slug === 0 ? (expSlug.test(values2.slug) ? 0 : 2) : slug;

    let response = funcBodyInput.data();
    let newDiv = document.createElement("div");
    newDiv.innerHTML = response.body;
    let pData = newDiv.querySelectorAll("p");

    let description = pData[0]
      ? newDiv.children[0].children.length === 1 &&
        (pData[0].textContent === "" || pData[0].textContent === " ")
        ? true
        : false
      : false;

    let bul =
      altText || metaDescriptions || slugCaracteres !== 0 || description
        ? true
        : false;

    setBulRequired({
      altText,
      metaDescriptions,
      slug: slugCaracteres,
      description,
    });

    // setRequirect({ ...values, ...values2 });
    setTimeout(() => {
      // setRequirect({
      //   title: "title",
      //   description: "description",
      //   altText: "altText",
      //   // date: "date",
      //   metaDescriptions: "metaDescriptions",
      //   slug: "slug",
      // });
    }, 3000);

    if (bul) {
      return;
    }

    handleCreateOrEditResource({
      ...values,
      status: type,
      images: response.images,
      // date: moment.utc(date.format("YYYY-MM-DD HH:mm")).format(),
    });
  };

  const updateTextSlug = () => {
    let slug = resourceForm2.getFieldValue("slug");
    let slugFormated = slug?.replaceAll(" ", "-");
    slugFormated = slugFormated?.toLowerCase();
    slugFormated = slugFormated?.replaceAll(/[^a-z0-9-]+/g, "");
    resourceForm2.setFieldsValue({
      slug: slugFormated,
    });
  };

  useEffect(() => {
    if (clock.current) {
      clearTimeout(clock.current);
    }

    updateTextSlug();

    clock.current = setTimeout(() => {
      setBulRepeatReact(!bulRepeatReact);
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setBulRepeatReact, bulRepeatReact]);

  const onCancelModal = (type, bul = true) => {
    setEditOrDeleteResource({});
    resourceForm.resetFields();
    resourceForm2.resetFields();
    setFileList([]);
    if (typeof funcBodyInput.reset === "function") {
      funcBodyInput.reset();
    }
    if (bul) {
      setCurrentTab(type === "draft" ? "1" : "2");
    }
    // setVisibleDeleteModal(false);
  };

  const handleCreateOrEditResource = async (data) => {
    // if (!data.date) {
    //   setBulLoad(true);
    // } else {
    //   setBulLoad2(true);
    // }

    if (data.slug.length > 60) {
      notification.error({
        message: "Slug (max 60 characters)",
      });
      return;
    }

    if (data.metaDescriptions.length > 155) {
      notification.error({
        message: "Meta Descriptions (max 155 characters)",
      });
      return;
    }

    if (data.altText.length > 125) {
      notification.error({
        message: "Alt-text (max 125 characters)",
      });
      return;
    }

    if (fileList[0] === undefined) {
      setBulRemove(`File is required`);
      setTimeout(() => {
        setBulRemove(``);
      }, 2000);
      return;
    }

    getImagesCarg(data.images, async (e) => {
      const responseHTML = funcBodyInput.functionReplaceImage(e);

      if (editOrDeleteResource.id) {
        let data2 = {};
        if (fileList[0].documentFileImageUrl === undefined) {
          const base64FormatFile = await getBase64(fileList[0]);

          data2 = {
            title: fileList[0].name,
            file: base64FormatFile,
          };
        }

        editFilePost(
          {
            ...data,
            ...data2,
            description: responseHTML,
            color,
            imageFile: file1,
            image2: file2,
            image3: file3,
            id: editOrDeleteResource.id,

            statusRequest:
              data.status === "draft" && !data.date
                ? "waiting"
                : data.statusRequest,
          },
          (error) => {
            if (error) {
              return notification.error({
                message: error,
              });
            }

            setEditOrDeleteResource({});
            onCancelModal(data.type);

            getAllFilePost();

            return notification.success({
              message: "Resource Updated Successfully",
            });
          }
        );
      } else {
        const base64FormatFile = await getBase64(fileList[0]);

        createFilePost(
          {
            ...data,
            title: fileList[0].name,
            color,
            file: base64FormatFile,
            description: responseHTML,
            owner: userProfile?.email,
            imageFile: file1,
            image2: file2,
            image3: file3,
            statusRequest:
              data.status === "draft" && !data.date ? "waiting" : "pending",
          },
          (error) => {
            if (error) {
              return notification.error({
                message: error,
              });
            }

            setEditOrDeleteResource({});
            onCancelModal(data.type);

            getAllFilePost();
            if (data.date === undefined) {
              notificationEmailToNewContentCreators({
                channelName: "Resource Post User",
                channelAdmin: userProfile?.firstName,
                channelAdminEmail: userProfile?.email,
                contentType: "resource",
                name: data.title,
                link: "resource not have link",
              });
            }

            return notification.success({
              message: "Resource Posted Successfully",
            });
          }
        );
      }

      // setBulLoad(false);
      // setBulLoad2(false);
    });
  };

  const functionPreviuw = () => {
    let values = resourceForm.getFieldValue();
    let values2 = resourceForm2.getFieldValue();

    let response = funcBodyInput.data();
    let newDiv = document.createElement("div");
    newDiv.innerHTML = response.body;
    let pData = newDiv.querySelectorAll("p");

    let description = pData[0]
      ? newDiv.children[0].children.length === 1 &&
        (pData[0].textContent === "" || pData[0].textContent === " ")
        ? true
        : false
      : false;

    getImagesCarg(response.images, async (e) => {
      const responseHTML = funcBodyInput.functionReplaceImage(e);
      let expSlug = /^[a-zA-Z0-9\s-]*$/;
      let altText =
        values.altText === "" || values.altText === undefined || !values.altText
          ? true
          : false;
      let metaDescriptions =
        values2.metaDescriptions === "" ||
        values2.metaDescriptions === undefined ||
        !values2.metaDescriptions
          ? true
          : false;
      let slug =
        values2.slug === "" || values2.slug === undefined || !values2.slug
          ? 1
          : 0;
      let slugCaracteres =
        slug === 0 ? (expSlug.test(values2.slug) ? 0 : 2) : slug;

      if (
        !description &&
        !altText &&
        !metaDescriptions &&
        slugCaracteres === 0 &&
        fileList.length > 0 &&
        values.imageFile !== "" &&
        values.name !== ""
      ) {
        localStorage.setItem(
          "previuwR",
          JSON.stringify({
            ...values,
            ...values2,
            documentFileImageUrl: values.imageFile,
            color,
            description: responseHTML,
          })
        );

        window.open(`${INTERNAL_LINKS.RESOURCE}`, "_blank");
      } else {
        setBulModal3(true);
      }
    });
  };

  useEffect(() => {
    if (editOrDeleteResource?.id) {
      if (funcBodyInput !== undefined) {
        if (typeof funcBodyInput.data === "function") {
          const containerMap = document.createElement("div");
          containerMap.innerHTML = editOrDeleteResource?.description;
          let data = containerMap?.children[0]
            ? containerMap?.children[0].innerHTML
            : "";
          funcBodyInput.functionValueDefault(data);
        }
      }

      setSummary1(editOrDeleteResource?.altText?.length);
      setSummary2(editOrDeleteResource?.metaDescriptions?.length);
      setSummary3(editOrDeleteResource?.slug?.length);
      setSummary4(editOrDeleteResource?.metaTitle?.length)
      setFileList([editOrDeleteResource]);
      setFileNameSelect(editOrDeleteResource.documentFileName);
      setBulEdit(editOrDeleteResource.id);
      // setBulAddFile(true);
      setFile1(editOrDeleteResource?.imageFile)
      setFile2(editOrDeleteResource?.image2)
      setFile3(editOrDeleteResource?.image3)
      setColor(
        editOrDeleteResource?.color === "" ||
          editOrDeleteResource?.color === undefined ||
          editOrDeleteResource?.color === " "
          ? "#17253e"
          : editOrDeleteResource?.color
      );

      resourceForm.setFieldsValue({
        ...editOrDeleteResource,
        imageFile: editOrDeleteResource?.documentFileImageUrl,
      });
      resourceForm2.setFieldsValue({
        ...editOrDeleteResource,
      });
    }
  }, [editOrDeleteResource, resourceForm, resourceForm2, funcBodyInput]);

  useEffect(() => {
    resetData({
      function: () => onCancelModal("", false),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funcBodyInput]);

  return (
    <>
      <div className="container-form-resources-for-user">
        <div className="container-fixed-see-porcentage-scroll"></div>
        <div className="container-header-blog-form">Add Resources Details</div>
        <div className="container-forms-blogs">
          <div className="container-first-form">
            <Form
              form={resourceForm}
              layout="vertical"
              onFinish={() => {
                // let values2 = resourceForm2.getFieldsValue();
                if (typeS === "draft" || typeS === "published") {
                  handleSaveDraftResourcePost(typeS);
                }
                if (typeS === "shedule") {
                  handleSaveLaterResourcePost("draft");
                }
                setTypeS("draft");
              }}
              style={{ maxWidth: "1000px", width: "100%" }}
            >
              <Form.Item style={{ marginBottom: 0 }}>
                <Form.Item
                  name="imageFile"
                  label="Image"
                  rules={[{ required: true }]}
                  style={{ display: "inline-block", width: "calc(50% - 8px)" }}
                >

                  {/* <ImageUpload type="blog-style" className="image-upload-3" aspect={755 / 755} textScale="755 * 755 px" /> */}
                  <ImageUploadPersonalized aspect={755 / 755} textScale="755 * 755 px" onChange={(e,file) => {
                    setFile1(e)
                  }}/>
                </Form.Item>
                <Form.Item
                  name="image2"
                  label="Image Page"
                  rules={[{ required: true }]}
                  style={{
                    display: "inline-block",
                    width: "calc(50% - 8px)",
                    margin: "0 8px",
                  }}
                >
                  {/* <ImageUpload type="blog-style" className="image-upload-3" aspect={755 / 1170.25} textScale="755 * 1170.25 px" /> */}
                  <ImageUploadPersonalized aspect={755 / 1170.25} textScale="755 * 1170.25 px" onChange={(e,file) => {
                    setFile2(e)
                  }}/>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={
                  <p style={{ margin: "0px", padding: "0px" }}>
                    <span
                      style={{
                        fontSize: "18px",
                        transform: "translateY(5px)",
                        color: "#f75b27",
                        marginRight: "3px",
                        display: "inline-block",
                      }}
                    >
                      *
                    </span>
                    File
                  </p>
                }
              >
                <Form.Item noStyle>
                  <Upload.Dragger
                    className="image-upload-3"
                    name="files"
                    beforeUpload={(file) => bef(file)}
                    fileList={[]}
                  >
                    {fileList[0] === undefined ? (
                      <>
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined style={{ color: "#fe5621" }} />
                        </p>
                        <p className="ant-upload-text">
                          Click or drag file to this area to upload
                        </p>
                        <p className="ant-upload-hint">
                          Support for a single upload.
                        </p>
                      </>
                    ) : (
                      <>
                        <p className="ant-upload-drag-icon">
                          <FileDoneOutlined style={{ color: "#fe5621" }} />
                        </p>
                        <p className="ant-upload-text">{fileNameSelect}</p>
                      </>
                    )}
                  </Upload.Dragger>
                </Form.Item>
              </Form.Item>
              {bulRemove ? (
                <p style={{ color: "#fe5621", margin: "5px" }}>{bulRemove}</p>
              ) : (
                <p style={{ display: "none" }}></p>
              )}
              <Form.Item
                name="altText"
                label={
                  <p style={{ margin: "0px", padding: "0px" }}>
                    <span
                      style={{
                        fontSize: "18px",
                        transform: "translateY(5px)",
                        color: "#f75b27",
                        marginRight: "3px",
                        display: "inline-block",
                      }}
                    >
                      *
                    </span>
                    Type ALT-text
                  </p>
                }
              >
                <CustomInput
                  placeholder="Child's Sensory andMotor Development"
                  className="custom-input-blog-for-user"
                  onChange={(e) => handleSummary(e, 1)}
                />
              </Form.Item>
              <div className="container-blog-form-limits">
                <div
                  className={
                    !bulRequired["altText"]
                      ? "container-inter-case"
                      : "container-inter-case-h"
                  }
                >
                  <p
                    className={
                      summary1 > 125
                        ? "response-limits-case"
                        : "response-limits-case-blog"
                    }
                  >
                    ALT-text (max 125 characters)
                  </p>
                  <p style={{ margin: "0px" }}>
                    <span style={summary1 > 125 ? { color: "#f75b27" } : {}}>
                      {125 - summary1}
                    </span>{" "}
                    / 125
                  </p>
                </div>
                <div
                  className={
                    bulRequired["altText"]
                      ? "container-inter-case"
                      : "container-inter-case-h"
                  }
                >
                  <p className="response-limits-case">
                    Type ALT-text is required
                  </p>
                </div>
              </div>
              <Form.Item
                name="name"
                label="Title"
                rules={[{ required: true, message: "Name is required" }]}
              >
                <CustomInput className="custom-input-blog-for-user" />
              </Form.Item>
              <p
                style={{ margin: "0px", padding: "0px", marginBottom: "10px" }}
              >
                <span
                  style={{
                    fontSize: "18px",
                    transform: "translateY(5px)",
                    color: "#f75b27",
                    marginRight: "3px",
                    display: "inline-block",
                  }}
                >
                  *
                </span>
                Description
              </p>
              <InputBlog functionData={(e) => setFuncBodyInput(e)} />
              <div
                className="container-blog-form-limits"
                style={{ transform: "translateY(0px)" }}
              >
                <div
                  className={
                    bulRequired["description"]
                      ? "container-inter-case"
                      : "container-inter-case-h"
                  }
                >
                  <p className="response-limits-case">Body is required</p>
                </div>
              </div>
              <div className="container-btns">
                <CustomButton
                  className="button-speaker font-size-more"
                  text={bulEdit === -1 ? "Post File" : "Edit File"}
                  size="md"
                  style={{
                    padding: "10px",
                    height: "40px",
                    width: "220px",
                  }}
                  type={"secondary"}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    setTypeS("published");
                    resourceForm.submit();
                  }}
                />
                <CustomButton
                  className="button-speaker font-size-more"
                  text={"Save As Draft"}
                  size="md"
                  style={{
                    padding: "10px",
                    height: "40px",
                    width: "220px",
                  }}
                  type={"primary"}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    setTypeS("draft");
                    resourceForm.submit();
                  }}
                />
                <CustomButton
                  className="button-speaker font-size-more"
                  text="PREVIEW"
                  size="md"
                  style={{
                    padding: "10px",
                    height: "40px",
                    width: "220px",
                  }}
                  type={"primary"}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    functionPreviuw();
                  }}
                />
              </div>
            </Form>
          </div>
          <div className="container-second-form">
            <Form
              form={resourceForm2}
              layout="vertical"
              style={{
                maxWidth: "1000px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
                <Form.Item 
                  name="image3" 
                  label="Image Link"
                  rules={[{ required: true, message: "Image is required." }]}
                >
                  {/* <ImageUpload type="blog-style" className="image-upload-3" aspect={755 / 305} textScale="755 * 305 px" /> */}
                  <ImageUploadPersonalized aspect={755 / 305} textScale="755 * 305 px" onChange={(e,file) => {
                    setFile3(e)
                  }}/>
                </Form.Item>
                <Form.Item
                  name="canonicalTags"
                  label="Canonical Tags"
                  rules={[{ required: false }]}
                >
                  <CustomInput
                    placeholder="Canonical Tag"
                    className="custom-input-blog-for-user"
                  />
                </Form.Item>
                <Form.Item
                  label={<p style={{margin:'0px',padding:'0px'}}>
                    <span style={{fontSize:'18px',transform:'translateY(5px)',color:'#f75b27',marginRight:'3px',display:'inline-block'}}>*</span>Meta Descriptions
                  </p>}
                  name="metaDescriptions"
                >
                  <CustomInput 
                    multiple={true} 
                    className="custom-input-blog-for-user" 
                    placeholder="Add meta-description" 
                    style={{fontSize:'13px !important',padding:'5px !important'}} 
                    onChange={(e) => handleSummary(e,2)}  
                  />
                </Form.Item>
                <div className="container-blog-form-limits">
                  <div className={!bulRequired["metaDescriptions"] ? "container-inter-case" : "container-inter-case-h"}>
                    <p className={(summary2 > 155) ? "response-limits-case" : "response-limits-case-blog"}>
                      Meta Descriptions (max 155 characters)
                    </p>
                    <p style={{margin: "0px"}}>
                      <span style={summary2 > 155 ? { color: "#f75b27" } : {}}>{155 - summary2}</span>{" "}/ 155
                    </p>
                  </div>
                  <div className={bulRequired["metaDescriptions"] ? "container-inter-case" : "container-inter-case-h"}>
                    <p className="response-limits-case">Meta Descriptions is required</p>
                  </div>
                </div>
                <Form.Item
                  label={
                    <p style={{ margin: "0px", padding: "0px" }}>
                      <span
                        style={{
                          fontSize: "18px",
                          transform: "translateY(5px)",
                          color: "#f75b27",
                          marginRight: "3px",
                          display: "inline-block",
                        }}
                      >
                        *
                      </span>
                      Slug
                    </p>
                  }
                  name="slug"
                >
                  <CustomInput
                    className="custom-input-blog-for-user"
                    placeholder="Add Slug"
                    style={{
                      fontSize: "13px !important",
                      padding: "5px !important",
                    }}
                    onChange={(e) => handleSummary(e, 3)}
                  />
                </Form.Item>
                <div className="container-blog-form-limits">
                  <div
                    className={
                      bulRequired["slug"] === 0
                        ? "container-inter-case"
                        : "container-inter-case-h"
                    }
                  >
                    <p
                      className={
                        summary3 > 60
                          ? "response-limits-case"
                          : "response-limits-case-blog"
                      }
                    >
                      Slug (max 60 characters)
                    </p>
                    <p style={{ margin: "0px" }}>
                      <span style={summary3 > 60 ? { color: "#f75b27" } : {}}>
                        {60 - summary3}
                      </span>{" "}
                      / 60
                    </p>
                  </div>
                  <div
                    className={
                      bulRequired["slug"] === 1
                        ? "container-inter-case"
                        : "container-inter-case-h"
                    }
                  >
                    <p className="response-limits-case">Slug is required</p>
                  </div>
                  <div
                    className={
                      bulRequired["slug"] === 2
                        ? "container-inter-case"
                        : "container-inter-case-h"
                    }
                  >
                    <p className="response-limits-case">
                      Slug cannot contain special characters
                    </p>
                  </div>
                </div>
                <Form.Item
                  label={
                    <p style={{ margin: "0px", padding: "0px" }}>
                      <span
                        style={{
                          fontSize: "18px",
                          transform: "translateY(5px)",
                          color: "#f75b27",
                          marginRight: "3px",
                          display: "inline-block",
                        }}
                      >
                        *
                      </span>
                      Meta Title
                    </p>
                  }
                  name="metaTitle"
                >
                  <CustomInput
                    className="custom-input-blog-for-user"
                    placeholder="Meta Title"
                    style={{
                      fontSize: "13px !important",
                      padding: "5px !important",
                    }}
                    onChange={(e) => handleSummary(e, 4)}
                  />
                </Form.Item>
                <div className="container-blog-form-limits">
                  <div
                    className={
                      bulRequired["metaTitle"] === 0
                        ? "container-inter-case"
                        : "container-inter-case-h"
                    }
                  >
                    <p
                      className={
                        summary4 > 60
                          ? "response-limits-case"
                          : "response-limits-case-blog"
                      }
                    >
                      Meta Title (max 60 characters)
                    </p>
                    <p style={{ margin: "0px" }}>
                      <span style={summary4 > 60 ? { color: "#f75b27" } : {}}>
                        {60 - summary4}
                      </span>{" "}
                      / 60
                    </p>
                  </div>
                  <div
                    className={
                      bulRequired["metaTitle"] === 1
                        ? "container-inter-case"
                        : "container-inter-case-h"
                    }
                  >
                    <p className="response-limits-case">Meta Title is required</p>
                  </div>
                  {/* <div
                    className={
                      bulRequired["metaTitle"] === 2
                        ? "container-inter-case"
                        : "container-inter-case-h"
                    }
                  >
                    <p className="response-limits-case">
                    Meta Title cannot contain special characters
                    </p>
                  </div> */}
                </div>
                <p
                  style={{ margin: "0px", padding: "0px", marginBottom: "10px" }}
                >
                  Color
                </p>
                <input
                  type="color"
                  value={color}
                  className="custom-input-blog-for-user"
                  style={{
                    fontSize: "13px !important",
                    padding: "5px !important",
                    width: "100%",
                    height: "37px",
                    background: "white",
                    borderRadius: "10px",
                    border: "#e1e2ee solid 1px",
                  }}
                  onChange={(e) => {
                    if (clock2) {
                      clearTimeout(clock2);
                    }

                    let colorI = e.target.value;
                    clock2 = setTimeout(() => {
                      setColor(colorI);
                    }, 500);
                  }}
                />
            </Form>
          </div>
        </div>
      </div>
      {/* <Modal
          visible={bulModal2}
          footer={null}
          width={400}
          bodyStyle={{
          marginBottom: "40px",
          padding: "20px",
          display: "flex",
          justifyContent: "center",
          }}
          onCancel={() => {
          setBulModal2(false);
          }}
      >
        <div
          style={{
              width: "100%",
              height: "auto",
              display: "flex",
              justifyContent: "center",
          }}
        >
        <div
            style={{
            marginTop: "0px",
            width: "470px",
            background: "white",
            height: "auto",
            borderRadius: "5px",
            display: "flex",
            padding: "40px",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            }}
        >
            <h3 style={{ margin: "0px", paddingTop: "20px" }}>Post Now</h3>
            <div
            style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "10px",
            }}
            >
            <CustomButton
                className="button-speaker font-size-more"
                text="Post Now"
                size="md"
                loading={bulLoad}
                style={{
                padding: "10px",
                height: "40px",
                width: "320px",
                marginTop: "10px",
                }}
                type={"primary"}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()

                  setTypeS("published")
                  resourceForm.submit();
                }}
            />
            </div>
            <h3 style={{ margin: "0px", paddingTop: "40px" }}>
              Schedule Your Post
            </h3>
            <DatePicker
              className={clsx("custom-input")}
              style={{ width: "100%" }}
              onChange={(date) => {
                  setDate(date);
              }}
              format={customFormat}
              showTime
              panelRender={(data) => {
                  return (
                  <div
                      className="container-ant-picker"
                      style={{ position: "relative" }}
                  >
                      <div
                      style={{
                          position: "absolute",
                          right: "0px",
                          height: "40.89px",
                          width: "170px",
                          background: "white",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontWeight: "bold",
                      }}
                      >
                      Hour ( in Pacific Time )
                      </div>
                      {data}
                  </div>
                  );
              }}
            />
            {!required.title && (
            <p style={{ color: "#fe5621", margin: "5px" }}>
                Title is required
            </p>
            )}
            {!required.altText && (
            <p style={{ color: "#fe5621", margin: "5px" }}>
                Alt Text is required
            </p>
            )}
            {!required.metaDescriptions && (
            <p style={{ color: "#fe5621", margin: "5px" }}>
                Meta Description is required
            </p>
            )}
            {!required.slug && (
            <p style={{ color: "#fe5621", margin: "5px" }}>
                Slug is required
            </p>
            )}
            {!required.categories && (
            <p style={{ color: "#fe5621", margin: "5px" }}>
                Categories is required
            </p>
            )}
            {!required.date && (
            <p style={{ color: "#fe5621", margin: "5px" }}>
                Date is required
            </p>
            )}
            <div
            style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "10px",
            }}
            >
            <CustomButton
                className="button-speaker font-size-more"
                text="Schedule Your Post"
                size="md"
                loading={bulLoad2}
                style={{
                padding: "10px",
                height: "40px",
                width: "320px",
                marginTop: "20px",
                }}
                type={"primary"}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()

                  setTypeS("shedule")
                  resourceForm.submit();
                }}
            />
            </div>
        </div>
        </div>
      </Modal> */}
      <CustomModal
        visible={bulModal3}
        footer={null}
        width={400}
        bodyStyle={{
          marginBottom: "40px",
          padding: "20px",
          display: "flex",
          justifyContent: "center",
        }}
        onCancel={() => {
          setBulModal3(false);
        }}
      >
        <h3>All fields are required</h3>
      </CustomModal>
      {/* <div className="container-modals-image" id="modal-container-image">
        
      </div> */}
    </>
  );
};

const mapStateToProps = (state) => ({
  userProfile: homeSelector(state).userProfile,
  allCategories: categorySelector(state).categories,
  // s3Hash: envSelector(state).s3Hash,
});

const mapDispatchToProps = {
  notificationEmailToNewContentCreators,
  createFilePost,
  editFilePost,
  getImagesCarg,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormCreateOrEditResource);
