import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Upload } from "antd";
import { CustomButton } from "components";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const FileUpload = ({ type, accept, onChange, value, fileName }) => {
  const [fileList, setFileList] = useState([]);
  //   const [src, setSrc] = useState("");

  const fileUpload = async ({ file, onSuccess }) => {
    const base64FormatFile = await getBase64(file);
    onChange(base64FormatFile);
    onSuccess("ok");
  };

  useEffect(() => {
    if (value && fileName) {
      setFileList([
        {
          uid: "-1",
          name: `${fileName}`,
          status: "done",
          url: `${value}`,
        },
      ]);
    }
  }, [value, fileName]);

  const removeFile = () => {};

  const handleChange = ({ fileList }) => {
    setFileList(fileList);
  };

  return (
    <Upload
      customRequest={fileUpload}
      listType="image-upload-control"
      fileList={fileList}
      maxCount={1}
      onChange={handleChange}
      onRemove={removeFile}
      accept={accept}
    >
      <CustomButton
        text={`${value ? "Update" : "Upload"} ${type}`}
        size="md"
        type="primary outlined"
      />
    </Upload>
  );
};

FileUpload.propTypes = {
  title: PropTypes.string,
  accept: PropTypes.string,
  onChange: PropTypes.func,
};

FileUpload.defaultProps = {
  title: "Upload file",
  accept: "",
  onChange: () => {},
};

export default FileUpload;
