/* eslint-disable no-template-curly-in-string */
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
// import FilterDrawer from "../Home/FilterDrawer";

import { getUser } from "redux/actions/home-actions";
import { getCouncilMembers, sendEmailsForPartOfCouncil } from "redux/actions/council-actions"
import { Modal } from "antd";
import { isEmpty } from "lodash";

import {
    getAllEvent,
} from "redux/actions/event-actions";
import {
    getChatLive,
    orderChatLive
} from "redux/actions/live-chat-actions";
// import { getCheckoutSession } from "api/module/stripe";

import {
    useLocation,
    Link
} from "react-router-dom";
// import Emitter from "services/emitter";

import { eventSelector } from "redux/selectors/eventSelector";
import { homeSelector } from "redux/selectors/homeSelector";
import { councilSelector } from "redux/selectors/councilSelector";
import { authSelector } from "redux/selectors/authSelector";
import { liveChatSelector } from "redux/selectors/liveChatSelector";
import IconBack from "images/icon-back.svg";

import { addToMyEventList } from "redux/actions/event-actions";

import { CustomButton } from "components";
// import { loadStripe } from "@stripe/stripe-js";
import { actions as councilEventActions } from "redux/actions/council-events-actions";

import clsx from "clsx";
import IconLogo from "images/logo-sidebar.svg";
import { CloseCircleFilled, CloseOutlined } from "@ant-design/icons";
import MicroConferenceVideoWrapper from "./MicroConferenceVideoWrapper";
import Arrow from "../../images/arrow-conference.svg"

import {
    INTERNAL_LINKS,
    // STRIPE_PRICES
    // EVENT_TYPES 
} from "enum";

import "./style.scss";

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_KEY);

const HomePage = ({
    // history,
    // location,
    userProfile,
    getUser,
    // allEvents,
    getCouncilMembers,
    // councilMembers,
    // sendEmailsForPartOfCouncil,
    getAllEvent,
    // isAuthenticated,
    // updatedEvent,
    // live,
    // redirectHome,
    // getCouncilEvents,
    getChatLive,
    liveChat,
    orderChatLive
}) => {

    const { search } = useLocation();
    const query = new URLSearchParams(search);

    const [activeMessages, setActiveMessages] = useState('')
    const [bulModalMoreInformation, setBulModalMoreInformation] = useState(false)
    const [liveChatSelected, setLiveChatSelected] = useState({})
    const id = query?.get("id");

    // const id = query?.get("id");

    useEffect(() => {
        if (isEmpty(userProfile)) {
            getUser();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUser])
    
    useEffect(() => {
        getChatLive()
    }, [getChatLive])

    const containerFather = useRef(null)

    useEffect(() => {
        if (localStorage.getItem("community") === null) {
            getUser();
        }
    }, [getUser])

    useEffect(() => {
        getCouncilMembers()
        getAllEvent()
    }, [getCouncilMembers, getAllEvent])

    const orderLiveChatData = (data) => {
        return data.sort((a, b) => {

            return Number(b.idOrder) - Number(a.idOrder)

        })
    }

    const confirmIsNotEnd = (index) => {
        return liveChat.length - 1 === index
    }

    return (
        <div className="home-page-container" ref={containerFather}>
            {(activeMessages !== '') &&
                <div className="container-messages-general2" style={{ zIndex: "100000" }} onClick={() => { setActiveMessages('') }} >
                    <div className="messages">{activeMessages}<CloseOutlined style={{ marginLeft: '20px', cursor: 'pointer' }} onClick={() => { setActiveMessages('') }} /></div>
                </div>
            }
            <div className={(id !== undefined && id !== null) ? "first-session-live-chat-select" : "first-session-live-chat"} id="home-segment">

                {orderLiveChatData(liveChat)?.map((data, index) => {
                    return (
                        <div className={(id !== undefined && id !== null)
                            ? (Number(data.id) === Number(id)) ? 'container-live-chats-cards-select' : 'container-live-chats-cards-de-select'
                            : "container-live-chats-cards"
                        }>
                            {index !== 0 && (userProfile?.email === "morecontrol64@gmail.com" || userProfile?.email === "jessa@hackinghr.io" || userProfile?.email === "enrique@hackinghr.io") && <div className="container-arrow-left">
                                <div className="circle-arrow"><img src={Arrow} className="arrow-title" alt="arrow-title" onClick={() => {
                                    orderChatLive({
                                        position: {
                                            change: Number(liveChat[index - 1].idOrder),
                                            id: Number(data.id)
                                        },
                                        position2: {
                                            change: Number(data.idOrder),
                                            id: Number(liveChat[index - 1].id)
                                        }
                                    }, () => {
                                        getChatLive()
                                    })
                                }} /></div>
                            </div>}
                            <div style={{ position: 'relative', width: 'auto', height: 'auto', marginTop: '20px' }}>
                                {(id !== undefined && id !== null) && <Link to={INTERNAL_LINKS.LIVE_CHAT} style={{
                                    position: 'absolute', top: '-30px', right: '-30px', display: 'flex', flexDirection: 'row'
                                }}><div style={{ fontSize: '20px', width: '20px', display: 'flex', marginRight: '10px' }}>
                                        <img src={IconBack} alt="icon-back" />
                                    </div>
                                    <h4>Back to Live Chat</h4></Link>}
                                <MicroConferenceVideoWrapper
                                    url={data.videoUrl ? data.videoUrl : null}
                                    id={data.id}
                                />
                                {(id === null || id === undefined) && <Link
                                    style={{ position: 'absolute', top: '0px', zIndex: '10', width: '100%', height: '80%' }}
                                    to={`${INTERNAL_LINKS.LIVE_CHAT}?id=${data.id}`}
                                ></Link>}
                            </div>
                            <CustomButton
                                className="button-speaker font-size-more"
                                text="More Information"
                                size="md"
                                style={{ padding: '10px', height: '40px', width: '100%', marginTop: '20px' }}
                                type={"primary"}
                                onClick={() => {
                                    setLiveChatSelected(data)
                                    setBulModalMoreInformation(true)
                                }}
                            />
                            {!confirmIsNotEnd(index) && (userProfile?.email === "morecontrol64@gmail.com" || userProfile?.email === "jessa@hackinghr.io" || userProfile?.email === "enrique@hackinghr.io") && <div className="container-arrow-right">
                                <div className="circle-arrow"><img src={Arrow} className="arrow-title" alt="arrow-title" onClick={() => {
                                    orderChatLive({
                                        position: {
                                            change: Number(liveChat[index + 1].idOrder),
                                            id: Number(data.id)
                                        },
                                        position2: {
                                            change: Number(data.idOrder),
                                            id: Number(liveChat[index + 1].id)
                                        }
                                    }, () => {
                                        getChatLive()
                                    })
                                }} /></div>
                            </div>}
                        </div>
                    )
                })}
            </div>
            <Modal
                className={clsx("custom-modal")}
                wrapClassName={clsx("custom-modal-wrap")}
                centered
                onCancel={() => {
                    setBulModalMoreInformation(false)
                    setLiveChatSelected({})
                }}
                visible={bulModalMoreInformation}
                closable={true}
                footer={[]}
                width="500px"
                style={{ top: "100px", padding: '0px' }}
                title={
                    <div
                        className="custom-modal-title"
                        style={{
                            paddingLeft: '20px',
                            paddingRight: '20px',
                            display: 'flex',
                            justifyContent: "center",
                            alignItems: 'start'
                        }}
                    >
                        <div className="custom-modal-logo">
                            <img src={IconLogo} alt="custom-logo" />
                        </div>
                    </div>
                }
                closeIcon={<CloseCircleFilled className="custom-modal-close" />}
            >
                <div className="library-claim-modal" style={{ padding: '0px' }}>
                    <h2 style={{ textAlign: 'center', fontSize: '18px' }}>
                        {liveChatSelected.title}
                    </h2>
                    <div style={{ marginTop: '20px' }} dangerouslySetInnerHTML={{ __html: liveChatSelected.description }} ></div>
                </div>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state) => ({
    isAuthenticated: authSelector(state).isAuthenticated,
    userProfile: homeSelector(state).userProfile,
    updatedEvent: eventSelector(state).updatedEvent,
    allEvents: eventSelector(state).allEvents,
    councilMembers: councilSelector(state).councilMembers,
    redirectHome: homeSelector(state).redirectHome,
    liveChat: liveChatSelector(state).liveChat
});

const mapDispatchToProps = {
    getUser,
    getCouncilMembers,
    sendEmailsForPartOfCouncil,
    getAllEvent,
    addToMyEventList,
    orderChatLive,
    getChatLive,
    ...councilEventActions
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
