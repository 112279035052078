/* eslint-disable no-template-curly-in-string */
import React, { useState, useRef, } from "react";
import { connect } from "react-redux";
import { getUser, sendEmailToEnrique } from "redux/actions/home-actions";
import { Modal, Form, Button, Input } from 'antd';

import { CustomButton, CustomInput } from "components";
import { homeSelector } from "redux/selectors/homeSelector";
import { authSelector } from "redux/selectors/authSelector";

import "./style.scss";


const ForHRVendors = ({
    sendEmailToEnrique
}) => {

    const [form] = Form.useForm();
    const formControl = useRef(null);
    const [bulModal, setBulModal] = useState(false)

    return (

        <div className="for-hr-vendors-container">
            <div className="first-session-vendors-container">
                <div className="container-divisions-for-hr-vendors">
                    <div className="image-content-banner-1"></div>
                    <div className="container-text-for-hr-vendors">
                        <p className="text-style">We are powering the future of HR with a global learning community of business and HR leaders, HR practitioners, technologists, consultants and vendors, working at the intersection of organizations, people, innovation, transformation and technology.</p>
                        <p className="text-style">Our annual vendor membership is focused on brand awareness and growth through thought leadership and subject matter expertise (demand generation). We are not the right space for direct lead generation (we don't sell or give out our members' contact information), nor do we directly promote specific products or services. In fact, for the HR community, the latter is a poor choice.What we do is provide you with as many spaces and opportunities as possible to be in front of our community and present your company as the authority in your space. In fact, we benefit also from your thought leadership as that helps us expand our content footprint.</p>
                    </div>
                </div>
                <div className="container-divisions-for-hr-vendors">
                    <div className="container-text-for-hr-vendors">
                        <ul>
                            <li className="text-style">We have an engaged community of HR leaders and professionals who regularly participate in our activities and engage with our content</li>
                            <li className="text-style">We have built a reputation with engaging, usable and valuable content for the HR community</li>
                            <li className="text-style">We are flexible in the way we engage with you through this vendor membership. We want to make this work. Yes, everybody says the same, but we are open to experimenting with you and find out the kind of content and delivery that brings value to you and helps you achieve the expected outcomes. In fact, we have tried so many different things that we have built additional expertise in the kind of content and delivery that resonate with HR audience</li>
                            <li className="text-style">We will do our best to convince you of something we (and you) already know: transactional activities (sending emails on your behalf or posting your logo somewhere or promoting discounted services or subscriptions to SaaS) don't work and people will unsubscribe from our emails before they even read what you have to say, whereas co-creating something really cool and valuable is much more effective, even if it takes a bit longer</li>
                        </ul>
                    </div>
                    <div className="image-content-banner-2"></div>
                </div>
                <div className="container-divisions-for-hr-vendors">
                    <div className="image-content-banner-3"></div>
                    <div className="container-text-for-hr-vendors">
                        <p className="text-style">An annual vendor membership includes, among other benefits:</p>
                        <ul>
                            <li className="text-style">Invitation to speak at any of the Hacking HR events</li>
                            <li className="text-style">Thought leadership content sharing across Hacking HR's social media channels</li>
                            <li className="text-style">Content co-created between vendor and Hacking HR team, including:
                                <ul>
                                    <li className="text-style">Write ups for the Hacking HR LinkedIn Newsletter (content co-created with Hacking HR) in a format that can be later on repurpose in image/video formats for other social media</li>
                                    <li className="text-style">Images/snapshots as a quick reference about important topics</li>
                                    <li className="text-style">Short videos/clips</li>
                                    <li className="text-style">And we are TOTALLY open to your ideas on engaging and valuable content/format we can co-create together</li>
                                </ul>
                            </li>
                            <li className="text-style">Content created by vendor, including:
                                <ul>
                                    <li className="text-style">E-books, guides, blog posts, etc.</li>
                                    <li className="text-style">Events</li>
                                </ul>
                            </li>
                            <li className="text-style">Depending on Hacking HR's annual event agenda, one event co-created between vendor and Hacking HR (panel, fireside chat, etc., generally broadcasted on LinkedIn Live and other social media channels - with or without previous registration)</li>
                            <li className="text-style">Tokens: the Hacking HR Lab (our community platform) includes space for advertising in the Home, Conference Library and/or Event pages. Advertisement requires purchasing tokens (10 tokens = $150 | 20 tokens = $260 | 30 tokens = $360 | 50 tokens = $500). You will receive 200 tokens as part of your annual membership.</li>
                            <li className="text-style">Goodies: who doesn't love cool (and useful!) goodies. So, we do a lot of activities and love to raffle useful stuff. For example, we are running "kindness" and "fighting burnout" challenges. If you have goodies to raffle, we will make sure to include your brand/logo/link in whatever promotion we generate about the activity that has raffle with prizes donated by you</li>
                            <li className="text-style">Annual vendor membership: Get in touch with us to find out</li>
                        </ul>
                    </div>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <CustomButton
                        className="button-speaker font-size-more"
                        text="Find Out More"
                        size="lg"
                        style={{ padding: '5px', width: '360px', height: '40px', marginTop: '20px' }}
                        type={"primary"}
                        onClick={() => {
                            setBulModal(true)
                        }}
                    />
                </div>
            </div>
            <div className="second-session-vendors-container">
                <div className="container-tittle">
                    <p>Membership Benefits</p>
                </div>
                <div className="container-cards">
                    <div className="cards-vendors">
                        <div className="container-image">
                            <div className="image-1-vendors"></div>
                        </div>
                        <div className="container-text">
                            <p className="tittle-banner">Thought Leadership</p>
                            <p className="text-banner">Opportunities to be in front of our community as a thought leader in events, posts, write ups and more</p>
                        </div>
                    </div>
                    <div className="cards-vendors">
                        <div className="container-image">
                            <div className="image-2-vendors"></div>
                        </div>
                        <div className="container-text">
                            <p className="tittle-banner">Social Media</p>
                            <p className="text-banner">Amplification of relevant content (guides, ebooks, white papers, research, etc.) through our social media channels (over 700k subscribers across our channels)</p>
                        </div>
                    </div>
                    <div className="cards-vendors">
                        <div className="container-image">
                            <div className="image-3-vendors"></div>
                        </div>
                        <div className="container-text">
                            <p className="tittle-banner">Content co-creation</p>
                            <p className="text-banner">Valuable opportunities to co-create content together with us</p>
                        </div>
                    </div>
                    <div className="cards-vendors">
                        <div className="container-image">
                            <div className="image-4-vendors"></div>
                        </div>
                        <div className="container-text">
                            <p className="tittle-banner">Creative ideas</p>
                            <p className="text-banner">We are open to creative ideas to make sure this membership delivers extraordinary to your brand and our community</p>
                        </div>
                    </div>
                </div>
                <div className="container-buttom">
                    <CustomButton
                        className="button-speaker font-size-more"
                        text="Find Out More"
                        size="lg"
                        style={{ padding: '5px', width: '360px', height: '40px', marginTop: '20px' }}
                        type={"primary"}
                        onClick={() => {
                            setBulModal(true)
                        }}
                    />
                </div>
            </div>
            <Modal
                visible={bulModal}
                footer={[
                    <Button key="cancel" onClick={() => {
                        setBulModal(false);
                        form.resetFields();
                    }}>
                        Cancel
                    </Button>,
                    <Button key="send" type="primary" onClick={() => {
                        form.submit()
                    }}>
                        Send
                    </Button>,
                ]}
                width={600}
                bodyStyle={{ marginBottom: '40px', padding: "30px", display: 'flex', justifyContent: 'center', flexDirection: 'column' }}
                onCancel={() => {
                    setBulModal(false);
                    form.resetFields();
                }}
            >
                <h1 style={{ textAlign: 'center', width: '100%', paddingBottom: '20px' }}>Find Out More</h1>
                <Form
                    form={form}
                    layout="vertical"
                    ref={formControl}
                    onFinish={(e) => {
                        sendEmailToEnrique(e, () => {
                            setBulModal(false);
                            form.resetFields();
                        })
                    }}
                >
                    <Form.Item
                        name="name"
                        rules={[{ required: true }]}
                        label="Name"
                    >
                        <CustomInput />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        rules={[{ required: true }]}
                        label="Email"
                    >
                        <CustomInput />
                    </Form.Item>
                    <Form.Item
                        name="content"
                        rules={[{ required: true }]}
                        label="Content"
                    >
                        <Input.TextArea />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

const mapStateToProps = (state) => ({
    isAuthenticated: authSelector(state).isAuthenticated,
    userProfile: homeSelector(state).userProfile,
    redirectHome: homeSelector(state).redirectHome,
});

const mapDispatchToProps = {
    getUser,
    sendEmailToEnrique,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForHRVendors);