import { put, fork, takeLatest, call } from "redux-saga/effects";

import {
  constants as courseConstants,
  actions as courseActions,
} from "../actions/course-actions";
import { actions as homeActions } from "../actions/home-actions";
import {
  get,
  getAll,
  getCourseClasses,
  getCourseInstructors,
  getCourseSponsors,
  claimCourse,
  getCourses,
  getCourse,
  registrationInACertificateEndPoint,
  exportAllUsersOfOneConference,
  updateActivityStatus,
  getCourseActivityStatusInfo,
  getCertificates,
  getCertificateBySlug,
  getCertificateUsersBySlug,
} from "../../api/module/course";

export function* getCoursesSaga({ payload }) {
  yield put(homeActions.setLoading(true));

  try {
    

    if (payload.type === "certificate") {
      const response = yield call(getCertificates, payload);

      if (response.status === 200) {
        yield put(courseActions.setCourses(response.data.courses));
      }
    } else {
      const response = yield call(getCourses, payload);
      if (response.status === 200) {
        // insert here
      }
    }

  } catch (error) {
    console.log(error);
  } finally {
    yield put(homeActions.setLoading(false));
  }
}

export function* getCourseSagaNew({ payload }) {
  yield put(homeActions.setLoading(true));

  try {
    const response = yield call(getCertificateBySlug, payload);

    if (response.status === 200) {
      yield put(courseActions.setCourseNew(response.data.certificate));
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put(homeActions.setLoading(false));
  }
}

export function* registerInACertificateSagaNew({ payload }) {
  yield put(homeActions.setLoading(true));

  try {
    const response = yield call(
      registrationInACertificateEndPoint,
      payload.certificateId
    );

    if (payload.callback) {
      payload.callback(true);
      yield put(homeActions.setLoading(false));
    }
  } catch (error) {
    if (payload.callback) {
      payload.callback(false);
    }
    yield put(homeActions.setLoading(false));
    console.log(error);
  }
}

export function* updateActivityStatusSaga({ payload }) {
  yield put(homeActions.setLoading(true));

  try {
    const response = yield call(updateActivityStatus, payload.id);

    if (response.status === 200) {
      const courseResponse = yield call(getCertificateBySlug, { slug: payload.slug });

      if (courseResponse.status === 200) {
        yield put(courseActions.setCourseNew(courseResponse.data.certificate));
      }

      if (payload.callback) {
        payload.callback()
      }
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put(homeActions.setLoading(false));
  }
}

export function* getCourseActivityStatusInfoSaga({ payload }) {
  // yield put(homeActions.setLoading(true));


  try {
    const response = yield call(getCourseActivityStatusInfo, {
      courseId: payload.courseId,
    });

    if (response.status === 200) {
      // yield put(homeActions.setLoading(false));
      yield put(
        courseActions.setCourseActivityStatusInfo(
          response.data.courseActivityStatusInfo
        )
      );
    }
  } catch (error) {
    // yield put(homeActions.setLoading(false));
    console.error(error);
  } finally {
    // yield put(homeActions.setLoading(false));
  }
}

export function* getAllCoursesSaga({ payload }) {
  yield put(homeActions.setLoading(true));

  try {
    const response = yield call(getAll, payload);

    if (response.status === 200) {
      yield put(courseActions.setAllCourses(response.data.courses));
    }

    yield put(homeActions.setLoading(false));
  } catch (error) {
    console.log(error);
    yield put(homeActions.setLoading(false));
  }
}

export function* getCourseSaga({ payload }) {
  yield put(homeActions.setLoading(true));

  try {
    const response = yield call(get, payload.id);

    if (response.status === 200) {
      yield put(courseActions.setCourse(response.data.course));
    }

    yield put(homeActions.setLoading(false));
  } catch (error) {
    console.log(error);
    yield put(homeActions.setLoading(false));
  }
}

export function* getCourseClassesSaga({ payload }) {
  yield put(homeActions.setLoading(true));

  try {
    const response = yield call(getCourseClasses, payload.id);

    if (response.status === 200) {
      yield put(courseActions.setCourseClasses(response.data.courseClasses));
    }

    yield put(homeActions.setLoading(false));
  } catch (error) {
    console.log(error);
    yield put(homeActions.setLoading(false));
  }
}

export function* getCourseInstructorsSaga({ payload }) {
  yield put(homeActions.setLoading(true));
  try {
    const response = yield call(getCourseInstructors, payload.id);

    if (response.status === 200) {
      yield put(courseActions.setCourseInstructors(response.data.instructors));
    }

    yield put(homeActions.setLoading(false));
  } catch (error) {
    console.log(error);
    yield put(homeActions.setLoading(false));
  }
}

export function* getUsersOfACertificateSaga({ payload }) {
  yield put(homeActions.setLoading(true));
  try {
    const response = yield call(
      exportAllUsersOfOneConference,
      payload.certificateId
    );
    // console.log('RESPONSEEEEE::   ',response)
    if (response.status === 200) {
      yield put(courseActions.setUsersOfACertificate(response.data.users));
    }

    yield put(homeActions.setLoading(false));
  } catch (error) {
    console.log(error);
    yield put(homeActions.setLoading(false));
  }
}

export function* getCourseSponsorsSaga({ payload }) {
  yield put(homeActions.setLoading(true));

  try {
    const response = yield call(getCourseSponsors, payload.id);

    if (response.status === 200) {
      yield put(courseActions.setCourseSponsors(response.data.sponsors));
    }

    yield put(homeActions.setLoading(false));
  } catch (error) {
    console.log(error);
    yield put(homeActions.setLoading(false));
  }
}

export function* claimCourseSaga({ payload }) {
  yield put(homeActions.setLoading(true));

  try {
    const response = yield call(claimCourse, { ...payload });

    if (response.status === 200) {
      if (payload.callback) {
        payload.callback("");
      }
    }
  } catch (error) {
    if (payload.callback) {
      payload.callback(
        error.response.data || "Something went wrong, Please try again."
      );
    }
  } finally {
    yield put(homeActions.setLoading(false));
  }
}

export function* getCertificateUsersBySlugSaga({ payload }) {
  yield put(homeActions.setLoading(true));

  try {
    const response = yield call(getCertificateUsersBySlug, payload);

    if (response.status === 200) {
      yield put(homeActions.setLoading(false));
      yield put(courseActions.setCertificateUsersBySlug(response.data));
    }
  } catch (error) {
    console.log(error);
    yield put(homeActions.setLoading(false));
  } finally {
    yield put(homeActions.setLoading(false));
  }
}

function* watchLogin() {
  yield takeLatest(courseConstants.GET_COURSES, getCoursesSaga);
  yield takeLatest(courseConstants.GET_COURSE_NEW, getCourseSagaNew);
  yield takeLatest(courseConstants.GET_ALL_COURSES, getAllCoursesSaga);
  yield takeLatest(courseConstants.GET_COURSE, getCourseSaga);
  yield takeLatest(courseConstants.GET_COURSE_CLASSES, getCourseClassesSaga);
  yield takeLatest(
    courseConstants.REGISTER_IN_A_CERTIFICATE,
    registerInACertificateSagaNew
  );
  yield takeLatest(
    courseConstants.GET_COURSE_INSTRUCTORS,
    getCourseInstructorsSaga
  );
  yield takeLatest(courseConstants.GET_COURSE_SPONSORS, getCourseSponsorsSaga);
  yield takeLatest(courseConstants.CLAIM_COURSE, claimCourseSaga);
  yield takeLatest(
    courseConstants.UPDATE_ACTIVITY_STATUS,
    updateActivityStatusSaga
  );
  yield takeLatest(
    courseConstants.GET_COURSE_ACTIVITY_STATUS_INFO,
    getCourseActivityStatusInfoSaga
  );
  yield takeLatest(courseConstants.GET_CERTIFICATE_USERS_BY_SLUG, getCertificateUsersBySlugSaga)
}

export const courseSaga = [fork(watchLogin)];
