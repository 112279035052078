import { put, fork, takeLatest, call } from "redux-saga/effects";

import {
  constants as annualConferenceClassConstants,
  actions as annualConferenceClassActions,
} from "../actions/annualConferenceClass-actions";
import { actions as homeActions } from "../actions/home-actions";
import {
  getAnnualConferenceClasses,
  getAnnualConferenceClass,
  addAnnualConferenceClass,
  updateAnnualConferenceClass,
  deleteAnnualConferenceClass,
} from "../../api";

export function* getAnnualConferenceClassesSaga({ payload }) {
  yield put(homeActions.setLoading(true));

  try {
    const response = yield call(
      getAnnualConferenceClasses,
      payload.annualConferenceId,
      payload.type
    );

    if (response.status === 200) {
      const { annualConferenceClasses } = response.data;

      if(payload.callback){
        payload.callback()
      }

      yield put(
        annualConferenceClassActions.setAnnualConferenceClasses(
          annualConferenceClasses
        )
      );
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put(homeActions.setLoading(false));
  }
}

export function* getAnnualConferenceClassSaga({ payload }) {
  yield put(homeActions.setLoading(true));

  try {
    const response = yield call(getAnnualConferenceClass);

    if (response.status === 200) {

      yield put(
        annualConferenceClassActions.setAnnualConferenceClass(
          response.data.annualConferenceclass
        )
      );
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put(homeActions.setLoading(false));
  }
}

export function* addAnnualConferenceClassSaga({ payload }) {
  yield put(homeActions.setLoading(true));
  let tipe = payload.annualConferenceClass.type

  try {

    let response = yield call(addAnnualConferenceClass, { ...payload });

    if (response.status === 200) {

      const type = (tipe !== "2022" || tipe !== undefined || tipe !== null) ? tipe : '2022' 

      response = yield call(
        getAnnualConferenceClasses,
        payload.annualConferenceClass.AnnualConferenceId,
        type
      );

      if (response.status === 200) {
        const { annualConferenceClasses } = response.data;

        if (payload.callback) {
          payload.callback();
        }

        yield put(
          annualConferenceClassActions.setAnnualConferenceClasses(
            annualConferenceClasses
          )
        );
      }
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put(homeActions.setLoading(false));
  }
}

export function* updateAnnualConferenceClassSaga({ payload }) {
  yield put(homeActions.setLoading(true));
  let tipe = payload.annualConferenceClass.type

  try {
    let response = yield call(updateAnnualConferenceClass, { ...payload });

    if (response.status === 200) {

      const type = (tipe !== "2022" || tipe !== undefined || tipe !== null) ? tipe : '2022' 

      response = yield call(
        getAnnualConferenceClasses,
        payload.annualConferenceClass.AnnualConferenceId,
        type
      );

      if (response.status === 200) {
        const { annualConferenceClasses } = response.data;

        if (payload.callback) {
          payload.callback();
        }

        yield put(
          annualConferenceClassActions.setAnnualConferenceClasses(
            annualConferenceClasses
          )
        );
      }
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put(homeActions.setLoading(false));
  }
}

export function* deleteAnnualConferenceClassSaga({ payload }) {
  yield put(homeActions.setLoading(true));
  let tipe = payload.annualConferenceClass.type

  try {
    let response = yield call(deleteAnnualConferenceClass, { ...payload });

    if (response.status === 200) {

      const type = (tipe !== "2022" || tipe !== undefined || tipe !== null) ? tipe : '2022' 

      response = yield call(
        getAnnualConferenceClasses,
        payload.annualConferenceClass.AnnualConferenceId,
        type
      );

      if (response.status === 200) {
        const { annualConferenceClasses } = response.data;

        

        yield put(
          annualConferenceClassActions.setAnnualConferenceClasses(
            annualConferenceClasses
          )
        );
      }
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put(homeActions.setLoading(false));
  }
}

function* watchCategory() {
  yield takeLatest(
    annualConferenceClassConstants.GET_ANNUAL_CONFERENCE_CLASSES,
    getAnnualConferenceClassesSaga
  );
  yield takeLatest(
    annualConferenceClassConstants.GET_ANNUAL_CONFERENCE_CLASS,
    getAnnualConferenceClassSaga
  );
  yield takeLatest(
    annualConferenceClassConstants.ADD_ANNUAL_CONFERENCE_CLASS,
    addAnnualConferenceClassSaga
  );
  yield takeLatest(
    annualConferenceClassConstants.UPDATE_ANNUAL_CONFERENCE_CLASS,
    updateAnnualConferenceClassSaga
  );
  yield takeLatest(
    annualConferenceClassConstants.DELETE_ANNUAL_CONFERENCE_CLASS,
    deleteAnnualConferenceClassSaga
  );
}

export const annualConferenceClassSaga = [fork(watchCategory)];
