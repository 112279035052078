import React from "react";
// import { connect } from "react-redux";
import { INTERNAL_LINKS } from "enum";
// import { Route } from "react-router-dom";
import { PrivateRoute } from "components";
import { Sidebar } from "components";

const startRouteBlogs1 = () => {

  return (
    <>
      {localStorage.getItem("community") !== null &&
        <PrivateRoute
            path={`${INTERNAL_LINKS.BLOGS}/:id`}
            exact
            render={(props) => <Sidebar typeStyle={'black'} {...props} />}
        />
      }
    </>
  );
};

export default startRouteBlogs1;