import { handleActions } from "redux-actions";
import { Map } from "immutable";
import cloneDeep from "lodash/cloneDeep";

// Action Type Imports
import { constants as crashCourseConstants } from "../actions/crash-course-actions";
import { constants as crashCourseProgressConstants } from "../actions/crash-course-user-progress-actions";

// CRASH COURSE's Reducer
export const reducers = {
  [crashCourseConstants.SET_ALL_CRASH_COURSES]: (state, { payload }) => {
    return state.merge({ allCrashCourses: cloneDeep(payload.allCrashCourses) });
  },
  [crashCourseConstants.SET_CRASH_COURSE]: (state, { payload }) => {
    return state.merge({ crashCourse: payload.crashCourse });
  },
  [crashCourseConstants.SET_CRASH_COURSE_SLIDES]: (state, { payload }) => {
    return state.merge({ crashCourseSlides: cloneDeep(payload.crashCourseSlides) });
  },
  [crashCourseConstants.SET_LOADING]: (state, { payload }) => {
    // return state.merge({ ...payload });
  },
  [crashCourseProgressConstants.SET_CRASH_COURSE_USER_PROGRESS]: (state, { payload }) => {
    return state.merge({ crashCourseProgress: cloneDeep(payload.crashCourseProgress) });
  },
};

export const initialState = () =>
  Map({
    loading: false,
    allCrashCourses: [],
    crashCourse: {},
    crashCourseSlides: [],
    crashCourseProgress: {}
  });

export default handleActions(reducers, initialState());
