import React from "react";

const BoxItered = ({
    box,
    columnSelect,
    columnSelect2,
    rowSelect,
    rowSelect2
}) => {

    return (
        <>
            {box.bulShowBox === 'true' && <div
                id="reference-checkbox"
                style={{
                    background: `${box.showColor === 'true' ? 'rgba(217, 217, 217, 0.54)' : 'transparent'}`,
                    gridColumn: `${columnSelect}/${columnSelect2}`,
                    gridRow: `${rowSelect}/${rowSelect2}`,
                    display: (columnSelect === columnSelect2 && rowSelect2 === rowSelect && rowSelect2 === 0 && columnSelect2 === 0) ? 'none' : 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: '9',
                    position: 'relative'
                }}
            >
                <p style={{
                    fontWeight: `${box.boldTextBox === 'true' ? 'bold' : 'normal'}`,
                    padding: '0px',
                    margin: '0px',
                    fontSize: `${box.fontSizeBox}%`,
                    color: box.colorTextBox,
                    lineHeight: box.lineHeightBox,
                    textAlign: 'center',
                    whiteSpace: box.lineTextBox === 'true' ? 'pre' : '',
                    boxSizing: 'content-box',
                    userSelect: 'none',
                }}>{box.textName}</p>

            </div>}
        </>
    );
};

export default BoxItered;