import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
// Sider
import { Sidebar, PrivateRoute } from "components";
import StartRouteSwiftSider from "./StartRouteSwiftSider";
import VerifyCertificateSwiftSider from "./VerifyCertificateSwiftSider";
import LiveChatRouteSwiftSider from "./LiveChatRouteSwiftSider";

import StartRouteBlogs1 from "./StartRouteBlogs/1";
import StartRouteBlogs2 from "./StartRouteBlogs/2";
import StartRouteBlogsNew from "./StartRouteBlogsNew/1";

import EventsCouncilShift from "./EventsCouncilShift";

// Enum
import { INTERNAL_LINKS } from "enum";
import CertificateDiploma from "pages/CertificateDiploma/CertificateDiploma";

class Sider extends Component {
  render() {
    return (
      <Switch>
        <PrivateRoute
          path={INTERNAL_LINKS.HOME}
          exact
          render={() => <Sidebar typeStyle={'black'} />}
        />
        <Route path={INTERNAL_LINKS.LANDING_PAGUE} />
        <Route path={INTERNAL_LINKS.LOGIN} />
        <Route path={INTERNAL_LINKS.CREATORS} />
        <Route path={INTERNAL_LINKS.FOR_HR_VENDORS} />
        <Route path={INTERNAL_LINKS.BLOGS_PAGUE} />
        <Route path={INTERNAL_LINKS.SIGNUP} />
        <PrivateRoute path={INTERNAL_LINKS.HEART} render={() => <Sidebar typeStyle={'black'} />} />
        <PrivateRoute
          path={INTERNAL_LINKS.LEARNING_LIBRARY}
          render={() => <Sidebar typeStyle={'black'} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.CERTIFICATES}
          render={() => <Sidebar typeStyle={'black'} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.DASHBOARDS_REFERENCE}
          render={() => <Sidebar typeStyle={'black'} />}
        />
         <PrivateRoute
          path={`${INTERNAL_LINKS.PROFILE}`}
          render={() => <Sidebar typeStyle={'black'} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.CORPORATE_ADMIN}
          render={() => <Sidebar typeStyle={'black'} />}
        />
        <PrivateRoute
          path={`${INTERNAL_LINKS.CERTIFICATES}/:id`}
          render={() => <Sidebar typeStyle={'black'} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.CERTIFICATES_DASHBOARD}
          render={() => <Sidebar typeStyle={'black'} />}
        />
        <PrivateRoute
          path={`${INTERNAL_LINKS.CERTIFICATES_DASHBOARD}/:id`}
          render={() => <Sidebar typeStyle={'black'} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.LEADERSHIP_PROGRAMS}
          render={() => <Sidebar typeStyle={'black'} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.CLASSES}
          render={() => <Sidebar typeStyle={'black'} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.MENTORING}
          render={() => <Sidebar typeStyle={'black'} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.MICRO_CLASS}
          render={() => <Sidebar typeStyle={'black'} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.CHALLENGE}
          render={() => <Sidebar typeStyle={'black'} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.BONFIRES}
          render={() => <Sidebar typeStyle={'black'} />}
        />
        <PrivateRoute path={INTERNAL_LINKS.EVENTS} render={() => <Sidebar typeStyle={'black'} />} />
        <PrivateRoute
          path={INTERNAL_LINKS.CHANNELS}
          exact
          render={() => <Sidebar typeStyle={'black'} />}
        />

        <PrivateRoute
          path={`${INTERNAL_LINKS.CHANNELS}/:id`}
          exact
          render={() => <Sidebar typeStyle={'black'} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.COUNCIL}
          render={() => <Sidebar typeStyle={'black'} />}
        />
        <PrivateRoute
          path={`${INTERNAL_LINKS.COUNCIL}/resource`}
          render={() => <Sidebar typeStyle={'black'} />}
        />
        <Route
          path={`${INTERNAL_LINKS.EVENTS_COUNCIL}`}
          render={() => <EventsCouncilShift />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.COUNCIL_ADMIN}
          render={(props) => <Sidebar typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.BUSINESS_PARTNER}
          render={() => <Sidebar typeStyle={'black'} />}
        />
        <PrivateRoute
          path={`${INTERNAL_LINKS.BUSINESS_PARTNER}/resource`}
          render={() => <Sidebar typeStyle={'black'} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.CERTIFICATIONS}
          render={() => <Sidebar typeStyle={'black'} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.JOURNEY}
          render={() => <Sidebar typeStyle={'black'} />}
        />
        <PrivateRoute path={INTERNAL_LINKS.HUB} render={() => <Sidebar typeStyle={'black'} />} />
        <PrivateRoute
          path={INTERNAL_LINKS.NOTIFICATIONS}
          render={() => <Sidebar typeStyle={'black'} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.PODCAST}
          render={() => <Sidebar typeStyle={'black'} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.FAVORITES}
          render={() => <Sidebar typeStyle={'black'} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.READ_LATER}
          render={() => <Sidebar typeStyle={'black'} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.MARKETPLACE}
          render={() => <Sidebar typeStyle={'black'} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.CONFERENCE_LIBRARY}
          render={() => <Sidebar typeStyle={'black'} />}
        />
        {/* <PrivateRoute
          path={INTERNAL_LINKS.GLOBAL_CONFERENCE}
          render={() => <Sidebar typeStyle={'black'} />}
        /> */}
        <PrivateRoute
          path={`${INTERNAL_LINKS.MICRO_CONFERENCE}/:id`}
          render={(props) => <Sidebar typeStyle={'black'} {...props} />}
        />
        <PrivateRoute
          path={`${INTERNAL_LINKS.SPEAKERS}`}
          render={() => <Sidebar typeStyle={'black'} />}
        />
        <PrivateRoute
          path={`${INTERNAL_LINKS.PARTICIPANTS}/:idConference`}
          render={() => <Sidebar typeStyle={'black'} />}
        />
        <PrivateRoute path={INTERNAL_LINKS.LIVE} render={() => <Sidebar typeStyle={'black'} />} />
        <PrivateRoute
          path={INTERNAL_LINKS.PODCAST_SERIES}
          render={() => <Sidebar typeStyle={'black'} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.SPEAKER_2023}
          render={() => <Sidebar typeStyle={'black'} />}
        />
        <PrivateRoute path={INTERNAL_LINKS.POST} render={() => <Sidebar typeStyle={'black'} />} />
        <PrivateRoute
          path={INTERNAL_LINKS.LIBRARY_ITEM}
          render={() => <Sidebar typeStyle={'black'} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.PROJECTX}
          render={() => <Sidebar typeStyle={'black'} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.MY_LEARNINGS}
          render={() => <Sidebar typeStyle={'black'} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.SPONSOR_DASHBOARD}
          render={() => <Sidebar typeStyle={'black'} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.TALENT_MARKETPLACE}
          render={() => <Sidebar typeStyle={'black'} />}
        />
        <PrivateRoute
          path={`${INTERNAL_LINKS.AD_HOME_PREVIEW}/:id`}
          exact
          render={() => <Sidebar typeStyle={'black'} />}
        />
        <PrivateRoute
          path={`${INTERNAL_LINKS.AD_CONFERENCE_LIBRARY_PREVIEW}/:id`}
          exact
          render={() => <Sidebar typeStyle={'black'} />}
        />
        <PrivateRoute
          path={`${INTERNAL_LINKS.AD_EVENTS_PREVIEW}/:id`}
          exact
          render={() => <Sidebar typeStyle={'black'} />}
        />
        <PrivateRoute
          path={`${INTERNAL_LINKS.AD_PROJECT_X_PREVIEW}/:id`}
          exact
          render={() => <Sidebar typeStyle={'black'} />}
        />
        <PrivateRoute
          path={INTERNAL_LINKS.SEARCH}
          exact
          render={() => <Sidebar typeStyle={'black'} />}
        />

        <Route
          path={`${INTERNAL_LINKS.CHANNELS}/:id${INTERNAL_LINKS.BLOGS}/:id`}
          exact
          render={() => <StartRouteBlogs2 />}
        />

        <PrivateRoute
          path={INTERNAL_LINKS.BLOGS}
          exact
          render={() => <Sidebar typeStyle={'black'} />}
        />

        <PrivateRoute
          path={INTERNAL_LINKS.BLOGS_NEW}
          exact
          render={() => <Sidebar typeStyle={'black'} />}
        />

        <PrivateRoute
          path={INTERNAL_LINKS.BLOGS_FOR_USERAPROVED}
          exact
          render={() => <Sidebar typeStyle={'black'} />}
        />
        {/* <PrivateRoute
          path={INTERNAL_LINKS.RESOURCES_FOR_USERAPROVED}
          exact
          render={() => <Sidebar typeStyle={'black'} />}
        /> */}
        <Route
          path={`${INTERNAL_LINKS.BLOGS}/:id`}
          exact
          render={() => <StartRouteBlogs1 />}
        />

        <Route
          path={`${INTERNAL_LINKS.BLOGS_NEW}/:id`}
          exact
          render={() => <StartRouteBlogsNew />}
        />

        <PrivateRoute
          path={`${INTERNAL_LINKS.SIMULATION_SPRINTS}`}
          exact
          render={() => <Sidebar typeStyle={'black'} />}
        />

        <PrivateRoute
          path={`${INTERNAL_LINKS.SIMULATION_SPRINTS}/:id`}
          render={() => <Sidebar typeStyle={'black'} />}
        />
        <PrivateRoute
          exact
          path={INTERNAL_LINKS.GROUPGROWS}
          render={(props) => <Sidebar typeStyle={'black'} />}
        />
        <Route
          exact
          path={`${INTERNAL_LINKS.LIVE_CHAT}`}
          render={(props) => <LiveChatRouteSwiftSider {...props} />}
        />

        {localStorage.getItem("community") && (
          <Route
            path={`${INTERNAL_LINKS.CERTIFICATE_DIPLOMA_VERIFY}/:uniqueCode`}
            render={(props) => <Sidebar typeStyle={"black"} {...props} />}
          />
        )}
      <Route
          exact
          path={`${INTERNAL_LINKS.VERIFY}/:code`}
          render={(props) => <VerifyCertificateSwiftSider {...props} />}
        />
        <Route
          exact
          path={"/:data"}
          render={(props) => <StartRouteSwiftSider {...props} />}
        />
      </Switch>
    );
  }
}

export default Sider;
