import React from "react";
// import { connect } from "react-redux";
import { INTERNAL_LINKS } from "enum";
import { Route } from "react-router-dom";
import { Sidebar } from "components";

const LiveChatRouteSwiftSider = () => {

    return (
        <>
            {localStorage.getItem("community") !== null ?
                <Route
                    path={`${INTERNAL_LINKS.LIVE_CHAT}`}
                    render={(props) => <Sidebar typeStyle={'black'} {...props} />}
                />
                :
                <Route
                    path={`${INTERNAL_LINKS.LIVE_CHAT}`}
                />
            }


        </>
    );
};

// const mapStateToProps = (state) => ({
// });

// const mapDispatchToProps = {
// };

export default LiveChatRouteSwiftSider;