import React, { useEffect, useState } from "react";
import {
  Avatar,
  Dropdown,
  Menu,
  Form,
  Popconfirm,
  AutoComplete,
  // Collapse,
  Switch,
  notification,
  Tooltip,
  Modal,
  Select,
  Space,
} from "antd";
import PhotoUploadForm from "../../../../containers/ProfileDrawer/PhotoUploadForm";
import { CustomButton, CustomModal, CustomInput } from "components";
import Emitter from "services/emitter";
import { DownOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { debounce } from "lodash";
import { EVENT_TYPES, INTERNAL_LINKS } from "enum";

import { actions as councilEventActions } from "redux/actions/council-events-actions";
import { actions as speaker } from "redux/actions/speaker-actions";
import { actions as homeActions } from "redux/actions/home-actions";
import { councilEventSelector } from "redux/selectors/councilEventSelector";
import { homeSelector } from "redux/selectors/homeSelector";
import { speakerAllPanelSpeakerSelector } from "redux/selectors/speakerSelector";

// import CommentForm from "./CommentForm";
import "./style.scss";
import { convertToLocalTime } from "utils/format";
import UserProfileModal from "containers/UserProfileModal";
import ConferenceClass from "containers/ConferenceClass";
import { categorySelector } from "redux/selectors/categorySelector";
import { getOneUserById } from "api";
import { getAllExternalSpeakers } from "api/module/speakers";

// const { Panel } = Collapse;

const CouncilEventPanel = ({
  emailOfAdmin = undefined,
  panel,
  userProfile,
  joinCouncilEvent,
  joinSpeakersEventWait,
  joinExternalAwait,
  tz,
  userTimezone,
  status,
  removeCouncilEventPanelist,
  searchUserForCouncilEventPanelist,
  searchedUsersForCouncilEvent,
  closeMainModal,
  councilEventId,
  getCouncilEvents,
  joinCouncilEventWait,
  allCategories,
  eventData,
  isGlobalConference,
  removeUserSpeakerToPanel,
  addUserSpeakerToPanel,
  index,
  allUserSpeaker,
  getAllPanelSpeakers,
  getAllUserSpeaker,
  allPanelSpeakers,
  allMember,
  getAllMemberSpeakerPanel,
  getAllPanelsOfOneUserSpeakers,
  getAllPanelsOfOneGlobalConference,
  searchUsers,
  searchUser,
  searchedUsers,
  getPanelsForId,
  changeStatus,
  getAllPanelSpeakersAdmin,
  setIsModalSessionOpen,
  setSessionSelected,
  setEditSession,
  deletePanelSpeakers,
  updateData,
  addPanelSpeakers,
}) => {
  const { Option } = Select;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [openSearchUser, setOpenSearchUser] = useState(false);
  const [showProfileCompletionFirewall, setShowProfileCompletionFirewall] =
    useState(false);
  const [form] = Form.useForm();
  const [speakersUserForm] = Form.useForm();
  const [externalUserForm] = Form.useForm();
  const [showProfile, setShowProfile] = useState(false);
  const [userProfileToShow, setUserProfileToShow] = useState({});
  const [Panel, setPanel] = useState({});
  // const [panelsFullBul, setPanelsFullBul] = useState(false);
  const panelsFullBul = false;
  const [bulModelDelete, setBulModelDelete] = useState(false);
  const [bulModelClone, setBulModelClone] = useState(false);
  const [photoMoodalBul, setPhotoModalBul] = useState(false);
  const [isModalClasses, setIsModalClasses] = useState(false);
  // const [bulRedirectEditSession, setBulRedirectEditSession] = useState(false);
  // const [bulRedirectEditSponsor, setBulRedirectEditSponsor] = useState(false);
  // const [bulRedirectEditParraf, setBulRedirectEditParraf] = useState(false);
  const [editImageUrl, setEditImageUrl] = useState("");
  const [imageStr, setImageStr] = useState("");
  const [openExternalUser, setOpenExternalUser] = useState(false);
  const [addExternalUserExisting, setAddExternalUserExisting] = useState(false);
  const [externalSpeakers, setExternalSpeakers] = useState([])
  const [editExternalUser, setEditExternalUser] = useState(false);
  const [externalUser, setExternalUser] = useState(null);
  const [typeDelete, setTypeDelete] = useState("");
  // const [idData, setIdData] = useState(-1);
  const [title, setTitle] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [timerSearch, setTimerSearch] = useState(null);
  const [userToSearch, setUserToSearch] = useState(null);

  let startTime = convertToLocalTime(panel?.startDate, tz);
  let endTime = convertToLocalTime(panel?.endDate, tz);

  const handleJoinPanel = (panel, state) => {
    joinCouncilEvent(
      panel?.id,
      userProfile?.id,
      state,
      false,
      false,
      councilEventId,
      () => {
        getCouncilEvents();
      }
    );
  };

  useEffect(() => {
    if (editExternalUser && externalUserForm) {
      if (externalUser !== null) {
        const prevExternalUser = {
          firstName: externalUser.firstName,
          lastName: externalUser.lastName,
          email: externalUser.email,
          img: externalUser.img,
          keynote: (externalUser.isKeynote === true || externalUser.isKeynote === 'true') ? true : false,
          isModerator: (externalUser.isModerator === true || externalUser.isModerator === 'true') ? true : false,
          titleProfessions: externalUser.titleProfessions,
          linkedin: externalUser.linkedin,
          description: externalUser.description,
        };

        setEditImageUrl(externalUser.img);
        externalUserForm.setFieldsValue(prevExternalUser);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editExternalUser, externalUser]);

  useEffect(() => {
    if (timerSearch !== null) {
      clearTimeout(timerSearch);
    }
    if (userToSearch !== null) {
      setTimerSearch(
        setTimeout(() => {
          searchUser({ search: userToSearch, userAdmin: true }, () => {
            console.log("Searching...");
          }, true);
        }, 1000)
      );

      return () => {
        clearTimeout(timerSearch);
        setTimerSearch(null);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToSearch, searchUser]);

  // useEffect(() => {
  //   searchUser({ search: null, userAdmin: true }, () => {
  //     console.log("Searching...");
  //   }, true);
  // }, []);


  // useEffect(() => {
  //   getAllPanelSpeakers("All");
  //   getAllUserSpeaker(userProfile?.id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [getAllPanelSpeakers]);


  const onClickDownloadCalendar = (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(
      `${process.env.REACT_APP_API_ENDPOINT}/public/council/event/panel/${panel.id}/ics?userTimezone=${userTimezone}`,
      "_blank"
    );
  };

  const onClickAddGoogleCalendar = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let googleCalendarUrl = `http://www.google.com/calendar/event?action=TEMPLATE&text=${encodeURIComponent(
      panel.panelName
    )}&dates=${startTime.format("YYYYMMDDTHHmmSSS")}/${endTime.format(
      "YYYYMMDDTHHmmSSS"
    )}&details=${encodeURIComponent(`Link to join: ${panel.linkToJoin}`)}`;
    window.open(googleCalendarUrl, "_blank");
  };

  const onClickAddYahooCalendar = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let yahooCalendarUrl = `https://calendar.yahoo.com/?v=60&st=${startTime.format(
      "YYYYMMDDTHHmm"
    )}&et=${endTime.format("YYYYMMDDTHHmm")}&title=${encodeURIComponent(
      panel.panelName
    )}&desc=${encodeURIComponent(`Link to join: ${panel.linkToJoin}`)}`;

    window.open(yahooCalendarUrl, "_blank");
  };

  const downloadDropdownOptions = () => (
    <Menu style={{ position: "relative", bottom: "70px" }}>
      <Menu.Item key="1">
        <a href="/#" onClick={(e) => onClickDownloadCalendar(e)}>
          Download ICS File
        </a>
      </Menu.Item>
      <Menu.Item key="2">
        <a href="/#" onClick={(e) => onClickAddGoogleCalendar(e)}>
          Add to Google Calendar
        </a>
      </Menu.Item>
      <Menu.Item key="3">
        <a href="/#" onClick={(e) => onClickAddYahooCalendar(e)}>
          Add to Yahoo Calendar
        </a>
      </Menu.Item>
    </Menu>
  );

  const escapeDoubleQuotesWithinString = (cadena, startString='":"', endString='","') => {
    let dentroDelRango = false;
    let cadenaEscapada = '';

    for (let i = 0; i < cadena?.length; i++) {
      // Comprobamos si estamos dentro del rango startString y endString
      if (cadena.substring(i, i + startString?.length) === startString) {
        dentroDelRango = true;
        cadenaEscapada += startString;
        i += startString.length - 1; // Saltamos al final del startString
        continue;
      } else if (cadena.substring(i, i + endString?.length) === endString) {
        dentroDelRango = false;
        cadenaEscapada += endString;
        i += endString.length - 1; // Saltamos al final del endString
        continue;
      }

      // Escapamos las comillas si estamos dentro del rango y no son parte de `{"` o `"}` 
      if (dentroDelRango && cadena[i] === '"' && !(cadena[i - 1] === "{" && cadena[i] === '"') && !(cadena[i] === '"' && cadena[i + 1] === "}")) {
        cadenaEscapada += '\\"';
      } else {
        cadenaEscapada += cadena[i];
      }
    }

    return cadenaEscapada;
  }

  const filterModerators = (data) => {
    return data ? data?.filter(e => e.isModerator === false || e.isModerator === 'false') : data
  }

  const isFullCouncil = filterModerators(panel?.CouncilEventPanelists)?.length >= +panel?.numberOfPanelists;

  const isFullGlobal = (panel?.SpeakerMemberPanels?.filter((user) => 
      user.isModerator === false
    )?.length + panel?.arrayControlIdsExternal?.filter((user2) => {
      let user = JSON.parse(escapeDoubleQuotesWithinString(user2))
      return ((user.isModerator === 'false') ? false : true) === false
    })?.length) >= +panel.numberOfPanelists;

  let isFull = isGlobalConference ? isFullGlobal : isFullCouncil;

  const councilEventPanelist = panel?.CouncilEventPanelists?.find(
    (panelist) => panelist?.User?.id === userProfile.id
  );

  const hasJoined = !!councilEventPanelist;

  const checkIfUserProfileIsCompletedAndJoin = () => {
    if (userProfile?.percentOfCompletion !== 100) {
      return setShowProfileCompletionFirewall(true);
    }

    handleJoinPanel(panel, "Join");
  };

  const displayJoinBtn = hasJoined ? (
    <CustomButton
      text="Withdraw"
      onClick={() => handleJoinPanel(panel, "Unjoin")}
      type="third"
      size="small"
    />
  ) : (
    <CustomButton
      text={isFull ? "Already Full" : "Join"}
      disabled={isFull}
      onClick={() => checkIfUserProfileIsCompletedAndJoin()}
      size="small"
    />
  );

  const handleRemovePanelist = (id) => {
    removeCouncilEventPanelist(panel.id, id);
  };

  const handleSearchUser = (values) => {
    const runDebounce = debounce(() => {
      searchUserForCouncilEventPanelist(values);
    }, 500);

    runDebounce();
  };

  const addUserToASessionOfGlobalConference = (data) => {
    const bulModerator =
      data?.isModerator === undefined ? false : data?.isModerator;
    const keynote = data?.keynote === undefined ? false : data?.keynote;

    addUserSpeakerToPanel(
      {
        usersNames: data?.users,
        bul: bulModerator,
        panel: Panel,
        keynote,
        type: "addUserAdmin",
      },
      () => {
        setPanel({});
        form.resetFields();
        getAllPanelsOfOneGlobalConference(panel?.relationConference);
        // getAllPanelSpeakers("Panels", {
        //   topics: undefined,
        //   bul: panelsFullBul,
        // });
        // getAllPanelsOfOneUserSpeakers();
        // getAllUserSpeaker(userProfile?.id);
      }
    );
  };

  const removeUserFunction = (id, user, panelName) => {
    removeUserSpeakerToPanel({ id: id, panelName: panelName }, () => {
      getAllPanelSpeakers("Panels", {
        topics: undefined,
        bul: panelsFullBul,
      });
      getAllPanelsOfOneUserSpeakers();
      getAllUserSpeaker(userProfile?.id);
      getAllMemberSpeakerPanel();
      getAllPanelsOfOneGlobalConference(panel?.relationConference);
      // getCouncilEvents()

      // if (userProfile?.id === user) {
      //   setTimeout(() => {
      //     setRemoveMembersSpeakers(true);
      //   }, 100);
      // }
    });
  };

  const handleClone = () => {
    const panels = {
      panelName: panel.panelName,
      type: panel.type,
      category: panel.category,
      startDate: panel.startDate,
      endDate: panel.endDate,
      timeZone: panel.timeZone,
      description: panel.description,
      // objetives: panel.objectives,
      link: panel.link,
      recertificactionCredits: panel.recertificactionCredits,
      metaData: panel.metaData,
      PanelId: panel.id,
      relationConference: panel.relationConference,
      hrCreditsBoolean: panel.hrCreditsBoolean,
      objetives: panel.objetives,
    };

    addPanelSpeakers(panels, (est, err) => {
      if (!err) {
        // getAllPanelSpeakersAdmin("All");
        getAllPanelsOfOneGlobalConference(panel?.relationConference);
        setBulModelClone(false);
      }
    });
  };

  const onPhotoSave = (url, base64) => {
    setPhotoModalBul(false);
    setEditImageUrl(url);
    setImageStr(base64);
  };

  const joinUser = (data, index, user) => {
    if (userProfile?.percentOfCompletion !== 100) {
      return setShowProfileCompletionFirewall(true);
    }
    let usersNames = {
      userId: user?.User?.id,
      userName: user?.User?.firstName,
      userEmail: user?.User?.email,
    };

    let arrayMemberNotModerator = allPanelSpeakers[
      index
    ]?.SpeakerMemberPanels?.filter((member) => {
      return member?.isModerator === false;
    });

    if (
      allMember?.length < 2 &&
      userProfile?.role !== "admin" &&
      arrayMemberNotModerator?.length < 5
    ) {
      addUserSpeakerToPanel(
        { usersNames, bul: false, panel: data, type: "joinUser" },
        () => {
          getAllPanelSpeakers("Panels", {
            topics: undefined,
            bul: panelsFullBul,
          });
          getAllPanelsOfOneUserSpeakers();
          getAllUserSpeaker(userProfile?.id);
          getAllMemberSpeakerPanel();
          getAllPanelsOfOneGlobalConference(panel?.relationConference);
        }
      );
    } else {
      if (userProfile?.role !== "admin") {
        if (arrayMemberNotModerator?.length === 5) {
          notification.error({
            message: "ERROR:",
            description: "This panel is full.",
          });
        }
        if (allMember?.length === 2) {
          notification.error({
            message: "ERROR:",
            description: "You can't join more than two panels.",
          });
        }
      }
    }
    if (userProfile?.role === "admin") {
      usersNames = [JSON.stringify(usersNames)];
      addUserSpeakerToPanel(
        { usersNames, bul: false, panel: data, type: "addUserAdmin" },
        () => {
          getAllPanelSpeakers("Panels", {
            topics: undefined,
            bul: panelsFullBul,
          });
          getAllPanelsOfOneUserSpeakers();
          getAllUserSpeaker(userProfile?.id);
          getAllMemberSpeakerPanel();
          getAllPanelsOfOneGlobalConference(panel?.relationConference);
        }
      );
    }
  };

  const handleOnFinish = (values) => {
    if (!values.user) {
      return notification.warn({
        message: "User can't be empty.",
      });
    }

    const user = filteredSearchUser.find(
      (_user) => _user.value === values.user
    );

    joinCouncilEvent(
      panel.id,
      user.id,
      "Join",
      true,
      !!values.isModerator,
      false,
      () => {
        getCouncilEvents();
      }
    );
    form.resetFields();
    setIsModalVisible(false);
  };

  const getOneUser = async (id) => {
    const response = await getOneUserById(id);
    setUserProfileToShow(response?.data?.user || {});
    setShowProfile(true);
  };

  const addAndFilterSituacion = (panels, users) => {
    let userSelects = users?.map((data2) => {
      const startString = '":"';
      const endString = '","';
      let data = data2?.id ? data2 : JSON.parse(escapeDoubleQuotesWithinString(data2,startString,endString));
      if (Number(data?.id) === Number(userProfile?.id)) {
        return {
          id: panels?.id,
          isModerator: false,
          buttonsAccept: true,
          isKeynote: data.isKeynote,
          UserId: data?.id,
          User: data,
          pending: true,
        };
      } else {
        return {
          id: panels?.id,
          isModerator: false,
          buttonsAccept: true,
          isKeynote: data.isKeynote,
          UserId: data?.id,
          User: data,
          pending: userProfile?.role !== "admin" ? false : true,
        };
      }
    });
    let usersSelectss = [];

    if (isGlobalConference) {
      let usersSelectss2 = panels.arrayControlIdsExternal?.map((data2) => {
        const startString = '":"';
        const endString = '","';
        let data = data2?.id ? data2 : JSON.parse(escapeDoubleQuotesWithinString(data2,startString,endString));
        if (Number(data?.id) === Number(userProfile?.id)) {
          return {
            id: panels?.id,
            isModerator: data.isModerator === "false" ? false : true,
            isKeynote: data.isKeynote,
            buttonsAccept: false,
            UserId: data?.id,
            User: data,
            isExternalUser: true,
            pending: true,
          };
        } else {
          return {
            id: panels?.id,
            isModerator: data.isModerator === "false" ? false : true,
            isKeynote: data.isKeynote,
            buttonsAccept: false,
            UserId: data?.id,
            User: data,
            isExternalUser: true,
            pending: userProfile?.role !== "admin" ? false : true,
          };
        }
      });
      if (userSelects?.length > 0) {
        usersSelectss = [
          ...panels.SpeakerMemberPanels,
          ...userSelects,
          ...usersSelectss2,
        ];
      } else {
        usersSelectss = [...panels.SpeakerMemberPanels, ...usersSelectss2];
      }
    } else {
      if (userSelects?.length > 0) {
        usersSelectss = [...panels?.CouncilEventPanelists, ...userSelects];
      } else {
        usersSelectss = [...panels?.CouncilEventPanelists];
      }
    }

    return usersSelectss;
  };

  const displaySpeakersListsOfOneConference = addAndFilterSituacion(
    panel,
    panel?.arrayControlIdsEvents
  )?.map((panelist) => {
    const user = panelist?.User;

    const { buttonsAccept, isExternalUser } = panelist;

    const isPanelModerator = panelist?.isModerator;

    const displayIsPanelModerator = isPanelModerator && <span>Moderator</span>;
    const displayKeyNote = (panelist?.isKeynote === true ||
      panelist?.isKeynote === "true") && <span>Keynote Speakers</span>;

    return (
      <div className="panelist" key={user?.email}>
        <div style={{ height: "100px" }}>
          <Avatar
            src={user?.img}
            size={100}
            style={{ marginLeft: "auto", marginRight: "auto" }}
          />
        </div>
        <div>{`${user?.firstName} ${user?.lastName}`}</div>
        <div>
          <div>
            <Tooltip title={user?.titleProfessions}>
              {user?.titleProfessions}
            </Tooltip>
          </div>
          {displayIsPanelModerator}
          {isPanelModerator &&
            (panelist?.isKeynote === true || panelist?.isKeynote === "true") &&
            " / "}
          {displayKeyNote}
        </div>
        {userProfile.isExpertCouncilAdmin && (
          <div
            style={{
              marginTop: "auto",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "20px",
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {!buttonsAccept && isExternalUser && (
              <CustomButton
                text="Edit"
                size="sm"
                style={{ marginBottom: '10px' }}
                onClick={() => {
                  setOpenExternalUser(true);
                  setEditExternalUser(true);
                  setExternalUser(user);
                  setPanel({
                    id: panel?.id,
                    startDate: panel?.startDate,
                    endDate: panel?.endDate,
                    panelName: panel?.panelName,
                  });
                }}
              />
            )}
            {!buttonsAccept && (
              <Popconfirm
                title="Do you want to remove this panelist?"
                onConfirm={() => {
                  if (isExternalUser) {
                    joinExternalAwait(
                      panel?.id,
                      panelist.User,
                      "Unjoin",
                      false,
                      false,
                      eventData,
                      null,
                      () =>
                        getAllPanelsOfOneGlobalConference(
                          panel?.relationConference
                        )
                    );
                  } else {
                    removeUserFunction(panelist?.id, "", panel?.panelName);
                  }
                }}
              >
                <CustomButton text="Remove" type="third" size="small" />
              </Popconfirm>
            )}
            {userProfile.role === "admin" && buttonsAccept && (
              <CustomButton
                className="button-speaker"
                text="Approve"
                size="sm"
                style={{ marginTop: "10px" }}
                type="secondary"
                onClick={() => {
                  // joinCouncilEvent(
                  //   panel.id,
                  //   user.id,
                  //   "Join",
                  //   false,
                  //   false,
                  //   panel.CouncilEventId,
                  //   () => {
                  //     getCouncilEvents();
                  //   }
                  // );
                  joinUser(panel, index, panelist);
                }}
              />
            )}
            {userProfile.role === "admin" && buttonsAccept && (
              <CustomButton
                className="button-speaker"
                text="Reject"
                size="sm"
                style={{ marginTop: "10px" }}
                type="third"
                onClick={() => {
                  // joinCouncilEventWait(
                  //   panel.id,
                  //   user,
                  //   "Unjoin",
                  //   false,
                  //   false,
                  //   eventData,
                  //   () => getCouncilEvents()
                  // );
                  // removeUserFunction(panelist?.User?.id, "", panel?.panelName);
                  joinSpeakersEventWait(
                    panel?.id,
                    panelist?.User,
                    "Unjoin",
                    false,
                    false,
                    eventData,
                    () => getCouncilEvents()
                  );
                }}
              />
            )}
            {userProfile.role === "admin" &&
              emailOfAdmin !== undefined &&
              (emailOfAdmin === "enrique@hackinghr.io" ||
                emailOfAdmin === "mkeivy.mora@gmail.com" ||
                emailOfAdmin === "morecontrol64@gmail.com") &&
              !isExternalUser && (
                <CustomButton
                  className="button-speaker"
                  style={{ marginTop: "10px" }}
                  text="Show Profile"
                  size="sm"
                  // type=""
                  onClick={() => {
                    getOneUser(user.id);
                  }}
                />
              )}
          </div>
        )}
        <UserProfileModal
          modalUserProfile={showProfile}
          setModalUserProfile={setShowProfile}
          userProfile={userProfileToShow}
          allCategories={allCategories}
        />
      </div>
    );
  });

  const displayPanelists = addAndFilterSituacion(
    panel,
    panel?.arrayControlIdsEvents
  )?.map((panelist) => {
    const user = panelist?.User;

    const { buttonsAccept } = panelist;

    const isPanelModerator = panelist?.isModerator;
    const displayIsPanelModerator = isPanelModerator && <span>Moderator</span>;

    return (
      <div className="panelist" key={user?.email}>
        <div style={{ height: "100px" }}>
          <Avatar
            src={user?.img}
            size={100}
            style={{ marginLeft: "auto", marginRight: "auto" }}
          />
        </div>
        <div>{`${user?.firstName} ${user?.lastName}`}</div>
        <div>
          <div>
            <Tooltip title={user?.titleProfessions}>
              {user?.titleProfessions}
            </Tooltip>
          </div>
          {displayIsPanelModerator}
        </div>
        {userProfile.isExpertCouncilAdmin && (
          <div
            style={{
              marginTop: "auto",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "20px",
            }}
          >
            {!buttonsAccept && (
              <Popconfirm
                title="Do you want to remove this panelist?"
                onConfirm={() => handleRemovePanelist(panelist.id)}
              >
                <CustomButton text="Remove" type="third" size="small" />
              </Popconfirm>
            )}
            {userProfile.role === "admin" && buttonsAccept && (
              <CustomButton
                className="button-speaker"
                text="Approve"
                size="sm"
                style={{ marginTop: "10px" }}
                type="secondary"
                onClick={() => {
                  joinCouncilEvent(
                    panel.id,
                    user.id,
                    "Join",
                    false,
                    false,
                    panel.CouncilEventId,
                    () => {
                      getCouncilEvents();
                    }
                  );
                }}
              />
            )}
            {userProfile.role === "admin" && buttonsAccept && (
              <CustomButton
                className="button-speaker"
                text="Reject"
                size="sm"
                style={{ marginTop: "10px" }}
                type="third"
                onClick={() => {
                  joinCouncilEventWait(
                    panel.id,
                    user,
                    "Unjoin",
                    false,
                    false,
                    eventData,
                    {
                      rejectWithEmail: false
                    },
                    () => getCouncilEvents()
                  );
                }}
              />
            )}
            {userProfile.role === "admin" && buttonsAccept && (
              <CustomButton
                className="button-speaker"
                title='This option will reject and send an email to the user.'
                text="CHANGE"
                size="sm"
                style={{ marginTop: "10px" }}
                type="third"
                onClick={() => {
                  joinCouncilEventWait(
                    panel.id,
                    user,
                    "Unjoin",
                    false,
                    false,
                    eventData,
                    {
                      rejectWithEmail: true,
                      linkToReturn: `${window?.location?.origin}${INTERNAL_LINKS.EVENTS_COUNCIL}/${eventData?.id}`,
                      startTime: `${startTime.format("HH:mm")} ${userTimezone}`,
                    },
                    () => getCouncilEvents()
                  );
                }}
              />
            )}
            {userProfile.role === "admin" &&
              emailOfAdmin !== undefined &&
              (emailOfAdmin === "enrique@hackinghr.io" ||
                emailOfAdmin === "mkeivy.mora@gmail.com" ||
                emailOfAdmin === "morecontrol64@gmail.com") && (
                <CustomButton
                  className="button-speaker"
                  style={{ marginTop: "10px" }}
                  text="Show Profile"
                  size="sm"
                  // type=""
                  onClick={() => {
                    getOneUser(user.id);
                  }}
                />
              )}
          </div>
        )}
        <UserProfileModal
          modalUserProfile={showProfile}
          setModalUserProfile={setShowProfile}
          userProfile={userProfileToShow}
          allCategories={allCategories}
        />
      </div>
    );
  });

  const filteredSearchUser = searchedUsersForCouncilEvent.filter(
    (user) =>
      !panel.CouncilEventPanelists?.some(
        (panelist) => panelist.UserId === user.id
      )
  );

  const completeProfile = () => {
    Emitter.emit(EVENT_TYPES.EVENT_VIEW_PROFILE);
  };

  const addUserExternal = (data) => {
    const { externalSpeaker, isModerator, keynote } = data;
    const data1 = externalSpeaker !== undefined ? JSON.parse(externalSpeaker) : {};
    const speakerExternal = externalSpeakers.find((user, index) => (user.uuid !== undefined && user?.uuid === data1?.uuid));

    if (speakerExternal && speakerExternal.email) {
      joinExternalAwait(
        panel?.id,
      {
        ...speakerExternal,
        description: speakerExternal.description !== undefined ? speakerExternal.description : '',
        email: speakerExternal.email !== undefined ? speakerExternal.email : '',
        firstName: speakerExternal.firstName !== undefined ? speakerExternal.firstName : '',
        lastName: speakerExternal.lastName !== undefined ? speakerExternal.lastName : '',
        titleProfessions: speakerExternal.titleProfessions !== undefined ? speakerExternal.titleProfessions : '',
        linkedin: speakerExternal.linkedin !== undefined ? speakerExternal.linkedin : '',
        img: speakerExternal.img !== undefined ? speakerExternal.img : '',
        isModerator: isModerator !== undefined ? isModerator : false,
        keynote: keynote !== undefined ? keynote : false,
        id: speakerExternal.id !== undefined ? speakerExternal.id : '',
        uuid: speakerExternal.uuid !== undefined ? speakerExternal.uuid : '',
        abbrName: speakerExternal.abbrName !== undefined ? speakerExternal.abbrName : '',
      },
      "Join",
      false,
      false,
      eventData,
      null,
      () => getAllPanelsOfOneGlobalConference(panel?.relationConference)
      );
    } else {
      joinExternalAwait(
        panel?.id,
      {
        ...data,
        description: data.description !== undefined ? data.description : '',
        email: data.email !== undefined ? data.email : '',
        firstName: data.firstName !== undefined ? data.firstName : '',
        lastName: data.lastName !== undefined ? data.lastName : '',
        titleProfessions: data.titleProfessions !== undefined ? data.titleProfessions : '',
        linkedin: data.linkedin !== undefined ? data.linkedin : '',
        img: imageStr,
        isModerator: data.isModerator !== undefined ? data.isModerator : false,
        keynote: data.keynote !== undefined ? data.keynote : false,
        id: -1,
        abbrName: `${data.firstName[0].toUpperCase()}${data.lastName[0].toUpperCase()}`,
      },
      "Join",
      false,
      false,
      eventData,
      null,
      () => getAllPanelsOfOneGlobalConference(panel?.relationConference)
      );
    }
    setImageStr('');
    setEditImageUrl('');
    setAddExternalUserExisting(false);
    setOpenExternalUser(false);
    externalUserForm.resetFields();
  };

  const editUserExternal = (data) => {    
    const ImageProfile = imageStr?.length > 0 ? imageStr : editImageUrl;

    joinExternalAwait(
      panel?.id,
      {
        ...data,
        description: data.description !== undefined ? data.description : '',
        email: data.email !== undefined ? data.email : '',
        firstName: data.firstName !== undefined ? data.firstName : '',
        lastName: data.lastName !== undefined ? data.lastName : '',
        titleProfessions: data.titleProfessions !== undefined ? data.titleProfessions : '',
        linkedin: data.linkedin !== undefined ? data.linkedin : '',
        img: ImageProfile,
        isModerator: data.isModerator !== undefined ? data.isModerator : false,
        keynote: data.keynote !== undefined ? data.keynote : false,
        id: -1,
        abbrName: `${data.firstName[0].toUpperCase()}${data.lastName[0].toUpperCase()}`,
      },
      "updateUser",
      false,
      false,
      eventData,
      externalUser,
      () => getAllPanelsOfOneGlobalConference(panel?.relationConference)
    );
    setImageStr('');
    setEditImageUrl('');
    setOpenExternalUser(false);
    setEditExternalUser(false);
    setAddExternalUserExisting(false);
    externalUserForm.resetFields();
  };

  const handleFinish = () => {
    if (typeDelete === "sessions") {
      deletePanelSpeakers(panel.id, () => {
        // getAllPanelSpeakersAdmin("All");
        updateData();
        setBulModelDelete(false);
        // setIdData(-1);
        setTitle("");
      });
    }
    // if (typeDelete === "sponsor") {
    //   deleteSponsor2023(idData, () => {
    //     getAllSponsors2023();
    //     setBulModelDelete(false);
    //     setIdData(-1);
    //     setTitle("");
    //   });
    // }
    // if (typeDelete === "parraf") {
    //   deleteParrafConference2023(idData, () => {
    //     getAllParrafConference2023();
    //     setBulModelDelete(false);
    //     setIdData(-1);
    //     setTitle("");
    //   });
    // }
  };

  return (
    <div style={{ marginTop: "1rem", background: "#f2f2f2", padding: "1rem" }}>
      <div className="d-flex justify-between" key={panel?.panelName}>
        <div>
          <div>
            <b>Panel</b>: {panel?.panelName}
          </div>
          <div>
            <b>Panel Date</b>:{` ${startTime.format("LL")}`}
          </div>
          <div>
            <b>Panel Start Time</b>: {startTime.format("HH:mm a")} {userTimezone}
          </div>
          <div>
            <b>Panel End Time</b>: {endTime.format("HH:mm a")} {userTimezone}
          </div>
          <div
            className="d-flex"
            style={{ marginTop: "1rem", flexWrap: "wrap" }}
          >
            {isGlobalConference
              ? displaySpeakersListsOfOneConference
              : displayPanelists}
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {isGlobalConference && userProfile.isExpertCouncilAdmin && (
            <>
              <CustomButton
                size="small"
                text="Add user"
                style={{ marginBottom: "1rem" }}
                type="secondary"
                onClick={() => {
                  setOpenSearchUser(true);
                  setPanel({
                    id: panel?.id,
                    startDate: panel?.startDate,
                    endDate: panel?.endDate,
                    panelName: panel?.panelName,
                  });
                }}
              />
              <CustomButton
                size="small"
                text="Add External User"
                style={{ marginBottom: "1rem" }}
                type="secondary"
                onClick={() => {
                  setOpenExternalUser(true);
                  setPanel({
                    id: panel?.id,
                    startDate: panel?.startDate,
                    endDate: panel?.endDate,
                    panelName: panel?.panelName,
                  });
                }}
              />
              {!isGlobalConference && (
                <CustomButton
                  text={isFull ? "Already Full" : "Join"}
                  disabled={isFull}
                  onClick={() =>
                    joinUser(
                      {
                        id: panel?.id,
                        startDate: panel?.startDate,
                        endDate: panel?.endDate,
                        panelName: panel?.panelName,
                      },
                      index
                    )
                  }
                  size="small"
                />
              )}
            </>
          )}
          {isGlobalConference && userProfile.role === "admin" && (
            <div
              style={{
                border: "1px solid #F16030",
                borderRadius: "8px",
                padding: "10px",
                marginTop: "10px",
              }}
            >
              <Space style={{ display: "flex", flexDirection: "column" }}>
                <CustomButton
                  text="Remove"
                  size="xs"
                  style={{
                    width: "130px",
                    marginRight: "-6px",
                    marginBottom: "7.5px",
                  }}
                  onClick={() => {
                    setBulModelDelete(true);
                    setTitle(panel.panelName);
                    // setIdData(panel.id);
                    setTypeDelete("sessions");
                  }}
                />
                <CustomButton
                  text="Edit"
                  size="xs"
                  style={{
                    width: "130px",
                    marginRight: "-6px",
                    marginBottom: "7.5px",
                  }}
                  onClick={() => {
                    setSessionSelected(panel);
                    // setIdData(panel.id);
                    // setBulRedirectEditSession(true);
                    setEditSession(true);
                    setIsModalSessionOpen(true);
                  }}
                />
                <CustomButton
                  text="Clone"
                  size="xs"
                  style={{
                    width: "130px",
                    marginRight: "-6px",
                    marginBottom: "7.5px",
                  }}
                  onClick={() => {
                    setBulModelClone(true);
                    // getPanelsForId(panel.id);
                  }}
                />
                {/* <Link
                  to={`${INTERNAL_LINKS.GLOBAL_CONFERENCE_CLASSES}/${panel.id}?type=2023`}
                > */}
                <CustomButton
                  size="xs"
                  text="Classes"
                  style={{
                    width: "130px",
                    marginRight: "-6px",
                    marginBottom: "7.5px",
                  }}
                  onClick={() => {
                    setRefresh((prev) => !prev);
                    setSessionSelected(panel);
                    setIsModalClasses(true);
                  }}
                />
                {/* </Link> */}
                <CustomButton
                  text={
                    panel.status === "active"
                      ? "Save as Draft"
                      : "Save as Active"
                  }
                  size="xs"
                  type={panel.status === "active" ? "primary" : "secondary"}
                  style={{ width: "130px", padding: "0px" }}
                  onClick={() => {
                    changeStatus(
                      panel.status === "active" ? "draft" : "active",
                      panel.id,
                      () => {
                        getAllPanelSpeakersAdmin("All");
                        getAllPanelsOfOneGlobalConference(panel?.relationConference, true)
                      }
                    );
                  }}
                />
              </Space>
            </div>
          )}
          {!isGlobalConference && userProfile.isExpertCouncilAdmin && (
            <CustomButton
              size="small"
              text="Add user"
              style={{ marginBottom: "1rem" }}
              type="secondary"
              onClick={() => setIsModalVisible(true)}
            />
          )}
          {!isGlobalConference && status !== "closed" && displayJoinBtn}
          {!isGlobalConference && hasJoined && (
            <>
              <div style={{ marginTop: "5px" }}>
                <Dropdown overlay={downloadDropdownOptions}>
                  <a
                    href="/#"
                    className="ant-dropdown-link"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    Download calendar <DownOutlined />
                  </a>
                </Dropdown>
              </div>
            </>
          )}
        </div>
        <CustomModal
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          title="Add user"
          width={420}
        >
          <Form form={form} layout="vertical" onFinish={handleOnFinish}>
            <Form.Item
              name="user"
              label="Search user"
              required={[{ required: true }]}
            >
              <AutoComplete
                size="large"
                onSearch={handleSearchUser}
                options={filteredSearchUser}
              />
            </Form.Item>
            <Form.Item name="isModerator" label="Is a moderator">
              <Switch />
            </Form.Item>
            <Form.Item>
              <CustomButton htmlType="submit" text="Add" block />
            </Form.Item>
          </Form>
        </CustomModal>
        <Modal
          visible={openSearchUser}
          title="Searh a speaker."
          width={500}
          onCancel={() => {
            setOpenSearchUser(false);
            speakersUserForm.resetFields();
            setPanel({});
          }}
          onOk={() => {
            setOpenSearchUser(false);
            speakersUserForm.submit();
          }}
          okText="Yes"
        >
          <Form
            form={speakersUserForm}
            layout="vertical"
            onFinish={(data) => {
              addUserToASessionOfGlobalConference(data);
            }}
          >
            <Form.Item
              label="Users"
              name="users"
              size="large"
              rules={[{ required: true, message: "Users is necesary" }]}
              onChange={(e) => setUserToSearch(e.target.value)}
            >
              <Select
                style={{ width: "100%" }}
                mode="multiple"
                allowClear
                placeholder="Select users"
                optionFilterProp="children"
              >
                {searchedUsers !== undefined ? (
                  searchedUsers.map((users) => (
                    <Option
                      key={users.id}
                      value={JSON.stringify({
                        userId: users.id,
                        userName: users.firstName,
                        userEmail: users.email,
                      })}
                    >
                      {`${users.firstName} / ${users.email}`}
                    </Option>
                  ))
                ) : (
                  <div></div>
                )}
                {/* {allUserSpeaker?.userSpeakers !== undefined ? (
                  allUserSpeaker?.userSpeakers.map((users) => (
                    <Option
                      key={users.id}
                      value={JSON.stringify({
                        userId: users.id,
                        userName: users.firstName,
                        userEmail: users.email,
                      })}
                    >
                      {`${users.firstName} / ${users.email}`}
                    </Option>
                  ))
                ) : (
                  <div></div>
                )} */}
              </Select>
            </Form.Item>
            <Form.Item
              name="isModerator"
              label="Is a moderator"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item name="keynote" label="Keynote">
              <Switch />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          visible={openExternalUser}
          title={`${ editExternalUser ? 'Edit External Speaker' : 'Add External Speaker to this panel.'}`}
          width={500}
          onCancel={() => {
            setAddExternalUserExisting(false);
            setOpenExternalUser(false);
            setEditExternalUser(false);
            externalUserForm.resetFields();
            setPanel({});
            setEditImageUrl('');
          }}
          onOk={() => {
            setOpenExternalUser(false);
            externalUserForm.submit();
          }}
          // okText="Yes"
          footer={[]}
        >
          {!editExternalUser && <Form.Item label="Existing User" valuePropName="checked">
            <Switch checked={addExternalUserExisting} onChange={async() => {
              setAddExternalUserExisting(prev => !prev);
              const {data} = await getAllExternalSpeakers(eventData.idConference);
              setExternalSpeakers(data.allExternalSpeakers || []);
            }} />
          </Form.Item>}
          <Form
            form={externalUserForm}
            layout="vertical"
            onFinish={(data) => {
              if (editExternalUser) {
                editUserExternal(data);
              } else {
                addUserExternal(data);
              }
            }}
          >
            { 
              !addExternalUserExisting
                ?
                <>
                  <div
                    className="profile-edit-panel-header"
                    style={{ marginBottom: "55px" }}
                  >
                    <div className="profile-user-img">
                      <div className="profile-user-img-container">
                        {editImageUrl ? (
                          <div className="profile-user-img-wrapper">
                            <img src={editImageUrl} alt="user-avatar" />
                          </div>
                        ) : (
                          <p className="profile-user-img-name"></p>
                        )}
                        <div
                          className="profile-user-img-camera"
                          onClick={() => setPhotoModalBul(true)}
                        >
                          <i className="fal fa-camera" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <Form.Item name="firstName" label="First Name" rules={[{ required: true, message: "First Name is required" }]}>
                    <CustomInput />
                  </Form.Item>
                  <Form.Item name="lastName" label="Last Name" rules={[{ required: true, message: "Last Name is required" }]}>
                    <CustomInput />
                  </Form.Item>
                  <Form.Item name="titleProfessions" label="Title">
                    <CustomInput />
                  </Form.Item>
                  <Form.Item name="email" label="Email" rules={[{ required: true, message: "Email is required" }]}>
                    <CustomInput />
                  </Form.Item>
                  <Form.Item name="linkedin" label="Linkedin">
                    <CustomInput />
                  </Form.Item>
                  <Form.Item name="description" label="Description">
                    <CustomInput />
                  </Form.Item>
                  <Form.Item name="isModerator" label="Is a moderator" valuePropName="checked">
                    <Switch />
                  </Form.Item>
                  <Form.Item name="keynote" label="Keynote" valuePropName="checked">
                    <Switch />
                  </Form.Item>
                  <Form.Item>
                    <CustomButton htmlType="submit" text={`${ editExternalUser ? 'Edit' : 'Add'}`} block />
                  </Form.Item>
                </>
                :
                <>
                  <Form.Item
                    label="External Speakers"
                    name="externalSpeaker"
                    size="large"
                    rules={[{ required: true, message: "Users is necesary" }]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      // mode="multiple"
                      showSearch
                      allowClear
                      placeholder="Select External Speaker"
                      optionFilterProp="children"
                    >
                      {externalSpeakers !== undefined ? (
                        externalSpeakers.map((users) => (
                          <Option
                            key={`${users.uuid}`}
                            value={JSON.stringify({
                              userId: users.id,
                              userName: users.firstName,
                              userEmail: users.email,
                              uuid: users.uuid,
                            })}
                          >
                            {`${users.firstName} / ${users.email}`}
                          </Option>
                        ))
                      ) : (
                        <div></div>
                      )}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="isModerator"
                    label="Is a moderator"
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                  <Form.Item name="keynote" label="Keynote">
                    <Switch />
                  </Form.Item>
                  <Form.Item>
                    <CustomButton htmlType="submit" text={`${ editExternalUser ? 'Edit' : 'Add'}`} block />
                  </Form.Item>
                </> 
            }
          </Form>
        </Modal>
      </div>
      {/* {hasJoined && (
        <div style={{ marginTop: "1rem" }}>
          <Collapse accordion>
            <Panel header="Click here to see comments" key="1">
              <CommentForm
                councilEventPanelComments={panel.CouncilEventPanelComments}
                CouncilEventPanelId={panel.id}
                CouncilEventPanelistId={councilEventPanelist?.id}
              />
            </Panel>
          </Collapse>
        </div>
      )} */}
      {showProfileCompletionFirewall && (
        <div
          className="skill-cohort-firewall"
          onClick={() => {
            closeMainModal();
            setShowProfileCompletionFirewall(false);
          }}
        >
          <div className="upgrade-notification-panel" onClick={completeProfile}>
            <h3>
              You must fully complete your profile before joining an event.
            </h3>
          </div>
        </div>
      )}

      <CustomModal
        title="Are you sure?"
        visible={bulModelDelete}
        centered
        footer={[
          <CustomButton
            key="Cancel"
            text="Cancel"
            type="primary outlined"
            size="xs"
            onClick={() => setBulModelDelete(false)}
          />,
          <CustomButton
            key="Delete"
            text="Delete"
            type="primary"
            size="xs"
            onClick={handleFinish}
          />,
        ]}
        onCancel={() => setBulModelDelete(false)}
      >
        <h4>
          {typeDelete === "parraf"
            ? `Are you sure delete this parraf?`
            : `Are you sure delete "${title}"?`}
        </h4>
      </CustomModal>

      <CustomModal
        title="Are you sure?"
        visible={bulModelClone}
        centered
        footer={[
          <CustomButton
            key="Cancel"
            text="Cancel"
            type="primary outlined"
            size="xs"
            onClick={() => setBulModelClone(false)}
          />,
          <CustomButton
            key="Clone"
            text="Clone"
            type="primary"
            size="xs"
            onClick={handleClone}
          />,
        ]}
        onCancel={() => setBulModelClone(false)}
      >
        <h4>{`Are you sure to clone this session?`}</h4>
      </CustomModal>
      <Modal
        visible={isModalClasses}
        width={"100vw"}
        style={{ padding: "10px", maxWidth: "900px" }}
        footer={[]}
        onCancel={() => {
          setIsModalClasses(false);
        }}
        maskClosable={false}
        centered
      >
        <ConferenceClass
          session={panel}
          refresh={refresh}
          type={panel?.relationConference}
        />
      </Modal>
      <Modal
        className="photo-upload-modal"
        title={
          <div className="photo-upload-modal-title">Select your photo.</div>
        }
        centered
        visible={photoMoodalBul}
        width={500}
        closable={true}
        maskClosable={false}
        footer={[]}
        onCancel={() => setPhotoModalBul(false)}
      >
        <PhotoUploadForm onSave={(url, base64) => onPhotoSave(url, base64)} />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ...councilEventSelector(state),
  userProfile: homeSelector(state).userProfile,
  allCategories: categorySelector(state).categories,
  allUserSpeaker: speakerAllPanelSpeakerSelector(state).allUserSpeakers,
  searchedUsers: homeSelector(state).searchedUsers,
  allPanelSpeakers: speakerAllPanelSpeakerSelector(state).allPanelSpeakers,
  allMember: speakerAllPanelSpeakerSelector(state).allMember,
});

const mapDispatchToProps = {
  ...councilEventActions,
  removeUserSpeakerToPanel: speaker.removeUserSpeakerToPanel,
  joinExternalAwait: speaker.joinExternalAwait,
  getAllPanelsOfOneUserSpeakers: speaker.getAllPanelsOfOneUserSpeakers,
  getAllMemberSpeakerPanel: speaker.getAllMemberSpeakerPanel,
  addUserSpeakerToPanel: speaker.addUserSpeakerToPanel,
  getAllPanelSpeakers: speaker.getAllPanelSpeakers,
  getAllUserSpeaker: speaker.getAllUserSpeaker,
  getAllPanelsOfOneGlobalConference: speaker.getAllPanelsOfOneGlobalConference,
  searchUser: homeActions.searchUser,
  getPanelsForId: speaker.getPanelsForId,
  changeStatus: speaker.changeStatus,
  getAllPanelSpeakersAdmin: speaker.getAllPanelSpeakersAdmin,
  joinSpeakersEventWait: speaker.joinSpeakersEventWait,
  deletePanelSpeakers: speaker.deletePanelSpeakers,
  addPanelSpeakers: speaker.addPanelSpeakers,
};

export default connect(mapStateToProps, mapDispatchToProps)(CouncilEventPanel);
