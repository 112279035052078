import React, { useEffect, useState } from "react";
import "./style.scss";
import { connect } from "react-redux";
import moment from "moment";
// import CertificateIcon from "images/certificatesIcon.js";
import { actions as courseActions } from "redux/actions/course-actions";
import { courseSelector } from "redux/selectors/courseSelector";
import CardCertificate from "components/CardCertificateProgram";
import { homeSelector } from "redux/selectors/homeSelector";
import { Pagination } from "antd";
import { useQuery } from "@tanstack/react-query";
import httpClient from "../../api/module/httpClient";
import { CERTIFICATES } from "enum/QueryKeys";
import { useCertificateStore } from "store/certificate";

const Certificates = ({ userProfile }) => {
  const certificateStore = useCertificateStore();
  const [page, setPage] = useState(1);
  const [tab, setTab] = useState(1);

  const { data: allNewCourses } = useQuery({
    queryKey: [CERTIFICATES],
    queryFn: async () => {
      const { data } = await httpClient.get(`private/certificates`);

      return data?.courses || [];
    },
  });

  const orderCertificates = (data, isMyPrograms = false) => {
    // let addStartTime = [];
    let arrayOrderTime = [];

    arrayOrderTime = data?.sort(function (a, b) {
      if (a.title.toLowerCase() > b.title.toLowerCase()) {
        return 1;
      }
      if (a.title.toLowerCase() < b.title.toLowerCase()) {
        return -1;
      }
      return 0;
    });

    // arrayOrderTime?.forEach((e) => {
    //   let arrayActu = sortDates(e?.classes)
    //   addStartTime = [...addStartTime,{
    //     ...e,
    //     startTime: arrayActu[0]?.startTime
    //   }]
    // })

    // arrayOrderTime = sortDates(addStartTime)

    arrayOrderTime = Array.isArray(arrayOrderTime)
      ? (arrayOrderTime || []).sort((a, b) => {
          let aTime = moment(a.startDate, "YYYYMMDDHHmm").format(
            "YYYYMMDDHHmm"
          );
          let bTime = moment(b.startDate, "YYYYMMDDHHmm").format(
            "YYYYMMDDHHmm"
          );

          return Number(aTime) - Number(bTime);
        })
      : [];

    let filteredCertificates = [...(arrayOrderTime || [])];

    if (!isMyPrograms) {
      const pstTimeNow = moment.tz("America/Los_Angeles");

      const timeNow = pstTimeNow.format("YYYY-MM-DDTHH:mm:ss") + "Z";
      filteredCertificates = arrayOrderTime.filter((item) =>
        moment.utc(timeNow).isBefore(moment.utc(item.startDate).add(1, "week"))
      );
    }

    return filteredCertificates;
  };

  const filterMyCertificatePrograms = (data) => {
    return data?.filter((e) => {
      const userIndex = (e?.CertificateUsers || [])?.findIndex(
        (user) => user.UserId === userProfile.id
      );
      return userIndex !== -1;
    });
  };

  const hasNotification = filterMyCertificatePrograms(
    orderCertificates(allNewCourses, true)
  ).some((certificateProgram) => {
    return (certificateProgram.CertificateNotifications ?? []).some(
      (certificateNotification) => {
        return !certificateNotification.readers.includes(userProfile.id);
      }
    );
  });

  return (
    <div className="container-certificates">
      <div className="container-one">
        <div className="container-two">
          <div className="container-title-certificate"></div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <div
              className={
                tab === 1
                  ? "title-list-certificates"
                  : "title-list-certificates2"
              }
              onClick={() => setTab(1)}
            >
              Certificate Programs Available
            </div>
            {filterMyCertificatePrograms(orderCertificates(allNewCourses, true))
              .length > 0 && (
              <div
                className={
                  tab === 2
                    ? "title-list-certificates"
                    : "title-list-certificates2"
                }
                onClick={() => setTab(2)}
              >
                My Certificate Programs Dashboards
                {hasNotification && (
                  <span
                    style={{
                      position: "absolute",
                      top: "-6px",
                      right: "2px",
                      width: "16px",
                      height: "16px",
                      backgroundColor: "red",
                      borderRadius: "50%",
                      border: "2px solid white",
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        <div className="container-banner">
          <div
            className="container-banner-logo"
            style={{
              width: "150px",
            }}
          ></div>
        </div>
      </div>
      <div className="container-list-certificates">
        {tab === 1 &&
          orderCertificates(allNewCourses)?.map((e, index) => {
            const userIndex = (e?.CertificateUsers || [])?.findIndex(
              (user) =>
                user.UserId === userProfile.id && user.status === "active"
            );
            const userRegistered = userIndex !== -1 ? true : false;

            return (
              <CardCertificate
                key={index}
                certificate={e}
                onClick={() => {
                  certificateStore.setCurrentCertificate(e);
                }}
                userRegistered={userRegistered}
                userProfile={userProfile}
              />
            );
          })}
        {tab === 2 &&
          filterMyCertificatePrograms(
            orderCertificates(allNewCourses, true)
          )?.map((e, index) => {
            const hasNotification = e.CertificateNotifications.some(
              (certificateNotification) => {
                return !certificateNotification.readers.includes(userProfile.id);
              }
            )
            return (
              <CardCertificate
                key={index}
                certificate={e}
                onClick={() => {
                  // setCourseNew(e);
                }}
                userDashBoard={true}
                userProfile={userProfile}
                isMyProgram={true}
                hasNotification={hasNotification}
              />
            );
          })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userProfile: homeSelector(state).userProfile,
});

export default connect(mapStateToProps)(Certificates);
