import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import Helmet from "react-helmet";
import {
  DownOutlined,
  CloseCircleFilled,
} from "@ant-design/icons";
import ImgCertificateStamp from "images/img-certificate-stamp.png";
import ImgHHRLogo from "images/img-certificate-logo.png";
import ImgSignature from "images/img-signature.png";
import html2canvas from "html2canvas";
import {
  Modal,
  Dropdown,
  Space,
  Carousel,
  Avatar,
  // Avatar, Tooltip
} from "antd";
import downloadDropdownOptions from "components/downloadDropdownOptions";
import moment from "moment";
import { isEmpty } from "lodash";
import GoogleMap from "./GoogleMaps";
import { loadStripe } from "@stripe/stripe-js";
import TimeZoneList from "enum/TimezoneList";

import { getCheckoutSession } from "api/module/stripe";
import {
  capitalizeWord,
  convertToLocalTime,
  getEventPeriod,
} from "utils/format";
import Emitter from "services/emitter";
import { CustomButton, RichEdit, CollapseComponent, Tabs, CardUser } from "components";
import Login from "pages/Login2";
import {
  getEvent,
  addToMyEventList,
  getAllEvent,
} from "redux/actions/event-actions";
import { getUser } from "redux/actions/home-actions";
import { eventSelector } from "redux/selectors/eventSelector";
import { authSelector } from "redux/selectors/authSelector";
import { envSelector } from "redux/selectors/envSelector";
import { homeSelector } from "redux/selectors/homeSelector";
import ObjectCheckbox from "./objectCheckbox";

import EventVideo from "./../Learnings/EventVideo";

// import MemberSpeakers from "./MembersSpeakers";
import Arrow from "../../images/arrow-conference.svg";

import { actions as councilEventActions } from "redux/actions/council-events-actions";
import { councilEventSelector } from "redux/selectors/councilEventSelector";

import { INTERNAL_LINKS, EVENT_TYPES } from "enum";
import IconLogo from "images/logo-sidebar.svg";
import EventModalContainer from "containers/EventModalContainer";

import {
  createControlCertificate,
  getAllCertificateType,
} from "redux/actions/certificate-ations";

import MicroConferenceVideoWrapper from "./MicroConferenceVideoWrapper";

import "./style.scss";

const EventTypesData = {
  presentation: "Presentation",
  workshop: "Workshop",
  panel: "Panel",
  "peer-to-peer": "Peer-to-Peer Conversation",
  conference: "Conference",
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_KEY);

const PublicEventPage = ({
  match,
  updatedEvent,
  allEventsUsersSketch,
  isAuthenticated,
  isMobile,
  getEvent,
  addToMyEventList,
  history,
  userProfile,
  getUser,
  getCouncilEvents,
  allCouncilEventsCached,
  allEvents,
  getAllEvent,
  redirectHome,
  createControlCertificate,
  getAllCertificateType,
}) => {
  const [canonicalUrl, setCanonicalUrl] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [editor, setEditor] = useState("froala");
  const [showFirewall, setShowFirewall] = useState(false);
  const [eventCouncil, setEvent] = useState(undefined);
  const [stripe, setStripe] = useState(null);
  const [loading, setLoading] = useState(false);
  const [bulModal4, setBulModal4] = useState(false);
  const [newChangue, setNewChangue] = useState(false);
  const [newChangue2, setNewChangue2] = useState(false);
  const [activeModal, setActiveModal] = useState(false);
  const [bulModal6, setBulModal6] = useState(false);
  const [objectEvent, setObjectEvent] = useState({});
  const [typeRedrect, setTypeRedirect] = useState("nothing");
  const [bulReset, setBulReset] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [bulOpenFirePopUp, setBulOpenFirePopUp] = useState(false);
  const [
    bulPopUpDownloadEventCertificate,
    setBulPopUpDownloadEventCertificate,
  ] = useState(false);
  const [dataEvent, setDataEvent] = useState({});
  const [dataEventFire, setDataEventFire] = useState({});
  const certificateRef = useRef(null);
  const [durationEvent, setDurationEvent] = useState(0);
  const [checked, setChecked] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [currentTab, setCurrentTab] = useState("0");
  const [eventId, setEventId] = useState(null);

  let checkedObject = {};

  let userTimezone = moment.tz.guess();
  let clockAnimation;
  let clockAnimation2;

  if (userTimezone.includes("_")) {
    userTimezone = userTimezone?.split("_")?.join(" ");
  }

  updatedEvent = {
    ...updatedEvent,
    usersData: allEventsUsersSketch[updatedEvent.id],
  };

  useEffect(() => {
    if (!bulPopUpDownloadEventCertificate) {
    } else {
      let duration = 0;

      dataEvent.startAndEndTimes.forEach((date) => {
        duration =
          date !== null
            ? duration +
              moment(date?.endTime, "YYYY-MM-DDTHH:mm:ssZ").diff(
                date?.startTime,
                "hours"
              )
            : duration;
      });

      setDurationEvent(duration);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulPopUpDownloadEventCertificate]);

  useEffect(() => {
    if (
      window?.location?.pathname.substring(0, 15) !==
      INTERNAL_LINKS.CONFERENCE_2023
    ) {
      if (isAuthenticated) {
        if (history != null) {
          if (typeRedrect !== "nothing") {
            if (typeRedrect === "home") {
              history.push(INTERNAL_LINKS.HOME);
            }
            if (typeRedrect === "events") {
              history.push(INTERNAL_LINKS.EVENTS);
            }
            if (typeRedrect === "changue") {
              setTypeRedirect("nothing");
              getUser();
              setNewChangue(true);
            }
          }
        } else {
          if (updatedEvent?.ticket === "premium") {
            if (
              !isEmpty(userProfile) &&
              userProfile?.memberShip === "premium"
            ) {
              addToMyEventList(updatedEvent);
            }
          } else if (updatedEvent?.ticket === "fee") {
            const userTimezone = moment.tz.guess();

            getCheckoutSession({
              prices: [
                {
                  price_data: {
                    currency: "usd",
                    product_data: {
                      name: updatedEvent?.title,
                    },
                    unit_amount: `${updatedEvent?.ticketFee}00`,
                  },
                },
              ],
              isPaidEvent: true,
              event: { ...updatedEvent, userTimezone },
              callback_url: window?.location?.href,
            }).then((sessionData) =>
              stripe.redirectToCheckout({ sessionId: sessionData?.data?.id })
            );
          } else {
            addToMyEventList(updatedEvent);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, userProfile]);

  useEffect(() => {
    getAllEvent();
  }, [getAllEvent]);

  useEffect(() => {
    instanceStripe();
  }, []);

  const instanceStripe = async () => {
    setStripe(await stripePromise);
  };

  useEffect(() => {
    if (
      userProfile?.firstName !== "" &&
      userProfile?.firstName !== null &&
      userProfile?.firstName !== undefined &&
      newChangue
    )
      getAllEvent(() => {
        setNewChangue(false);
        setNewChangue2(true);
      });
  }, [userProfile, newChangue, getAllEvent]);

  const onAttend = async () => {
    if (isAuthenticated) {
      if (updatedEvent?.ticket === "premium") {
        if (!isEmpty(userProfile) && userProfile?.memberShip === "premium") {
          const timezone = moment.tz.guess();
          addToMyEventList(updatedEvent, timezone);
          history.push(INTERNAL_LINKS.EVENTS);
        } else {
          setShowFirewall(true);
        }
      } else if (updatedEvent?.ticket === "fee") {
        try {
          setLoading(true);
          let sessionData = await getCheckoutSession({
            prices: [
              {
                price_data: {
                  currency: "usd",
                  product_data: {
                    name: updatedEvent?.title,
                  },
                  unit_amount: `${updatedEvent?.ticketFee}00`,
                },
              },
            ],
            isPaidEvent: true,
            event: {
              ...updatedEvent,
              userTimezone,
            },
            callback_url: window?.location?.href,
          });

          stripe.redirectToCheckout({ sessionId: sessionData?.data?.id });
        } catch (error) {
          console.log(error);
        }
      } else {
        const timezone = moment.tz.guess();
        addToMyEventList(updatedEvent, timezone);
        history.push(INTERNAL_LINKS.EVENTS);
      }
    } else {
      setModalVisible(true);
    }
  };

  let bulStatus =
    eventCouncil?.status === "active" || eventCouncil?.status === "closed";

  useEffect(() => {
    if (redirectHome) {
      setTypeRedirect("home");
    } else {
      setTypeRedirect("nothing");
    }
  }, [redirectHome]);

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCouncilEvents(() => {},'cached');
  }, [getCouncilEvents]);

  useEffect(() => {
    if (
      updatedEvent?.relationEventCouncil !== -1 ||
      updatedEvent?.relationEventCouncil
    ) {
      const _event = allCouncilEventsCached?.find(
        (eve) => eve.id === Number(updatedEvent?.relationEventCouncil)
      );
      setEvent(_event);
    }
  }, [allCouncilEventsCached, updatedEvent, setEvent]);

  useEffect(() => {
    if (updatedEvent?.description && updatedEvent?.description.blocks) {
      setEditor("draft");
    } else {
      setEditor("froala");
    }
  }, [updatedEvent]);

  useEffect(() => {
    let isMounted = true;
    if (match?.params?.id) {
      setCanonicalUrl(
        `${process.env.REACT_APP_DOMAIN_URL}${INTERNAL_LINKS.PUBLIC_EVENT}/${match?.params?.id}`
      );
      if (!isNaN(Number(match?.params?.id))) {
        getEvent(match?.params?.id, (error) => {
          if (isMounted && error) {
            history.push(INTERNAL_LINKS.NOT_FOUND);
          }
        });
      } else {
        history.push(INTERNAL_LINKS.NOT_FOUND);
      }
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  const planUpgrade = (e) => {
    e.preventDefault();
    e.stopPropagation();
    Emitter.emit(EVENT_TYPES.OPEN_PAYMENT_MODAL);
  };

  const onCancelModal = () => {
    setModalVisible(false);
  };

  const isPosibleConfirm = () => {
    let confirmData = dataEvent?.Libraries?.filter((data) => {
      return checkedObject[data?.id] === true;
    });

    if (confirmData?.length === 0) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  };

  // const onClickDownloadCalendar = (startDate, endDate, day, titleSession) => {
  //   const userTimezone = moment.tz.guess();

  //   window.open(
  //     `${process.env.REACT_APP_API_ENDPOINT}/public/event/ics/${updatedEvent?.id}?day=${day}&title=${titleSession}&userTimezone=${userTimezone}&startTimeD=${startDate}&endTimeD=${endDate}`,
  //     "_blank"
  //   );
  // };

  // const onClickAddGoogleCalendar = (startDate, endDate, titleSession, link) => {

  //   const getEventLink = (eventD, linkD) => {
  //     if (eventD?.bulLinkForEachDay === 'true') {
  //       return linkD
  //     } else {
  //       return eventD?.link
  //     }
  //   };

  //   let textSelectGoogleCalendar = (titleSession !== undefined) ? titleSession : updatedEvent?.title

  //   let googleCalendarUrl = `http://www.google.com/calendar/event?action=TEMPLATE&text=${textSelectGoogleCalendar}&details=Link to connect: ${getEventLink(updatedEvent, link)}&dates=${startDate}/${endDate}&location=${updatedEvent?.location}&trp=false&sprop=https://www.hackinghrlab.io/&sprop=name:`;

  //   window.open(googleCalendarUrl, "_blank");
  // };

  // const onClickAddYahooCalendar = (startDate, endDate, titleSession, link) => {

  //   const getEventLink = (eventD, linkD) => {
  //     if (eventD?.bulLinkForEachDay === 'true') {
  //       return linkD
  //     } else {
  //       return eventD?.link
  //     }
  //   };

  //   let textSelectYahooCalendar = (titleSession !== undefined) ? titleSession : updatedEvent?.title

  //   let yahooCalendarUrl = `https://calendar.yahoo.com/?v=60&st=${startDate}&et=${endDate}&DESC=Link to connect: ${getEventLink(updatedEvent, link)}&title=${textSelectYahooCalendar}&in_loc=${updatedEvent?.location}`;

  //   window.open(yahooCalendarUrl, "_blank");
  // };

  // const handleOnClick = ({ item, key, domEvent }) => {
  //   domEvent.stopPropagation();
  //   domEvent.preventDefault();

  //   const [startTime, endTime, day, titleData, link] = item?.props?.value;

  //   const convertedStartTime = startTime?.format("YYYYMMDDTHHmmss");

  //   const convertedEndTime = endTime.format("YYYYMMDDTHHmmss");

  //   switch (key) {
  //     case "1":
  //       onClickDownloadCalendar(startTime, endTime, day, titleData);
  //       break;
  //     case "2":
  //       onClickAddGoogleCalendar(convertedStartTime, convertedEndTime, titleData, link);
  //       break;
  //     case "3":
  //       onClickAddYahooCalendar(convertedStartTime, convertedEndTime, titleData, link);
  //       break;
  //     default:
  //   }
  // };

  // const downloadDropdownOptions = (startTimeD, endTimeDa, dayDDA, titleData, link) => {
  //   return (
  //     <Menu onClick={handleOnClick}>
  //       <Menu.Item key="1" value={[startTimeD, endTimeDa, dayDDA, titleData]}>
  //         Download ICS File
  //       </Menu.Item>
  //       <Menu.Item key="2" value={[startTimeD, endTimeDa, dayDDA, titleData, link]}>
  //         Add to Google Calendar
  //       </Menu.Item>
  //       <Menu.Item key="3" value={[startTimeD, endTimeDa, dayDDA, titleData, link]}>
  //         Add to Yahoo Calendar
  //       </Menu.Item>
  //     </Menu>
  //   );
  // };

  const displayVenueLocation = !isEmpty(updatedEvent?.venueAddress) && (
    <div>
      <h5>
        Venue Address:{" "}
        <a
          href={`http://maps.google.com/maps?q=${encodeURI(
            updatedEvent?.venueAddress?.formatted_address
          )}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {updatedEvent?.venueAddress?.formatted_address}
        </a>
      </h5>
      <GoogleMap
        lat={updatedEvent?.venueAddress.lat}
        lng={updatedEvent?.venueAddress.lng}
      />
    </div>
  );

  const filterAllEvents = (data) => {
    let newDataS = data?.filter(
      (d) => d?.image2 !== null || d?.images[0] !== undefined
    );

    return newDataS;
  };

  const searchTimeZone = (timezone) => {
    let currentTimezone = TimeZoneList?.find(
      (item) => item?.value === timezone
    );

    if (currentTimezone) {
      currentTimezone = currentTimezone?.utc[0];
    } else {
      currentTimezone = timezone;
    }

    return currentTimezone;
  };

  const filterUpcoming = (data) => {
    let dataFilter = data?.filter((event) => {
      let dateFilter =
        event?.channel !== null
          ? moment(event?.endDate, "YYYY-MM-DD hh:mm a")?.format("YYYYMMDDHHmm")
          : moment(
              event?.startAndEndTimes[event?.startAndEndTimes?.length - 1]
                ?.endTime,
              "YYYY-MM-DD hh:mm a"
            )?.format("YYYYMMDDHHmm");

      let dateNow = moment(moment.utc().add(1, "minute"), "YYYY-MM-DD hh:mm a")
        .tz(searchTimeZone(event?.timezone))
        ?.format("YYYYMMDDHHmm");

      return Number(dateNow) < Number(dateFilter);
    });

    return dataFilter;
  };

  const functionOrderPanels = (panels) => {
    if (panels !== undefined) {
      let arrayFixed = [];
      let num = -1;
      let titlesDateReady;

      const arrayOrderTime = panels?.sort((a, b) => {
        let aTime = convertToLocalTime(
          a?.startDate,
          eventCouncil?.timezone
        )?.format("YYYY");
        let bTime = convertToLocalTime(b?.startDate)?.format("YYYY");

        let aTimeRest = convertToLocalTime(
          a?.startDate,
          eventCouncil?.timezone
        )?.format("MMDDHHmm");
        let bTimeRest = convertToLocalTime(
          b?.startDate,
          eventCouncil?.timezone
        )?.format("MMDDHHmm");

        return Number(bTime - bTimeRest) - Number(aTime - aTimeRest);
      });

      for (let i = 0; i < arrayOrderTime?.length; i++) {
        let dateNow = arrayOrderTime[i].startDate;
        let timezone = eventCouncil?.timezone;
        if (
          titlesDateReady !==
          convertToLocalTime(dateNow, timezone)?.format()?.substring(0, 10)
        ) {
          titlesDateReady = convertToLocalTime(dateNow, timezone)
            ?.format()
            ?.substring(0, 10);
          num++;
          if (!arrayFixed[num]) {
            arrayFixed.push([]);
          }
          arrayFixed[num].push(arrayOrderTime[i]);
        } else {
          arrayFixed[num].push(arrayOrderTime[i]);
        }
      }

      return arrayFixed;
    } else {
      return panels;
    }
  };

  const onDownloadCertificateEvent = async () => {
    if (userProfile?.memberShip === "premium") {
      setLoading2(true);
      const domElement = certificateRef?.current;
      const canvas = await html2canvas(domElement, {
        scale: 4,
      });

      let a = document.createElement("a");
      a.href = canvas.toDataURL("image/png");
      a.download = `${dataEvent?.title} - ${userProfile?.firstName} ${userProfile?.lastName} Certificate.png`;
      a.click();
      createControlCertificate(
        {
          type: "learning",
          idTypeCertificate: dataEvent?.id,
          title: dataEvent?.title,
        },
        () => {
          setChecked(false);
          checkedObject = {};
          getAllCertificateType("learning");
        }
      );
      setLoading2(false);
      setBulPopUpDownloadEventCertificate(false);
      setBulReset(true);
    } else {
      Emitter.emit(EVENT_TYPES.SHOW_FIREWALL, "learningCertificate");
    }
  };

  const groupCheckbox = (data) => {
    if (data?.Libraries !== undefined) {
      if (data?.Libraries !== undefined) {
        data.Libraries.forEach((data) => {
          checkedObject[data?.id] = true;
        });
      }

      return data?.Libraries?.map((library, index) => (
        <ObjectCheckbox
          functionState={(data, bul) => {
            checkedObject[data] = bul;

            isPosibleConfirm();
          }}
          message={library?.title}
          id={library?.id}
          key={index}
          bulReset={bulReset}
          functionReset={() => {
            setBulReset(false);
          }}
        />
      ));
    } else {
      return <div></div>;
    }
  };

  useEffect(() => {
    if (newChangue2) {
      setObjectEvent(
        filterUpcoming(filterAllEvents(allEvents))?.filter(
          (data) => objectEvent?.id === data?.id
        )[0]
      );
      setNewChangue2(false);
      setBulModal4(false);
      setActiveModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allEvents, newChangue2, filterUpcoming, filterAllEvents]);

  const containerActions = (panel) => {
    const libraySelected = updatedEvent?.Libraries?.filter(
      (data) => data?.idPanelCouncilRelation === panel?.id
    );
    if (
      libraySelected?.length > 0 &&
      updatedEvent?.showPopupMyLearning &&
      libraySelected?.length !== undefined
    ) {
      return (
        <div
          style={{
            width: "100%",
            height: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <EventVideo
            library={libraySelected[0]}
            key={libraySelected[0]?.id}
            isPublicEvent={true}
          />
          <MicroConferenceVideoWrapper
            url={
              libraySelected[0]?.videoUrl ? libraySelected[0]?.videoUrl : null
            }
            id={libraySelected[0]?.id}
          />
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const content = (panels) => {
    let startTime = convertToLocalTime(
      panels?.startDate,
      eventCouncil?.timezone
    );
    let endTime = convertToLocalTime(panels?.endDate, eventCouncil?.timezone);

    let linkToConnect = "";

    if (updatedEvent?.bulLinkForEachDay === "true") {
      let day = moment(panels?.startDate).utc().format("DD");
      let daySelectec = updatedEvent?.startAndEndTimes?.filter(
        (item) =>
          (item?.startTime === null || item?.startTime === undefined
            ? 0
            : Number(moment(item?.startTime).utc().format("DD"))) ===
          Number(day)
      );

      linkToConnect = daySelectec[0]?.link;
    } else {
      linkToConnect = updatedEvent?.link;
    }

    return (
      <div className="content-collapse" key={panels?.id}>
        <p style={{ margin: "0px", marginTop: "3px" }}>
          <b>Session</b>: {panels?.panelName}
        </p>
        <p style={{ margin: "0px", marginTop: "3px" }}>
          <b>Session Date</b>:{` ${startTime?.format("LL")}`}
        </p>
        <p
          className="title-collapse"
          style={{ margin: "0px", marginTop: "3px" }}
        >
          <b>Session Start Time:</b> {startTime?.format("HH:mm a")}{" "}
          {moment.tz.guess()}
        </p>
        <p
          className="title-collapse"
          style={{ margin: "0px", marginTop: "3px" }}
        >
          <b>Session End Time:</b> {endTime?.format("HH:mm a")}{" "}
          {moment.tz.guess()}
        </p>
        {panels?.typePanel?.length !== 0 && (
          <p
            className="title-collapse"
            style={{ margin: "0px", marginTop: "3px" }}
          >
            <b>Session Type:</b>{" "}
            {EventTypesData !== undefined &&
              panels?.typePanel?.map((category, index) => (
                <span>
                  {capitalizeWord(EventTypesData[category])}{" "}
                  {panels?.typePanel[index + 1] && `| `}
                </span>
              ))}
          </p>
        )}
        {panels?.hrCreditsBoolean !== undefined &&
          panels?.hrCreditsBoolean !== "" && (
            <p
              className="title-collapse"
              style={{ margin: "0px", marginTop: "3px" }}
            >
              <b>Does this session offer HR credits?: </b>{" "}
              {panels?.hrCreditsBoolean === "No"
                ? "NO"
                : `YES (${panels?.numberOfHRCredits} SHRM + ${panels?.numberOfHRCredits} HRCI | Credit Type: ${panels?.typeHrCredits})`}
            </p>
          )}
        {panels?.descriptionPanel !== undefined &&
          panels?.descriptionPanel !== "" && (
            <p
              className="title-collapse"
              style={{ margin: "0px", marginTop: "20px", marginBottom: "30px" }}
            >
              <b>Session Description: </b> {panels?.descriptionPanel}
            </p>
          )}
        <Space direction="vertical">
          <div className="d-flex calendar">
            <Space
              size="middle"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              {/* {`${startTime?.format("MMMM DD")} From ${startTime?.format(
                "HH:mm a"
              )} to ${endTime?.format("HH:mm a")} (${userTimezone})`} */}
              <Dropdown
                overlay={downloadDropdownOptions(
                  startTime,
                  endTime,
                  1,
                  linkToConnect,
                  updatedEvent,
                  eventCouncil,
                  panels?.panelName,
                  panels?.startDate,
                  panels?.endDate
                )}
              >
                <a
                  href="/#"
                  className="ant-dropdown-link"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <Space>
                    {"Download Calendar"}
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
            </Space>
          </div>
        </Space>
        <div className="ajust-contain-here" style={{ paddingTop: "20px" }}>
          {panels?.CouncilEventPanelists?.filter(
            (user) => user?.isModerator === false
          )?.map((user) => {
            // return <MemberSpeakers key={user?.id} usersPanel={user} />;
            return <CardUser key={user?.id} instructor={user?.User} />;
          })}
        {/* </div>
        <div className="ajust-contain-22"> */}
          {panels?.CouncilEventPanelists?.filter(
            (user) => user?.isModerator === true
          )?.map((user) => {
            // return <MemberSpeakers key={user?.id} usersPanel={user} />;
            return <CardUser key={user?.id} instructor={user?.User} />;
          })}
        </div>
        {containerActions(panels)}
      </div>
    );
  };

  const activeCollapse = (e) => {
    let targetContainer =
      e?.target?.parentElement?.parentElement?.parentElement?.children[1]
        ?.children[0];
    let targetHeight =
      e?.target?.parentElement?.parentElement?.parentElement?.children[1];
    let targetContainerHeight = targetContainer?.clientHeight;

    targetHeight.style.cssText = `height: ${targetContainerHeight}px;`;

    if (e.target.className === "arrow-title") {
      clearTimeout(clockAnimation);
      clockAnimation2 = setTimeout(() => {
        targetHeight.style.cssText = `height: auto;`;
      }, 500);
      targetContainer.style.cssText = "position:relative;";
      e.target.className = "arrow-title-change";
      targetContainer.className = "container-collapse-title";
    } else {
      clockAnimation = setTimeout(() => {
        targetContainer.style.cssText = "position:absolute;";
      }, 490);
      clearTimeout(clockAnimation2);
      setTimeout(() => {
        targetHeight.style.cssText = `height: 0px;`;
      }, 10);
      e.target.className = "arrow-title";
      targetContainer.className = "container-collapse-title-change";
    }
  };

  const allPanelsConcil = functionOrderPanels(
    eventCouncil?.CouncilEventPanels
  )?.map((panels, index) => {
    let startTime = convertToLocalTime(
      panels[0]?.startDate,
      eventCouncil?.timezone
    );

    return (
      <div key={index} id={index}>
        <p className="title-date">
          <div className="container-arrow-title">
            <img
              src={Arrow}
              className="arrow-title"
              alt="arrow-title"
              onClick={(e) => activeCollapse(e)}
            />
          </div>
          {startTime?.format("dddd, MMMM DD")}
          <sup>{startTime?.format("Do")?.slice(-2)}</sup>
        </p>
        <div className="data-height" style={{ height: "0px" }}>
          <div
            className="container-collapse-title-change"
            style={{ position: "absolute" }}
          >
            {panels?.map((panel) => {
              return (
                <CollapseComponent
                  index={panel?.id}
                  informationCollapse={content(panel)}
                  buttons={<div></div>}
                  className={"container-panel"}
                  bulShowMore={false}
                  bulMessage={panel?.type === "Simulations" ? false : true}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  });

  const HandleBulPastEvent = () => {
    let dateFilter =
      updatedEvent?.channel !== null
        ? moment(updatedEvent?.endDate, "YYYY-MM-DD hh:mm a")?.format(
            "YYYYMMDDHHmm"
          )
        : moment(
            updatedEvent?.startAndEndTimes[
              updatedEvent?.startAndEndTimes?.length - 1
            ]?.endTime,
            "YYYY-MM-DD hh:mm a"
          )?.format("YYYYMMDDHHmm");

    let dateNow = moment(moment.utc().add(1, "minute"), "YYYY-MM-DD hh:mm a")
      .tz(searchTimeZone(updatedEvent?.timezone))
      ?.format("YYYYMMDDHHmm");

    return Number(dateNow) > Number(dateFilter);
  };

  const filterOnlySpeakersPanels = (data) => {
    let newArray = [];

    data.forEach((e) => {
      newArray = [...newArray, ...e?.CouncilEventPanelists];
    });

    newArray = newArray?.filter((item, index) => {
      return newArray?.map((a) => a?.UserId).indexOf(item?.UserId) === index;
    });

    return newArray;
  };

  const fixUser = (data) => {
    return data?.filter((item, index) => {
      return data.indexOf(item) === index;
    });
  };

  const allHrCredits = () => {
    let numberOfHRCredits = 0;

    for (let i = 0; i < eventCouncil?.CouncilEventPanels?.length; i++) {
      if (eventCouncil?.CouncilEventPanels[i]?.hrCreditsBoolean !== "No") {
        numberOfHRCredits =
          numberOfHRCredits +
          eventCouncil?.CouncilEventPanels[i]?.numberOfHRCredits;
      }
    }

    return `${numberOfHRCredits} SHRM + ${numberOfHRCredits} HRCI`;
  };

  const filterInvalidDates = (data) => {
    let newTime = data.filter((time) => {
      return time !== null;
    });

    return newTime;
  };

  const TabData = [
    {
      title: <span style={{fontSize: '21px'}}>Description</span>,
      key: 1,
      content: () => (
        <>
          <div
            className="public-event-page-content"
            style={{ borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}
          >
            {/* <div className="public-event-page-content-calendar">
              {updatedEvent?.status === "attend" && (
                <CustomButton
                  text="REGISTER HERE"
                  size={isMobile ? "md" : "lg"}
                  type="primary"
                  onClick={(e) => {
                    if (!updatedEvent?.bulCloseEvent) {
                      onAttend(e);
                    } else {
                      setPopUp(true);
                    }
                  }}
                  loading={loading}
                />
              )}
              {HandleBulPastEvent() && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CustomButton
                    text="Download Event Certificate"
                    size={isMobile ? "md" : "lg"}
                    type="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (localStorage.getItem("community") === null) {
                        if (!updatedEvent?.bulCloseEvent) {
                          onAttend(e);
                        } else {
                          setPopUp(true);
                        }
                      } else {
                        if (userProfile?.memberShip === "premium") {
                          if (!updatedEvent?.showPopupMyLearning) {
                            setBulOpenFirePopUp(true);
                            setDataEventFire(updatedEvent);
                          } else {
                            setBulPopUpDownloadEventCertificate(true);
                            setDataEvent(updatedEvent);
                          }
                        } else {
                          Emitter.emit(
                            EVENT_TYPES.SHOW_FIREWALL,
                            "learningCertificate"
                          );
                        }
                      }
                    }}
                  />
                </div>
              )}
              {updatedEvent?.status === "going" && (
                <div
                  className="going-label"
                  style={{ marginRight: "1rem", color: "#00b574" }}
                >
                  <CheckOutlined />
                  <span>I'm going</span>
                </div>
              )}
              {updatedEvent?.status === "going" && isAuthenticated && (
                <>
                  <Space direction="vertical">
                    {updatedEvent?.startAndEndTimes?.map((time, index) => {
                      const startTime = convertToLocalTime(
                        time?.startTime,
                        updatedEvent?.timezone
                      );
                      const endTime = convertToLocalTime(
                        time?.endTime,
                        updatedEvent?.timezone
                      );

                      return (
                        <div className="d-flex calendar" key={index}>
                          <Space
                            size="middle"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            {`${startTime?.format(
                              "MMMM DD"
                            )} From ${startTime?.format(
                              "HH:mm a"
                            )} to ${endTime?.format(
                              "HH:mm a"
                            )} (${userTimezone})`}
                            <Dropdown
                              overlay={downloadDropdownOptions(
                                startTime,
                                endTime,
                                index,
                                time?.link,
                                updatedEvent,
                                eventCouncil,
                                undefined,
                                time?.startTime,
                                time?.endTime,
                              )}
                            >
                              <a
                                href="/#"
                                className="ant-dropdown-link"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                }}
                              >
                                <Space>
                                  {updatedEvent?.startAndEndTimes?.length > 1
                                    ? `Download Calendar Day ${
                                        index + 1
                                      }: ${moment(startTime).format("MMM DD")} `
                                    : "Download Calendar"}
                                  <DownOutlined />
                                </Space>
                              </a>
                            </Dropdown>
                          </Space>
                        </div>
                      );
                    })}
                  </Space>
              //     <div style={{
              //   background: '#00b574', color: 'white', height: '40px', width: '400px', padding: '10px', fontWeight: '700', fontSize: '20px', borderRadius: '5px',
              //   display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '10px'
              // }}>
              //   You are now registered for this event!
              // </div>
                </>
              )}
            </div> */}
            <h1
              className={clsx("event-title", {
                "no-image": !updatedEvent?.image2 && !updatedEvent?.image,
              })}
            >
              {updatedEvent?.title}
            </h1>

            <h5 className="event-card-topic-title">
              {`Event date${
                updatedEvent?.startDate !== updatedEvent?.endDate ? "s" : ""
              }:`}
              <span>
                {" "}
                {getEventPeriod(
                  updatedEvent?.startDate,
                  updatedEvent?.endDate,
                  updatedEvent?.timezone
                )}
              </span>
            </h5>
            {updatedEvent?.location && (
              <>
                <h5 className="event-card-topic-title">
                  Event Type:{" "}
                  <span>
                    {updatedEvent?.location.map((loc, index) => {
                      if (loc === "online") {
                        return (
                          <>
                            Online{" "}
                            {updatedEvent?.location[index + 1] ? "and " : ""}
                          </>
                        );
                      }

                      return (
                        <>
                          In Person{" "}
                          {updatedEvent?.location[index + 1] ? "and " : ""}
                        </>
                      );
                    })}
                  </span>
                </h5>
              </>
            )}
            {updatedEvent?.ticket && (
              <h5 className="event-card-topic-title">
                Event tickets:
                <span>
                  {updatedEvent?.ticket === "fee"
                    ? `$${updatedEvent?.ticketFee} Registration fee`
                    : updatedEvent?.ticket === "premium"
                    ? "Only PREMIUM members"
                    : capitalizeWord(updatedEvent?.ticket)}
                </span>
              </h5>
            )}

            {updatedEvent?.type && (
              <>
                <h5 className="event-card-topic-title">
                  Content delivery format:
                  {updatedEvent?.type.map((tp, index) => (
                    <span>
                      {capitalizeWord(tp)}{" "}
                      {updatedEvent?.type[index + 1] && `|`}
                    </span>
                  ))}
                </h5>
              </>
            )}

            {updatedEvent?.categories &&
              updatedEvent?.categories?.length > 0 && (
                <h5 className="event-card-topic-title">
                  Event topics:
                  {updatedEvent?.categories.map((tp, index) => (
                    <span>
                      {capitalizeWord(tp)}{" "}
                      {updatedEvent?.categories[index + 1] && `|`}
                    </span>
                  ))}
                </h5>
              )}
            {displayVenueLocation}
            {/* <div
              style={{
                marginTop: "1rem",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {updatedEvent?.status === "going" && isAuthenticated && (
                <div
                  style={{
                    background: "#00b574",
                    color: "white",
                    height: "40px",
                    width: "400px",
                    padding: "10px",
                    fontWeight: "700",
                    fontSize: "20px",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "10px",
                  }}
                >
                  You are now registered for this event!
                </div>
              )}
            </div> */}
            {/* {isMobile && updatedEvent?.status === "going" && isAuthenticated ? (
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginTop: "20px",
                }}
              >
                <div
                  style={{
                    background: "#00b574",
                    color: "white",
                    minHeight: "40px",
                    maxWidth: "400px",
                    padding: "10px",
                    fontWeight: "700",
                    fontSize: "20px",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // marginRight: "10px",
                    margin: "0 auto",
                  }}
                >
                  You Are Now Registered!
                </div>
              </div>
            ) : (
              isMobile && (
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginTop: "20px",
                  }}
                >
                  <CustomButton
                    text="REGISTER HERE"
                    size={isMobile ? "md" : "lg"}
                    type="primary"
                    onClick={(e) => {
                      if (!updatedEvent?.bulCloseEvent) {
                        onAttend(e);
                      } else {
                        setPopUp(true);
                      }
                    }}
                    loading={loading}
                  />
                </div>
              )
            )} */}
          </div>

          {updatedEvent?.description && (
            <div
              className="public-event-page-content"
              style={{
                marginTop: "15px",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                // paddingBottom: "100px"
              }}
            >
              <h1 className="event-title">Description</h1>
              {editor === "froala" ? (
                <div
                  className="event-description"
                  dangerouslySetInnerHTML={{
                    __html: (updatedEvent?.description || {})?.html || "",
                  }}
                />
              ) : (
                <RichEdit data={updatedEvent?.description} />
              )}
              {/* {updatedEvent?.status === "attend" && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CustomButton
                    text="REGISTER HERE"
                    size={isMobile ? "md" : "lg"}
                    type="primary"
                    onClick={(e) => {
                      if (!updatedEvent?.bulCloseEvent) {
                        onAttend(e);
                      } else {
                        setPopUp(true);
                      }
                    }}
                    loading={loading}
                  />
                </div>
              )} */}
            </div>
          )}
        </>
      ),
    },
    {
      title: <span style={{fontSize: '21px'}}>Agenda</span>,
      key: 2,
      content: () => (
        <>
          {/* <div
            className="public-event-page-content"
            style={{ borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}
          >
            <h1
              className={clsx("event-title", {
                "no-image": !updatedEvent?.image2 && !updatedEvent?.image,
              })}
            >
              {updatedEvent?.title}
            </h1>

            <h5 className="event-card-topic-title">
              {`Event date${
                updatedEvent?.startDate !== updatedEvent?.endDate ? "s" : ""
              }:`}
              <span>
                {" "}
                {getEventPeriod(
                  updatedEvent?.startDate,
                  updatedEvent?.endDate,
                  updatedEvent?.timezone
                )}
              </span>
            </h5>
            {updatedEvent?.location && (
              <>
                <h5 className="event-card-topic-title">
                  Event Type:{" "}
                  <span>
                    {updatedEvent?.location.map((loc, index) => {
                      if (loc === "online") {
                        return (
                          <>
                            Online{" "}
                            {updatedEvent?.location[index + 1] ? "and " : ""}
                          </>
                        );
                      }

                      return (
                        <>
                          In Person{" "}
                          {updatedEvent?.location[index + 1] ? "and " : ""}
                        </>
                      );
                    })}
                  </span>
                </h5>
              </>
            )}
            {updatedEvent?.ticket && (
              <h5 className="event-card-topic-title">
                Event tickets:
                <span>
                  {updatedEvent?.ticket === "fee"
                    ? `$${updatedEvent?.ticketFee} Registration fee`
                    : updatedEvent?.ticket === "premium"
                    ? "Only PREMIUM members"
                    : capitalizeWord(updatedEvent?.ticket)}
                </span>
              </h5>
            )}

            {updatedEvent?.type && (
              <>
                <h5 className="event-card-topic-title">
                  Content delivery format:
                  {updatedEvent?.type.map((tp, index) => (
                    <span>
                      {capitalizeWord(tp)}{" "}
                      {updatedEvent?.type[index + 1] && `|`}
                    </span>
                  ))}
                </h5>
              </>
            )}

            {updatedEvent?.categories &&
              updatedEvent?.categories?.length > 0 && (
                <h5 className="event-card-topic-title">
                  Event topics:
                  {updatedEvent?.categories.map((tp, index) => (
                    <span>
                      {capitalizeWord(tp)}{" "}
                      {updatedEvent?.categories[index + 1] && `|`}
                    </span>
                  ))}
                </h5>
              )}
            {displayVenueLocation}
            <div
              style={{
                marginTop: "1rem",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {updatedEvent?.status === "going" && isAuthenticated && (
                <div
                  style={{
                    background: "#00b574",
                    color: "white",
                    height: "40px",
                    width: "400px",
                    padding: "10px",
                    fontWeight: "700",
                    fontSize: "20px",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "10px",
                  }}
                >
                  You are now registered for this event!
                </div>
              )}
            </div>
          </div> */}

          {updatedEvent?.relationEventCouncil !== -1 && bulStatus && (
            <>
              <div
                className="public-event-page-content"
                style={{
                  // marginTop: "15px",
                  marginTop: 0,
                  borderTopLeftRadius: "8px",
                  borderTopRightRadius: "8px",
                  // paddingBottom: "100px"
                }}
              >
                {HandleBulPastEvent() && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <CustomButton
                      text="Download Event Certificate"
                      size={isMobile ? "md" : "lg"}
                      type="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (localStorage.getItem("community") === null) {
                          if (!updatedEvent?.bulCloseEvent) {
                            onAttend(e);
                          } else {
                            setPopUp(true);
                          }
                        } else {
                          if (userProfile?.memberShip === "premium") {
                            if (!updatedEvent?.showPopupMyLearning) {
                              setBulOpenFirePopUp(true);
                              setDataEventFire(updatedEvent);
                            } else {
                              setBulPopUpDownloadEventCertificate(true);
                              setDataEvent(updatedEvent);
                            }
                          } else {
                            Emitter.emit(
                              EVENT_TYPES.SHOW_FIREWALL,
                              "learningCertificate"
                            );
                          }
                        }
                      }}
                    />
                  </div>
                )}
                {/* <h1
                  className="event-title"
                  // style={{ paddingTop: "30px" }}
                >
                  Agenda
                </h1> */}
                <div style={{ marginBottom: "50px" }}>{allPanelsConcil}</div>

                {/* {updatedEvent?.status === "attend" && (
                  // <div
                  //   className="public-event-page-content"
                  //   style={{
                  //     position: "relative",
                  //     clipPath: "polygon(0 31%, 100% 31%, 100% 100%, 0% 100%)",
                  //     top: "-10%",
                  //     width: "100%",
                  //   }}
                  // >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "50px",
                    }}
                  >
                    <CustomButton
                      text="REGISTER HERE"
                      size={isMobile ? "md" : "lg"}
                      type="primary"
                      onClick={(e) => {
                        if (!updatedEvent?.bulCloseEvent) {
                          onAttend(e);
                        } else {
                          setPopUp(true);
                        }
                      }}
                      loading={loading}
                    />
                  </div>
                )} */}
              </div>
            </>
          )}
        </>
      ),
    },
    {
      title: <span style={{fontSize: '21px'}}>Speakers</span>,
      key: 3,
      content: () => (
        <>
          {updatedEvent?.relationEventCouncil !== -1 && bulStatus && (
            <div
              className="public-event-page-instructors"
              style={{
                padding: "60px 2rem 40px",
                marginTop: 0,
              }}
            >
              {HandleBulPastEvent() && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CustomButton
                    text="Download Event Certificate"
                    size={isMobile ? "md" : "lg"}
                    type="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (localStorage.getItem("community") === null) {
                        if (!updatedEvent?.bulCloseEvent) {
                          onAttend(e);
                        } else {
                          setPopUp(true);
                        }
                      } else {
                        if (userProfile?.memberShip === "premium") {
                          if (!updatedEvent?.showPopupMyLearning) {
                            setBulOpenFirePopUp(true);
                            setDataEventFire(updatedEvent);
                          } else {
                            setBulPopUpDownloadEventCertificate(true);
                            setDataEvent(updatedEvent);
                          }
                        } else {
                          Emitter.emit(
                            EVENT_TYPES.SHOW_FIREWALL,
                            "learningCertificate"
                          );
                        }
                      }
                    }}
                  />
                </div>
              )}
              {/* <h1
                className="event-title"
                // style={{ paddingTop: "20px" }}
              >
                Speakers
              </h1> */}
              <div className="event-peoples">
                {filterOnlySpeakersPanels(
                  eventCouncil?.CouncilEventPanels
                )?.map((data) => {
                  return (
                    // <div style={{ width: "250px", float: "left" }}>
                      // <MemberSpeakers key={data?.id} usersPanel={data} />
                      <CardUser key={data?.id} instructor={data?.User} />
                    // </div>
                  );
                })}
              </div>
              {/* {updatedEvent?.status === "attend" && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CustomButton
                    text="REGISTER HERE"
                    size={isMobile ? "md" : "lg"}
                    type="primary"
                    onClick={(e) => {
                      if (!updatedEvent?.bulCloseEvent) {
                        onAttend(e);
                      } else {
                        setPopUp(true);
                      }
                    }}
                    loading={loading}
                  />
                </div>
              )} */}
            </div>
          )}
        </>
      ),
    },
    {
      title: <span style={{fontSize: '21px'}}>Attendees</span>,
      key: 4,
      content: () => (
        <>
          {updatedEvent?.relationEventCouncil !== -1 && bulStatus && (
            <div
              className="public-event-page-instructors"
              style={{
                paddingBottom: "40px",
                marginTop: 0,
              }}
            >
              {/* <h1
                className="event-title"
                // style={{ paddingTop: "20px" }}
              >
                Attendees
              </h1> */}
              <div
                style={{
                  width: "100%",
                  height: "auto",
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {updatedEvent?.usersData &&
                  [...updatedEvent?.usersData, { type: "finish" }].map(
                    (u, index) => {
                      if (
                        u?.type === "finish" &&
                        fixUser(updatedEvent?.users)?.length > 16 &&
                        updatedEvent?.usersData?.length === 16
                      ) {
                        return (
                          <Avatar
                            key={index}
                            shape="square"
                            size={45}
                            style={{
                              fontSize: "1rem",
                              borderRadius: "15px",
                              position: "relative",
                              border: "4px solid white",
                              boxSizing: "content-box",
                              fontWeight: "bold",
                              left: `${-15 * index}px`,
                              color: "black",
                              zIndex: `${1 * index}`,
                            }}
                          >
                            +{fixUser(updatedEvent?.users)?.length - 16}
                          </Avatar>
                        );
                      } else {
                        if (u?.type === "finish") {
                          return (
                            <div key={index} style={{ display: "none" }}></div>
                          );
                        } else {
                          return u?.img ? (
                            <Avatar
                              key={index}
                              shape="square"
                              style={{
                                borderRadius: "15px",
                                position: "relative",
                                left: `${-15 * index}px`,
                                boxSizing: "content-box",
                                border: "4px solid white",
                                zIndex: `${1 * index}`,
                              }}
                              size={45}
                              src={u?.img}
                            />
                          ) : (
                            <Avatar
                              key={index}
                              shape="square"
                              size={45}
                              style={{
                                fontSize: "1rem",
                                borderRadius: "15px",
                                position: "relative",
                                boxSizing: "content-box",
                                fontWeight: "bold",
                                border: "4px solid white",
                                left: `${-15 * index}px`,
                                zIndex: `${1 * index}`,
                              }}
                            >
                              {u?.abbrName}
                            </Avatar>
                          );
                        }
                      }
                    }
                  )}
              </div>
              {/* {updatedEvent?.status === "attend" && (
                // <div
                //   className="public-event-page-content"
                //   style={{
                //     width: "100%",
                //     position: "relative",
                //     clipPath: "polygon(0 31%, 100% 31%, 100% 100%, 0% 100%)",
                //     top: "-10%",
                //   }}
                // >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "50px",
                  }}
                >
                  <CustomButton
                    text="REGISTER HERE"
                    size={isMobile ? "md" : "lg"}
                    type="primary"
                    onClick={(e) => {
                      if (!updatedEvent?.bulCloseEvent) {
                        onAttend(e);
                      } else {
                        setPopUp(true);
                      }
                    }}
                    loading={loading}
                  />
                </div>
                // </div>
              )} */}
            </div>
          )}
        </>
      ),
    },
    {
      title: <span style={{fontSize: '21px'}}>HR Credits</span>,
      key: 5,
      content: () => (
        <>
          {/* <div
            className="public-event-page-content"
            style={{ borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}
          >
            <h1
              className={clsx("event-title", {
                "no-image": !updatedEvent?.image2 && !updatedEvent?.image,
              })}
            >
              {updatedEvent?.title}
            </h1>

            <h5 className="event-card-topic-title">
              {`Event date${
                updatedEvent?.startDate !== updatedEvent?.endDate ? "s" : ""
              }:`}
              <span>
                {" "}
                {getEventPeriod(
                  updatedEvent?.startDate,
                  updatedEvent?.endDate,
                  updatedEvent?.timezone
                )}
              </span>
            </h5>
            {updatedEvent?.location && (
              <>
                <h5 className="event-card-topic-title">
                  Event Type:{" "}
                  <span>
                    {updatedEvent?.location.map((loc, index) => {
                      if (loc === "online") {
                        return (
                          <>
                            Online{" "}
                            {updatedEvent?.location[index + 1] ? "and " : ""}
                          </>
                        );
                      }

                      return (
                        <>
                          In Person{" "}
                          {updatedEvent?.location[index + 1] ? "and " : ""}
                        </>
                      );
                    })}
                  </span>
                </h5>
              </>
            )}
            {updatedEvent?.ticket && (
              <h5 className="event-card-topic-title">
                Event tickets:
                <span>
                  {updatedEvent?.ticket === "fee"
                    ? `$${updatedEvent?.ticketFee} Registration fee`
                    : updatedEvent?.ticket === "premium"
                    ? "Only PREMIUM members"
                    : capitalizeWord(updatedEvent?.ticket)}
                </span>
              </h5>
            )}

            {updatedEvent?.type && (
              <>
                <h5 className="event-card-topic-title">
                  Content delivery format:
                  {updatedEvent?.type.map((tp, index) => (
                    <span>
                      {capitalizeWord(tp)}{" "}
                      {updatedEvent?.type[index + 1] && `|`}
                    </span>
                  ))}
                </h5>
              </>
            )}

            {updatedEvent?.categories &&
              updatedEvent?.categories?.length > 0 && (
                <h5 className="event-card-topic-title">
                  Event topics:
                  {updatedEvent?.categories.map((tp, index) => (
                    <span>
                      {capitalizeWord(tp)}{" "}
                      {updatedEvent?.categories[index + 1] && `|`}
                    </span>
                  ))}
                </h5>
              )}
            {displayVenueLocation}
            <div
              style={{
                marginTop: "1rem",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {updatedEvent?.status === "going" && isAuthenticated && (
                <div
                  style={{
                    background: "#00b574",
                    color: "white",
                    height: "40px",
                    width: "400px",
                    padding: "10px",
                    fontWeight: "700",
                    fontSize: "20px",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "10px",
                  }}
                >
                  You are now registered for this event!
                </div>
              )}
            </div>
          </div> */}

          {updatedEvent?.relationEventCouncil !== -1 &&
            updatedEvent !== undefined &&
            bulStatus && (
              <div
                className="public-event-page-instructors"
                style={{
                  borderTopRightRadius: "8px",
                  borderTopLeftRadius: "8px",
                  // marginTop: "15px",
                  // marginBottom: "40px",
                  paddingBottom: "50px",
                  marginTop: 0,
                }}
              >
                {/* <h1 className="event-title">HR Credits</h1> */}
                <p style={{ marginTop: "20px", marginBottom: "20px" }}>
                  Hacking HR is recognized as an approved training provider with the <b>Society for Human Resource Management</b> (SHRM) and the <b>HR Certification Institute</b> (HRCI). Members of these organizations are eligible to receive <b>personal development credits</b> (PDCs) upon completion of the learning sessions in this event. The content of this event is free (live or the post-event recorded videos), but to get access to the HR (SHRM/HRCI) credits and our digital certificate of participation you must have a premium account in the Hacking HR LAB. The codes for the HR credits are generally available a week later in the Hacking HR LAB {'>'} My Learning {'>'} Event Videos. Thank you!
                </p>
                <h5
                  className="event-card-topic-title"
                  style={{ marginTop: "20px" }}
                >
                  This event offers:
                  <span>{allHrCredits()}</span>
                </h5>
                {/* {updatedEvent?.status === "attend" && (
                  // <div
                  //   className="public-event-page-content"
                  //   style={{
                  //     width: "100%",
                  //     position: "relative",
                  //     clipPath: "polygon(0 31%, 100% 31%, 100% 100%, 0% 100%)",
                  //     top: "-10%",
                  //   }}
                  // >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "50px",
                    }}
                  >
                    <CustomButton
                      text="REGISTER HERE"
                      size={isMobile ? "md" : "lg"}
                      type="primary"
                      onClick={(e) => {
                        if (!updatedEvent?.bulCloseEvent) {
                          onAttend(e);
                        } else {
                          setPopUp(true);
                        }
                      }}
                      loading={loading}
                    />
                  </div>
                  // </div>
                )} */}
              </div>
            )}
        </>
      ),
      // disabled: menteeProfile?.topics?.length < 1,
    },
    (updatedEvent?.status !== "attend" && updatedEvent?.status !== "confirmed") && {
      title: <span style={{fontSize: '21px'}}>Download Calendars</span>,
      key: 6,
      content: () => (
        <>
          {(updatedEvent?.status !== "attend" && updatedEvent?.status !== "confirmed") && (
            <div
              className="event-details-description"
              style={{
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
              }}
            >
              <h1 className="event-title">Calendar Invites</h1>
              <Space direction="vertical">
                {!isEmpty(updatedEvent?.startAndEndTimes) &&
                  filterInvalidDates(updatedEvent?.startAndEndTimes)?.map(
                    (time, index) => {
                      const startTime = convertToLocalTime(
                        time?.startTime,
                        updatedEvent?.timezone
                      );
                      const endTime = convertToLocalTime(
                        time?.endTime,
                        updatedEvent?.timezone
                      );

                      return (
                        <Dropdown
                          key={time?.startTime}
                          overlay={downloadDropdownOptions(
                            startTime,
                            endTime,
                            index,
                            time?.link,
                            updatedEvent,
                            eventCouncil,
                            undefined,
                            time?.startTime,
                            time?.endTime,
                          )}
                        >
                          <a
                            href="/#"
                            className="ant-dropdown-link"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            {updatedEvent?.startAndEndTimes?.length > 1
                              ? `Download Calendar Day ${index + 1}: ${moment(
                                  startTime
                                ).format("MMM DD")} `
                              : "Download Calendar"}
                            <DownOutlined />
                          </a>
                        </Dropdown>
                      );
                    }
                  )}
              </Space>
            </div>
          )}
        </>
      ),
    },
  ].filter(Boolean);

  return (
    <div className="public-event-page">
      {showFirewall && (
        <div
          className="event-card-firewall"
          onClick={() => setShowFirewall(false)}
        >
          <div className="upgrade-notification-panel" onClick={planUpgrade}>
            <h3>
              This event requires a PREMIUM Membership to join. Click here to
              upgrate to a Premium Membership and get unlimited access to the
              LAB features.
            </h3>
          </div>
        </div>
      )}
      <Helmet>
        <title>{updatedEvent?.title}</title>
        <meta name="description" content={updatedEvent?.about} />
        <meta name="twitter:creator" />
        <meta
          name="twitter:image"
          content={updatedEvent?.image || updatedEvent?.image2}
        />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:title" content={updatedEvent?.title} />
        <meta property="og:description" content={updatedEvent?.about} />
        <meta
          property="og:image"
          content={updatedEvent?.image || updatedEvent?.image2}
        />
        <link rel="canonical" href={canonicalUrl} />
        <link
          rel="image_src"
          href={updatedEvent?.image || updatedEvent?.image2}
        />
      </Helmet>

      <div className="public-event-page-header">
        {!isEmpty(updatedEvent?.images) && (
          <Carousel autoplay dots>
            {updatedEvent?.images.map((image) => (
              <img src={image} alt="updatedEvent-img" key={image} />
            ))}
          </Carousel>
        )}
        {isEmpty(updatedEvent?.images) &&
          !updatedEvent?.image2 &&
          updatedEvent?.image && (
            // <img src={updatedEvent?.image} alt="updatedEvent-img" />
            <div
              style={{
                position: "relative",
              }}
            >
              <img src={updatedEvent?.image} alt="updatedEvent-img" />

              {updatedEvent?.status !== "attend" && isAuthenticated ? (
                <div
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      padding: "10px",
                      borderRadius: "8px",
                      backdropFilter: "blur(5px)",
                    }}
                  >
                    <div
                      style={{
                        background: "#00b574",
                        color: "white",
                        height: "40px",
                        // width: "400px",
                        padding: "10px",
                        fontWeight: "700",
                        fontSize: "20px",
                        borderRadius: "5px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: "10px",
                      }}
                    >
                      You Are Now Registered!
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      padding: "10px",
                      borderRadius: "8px",
                      backdropFilter: "blur(5px)",
                    }}
                  >
                    <CustomButton
                      text="REGISTER HERE"
                      size={isMobile ? "md" : "lg"}
                      type="primary"
                      onClick={(e) => {
                        if (!updatedEvent?.bulCloseEvent) {
                          onAttend(e);
                        } else {
                          setPopUp(true);
                        }
                      }}
                      loading={loading}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        {isEmpty(updatedEvent?.images) &&
          !updatedEvent?.image &&
          updatedEvent?.image2 && (
            // <img src={updatedEvent?.image2} alt="updatedEvent-img" />
            <div
              style={{
                position: "relative",
              }}
            >
              <img src={updatedEvent?.image2} alt="updatedEvent-img" />

              {updatedEvent?.status !== "attend" && isAuthenticated ? (
                <div
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      padding: "10px",
                      borderRadius: "8px",
                      backdropFilter: "blur(5px)",
                    }}
                  >
                    <div
                      style={{
                        background: "#00b574",
                        color: "white",
                        height: "40px",
                        // width: "400px",
                        padding: "10px",
                        fontWeight: "700",
                        fontSize: "20px",
                        borderRadius: "5px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: "10px",
                      }}
                    >
                      You Are Now Registered!
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      padding: "10px",
                      borderRadius: "8px",
                      backdropFilter: "blur(5px)",
                    }}
                  >
                    <CustomButton
                      text="REGISTER HERE"
                      size={isMobile ? "md" : "lg"}
                      type="primary"
                      onClick={(e) => {
                        if (!updatedEvent?.bulCloseEvent) {
                          onAttend(e);
                        } else {
                          setPopUp(true);
                        }
                      }}
                      loading={loading}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        {isEmpty(updatedEvent?.images) &&
          !updatedEvent?.image &&
          !updatedEvent?.image2 && (
            <div className="public-event-page-header-defaultimg" />
          )}

        {/* {updatedEvent?.image && updatedEvent?.image !== undefined && (
          // <div className="public-event-page-header-imgUpdatedEvent">
          <div
            style={{
              position: "relative",
            }}
          >
            <img src={updatedEvent?.image2} alt="updatedEvent-img" />

            {updatedEvent?.status === "going" && isAuthenticated ? (
              <div
                style={{
                  position: "absolute",
                  bottom: "10px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    padding: "10px",
                    borderRadius: "8px",
                    backdropFilter: "blur(5px)",
                  }}
                >
                  <div
                    style={{
                      background: "#00b574",
                      color: "white",
                      height: "40px",
                      // width: "400px",
                      padding: "10px",
                      fontWeight: "700",
                      fontSize: "20px",
                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: "10px",
                    }}
                  >
                    You Are Now Registered!
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  position: "absolute",
                  bottom: "10px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    padding: "10px",
                    borderRadius: "8px",
                    backdropFilter: "blur(5px)",
                  }}
                >
                  <CustomButton
                    text="REGISTER HERE"
                    size={isMobile ? "md" : "lg"}
                    type="primary"
                    onClick={(e) => {
                      if (!updatedEvent?.bulCloseEvent) {
                        onAttend(e);
                      } else {
                        setPopUp(true);
                      }
                    }}
                    loading={loading}
                  />
                </div>
              </div>
            )}
          </div>
        )} */}
        {updatedEvent?.image === undefined && (
          <div className="public-event-page-header-defaultimg" />
        )}

        <div className="public-event-page-header-title">
          <Modal
            visible={modalVisible}
            footer={null}
            width={400}
            bodyStyle={{ overflow: "auto", padding: "20px" }}
            className="modal-container-login"
            onCancel={onCancelModal}
          >
            <Login
              login={true}
              signUp={false}
              history={null}
              match={{ params: {} }}
              modal={onCancelModal}
              onClose={onCancelModal}
            />
          </Modal>
        </div>
      </div>
      {/* <div className="public-event-page-content">
        <div className="public-event-page-content-calendar">
          {updatedEvent?.status === "attend" && (
            <CustomButton
              text="REGISTER HERE"
              size={isMobile ? "md" : "lg"}
              type="primary"
              onClick={(e) => {
                if (!updatedEvent?.bulCloseEvent) {
                  onAttend(e);
                } else {
                  setPopUp(true);
                }
              }}
              loading={loading}
            />
          )}
          {HandleBulPastEvent() && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CustomButton
                text="Download Event Certificate"
                size={isMobile ? "md" : "lg"}
                type="primary"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (localStorage.getItem("community") === null) {
                    if (!updatedEvent?.bulCloseEvent) {
                      onAttend(e);
                    } else {
                      setPopUp(true);
                    }
                  } else {
                    if (userProfile?.memberShip === "premium") {
                      if (!updatedEvent?.showPopupMyLearning) {
                        setBulOpenFirePopUp(true);
                        setDataEventFire(updatedEvent);
                      } else {
                        setBulPopUpDownloadEventCertificate(true);
                        setDataEvent(updatedEvent);
                      }
                    } else {
                      Emitter.emit(
                        EVENT_TYPES.SHOW_FIREWALL,
                        "learningCertificate"
                      );
                    }
                  }
                }}
              />
            </div>
          )}
          {updatedEvent?.status === "going" && (
            <div
              className="going-label"
              style={{ marginRight: "1rem", color: "#00b574" }}
            >
              <CheckOutlined />
              <span>I'm going</span>
            </div>
          )}
          {updatedEvent?.status === "going" && isAuthenticated && (
            <>
              <Space direction="vertical">
                {updatedEvent?.startAndEndTimes?.map((time, index) => {
                  const startTime = convertToLocalTime(
                    time?.startTime,
                    updatedEvent?.timezone
                  );
                  const endTime = convertToLocalTime(
                    time?.endTime,
                    updatedEvent?.timezone
                  );

                  return (
                    <div className="d-flex calendar" key={index}>
                      <Space
                        size="middle"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        {`${startTime?.format(
                          "MMMM DD"
                        )} From ${startTime?.format(
                          "HH:mm a"
                        )} to ${endTime?.format("HH:mm")} (${userTimezone})`}
                        <Dropdown
                          overlay={downloadDropdownOptions(
                            startTime,
                            endTime,
                            index,
                            time?.link,
                            updatedEvent,
                            eventCouncil,
                            undefined,
                            time?.startTime,
                            time?.endTime,
                          )}
                        >
                          <a
                            href="/#"
                            className="ant-dropdown-link"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            <Space>
                              {updatedEvent?.startAndEndTimes?.length > 1
                                ? `Download Calendar Day ${index + 1}: ${moment(
                                    startTime
                                  ).format("MMM DD")} `
                                : "Download Calendar"}
                              <DownOutlined />
                            </Space>
                          </a>
                        </Dropdown>
                      </Space>
                    </div>
                  );
                })}
              </Space>
              // <div style={{
              //   background: '#00b574', color: 'white', height: '40px', width: '400px', padding: '10px', fontWeight: '700', fontSize: '20px', borderRadius: '5px',
              //   display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '10px'
              // }}>
              //   You are now registered for this event!
              // </div>
            </>
          )}
        </div>
        <h1
          className={clsx("event-title", {
            "no-image": !updatedEvent?.image2 && !updatedEvent?.image,
          })}
        >
          {updatedEvent?.title}
        </h1>

        <h5 className="event-card-topic-title">
          {`Event date${
            updatedEvent?.startDate !== updatedEvent?.endDate ? "s" : ""
          }:`}
          <span>
            {" "}
            {getEventPeriod(
              updatedEvent?.startDate,
              updatedEvent?.endDate,
              updatedEvent?.timezone
            )}
          </span>
        </h5>
        {updatedEvent?.location && (
          <>
            <h5 className="event-card-topic-title">
              Event Type:{" "}
              <span>
                {updatedEvent?.location.map((loc, index) => {
                  if (loc === "online") {
                    return (
                      <>
                        Online {updatedEvent?.location[index + 1] ? "and " : ""}
                      </>
                    );
                  }

                  return (
                    <>
                      In Person{" "}
                      {updatedEvent?.location[index + 1] ? "and " : ""}
                    </>
                  );
                })}
              </span>
            </h5>
          </>
        )}
        {updatedEvent?.ticket && (
          <h5 className="event-card-topic-title">
            Event tickets:
            <span>
              {updatedEvent?.ticket === "fee"
                ? `$${updatedEvent?.ticketFee} Registration fee`
                : updatedEvent?.ticket === "premium"
                ? "Only PREMIUM members"
                : capitalizeWord(updatedEvent?.ticket)}
            </span>
          </h5>
        )}

        {updatedEvent?.type && (
          <>
            <h5 className="event-card-topic-title">
              Content delivery format:
              {updatedEvent?.type.map((tp, index) => (
                <span>
                  {capitalizeWord(tp)} {updatedEvent?.type[index + 1] && `|`}
                </span>
              ))}
            </h5>
          </>
        )}

        {updatedEvent?.categories && updatedEvent?.categories?.length > 0 && (
          <h5 className="event-card-topic-title">
            Event topics:
            {updatedEvent?.categories.map((tp, index) => (
              <span>
                {capitalizeWord(tp)}{" "}
                {updatedEvent?.categories[index + 1] && `|`}
              </span>
            ))}
          </h5>
        )}
        {displayVenueLocation}
        <div
          style={{
            marginTop: "1rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {updatedEvent?.status === "going" && isAuthenticated && (
            <div
              style={{
                background: "#00b574",
                color: "white",
                height: "40px",
                width: "400px",
                padding: "10px",
                fontWeight: "700",
                fontSize: "20px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              You are now registered for this event!
            </div>
          )}
        </div>
      </div> */}

      <div
        className="public-event-page-description-tabs"
        // style={{ paddingTop: "15px" }}
      >
        <Tabs
          tabBarExtraContent={
            undefined
            // !isMobile && updatedEvent?.status === "going" && isAuthenticated ? (
            //   <div
            //     style={{
            //       background: "#00b574",
            //       color: "white",
            //       height: "40px",
            //       // width: "400px",
            //       padding: "10px",
            //       fontWeight: "700",
            //       fontSize: "20px",
            //       borderRadius: "5px",
            //       display: "flex",
            //       justifyContent: "center",
            //       alignItems: "center",
            //       marginRight: "10px",
            //     }}
            //   >
            //     You Are Now Registered!
            //   </div>
            // ) : (
            //   !isMobile && (
            //     <CustomButton
            //       text="REGISTER HERE"
            //       size={isMobile ? "md" : "lg"}
            //       type="primary"
            //       onClick={(e) => {
            //         if (!updatedEvent?.bulCloseEvent) {
            //           onAttend(e);
            //         } else {
            //           setPopUp(true);
            //         }
            //       }}
            //       loading={loading}
            //     />
            //   )
            // )
          }
          data={TabData}
          current={currentTab}
          onChange={setCurrentTab}
          // tabPosition={"bottom"}
        />
      </div>

      {/* {updatedEvent?.description && (
        <div className="public-event-page-description">
          <h1 className="event-title">Description</h1>
          {editor === "froala" ? (
            <div
              className="event-description"
              dangerouslySetInnerHTML={{
                __html: (updatedEvent?.description || {})?.html || "",
              }}
            />
          ) : (
            <RichEdit data={updatedEvent?.description} />
          )}
          {updatedEvent?.status === "attend" && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CustomButton
                text="REGISTER HERE"
                size={isMobile ? "md" : "lg"}
                type="primary"
                onClick={(e) => {
                  if (!updatedEvent?.bulCloseEvent) {
                    onAttend(e);
                  } else {
                    setPopUp(true);
                  }
                }}
                loading={loading}
              />
            </div>
          )}
        </div>
      )} */}

      {/* {updatedEvent?.relationEventCouncil !== -1 && bulStatus && (
        <div className="public-event-page-instructors">
          {HandleBulPastEvent() && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CustomButton
                text="Download Event Certificate"
                size={isMobile ? "md" : "lg"}
                type="primary"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (localStorage.getItem("community") === null) {
                    if (!updatedEvent?.bulCloseEvent) {
                      onAttend(e);
                    } else {
                      setPopUp(true);
                    }
                  } else {
                    if (userProfile?.memberShip === "premium") {
                      if (!updatedEvent?.showPopupMyLearning) {
                        setBulOpenFirePopUp(true);
                        setDataEventFire(updatedEvent);
                      } else {
                        setBulPopUpDownloadEventCertificate(true);
                        setDataEvent(updatedEvent);
                      }
                    } else {
                      Emitter.emit(
                        EVENT_TYPES.SHOW_FIREWALL,
                        "learningCertificate"
                      );
                    }
                  }
                }}
              />
            </div>
          )}
          <h1 className="event-title" style={{ paddingTop: "20px" }}>
            Speakers
          </h1>
          <div className="event-peoples">
            {filterOnlySpeakersPanels(eventCouncil?.CouncilEventPanels)?.map(
              (data) => {
                return (
                  <div style={{ width: "250px", float: "left" }}>
                    <MemberSpeakers key={data?.id} usersPanel={data} />
                  </div>
                );
              }
            )}
          </div>
          {updatedEvent?.status === "attend" && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CustomButton
                text="REGISTER HERE"
                size={isMobile ? "md" : "lg"}
                type="primary"
                onClick={(e) => {
                  if (!updatedEvent?.bulCloseEvent) {
                    onAttend(e);
                  } else {
                    setPopUp(true);
                  }
                }}
                loading={loading}
              />
            </div>
          )}
        </div>
      )} */}

      {/* {updatedEvent?.relationEventCouncil !== -1 && bulStatus && (
        <>
          <div
            className="public-event-page-content"
            style={{ marginTop: "15px", paddingBottom: "100px" }}
          >
            {HandleBulPastEvent() && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CustomButton
                  text="Download Event Certificate"
                  size={isMobile ? "md" : "lg"}
                  type="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (localStorage.getItem("community") === null) {
                      if (!updatedEvent?.bulCloseEvent) {
                        onAttend(e);
                      } else {
                        setPopUp(true);
                      }
                    } else {
                      if (userProfile?.memberShip === "premium") {
                        if (!updatedEvent?.showPopupMyLearning) {
                          setBulOpenFirePopUp(true);
                          setDataEventFire(updatedEvent);
                        } else {
                          setBulPopUpDownloadEventCertificate(true);
                          setDataEvent(updatedEvent);
                        }
                      } else {
                        Emitter.emit(
                          EVENT_TYPES.SHOW_FIREWALL,
                          "learningCertificate"
                        );
                      }
                    }
                  }}
                />
              </div>
            )}
            <h1 className="event-title" style={{ paddingTop: "30px" }}>
              Agenda
            </h1>
            {allPanelsConcil}
          </div>
          {updatedEvent?.status === "attend" && (
            <div
              className="public-event-page-content"
              style={{
                position: "relative",
                clipPath: "polygon(0 31%, 100% 31%, 100% 100%, 0% 100%)",
                top: "-10%",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CustomButton
                  text="REGISTER HERE"
                  size={isMobile ? "md" : "lg"}
                  type="primary"
                  onClick={(e) => {
                    if (!updatedEvent?.bulCloseEvent) {
                      onAttend(e);
                    } else {
                      setPopUp(true);
                    }
                  }}
                  loading={loading}
                />
              </div>
            </div>
          )}
        </>
      )} */}

      <>
        <div
          className="public-event-page-content"
          style={{ marginTop: "15px", paddingBottom: "100px" }}
        >
          {HandleBulPastEvent() && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CustomButton
                text="Download Event Certificate"
                size={isMobile ? "md" : "lg"}
                type="primary"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (localStorage.getItem("community") === null) {
                    if (!updatedEvent?.bulCloseEvent) {
                      onAttend(e);
                    } else {
                      setPopUp(true);
                    }
                  } else {
                    if (userProfile?.memberShip === "premium") {
                      if (!updatedEvent?.showPopupMyLearning) {
                        setBulOpenFirePopUp(true);
                        setDataEventFire(updatedEvent);
                      } else {
                        setBulPopUpDownloadEventCertificate(true);
                        setDataEvent(updatedEvent);
                      }
                    } else {
                      Emitter.emit(
                        EVENT_TYPES.SHOW_FIREWALL,
                        "learningCertificate"
                      );
                    }
                  }
                }}
              />
            </div>
          )}
          <div className="container-father">
            <p className="second-title-community">Other Events</p>
            <div style={{ position: "relative" }}>
              {filterUpcoming(filterAllEvents(allEvents)).map((data, index) => {
                const { image2, images } = data;

                return (
                  <div key={index} style={{ marginBottom: "20px" }}>
                    <div
                      className="event-card-img"
                      style={{ position: "relative" }}
                    >
                      {!isEmpty(images) && (
                        <img
                          src={images[0]}
                          alt="card-img"
                          style={{ width: "100%" }}
                        />
                      )}
                      {isEmpty(images) && image2 && (
                        <img src={image2} alt="card-img" />
                      )}
                      <div
                        style={
                          window.innerWidth > 600
                            ? {
                                display: "flex",
                                justifyContent: "center",
                                position: "absolute",
                                bottom: "10%",
                                width: "100%",
                              }
                            : {
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                              }
                        }
                      >
                        <CustomButton
                          className="button-speaker font-size-more"
                          text="Event Information"
                          size="md"
                          style={{
                            padding: "10px",
                            height: "40px",
                            width: "320px",
                          }}
                          type={"primary"}
                          onClick={() => {
                            if (data.id !== eventId) {
                              history.push(`${data.id}`);
                              setEventId(data.id);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
                marginBottom: "40px",
              }}
            >
              <CustomButton
                className="button-speaker font-size-more"
                text="SHOW ALL EVENTS"
                size="md"
                style={{ padding: "10px", height: "40px", width: "320px" }}
                type={"primary"}
                onClick={() => {
                  if (localStorage.getItem("community") === null) {
                    setTypeRedirect("events");
                    setBulModal4(true);
                  } else {
                    history.push(INTERNAL_LINKS.EVENTS);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </>
      <Modal
        visible={bulModal4}
        footer={null}
        width={400}
        bodyStyle={{ overflow: "auto", padding: "20px" }}
        className="modal-container-login"
        onCancel={() => {
          setBulModal4(false);
          setTypeRedirect("nothing");
        }}
      >
        <Login
          login={true}
          signup={false}
          type={"ladingPague"}
          history={null}
          confirm={() => {}}
          match={{ params: {} }}
          modal={() => {
            setBulModal4(false);
            setTypeRedirect("nothing");
          }}
          onClose={() => {
            setBulModal4(false);
            setTypeRedirect("nothing");
          }}
        />
      </Modal>
      <Modal
        className={clsx("custom-modal")}
        wrapClassName={clsx("custom-modal-wrap")}
        title={
          <div className="custom-modal-title">
            <div className="custom-modal-logo">
              <img src={IconLogo} alt="custom-logo" />
            </div>
          </div>
        }
        centered
        onCancel={() => {
          setActiveModal(false);
          setBulModal6(false);
          setTypeRedirect("nothing");
        }}
        visible={activeModal || bulModal6}
        closable={true}
        footer={[]}
        width={"800px"}
        style={{ top: "100px" }}
        closeIcon={<CloseCircleFilled className="custom-modal-close" />}
      >
        {bulModal6 && (
          <Login
            login={true}
            signup={false}
            type={"ladingPague"}
            history={null}
            match={{ params: {} }}
            modal={() => {
              setBulModal6(false);
              setActiveModal(false);
            }}
            onClose={() => {
              setBulModal6(false);
              setActiveModal(false);
            }}
          />
        )}
        {activeModal && (
          <EventModalContainer
            event={objectEvent}
            visible={activeModal}
            allCouncilEventsCached={allCouncilEventsCached}
            setTypeRedirect={() => {
              setTypeRedirect("events");
            }}
            setObjectEvent={(data) => {
              setObjectEvent({
                ...objectEvent,
                status: data,
              });
            }}
          />
        )}
      </Modal>
      <Modal
        className={clsx("custom-modal")}
        wrapClassName={clsx("custom-modal-wrap")}
        centered
        onCancel={() => {
          setChecked(false);
          checkedObject = {};
          setBulPopUpDownloadEventCertificate(false);
          setBulReset(true);
        }}
        visible={bulPopUpDownloadEventCertificate}
        closable={true}
        footer={[]}
        width="500px"
        style={{ top: "100px" }}
        title={
          <div
            className="custom-modal-title"
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <p
              style={{
                margin: "0px",
                marginTop: "5px",
                fontWeight: "bold",
                fontSize: "22px",
                width: "100%",
                textAlign: "center",
                color: "#3c4752",
              }}
            >
              Download Event Certificate
            </p>
            <div className="custom-modal-logo">
              <img src={IconLogo} alt="custom-logo" />
            </div>
          </div>
        }
        closeIcon={<CloseCircleFilled className="custom-modal-close" />}
      >
        <div className="library-claim-modal">
          <p>
            Thank you for attending Hacking HR’s event{" "}
            <strong>{dataEvent?.title}</strong>
          </p>
          <p>
            To download your Digital Certificate of Attendance, please verify
            that you attended LIVE or watched all the sessions below and then
            click on CONFIRM.
          </p>
          <p>
            When you click CONFIRM an image PNG will download. In addition, we
            will send you the certificate automatically via email and also the
            certificate ID and unique URL so that you can share your badge on
            LinkedIn or any other platform.
          </p>
          {groupCheckbox(dataEvent)}
          <p>Thank you!</p>
          <div className="podcast-claim-modal-footer">
            <CustomButton
              disabled={!checked}
              text="Confirm/Download"
              size="md"
              onClick={() => onDownloadCertificateEvent()}
              loading={loading2}
            />
          </div>
        </div>
      </Modal>
      <Modal
        className={clsx("custom-modal")}
        wrapClassName={clsx("custom-modal-wrap")}
        centered
        onCancel={() => {
          setBulOpenFirePopUp(false);
          setDataEventFire({});
        }}
        visible={bulOpenFirePopUp}
        closable={true}
        footer={[]}
        width="500px"
        style={{ top: "100px" }}
        title={
          <div
            className="custom-modal-title"
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            {/* <p
              style={{
                margin: '0px',
                marginTop: '5px',
                fontWeight: 'bold',
                fontSize: '22px',
                width: '100%',
                textAlign: 'center',
                color: "#3c4752",
              }}
            >The event certificate is not yet ready</p> */}
            <div className="custom-modal-logo">
              <img src={IconLogo} alt="custom-logo" />
            </div>
          </div>
        }
        closeIcon={<CloseCircleFilled className="custom-modal-close" />}
      >
        <div className="library-claim-modal">
          <p style={{ textAlign: "center", fontSize: "18px" }}>
            The Digital Certificate of Participation is not ready yet for this
            event. It will be available on{" "}
            {convertToLocalTime(dataEventFire?.endDate, dataEventFire.timezone)
              .add(24, "hours")
              .format("MM/DD/YYYY")}
          </p>
          <p style={{ textAlign: "center", fontSize: "18px" }}>Thank you!</p>
        </div>
      </Modal>
      <Modal
        className={clsx("custom-modal")}
        wrapClassName={clsx("custom-modal-wrap")}
        centered
        onCancel={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setPopUp(false);
        }}
        visible={popUp}
        closable={true}
        footer={[]}
        width="500px"
        style={{ top: "100px" }}
        title={
          <div
            className="custom-modal-title"
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <div className="custom-modal-logo">
              <img src={IconLogo} alt="custom-logo" />
            </div>
          </div>
        }
        closeIcon={<CloseCircleFilled className="custom-modal-close" />}
      >
        <div className="library-claim-modal">
          <p style={{ textAlign: "center", fontSize: "18px" }}>
            This event is sold out.
          </p>
        </div>
      </Modal>
      <div style={{ position: "absolute", left: "-100%" }}>
        <div
          className="skill-cohort-certificate-page-wrapperr"
          id="certificate-panel"
          ref={certificateRef}
        >
          <div className="certificate">
            <div className="certificate-top">
              <div className="certificate-logo">
                <img src={ImgHHRLogo} alt="sidebar-logo" />
              </div>
              <h3 className="certificate-title">
                Hacking HR's Certificate of Participation To
              </h3>
              <h1 className="certificate-username">{`${userProfile?.firstName} ${userProfile?.lastName}`}</h1>
            </div>
            <div className="certificate-center">
              <h5 className="certificate-text1 organizer">
                For Successfully Participating in the Hacking HR Event:
              </h5>
              <h3 className="certificate-text2" style={{ textAlign: "center" }}>
                {dataEvent?.title}
              </h3>
              <h5 className="certificate-text1 organizer">
                Duration: {durationEvent} hours
              </h5>
            </div>
            <div className="certificate-bottom">
              <div className="certificate-bottom-sign">
                <h5 className="certificate-text1 date">{`${moment(
                  dataEvent?.startDate,
                  "YYYY-MM-DDTHH:mm:ss"
                ).format("ll")} - ${moment(
                  dataEvent?.endDate,
                  "YYYY-MM-DDTHH:mm:ss"
                ).format("ll")}`}</h5>
                <div className="certificate-divider" />
                <h5 className="certificate-text1">Date</h5>
              </div>
              <div className="certificate-bottom-image">
                <img src={ImgCertificateStamp} alt="certificate-img" />
              </div>
              <div className="certificate-bottom-sign">
                <div className="certificate-signature">
                  <img src={ImgSignature} alt="certificate-signature" />
                </div>
                <div className="certificate-divider" />
                <h5 className="certificate-text1 signature">
                  Founder at Hacking HR
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  updatedEvent: eventSelector(state).updatedEvent,
  allEventsUsersSketch: eventSelector(state).allEventsUsersSketch,
  allEvents: eventSelector(state).allEvents,
  isAuthenticated: authSelector(state).isAuthenticated,
  isMobile: envSelector(state).isMobile,
  userProfile: homeSelector(state).userProfile,
  redirectHome: homeSelector(state).redirectHome,
  ...councilEventSelector(state),
});

const mapDispatchToProps = {
  getEvent,
  addToMyEventList,
  getUser,
  getAllEvent,
  createControlCertificate,
  getAllCertificateType,
  ...councilEventActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicEventPage);
