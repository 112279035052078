import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Row, Col, Modal, Button } from "antd";
// import groupBy from "lodash/groupBy";
import moment from "moment";
import { connect } from "react-redux";
import { homeSelector } from "redux/selectors/homeSelector";
import { searchUser } from "redux/actions/home-actions";
import { convertToLocalTime } from "utils/format";
import TimeZoneList from "enum/TimezoneList";
import { categorySelector } from "redux/selectors/categorySelector";
import InvitPeople from "./InvitPeople";
import Followers from "./Followers";

import {
  // DateAvatar,
  EventCard,
  CustomButton,
  CustomInput,
  CustomSelect,
} from "components";
import { sendEmailInvite } from "redux/actions/event-actions";
import { NoEventCard } from "components";
// import Emitter from "services/emitter";
import {
  // EVENT_TYPES,
  //SETTINGS,
  CARD_TYPE,
  INTERNAL_LINKS,
  COUNTRIES,
  PROFILE_SETTINGS,
} from "enum";
import { envSelector } from "redux/selectors/envSelector";

import {
  CloseCircleFilled,
  CaretRightOutlined,
  CaretLeftOutlined,
} from "@ant-design/icons";

import "./style.scss";

// const monthStr = [
//   "JAN",
//   "FEB",
//   "MAR",
//   "APR",
//   "MAY",
//   "JUN",
//   "JUL",
//   "AUG",
//   "SEP",
//   "OCT",
//   "NOV",
//   "DEC",
// ];

// const DataFormat = SETTINGS.DATE_FORMAT;

const EventList = ({
  data,
  isMobile,
  onAttend,
  showFilter,
  onClick,
  edit,
  type,
  onMenuClick,
  onAddEvent,
  onConfirmAttendance,
  onConfirmCredit,
  userProfile,
  limit = "all",
  buttomEdit,
  setActiveMessages,
  boxes,
  allEventsUsersSketch,
  setLoading,
  searchUsers,
  searchUser,
  allCategories,
  sendEmailInvite,
  ...rest
}) => {
  const selectInvit = useRef();

  const [selectedEvent, setSelectedEvent] = useState({});
  const [bulInviteModal, setBulInviteModal] = useState(false);
  const [bulSelectOptionResponsiveInvite, setBulSelectOptionResponsiveInvite] =
    useState(false);
  const [bulNext, setBulNext] = useState(false);
  const [idPeopleSendInvitation, setIdPeopleSendInvitation] = useState([]);
  const [dataSelected, setDataSelected] = useState({
    location: [],
    recentJobLevel: [],
    sizeOfOrganization: [],
    topicsOfInterest: [],
    search: "",
  });

  let num = -1;

  const onEventChanged = (event, going, img) => {
    event.going = going;
    onAttend(event, img);
  };

  const getRandomNumber = () => Math.floor(Math.random() * 1000);

  const searchTimeZone = (timezone) => {
    let currentTimezone = TimeZoneList.find((item) => item.value === timezone);

    if (currentTimezone) {
      currentTimezone = currentTimezone.utc[0];
    } else {
      currentTimezone = timezone;
    }

    return currentTimezone;
  };

  const handleInvite = () => {
    let values;

    values = {
      linkEvent: `${process.env.REACT_APP_DOMAIN_URL}/${selectedEvent?.id}`,
      to: idPeopleSendInvitation.map((data) => data.id),
      invitePeople: true,
      replyToEmail: userProfile?.email,
      nameEvent: selectedEvent?.title,
      idChannel: selectedEvent?.id,
    };

    sendEmailInvite(values, () => {
      setIdPeopleSendInvitation([]);
      setBulInviteModal(false);
      setDataSelected({
        location: [],
        recentJobLevel: [],
        sizeOfOrganization: [],
        topicsOfInterest: [],
        search: "",
      });
    });
  };

  const newFilterEventsForDays = (data) => {
    let newData = data.filter((e) => {
      let dateFilter =
        e.channel !== null
          ? moment(e?.endDate, "YYYY-MM-DD hh:mm a")?.format("YYYYMMDDHHmm")
          : moment(
              e?.startAndEndTimes[e.startAndEndTimes.length - 1]?.endTime,
              "YYYY-MM-DD hh:mm a"
            )?.format("YYYYMMDDHHmm");

      let dateNow = moment(moment.utc(), "YYYY-MM-DD hh:mm a")
        .tz(searchTimeZone(e?.timezone))
        ?.format("YYYYMMDDHHmm");

      return Number(dateNow) <= Number(dateFilter);
    });

    let arrayFixed = [];
    let num = -1;
    let titlesDateReady;

    for (let i = 0; i < newData.length; i++) {
      let dateNow = newData[i].startDate;
      let timezone = newData[i].timeZone;
      if (
        titlesDateReady !==
        convertToLocalTime(dateNow, timezone).format().substring(0, 10)
      ) {
        titlesDateReady = convertToLocalTime(dateNow, timezone)
          .format()
          .substring(0, 10);
        num++;
        if (!arrayFixed[num]) {
          arrayFixed.push([]);
        }
        arrayFixed[num].push(newData[i]);
      } else {
        arrayFixed[num].push(newData[i]);
      }
    }

    return arrayFixed;
  };

  useEffect(() => {
    let where = {};
    let clock2;

    if (dataSelected.location[0] !== undefined) {
      where = {
        ...where,
        location: dataSelected.location,
      };
    }
    if (dataSelected.recentJobLevel[0] !== undefined) {
      where = {
        ...where,
        recentJobLevel: dataSelected.recentJobLevel,
      };
    }
    if (dataSelected.sizeOfOrganization[0] !== undefined) {
      where = {
        ...where,
        sizeOfOrganization: dataSelected.sizeOfOrganization,
      };
    }
    if (dataSelected.topicsOfInterest[0] !== undefined) {
      where = {
        ...where,
        topicsOfInterest: dataSelected.topicsOfInterest,
      };
    }
    if (dataSelected.search !== "") {
      where = {
        ...where,
        search: dataSelected.search,
      };
    }

    if (Object.entries(where)[0] !== undefined) {
      clearTimeout(clock2);
      clock2 = setTimeout(() => {
        searchUser({
          channel: true,
          ...where,
        });
      }, 250);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSelected]);

  return (
    <>
      <div
        className={
          window.location.pathname.substring(0, 15) === INTERNAL_LINKS.EVENTS
            ? "grow-list-event"
            : "grow-list"
        }
      >
        {data && data.length === 0 && type !== CARD_TYPE.EDIT && (
          <NoEventCard type="GrowGroups" />
        )}
        {edit && type === CARD_TYPE.EDIT && (
          <CustomButton
            text="Add Events"
            htmlType="submit"
            size="sm"
            type="primary"
            className={buttomEdit === "home" ? "buttomAddRR" : "buttomAddR"}
            style={buttomEdit === "home" ? { left: "110px" } : {}}
            onClick={() => onAddEvent()}
          />
        )}
        {newFilterEventsForDays(data)?.map((eventArray, index) => {
          return (
            <React.Fragment key={index}>
              {/* <div style={{width:'100%',height:'auto',display:'flex',justifyContent:'center'}}>
                <h3
                  style={{paddingTop:'10px',paddingBottom:'10px',width:'100%',maxWidth:'1050px'}}
                >{moment(eventArray[0].startDate, "YYYY-MM-DD hh:mm a")?.format('MMMM Do YYYY')}</h3>
              </div> */}
              {eventArray.map((event, index) => {
                num++;
                return limit > num || limit === "all" ? (
                  <div
                    className="grow-list-batch"
                    key={`${index}-${getRandomNumber()}`}
                  >
                    <Row gutter={[0, 36]}>
                      <Col
                        key={`col-${index}-${getRandomNumber()}`}
                        span={24}
                        className="grow-list-item"
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <EventCard
                          edit={edit}
                          data={event}
                          setBulInviteModal={(bul) => {
                            setSelectedEvent(event);
                            setBulInviteModal(bul);
                          }}
                          userProfile={userProfile}
                          onAttend={(going, img) =>
                            onEventChanged(event, going, img)
                          }
                          onClick={onClick}
                          boxes={boxes}
                          isGrowGroups={true}
                          onMenuClick={(menu) => onMenuClick(menu, event)}
                          onConfirmAttendance={onConfirmAttendance}
                          onConfirmCredit={onConfirmCredit}
                          setActiveMessages={setActiveMessages}
                          isMobile={isMobile}
                          usersData={allEventsUsersSketch[event.id]}
                          setLoading={setLoading}
                          esIncoming={true}
                        />
                      </Col>
                    </Row>
                  </div>
                ) : (
                  // ))}
                  <div
                    key={`${index}-${getRandomNumber()}`}
                    style={{ display: "none" }}
                  ></div>
                );
              })}
            </React.Fragment>
          );
        })}
      </div>
      <Modal
        visible={bulInviteModal}
        footer={[
          <Button key="back" onClick={handleInvite}>
            Invite
          </Button>,
        ]}
        title={
          <div style={{ display: "flex", flexDirection: "row" }}>
            <p>Invite</p>
          </div>
        }
        width={900}
        style={{ top: "40px" }}
        closable={true}
        onCancel={() => setBulInviteModal(false)}
        closeIcon={
          <CloseCircleFilled className="custom-modal-close" id="close-email" />
        }
      >
        <div className="container-invite-modal">
          <div className="container-search-user">
            <CustomInput
              style={{
                width: "100%",
                border: "none",
                fontSize: "18px",
                margin: "0px",
              }}
              onKeyUp={(e) => {
                setDataSelected({
                  ...dataSelected,
                  search: e.target.value,
                });
              }}
              placeholder="Search by name / email"
            ></CustomInput>
            <CustomSelect
              className="border"
              showSearch
              options={COUNTRIES}
              value={dataSelected.location}
              optionFilterProp="location"
              onChange={(value) => {
                setDataSelected({
                  ...dataSelected,
                  location: value,
                });
              }}
              change={true}
              mode="multiple"
              dropdownStyle={{ width: "95%", minWidth: "none" }}
              style={{ width: "100%", minWidth: "none", marginTop: "10px" }}
              placeholder="Location"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
            <CustomSelect
              className="border"
              showSearch
              options={PROFILE_SETTINGS.JOB_LEVELS}
              value={dataSelected.recentJobLevel}
              optionFilterProp="location"
              onChange={(value) => {
                setDataSelected({
                  ...dataSelected,
                  recentJobLevel: value,
                });
              }}
              change={true}
              mode="multiple"
              dropdownStyle={{ width: "95%", minWidth: "none" }}
              style={{ width: "100%", minWidth: "none", marginTop: "10px" }}
              placeholder="Level"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
            <CustomSelect
              className="border"
              showSearch
              options={PROFILE_SETTINGS.ORG_SIZES}
              value={dataSelected.sizeOfOrganization}
              optionFilterProp="location"
              onChange={(value) => {
                setTimeout(() => {
                  selectInvit.current.scroll(
                    selectInvit.current.clientWidth,
                    0
                  );
                  setBulNext(true);
                }, 100);
                setDataSelected({
                  ...dataSelected,
                  sizeOfOrganization: value,
                });
              }}
              change={true}
              mode="multiple"
              dropdownStyle={{ width: "95%", minWidth: "none" }}
              style={{ width: "100%", minWidth: "none", marginTop: "10px" }}
              placeholder="Company size"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
            <CustomSelect
              className="border"
              showSearch
              options={allCategories}
              value={dataSelected.topicsOfInterest}
              optionFilterProp="location"
              onChange={(value) => {
                setTimeout(() => {
                  selectInvit.current.scroll(
                    selectInvit.current.clientWidth,
                    0
                  );
                  setBulNext(true);
                }, 100);
                setDataSelected({
                  ...dataSelected,
                  topicsOfInterest: value,
                });
              }}
              change={true}
              mode="multiple"
              dropdownStyle={{ width: "100%", minWidth: "none" }}
              style={{ width: "100%", minWidth: "none", marginTop: "10px" }}
              placeholder="Topic interest"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
            <div className="space-selected border">
              <div
                className={
                  !bulSelectOptionResponsiveInvite
                    ? "container-option-tab-select selectOption"
                    : "container-option-tab-select"
                }
                onClick={() => {
                  setBulSelectOptionResponsiveInvite(
                    !bulSelectOptionResponsiveInvite
                  );
                }}
              >
                Users found
              </div>
              <div
                className={
                  bulSelectOptionResponsiveInvite
                    ? "container-option-tab-select selectOption"
                    : "container-option-tab-select"
                }
                onClick={() => {
                  setBulSelectOptionResponsiveInvite(
                    !bulSelectOptionResponsiveInvite
                  );
                }}
              >
                Users selected
              </div>
            </div>
          </div>
          <div className="container-distribution-invite-modal">
            <div className="box-left-invite-modal">
              {bulNext && (
                <div
                  className="before-arrow"
                  onClick={() => {
                    setBulNext(false);
                    selectInvit.current.scroll(0, 0);
                  }}
                >
                  <CaretLeftOutlined />
                </div>
              )}
              {!bulNext && (
                <div
                  className="next-arrow"
                  onClick={() => {
                    setBulNext(true);
                    selectInvit.current.scroll(
                      selectInvit.current.clientWidth,
                      0
                    );
                  }}
                >
                  <CaretRightOutlined />
                </div>
              )}
              <div
                className="container-selects-topics-invite-modal"
                ref={selectInvit}
              >
                <CustomSelect
                  className="border"
                  showSearch
                  options={COUNTRIES}
                  value={dataSelected.location}
                  optionFilterProp="location"
                  onChange={(value) => {
                    setDataSelected({
                      ...dataSelected,
                      location: value,
                    });
                  }}
                  change={true}
                  mode="multiple"
                  dropdownStyle={{ width: "250px", minWidth: "250px" }}
                  style={{ marginLeft: "15px", transform: "scale(0.9)" }}
                  placeholder="Location"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                />
                <CustomSelect
                  className="border"
                  showSearch
                  options={PROFILE_SETTINGS.JOB_LEVELS}
                  value={dataSelected.recentJobLevel}
                  optionFilterProp="location"
                  onChange={(value) => {
                    setDataSelected({
                      ...dataSelected,
                      recentJobLevel: value,
                    });
                  }}
                  change={true}
                  mode="multiple"
                  dropdownStyle={{ width: "150px", minWidth: "150px" }}
                  style={{ marginLeft: "15px", transform: "scale(0.9)" }}
                  placeholder="Level"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                />
                <CustomSelect
                  className="border"
                  showSearch
                  options={PROFILE_SETTINGS.ORG_SIZES}
                  value={dataSelected.sizeOfOrganization}
                  optionFilterProp="location"
                  onChange={(value) => {
                    setTimeout(() => {
                      selectInvit.current.scroll(
                        selectInvit.current.clientWidth,
                        0
                      );
                      setBulNext(true);
                    }, 100);
                    setDataSelected({
                      ...dataSelected,
                      sizeOfOrganization: value,
                    });
                  }}
                  change={true}
                  mode="multiple"
                  dropdownStyle={{ width: "150px", minWidth: "150px" }}
                  style={{ marginLeft: "15px", transform: "scale(0.9)" }}
                  placeholder="Company size"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                />
                <CustomSelect
                  className="border"
                  showSearch
                  options={allCategories}
                  value={dataSelected.topicsOfInterest}
                  optionFilterProp="location"
                  onChange={(value) => {
                    setTimeout(() => {
                      selectInvit.current.scroll(
                        selectInvit.current.clientWidth,
                        0
                      );
                      setBulNext(true);
                    }, 100);
                    setDataSelected({
                      ...dataSelected,
                      topicsOfInterest: value,
                    });
                  }}
                  change={true}
                  mode="multiple"
                  dropdownStyle={{ width: "300px", minWidth: "300px" }}
                  style={{ marginLeft: "15px", transform: "scale(0.9)" }}
                  placeholder="Topic interest"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                />
              </div>
              <div className="container-scroll">
                <div className="ajust-contain-channel">
                  {searchUsers !== undefined ? (
                    searchUsers?.map((user, index) => (
                      <div key={index} style={{ width: "100%" }}>
                        <InvitPeople
                          followers={user}
                          index={index}
                          arrayId={idPeopleSendInvitation}
                          setArrayId={setIdPeopleSendInvitation}
                          userFollowed={selectedEvent.users}
                        />
                      </div>
                    ))
                  ) : (
                    <div style={{ display: "none" }}></div>
                  )}
                </div>
              </div>
            </div>
            <div
              className={`box-right-invite-modal ${
                !bulSelectOptionResponsiveInvite
                  ? "hidden-reflex-select-tab"
                  : ""
              }`}
            >
              <div className="container-reset">
                <p>{idPeopleSendInvitation.length} selected</p>
                <p
                  style={{ fontWeight: "bold", cursor: "pointer" }}
                  onClick={() => {
                    setIdPeopleSendInvitation([]);
                  }}
                >
                  Unselect all
                </p>
              </div>
              <div className="container-selecteds">
                {idPeopleSendInvitation?.map((user, index) => (
                  <div key={index}>
                    <Followers followers={user} index={index} type={""} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

EventList.propTypes = {
  data: PropTypes.array,
  edit: PropTypes.bool,
  type: PropTypes.string,
  onAttend: PropTypes.func,
  onClick: PropTypes.func,
  showFilter: PropTypes.func,
  onMenuClick: PropTypes.func,
  onAddEvent: PropTypes.func,
  onConfirmAttendance: PropTypes.func,
  onConfirmCredit: PropTypes.func,
  userProfile: PropTypes.object,
  boxes: PropTypes.object,
  setLoading: PropTypes.func,
};

EventList.defaultProps = {
  data: [],
  edit: false,
  type: CARD_TYPE.VIEW,
  userProfile: {},
  onAttend: () => {},
  onClick: () => {},
  showFilter: () => {},
  onMenuClick: () => {},
  onAddEvent: () => {},
  onConfirmAttendance: () => {},
  onConfirmCredit: () => {},
  setLoading: () => {},
  boxes: {},
};

const mapStateToProps = (state) => ({
  isMobile: envSelector(state).isMobile,
  allCategories: categorySelector(state).categories,
  searchUsers: homeSelector(state).searchedUsers,
});

const mapDispatchToProps = {
  searchUser,
  sendEmailInvite,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventList);
