import React, { useEffect, useState, useRef } from "react";
import "./style.scss";
import { connect } from "react-redux";
import { homeSelector } from "redux/selectors/homeSelector";
import { getUser } from "redux/actions/home-actions";
import { actions as courseActions } from "redux/actions/course-actions";
import { actions as crashCourseActions } from "redux/actions/crash-course-actions";
import { actions as crashCourseProgressActions } from "redux/actions/crash-course-user-progress-actions";
import IconLogo from "images/logo-sidebar.svg";

import { courseSelector } from "redux/selectors/courseSelector";
import CertificateCrashCourse from "pages/VerifyCertificate/CertificateCrashCourse";
import { 
  notification, Modal, 
  Radio, Collapse } from "antd";
import { ModalCompleteYourProfile } from "components";
import { CustomButton, 
} from "components";

import CollapseDescription from "./CollapseDescription";
import HeaderCollapseMap from "./HeaderCollapseMap";

import {
  StepBackwardOutlined,
  StepForwardOutlined,
  CheckOutlined,
  CloseOutlined
} from "@ant-design/icons";
import { crashCourseSelector } from "redux/selectors/crashCourseSelector";


const { Panel } = Collapse;

const LeadershipProgramPage = ({
  userProfile,
  match,
  updateCrashCourseUserProgress,
  crashCourse,
  getCrashCourseBySlug,
  createCrashCourseUserProgress,
  getCrashCourseUserProgress,
  crashCourseProgress
}) => {
  const [bulCompleteProfile, setBulCompleteProfile] = useState(false);
  const [countSlides, setCountSlides] = useState(0);
  // const [countSlidesTest, setCountSlidesTest] = useState(0);
  const [relationSideIndex, setRelationSideIndex] = useState({})
  const [relationSideIndex2, setRelationSideIndex2] = useState({})
  const [conjuntSelected, setConjuntSelected] = useState([])
  const [relationSlides, setRelationSlides] = useState({})
  const [relationCorrectResponses, setRelationCorrectResponses] = useState({})
  const [responseTests, setResponsesTests] = useState({})
  const [slideSelect, setSlideSelect] = useState({})
  const [pageNumber, setPageNumber] = useState(null);
  const [pageNumber2, setPageNumber2] = useState(null);
  const [modal, setModal] = useState(false)
  const [sectionActive, seSectionActive] = useState([])
  const timeNextStep = useRef({});
  const clockNextStep = useRef(null)

  // const [showModal, setShowModal] = useState(false);

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     setShowModal(true);
  //     event.preventDefault();
  //     event.returnValue = '';
  //     return '';
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  // const handleSaveChanges = () => {
  //   // Lógica para guardar cambios
  //   setShowModal(false);
  // };

  useEffect(() => {
    if (!crashCourse.id && match.params.id) {
      getCrashCourseBySlug(match.params.id);
    }
  }, [match.params, crashCourse]);

  useEffect(() => {
    if (
      crashCourse.CrashCourseSlides &&
      crashCourse.CrashCourseSlides.length > 0
    ) {
      setCountSlides(crashCourse.CrashCourseSlides.length);
      // setCountSlidesTest(crashCourse.CrashCourseSlides.filter(e => e.typeshow === "assessment").length)
    }
  }, [crashCourse]);

  useEffect(() => {
    if (crashCourse.id) {
      getCrashCourseUserProgress(crashCourse.id,(data) => {
        if(data.pagesSave){
          setPageNumber(Number(data.pagesSave === 'completed' ? Object.keys(relationSlides).length : Number(data.pagesSave)))
        }
        setResponsesTests(data.testsAnswers)
      });
    }
  }, [crashCourse,relationSlides]);

  const startCrashCourse = () => {
    if (userProfile.memberShip !== "premium") {
      return notification.error({
        message: "You must be a premium user.",
      });
    }

    setPageNumber(1);

    setTimeout(() => {
      if (!(crashCourseProgress || {}).id && (crashCourse || {}).id) {
        createCrashCourseUserProgress({
          CrashCourseId: (crashCourse || {}).id,
          timeProgress: 0,
          testsCompleted: 0,
        })
      }
    }, 500)
  };

  useEffect(() => {
    if(crashCourse?.CrashCourseSlides && crashCourse.CrashCourseSlides.length > 0){
      let positionSlides = {};
      let positionSection = {}
      let positionSection2 = {}
      let responseD = {};
      let numSection = 0;
      let datas = crashCourse.CrashCourseSlides;

      let foxed = datas.reduce((acc, obj) => {
        acc[obj.id] = obj;
        return acc;
      }, {});
  
      let datal = (((crashCourse || {}).order || [])[0] ? (crashCourse || {}).order || [] : []).map(e => {
        return foxed[e];
      })

      for(let i = 1; i < datal.length+1; i++){
        if(datal[i-1].typeshow === "section"){
          numSection++;
          positionSection = {...positionSection, [numSection]: [datal[i-1]]}
          positionSection2 = {...positionSection2, [numSection]: {start:i,end:i}}
        }else if(numSection !== 0){
          positionSection[numSection] = [...positionSection[numSection], datal[i-1]];
          (positionSection2[numSection] || {}).end = i;
        }

        positionSlides = {
          ...positionSlides,
          [i]: {...datal[i-1], type: datal[i-1].typeshow === "assessment" ? "test" 
            : datal[i-1].typeshow === "slide" ? "info" : "section"}
        }
        if(datal.length === i){
          positionSlides[i+1] = {...datal[i-1],id:"completed",type: "completed"}
          numSection++;
          positionSection = {...positionSection, [numSection]: [{...datal[i-1],id:"completed",type:"completed"}]}
          positionSection2 = {...positionSection2, [numSection]: {start:i+1,end:i+1}}
        }
        if((datal[i-1] || {}).id && datal[i-1].typeshow === "assessment"){
          let assesments
          for(let y = 0; y < (datal[i-1]?.assessmentActivities || []).length; y++){
            assesments = {...assesments,[(datal[i-1]?.assessmentActivities || [])[y].uuid]: (datal[i-1]?.assessmentActivities || [])[y]?.['correct-option']}
          }

          responseD = {
            ...responseD,
            [(datal[i-1] || {}).id]: assesments
          }
        }
      }

      setRelationSideIndex(positionSection)
      setRelationSideIndex2(positionSection2)
      setRelationCorrectResponses(responseD)
      setRelationSlides(positionSlides)
    }
  },[crashCourse])

  useEffect(() => {
    if((crashCourseProgress || {}).id && JSON.stringify(responseTests) !== '{}' && JSON.stringify(crashCourseProgress.testsAnswers || '{}') !== JSON.stringify(responseTests || '{}')){
      updateCrashCourseUserProgress({
        testsAnswers: responseTests,
        id: crashCourseProgress.id
      })
    }  
  },[responseTests,crashCourseProgress])

  useEffect(() => {
    if((crashCourseProgress || {}).id 
      && !!pageNumber && Number(crashCourseProgress.pagesSave) !== Number(pageNumber) 
      && crashCourseProgress.pagesSave !== 'completed'){
        if((crashCourse?.CrashCourseSlides?.length + 1) === Number(pageNumber) && relationSlides[pageNumber]?.type === 'completed'){
          updateCrashCourseUserProgress({
            pagesSave: 'completed' ,
            id: crashCourseProgress.id
          })
        }else{
          updateCrashCourseUserProgress({
            pagesSave: pageNumber ,
            id: crashCourseProgress.id
          })
        }
    }
  },[pageNumber,crashCourseProgress])

  // useEffect(() => {
  //   if((crashCourseProgress || {}).id ){
  //     if(!crashCourseProgress.pagesVisited.includes(String(pageNumber))){
  //       updateCrashCourseUserProgress({
  //         pagesVisited: [...new Set([...crashCourseProgress.pagesVisited, String(pageNumber)])],
  //         id: crashCourseProgress.id
  //       }) 
  //     } 
  //   }
  // },[pageNumber,crashCourseProgress])

  const objetosIguales = (obj1, obj2) => {
    const keys1 = Object.keys(obj1 || {});
    const keys2 = Object.keys(obj2 || {});
    const totalKeys = Math.max(keys1.length, keys2.length);

    if (totalKeys === 0) return true;

    const matchingKeys = keys1.filter(key => obj2[key] === obj1[key]).length;
    return (matchingKeys / totalKeys) >= 1;
  }

  const descriptionSlide = (data) => (
    <CollapseDescription
      title={data?.title}
      content={
        <div
          className="container-will-learn-collapsee"
          style={{ height: "450px", maxHeight: "450px", overflowX: "auto" }}
          dangerouslySetInnerHTML={{__html: data?.description }}
        />
      }
    />
  )

  const testSlide = (data) => (
    <CollapseDescription
      title={data?.title}
      content={
        <div className="container-will-learn-collapsee" style={{ height: "450px", maxHeight: "450px", overflowX: "auto", width: "100%", padding: "20px",}} >
          {data?.assessmentActivities?.map((item,index) => {
            return (
              <div key={index}>
                <h3>{item.question}</h3>
                <Radio.Group 
                  size="large" 
                  value={responseTests?.[data?.id]?.[item?.uuid]} 
                  onChange={(e) => {
                  setResponsesTests({
                    ...responseTests,
                    [data?.id]:{
                      ...(responseTests?.[data?.id] || {}),
                      [item?.uuid]: e.target.value,
                      memory: {
                        ...((responseTests?.[data?.id] || {})?.memory || {}),
                        [item?.uuid]: [...new Set([
                          // ...(((responseTests?.[data?.id] || {})?.memory || [])?.[item?.uuid] || []),
                          e.target.value
                        ])]
                      }
                    }
                  })
                }}>
                  {item.options.map((option,index2) => {
                    let bulText = ((relationCorrectResponses?.[data?.id] || {})?.[item?.uuid] || '') === option;
                    let bulSelected = (((responseTests?.[data?.id] || {})?.memory || [])?.[item?.uuid] || []).includes(option);
                    return (
                      <div key={option} style={{ margin: "8px 0", position:'relative', display:'flex',alignItems:'center' }}>
                        <label for={`radio-${item?.uuid}-${index2}`} style={{position:'absolute',top:'3px', width:'17px',height:'17px',display:'flex',left:'-20px',justifyContent:'center',alignItems:'center', background:'green',borderRadius:'50% 50%',zIndex:'10',opacity:`${(bulSelected && bulText) ? 1 : 0}`,transition:'all 0.4s ease-in-out'}}>
                          <CheckOutlined style={{color:"white",fontSize:'12px'}}/>
                        </label>
                        <label for={`radio-${item?.uuid}-${index2}`} style={{position:'absolute',top:'3px', width:'17px',height:'17px',display:'flex',left:'-20px',justifyContent:'center',alignItems:'center', background:'#fe5621',borderRadius:'50% 50%',zIndex:'10',opacity:`${(bulSelected && !bulText) ? 1 : 0}`,transition:'all 0.4s ease-in-out'}}>
                          <CloseOutlined style={{color:"white",fontSize:'12px'}}/>
                        </label>
                        <Radio id={`radio-${item?.uuid}-${index2}`} value={option}>{option}</Radio>
                      </div>
                    )
                  })}
                </Radio.Group>
              </div>
            );
          })}
        </div>
      }
    />
  )

  const textCustom = () => {
    if(slideSelect[pageNumber2]){
      return (slideSelect[pageNumber2 + 1]?.type === 'info' || slideSelect[pageNumber2 + 1]?.type === 'section')
        ? 'NEXT' 
        : slideSelect[pageNumber2 + 1]?.type === 'test'
          ? 'TAKE TEST'
          : 'COMPLETE'
    }else{
      return (relationSlides[pageNumber + 1]?.type === 'info' || relationSlides[pageNumber + 1]?.type === 'section')
        ? 'NEXT' 
        : relationSlides[pageNumber + 1]?.type === 'test'
          ? `TAKE TEST`
          : 'COMPLETE'
    }
  }

  useEffect(() => {
    let keys = Object.keys(relationSideIndex);
    (keys || []).map((e) => {
      if(Number(pageNumber) >= Number(relationSideIndex2?.[e]?.start) && Number(pageNumber) <= Number(relationSideIndex2?.[e]?.end)){
        if(conjuntSelected !== relationSideIndex?.[e]?.[0]?.id){
          let newData = [...new Set([...sectionActive, relationSideIndex?.[e]?.[0]?.id])]
          seSectionActive(newData.filter(ar => ar !== conjuntSelected))
          setConjuntSelected(relationSideIndex?.[e]?.[0]?.id)
        }
      }
    });
  },[pageNumber,relationSideIndex])

  return (
    <div className="container-certificates" style={{paddingLeft:'20px',paddingRight:'20px'}}>
      <div className="container-buttons-certificate">
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: "20px" }}>
          <div className="title-list-certificates" style={{ minWidth: "300px" }}>
            {crashCourse.title}
          </div>
        </div>
      </div>
      <div className="container-firstsegment-certificate" style={{gap:'10px'}}>
        <div className="container-side-index">
            { Object.keys(relationSideIndex)?.map((e) => {
              return  (
                <Collapse
                  bordered={false}
                  expandIcon={() => (<></>)}
                  activeKey={sectionActive.includes(relationSideIndex?.[e]?.[0]?.id) ? [1] : []}
                  className="site-collapse-custom-collapse"
                >
                  <Panel
                    header={<HeaderCollapseMap 
                      pagesVisitedMap={crashCourseProgress.pagesVisited?.map(e => String(e) || {includes: () => {}}).includes(String(relationSideIndex2?.[e]?.start))} 
                      inPage={(relationSlides[pageNumber]?.id === relationSideIndex?.[e]?.[0]?.id)}
                      data={relationSideIndex?.[e]?.[0]} 
                      onClick={() => {
                        let pagesNumber = Number(relationSideIndex2?.[e]?.start)
                        if(relationSideIndex?.[e]?.[0]?.type !== 'completed'){
                          let dataF = {...relationSideIndex}
                          let data = sectionActive.includes(dataF?.[e]?.[0]?.id) 
                          ? sectionActive.filter(as => {
                            return Number(as) !== Number(dataF?.[e]?.[0]?.id);
                          }) : [...sectionActive, dataF?.[e]?.[0]?.id];
                          seSectionActive([...new Set(data)])
                        }
                        if(crashCourseProgress?.pagesVisited?.map(e => String(e) || {includes: () => {}}).includes(String(pagesNumber))){
                          setPageNumber(pagesNumber)
                        }
                      }
                    } />}
                    key={1}
                    className="site-collapse-custom-panel"
                    style={Number(pageNumber) >= Number(relationSideIndex2?.[e]?.start) && Number(pageNumber) <= Number(relationSideIndex2?.[e]?.end)
                      ? {borderRight: '#fe5621 2px solid', background: "white", transition: 'all 1s ease-in-out '} : { background: "white", transition: 'all 1s ease-in-out ', borderRight: 'transparent 2px solid' }
                    }
                  >
                    {relationSideIndex?.[e]?.map((a,index) => {
                      let pagesNumber = Number(relationSideIndex2?.[e]?.start) + Number(index);
                      let pagesVisitedMap = crashCourseProgress?.pagesVisited?.map(e => String(e) || {includes: () => {}}).includes(String(pagesNumber))
                      return (<p
                        className={"parraf-collapse-sitemap"} 
                        style={index === 0 ? {opacity:'0',height:'0px',padding:'0px',margin:'0px'} : {opacity:'1'}} 
                        key={index}
                        onClick={() => {
                          if(pagesVisitedMap){
                            setPageNumber(pagesNumber)
                          }else{
                            notification.error({
                              message: "You can't jump to this section yet. Please complete the previous sections before. Thank you",
                            });
                          }
                        }}
                      >
                        {<div className="check-map" style={pagesVisitedMap 
                          ? {opacity:'1'} : {opacity:'0'}
                        }><CheckOutlined style={{color:"white",fontSize:'8px'}}/></div>}
                        {index === 0 ? 'Index' : a?.title}
                        <div style={(relationSlides[pageNumber]?.id === a?.id && !pagesVisitedMap) 
                          ? {opacity:'1'} : {opacity:'0'}
                        } className="circul-map"></div>
                      </p>
                    )})}
                  </Panel>
                </Collapse>
              )
          })}
          
        </div>
        <div className="container-left-certificate">
          {pageNumber === null && (
            <>
              <CollapseDescription
                title="Description"
                widthTitle="360px"
                content={
                  <div
                    className="container-will-learn-collapsee"
                    dangerouslySetInnerHTML={{
                      __html: crashCourse?.description,
                    }}
                  />
                }
              />
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <CustomButton
                  className="button-start-now"
                  style={{ background: "#1A2842", width: "max-content", color: "white", minWidth: "180px", textAlign: "center", marginRight: '20px' }}
                  text={(crashCourseProgress || {}).id ? "NEXT" : "START NOW"}
                  onClick={() => {
                    startCrashCourse()
                  }}
                />
              </div>
              <CollapseDescription
                title="What You Will Learn"
                widthTitle="360px"
                content={
                  <div className="container-will-learn-collapse">
                    {(crashCourse?.youWillLearn?.length > 0
                      ? crashCourse?.youWillLearn
                      : []
                    )?.map((e, index) => {
                      return (
                        <div
                          key={index}
                          className="container-will-learn-collapse"
                        >
                          <p>{e}</p>
                        </div>
                      );
                    })}
                  </div>
                }
              />
            </>
          )}
          {(relationSlides && pageNumber !== null) && <>
            {(relationSlides[pageNumber]?.type === 'info' || relationSlides[pageNumber]?.type === 'section') && descriptionSlide(relationSlides[pageNumber])}
            {(relationSlides[pageNumber]?.type === 'test') && testSlide(relationSlides[pageNumber])}
            {(relationSlides[pageNumber]?.type === 'completed') && (<>
              <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column', width:'100%',height:'auto'}}>
                <CertificateCrashCourse
                  idCrash={crashCourse?.id}
                  onlyCertificate={true}
                  slug={crashCourse?.slug}
                />
              </div>
            </>)}
          <React.Fragment>
            <div style={{ display: "flex", justifyContent: "center", marginTop:'20px' }}>
              <div style={{ display: "flex", gap: "15px" }}>
                <CustomButton
                  className="button-start-now"
                  style={{ background: "#F05C27", width: "max-content", color: "white", minWidth: "200px", textAlign: "center", display: "flex", alignItems: "center", gap: "5px" }}
                  icon={<StepBackwardOutlined />}
                  text="PREVIOUS"
                  onClick={() => {
                    if(slideSelect[pageNumber2]){
                      if(pageNumber2 === 0){
                        setPageNumber2(null)
                        setSlideSelect({})
                      }else{
                        setPageNumber2((prev) => --prev)
                      }
                    }else{
                      setPageNumber((prev) => {
                        if (prev === 1) {
                          return null;
                        } else {
                          return --prev;
                        }
                      });
                    }
                    
                  }}
                />
                {(relationSlides[pageNumber]?.type !== 'completed') && <CustomButton
                  className="button-start-now"
                  style={{ background: "#F05C27", width: "max-content", color: "white", minWidth: "180px", textAlign: "center", display: "flex", alignItems: "center", gap: "5px", flexDirection: "row-reverse", justifyContent: "space-around" }}
                  icon={<StepForwardOutlined />}
                  text={textCustom()}
                  onClick={() => {
                    let relationSP = relationSlides?.[pageNumber];
                    let rt = {...(responseTests || [])[relationSP?.id]};
                    delete rt.memory;
                    if(slideSelect[pageNumber2]){
                      if(pageNumber2 === 1){
                        setPageNumber2(null)
                        setSlideSelect({})
                      }else{
                        setPageNumber2((prev) => ++prev)
                      }
                    }else{
                      if(relationSP?.type === 'test' 
                      && relationSP?.assessmentActivities?.length !== Object.keys(rt || {})?.length){
                        notification.error({
                          message: "Please select an option before proceeding.",
                        });
                        return
                      }
                      if(relationSP?.type === 'test' ){
                        if(!objetosIguales(rt,relationCorrectResponses[relationSP?.id])){
                          setModal(true)
                          return
                        }
                      }
                      let newArrayS = [...crashCourseProgress.pagesVisited, pageNumber];
                      newArrayS.map(e => {
                        timeNextStep.current[e] = '';
                      })
                      if(clockNextStep.current){clearTimeout(clockNextStep.current)}
                      clockNextStep.current = setTimeout(() => {
                        if(!crashCourseProgress.pagesVisited.includes(String(pageNumber))){
                          updateCrashCourseUserProgress({
                            pagesVisited: [...new Set(Object.keys(timeNextStep.current).map(e => String(e)))],
                            id: crashCourseProgress.id
                          }) 
                        } 
                        if(relationSlides?.[pageNumber+1]?.type === 'completed' && !crashCourseProgress.pagesVisited.includes(pageNumber + 1)){
                          newArrayS = [...Object.keys(timeNextStep.current), pageNumber+1];
                          updateCrashCourseUserProgress({
                            pagesVisited: [...new Set(newArrayS.map(e => String(e)))],
                            id: crashCourseProgress.id
                          }) 
                        }
                      })
                      setPageNumber((prev) => ++prev);
                    }
                  }}
                />}
                {relationSlides[pageNumber]?.type !== 'completed' && <CustomButton
                  className="button-start-now"
                  style={{ background: "transparent", border:'#F05C27 solid 2px', width: "max-content", color: "#F05C27", minWidth: "180px", textAlign: "center", display: "flex", alignItems: "center", gap: "5px", flexDirection: "row-reverse", justifyContent: "space-around" }}
                  text={`Page ${pageNumber} of ${countSlides}`}
                  onClick={() => {
                  }}
                />}
              </div>
            </div>
          </React.Fragment>
          </>}
        </div>
        {/* {showModal && (
          <div className="modal">
            <div className="modal-content">
              <p>Perderás los cambios no guardados. ¿Deseas guardar antes de salir?</p>
              <button onClick={handleSaveChanges}>Guardar</button>
              <button onClick={() => setShowModal(false)}>Cancelar</button>
            </div>
          </div>
        )} */}
        <Modal
          className="modal-login"
          width={"480px"}
          style={{ height: "auto", top: "50px" }}
          visible={modal}
          onCancel={() => setModal(false)}
          onOk={() => {
            // let relationSP = relationSlides?.[pageNumber];
            // let rt = {...responseTests[relationSP?.id]};
            // delete rt.memory;
            // if(relationSP?.type === 'test'
            // && !objetosIguales(rt,relationCorrectResponses[relationSP?.id])
            //   ){
            //   notification.error({
            //     message: "You need to get 100% of the test right to advance.",
            //   });
            //   return
            // }
            // setPageNumber((prev) => ++prev);
            setModal(false)
          }}
        >
          <div className="login-page-2">
            <div
              className="login-dialog"
              style={{ paddingTop: "40px", paddingBottom: "40px" }}
            >
              <div className="login-dialog-header" style={{ padding: "10px" }}>
                <h3 style={{ textAlign: "center" }}>
                  All your responses must be correct to advance to the next section. Please go back to the assessment and try again.
                </h3>
                <div
                  className="login-dialog-logo"
                  style={{ borderRadius: "50%", background: "white" }}
                >
                  <img src={IconLogo} alt="login-logo" />
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {bulCompleteProfile && (
          <div className="complete-profile">
            <ModalCompleteYourProfile
              userProfile={userProfile}
              get={getUser}
              setBulKnowRegister={() => {}}
              setBulCompleteProfile={setBulCompleteProfile}
              onClose={() => setBulCompleteProfile(false)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  allNewCources: courseSelector(state).allNewCources,
  courseSelected: courseSelector(state).courseSelected,
  userProfile: homeSelector(state).userProfile,
  certificateUsersInfo: courseSelector(state).certificateUsersInfo,

  crashCourse: crashCourseSelector(state).crashCourse,
  crashCourseProgress: crashCourseSelector(state).crashCourseProgress
});

const mapDispatchToProps = {
  ...courseActions,
  ...crashCourseActions,
  ...crashCourseProgressActions
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadershipProgramPage);
