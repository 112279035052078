import React, {useEffect, useRef} from "react";
import { INTERNAL_LINKS } from "enum";

const PostMessageComponent = ({checkSession}) => {

    const clock = useRef(null)
    const clock2 = useRef(null)
    // const [bulCase, setBulCase] = useState(false)
    let bulCase = false

    useEffect(() => {
      const messagePage = (ev) => {
        let caseRegister = (ev.origin === process.env.REACT_APP_PUBLIC_DOMAIN_WWW || ev.origin === process.env.REACT_APP_PUBLIC_DOMAIN) 
          ? 'community-education-seo' 
          : (ev.origin === process.env.REACT_APP_PUBLIC_DOMAIN_PEOPLE_SUMMIT) 
            ? 'the-people-summit'
            : ''
          
        if (ev.origin === process.env.REACT_APP_PUBLIC_DOMAIN || ev.origin === process.env.REACT_APP_PUBLIC_DOMAIN_PEOPLE_SUMMIT || ev.origin === process.env.REACT_APP_PUBLIC_DOMAIN_WWW) {
          if(ev.data.code && ev.data.email){
            const {code, email} = ev.data
            if(clock.current){
              clearTimeout(clock.current)
            }
            if(!clock.current){
              checkSession(email,code,(bul) => {
                if(bul){
                  localStorage.setItem("caseRegister", caseRegister)
                  if(window.location.pathname !== INTERNAL_LINKS.HOME){
                    window.location.href = INTERNAL_LINKS.HOME
                  }
                }
              })
            }
            let clockM = setTimeout(() => {
              clock.current = null ;  
            }, 800);
            clock.current = clockM 
          }else{
            if(!bulCase){
              if(clock2.current){
                clearTimeout(clock2.current)
              }
              clock2.current = setTimeout(() => {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                bulCase = true
                ev.source.postMessage({ ready: true }, ev.origin);
              }, 600);
            }
          }
        }
      }
    
      window.addEventListener('message', (e) => messagePage(e));

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (<></>)
};

export default PostMessageComponent;