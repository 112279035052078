import { createAction } from "redux-actions";

const CREATE_FILE_POST = "CREATE_FILE_POST";
const EDIT_FILE_POST = "EDIT_FILE_POST";
const REMOVE_FILE_POST = "REMOVE_FILE_POST";
const GET_ALL_FILE_POST = "GET_ALL_FILE_POST";
const DOWNLOAD_FILE_POST = "DOWNLOAD_FILE_POST";
const SET_ALL_FILE_POST = "SET_ALL_FILE_POST"
const SET_NEW_POST_FILE_TO_ARRAY = "SET_NEW_POST_FILE_TO_ARRAY";

export const constants = {
    CREATE_FILE_POST,
    EDIT_FILE_POST,
    REMOVE_FILE_POST,
    GET_ALL_FILE_POST,
    DOWNLOAD_FILE_POST,
    SET_ALL_FILE_POST,
    SET_NEW_POST_FILE_TO_ARRAY
};

// ------------------------------------
// Actions
// ------------------------------------

export const createFilePost = createAction(
    CREATE_FILE_POST,
    (body, callback) => ({ body, callback })
)
export const editFilePost = createAction(
    EDIT_FILE_POST,
    (body, callback) => ({ body, callback })
)
export const removeFilePost = createAction(
    REMOVE_FILE_POST,
    (id, callback) => ({ id, callback })
)
export const getAllFilePost = createAction(
    GET_ALL_FILE_POST,
    (callback) => ({ callback })
)
export const downloadFilePost = createAction(
    DOWNLOAD_FILE_POST,
    (id, name, callback) => ({ id, name, callback })
)

export const setAllFilePost = createAction(
    SET_ALL_FILE_POST,
    (filePosts) => ({ filePosts })
)

export const setNewFilePostToArray = createAction(
    SET_NEW_POST_FILE_TO_ARRAY,
    (filePost) => ({ filePost })
)

export const actions = {
    createFilePost,
    editFilePost,
    removeFilePost,
    getAllFilePost,
    downloadFilePost,
    setAllFilePost,
    setNewFilePostToArray,
};