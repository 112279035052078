export default {
  SESSION_TYPE: [
    {
      text: "Keynote/Fireside Chat",
      value: "Keynote/Fireside Chat",
    },
    {
      text: "Presentation",
      value: "Presentation",
    },
    {
      text: "Roundtable",
      value: "Roundtable",
    },
    // {
    //   text: "DEMO",
    //   value: "DEMO",
    // },
    {
      text: "Certificate Track and Panels",
      value: "Certificate Track and Panels",
    },
  ],
};
