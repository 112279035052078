import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Upload } from "antd";
import ImgCrop from "antd-img-crop";
import clsx from "clsx";

import { CustomButton } from "components";

import "./style.scss";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const ImageUpload = ({
  className,
  value,
  aspect,
  onChange,
  type = "classic",
  textScale = "",
}) => {
  const [fileList, setFileList] = useState([]);
  const [src, setSrc] = useState("");
  const [urlStatic, setUrlStatic] = useState("");
  const containerRef = useRef(null)

  const imageUpload = async ({ file, onSuccess }) => {
    let type = file.type;

    // const addBackslash = (type) => {
    //   return type.replace("+", "\\+");
    // };

    if (file?.type === "image/svg+xml") {
      // type = addBackslash(file.type)
      type = 'image/svg'
    }

    const fileBlob = new Blob([file], { type: type });
    
    if (file.type === "image/svg+xml") {
    setUrlStatic(URL.createObjectURL(file));

      const fileWithTypeModified = new Blob([file], { type });
      const base64FormatFile = await getBase64(fileWithTypeModified);
      onChange(base64FormatFile, file);
      setSrc(base64FormatFile);
    } else {
      setUrlStatic(URL.createObjectURL(fileBlob));
      const base64FormatFile = await getBase64(file);
      onChange(base64FormatFile, file);
      setSrc(base64FormatFile);
    }
    onSuccess("ok");
  };

  const handleChange = ({ fileList }) => {
    setFileList(fileList);
  };

  const removeFile = () => {};

  useEffect(() => {
    setSrc(value);
    setUrlStatic(value);
    return () => {};
  }, [value]);

  return (
    <div
      className={clsx(className, "image-upload")}
      style={src ? { backgroundImage: `url("${src}")` } : {}}
      ref={containerRef}
    >
      <ImgCrop rotate aspect={aspect}>
        <Upload
          customRequest={imageUpload}
          listType="image-upload-control"
          fileList={fileList}
          maxCount={1}
          onChange={handleChange}
          onRemove={removeFile}
          accept="image/*"
        >
          {type === "classic" ? (
            <CustomButton
              text="Upload image"
              size="md"
              type="primary outlined"
            />
          ) : type === "blog-style" ? (
            <>
              {urlStatic === "" ? (
                <div
                  style={{
                    width: "100%",
                    height: "auto",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="icon-image-upload"></div>
                  <p style={{ fontSize: "16px", textAlign: "center" }}>
                    Upload image
                  </p>
                  {textScale !== "" && (
                    <p style={{ fontSize: "14px", textAlign: "center" }}>
                      {textScale}
                    </p>
                  )}
                </div>
                ) : (
                <img
                  style={{
                    height: `auto`,
                    width: `${(containerRef.current ? (containerRef.current.clientHeight * 0.85) : 90) * aspect}px`,
                    borderRadius: "10px",
                  }}
                  alt="img-profile"
                  src={urlStatic}
                  />
              )}
            </>
          ) : (
            <CustomButton
              text="Upload image"
              size="md"
              type="primary outlined"
            />
          )}
        </Upload>
      </ImgCrop>
    </div>
  );
};

ImageUpload.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  aspect: PropTypes.number,
  onChange: PropTypes.func,
};

ImageUpload.defaultProps = {
  className: "",
  value: "",
  aspect: 1 / 1,
  onChange: () => {},
};

export default ImageUpload;
