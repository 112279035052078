import { createSelector } from "reselect";

const liveChatDataSelector = (state) => state.liveChat;

const resultSelector = createSelector(liveChatDataSelector, (payload) => {
    return {
        liveChat: payload.get("liveChat"),
    };
});

export const liveChatSelector = (state) => ({
    ...resultSelector(state),
});
