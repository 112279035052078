import React, {useState,useEffect} from "react";


import {Button,Form, Input} from 'antd'
// import IconMenu from "images/icon-menu.svg";
import { ResourceCard } from "components";
import { connect } from "react-redux";

import "./style.scss";
// import moment from "moment";

const Resource = () => {
    const [file,setFile] = useState({})
    const [colorText, setColorText] = useState('white')

    useEffect(() => {
        let data = JSON.parse(localStorage.getItem("previuwR"))
        setFile(data)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getContrast = (color) => {

        const hexColor = color.charAt(0) === '#' ? color.substring(1) : color;
        const r = parseInt(hexColor.substring(0, 2), 16);
        const g = parseInt(hexColor.substring(2, 4), 16);
        const b = parseInt(hexColor.substring(4, 6), 16);
      

        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
      
        return luminance > 0.5 ? 'black' : 'white';
    }

    useEffect(() => {
        if(file.color){
          setColorText(getContrast(file.color))  
        }
    }, [file,getContrast])

    return (<>
        <div className="container-resource">
            <div className="container-first-segment-r" >
                <div className="container-background-color-r" style={{background: file.color}}></div>
                <div className="container-background-r"></div>
                <div className="container-distribution-resourcers">
                    <div className="container-left-first-segment-r">
                        <div className="container-text-re">
                            <h3 className={(colorText === 'white') ? "color-white h3-re" : "color-black h3-re"}>{file.name}</h3>
                            <div style={{marginTop:'40px'}} className={(colorText === 'white') ? "color-white2" : "color-black2"} dangerouslySetInnerHTML={{ __html: file.description }}></div>
                            {/* <div className="container-texts">
                                <div className="element-contaniner-texts">
                                    <div></div>
                                    <p style={{color:colorText}}>Guides</p>
                                </div>
                                <div className="element-contaniner-texts">
                                    <div></div>
                                    <p style={{color:colorText}}>Ebooks</p>
                                </div>
                                <div className="element-contaniner-texts">
                                    <div></div>
                                    <p style={{color:colorText}}>Cheatsheets</p>
                                </div>
                            </div>
                            <p className="parraf-text-end">Fill form below to download complete Ebook content</p> */}
                        </div>
                    </div>
                    <div className="container-left-second-segment-r">
                        <div className="container-image-ebook">
                            <div className="container-ebook-container">
                            <img style={{width:'100%',height:'100%'}} src={file?.documentFileImageUrl} alt={"resource"+file.slug} />
                            <div className="container-gradient"></div>
                            <p className="button-text-ebook-img">(Ebook - PDF file Demo)</p>
                            <div className="button-explore">Explore</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-form-send-email">
                <div className="container-form-send-download">
                <Form name="form_item_path" layout="vertical">
                    <div className="container-inputs-contact">
                        <Form.Item name="firstName" label="First Name" required>
                            <Input size="large" placeholder="Your name" />
                        </Form.Item>
                        <Form.Item name="lastName" label="Last Name" required>
                            <Input size="large" placeholder="Your last name" />
                        </Form.Item>

                        <Form.Item name="age" label="Preferred Pronouns" required>
                            <Input size="large" placeholder="Preferred Pronouns" />
                        </Form.Item>

                        {/* </Form.ItemGroup> */}

                        <Form.Item
                            name="email"
                            label="Email"
                        >
                            <Input size="large" placeholder="you@example.com" />
                        </Form.Item>

                        <Form.Item name="job_title" label="Job title" required>
                            <Input size="large" placeholder="Job title" />
                        </Form.Item>

                        <Form.Item name="company_name" label="Company name" required>
                            <Input size="large" placeholder="Company name" />
                        </Form.Item>

                    </div>

                        <Button type="primary" htmlType="submit" className="button-send-message">
                            Download
                        </Button>
                    </Form>
                </div>
            </div>
            <div className="resource-2">
            <div className="resource_container" style={{background:'none'}}>
                <div className="search-results-container" style={{background:'none'}}>
                <div className="container-father">
                    <div className='container-quit-center'>
                        <div className='container-text-tranding'>
                            <h1>Other Resources to Download</h1>
                            <div></div>
                        </div>
                    </div>
                    <div className="resource-list">
                        <ResourceCard data={file} />
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
    </>);
};

const mapStateToProps = (state) => ({


});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(Resource);