import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import { homeSelector } from "redux/selectors/homeSelector";
import { councilSelector } from "redux/selectors/councilSelector";
import { getUser } from "redux/actions/home-actions";
import { INTERNAL_LINKS, EVENT_TYPES } from "enum";

import { actions as councilConversation } from "redux/actions/councilConversation-actions";
import { actions as councilEventActions } from "redux/actions/council-events-actions";
import { actions as speaker } from "redux/actions/speaker-actions";
import { councilEventSelector } from "redux/selectors/councilEventSelector";
import { councilConversationSelector } from "redux/selectors/councilConversationSelector";
import { speakerAllPanelSpeakerSelector } from "redux/selectors/speakerSelector";

import { Modal } from "antd";
import Login from "../Login2";
import { isEmpty } from "lodash";
import moment from "moment-timezone";

import ButtonsCouncilEvents from "./ButtonsCouncilEvents";
import MemberSpeakers from "./MembersSpeakers";
import ModalCompleteYourProfile2 from "./ModalCompleteYourProfile2";

import { convertToLocalTime } from "utils/format";

import Emitter from "services/emitter";

import {
    CollapseComponent,
} from "components";

import {
    Space,
    notification
} from "antd";

import IconBack from "images/icon-back.svg";

import "./style.scss";

const CouncilPageAdmin = ({
    userProfile,
    getCouncilEvents,
    allCouncilEvents,
    joinCouncilEvent,
    joinCouncilEventWait,
    joinSpeakersEventWait,
    getUser,
    getAllPanelsOfOneGlobalConference,
    allPanelsOfAConference,
    removeUserSpeakerToPanel,
    getAllPanelSpeakers,
    getAllUserSpeaker,
    allMember,
    getAllMemberSpeakerPanel,
    getAllPanelsOfOneUserSpeakers,
    allPanelSpeakers,
    addUserSpeakerToPanel,
    joinExternalAwait,
}) => {

    const location = useLocation();
    const id = location.pathname.substring(16,location.pathname.length)
    const [event, setEvent] = useState({});
    const [limit, setLimit] = useState(0)

    const [bulModal5, setBulModal5] = useState(false)

    const [changueModal, setChangueModal] = useState(false)
    const [changueModal2, setChangueModal2] = useState(false)

    const [showProfileCompletionFirewall, setShowProfileCompletionFirewall] = useState(false);
    const userTimezone = moment.tz.guess();
    const [isLogged,setIsLogged] = useState(true)
    const [isGlobalConference,setIsGlobalConference] = useState(null)
    // const [panelsFullBul, setPanelsFullBul] = useState(false);
    const panelsFullBul = false;
    const firstName = userProfile?.firstName
    const timezone = !isEmpty(event) && event.timezone;

    useEffect(() => {
        getUser();
    }, [getUser])

    useEffect(() => {
        if (isGlobalConference) {    
           getAllPanelsOfOneGlobalConference(event?.idConference);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event])

    useEffect(() => {
        if (changueModal === true && firstName !== '' && firstName !== undefined) {
          setTimeout(() => {
            if (userProfile.percentOfCompletion === '100' || userProfile.percentOfCompletion === 100) {
              setIsLogged(true)
            } else {
                setBulModal5(true)
                setIsLogged(true)
            }
          }, 200);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [firstName, changueModal, setBulModal5, userProfile])
    
      useEffect(() => {
        if (changueModal2 === true && firstName !== '' && firstName !== undefined) {
          setTimeout(() => {
            setChangueModal2(false)
            if (userProfile.percentOfCompletion === '100' || userProfile.percentOfCompletion === 100) {
              setBulModal5(false)
            }
          }, 200);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [firstName, changueModal2, setBulModal5])

    useEffect(() => {
        getCouncilEvents();
    },[getCouncilEvents])

    useEffect(() => {
        const _event = allCouncilEvents.find((eve) => eve.id === Number(id));
        setEvent(_event);
        if (_event?.isGlobalConference !== undefined) {   
            setIsGlobalConference(_event.isGlobalConference)
        }
    },[allCouncilEvents,id,setEvent])

    useEffect(() => {
        if(userProfile !== undefined){
            const limit1  = event?.CouncilEventPanels?.filter((panel) => {
                return panel?.arrayControlIdsEvents?.some(users => {
                    return Number(users.id) === Number(userProfile.id)
                })
            })

            const limit2  = event?.CouncilEventPanels?.filter((panel) => {
                return panel?.CouncilEventPanelists?.some(users => {
                    return Number(users.UserId) === Number(userProfile.id)
                })
            })

            if(limit1 !== undefined && limit2 !== undefined){
              setLimit([...limit1, ...limit2]?.length)  
            }
            
        }
    }, [event, userProfile])

    const content = (panels) => {

        let startTime = convertToLocalTime(panels?.startDate, event?.timezone);
        let endTime = convertToLocalTime(panels?.endDate, event?.timezone);

        return (
          <div className="content-collapse" key={panels?.id}>
            <p
                style={{margin:'0px',marginTop:'3px', fontSize: '30px', fontWeight: 'bold'}}
            >
                {panels?.panelName}
            </p>
            <p
                style={{margin:'0px',marginTop:'3px'}}
            >
                <b>Panel Date</b>:{` ${startTime.format("LL")}`}
            </p>
            <p className="title-collapse"
                style={{margin:'0px',marginTop:'3px'}}
            >
                <b>Panel Start Time:</b> {startTime.format("HH:mm a")} {moment.tz.guess()}
            </p>
            <p className="title-collapse"
                style={{margin:'0px',marginTop:'3px', marginBottom: '10px'}}
            >
                <b>Panel End Time:</b> {endTime.format("HH:mm a")} {moment.tz.guess()}
            </p>
          </div>
        )  
    };

    const updateDataInformation = () => {
        getCouncilEvents(() => {
            const _event = allCouncilEvents.find((eve) => eve.id === Number(id));

            const limit1  = event?.CouncilEventPanels?.filter((panel) => {
                return panel?.arrayControlIdsEvents?.some(users => {
                    return Number(users.id) === Number(userProfile.id)
                })
            })

            const limit2  = event?.CouncilEventPanels?.filter((panel) => {
                return panel?.CouncilEventPanelists?.some(users => {
                    return Number(users.UserId) === Number(userProfile.id)
                })
            })

            if(limit1 !== undefined && limit2 !== undefined){
              setLimit([...limit1, ...limit2]?.length)  
            }

            setEvent(_event);
        }); 
    }

    const removeUserFunction = (id, user, panelName) => {
        removeUserSpeakerToPanel({ id: id, panelName: panelName }, () => {
          getAllPanelSpeakers("Panels", {
            topics: undefined,
            bul: panelsFullBul,
          });
          getAllPanelsOfOneUserSpeakers();
          getAllUserSpeaker(userProfile?.id);
          getAllMemberSpeakerPanel();
          getAllPanelsOfOneGlobalConference(event?.idConference);
    
          // if (userProfile?.id === user) {
          //   setTimeout(() => {
          //     setRemoveMembersSpeakers(true);
          //   }, 100);
          // }
        });
    };
    
    const joinUser = (data, index, user) => {
        if (userProfile?.percentOfCompletion !== 100) {
          return setShowProfileCompletionFirewall(true);
        }
    
        let usersNames = {
          userId: user?.User?.id,
          userName: user?.User?.firstName,
          userEmail: user?.User?.email,
        };
    
        let arrayMemberNotModerator = allPanelSpeakers[
          index
        ]?.SpeakerMemberPanels?.filter((member) => {
          return member?.isModerator === false;
        });
    
        if (
          allMember?.length < 2 &&
          userProfile?.role !== "admin" &&
          arrayMemberNotModerator?.length < 5
        ) {
          addUserSpeakerToPanel(
            { usersNames, bul: false, panel: data, type: "joinUser" },
            () => {
              getAllPanelSpeakers("Panels", {
                topics: undefined,
                bul: panelsFullBul,
              });
              getAllPanelsOfOneUserSpeakers();
              getAllUserSpeaker(userProfile?.id);
              getAllMemberSpeakerPanel();
              getAllPanelsOfOneGlobalConference(event?.idConference);
            }
          );
        } else {
          if (userProfile?.role !== "admin") {
            if (arrayMemberNotModerator?.length === 5) {
              notification.error({
                message: "ERROR:",
                description: "This panel is full.",
              });
            }
            if (allMember?.length === 2) {
              notification.error({
                message: "ERROR:",
                description: "You can't join more than two panels.",
              });
            }
          }
        }
        if (userProfile?.role === "admin") {
            usersNames = [JSON.stringify(usersNames)]
            addUserSpeakerToPanel(
                { usersNames, bul: false, panel: data, type: "addUserAdmin" },
                () => {
                getAllPanelSpeakers("Panels", {
                    topics: undefined,
                    bul: panelsFullBul,
                });
                getAllPanelsOfOneUserSpeakers();
                getAllUserSpeaker(userProfile?.id);
                getAllMemberSpeakerPanel();
                getAllPanelsOfOneGlobalConference(event?.idConference);
                }
            );
        }
    };

    const dataIterated = (panels,users) => {
        let userSelects = users?.map((data) => {
            if(Number(data?.id) === Number(userProfile?.id)){
                return {
                    id: panels?.id, 
                    isModerator: false, 
                    buttonsAccept: true,
                    UserId: data?.id,
                    User: data,
                    pending: true
                } 
            }else{
                return {
                    id: panels?.id, 
                    isModerator: false, 
                    buttonsAccept: true,
                    UserId: data?.id,
                    User: data,
                    pending: (userProfile?.role !== 'admin') ? false : true
                } 
            }  
        })
      
        let usersSelectss = []
        if(userSelects.length > 0){
            usersSelectss = [...panels?.CouncilEventPanelists, ...userSelects]
        }else{
            usersSelectss = [...panels?.CouncilEventPanelists]
        }    

        return (
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyDontent: "space-around",
                flexWrap: "wrap",
            }}>
                {usersSelectss?.map((user,index) => {
                
                    return (
                        <MemberSpeakers
                            key={index}
                            // emailOfAdmin={userProfile?.email}
                            usersPanel={user}
                            isAdmin={userProfile?.role === "admin" ? true : false}
                            remove={() => {
                                joinCouncilEventWait(
                                    panels.id,
                                    user.User,
                                    "Unjoin",
                                    false,
                                    false,
                                    event,
                                    {
                                        rejectWithEmail: false
                                    },
                                    () => updateDataInformation()
                                ); 
                            }}
                            removeAdmin={() => {
                                joinCouncilEvent(
                                    panels.id,
                                    Number(user.UserId),
                                    "Unjoin",
                                    false,
                                    false,
                                    event.id,
                                    () => updateDataInformation()
                                ); 
                            }}
                            joinAdmin={() => {
                                joinCouncilEvent(
                                    panels.id,
                                    Number(user.UserId),
                                    "Join",
                                    false,
                                    false,
                                    event.id,
                                    () => updateDataInformation()
                                );
                            }}
                        />
                    )
                })}
            </div>
        )
    };

    const dataIteratedForConference = (panels,users) => {
        let userSelects = users?.map((data2) => {
            let data = data2?.id ? data2 : JSON.parse(data2)
            if(Number(data?.id) === Number(userProfile?.id)){
                return {
                    id: panels?.id, 
                    isModerator: false, 
                    buttonsAccept: true,
                    UserId: data?.id,
                    User: data,
                    isKeynote: data.isKeynote,
                    pending: true
                } 
            }else{
                return {
                    id: panels?.id, 
                    isModerator: false, 
                    buttonsAccept: true,
                    UserId: data?.id,
                    isKeynote: data.isKeynote,
                    User: data,
                    pending: (userProfile?.role !== 'admin') ? false : true
                } 
            }  
        })

        let usersSelectss2 = panels.arrayControlIdsExternal?.map((data2) => {
            let data = data2?.id ? data2 : JSON.parse(data2)
            if (Number(data?.id) === Number(userProfile?.id)) {
              return {
                id: panels?.id,
                isModerator: (data.isModerator === 'false') ? false : true,
                buttonsAccept: false,
                UserId: data?.id,
                User: data,
                isExternalUser: true,
                isKeynote: data.isKeynote,
                pending: true,
              };
            } else {
              return {
                id: panels?.id,
                isModerator: (data.isModerator === 'false') ? false : true,
                buttonsAccept: false,
                UserId: data?.id,
                isKeynote: data.isKeynote,
                User: data,
                isExternalUser: true,
                pending: userProfile?.role !== "admin" ? false : true,
              };
            }
          });
      
        let usersSelectss = []
        if(userSelects.length > 0){
            usersSelectss = [...panels.SpeakerMemberPanels,...userSelects, ...usersSelectss2]
        }else{
            usersSelectss = [...panels.SpeakerMemberPanels, ...usersSelectss2]
        }  

        return (
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyDontent: "space-around",
                flexWrap: "wrap",
            }}>
                {usersSelectss?.map((user, index) => {
                    return (
                        <MemberSpeakers
                            key={index}
                            // emailOfAdmin={userProfile?.email}
                            usersPanel={user}
                            isAdmin={userProfile?.role === "admin" ? true : false}
                            removeExternal={() => [
                                joinExternalAwait(
                                    panels?.id,
                                    user?.User,
                                    "Unjoin",
                                    false,
                                    false,
                                    event,
                                    null,
                                    () => updateDataInformation()
                                  )
                            ]}
                            remove={() => {
                                // removeUserFunction(user?.UserId, '', panels?.panelName);
                                // updateDataInformation();
                                // joinCouncilEventWait(
                                //     panels.id,
                                //     user.User,
                                //     "Unjoin",
                                //     false,
                                //     false,
                                //     event,
                                //     () => updateDataInformation()
                                // ); 
                                joinSpeakersEventWait(
                                    panels?.id,
                                    user?.User,
                                    "Unjoin",
                                    false,
                                    false,
                                    event,
                                    () => updateDataInformation()
                                )
                            }}
                            removeAdmin={() => {
                                removeUserFunction(user?.id, '', panels?.panelName);
                                updateDataInformation();
                                // joinCouncilEvent(
                                //     panels.id,
                                //     Number(user.UserId),
                                //     "Unjoin",
                                //     false,
                                //     false,
                                //     event.id,
                                //     () => updateDataInformation()
                                // ); 
                            }}
                            joinAdmin={() => {
                                joinUser(panels,index,user)
                                updateDataInformation();
                                // joinUser(panels,index )
                                // joinCouncilEvent(
                                //     panels.id,
                                //     Number(user.UserId),
                                //     "Join",
                                //     false,
                                //     false,
                                //     event.id,
                                //     () => updateDataInformation()
                                // );
                            }}
                        />
                    )
                })}
            </div>
        )
    };

    const completeProfile = () => {
        Emitter.emit(EVENT_TYPES.EVENT_VIEW_PROFILE);
    };

    const functionOrderPanels = (panels) => {
        if(panels !== undefined){
            let newPanels = panels?.filter((t) => (t?.typePanel?.length !== 0) ? t?.typePanel?.includes('panel') : true)

            let arrayOrderNumer = newPanels?.sort((a,b) => {

                let aTime = convertToLocalTime(a?.startDate, event?.timezone).format("YYYYMMDDHHmm");
                let bTime = convertToLocalTime(a?.startDate, event?.timezone).format("YYYYMMDDHHmm");

                return Number(aTime) - Number(bTime)
        
            })

            return arrayOrderNumer
        } else {
            
            return panels
        }
    }

    const functionOrderSessionsOfOneConference = (panels) => {
        if(panels !== undefined){
            // let newPanels = panels?.filter((t) => (t?.typePanel?.length !== 0) ? t?.typePanel?.includes('panel') : true)

            let arrayOrderNumer = panels?.sort((a,b) => {

                let aTime = convertToLocalTime(a?.startDate, event?.timezone).format("YYYYMMDDHHmm");
                let bTime = convertToLocalTime(a?.startDate, event?.timezone).format("YYYYMMDDHHmm");

                return Number(aTime) - Number(bTime)
        
            })
            return arrayOrderNumer
        } else {
            return panels
        }
    }

    const displayPanels = functionOrderPanels(event?.CouncilEventPanels)?.map((panel, index) => {
        return (
            <CollapseComponent
                key={panel?.id}
                informationCollapse={content(panel)}
                className={"container-panel"}
                dataIterated={dataIterated(panel, panel.arrayControlIdsEvents)}
                buttons={
                    <ButtonsCouncilEvents
                        index={index}
                        panel={panel}
                        limit2={limit >= event.maxNumberOfPanelsUsersCanJoin}
                        isGlobalConference={false}
                        withdraw={() => {
                            joinCouncilEventWait(
                                panel.id,
                                userProfile,
                                "Unjoin",
                                false,
                                false,
                                event,
                                {
                                    rejectWithEmail: false
                                },
                                () => updateDataInformation()
                            );
                        }}
                        join={() => {
                            if(event?.status !== 'closed'){
                                joinCouncilEventWait(
                                    panel.id,
                                    userProfile,
                                    "Join",
                                    false,
                                    false,
                                    event,
                                    {
                                        rejectWithEmail: false
                                    },
                                    () => updateDataInformation()
                                );
                            }else{
                                notification.info({
                                    message: "Sorry, this event is closed"
                                })
                            }
                        }}
                        withdrawAdmin={() => {
                            joinCouncilEvent(
                                panel.id,
                                Number(userProfile.id),
                                "Unjoin",
                                false,
                                false,
                                event.id,
                                () => updateDataInformation()
                            );
                            
                        }}
                        completeProfile={() => setBulModal5(true)}
                        logginModal={() => setIsLogged(false)}
                        userProfile={userProfile}
                    />
                }
            />
        );
    });

    const displaySessionsOfOneGlobalConference = functionOrderSessionsOfOneConference(allPanelsOfAConference)?.map((panel, index) => {
        return (
            <CollapseComponent
                key={panel?.id}
                informationCollapse={content(panel)}
                className={"container-panel"}
                dataIterated={dataIteratedForConference(panel, panel?.arrayControlIdsEvents)}
                buttons={
                    <ButtonsCouncilEvents
                        index={index}
                        panel={panel}
                        limit2={false}
                        isGlobalConference={true}
                        withdraw={() => {
                            // const user = panel?.SpeakerMemberPanels.find((user) => Number(user?.UserId) === Number(userProfile.id));
                            // removeUserFunction(user.id, '', panel?.panelName);
                            // joinCouncilEventWait(
                            //     panel.id,
                            //     userProfile,
                            //     "Unjoin",
                            //     false,
                            //     false,
                            //     event,
                            //     () => updateDataInformation()
                            // );
                            joinSpeakersEventWait(
                                panel?.id,
                                userProfile,
                                "Unjoin",
                                false,
                                false,
                                event,
                                () => updateDataInformation()
                            )
                        }}
                        join={() => {
                            // joinUser(panel,index )
                            if(event?.status !== 'closed'){
                                // joinCouncilEventWait(
                                //     panel.id,
                                //     userProfile,
                                //     "Join",
                                //     false,
                                //     false,
                                //     event,
                                //     () => updateDataInformation()
                                // );
                                joinSpeakersEventWait(
                                    panel?.id,
                                    userProfile,
                                    "Join",
                                    false,
                                    false,
                                    event,
                                    () => updateDataInformation()
                                )
                            }else{
                                notification.info({
                                    message: "Sorry, this event is closed"
                                })
                            }
                        }}
                        withdrawAdmin={() => {
                            const user = panel?.SpeakerMemberPanels.find((user) => Number(user?.UserId) === Number(userProfile.id))
                            removeUserFunction(user?.id,'',panel?.panelName)
                        }}
                        completeProfile={() => setBulModal5(true)}
                        logginModal={() => setIsLogged(false)}
                        userProfile={userProfile}
                    />
                }
            />
        );
    });
  
    return (
        <>
            <div className="council-page-events-3">
                <div className="search-results-container-2"> 
                    <div className="council-page__container">
                    <div className="council-page__results">
                        <div className="council-page__row">
                        <div className="council-page__info-column"></div>
                        <div className="council-page__content">
                            <div className="council-filter-panel">
                                {(localStorage.getItem("community") !== null) && <Link to={INTERNAL_LINKS.HOME}>
                                    <div className="council-page__content-top">
                                    <div className="council-page__content-top-back">
                                        <img src={IconBack} alt="icon-back" />
                                    </div>
                                    <h4>Back</h4>
                                    </div>
                                </Link>}
                                <Space direction="vertical" className="new-detail">
                                    <h2 style={{paddingTop: '20px'}}>Event Name: {event?.eventName}</h2>
                                    <h4>
                                    Date:{" "}
                                    {convertToLocalTime(event?.startDate, timezone).format("LL")} -{" "}
                                    {convertToLocalTime(event?.endDate, timezone).format("LL")} (
                                    {userTimezone})
                                    </h4>
                                    <h4>Description: {event?.description}</h4>
                                </Space>
                                <div className="fix-width">
                                    { isGlobalConference ? displaySessionsOfOneGlobalConference : displayPanels }
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            {showProfileCompletionFirewall && (
                <div
                className="skill-cohort-firewall"
                onClick={() => {
                    setShowProfileCompletionFirewall(false);
                }}
                >
                <div className="upgrade-notification-panel" onClick={completeProfile}>
                    <h3>
                        You must fully complete your profile before joining an event.
                    </h3>
                </div>
                </div>
            )}
            <Modal
                visible={!isLogged || bulModal5}
                footer={null}
                width={400}
                bodyStyle={{ marginBottom: '40px', padding: "20px", display: 'flex', justifyContent: 'center' }}
                className="modal-container-login"
            >
                {!isLogged && <Login
                  login={true}
                  signup={false}
                  type={'ladingPague'}
                  history={null}
                  confirm={() => {
                    getUser();
                    if (!isLogged) {
                      setChangueModal(true)
                    }
                  }}
                  match={{ params: {} }}
                  modal={() => {
                    setIsLogged(false);
                  }}
                  onClose={() => {
                    // setIsLogged(false);
                  }}
                />
                }{bulModal5 &&
                  <ModalCompleteYourProfile2
                    style={{ marginTop: '100px' }}
                    userProfile={userProfile}
                    onOk={() => {
                        getUser(userProfile?.id,() => {
                          setChangueModal2(true)  
                        })
                      
                    }}
                  />
                }
        
            </Modal>
            
        </>
    );
};

const mapStateToProps = (state, props) => ({
  userProfile: homeSelector(state).userProfile,
  councilResources: councilSelector(state).councilResources,
  ...councilEventSelector(state),
  ...councilConversationSelector(state),
  allPanelsOfAConference:
    speakerAllPanelSpeakerSelector(state).allPanelsOfAConference,
  allUserSpeaker: speakerAllPanelSpeakerSelector(state).allUserSpeakers,
  allPanelSpeakers: speakerAllPanelSpeakerSelector(state).allPanelSpeakers,
  allMember: speakerAllPanelSpeakerSelector(state).allMember,
});

const mapDispatchToProps = {
    getUser,
  ...councilConversation,
  ...councilEventActions,
  getAllPanelsOfOneGlobalConference: speaker.getAllPanelsOfOneGlobalConference,
  joinSpeakersEventWait: speaker.joinSpeakersEventWait,
  removeUserSpeakerToPanel: speaker.removeUserSpeakerToPanel,
  getAllPanelsOfOneUserSpeakers: speaker.getAllPanelsOfOneUserSpeakers,
  getAllMemberSpeakerPanel: speaker.getAllMemberSpeakerPanel,
  addUserSpeakerToPanel: speaker.addUserSpeakerToPanel,
  joinExternalAwait: speaker.joinExternalAwait,
  getAllPanelSpeakers: speaker.getAllPanelSpeakers,
  getAllUserSpeaker: speaker.getAllUserSpeaker,
};

export default connect(mapStateToProps, mapDispatchToProps)(CouncilPageAdmin);
