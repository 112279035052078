import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Tabs } from "components";

import GeneralInformation from "./GeneralInformation";
import GeneralProfile from "./General Profile";
import Mentee from "./Mentee";
import Mentor from "./Mentor";

import {
  createMentor,
  createMentee,
  createRequest,
  updateMentor,
  updateMente,
  updateRequest,
  getOneMentor,
  getOneMentee,
} from "redux/actions/mentoring-actions";

import { getUser } from "redux/actions/home-actions";

import { mentoringSelector } from "redux/selectors/mentoringSelector";
import { homeSelector } from "redux/selectors/homeSelector";
import { notificationSelector } from "redux/selectors/notificationSelector";

import {
  getNotifications,
  markNotificationToRead
} from "redux/actions/notification-actions";

import "./style.scss";

const MentoringNew = ({
  createMentor,
  createMentee,
  createRequest,
  updateMentor,
  updateMente,
  updateRequest,
  getOneMentor,
  getOneMentee,
  mentorProfile,
  menteeProfile,
  mentorListFind,
  menteeListFind,
  userProfile,
  requestListMentor,
  requestListMentee,
  getUser,
  getNotifications,
  notificationList,
  markNotificationToRead
}) => {
  const [currentTab, setCurrentTab] = useState("0");
  const [subCurrentTab, setSubCurrentTab] = useState(null);
  const [notificationsMentor, setNotificationsMentor] = useState(null);
  const [notificationsMentee, setNotificationsMentee] = useState(null);
  const location = useLocation()

  useEffect(() => {
    getOneMentee();
    getOneMentor();
    getUser();
  }, [getOneMentee, getOneMentor, getUser]);

  useEffect(()=>{
    const menteeNotifications = (notificationList || []).filter(notif => !notif?.readers?.includes(userProfile?.id) && notif?.onlyFor?.includes(userProfile?.id) && notif?.type === 'mentoring-request-from-mentor');
    const mentorNotifications = (notificationList || []).filter(notif => !notif?.readers?.includes(userProfile?.id) && notif?.onlyFor?.includes(userProfile?.id) && notif?.type === 'mentoring-request-from-mentee');

    setNotificationsMentee(menteeNotifications || null)
    setNotificationsMentor(mentorNotifications || null)
  },[notificationList,userProfile])

  useEffect(()=>{
    if(location.search === '?key=mentee-notification'){
      setCurrentTab('2')
      setSubCurrentTab('2')
    }else if(location.search === '?key=mentor-notification'){
      setCurrentTab('1')
      setSubCurrentTab('2')
    }else{
      setSubCurrentTab(null)
    }
  },[location])


  const TabDataMentor =
    // menteeProfile?.status === "active"
    //   ?
    [
      {
        title: "Mentee",
        key: 2,
        notificationsCount: notificationsMentee?.length || 0,
        content: () => (
          <Mentee
            userProfile={userProfile}
            mentorProfile={mentorProfile}
            menteeProfile={menteeProfile}
            createMentee={createMentee}
            updateMente={updateMente}
            getOneMentee={getOneMentee}
            getOneMentor={getOneMentor}
            getUser={getUser}
            mentorListFind={mentorListFind}
            menteeListFind={menteeListFind}
            createRequest={createRequest}
            updateRequest={updateRequest}
            requestListMentor={requestListMentor}
            requestListMentee={requestListMentee}
            markNotificationToRead={markNotificationToRead}
            notificationsCount={ notificationsMentee?.length || 0}
            notifications={notificationsMentee}
            getNotifications={getNotifications}
            subCurrentTab={subCurrentTab}
          />
        ),
      },
    ];
  // : [];

  const TabDataMentee =
    // mentorProfile?.status === "active"
    //   ?
    [
      {
        title: "Mentor",
        key: 3,
        notificationsCount: notificationsMentor?.length || 0,
        content: () => (
          <Mentor
            userProfile={userProfile}
            mentorProfile={mentorProfile}
            menteeProfile={menteeProfile}
            createMentor={createMentor}
            updateMentor={updateMentor}
            getOneMentor={getOneMentor}
            getOneMentee={getOneMentee}
            getUser={getUser}
            mentorListFind={mentorListFind}
            menteeListFind={menteeListFind}
            createRequest={createRequest}
            updateRequest={updateRequest}
            requestListMentor={requestListMentor}
            requestListMentee={requestListMentee}
            markNotificationToRead={markNotificationToRead}
            notificationsCount={notificationsMentor?.length || 0}
            notifications={notificationsMentor}
            getNotifications={getNotifications}
            subCurrentTab={subCurrentTab}
          />
        ),
      },
    ];
  // : [];

  const TabData = [
    {
      title: "General Profile",
      key: 1,
      content: () => (
        <GeneralProfile
          mentorProfile={mentorProfile}
          menteeProfile={menteeProfile}
          createMentor={createMentor}
          createMentee={createMentee}
          updateMentor={updateMentor}
          updateMente={updateMente}
          userProfile={userProfile}
          getOneMentor={getOneMentor}
          getOneMentee={getOneMentee}
          getUser={getUser}
        />
      ),
    },
    ...TabDataMentee,
    ...TabDataMentor,
  ];

  const TabDataDefault = [
    {
      title: "General Information",
      key: 1,
      content: () => <GeneralInformation userProfile={userProfile} />,
    },
  ];

  return (
    <div className="container-mentoring">
      {userProfile.isMentoringMember !== undefined &&
      userProfile.isMentoringMember === "accepted" ? (
        <Tabs data={TabData} current={currentTab} onChange={setCurrentTab} />
      ) : (
        <Tabs
          data={TabDataDefault}
          current={currentTab}
          onChange={setCurrentTab}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  mentorProfile: mentoringSelector(state).mentorProfile,
  menteeProfile: mentoringSelector(state).menteeProfile,
  mentorListFind: mentoringSelector(state).mentorListFind,
  menteeListFind: mentoringSelector(state).menteeListFind,
  requestListMentor: mentoringSelector(state).requestListMentor,
  requestListMentee: mentoringSelector(state).requestListMentee,
  userProfile: homeSelector(state).userProfile,
  ...notificationSelector(state),
});

const mapDispatchToProps = {
  createMentor,
  createMentee,
  createRequest,
  updateMentor,
  updateMente,
  updateRequest,
  getOneMentor,
  getOneMentee,
  getUser,
  getNotifications,
  markNotificationToRead
};

export default connect(mapStateToProps, mapDispatchToProps)(MentoringNew);
