import React, { useState } from "react";
import "./style.scss";
import { Collapse } from "antd";
import CollapseArrow from "../../../images/collapsearrow.svg"
const { Panel } = Collapse;

const CollapseDescription = ({content, title, widthTitle}) => {

    const [expandArrowAnimation, setExpandArrowAnimation] = useState(true)
  
    return (
        <div className="container-certificateee-collapse">

            <svg class="svg-change-position" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <clipPath id="optionn">
                    <path d="M0 0V30H15H30C15 30 0 17 0 0Z" fill="#D9D9D9"/>
                </clipPath>
            </svg>
            <svg class="svg-change-position" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <clipPath id="optionnn">
                    <path d="M0 0V35H17.5H35C17.5 35 0 19.8333 0 0Z" fill="#D9D9D9"/>
                </clipPath>
            </svg>
            <Collapse defaultActiveKey={['1']} bordered={false} expandIcon={() => (<></>)}>
                <Panel key={1} header={<><div className="block-all-triggers" onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}></div><div style={{'--i':widthTitle, display:'inline-flex'}} className={!expandArrowAnimation ? "container-header-collapse-certificate" : "container-header-collapse-certificate2"} onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    // setExpandArrowAnimation(!expandArrowAnimation)
                }} >
                    <div className="truc-shadow">
                        <img src={CollapseArrow} style={expandArrowAnimation ? {transform:'rotate(-90deg)', transition:'all 0.4s ease-in-out', padding:'0px'}:{transform:'rotate(0deg)',transition:'all 0.4s ease-in-out',padding:'0px'}} alt="arrow-colapse" />
                        <p className="p-collap-certificate">{title}</p> 
                    </div>
                    <div className="container-border-card-shadow"></div>
                    <div className="container-border-card"></div>
                </div></>}>
                <div className="container-collapse-card">
                    <div className="container-collapse-card-shadow">
                    {content}
                    </div>
                </div>
                </Panel>
            </Collapse>
        </div>
    );
};


export default CollapseDescription;
