import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Avatar } from "antd";
// import { CustomButton } from "components";

import "./style.scss";

const MemberSpeakers = ({
  usersPanel,
  // isAdmin,
  // remove
}) => {

  const { firstName, img, titleProfessions, lastName, abbrName, personalLinks } = usersPanel

  let caseLink = (personalLinks?.linkedin?.substring(0, 7) !== "http://" && personalLinks?.linkedin?.substring(0, 8) !== "https://")
    ? `https://${personalLinks?.linkedin}`
    : personalLinks?.linkedin

  if (caseLink?.substring(0, 14) === "http://http://") {
    caseLink = caseLink.substring(7, caseLink.length)
  }

  if (caseLink?.substring(0, 16) === "https://https://") {
    caseLink = caseLink.substring(8, caseLink.length)
  }

  return (
    <>
      <div className="container-users2">
        <div className="container-data-member">
          <div style={{ width: '65px', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {img ? (
              <Avatar size={65} src={img} />
            ) : (
              <Avatar size={65} style={{ fontSize: "1rem" }}>
                {abbrName}
              </Avatar>
            )}
          </div>
          <div className="container-p">
            <p className="p-title-conference">{firstName} {lastName}</p>
            <p className="p-profession-conference">{titleProfessions}</p>
            <a className="p-profession-conference" href={caseLink} target="_blank" rel="noopener noreferrer">
              <svg width="20px" height="20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" /></svg>
            </a>
          </div>
        </div>
        {/* {isAdmin &&
          <CustomButton
            className="button-speaker"
            text="Remove"
            size="md"
            type="third"
            onClick={() => { remove(id) }}
          />} */}
      </div>
    </>
  );
};

const mapStateToProps = (state, props) => ({

});

const mapDispatchToProps = {

};

MemberSpeakers.propTypes = {
  usersPanel: PropTypes.object,
  isAdmin: PropTypes.bool,
  remove: PropTypes.func,
};

MemberSpeakers.defaultProps = {
  usersPanel: {},
  isAdmin: false,
  remove: () => { },
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberSpeakers);
