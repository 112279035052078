import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { CaretDownOutlined } from "@ant-design/icons";
import { Badge } from "antd";
import "./style.scss";
import { useQuery } from "@tanstack/react-query";
import { CERTIFICATES } from "enum/QueryKeys";
import httpClient from "../../../api/module/httpClient";

const SideBarsChildren = ({
  TopMenuList,
  typeStyle,
  location,
  userProfile,
  onClickMenu,
  notificationsMentoring,
}) => {
  const [objectBulSideBar, setObjectBulSideBar] = useState({});
  const history = useHistory();

  const findParent = (element, type) => {
    let parent = element.closest(type);
    return parent;
  };

  const { data: allNewCourses } = useQuery({
    queryKey: [CERTIFICATES],
    queryFn: async () => {
      const { data } = await httpClient.get(`private/certificates`);

      const courses = data?.courses || [];

      let bulObject = objectBulSideBar;
      TopMenuList(courses).forEach((e) => {
        if (e?.sons?.length > 0) {
          bulObject[e?.label] = {
            bul: bulObject[e?.label]?.bul || true,
            reference: bulObject[e?.label]?.reference || [],
            height: bulObject[e?.label]?.height || e?.height,
          };

          for (let i = 0; i < e?.sons?.length; i++) {
            if (e?.sons[i]?.sons?.length > 0) {
              const dataArr = new Set([
                ...bulObject[e?.label]?.reference,
                e?.sons[i]?.label,
              ]);
              bulObject[e?.sons[i]?.label] = {
                bul: bulObject[e?.sons[i]?.label]?.bul || false,
                reference: bulObject[e?.sons[i]?.label]?.reference || [],
                height:
                  bulObject[e?.sons[i]?.label]?.height || e?.sons[i]?.height,
              };
              bulObject[e?.label] = {
                ...bulObject[e?.label],
                reference: [...dataArr],
              };
            }
          }
        }
      });

      setObjectBulSideBar(bulObject);

      return courses;
    },
  });

  const myCertificatePrograms = allNewCourses?.filter((e) => {
    const userIndex = (e?.CertificateUsers || [])?.findIndex(
      (user) => user.UserId === userProfile.id
    );
    return userIndex !== -1;
  });

  const hasNotification = (myCertificatePrograms || []).some(
    (certificateProgram) => {
      return (certificateProgram.CertificateNotifications ?? []).some(
        (certificateNotification) => {
          return !certificateNotification.readers.includes(userProfile.id);
        }
      );
    }
  );
  

  const collapseSitutaion = (label) => {
    let bulObject2 = objectBulSideBar;
    bulObject2[label] = { ...bulObject2[label], bul: !bulObject2[label]?.bul };
    setObjectBulSideBar(bulObject2);
  };

  const containerSons = (sons, label, height, menu) => {
    let number = 0;
    for (let i = 0; i < objectBulSideBar[label]?.reference?.length; i++) {
      let option = objectBulSideBar[objectBulSideBar[label]?.reference[i]];
      if (option?.bul) {
        number = number + option.height;
      }
    }

    return (
      <div
        style={{
          height: objectBulSideBar[label]?.bul ? `${height + number}px` : "0px",
        }}
        className="containers-sons"
      >
        {sons.map((menu2, index) => {
          if (
            // menu2?.label !== "Mentoring (Beta Version)" &&
            menu2?.label !== "GrowGroups"
          ) {
            return (
              <>
                <div
                  key={index}
                  className="listSideBar"
                  style={{
                    marginTop: menu2?.case === "sub" ? "17px" : "8px",
                    marginBottom: menu2?.case === "sub" ? "17px" : "8px",
                    background:
                      typeStyle === "black"
                        ? location.pathname === menu2?.url
                          ? "#FFD1C2"
                          : "transparent"
                        : location.pathname === menu2?.url
                        ? "#FFD1C2"
                        : "#ffffff",
                  }}
                >
                  <Link
                    to="#"
                    className="linkListSideBar"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      if (
                        menu2?.sons?.length > 0 &&
                        findParent(e.target, "div").id === "svg"
                      ) {
                        collapseSitutaion(menu2?.label);
                      } else {
                        onClickMenu(e, menu2?.url);
                        history.push(
                          (menu2?.noredirect === true) 
                            ? '#'
                            : location.pathname !== menu2?.url
                              ? menu2?.url
                              : `${menu2?.url}?update=${true}`
                        );
                      }
                    }}
                  >
                    {menu2?.icon && (
                      <>
                        {typeof menu2?.icon === "function" ? (
                          menu2.icon(
                            typeStyle === "black"
                              ? location.pathname === menu2?.url
                                ? "black"
                                : "white"
                              : "black"
                          )
                        ) : (
                          <img
                            alt="nose"
                            style={{ width: "24px", height: "24px" }}
                            src={menu2?.icon}
                          />
                        )}
                      </>
                    )}
                    {menu2.label?.includes("Mentoring (Beta Version)") &&
                    notificationsMentoring.length > 0 ? (
                      <Badge size="small" count={notificationsMentoring.length}>
                        <p
                          className={
                            typeStyle === "black"
                              ? location.pathname === menu2?.url
                                ? "pListSideBar"
                                : "pListSideBarBlack"
                              : "pListSideBar"
                          }
                          style={{
                            paddingLeft:
                              menu2?.icon && menu2?.icon !== ""
                                ? "10px"
                                : "0px",
                            fontSize: menu2?.case === "sub" ? "15px" : "14px",
                            fontWeight:
                              menu2?.case === "sub" ? "bold" : "normal",
                            width: "100%",
                            lineHeight: 1.29,
                          }}
                        >
                          {menu2?.label}
                        </p>
                      </Badge>
                    ) : (
                      <p
                        className={
                          typeStyle === "black"
                            ? location.pathname === menu2?.url
                              ? "pListSideBar"
                              : "pListSideBarBlack"
                            : "pListSideBar"
                        }
                        style={{
                          paddingLeft:
                            menu2?.icon && menu2?.icon !== "" ? "10px" : "0px",
                          fontSize: menu2?.case === "sub" ? "15px" : "14px",
                          fontWeight: menu2?.case === "sub" ? "bold" : "normal",
                          width: "100%",
                          position: "relative", // Add position relative to the <p> element
                          display: "inline-block", // Change display to inline-block
                          alignItems: "center",
                        }}
                      >
                        {menu2?.label}
                        {menu2?.label === "Certificates" && hasNotification && (
                          <span
                            style={{
                              position: "absolute",
                              width: "10px",
                              height: "10px",
                              backgroundColor: "red",
                              borderRadius: "50%",
                            }}
                          />
                        )}
                        {menu2?.sons?.length > 0 && (
                          <div
                            id="svg"
                            style={{
                              width: "30px",
                              height: "30px",
                              marginLeft: "2px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CaretDownOutlined
                              style={{
                                position: "relative",
                                top: "1px",
                                left: "2px",
                                transition: "all 1s",
                                transform: objectBulSideBar[menu2?.label]?.bul
                                  ? "rotate(0deg)"
                                  : "rotate(-90deg)",
                                padding: "20px",
                              }}
                            />
                          </div>
                        )}
                      </p>
                    )}
                  </Link>
                </div>
                {menu2?.sons?.length > 0 && (
                  <>
                    {containerSons(
                      menu2?.sons,
                      menu2?.label,
                      menu2?.height,
                      menu2
                    )}
                  </>
                )}
              </>
            );
          } else {
            if (
              userProfile.email === "enrique@hackinghr.io" ||
              userProfile.email === "morecontrol64@gmail.com" ||
              userProfile.email === "mkeivy.mora@gmail.com" ||
              userProfile.email === "erubio2013@gmail.com" ||
              userProfile.email === "barbara@hackinghr.io"
              // ||
              // userProfile.email === "test@gmail.com"
            ) {
              return (
                <>
                  <div
                    key={index}
                    className="listSideBar"
                    style={{
                      marginTop: menu2?.case === "sub" ? "17px" : "8px",
                      marginBottom: menu2?.case === "sub" ? "17px" : "8px",
                      background:
                        typeStyle === "black"
                          ? location.pathname === menu2?.url
                            ? "#FFD1C2"
                            : "transparent"
                          : location.pathname === menu2?.url
                          ? "#FFD1C2"
                          : "#ffffff",
                    }}
                  >
                    <Link
                      to="#"
                      className="linkListSideBar"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        if (
                          menu2?.sons?.length > 0 &&
                          findParent(e.target, "div").id === "svg"
                        ) {
                          collapseSitutaion(menu2?.label);
                        } else {
                          onClickMenu(e, menu2?.url);
                          history.push(
                            (menu2?.noredirect === true) 
                              ? '#'
                              : location.pathname !== menu2?.url
                                ? menu2?.url
                                : `${menu2?.url}?update=${true}`
                          );
                        }
                      }}
                    >
                      {menu2?.icon && (
                        <>
                          {typeof menu2?.icon === "function" ? (
                            menu2.icon(
                              typeStyle === "black"
                                ? location.pathname === menu2?.url
                                  ? "black"
                                  : "white"
                                : "black"
                            )
                          ) : (
                            <img
                              alt="nose"
                              style={{ width: "24px", height: "24px" }}
                              src={menu2?.icon}
                            />
                          )}
                        </>
                      )}
                      <p
                        className={
                          typeStyle === "black"
                            ? location.pathname === menu2?.url
                              ? "pListSideBar"
                              : "pListSideBarBlack"
                            : "pListSideBar"
                        }
                        style={{
                          paddingLeft:
                            menu2?.icon && menu2?.icon !== "" ? "10px" : "0px",
                          fontSize: menu2?.case === "sub" ? "15px" : "14px",
                          fontWeight: menu2?.case === "sub" ? "bold" : "normal",
                        }}
                      >
                        {menu2?.label}
                        {menu2?.sons?.length > 0 && (
                          <div
                            id="svg"
                            style={{
                              width: "30px",
                              height: "30px",
                              marginLeft: "2px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CaretDownOutlined
                              style={{
                                position: "relative",
                                top: "1px",
                                left: "2px",
                                transition: "all 1s",
                                transform: objectBulSideBar[menu2?.label]?.bul
                                  ? "rotate(0deg)"
                                  : "rotate(-90deg)",
                              }}
                            />
                          </div>
                        )}
                      </p>
                    </Link>
                  </div>
                  {menu2?.sons?.length > 0 && (
                    <>
                      {containerSons(
                        menu2?.sons,
                        menu2?.label,
                        menu2?.height,
                        menu2
                      )}
                    </>
                  )}
                </>
              );
            } else {
              return <div key={index} style={{ display: "none" }}></div>;
            }
          }
        })}
      </div>
    );
  };

  return (
    <>
      {TopMenuList(allNewCourses, objectBulSideBar, userProfile)?.map((menu, index) => {
        return (
          <React.Fragment key={index}>
            <div
              className="listSideBar"
              style={{
                marginTop: menu?.case === "sub" ? "17px" : "8px",
                marginBottom:
                  menu?.case === "sub"
                    ? menu?.sons?.length > 0
                      ? objectBulSideBar[menu?.label]?.bul
                        ? "15px"
                        : "4px"
                      : "17px"
                    : "8px",
                background:
                  typeStyle === "black"
                    ? location.pathname === menu?.url
                      ? "#FFD1C2"
                      : "transparent"
                    : location.pathname === menu?.url
                    ? "#FFD1C2"
                    : "#ffffff",
              }}
            >
              <Link
                to={
                  (menu.noredirect === true) 
                    ? '#'
                    : location.pathname !== menu?.url
                      ? menu?.url
                      : `${menu?.url}?update=${true}`
                }
                className="linkListSideBar"
                onClick={(e) => {
                  if (menu?.sons?.length > 0) {
                    collapseSitutaion(menu?.label);
                  } else {
                    onClickMenu(e, menu?.url);
                  }
                }}
              >
                {menu?.icon && (
                  <>
                    {typeof menu?.icon === "string" ? (
                      <img
                        alt="nose"
                        style={{ width: "24px", height: "24px" }}
                        src={menu?.icon}
                      />
                    ) : (
                      menu.icon()
                    )}
                  </>
                )}
                <p
                  className={
                    typeStyle === "black"
                      ? location.pathname === menu?.url
                        ? "pListSideBar"
                        : "pListSideBarBlack"
                      : "pListSideBar"
                  }
                  style={{
                    paddingLeft:
                      menu?.icon && menu?.icon !== "" ? "10px" : "0px",
                    fontSize: menu?.case === "sub" ? "15px" : "14px",
                    fontWeight: menu?.case === "sub" ? "bold" : "normal",
                  }}
                >
                  {menu?.label}
                  {menu?.sons?.length > 0 && (
                    <CaretDownOutlined
                      style={{
                        position: "relative",
                        top: "1px",
                        left: "2px",
                        transition: "all 1s",
                        transform: objectBulSideBar[menu?.label]?.bul
                          ? "rotate(0deg)"
                          : "rotate(-90deg)",
                      }}
                    />
                  )}
                </p>
              </Link>
            </div>
            {menu?.sons?.length > 0 && (
              <>{containerSons(menu?.sons, menu?.label, menu?.height, menu)}</>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default SideBarsChildren;