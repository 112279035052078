export default [
  {
    label: "Edit",
    value: "edit",
  },
  {
    label: "Delete",
    value: "delete",
  },
  {
    label: "Share to Learning Library",
    value: 'share',
  },
];
