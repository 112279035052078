import React, { useEffect, useState } from "react";
import "./style.scss";
import { connect } from "react-redux";
import { actions as crashCourseActions } from "redux/actions/crash-course-actions";
import { useHistory } from "react-router-dom";

import CardLeadership from "components/CardLeadershipProgram";
import { INTERNAL_LINKS } from "enum";
import { homeSelector } from "redux/selectors/homeSelector";
import { crashCourseSelector } from "redux/selectors/crashCourseSelector";

const Leadership = ({
  userProfile,
  allCrashCourses,
  getAllCrashCourses,
  setCrashCourse,
}) => {
  const [tab, setTab] = useState(1);
  const history = useHistory();

  useEffect(() => {
    if(userProfile?.id !== -1 && userProfile.id){
      if((userProfile || {}).email !== "enrique@hackinghr.io" && (userProfile || {}).email !== "morecontrol64@gmail.com"){
        history.push(INTERNAL_LINKS.HOME)
      }
    }
  },[userProfile])

  useEffect(() => {
    getAllCrashCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const content = (data, cases) => {
    let newData = cases === 1 
      ? data?.filter(e => e?.CrashCourseProgresses?.[0]?.pagesSave !== 'completed')
      : cases === 2
        ? data?.filter(e => e?.CrashCourseProgresses?.length > 0 && e?.CrashCourseProgresses?.[0]?.pagesSave !== 'completed') 
        : data?.filter(e => e?.CrashCourseProgresses?.[0]?.pagesSave === 'completed')

    return newData?.map((e, index) => {
      return (
        <CardLeadership
          key={index}
          certificate={e}
          onClick={() => {
            setCrashCourse(e);
          }}
          userProfile={userProfile}
          userRegistered={e?.CrashCourseProgresses?.length > 0}
          userCompleted={e?.CrashCourseProgresses?.[0]?.pagesSave === 'completed'}
        />
      );
    })
  }

  return (
    <div className="container-certificates">
      <div className="container-one">
        <div className="container-two">
          <div className="container-title-certificate"></div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "20px",
              height: "150px"
            }}
          >
            <div 
              className={tab === 1 ? "title-list-certificates" : "title-list-crashcourse2"}
              onClick={() => setTab(1)}
            >
              Crash Courses Available
            </div>
            <div className={tab === 2 ? "title-list-certificates" : "title-list-crashcourse2"}
               onClick={() => {
                // if(allCrashCourses?.some(e => e?.CrashCourseProgresses?.length > 0 && e?.CrashCourseProgresses?.[0]?.pagesSave !== 'completed')){
                  setTab(2)
                // }
              }}
            >
              In Progress
              {/* {allCrashCourses?.some(e => e?.CrashCourseProgresses?.length > 0 && e?.CrashCourseProgresses?.[0]?.pagesSave !== 'completed')  
                ? 'shows crash courses in progress' : 'You do not have any crash course in progress'} */}
            </div>
            <div className={tab === 3 ? "title-list-certificates" : "title-list-crashcourse2"}
              onClick={() => {
                // if(allCrashCourses?.some(e => e?.CrashCourseProgresses?.[0]?.pagesSave === 'completed')){
                  setTab(3)
                // }
              }}
            >
              Completed
              {/* {allCrashCourses?.some(e => e?.CrashCourseProgresses?.[0]?.pagesSave === 'completed') 
                ? 'shows completed' : 'You have not completed any crash course yet'} */}
            </div>
          </div>
        </div>
      </div>
      <div className="container-list-certificates">
        {content(allCrashCourses,tab)}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userProfile: homeSelector(state).userProfile,
  allCrashCourses: crashCourseSelector(state).allCrashCourses,
});

const mapDispatchToProps = {
  ...crashCourseActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Leadership);
