import { put, fork, takeLatest, call } from "redux-saga/effects";

import {
    constants as liveChatConstants,
    actions as liveChatActions,
} from "../actions/live-chat-actions";
import { actions as homeActions } from "../actions/home-actions";
import { get, order } from "../../api/module/liveChat";

export function* getAllLiveChatSagas() {
    yield put(homeActions.setLoading(true));

    try {
        const response = yield call(get);

        if (response.status === 200) {
            yield put(liveChatActions.setChatLive(response.data.data));
        }

        yield put(homeActions.setLoading(false));
    } catch (error) {
        console.log(error);
        yield put(homeActions.setLoading(false));
    }
}

export function* orderChatLiveSagas({ payload }) {
    const { data } = payload
    yield put(homeActions.setLoading(true));

    try {
        const response = yield call(order, data);

        if (response.status === 200) {
            if (payload.callback) {
                payload.callback("");
            }
        }
    } catch (error) {
        console.log(error);
        yield put(homeActions.setLoading(false));
    } finally {
        yield put(homeActions.setLoading(false));
    }
}

function* watchLogin() {
    yield takeLatest(liveChatConstants.GET_ALL_LIVE, getAllLiveChatSagas);
    yield takeLatest(liveChatConstants.ORDER_CHAT_LIVE, orderChatLiveSagas)
}

export const liveChatSaga = [fork(watchLogin)];
