import React from "react";
import moment from "moment";
import { Menu } from "antd";

const downloadDropdownOptions = (
  startTime,
  endTime,
  day,
  link,
  data,
  councilEvent,
  pN,
  startTimeP,
  endTimeP,
) => {
  const onClickAddGoogleCalendar = (startDate, endDate, link, title) => {
    const getEventLink = (event, linkD) => {
      if (event.bulLinkForEachDay === "true") {
        return linkD;
      } else {
        return event.link;
      }
    };

    let googleCalendarUrl = `http://www.google.com/calendar/event?action=TEMPLATE&text=${
      title ? title : data?.title
    }&dates=${startDate}/${endDate}&details=Link to connect: ${getEventLink(
      data,
      link
    )}&location=${
      data?.location
    }&trp=false&sprop=https://www.hackinghrlab.io/&sprop=name:`;

    window.open(googleCalendarUrl, "_blank");
  };

  const onClickAddYahooCalendar = (startDate, endDate, link, title) => {
    const getEventLink = (event, linkD) => {
      if (event.bulLinkForEachDay === "true") {
        return linkD;
      } else {
        return event.link;
      }
    };

    let yahooCalendarUrl = `https://calendar.yahoo.com/?v=60&st=${startDate}&et=${endDate}&DESC=Link to connect: ${getEventLink(
      data,
      link
    )}&title=${title ? title : data?.title}&in_loc=${data?.location}`;

    yahooCalendarUrl = yahooCalendarUrl
      .split("")
      .map((char) => (char === " " ? "%2B" : char))
      .join("");

    window.open(yahooCalendarUrl, "_blank");
  };

  const onClickDownloadCalendar = (day, title, startDate, endDate, link) => {
    const getEventLink = (event, linkD) => {
      if (event.bulLinkForEachDay === "true") {
        return linkD;
      } else {
        return event.link;
      }
    };

    console.log(`${process.env.REACT_APP_API_ENDPOINT}/public/event/ics/${
      data.id
    }?day=${day}&userTimezone=${
      data?.timezone
    }&startTimeD=${startTimeP}&endTimeD=${endTimeP}&title=${title}&link=${getEventLink(
      data,
      link
    )}`)

    //http://localhost:3002/public/event/ics/287?day=0&userTimezone=America/Los_Angeles&startTimeD=2028-04-05T10:00:00Z&endTimeD=undefined&title=2028-04-05T09:00:00Z&link=https://www.linkedin.com/video/event/urn:li:ugcPost:7059186872811487232/
    //http://localhost:3002/public/event/ics/287?day=0&userTimezone=America/Los_Angeles&startTimeD=2028-04-05T09:00:00Z&endTimeD=2028-04-05T10:00:00Z&title=undefined&link=https://www.linkedin.com/video/event/urn:li:ugcPost:7059186872811487232/

    if (title) {
      window.open(
        `${process.env.REACT_APP_API_ENDPOINT}/public/event/ics/${
          data.id
        }?day=${day}&userTimezone=${
          data?.timezone
        }&startTimeD=${startTimeP}&endTimeD=${endTimeP}&title=${title}&link=${getEventLink(
          data,
          link
        )}`,
        "_blank"
      );
    } else {
      window.open(
        `${process.env.REACT_APP_API_ENDPOINT}/public/event/ics/${
          data.id
        }?day=${day}&userTimezone=${data?.timezone}&link=${getEventLink(
          data,
          link
        )}`,
        "_blank"
      );
    }
  };

  const handleOnClick = ({ item, key, domEvent }) => {
    domEvent.stopPropagation();
    domEvent.preventDefault();

    const [startTime, endTime, day, link] = item.props.value;

    const convertedStartTime = startTime.format("YYYYMMDDTHHmmss");

    const convertedEndTime = endTime.format("YYYYMMDDTHHmmss");

    const panel = councilEvent?.CouncilEventPanels?.filter((e) => {
      return (
        moment(e?.startDate, "YYYY-MM-DDTHH:mm:ssZ")
          .utc()
          .format("YYYYMMDDT") === startTime.format("YYYYMMDDT")
      );
    });

    switch (key) {
      case "1":
        onClickDownloadCalendar(
          day,
          pN || (panel || [])[0]?.panelName,
          convertedStartTime,
          convertedEndTime,
          link
        );
        break;
      case "2":
        onClickAddGoogleCalendar(
          convertedStartTime,
          convertedEndTime,
          link,
          pN || (panel || [])[0]?.panelName
        );
        break;
      case "3":
        onClickAddYahooCalendar(
          convertedStartTime,
          convertedEndTime,
          link,
          pN || (panel || [])[0]?.panelName
        );
        break;
      default:
    }
  };

  return (
    <Menu onClick={handleOnClick}>
      <Menu.Item key="1" value={[startTime, endTime, day, link]}>
        Download ICS File
      </Menu.Item>
      <Menu.Item key="2" value={[startTime, endTime, day, link]}>
        Add to Google Calendar
      </Menu.Item>
      <Menu.Item key="3" value={[startTime, endTime, day, link]}>
        Add to Yahoo Calendar
      </Menu.Item>
    </Menu>
  );
};

export default downloadDropdownOptions;
