import React from "react";
// import { connect } from "react-redux";
import { INTERNAL_LINKS } from "enum";
import { Route } from "react-router-dom";
import { PrivateRoute } from "components";
import BlogPage from "pages/Blog";

const startRouteBlogs2 = () => {

  return (
    <>
      {localStorage.getItem("community") !== null &&
        <>
            <PrivateRoute
                path={`${INTERNAL_LINKS.CHANNELS}/:id${INTERNAL_LINKS.BLOGS}/:id`}
                exact
                render={(props) => <BlogPage {...props} />}
            />
        </>
      }
      {localStorage.getItem("community") === null &&
        <>
            <Route
                path={`${INTERNAL_LINKS.CHANNELS}/:id${INTERNAL_LINKS.BLOGS}/:id`}
                exact
                render={(props) => <BlogPage {...props} />}
            />
        </>
      }
    </>
  );
};

export default startRouteBlogs2;