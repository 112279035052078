import React from "react";
import { connect } from "react-redux";
import { Dropdown, Menu, Space } from "antd"
import { DownOutlined } from "@ant-design/icons";
import { homeSelector } from "redux/selectors/homeSelector";
import { convertToLocalTime } from "utils/format";
import moment from "moment";
import "./style.scss";

const ButtonsCalendar = ({
    userProfile,
    panels,
    slug
}) => {
    const { id, title, startTime, endTime, timezone } = panels

    // const [modal, setModal] = useState(false)

    const convertedStartTime = convertToLocalTime(
        startTime,
        timezone
    );

    const convertedEndTime = convertToLocalTime(
        endTime,
        timezone
    );

    const downloadDropdownOptions = () => (
        <Menu style={{ position: "relative", bottom: "70px" }}>
          <Menu.Item key="1">
            <a href="/#" style={{textDecoration: 'none'}} onClick={(e) => onClickDownloadCalendar(e)}>
              Download ICS File
            </a>
          </Menu.Item>
          <Menu.Item key="2">
            <a href="/#" style={{textDecoration: 'none'}} onClick={(e) => onClickAddGoogleCalendar(e)}>
              Add to Google Calendar
            </a>
          </Menu.Item>
          <Menu.Item key="3">
            <a href="/#" style={{textDecoration: 'none'}} onClick={(e) => onClickAddYahooCalendar(e)}>
              Add to Yahoo Calendar
            </a>
          </Menu.Item>
        </Menu>
      );

    const userTimezone = moment.tz.guess();

    const onClickDownloadCalendar = (e) => {
        e.preventDefault();
        e.stopPropagation();
        window.open(
            `${process.env.REACT_APP_API_ENDPOINT}/public/course/ics/${id}?userTimezone=${userTimezone}&slug=${slug}`,
            "_blank"
        );
    };

    const onClickAddGoogleCalendar = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let googleCalendarUrl = `http://www.google.com/calendar/event?action=TEMPLATE&text=${encodeURIComponent(
            title
        )}&dates=${convertedStartTime.format("YYYYMMDDTHHmmss")}/${convertedEndTime.format("YYYYMMDDTHHmmss")}&details=${encodeURIComponent(
            ''
        )}&location=https://hackinghrlab.io/hr-certificate-programs/${slug}/&trp=false&sprop=https://www.hackinghrlab.io/&sprop=name:`;
        window.open(googleCalendarUrl, "_blank");
    };

    const onClickAddYahooCalendar = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let yahooCalendarUrl = `https://calendar.yahoo.com/?v=60&st=${convertedStartTime.format("YYYYMMDDTHHmmss")}&et=${convertedEndTime.format("YYYYMMDDTHHmmss")}&title=${encodeURIComponent(
            title
        )}&desc=${encodeURIComponent(
            ''
        )}&in_loc=https://hackinghrlab.io/hr-certificate-programs/${slug}/`;
        window.open(yahooCalendarUrl, "_blank");
    };

    return (
        <>
            {userProfile?.id !== -1 ? <div style={{ position: 'relative' }}>
                <button
                    className={"button-download"}
                    style={{ marginTop: '10px' }}
                    onClick={(e) => { e.preventDefault() }}
                >
                    DOWNLOAD CALENDAR REMINDER
                </button>
                <Dropdown
                    overlay={downloadDropdownOptions}
                >
                    <span
                        style={{ position: "absolute", width: "100%", top: "10px", left: "0px", opacity: "0%", height: "35px", zIndex: "1000" }}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                    >
                        <Space>
                            <DownOutlined />
                        </Space>
                    </span>
                </Dropdown>
            </div> : <button
                className={"button-download"}
                style={{ marginTop: '10px' }}
                // onClick={(e) => { setModal(true) }}
            >
                DOWNLOAD CALENDAR REMINDER
            </button>}
        </>
    );
};

const mapStateToProps = (state) => ({
    userProfile: homeSelector(state).userProfile,
  });
  
  const mapDispatchToProps = {
  };
  
  
  export default connect(mapStateToProps, mapDispatchToProps)(ButtonsCalendar);