import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment-timezone";
// import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import clsx from "clsx";
import { notification } from "antd";
import converter from "number-to-words";
import html2canvas from "html2canvas";
import jsPdf from "jspdf";
import { useParams, useHistory } from "react-router-dom";
import { Modal } from "antd";
import IconLogo from "images/logo-sidebar.svg";
import { CloseCircleFilled, CloseOutlined } from "@ant-design/icons";
// import TimeZoneList from "enum/TimezoneList";

import {
  // Tabs,
  // EventFilterPanel,
  CustomButton,
} from "components";
import EventPage from "containers/EventPage";
import { useLocation } from "react-router-dom";
import { MONTH_NAMES, INTERNAL_LINKS } from "enum";
import EventList from "./EventList";
// import EventListPast from "./EventListPast";
import {
  getAllGrows,
  addToMyEventList,
  removeFromMyEventList,
  claimEventAttendance,
  claimEventCredit,
  verifySuscribedUser,
  suscriptionSendingBlue,
  getIdBoxes,
} from "redux/actions/event-actions";
import { getIdBoxesGridEndPoint } from "../../api";
import {
  setLoading,
  attendToGlobalConference,
} from "redux/actions/home-actions";
import {
  getAdvertisementsTodayByPage,
  getAdvertisementById,
  createAdvertisementClick,
} from "redux/actions/advertisment-actions";
import { getAllLibraries } from "redux/actions/myLearning-actions";
import { getEventVerification } from "redux/actions/event-verfication-code";
import { advertisementSelector } from "redux/selectors/advertisementsSelector";
import { eventSelector } from "redux/selectors/eventSelector";
import { homeSelector } from "redux/selectors/homeSelector";
import { envSelector } from "redux/selectors/envSelector";
import { myLearningSelector } from "redux/selectors/myLearningSelector";
import { eventConfirmCodeSelector } from "redux/selectors/eventConfirmCodeSelector";
// import EventFilterDrawer from "./EventFilterDrawer";
import EventClaimModal from "./EventClaimModal";

import ImgCertificateStamp from "images/img-certificate-stamp.png";
import ImgHHRLogo from "images/img-certificate-logo.png";
import ImgSignature from "images/img-signature.png";
import { actions as councilEventActions } from "redux/actions/council-events-actions";
import { councilEventSelector } from "redux/selectors/councilEventSelector";

import { convertBlobToBase64 } from "utils/format";

import "./style.scss";
import PopupPremiumUser from "components/Popups/PopupPremiumUser";

const GrowGroups = ({
  allGrows,
  updatedEvent,
  userProfile,
  getAllGrows,
  addToMyEventList,
  attendToGlobalConference,
  removeFromMyEventList,
  // claimEventAttendance,
  claimEventCredit,
  setLoading,
  getAdvertisementsTodayByPage,
  getAdvertisementById,
  advertisementsByPage,
  advertisementById,
  isAdPreview = false,
  createAdvertisementClick,
  boxesSaves,
  verifySuscribedUser,
  suscriptionSendingBlue,
  getEventVerification,
  allGrowsUsersSketch,
  // eventVerificationCode,
  isMobile,
}) => {
  // const [filteredEvents, setFilteredEvents] = useState([]);
  // const [visibleFilter, setVisibleFilter] = useState(false);
  // const [currentTab, setCurrentTab] = useState("0");
  // const [filterParams, setFilterParams] = useState({});
  // const [pastFilterData, setPastFilterData] = useState([]);
  // const [allEventFilterData, setAllEventFilterData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [event, setEvent] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  // const [allCouncilEvents, setAllCouncilEvents] = useState(undefined)
  const [eventForCredit, setEventForCredit] = useState({});
  const [bulModal, setBulModal] = useState(false);
  const [activeMessages, setActiveMessages] = useState("");
  const [boxesCards, setBoxesCards] = useState({});
  const [popupPremiumUser, setPopupPremiumUser] = useState(false);

  const {
    // search,
    hash,
  } = useLocation();
  // const query = new URLSearchParams(search);
  const history = useHistory();

  // const update = query?.get("update");

  const { id } = useParams();

  useEffect(() => {
    //access limited
    if (
      userProfile.email !== undefined &&
      userProfile.email !== "enrique@hackinghr.io" &&
      userProfile.email !== "morecontrol64@gmail.com" &&
      userProfile.email !== "mkeivy.mora@gmail.com" &&
      userProfile.email !== "barbara@hackinghr.io" &&
      userProfile.email !== "erubio2013@gmail.com"
      // &&
      // userProfile.email !== "test@gmail.com"
    ) {
      history.push(INTERNAL_LINKS.HOME);
    }
  }, [history, userProfile]);

  useEffect(() => {
    if (hash === "#?update=true") {
      setVisible(false);
      history.push(INTERNAL_LINKS.GROUPGROWS);
    }
  }, [history, hash]);

  useEffect(() => {
    if (isAdPreview) {
      getAdvertisementById(id);
    } else {
      getAdvertisementsTodayByPage("events");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    getEventVerification();
  }, [getEventVerification]);

  const displayAds = (
    <div className="events-advertisement-wrapper">
      {advertisementsByPage?.events?.map((advertisement) => {
        return (
          <div
            className="events-advertisement-wrapper-content"
            key={advertisement?.id}
          >
            <div
              className="advertisement"
              onClick={() => {
                createAdvertisementClick(advertisement?.id);
                window.open(advertisement?.advertisementLink, "_blank");
              }}
            >
              <img
                src={advertisement?.adContentLink}
                alt="advertisement"
                className="advertisement-img"
              />
            </div>
          </div>
        );
      })}
    </div>
  );

  const displayPreviewAd = isAdPreview && (
    <div className="events-advertisement-wrapper-preview">
      <div
        className="advertisement"
        onClick={() =>
          window.open(advertisementById?.advertisementLink, "_blank")
        }
      >
        <img
          src={advertisementById?.adContentLink}
          alt="advertisement"
          className="advertisement-img"
        />
      </div>
    </div>
  );

  const DataFormat = "YYYY.MM.DD hh:mm A";

  const addMyEvents = (event, image) => {
    if (userProfile.memberShip === "premium") {
      event.imageEmail = image;
      const timezone = moment.tz.guess();

      if (event.going) {
        addToMyEventList(
          event,
          timezone,
          "growgroups",
          (e, textCloseToRegister) => {
            if (textCloseToRegister === "closeToRegister") {
              notification.info({
                message: "Limited Registration",
                description: "Registration for this GrowGroup is limited.",
              });
            } else {
              notification.success({
                message: "GrowGroup successfully added",
                description:
                  "An email has been sent to you with more information.",
              });
            }
            verifySuscribedUser((data) => {
              if (data) {
                setBulModal(true);
              }
            });
            // getAllGrows();
          }
        );
        if (event.isAnnualConference === 1) {
          attendToGlobalConference();
        }
      } else {
        removeFromMyEventList(event, "growgroups");
        if (event?.isAnnualConference === 1) {
          attendToGlobalConference();
        }
      }
    } else {
      return setPopupPremiumUser(true);
    }
  };

  const onEventClick = (event) => {
    setVisible(true);
    setEvent({
      ...event,
      day: moment(event.date, DataFormat).date(),
      month: MONTH_NAMES[moment(event.date, DataFormat).month()],
    });
  };

  // const onConfirmAttendance = (event) => {
  //   claimEventAttendance(event.id);
  // };

  const onConfirmCredit = (event) => {
    setEventForCredit(event);
    setModalVisible(true);
  };

  const onClaimCredit = async () => {
    const pdf = await generatePDF();

    claimEventCredit(eventForCredit.id, pdf, (err) => {
      if (err) {
        notification.error({
          message: "Error",
          description: (err || {}).msg,
        });
      } else {
        notification.info({
          message: "Email was sent successfully.",
        });
        setModalVisible(false);
      }
    });
  };

  const orderListEvents = (data) => {
    let arrayOrderTime = data.sort((a, b) => {
      let aTime = moment(a.startDate, "YYYYMMDDHHmm").format("YYYYMMDDHHmm");
      let bTime = moment(b.startDate, "YYYYMMDDHHmm").format("YYYYMMDDHHmm");

      return Number(aTime) - Number(bTime);
    });

    // if (filterParams.date !== null && filterParams.date !== undefined) {
    //   arrayOrderTime = arrayOrderTime.filter((data) =>
    //     moment(data.startDate, "YYYY-MM-DD").format("YYYY-MM-DD") === moment(filterParams?.date, "YYYY-MM-DD")?.format("YYYY-MM-DD")
    //   )
    // }

    return arrayOrderTime;
  };

  // const orderListEvents2 = (data) => {

  //   let arrayOrderTime = data.sort((a, b) => {

  //     let aTime = moment(a.startDate, "YYYYMMDDHHmm").format("YYYYMMDDHHmm")
  //     let bTime = moment(b.startDate, "YYYYMMDDHHmm").format("YYYYMMDDHHmm")

  //     return Number(bTime) - Number(aTime)

  //   })

  //   if (filterParams.date !== null && filterParams.date !== undefined) {
  //     arrayOrderTime = arrayOrderTime.filter((data) =>
  //       moment(data.startDate, "YYYY-MM-DD").format("YYYY-MM-DD") === moment(filterParams?.date, "YYYY-MM-DD")?.format("YYYY-MM-DD")
  //     )
  //   }

  //   return arrayOrderTime
  // }

  // const orderListPersonalEvents = (data) => {

  //   const data1 = data.filter((item) => {
  //     let flag = false;

  //     if (item.users?.includes(String(userProfile.id)) || item.users?.includes(userProfile.id)) {
  //       flag = true;
  //     }

  //     return flag;
  //   });

  //   return data1;

  // }

  // const filterEvents = (data) => {

  //   let newD = data.filter((dat) => {
  //     return dat.categories !== null &&
  //       dat.title !== null &&
  //       dat.image2 !== null &&
  //       dat.date !== null &&
  //       dat.date2 !== null &&
  //       dat.link !== null &&
  //       dat.timezone !== null
  //   })

  //   return newD
  // }

  const searchIfAttendOrNo = (data) => {
    let newMap = data?.map((event) => {
      if (
        event.status !== undefined &&
        event.status !== "attend" &&
        event.status !== "going" &&
        event.status !== "past"
      ) {
        return {
          ...event,
          status: "attend",
        };
      } else {
        return event;
      }
    });

    return newMap;
  };

  const filterIsOnlyForCouncilMember = (allGrows) => {
    const filteredGrows = allGrows.filter((grow) => {
      if (
        (grow.onlyHRBPsMembers &&
          userProfile.isBusinessPartner === "accepted") ||
        (grow.onlySeniorLeadersMembers && userProfile.councilMember) ||
        (grow.onlyHRBPsMembers === false &&
          grow.onlySeniorLeadersMembers === false)
      ) {
        return true;
      }

      return false;
    });

    return filteredGrows;
  };

  useEffect(() => {
    let allGrowsD = orderListEvents([...searchIfAttendOrNo(allGrows)]);

    let BoxesFix;

    allGrowsD.map(async (data, index) => {
      const response = await getIdBoxesGridEndPoint({
        idReference: data.id,
        typeReference: "Ticket",
      });

      if (response.status === 200) {
        BoxesFix = { ...BoxesFix, [`${data.id}`]: response.data.BoxGrid };

        if (allGrowsD.length === index + 1) {
          setBoxesCards(BoxesFix);
        }
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allGrows]);

  const onEventDrawerClose = () => {
    setVisible(false);
  };

  const getPerodOfEvent = (startDate, endDate) => {
    const duration = moment.duration(moment(endDate).diff(moment(startDate)));

    return duration.asHours();
  };

  const period = getPerodOfEvent(
    eventForCredit.startDate,
    eventForCredit.endDate
  );

  const generatePDF = async () => {
    setLoading(true);
    const domElement = document.getElementById("certificate-panel");
    const canvas = await html2canvas(domElement, { scale: 4 });

    const width = domElement.clientWidth;
    const height = domElement.clientHeight;

    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPdf({
      orientation: "landscape",
      format: [2000, (2000 / width) * height],
      unit: "px",
      hotfixes: ["px_scaling"],
      precision: 32,
    });

    pdf.addImage(
      imgData,
      "jpeg",
      0,
      0,
      2000,
      (2000 / width) * height,
      "",
      "SLOW"
    );

    const blobPdf = pdf.output("blob");

    setLoading(false);
    return await convertBlobToBase64(blobPdf);
  };

  useEffect(() => {
    if (!allGrows || allGrows.length === 0) {
      getAllGrows();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (event && updatedEvent && event.id === updatedEvent.id) {
      setEvent({
        ...updatedEvent,
        day: moment(updatedEvent.date, DataFormat).date(),
        month: MONTH_NAMES[moment(updatedEvent.date, DataFormat).month()],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedEvent]);

  return (
    <div className="grow-page">
      {activeMessages !== "" && (
        <div
          className="container-messages-general2"
          style={{ zIndex: "100000" }}
          onClick={() => {
            setActiveMessages("");
          }}
        >
          <div className="messages">
            {activeMessages}
            <CloseOutlined
              style={{ marginLeft: "20px", cursor: "pointer" }}
              onClick={() => {
                setActiveMessages("");
              }}
            />
          </div>
        </div>
      )}

      <div className="grow-page-wrapper">
        <div className="grow-page-containerr">
          {/* <Tabs
            data={TabData}
            current={currentTab}
            onChange={setCurrentTab}
          /> */}
          <>
            {!visible ? (
              <EventList
                data={orderListEvents([
                  ...searchIfAttendOrNo(filterIsOnlyForCouncilMember(allGrows)),
                ])}
                allEventsUsersSketch={allGrowsUsersSketch}
                boxes={boxesCards}
                onAttend={addMyEvents}
                setLoading={setLoading}
                onClick={onEventClick}
                userProfile={userProfile}
                showFilter={() => {
                  // setVisibleFilter(true)
                }}
                setActiveMessages={(data) => {
                  setActiveMessages(data);
                }}
              />
            ) : (
              <EventPage
                visible={visible}
                allGrows={orderListEvents([
                  ...searchIfAttendOrNo(filterIsOnlyForCouncilMember(allGrows)),
                ])}
                isGrowGroups={true}
                onAttend={addMyEvents}
                eventData={event}
                allEventsUsersSketch={allGrowsUsersSketch}
                onClose={onEventDrawerClose}
                onConfirmCredit={onConfirmCredit}
                allCouncilEvents={[]}
              />
            )}
          </>
        </div>
        {displayAds}
        {displayPreviewAd}
      </div>
      <EventClaimModal
        visible={modalVisible}
        title="HR Credit Offered"
        destroyOnClose={true}
        data={eventForCredit}
        onClaim={onClaimCredit}
        onCancel={() => setModalVisible(false)}
      />
      {!isEmpty(eventForCredit) && (
        <div
          className="event-certificate certificate-page-wrapper"
          id="certificate-panel"
        >
          <div className="certificate">
            <div className="certificate-top">
              <div className="certificate-logo">
                <img src={ImgHHRLogo} alt="sidebar-logo" />
              </div>
              <h3 className="certificate-title">
                Hacking HR's Certificate of Participation
              </h3>
              <h1 className="certificate-username">{`${userProfile.firstName} ${userProfile.lastName}`}</h1>
            </div>
            <div className="certificate-center">
              <h5 className="certificate-text1 organizer">
                {`For Attending ${eventForCredit.organizer} Session:`}
              </h5>
              <h4 className="certificate-text2">{eventForCredit.title}</h4>
              <h5 className="certificate-text1 duration">{`Duration: ${converter.toWords(
                period
              )} Hour${period > 1 ? "s" : ""}`}</h5>
            </div>
            <div className="certificate-bottom">
              <div className="certificate-bottom-sign">
                <h5 className="certificate-text1 date">{`${moment(
                  eventForCredit.startDate
                ).format("MMMM DD, YYYY")}`}</h5>
                <div className="certificate-divider" />
                <h5 className="certificate-text1">Date</h5>
              </div>
              <div className="certificate-bottom-image">
                <img src={ImgCertificateStamp} alt="certificate-img" />
              </div>
              <div className="certificate-bottom-sign">
                <div className="certificate-signature">
                  <img src={ImgSignature} alt="certificate-signature" />
                </div>
                <div className="certificate-divider" />
                <h5 className="certificate-text1 signature">
                  Founder at Hacking HR
                </h5>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        className={clsx("custom-modal")}
        wrapClassName={clsx("custom-modal-wrap")}
        title={
          <div className="custom-modal-title">
            <h3>Thank you for registering to attend this event!</h3>
            <div className="custom-modal-logo">
              <img src={IconLogo} alt="custom-logo" />
            </div>
          </div>
        }
        centered
        onCancel={() => setBulModal(false)}
        visible={bulModal}
        closable={true}
        footer={[]}
        width={"300px"}
        closeIcon={<CloseCircleFilled className="custom-modal-close" />}
      >
        <p>
          Please keep in mind that you must be subscribed to our email list to
          get information (updates, links to connect, etc.) about the event. If
          you are not subscribed to our email list you will not receive further
          information about the event.
        </p>
        <div className="container-buttons">
          <CustomButton
            key="Cancel"
            text="Cancel"
            type="third"
            size="xs"
            className="button-modal"
            style={{ padding: "0px 10px", marginLeft: "10px" }}
            onClick={() => {
              setBulModal(false);
            }}
          />
          <CustomButton
            key="Subscribe to email list"
            text="Subscribe to email list"
            type="primary"
            size="xs"
            className="button-modal"
            style={{ padding: "0px 10px", marginLeft: "10px" }}
            onClick={() => {
              suscriptionSendingBlue(() => {
                setBulModal(false);
              });
            }}
          />
        </div>
      </Modal>
      {popupPremiumUser ? (
        <PopupPremiumUser
          text="You must be a premium member to register here."
          onclick={() => setPopupPremiumUser(false)}
        />
      ) : null}
    </div>
  );
};

GrowGroups.propTypes = {
  title: PropTypes.string,
};

GrowGroups.defaultProps = {
  title: "",
};

const mapStateToProps = (state) => ({
  myEvents: eventSelector(state).myEvents,
  allGrows: eventSelector(state).allGrows,
  allGrowsUsersSketch: eventSelector(state).allGrowsUsersSketch,
  updatedEvent: eventSelector(state).updatedEvent,
  userProfile: homeSelector(state).userProfile,
  allLibraries: myLearningSelector(state).allLibraries,
  eventVerificationCode: eventConfirmCodeSelector(state).eventVerificationCode,
  isMobile: envSelector(state).isMobile,
  ...advertisementSelector(state),
  ...councilEventSelector,
});

const mapDispatchToProps = {
  getAllGrows,
  addToMyEventList,
  removeFromMyEventList,
  attendToGlobalConference,
  claimEventAttendance,
  claimEventCredit,
  suscriptionSendingBlue,
  setLoading,
  getAdvertisementsTodayByPage,
  getAdvertisementById,
  createAdvertisementClick,
  getIdBoxes,
  verifySuscribedUser,
  getAllLibraries,
  getEventVerification,
  ...councilEventActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(GrowGroups);
