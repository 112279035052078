import React, { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import 'chartjs-adapter-moment';
import moment from 'moment-timezone';

const chartOptionsFunction = (chart,xMin,xMax,yMax,key) => {

    let x = (xMin && xMax) ? {
        min: xMin,
        max: xMax
    } : {}
    let y = yMax ? {
        max: yMax
    } : {}

    return {
        responsive: true,
        plugins: {
            legend: {
                display: chart?.datasets?.length > 1,
                position: 'top',
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        let label = context.label || '';
                        let value = context.raw || 0;
                        value = value?.y ? value?.y : value;
                        return `${moment(label,'YYYY-MM-DD HH:mm').format('YYYY-MM-DD')} - ${key}: ${value}`;
                    }
                }
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                min: 0,
                ticks: {
                    stepSize: 1,
                    callback: (value) => {
                        if (Number.isInteger(value)) {
                            return value;
                        }
                    }
                },
                ...y
            },
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                    tooltipFormat: 'YYYY-MM-DD HH:mm'
                },
                ...x
            }
        }
    }
};

const colors = [
    '26, 40, 66',
    '0, 181, 115',
    '255, 209, 194'
];

const borders = [
    '#1A2842',
    '#00b573',
    '#FFD1C2'
];

const LineChartComponent = ({ data }) => {
    const chartRef = useRef(null);
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: []
    });
    const [xMin, setXMin] = useState(null);
    const [xMax, setXMax] = useState(null);
    const [yMax, setYMax] = useState(0);

    // Obtener la zona horaria local
    const localTimeZone = moment.tz.guess();

    useEffect(() => {
        const processData = () => {
            const actions = Object.keys(data);
            const hoursSet = new Set();
            let bulCase = false
            const dataSets = (data[actions[0]] || []).map((e,index) => {
                const actionData = e.data
                // Filtrar y contar las acciones por hora
                const actionCountsByHour = actionData
                    .filter(item => item.action === actions[0])
                    .reduce((counts, item) => {
                        const localDate = moment.tz(item.date, localTimeZone).format('YYYY-MM-DD HH:00');
                        counts[localDate] = (counts[localDate] || 0) + 1;
                        hoursSet.add(localDate);
                        return counts;
                    }, {});

                const sortedHours = Array.from(hoursSet).sort();

                const cumulativeCounts = sortedHours.map((hour) => {
                    return actionCountsByHour[hour] || 0;
                });

                // Si solo hay un punto de datos, crear una línea en lugar de un punto
                if (sortedHours.length === 1) {
                    bulCase = true
                    return {
                        label: e.label ? e.label : actions[0].charAt(0).toUpperCase() + actions[0].slice(1),
                        data: [{ x: sortedHours[0], y: cumulativeCounts[0] }, { x: moment(sortedHours[0]).add(1, 'hour'), y: cumulativeCounts[0] }],
                        fill: true,
                        backgroundColor: (context) => {
                            const chart = context.chart;
                            const { ctx, chartArea } = chart;

                            if (!chartArea) {
                                return null;
                            }

                            const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
                            gradient.addColorStop(0, `rgba(${colors[index]}, 0.5)`);
                            gradient.addColorStop(1, `rgba(${colors[index]}, 0)`);
                            return gradient;
                        },
                        borderColor: borders[index],
                        tension: 0.1
                    };
                }

                // Crear acumulado
                // const accumulatedCounts = cumulativeCounts.reduce((acc, count) => {
                //     const lastCount = acc.length > 0 ? acc[acc.length - 1] : 0;
                //     acc.push(lastCount + count);
                //     return acc;
                // }, []);

                return {
                    label: e.label ? e.label : actions[0].charAt(0).toUpperCase() + actions[0].slice(1),
                    data: cumulativeCounts,
                    fill: true,
                    backgroundColor: (context) => {
                        const chart = context.chart;
                        const { ctx, chartArea } = chart;

                        if (!chartArea) {
                            return null;
                        }

                        const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
                        gradient.addColorStop(0, `rgba(${colors[index]}, 0.5)`);
                        gradient.addColorStop(1, `rgba(${colors[index]}, 0)`);
                        return gradient;
                    },
                    borderColor: borders[index],
                    tension: 0.1
                };
            });

            const allHours = Array.from(hoursSet).sort();
            const maxY = dataSets.reduce((max, dataset) => {
                const datasetMax = Math.max(...dataset.data);
                return datasetMax > max ? datasetMax : max;
            }, 0);
            if(!bulCase){
                setXMin(allHours.length > 0 ? allHours[0] : null);
                setXMax(allHours.length > 0 ? allHours[allHours.length - 1] : null);

                setYMax(maxY);   
            }else{
                setYMax(dataSets?.[0]?.data?.[0]?.y * 2);
            }

            setChartData({
                labels: allHours,
                datasets: dataSets
            });
        };

        processData();
    }, [data, localTimeZone]);

    const chartOptions = chartOptionsFunction(chartData,xMin,xMax,yMax,Object.keys(data)[0] || '');

    return (
        <div>
            <Line ref={chartRef} data={chartData} options={chartOptions} />
        </div>
    );
};

export default LineChartComponent;