import React from "react";

const IconFlask = ({color = 'black', width = '24px', height = '24px'}) => {
  return (
    <div style={{width:width,height:height}}>
        <svg width="36" height="39" viewBox="0 0 36 39" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.69333 1.39237C9.69333 0.623115 10.3132 -0.000488281 11.0778 -0.000488281H24.9222C25.6868 -0.000488281 26.3067 0.623115 26.3067 1.39237C26.3067 2.16162 25.6868 2.78523 24.9222 2.78523H23.5378V9.53031C23.5377 10.3201 23.7596 11.0937 24.178 11.762C24.1782 11.7623 24.1784 11.7626 24.1786 11.763L34.987 28.9838C37.7156 33.3311 34.6113 38.9995 29.5013 38.9995H6.49872C1.38873 38.9995 -1.71559 33.3312 1.01295 28.9838C1.01296 28.9838 1.01294 28.9838 1.01295 28.9838L11.8207 11.7626C12.2397 11.0943 12.4621 10.3203 12.4622 9.53014C12.4622 9.53008 12.4622 9.53019 12.4622 9.53014V2.78523H11.0778C10.3132 2.78523 9.69333 2.16162 9.69333 1.39237ZM15.2311 2.78523V9.53031C15.231 10.8463 14.8606 12.1355 14.1628 13.2486L8.49153 22.2852H27.5081L21.8361 13.2483C21.1388 12.1351 20.7688 10.846 20.7689 9.53014C20.7689 9.53008 20.7689 9.5302 20.7689 9.53014V2.78523H15.2311ZM29.2565 25.0709H6.74325L3.35421 30.4711C1.78929 32.9644 3.5703 36.2138 6.49872 36.2138H29.5013C32.4297 36.2138 34.2107 32.9644 32.6458 30.4711L29.2565 25.0709Z" fill={color}/>
        </svg>
    </div>
  );
};

export default IconFlask;
