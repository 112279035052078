import httpClient from "./httpClient";


export const get = async () => {
    return await httpClient.get(`public/live-chat-controller/`);
}

export const order = (data) => {
    return httpClient.post(`private/live-chat-order/`, { data });
};

