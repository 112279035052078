import axios from "axios";
import storage from "store";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT_INSTANCE || process.env.REACT_APP_API_ENDPOINT
});

instance.interceptors.request.use(
    async (config) => {

      const community = await storage.get("community");

      if (community && community.accessToken) {
        config.headers.Authorization = `Bearer ${community.accessToken}`;
      }
  
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

export { axios as httpClientCron }

export default instance;