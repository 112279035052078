import { put, fork, takeLatest, call } from "redux-saga/effects";
import { notification } from "antd";

import {
  actions as speakerActions,
  constants as speakerConstans,
} from "redux/actions/speaker-actions";
import { actions as homeActions } from "../actions/home-actions";
import {
  allPanelSpeakersEndPonit,
  getAllUserSpeakerEndPoint,
  removeUserSpeakerToPanelEndPoint,
  addUserSpeakerToPanelEndPoint,
  registerUserIfNotAreRegisterConference2023EndPoint,
  getAllPanelsOfOneUserEndPoint,
  getPanelsForIdEndPoint,
  getAllMyPanels,
  addedToPersonalAgendaEndPoint,
  getAllSponsors2023EndPoint,
  getAllParrafsEndPoint,
  getAllMemberSpeakerPanelEndPoint,
  setStatusUserLibraryEndPoint,
  getStatusUserLibraryEndPoint,
  addPanelSpeakersEndPoint,
  editPanelSpeakersEndPonit,
  joinSpeakerAwaitEvent,
  getAllPanelsOfOneGlobalConferenceEndPoint,
  changeStatusEndPoint,
  getAllPanelSpeakersAdminEndPonit,
  deletePanelSpeakersEndPonit,
  joinExternalAwaitEvent,
} from "../../api";

export function* addPanelSpeakerSaga({ payload }) {
  const { panels } = payload;
  yield put(homeActions.setLoading(true));

  try {
    const response = yield call(addPanelSpeakersEndPoint, { panels });

    if (response.status === 200) {
      const { panelsSpeakers } = response.data;

      yield put(speakerActions.updatePanelSpeakersAdd(panelsSpeakers));

      if (payload.callback) {
        payload.callback();
      }

      notification.success({
        message: "Panel add successflly",
      });
    }
  } catch (error) {
    console.log(error);
    notification.error({
      message: "there are a error",
      description: error.response.data.msg,
    });
  } finally {
    yield put(homeActions.setLoading(false));
  }
}

export function* getPanelSpeakerSaga({ payload }) {
  const { type, filters } = payload;

  yield put(homeActions.setLoading(true));

  try {
    const response = yield call(allPanelSpeakersEndPonit, { type });

    if (response.status === 200) {
      const { panelsSpeakers } = response.data;

      yield put(
        speakerActions.updatePanelSpeakers({ panelsSpeakers }, filters)
      );
    }
  } catch (error) {
    console.log(error);
    notification.error({
      message: "ERROR:",
      description: error?.response?.data?.msg,
    });
  } finally {
    yield put(homeActions.setLoading(false));
  }
}

export function* getAllUserSpeakerSaga({ payload }) {
  const { UserId } = payload;

  yield put(homeActions.setLoading(true));

  try {
    const response = yield call(getAllUserSpeakerEndPoint, { UserId });

    if (response.status === 200) {
      const { userSpeakers } = response.data;

      yield put(
        speakerActions.updateAllUserSpeakers({
          userSpeakers,
        })
      );
      yield put(homeActions.setLoading(false));
    }
  } catch (error) {
    console.log(error);
    notification.error({
      message: "ERROR:",
      description: error?.response?.data?.msg,
    });
  } finally {
    yield put(homeActions.setLoading(false));
  }
}

export function* editPanelSpeakers({ payload }) {
  const { panels } = payload;
  yield put(homeActions.setLoading(true));

  try {
    const response = yield call(editPanelSpeakersEndPonit, { panels });

    if (response.status === 200) {
      if (payload.callback) {
        payload.callback();
      }

      notification.success({
        message: "Panel edit successflly",
      });
    }
  } catch (error) {
    console.log(error);
    notification.error({
      message: "there are a error",
      description: error.response.data.msg,
    });
  } finally {
    yield put(homeActions.setLoading(false));
  }
}

export function* removeUserSpeakerToPanelSaga({ payload }) {
  const { data } = payload;

  yield put(homeActions.setLoading(true));

  try {
    const response = yield call(removeUserSpeakerToPanelEndPoint, { data });
    if (response.status === 200) {
      if (payload.callback) {
        payload.callback();
      }

      notification.success({
        message: "Withdrawn successfully",
      });
    }
  } catch (error) {
    console.log(error);
    notification.error({
      message: "ERROR:",
      description: error?.response?.data?.msg,
    });
  } finally {
    yield put(homeActions.setLoading(false));
  }
}

export function* addUserSpeakerToSaga({ payload }) {
  const { data } = payload;

  yield put(homeActions.setLoading(true));

  try {
    const response = yield call(addUserSpeakerToPanelEndPoint, { data });

    if (response.status === 200) {
      const { panelsSpeakers } = response?.data;

      yield put(
        speakerActions.updatePanelSpeakers({
          panelsSpeakers,
        })
      );

      if (payload.callback) {
        payload.callback();
      }

      notification.success({
        message: "Thank you for joining this panel",
      });
    }
  } catch (error) {
    console.log(error);
    notification.error({
      message: "ERROR:",
      description: error?.response?.data?.msg,
    });
  } finally {
    yield put(homeActions.setLoading(false));
  }
}

export function* registerUserIfNotAreRegisterConference2023({ payload }) {
  try {
    const response = yield call(
      registerUserIfNotAreRegisterConference2023EndPoint
    );

    if (response.status === 200) {
      if (payload.callback) {
        payload.callback();
      }
    }
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.msg) {
      notification.error({
        message: "ERROR:",
        description: error?.response?.data?.msg,
      });
    }
  } finally {
    yield put(homeActions.setLoading(false));
  }
}

export function* getAllPanelsOfOneUserSagas({ payload }) {
  const { UserId } = payload;

  yield put(homeActions.setLoading(true));

  try {
    const response = yield call(getAllPanelsOfOneUserEndPoint, { UserId });

    if (response.status === 200) {
      const { userSpeakers } = response.data;
      if (payload.callback) {
        payload.callback();
      }

      yield put(speakerActions.updateAllPanelsOfOneUser(userSpeakers));
    }
  } catch (error) {
    console.log(error);
    notification.error({
      message: "ERROR:",
      description: error?.response?.data?.msg,
    });
  } finally {
    yield put(homeActions.setLoading(false));
  }
}

export function* getPanelsForIdSagas({ payload }) {
  const { PanelId } = payload;

  yield put(homeActions.setLoading(true));

  try {
    const response = yield call(getPanelsForIdEndPoint, { PanelId });

    if (response.status === 200) {
      const { userSpeakers } = response.data;
      if (payload.callback) {
        payload.callback();
      }

      yield put(speakerActions.updatePanelsForId(userSpeakers));
    }
  } catch (error) {
    notification.error({
      message: "there are a error",
      description: error.response.data.msg,
    });
  } finally {
    yield put(homeActions.setLoading(false));
  }
}

export function* getAllPanelsOfOneUserSpeakersSagas({ payload }) {
  yield put(homeActions.setLoading(true));

  try {
    const response = yield call(getAllMyPanels);

    if (response.status === 200) {
      const { userSpeakers } = response.data;
      if (payload.callback) {
        payload.callback();
      }

      yield put(speakerActions.updateAllPanelsOfOneUserSpeakers(userSpeakers));
    }
  } catch (error) {
    console.log(error);
    notification.error({
      message: "ERROR:",
      description: error?.response?.data?.msg,
    });
  } finally {
    yield put(homeActions.setLoading(false));
  }
}

export function* addedToPersonalAgendaSagas({ payload }) {
  const { data } = payload;
  yield put(homeActions.setLoading(true));

  try {
    const response = yield call(addedToPersonalAgendaEndPoint, { data });

    if (response.status === 200) {
      if (payload.callback) {
        payload.callback();
      }
    }
  } catch (error) {
    console.log(error);
    notification.error({
      message: "ERROR:",
      description: error?.response?.data?.msg,
    });
  } finally {
    yield put(homeActions.setLoading(false));
  }
}

export function* getAllSponsors2023Sagas() {
  yield put(homeActions.setLoading(true));

  try {
    const response = yield call(getAllSponsors2023EndPoint);
    if (response.status === 200) {
      const { sponsor } = response.data;

      yield put(speakerActions.updateAllSponsors2023(sponsor));
    }
  } catch (error) {
    console.log(error);
    notification.error({
      message: "ERROR:",
      description: error?.response?.data?.msg,
    });
  } finally {
    yield put(homeActions.setLoading(false));
  }
}

export function* getAllParrafsSagas({ payload }) {
  const { type } = payload;

  yield put(homeActions.setLoading(true));

  try {
    const response = yield call(getAllParrafsEndPoint, { type });

    if (response.status === 200) {
      const { parraf } = response.data;

      yield put(
        speakerActions.updateParraf({
          parraf,
        })
      );
    }
  } catch (error) {
    console.log(error);
    notification.error({
      message: "ERROR:",
      description: error?.response?.data?.msg,
    });
  } finally {
    yield put(homeActions.setLoading(false));
  }
}

export function* getAllMemberSpeakerPanelSagas({ payload }) {
  yield put(homeActions.setLoading(true));

  try {
    const response = yield call(getAllMemberSpeakerPanelEndPoint);

    if (response.status === 200) {
      const { member } = response.data;

      yield put(
        speakerActions.setAllMemberSpeakerPanel({
          member,
        })
      );
    }
  } catch (error) {
    console.log(error);
    notification.error({
      message: "ERROR:",
      description: error?.response?.data?.msg,
    });
  } finally {
    yield put(homeActions.setLoading(false));
  }
}

export function* setStatusUserLibrarySagas({ payload }) {
  yield put(homeActions.setLoading(true));

  try {
    const response = yield call(setStatusUserLibraryEndPoint, payload.data);

    if (response.status === 200) {
      if (payload.callback) {
        payload.callback();
      }
    }
  } catch (error) {
    console.log(error);
    notification.error({
      message: "ERROR:",
      description: error?.response?.data?.msg,
    });
  } finally {
    yield put(homeActions.setLoading(false));
  }
}

export function* getStatusUserLibrarySagas({ payload }) {
  yield put(homeActions.setLoading(true));

  try {
    const response = yield call(getStatusUserLibraryEndPoint);

    if (response.status === 200) {
      const { SpeakerPanelStatuss } = response.data;

      yield put(
        speakerActions.settStatusUserSessionLibrary(SpeakerPanelStatuss)
      );

      if (payload.callback) {
        payload.callback();
      }
    }
  } catch (error) {
    console.log(error);
    notification.error({
      message: "ERROR:",
      description: error?.response?.data?.msg,
    });
  } finally {
    yield put(homeActions.setLoading(false));
  }
}

export function* getAllPanelsOfOneGlobalConferenceSagas({ payload }) {
  yield put(homeActions.setLoading(true));

  try {
    const response = yield call(
      getAllPanelsOfOneGlobalConferenceEndPoint,
      payload.id,
      payload?.isAdmin
    );

    if (response.status === 200) {
      const { speakersPanels } = response.data;

      yield put(
        speakerActions.setAllPanelsOfOneGlobalConference(speakersPanels)
      );

      if (payload.callback) {
        payload.callback();
      }
    }
  } catch (error) {
    console.log(error);
    notification.error({
      message: "ERROR:",
      description: error?.response?.data?.msg,
    });
  } finally {
    yield put(homeActions.setLoading(false));
  }
}

export function* changeStatusSagas({ payload }) {
  const { status, PanelId } = payload;
  yield put(homeActions.setLoading(true));

  try {
    const response = yield call(changeStatusEndPoint, { status, PanelId });

    if (response.status === 200) {
      if (payload.callback) {
        payload.callback();
      }

      notification.success({
        message: "Panel edit successflly",
      });
    }
  } catch (error) {
    console.log(error);
    notification.error({
      message: "there are a error",
      description: error.response.data.msg,
    });
  } finally {
    yield put(homeActions.setLoading(false));
  }
}

export function* joinSpeakerWaitSaga({ payload }) {
  yield put(homeActions.setLoading(true));

  try {
    const response = yield call(joinSpeakerAwaitEvent, payload);

    if (response.status === 200) {
      if (payload.callback) {
        payload.callback();
      }

      notification.success({
        message: "Success",
      });
    } else if (response.status === 202) {
      notification.warn({
        message: response.data.msg,
      });
    }
  } catch (err) {
    console.log(err);
    notification.error({
      message: "Something went wrong.",
    });
  } finally {
    yield put(homeActions.setLoading(false));
  }
}

export function* joinExternalWaitSaga({ payload }) {
  yield put(homeActions.setLoading(true));

  try {
    const response = yield call(joinExternalAwaitEvent, payload);

    if (response.status === 200) {
      if (payload.callback) {
        payload.callback();
      }

      notification.success({
        message: "Success",
      });
    } else if (response.status === 202) {
      notification.warn({
        message: response.data.msg,
      });
    }
  } catch (err) {
    console.log(err);
    notification.error({
      message: "Something went wrong.",
    });
  } finally {
    yield put(homeActions.setLoading(false));
  }
}

export function* getAllPanelSpeakersAdminSagas({ payload }) {
  yield put(homeActions.setLoading(true));

  const { type } = payload;

  try {
    const response = yield call(getAllPanelSpeakersAdminEndPonit, { type });
    if (response.status === 200) {
      const { panelsSpeakers } = response.data;

      yield put(speakerActions.updatePanelSpeakers(panelsSpeakers));
    }
  } catch (error) {
    notification.error({
      message: "there are a error",
      description: error.response.data.msg,
    });
  } finally {
    yield put(homeActions.setLoading(false));
  }
}

export function* deletePanelSpeakers({ payload }) {
  const { PanelId } = payload;
  yield put(homeActions.setLoading(true));

  try {
    const response = yield call(deletePanelSpeakersEndPonit, { PanelId });

    if (response.status === 200) {
      yield put(homeActions.setLoading(false));

      if (payload.callback) {
        payload.callback();
      }
    }
  } catch (error) {
    console.log(error);
    notification.error({
      message: "there are a error",
      description: error.response.data.msg,
    });
  } finally {
    yield put(homeActions.setLoading(false));
  }
}

function* watchLogin() {
  yield takeLatest(
    speakerConstans.GET_ALL_PANELS_OF_A_CONFERENCE,
    getAllPanelsOfOneGlobalConferenceSagas
  );
  yield takeLatest(
    speakerConstans.JOIN_SPEAKERS_WAIT_SAGAS,
    joinSpeakerWaitSaga
  );
  yield takeLatest(
    speakerConstans.JOIN_EXTERNAL_WAIT_SAGAS,
    joinExternalWaitSaga
  );
  yield takeLatest(speakerConstans.GET_PANEL_SPEAKERS, getPanelSpeakerSaga);
  yield takeLatest(speakerConstans.GET_USERS_SPEAKERS, getAllUserSpeakerSaga);
  yield takeLatest(speakerConstans.ADD_PANEL_SPEAKERS, addPanelSpeakerSaga);
  yield takeLatest(
    speakerConstans.REMOVE_USERS_PANEL,
    removeUserSpeakerToPanelSaga
  );
  yield takeLatest(speakerConstans.ADD_SPEAKER_TO_PANEL, addUserSpeakerToSaga);
  yield takeLatest(
    speakerConstans.REGISTER_USER_IF_NOT_REGISTER_CONFERENCE_2023,
    registerUserIfNotAreRegisterConference2023
  );
  yield takeLatest(
    speakerConstans.GET_ALL_PANELS_OF_ONE_USER,
    getAllPanelsOfOneUserSagas
  );
  yield takeLatest(
    speakerConstans.GET_ALL_PANELS_OF_ONE_USER_BY_ID,
    getPanelsForIdSagas
  );
  yield takeLatest(speakerConstans.EDIT_PANELS_SPEAKERS, editPanelSpeakers);

  yield takeLatest(
    speakerConstans.ADDED_TO_MY_PERSONAL_AGENDA,
    addedToPersonalAgendaSagas
  );
  yield takeLatest(speakerConstans.GET_ALL_SPONSORS, getAllSponsors2023Sagas);
  yield takeLatest(
    speakerConstans.GET_ALL_PARRAF_CONFERENCE,
    getAllParrafsSagas
  );
  yield takeLatest(
    speakerConstans.GET_SPEAKER_MEMBER,
    getAllMemberSpeakerPanelSagas
  );
  yield takeLatest(
    speakerConstans.MY_PANELS_USER,
    getAllPanelsOfOneUserSpeakersSagas
  );
  yield takeLatest(
    speakerConstans.SET_STATUS_USER_SESSION_LIBRARY,
    setStatusUserLibrarySagas
  );
  yield takeLatest(
    speakerConstans.GET_STATUS_USER_SESSION_LIBRARY,
    getStatusUserLibrarySagas
  );
  yield takeLatest(speakerConstans.CHANGUE_STATUS, changeStatusSagas);
  yield takeLatest(speakerConstans.UPDATE_USERS_SPEAKERS_ADMIN, getAllPanelSpeakersAdminSagas);
  yield takeLatest(speakerConstans.DELETE_PANELS_SPEAKERS, deletePanelSpeakers);
}

export const speakerSaga = [fork(watchLogin)];
