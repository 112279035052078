import React, { useEffect, useRef, useState } from "react";
import "../style.scss";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import { CustomInput } from "../../../components";
import ChatMessage from "../ChatMessage";
import { Input, Modal, notification, Skeleton } from "antd";
import { ArrowDownOutlined, CloseOutlined, EditOutlined, SendOutlined } from "@ant-design/icons";
import { useChatMessageStore } from "../store";
import { httpClientCron } from "../../../api/module/httpClientCron";
import ChatMessages from "../ChatMessages";

let typingTimer;

export default function ChatMessageWindow({
                                            index,
                                            img,
                                            name,
                                            id,
                                            titleProfessions,
                                            isTyping,
                                            userProfile,
                                            chatSocket,
                                            type,
                                          }) {
  const [open, setOpen] = useState(true);
  const chatMessageStore = useChatMessageStore();
  const queryClient = useQueryClient();
  const [message, setMessage] = useState({ id: null, content: "" });
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingGroupTitle, setIsEditingGroupTitle] = useState(false);
  const [groupChatName, setGroupChatName] = useState(name);

  const handleEditMessage = ({ id, content }) => {
    setMessage({ id, content });
    setIsEditing(true);
  };

  const sendMessageMutation = useMutation({
    mutationFn: async (payload) => {
      console.log("Emitting", payload);

      chatSocket.emit("private chat", payload, async (err, data) => {

        if (err) {
          notification.error({
            message: "Something went wrong",
            position: "top-right",
          });
        } else {
          setIsEditing(false);

          setMessage({ id: null, content: "" });
        }
      });
    },
    onSuccess: () => {
      if (type === "individual") {
        queryClient.invalidateQueries({ queryKey: ["LAST_CHAT_MESSAGES", userProfile.id] });
      } else {
        queryClient.invalidateQueries({ queryKey: ["GROUP_CHAT_LAST_MESSAGE"] });
      }
      queryClient.invalidateQueries({ queryKey: ["CHAT_MESSAGE_WINDOW", type, id] });
    },
  });

  function handleSendMessage() {
    const { content, id: chatMessageId } = message;

    if (!content.trim()) return;

    const payload = {
      senderId: userProfile.id,
      receiverId: type === "individual" ? id : null,
      content: content.trim(),
      chatMessageId,
      type: type,
      groupChatId: type === "individual" ? null : id,
    };

    sendMessageMutation.mutate(payload);
  }

  const handleInputOnChange = (text) => {
    const { id: chatMessageId } = message;

    setMessage({ content: text, id: chatMessageId });

    if (type === "individual") {
      chatSocket.emit("typing", { senderId: userProfile.id, receiverId: id, type });

      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        chatSocket.emit("stop typing", { senderId: userProfile.id, receiverId: id, type });
      }, 1000); // Adjust the delay as needed
    } else {
      chatSocket.emit("typing", { senderId: userProfile.id, groupChatId: id, type });

      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        chatSocket.emit("stop typing", { senderId: userProfile.id, groupChatId: id, type });
      }, 1000); // Adjust the delay as needed
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const changeChatNameMutation = useMutation({
    mutationFn: async ({ groupChatId, name }) => {
      const { data } = await httpClientCron.patch(`private/group-chat/${groupChatId}/edit-name`, { name });

      return data;
    },
    onSuccess: () => {
      chatMessageStore.updateMessageWindowName({ id, name: groupChatName });
      queryClient.invalidateQueries({ queryKey: ["GROUP_CHAT_LAST_MESSAGE"] });
      queryClient.invalidateQueries({ queryKey: ["CHAT_MESSAGE_WINDOW", type, id] });
      notification.success({
        placement: "topRight",
        message: "Success",
      });
      setIsEditingGroupTitle(false);
    },
    onError: () => {
      notification.error({
        placement: "topRight",
        message: "Something went wrong",
      });
    },
  });

  return (
    <div
      className={open ? "chat-message-window-open" : "chat-message-window"}
      style={{
        // right: open ? `${350 + (index * 480)}px`: `${350 + (index * 350)}px`,
        right: `${350 + (index * 480)}px`,
      }}
    >
      <Modal title="Basic Modal" open={isEditingGroupTitle}
             onOk={() => {
               changeChatNameMutation.mutate({
                 groupChatId: id,
                 name: groupChatName,
               });
             }}
             onCancel={() => {
               setGroupChatName(name);
               setIsEditingGroupTitle(false);
             }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <h2>Change Chat Name</h2>
          <CustomInput value={groupChatName} onChange={value => setGroupChatName(value)} />
        </div>
      </Modal>
      <div
        className={"chat-message-header-container"}
        onClick={() => setOpen(prev => !prev)}
      >
        <div className="chat-message-window-header">
          {type === "individual" ? (
            <>
              <div style={{ position: "relative", display: "inline-block", height: "100%" }}>
                {img && <img src={img} alt={name} style={{ borderRadius: "9999px", width: "2rem", height: "2rem" }} />}
                {chatMessageStore.onlineUsers.some(user => user.userId === id) && (
                  <div
                    style={{
                      position: "absolute",
                      bottom: "0",
                      right: "0",
                      width: "0.8rem",
                      height: "0.8rem",
                      backgroundColor: "green",
                      borderRadius: "50%",
                      border: "2px solid white", // Add a white border for better visibility
                    }}
                  />
                )}
              </div>
              <div>
                <h4
                  className={(isTyping && !open) ? "loading" : "none"}>{`${name} ${(isTyping && !open) ? "is typing" : ""}`}</h4>
                {(titleProfessions && type === "individual" && open) && (
                  <div>
                    <h5>{titleProfessions}</h5>
                  </div>
                )}
              </div>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <h4 className={(isTyping && !open) ? "loading" : "none"}>
                {`${name} ${(isTyping && !open) ? ": Someone is typing" : ""}`}
              </h4>
              <span style={{ color: "black" }} onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsEditingGroupTitle(true);
              }}>
                  <EditOutlined />
                </span>
            </div>
          )
          }

        </div>
        <div
          style={{
            padding: "8px",
          }}
          onClick={(event) => {
            event.stopPropagation();

            chatMessageStore.removeMessageWindow(id);
          }}>
          <CloseOutlined />
        </div>
      </div>
      {open && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            padding: "5px 10px",
          }}
        >
          {/*{(titleProfessions && type === "individual") && (*/}
          {/*  <div*/}
          {/*    style={{*/}
          {/*      borderBottom: type === "individual" ? "1px solid gray" : "none",*/}
          {/*      padding: "5px 10px",*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <h5>{titleProfessions}</h5>*/}
          {/*  </div>*/}
          {/*)}*/}
          <ChatMessages
            id={id}
            senderName={name}
            senderImg={img}
            isTyping={isTyping}
            userProfile={userProfile}
            chatSocket={chatSocket}
            handleEditMessage={handleEditMessage}
            type={type}
            titleProfession={titleProfessions}
          />
          {isEditing && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "5px 10px",
                backgroundColor: "#f3f4f6",
              }}
            >
              <div>Edit Message</div>
              <div
                style={{
                  marginRight: "10px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setIsEditing(false);
                  setMessage({ id: null, content: "" });
                }}
              >
                <CloseOutlined style={{ fontSize: "1rem" }} />
              </div>
            </div>
          )}
          <div
            style={{
              marginTop: "auto",
              marginBottom: "5px",
              display: "flex",
              gap: "1rem",
              alignItems: "center",
              position: "relative",
            }}
          >
            <CustomInput
              multiple
              autoFocus
              value={message.content}
              onChange={handleInputOnChange}
              onKeyDown={handleKeyDown}
              // onKeyDown={event => {
              //   if (event.ctrlKey && event.key === "Enter") {
              //     handleSendMessage();
              //   }
              // }}
            />
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={handleSendMessage}
            >
              <SendOutlined style={{ fontSize: "1.5rem", color: "#FE5622" }} />
            </div>
          </div>
          <div style={{ marginBottom: "50px", visibility: isTyping ? "visible" : "hidden" }}>
            {type === "individual" ? (
              <div className="loading">{name} is typing</div>
            ) : (
              <div className="loading">Someone is typing</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}