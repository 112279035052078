import { put, fork, takeLatest, call } from "redux-saga/effects";
import { notification } from "antd";

import {
    constants as challengeConstants,
    actions as challengeActions,
} from "../actions/challenge-actions";
import { actions as homeActions } from "../actions/home-actions";
import {
    get,
    getOne,
    joinOrLeave,
    send,
    setStatusChallengeEndPoint,
    getStatusChallengeEndPoint,
    getAllCommentsChallenge,
    postChallenge,
    removeChallengeMessage
} from "../../api/module/challenge.js";

export function* getChallengeSagas({ payload }) {
    yield put(homeActions.setLoading(true));

    try {
        const response = yield call(get);

        if (response.status === 200) {
            if (payload.callback) {
                payload.callback()
            }

            yield put(homeActions.setLoading(false));
            yield put(challengeActions.setChallenge(response.data.challenge));
        }
    } catch (error) {
        console.log(error);
        yield put(homeActions.setLoading(false));
    }
}

export function* getOneChallengeSagas({ payload }) {
    yield put(homeActions.setLoading(true));

    try {
        const response = yield call(getOne, payload.id);

        if (response.status === 200) {
            if (payload.callback) {
                payload.callback(response.data)
            }

            yield put(homeActions.setLoading(false));
            yield put(challengeActions.setChallengeOne(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(homeActions.setLoading(false));
    }
}

export function* joinOrLeaveChallengeSagas({ payload }) {
    yield put(homeActions.setLoading(true));

    try {
        const response = yield call(joinOrLeave, payload.id);

        if (response.status === 200) {
            if (payload.callback) {
                payload.callback()
            }

            yield put(homeActions.setLoading(false));
            // notification.info({
            //     message: "Join challenge successfully.",
            // });
        }
    } catch (error) {
        console.log(error);
        yield put(homeActions.setLoading(false));
    }
}

export function* sendInvitationSagas({ payload }) {
    yield put(homeActions.setLoading(true));

    try {
        const response = yield call(send, { emails: payload.emails, id: payload.id });

        if (response.status === 200) {
            if (payload.callback) {
                payload.callback()
            }

            yield put(homeActions.setLoading(false));
            notification.info({
                message: "Invitation sent successfully.",
            });
        }
    } catch (error) {
        console.log(error);
        yield put(homeActions.setLoading(false));
    }
}

export function* setStatusChallengeSagas({ payload }) {
    yield put(homeActions.setLoading(true));

    try {
        const response = yield call(setStatusChallengeEndPoint, payload.data);

        if (response.status === 200) {

            if (payload.callback) {
                payload.callback()
            }
        }
    } catch (error) {
        console.log(error)
        notification.error({
            message: "ERROR:",
            description: error?.response?.data?.msg,
        });
    } finally {
        yield put(homeActions.setLoading(false));
    }
}

export function* getStatusChallengeSagas({ payload }) {
    yield put(homeActions.setLoading(true));

    try {
        const response = yield call(getStatusChallengeEndPoint);

        if (response.status === 200) {

            const { ChallengeStatusActivitiess } = response.data

            yield put(
                challengeActions.settStatusChallenge(ChallengeStatusActivitiess)
            );

            if (payload.callback) {
                payload.callback()
            }
        }
    } catch (error) {
        console.log(error)
        notification.error({
            message: "ERROR:",
            description: error?.response?.data?.msg,
        });
    } finally {
        yield put(homeActions.setLoading(false));
    }
}

export function* getAllCommentsSaga({ payload }) {

    yield put(homeActions.setLoading(true));

    try {
        const response = yield call(getAllCommentsChallenge, payload);

        if (response.status === 200) {

            yield put(
                challengeActions.setAllCommentsChallenge(
                    response.data.comments.rows
                )
            );
        }
    } catch (error) {
        console.log(error);

        if (error && error.response && error.response.status === 401) {

        }
    } finally {
        yield put(homeActions.setLoading(false));
    }
}

export function* addPostCommentSaga({ payload }) {
    yield put(homeActions.setLoading(true));

    try {
        const response = yield call(postChallenge, payload.comment);
        if (response.status === 200) {
            yield put(
                challengeActions.getAllCommentsChallenge({ postId: payload.comment.PostId })
            );
        }
    } catch (error) {
        console.log(error);

        if (error && error.response && error.response.status === 401) {

        }
    } finally {
        yield put(homeActions.setLoading(false));
    }
}

export function* removePostCommentSaga({ payload }) {
    yield put(homeActions.setLoading(true));

    try {
        const response = yield call(removeChallengeMessage, payload.comment);
        if (response.status === 200) {
            yield put(
                challengeActions.getAllCommentsChallenge({ postId: payload.comment.PostId })
            );
        }
    } catch (error) {
        console.log(error);

        if (error && error.response && error.response.status === 401) {

        }
    } finally {
        yield put(homeActions.setLoading(false));
    }
}

function* watchLogin() {
    yield takeLatest(challengeConstants.GET_CHALLENGE, getChallengeSagas);
    yield takeLatest(challengeConstants.GET_CHALLENGE_ONE, getOneChallengeSagas);
    yield takeLatest(challengeConstants.JOIN_OR_LEAVE_CHALLENGE, joinOrLeaveChallengeSagas);
    yield takeLatest(challengeConstants.SEND_INVITATION, sendInvitationSagas);
    yield takeLatest(challengeConstants.SET_STATUS_CHALLENGE, setStatusChallengeSagas);
    yield takeLatest(challengeConstants.GET_STATUS_CHALLENGE, getStatusChallengeSagas);
    yield takeLatest(challengeConstants.GET_ALL_COMMENTS_CHALLENGE, getAllCommentsSaga);
    yield takeLatest(challengeConstants.ADD_COMMENT_CHALLENGE, addPostCommentSaga);
    yield takeLatest(challengeConstants.DELETE_COMMENT_CHALLENGE, removePostCommentSaga);
}

export const challengeSagas = [fork(watchLogin)];
