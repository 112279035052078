import { handleActions } from "redux-actions";
import { Map } from "immutable";
import cloneDeep from "lodash/cloneDeep";

// Action Type Imports
import { constants as challengeConstants } from "../actions/challenge-actions";

// Events's Reducer
export const reducers = {
    [challengeConstants.SET_CHALLENGE]: (state, { payload }) => {
        return state.merge({ challenges: payload.challenge });
    },
    [challengeConstants.SET_CHALLENGE_ONE]: (state, { payload }) => {
        return state.merge({ challenge: payload.challenge });
    },
    [challengeConstants.SETT_STATUS_CHALLENGE]: (state, { payload }) => {
        return state.merge({ activitiesStatus: payload.activitiesStatus });
    },
    [challengeConstants.SET_ALL_COMMENTS_CHALLENGE]: (state, { payload }) => {

        return state.merge({
            allCommentsChallenge: cloneDeep(payload.comments),
        });
    },
};

export const initialState = () =>
    Map({
        challenge: [],
        challenges: [],
        allCommentsChallenge: [],
        activitiesStatus: []
    });

export default handleActions(reducers, initialState());
