/* eslint-disable no-template-curly-in-string */
import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import {
  Form,
  DatePicker,
  Checkbox,
  Radio,
  Select,
  Avatar,
  InputNumber,
} from "antd";

import {
  CustomInput,
  CustomRadio,
  CustomButton,
  CustomCheckbox,
  CustomSelect,
} from "components";
import { searchUser } from "redux/actions/home-actions";
import { homeSelector } from "redux/selectors/homeSelector";
import { CONFERENCE_TYPE_2023 } from "enum";
// import { getInstructors } from "redux/actions/instructor-actions";
import { categorySelector } from "redux/selectors/categorySelector";
import { sessionSelector } from "redux/selectors/sessionSelector";
import { speakerAllPanelSpeakerSelector } from "redux/selectors/speakerSelector";
import {
  getPanelsForId,
  getAllUserSpeaker,
  addPanelSpeakers,
  editPanelSpeakers,
} from "redux/actions/speaker-actions";
import { useSearchCity } from "hooks";
import { getNameOfCityWithTimezone } from "utils/format";
// import { getNameOfCityWithTimezone } from "../../../utils/format";

import "./style.scss";

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const ConferenceTypes = Object.values(CONFERENCE_TYPE_2023);

const { RangePicker } = DatePicker;

const Conference2023SessionsForm = ({
  edit,
  match,
  allCategories,
  getPanelsForId,
  panelsForId,
  // allUserSpeaker,
  searchUser,
  searchUsers,
  getAllUserSpeaker,
  addPanelSpeakers,
  editPanelSpeakers,
  id,
  onCancel,
  globalConference,
  sessionSelected,
}) => {
  const formControl = useRef(null);
  const [options, setOptions] = useState([]);
  // const [redirect, setRedirect] = useState(false);
  const [searchCity, setSearchCity] = useState("");
  const cities = useSearchCity(searchCity);
  const [question, setQuestion] = useState("");

  // const {
  //   params: { id },
  // } = match;

  const onFinish = (values) => {
    const timezoneFirstSliceIndex = values.timezone?.indexOf("/");

    const convertedStartTime = moment
      .utc(values.time[0].format("YYYY-MM-DD HH:mm"))
      .format();

    const convertedEndTime = moment
      .utc(values.time[1].format("YYYY-MM-DD HH:mm"))
      .format();

    const panels = {
      panelName: values.panelName,
      numberOfPanelists: values.numberOfPanelists,
      type: values.type,
      category: values.category,
      startDate: convertedStartTime,
      endDate: convertedEndTime,
      timeZone: values.timezone?.slice(
        timezoneFirstSliceIndex + 1,
        values.timezone.length
      ),
      description: values.description,
      objetives: values.objectives,
      link: values.link,
      recertificactionCredits: values.recertificactionCredits,
      speakers: values.speakers,
      speakersModerator: values.speakersmoderator,
      metaData: values.metaData,
      PanelId: id,
      hrCreditsBoolean: values.hrCreditsBoolean,
      descriptionPanel: values.descriptionPanel,
      typeHrCredits: values.typeHrCredits,
      numberOfHRCredits: values.numberOfHRCredits,
      relationConference: edit
        ? values?.relationConference
        : globalConference?.idConference,
    };

    if (edit) {
      editPanelSpeakers(panels, (est, err) => {
        if (!err) {
          // setRedirect(true);
          formControl.current.resetFields();
          onCancel(); //clear states
        }
      });
    } else {
      addPanelSpeakers(panels, (est, err) => {
        if (!err) {
          // setRedirect(true);
          formControl.current.resetFields();
          onCancel(); //clear states
        }
      });
    }
  };

  const handleSearchCity = (value) => {
    if (value === "") {
      return;
    }

    let timer = setTimeout(() => {
      setSearchCity(value);
      clearTimeout(timer);
    }, 1000);
  };

  useEffect(() => {
    if (edit && formControl) {
      if (sessionSelected !== undefined && sessionSelected.length !== 0) {
        const startTime = moment(
          sessionSelected.startDate,
          "YYYY-MM-DDTHH:mm:ss"
        );
        const endTime = moment(sessionSelected.endDate, "YYYY-MM-DDTHH:mm:ss");
        let city;

        if (
          sessionSelected.timeZone &&
          sessionSelected.timeZone.includes("/")
        ) {
          city = getNameOfCityWithTimezone(sessionSelected.timeZone);
          setSearchCity(city);
        }

        const arrayOfSpeaker = [];
        const arrayOfSpeakerModerator = [];

        sessionSelected.SpeakerMemberPanels.forEach(function (data) {
          if (!data.isModerator) {
            arrayOfSpeaker.push(
              `{"userId":"${data.User.id}","userName":"${data.User.firstName}","userEmail":"${data.User?.email}"}`
            );
          }
          if (data.isModerator) {
            arrayOfSpeakerModerator.push(
              `{"userId":"${data.User.id}","userName":"${data.User.firstName}","userEmail":"${data.User?.email}"}`
            );
          }
        });

        setQuestion(sessionSelected.hrCreditsBoolean);

        const panelForIdArray = {
          numberOfPanelists: sessionSelected.numberOfPanelists,
          panelName: sessionSelected.panelName,
          type: sessionSelected.type,
          category: sessionSelected.category,
          time: [startTime, endTime],
          description: sessionSelected.description,
          objectives: sessionSelected.objetives,
          link: sessionSelected.link,
          timezone: !city
            ? sessionSelected.timeZone
            : `${city}/${sessionSelected.timeZone}`,
          recertificactionCredits: sessionSelected.recertificactionCredits,
          speakers: arrayOfSpeaker,
          speakersmoderator: arrayOfSpeakerModerator,
          metaData: sessionSelected.metaData,
          hrCreditsBoolean: sessionSelected.hrCreditsBoolean,
          descriptionPanel: sessionSelected.descriptionPanel,
          typeHrCredits: sessionSelected.typeHrCredits,
          numberOfHRCredits: sessionSelected.numberOfHRCredits,
          id: sessionSelected.id,
          relationConference: sessionSelected.relationConference,
        };

        formControl.current.setFieldsValue(panelForIdArray);
      }
    }
  }, [sessionSelected, edit]);

  // useEffect(() => {
  //   if (allUserSpeaker) {
  //     // setOptions(
  //     //   allUserSpeaker.map((item) => ({
  //     //     id: item.id.toString(),
  //     //     value: item.id.toString(),
  //     //     name: item.firstName || "(none)",
  //     //     title: item.email || "",
  //     //     image: item.image || undefined,
  //     //     abbrName: item.abbrName,
  //     //     email: item.email
  //     //   }))
  //     // );
  //   }
  // }, [allUserSpeaker]);

  useEffect(() => {
    getAllUserSpeaker();

    // if (edit) {
    //   getPanelsForId(id);
    // }
  }, [getAllUserSpeaker, edit, getPanelsForId, id]);

  useEffect(() => {
    if (searchUsers) {
      setOptions(
        searchUsers.map((item) => ({
          id: item.id.toString(),
          value: item.id.toString(),
          name: item.firstName || "(none)",
          title: item.email || "",
          image: item.image || undefined,
          abbrName: item.abbrName,
          email: item.email,
        }))
      );
    }
  }, [searchUsers]);

  return (
    <div className="global-conference-edit">
      <div className="session-form">
        <Form
          layout="vertical"
          ref={formControl}
          initialValues={{}}
          scrollToFirstError="true"
          onFinish={onFinish}
          validateMessages={validateMessages}
        >
          <Form.Item
            name="panelName"
            label="title"
            rules={[{ required: true, message: "title is required" }]}
          >
            <CustomInput />
          </Form.Item>
          <Form.Item
            name="type"
            label="Type"
            rules={[{ required: true, message: "Type is required" }]}
          >
            <Radio.Group className="d-flex flex-column session-form-radiogrp">
              {ConferenceTypes.map((type, index) => (
                <CustomRadio key={index} value={type}>
                  {type}
                </CustomRadio>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Number of panelists"
            name="numberOfPanelists"
            initialValue="1"
            rules={[{ required: true }]}
          >
            <InputNumber size="large" min="1" style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item name={"category"} label="Category">
            <Checkbox.Group className="session-form-category">
              {allCategories.map((category) => (
                <CustomCheckbox key={category.value} value={category.value}>
                  {category.title}
                </CustomCheckbox>
              ))}
            </Checkbox.Group>
          </Form.Item>
          <Form.Item
            name="time"
            label="Time"
            rules={[{ required: true, message: "the date is required" }]}
          >
            <RangePicker size="large" showTime format="YYYY/MM/DD HH:mm" />
          </Form.Item>
          <Form.Item
            name={"timezone"}
            label="Timezone"
            rules={[{ required: true, message: "timezone is required" }]}
          >
            <CustomSelect
              showSearch
              options={cities}
              optionFilterProp="children"
              onSearch={(value) => handleSearchCity(value)}
              className="border"
            />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: "Description is required" }]}
          >
            <CustomInput multiple={true} />
          </Form.Item>
          <Form.Item name="objectives" label="Objectives">
            <CustomInput multiple={true} />
          </Form.Item>
          <Form.Item name="link" label="Link">
            <CustomInput />
          </Form.Item>
          <Form.Item
            name="recertificactionCredits"
            label="HR Recertification Credits"
          >
            <CustomInput multiple />
          </Form.Item>
          <Form.Item name="metaData" label="Meta Data">
            <CustomInput multiple />
          </Form.Item>
          <Form.Item
            name="speakers"
            label="Speakers"
            className="speakers-container"
          >
            <Select
              className="user-select"
              mode="multiple"
              onKeyUp={(e) => {
                if (e.target !== undefined) {
                  searchUser({
                    search: e.target.value,
                    userAdmin: true,
                  });
                }
              }}
            >
              {(options || []).map((user) => (
                <Select.Option
                  className="user-select-option"
                  key={user.id}
                  value={JSON.stringify({
                    userId: user.id,
                    userName: user.name,
                    userEmail: user.title,
                  })}
                >
                  <div
                    className="container-option"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      height: "35px",
                    }}
                  >
                    <div className="user-image" style={{ marginRight: "10px" }}>
                      {user.image ? (
                        <img src={user.image} alt="user-img" />
                      ) : (
                        <Avatar size={30}>{user.abbrName}</Avatar>
                      )}
                    </div>
                    <h6
                      className="user-name"
                      style={{ height: "20px", paddingRight: "10px" }}
                    >
                      {user.name}
                    </h6>
                    <h6
                      className="user-title"
                      style={{ height: "20px", paddingRight: "10px" }}
                    >
                      {user.title}
                    </h6>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="speakersmoderator"
            label="Speakers Moderators"
            className="speakers-container"
          >
            <Select
              className="user-select"
              mode="multiple"
              onKeyUp={(e) => {
                if (e.target !== undefined) {
                  searchUser({
                    search: e.target.value,
                    userAdmin: true,
                  });
                }
              }}
            >
              {(options || []).map((user) => (
                <Select.Option
                  className="user-select-option"
                  key={user.id}
                  value={JSON.stringify({
                    userId: user.id,
                    userName: user.name,
                    userEmail: user.title,
                  })}
                >
                  <div
                    className="container-option"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      height: "35px",
                    }}
                  >
                    <div className="user-image" style={{ marginRight: "10px" }}>
                      {user.image ? (
                        <img src={user.image} alt="user-img" />
                      ) : (
                        <Avatar size={30}>{user.abbrName}</Avatar>
                      )}
                    </div>
                    <h6
                      className="user-name"
                      style={{ height: "20px", paddingRight: "10px" }}
                    >
                      {user.name}
                    </h6>
                    <h6
                      className="user-title"
                      style={{ height: "20px", paddingRight: "10px" }}
                    >
                      {user.title}
                    </h6>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="hrCreditsBoolean"
            label="HR Credits Available?"
            rules={[{ required: false }]}
          >
            <Radio.Group
              className="d-flex flex-column event-edit-form-cbgrp"
              onChange={(e) => {
                if (e.target !== undefined) {
                  setQuestion(e.target.value);
                }
              }}
            >
              {["Yes", "No"].map((type) => (
                <CustomRadio key={type} value={type}>
                  {type}
                </CustomRadio>
              ))}
            </Radio.Group>
          </Form.Item>
          {question === "Yes" && (
            <Form.Item
              name="typeHrCredits"
              label="Type HR Credits"
              rules={[{ required: false }]}
            >
              <Radio.Group className="d-flex flex-column event-edit-form-cbgrp">
                {["Ethics", "Business", "HR & Global"].map((type) => (
                  <CustomRadio key={type} value={type}>
                    {type}
                  </CustomRadio>
                ))}
              </Radio.Group>
            </Form.Item>
          )}
          {question === "Yes" && (
            <Form.Item
              name="numberOfHRCredits"
              label="Number of HR Credits"
              initialValue="1"
              rules={[{ required: false }]}
            >
              <InputNumber size="large" min="1" style={{ width: "100%" }} />
            </Form.Item>
          )}
          {/* <Form.Item
            label="Description"
            name="descriptionPanel"
          >
            <CustomInput />
          </Form.Item> */}
          <div className="session-form-actions d-flex justify-center align-center">
            <CustomButton
              text="Cancel"
              type="secondary"
              style={{ marginRight: "10px" }}
              // onClick={() => setRedirect(true)}
              onClick={() => {
                onCancel();
                formControl.current.resetFields();
              }}
            />
            <CustomButton
              text={edit ? "Save" : "Add"}
              htmlType="submit"
              type="primary"
            />
          </div>
        </Form>
      </div>
      {/* {redirect &&
        <Redirect to={`${INTERNAL_LINKS.CONFERENCE_2023}?edit=${edit}`} />
      } */}
    </div>
  );
};

Conference2023SessionsForm.propTypes = {
  edit: PropTypes.bool,
};

Conference2023SessionsForm.defaultProps = {
  edit: false,
};

const mapStateToProps = (state) => ({
  allCategories: categorySelector(state).categories,
  panelsForId: speakerAllPanelSpeakerSelector(state).panelsForId,
  allUserSpeaker: speakerAllPanelSpeakerSelector(state).allUserSpeaker,
  searchUsers: homeSelector(state).searchUsers,
  ...sessionSelector(state),
});

const mapDispatchToProps = {
  // getInstructors,
  getPanelsForId,
  getAllUserSpeaker,
  addPanelSpeakers,
  editPanelSpeakers,
  searchUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Conference2023SessionsForm);
