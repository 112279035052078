import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import IconLoadingMore from "images/icon-loading-more.gif";
import getPodcastLinks from "utils/getPodcastLinks.js";
import { useHistory } from "react-router-dom";
import MemberSpeakers from "../MembersSpeakers";
import Emitter from "services/emitter";
import { myLearningSelector } from "redux/selectors/myLearningSelector";
import { actions as myLearningActions } from "redux/actions/myLearning-actions";
import { CollapseComponent } from "components";
import { categorySelector } from "redux/selectors/categorySelector";
import { actions as speaker } from "redux/actions/speaker-actions";
import { CustomButton,ConferenceCard,LibraryCard,PodcastSeriesCard,AnnualConferenceCard,EpisodeCard } from "components";
import { EVENT_TYPES, INTERNAL_LINKS, SETTINGS } from "enum";

const DisplaySavedItems = ({
    getMoreSaved,
    getAllPanelSpeakers,
    allSavedCurrentPage,
    getStatusUserSessionLibrary,
    setStatusUserSessionLibrary,
    allPanelSpeakers,
    allCategories,
    allPanelSpeakersStatus,  
    allSaved,
    getAllSaved,
    currentTab,
    loading,
    listOfYears=[],
}) => {
  const history = useHistory();
  const [dataCategoriesState, setDataCategoriesState] = useState()

  useEffect(() => {
    getAllSaved({});
    getAllPanelSpeakers('All')
    getStatusUserSessionLibrary()
  },[getAllSaved])

  useEffect(() => {
    let objectAllCategories = {}

    allCategories.forEach((category) => {
      objectAllCategories[`${category.value}`] = category.title
    })

    setDataCategoriesState(objectAllCategories)
    }, [allCategories, setDataCategoriesState])
  

  useEffect(() => {
    if (currentTab === "2") { getAllSaved({}) }
    // eslint-disable-next-line
  }, [currentTab]);

  const showMoreSaved = () => {
    getMoreSaved({page: allSavedCurrentPage + 1});
  };

  const planUpdate = () => {
    Emitter.emit(EVENT_TYPES.OPEN_PAYMENT_MODAL);
  };

  const content = (panels) => {

    let categories

    if (dataCategoriesState !== undefined) {
      categories = panels?.category?.map((data, index) => {
        if (panels?.category?.length !== index + 1) {
          return (<span className="date-panels" key={index}> {dataCategoriesState[data]} |</span>)
        } else {
          return (<span className="date-panels" key={index}> {dataCategoriesState[data]}</span>)
        }
      })
    }

    let situation = allPanelSpeakersStatus.filter((data) => data.idSession === panels.id)

    return (
      <div className="content-collapse" key={panels?.id}>
        <p className="title-collapse">{panels?.panelName}</p>
        <div className="content-information">
          <div className="content-first-information">
            <p className="p-content">Session type:
              <span className="date-panels"> {panels?.type}</span>
            </p>
            {(panels.type === "Panels") &&
              <p className="p-content">Panel Topics: {categories}</p>
            }
            {(panels.type === "Simulations") &&
              <p className="p-content">Simulation Topics: {categories}</p>
            }
            {(panels?.hrCreditsBoolean !== undefined && panels?.hrCreditsBoolean !== '')
              &&
              <p className="p-content">Does this session offer HR credits?:  <span className="date-panels">{(panels?.hrCreditsBoolean === 'No')
                ? 'NO'
                : `YES (${panels?.numberOfHRCredits} SHRM + ${panels?.numberOfHRCredits} HRCI | Credit Type: ${panels?.typeHrCredits})`
              }</span></p>}
          </div>
          <div className="content-bottom">
            <CustomButton
              className="claim-credits"
              type="primary"
              size="xs"
              text="Watch"
              style={{ width: '100%', marginBottom: '10px' }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                window.open(`${INTERNAL_LINKS.MICRO_CONFERENCE}/${panels?.id}?type=2023`, "_blank");
              }}
            />
            <CustomButton
              className="mark-viewed"
              type={(situation[0]?.viewed === true) ? "remove" : "secondary"}
              size="xs"
              style={{ width: '100%' }}
              text={(situation[0]?.viewed === true) ? "Viewed" : "Mark As Completed"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setStatusUserSessionLibrary({
                  idSession: panels?.id,
                  viewed: situation[0]?.viewed ? false : true,
                  saveForLater: (situation[0]?.saveForLater !== undefined) ? situation[0]?.saveForLater : false
                }, () => getStatusUserSessionLibrary())
              }}
            />
            {(situation[0]?.viewed !== true) && (
              <CustomButton
                className="save-for-later"
                type={(situation[0]?.saveForLater === true) ? "remove" : "third"}
                size="xs"
                text={(situation[0]?.saveForLater === true) ? "Unsave" : "Save for later"}
                style={{ width: '100%' }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setStatusUserSessionLibrary({
                    idSession: panels?.id,
                    viewed: (situation[0]?.viewed !== undefined) ? situation[0]?.viewed : false,
                    saveForLater: situation[0]?.saveForLater ? false : true
                  }, () => getStatusUserSessionLibrary())
                }}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
  
  const dataIterated = (panels) => (
      <div className="ajust-contain-conference">
        {panels?.SpeakerMemberPanels?.map((user) => {
          return (
            <MemberSpeakers
              key={user?.id}
              usersPanel={user}
            />
          )
        })}
      </div>
  )
  
  const dataStatic = (panels) => (
      <p className="container-panel-speaker-parraf" style={{ marginBottom: "40px", fontSize: "18px" }}>
        Description: <span className="not-bold">{panels?.description}</span>
      </p>
  )
  
  const functionFilterSession = (data) => {
      let newData = data.filter((e) => {
        let situation = allPanelSpeakersStatus.filter((a) => a.idSession === e.id)
  
        return (situation[0]?.saveForLater === true) && (situation[0]?.viewed !== true)
      })
      return newData
  }

  return (
    <>
      <div className="sessions-2022-container-2" style={{ paddingBottom: '10px' }}>
        {functionFilterSession(allPanelSpeakers || [])?.map((item) => {
          return (
            <CollapseComponent
              index={item?.id}
              informationCollapse={content(item)}
              buttons={<div></div>}
              className={"container-panel"}
              dataIterated={dataIterated(item)}
              dataStatic={dataStatic(item)}
              bulArrow={false}
              bulMessage={(item?.type === "Simulations") ? false : true}
            />
          );
        })}
      </div>
      <div className="saved-for-later">
        {allSaved?.rows?.map((item, index) => {
          if (item.type === "libraries") {
            return (
              <LibraryCard key={index} data={item} onClickAccess={planUpdate} />
            );
          } else if (item.type === "conferences") {
            return (
              <ConferenceCard
                key={index}
                data={item}
                listOfYearsIndex={listOfYears.findIndex((year) => year === item.year)}
              />
            );
          } else if (item.type === "podcasts") {
            return (
              <EpisodeCard
                key={index}
                links={getPodcastLinks(item)}
                episode={item}
              />
            );
          } else if (
            item.type === "Certificate Track and Panels" ||
            item.type === "Presentation"
          ) {
            return (
              <AnnualConferenceCard
                key={item.id}
                session={item}
                typeConference="conference-library"
                onWatch={() =>
                  history.push(`${INTERNAL_LINKS.MICRO_CONFERENCE}/${item.id}`)
                }
                savedItem
              />
            );
          } else {
            return <PodcastSeriesCard key={index} data={item} />;
          }
        }) || []}
      </div>
      <>
        {allSavedCurrentPage * SETTINGS.MAX_SEARCH_ROW_NUM < allSaved?.count && (
          <div className="search-results-container-footer d-flex justify-center items-center">
            {loading && (
              <div className="my-learnings-page-loading-more">
                <img src={IconLoadingMore} alt="loading-more-img" />
              </div>
            )}
            {!loading && (
              <CustomButton
                text="Show More"
                type="primary outlined"
                size="lg"
                onClick={showMoreSaved}
              />
            )}
          </div>
        )}
      </>
    </>
  )
};
  
const mapStateToProps = (state, props) => ({
  ...myLearningSelector(state),
  allCategories: categorySelector(state).categories,
});
  
const mapDispatchToProps = {
  ...myLearningActions,
  getAllPanelSpeakers: speaker.getAllPanelSpeakers,
  getStatusUserSessionLibrary: speaker.getStatusUserSessionLibrary,
};
  
export default connect(mapStateToProps, mapDispatchToProps)(DisplaySavedItems);