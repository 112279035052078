import { Modal } from "antd";
import React from 'react';
import IconLogo from "images/logo-sidebar.svg";

import '../style.scss'
export default function ClosedCertificateProgramModal({
  visible,
  onClose
}) {
  return (
    <Modal
      className="modal-login"
      width={"480px"}
      footer={null}
      style={{ height: "auto", top: "50px" }}
      visible={visible}
      onCancel={onClose}
    >
      <div className="login-page-2">
        <div
          className="login-dialog"
          style={{ paddingTop: "40px", paddingBottom: "40px" }}
        >
          <div className="login-dialog-header" style={{ padding: "10px" }}>
            <h3 style={{ textAlign: "center" }}>
              Enrollment for this Certificate Program has closed
            </h3>
            <div
              className="login-dialog-logo"
              style={{ borderRadius: "50%", background: "white" }}
            >
              <img src={IconLogo} alt="login-logo" />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}