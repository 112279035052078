import React from "react";
import { INTERNAL_LINKS } from "enum";
import { PrivateRoute } from "components";
import { Sidebar } from "components";

const startRouteBlogsNew1 = () => {
  return (
    <>
      {localStorage.getItem("community") !== null && (
        <PrivateRoute
          path={`${INTERNAL_LINKS.BLOGS_NEW}/:id`}
          exact
          render={(props) => <Sidebar typeStyle={'black'} {...props} />}
        />
      )}
    </>
  );
};

export default startRouteBlogsNew1;
