import React from "react";
import { Avatar, Divider } from "antd";
import iconDown from "images/icon-linkedin.svg";

import "./style.scss";
// import PopupSpeakerSummit from "../PopupInstructor";

const CardUser = ({ instructor, title, btnClass = true }) => {
  // const [openModal, setOpenModal] = useState(false);
  // const [userSelected, setUserSelected] = useState({});

  // const handleModalShowUser = async (visible = false, speaker = {}) => {
  //   setOpenModal(visible);
  //   setUserSelected(speaker);
  // };

  return (
    <div className={"card-user"}>
      {/* <h2 className={"title"}>{title}</h2> */}
      <Avatar src={instructor?.img} size={150}></Avatar>
      <Divider className={"divider"} />
      <h3 className={"name"}>{`${instructor?.firstName} ${instructor?.lastName}`}</h3>
      <p style={{ fontSize: "15px" }}>{instructor?.titleProfessions}</p>
      <p className={"text-content"}>{/* Co-Founder Hacking HR */}</p>

          <div className={"container"}>
            <a href={instructor?.personalLinks?.linkedin || '#'} target="_blank" rel="noopener noreferrer">
              <img className={"icon-linkedin"} src={iconDown} alt="linkedin" />
        </a>
        {/* {
          btnClass
          &&
          <p className={"classes-btn"} onClick={() => handleModalShowUser(true, instructor)}>
            Class
          </p>} */}
        </div>
      {/* {
        btnClass
        &&
        <>
        <PopupSpeakerSummit
          updatedEvent={{}}
          open={openModal}
          funModal={handleModalShowUser}
          speaker={userSelected}
          speakerId={userSelected?.id}
          // certificateSlug={slug} //only for classes of the certificate that is now
          certificateSlug={undefined}
          />
        </>
        } */}
    </div>
  );
};

export default CardUser;
