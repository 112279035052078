import React, { useState } from "react";

import PropTypes from "prop-types";
import { Row, Col } from "antd";
// import groupBy from "lodash/groupBy";
import moment from "moment";
import { connect } from "react-redux";
import { convertToLocalTime } from "utils/format";
import TimeZoneList from "enum/TimezoneList";
import { useHistory } from "react-router-dom";

import {
  // DateAvatar,
  EventCard,
  // CustomButton,
} from "components";
import { NoEventCard } from "components";
import EventDetails from "./EventDetails";
// import Emitter from "services/emitter";
import {
  // EVENT_TYPES,
  //SETTINGS,
  CARD_TYPE,
  // INTERNAL_LINKS,
} from "enum";
import { envSelector } from "redux/selectors/envSelector";

import "./style.scss";

// const monthStr = [
//   "JAN",
//   "FEB",
//   "MAR",
//   "APR",
//   "MAY",
//   "JUN",
//   "JUL",
//   "AUG",
//   "SEP",
//   "OCT",
//   "NOV",
//   "DEC",
// ];

// const DataFormat = SETTINGS.DATE_FORMAT;

const EventList = ({
  data,
  isMobile,
  onAttend,
  showFilter,
  onClick,
  edit,
  type,
  onMenuClick,
  onAddEvent,
  onConfirmAttendance,
  onConfirmCredit,
  userProfile,
  limit = "all",
  buttomEdit,
  setActiveMessages,
  boxes,
  allEventsUsersSketch,
  setLoading,
  ...rest
}) => {
  const [isEventActive, setIsEventActive] = useState(false);
  const [event, setEvent] = useState({});

  // let num = -1;
  const onEventChanged = (event, going, img) => {
    event.going = going;
    onAttend(event, img);
  };

  const getRandomNumber = () => Math.floor(Math.random() * 1000);

  const searchTimeZone = (timezone) => {
    let currentTimezone = TimeZoneList.find((item) => item.value === timezone);

    if (currentTimezone) {
      currentTimezone = currentTimezone.utc[0];
    } else {
      currentTimezone = timezone;
    }

    return currentTimezone;
  };

  const newFilterEventsForDays = (data) => {
    let newData = data?.filter((e) => {
      let dateFilter = moment(e?.endDate, "YYYY-MM-DD hh:mm a")?.format(
        "YYYYMMDDHHmm"
      );
      // e.channel !== null
      // : moment(
      //     e?.startAndEndTimes[e.startAndEndTimes.length - 1]?.endTime,
      //     "YYYY-MM-DD hh:mm a"
      //   )?.format("YYYYMMDDHHmm");

      let dateNow = moment(moment.utc(), "YYYY-MM-DD hh:mm a")
        .tz(
          searchTimeZone(
            e?.timezone === "null" ? "Pacific Daylight Time" : e?.timezone
          )
        )
        ?.format("YYYYMMDDHHmm");

      return (Number(dateNow) <= Number(dateFilter)) === true;

    });

    let arrayFixed = [];
    let num = -1;
    let titlesDateReady;

    for (let i = 0; i < newData.length; i++) {
      let dateNow = newData[i].startDate;
      let timezone =
        newData[i].timezone === "null"
          ? "Pacific Daylight Time"
          : newData[i].timezone;
      if (
        titlesDateReady !==
        convertToLocalTime(dateNow, timezone).format().substring(0, 10)
      ) {
        titlesDateReady = convertToLocalTime(dateNow, timezone)
          .format()
          .substring(0, 10);
        num++;
        if (!arrayFixed[num]) {
          arrayFixed.push([]);
        }
        arrayFixed[num].push(newData[i]);
      } else {
        arrayFixed[num].push(newData[i]);
      }
    }

    let newArray = [].concat(...arrayFixed);
    // let newArray = [];
    // arrayFixed.map((item) => {
    //   newArray = [...newArray, ...item];
    // });

    return newArray;
  };

  return (
    <div className={"event-list-event"} style={{overflow:'auto',height: 'calc(100vh - 130px)',paddingBottom:'0px'}}>
      {data && data.length === 0 && type !== CARD_TYPE.EDIT && <NoEventCard />}
      {/* {edit && type === CARD_TYPE.EDIT && (
        <CustomButton
          text="Add Events"
          htmlType="submit"
          size="sm"
          type="primary"
          className={buttomEdit === "home" ? "buttomAddRR" : "buttomAddR"}
          style={buttomEdit === "home" ? { left: "110px" } : {}}
          onClick={() => onAddEvent()}
        />
      )} */}
      {isEventActive ? (
        <EventDetails
          event2={event}
          setIsEventActive={setIsEventActive}
          setEvent2={setEvent}
        />
      ) : (
        <>
          {
          // newFilterEventsForDays(data)
          data  
          ?.filter((item) => item.status === "active")
            ?.map((event, index) => {
              return (
                <div
                  className="event-list-batch"
                  key={`${index}-${getRandomNumber()}`}
                >
                  <Row gutter={[0, 36]}>
                    <Col
                      key={`col-${index}-${getRandomNumber()}`}
                      span={24}
                      className="event-list-item"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <EventCard
                        edit={edit}
                        data={event}
                        userProfile={userProfile}
                        onAttend={(going, img) =>
                          onEventChanged(event, going, img)
                        }
                        onClick={() => {
                          setIsEventActive(true);
                          return setEvent(event);
                        }}
                        callForSpeaker={true}
                        history={useHistory}
                        onClickCallSpeaker={() => {
                          setIsEventActive(true);
                          return setEvent(event);
                        }}
                        boxes={boxes}
                        onMenuClick={(menu) => onMenuClick(menu, event)}
                        onConfirmAttendance={onConfirmAttendance}
                        onConfirmCredit={onConfirmCredit}
                        setActiveMessages={setActiveMessages}
                        isMobile={isMobile}
                        // usersData={allEventsUsersSketch[event.id]}
                        setLoading={setLoading}
                        esIncoming={true}
                      />
                    </Col>
                  </Row>
                </div>
              );
            })}
        </>
      )}
    </div>
  );
};

EventList.propTypes = {
  data: PropTypes.array,
  edit: PropTypes.bool,
  type: PropTypes.string,
  onAttend: PropTypes.func,
  onClick: PropTypes.func,
  showFilter: PropTypes.func,
  onMenuClick: PropTypes.func,
  onAddEvent: PropTypes.func,
  onConfirmAttendance: PropTypes.func,
  onConfirmCredit: PropTypes.func,
  userProfile: PropTypes.object,
  boxes: PropTypes.object,
  setLoading: PropTypes.func,
};

EventList.defaultProps = {
  data: [],
  edit: false,
  type: CARD_TYPE.VIEW,
  userProfile: {},
  onAttend: () => {},
  onClick: () => {},
  showFilter: () => {},
  onMenuClick: () => {},
  onAddEvent: () => {},
  onConfirmAttendance: () => {},
  onConfirmCredit: () => {},
  setLoading: () => {},
  boxes: {},
};

const mapStateToProps = (state) => ({
  isMobile: envSelector(state).isMobile,
});

export default connect(mapStateToProps)(EventList);
