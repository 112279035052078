import React from "react";

const IconBookmark = ({color = 'black', width = '24px', height = '24px'}) => {
  return (
    <div style={{width:width,height:height}}>
        <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Bookmark</title><path d='M352 48H160a48 48 0 00-48 48v368l144-128 144 128V96a48 48 0 00-48-48z' fill='none' stroke={color} stroke-linecap='round' stroke-linejoin='round' stroke-width='32'/></svg>
    </div>
  );
};

export default IconBookmark;
