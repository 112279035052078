import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Avatar, Dropdown, Space, Tooltip, Modal } from "antd";
import {
  CheckOutlined,
  DownOutlined,
  CloseCircleFilled,
} from "@ant-design/icons";
import { isEmpty } from "lodash";
import TimeZoneList from "enum/TimezoneList";
import moment from "moment-timezone";
import { categorySelector } from "redux/selectors/categorySelector";
import { envSelector } from "redux/selectors/envSelector";
import { loadStripe } from "@stripe/stripe-js";

import { getCheckoutSession } from "api/module/stripe";

import {
  // DateAvatar,
  CustomButton,
  EventCard,
  // CustomDrawer,
  RichEdit,
  CollapseComponent,
  Tabs,
  CardUser,
} from "components";
import { EVENT_TYPES, MONTH_NAMES } from "enum";
import Emitter from "services/emitter";
import IconLogo from "images/logo-sidebar.svg";
import downloadDropdownOptions from "components/downloadDropdownOptions";
import {
  actions as eventActions,
  getChannelEvents,
  setEvent,
  setGrowGroup,
} from "redux/actions/event-actions";
import { actions as homeActions } from "redux/actions/home-actions";
import { eventSelector } from "redux/selectors/eventSelector";
import { homeSelector } from "redux/selectors/homeSelector";
import { actions as councilEventActions } from "redux/actions/council-events-actions";
import { councilEventSelector } from "redux/selectors/councilEventSelector";

// import MemberSpeakers from "./MembersSpeakers";
import Arrow from "../../images/arrow-conference.svg";
import IconBack from "images/icon-back.svg";

import {
  convertToLocalTime,
  // convertToCertainTime,
  capitalizeWord,
} from "utils/format";
import clsx from "clsx";

import "./style.scss";
import { channelSelector } from "redux/selectors/channelSelector";

const EventTypesData = {
  presentation: "Presentation",
  workshop: "Workshop",
  panel: "Panel",
  "peer-to-peer": "Peer-to-Peer Conversation",
  conference: "Conference",
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_KEY);

const EventPage = ({
  // addToMyEventList,
  // removeFromMyEventList,
  updatedEvent,
  visible,
  eventData,
  onAttend,
  userProfile,
  onClose,
  allEventsUsersSketch,
  isMobile,
  allEvents,
  allGrows,
  allUsers,
  isGrowGroups = false,
  // filter,
  // getChannelEvents,
  // channel,
  // onConfirmCredit,
  allCategories,
  allCouncilEventss,
  // getAllUsers,
}) => {
  const [editor, setEditor] = useState("froala");
  const [event, setEventR] = useState(eventData);
  const [showFirewall, setShowFirewall] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bulChangeEvent, setBulChangeEvent] = useState(false);
  const [stripe, setStripe] = useState(null);
  const [eventCouncil, setEvent2] = useState(undefined);
  const DataFormat = "YYYY.MM.DD hh:mm A";
  const [dataCategoriesState, setDataCategoriesState] = useState();
  const [popUp, setPopUp] = useState(false);
  const [btnTextGrowGroup, setBtnTextGrowGroup] = useState("");
  const [instructorsGrow, setInstructorsGrow] = useState([]);
  const [stateGrowGroup, setStateGrowGroup] = useState(
    event?.arrayUsersIdGrowGroups?.find(
      (item) => item?.userId === userProfile?.id
    ) || {}
  );
  const [btnGrowGroupDisabled, setBtnGrowGroupDisabled] = useState(false);
  const [currentTab, setCurrentTab] = useState("0");

  // useEffect(() => {
  //   if (isGrowGroups) {
  //     getAllUsers({ eventOrGrowId: event?.id });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [getAllUsers, isGrowGroups]);

  useEffect(() => {
    if (isGrowGroups && allUsers && allUsers.length > 0) {
      const filteredUsers = allUsers.filter((user) =>
        eventData.instructorIds.includes(user.id)
      );
      setInstructorsGrow(filteredUsers);
    }
  }, [allUsers, eventData.instructorIds, isGrowGroups]);

  useEffect(() => {
    setBtnTextGrowGroup(
      stateGrowGroup?.status === undefined
        ? "REGISTER HERE"
        : stateGrowGroup?.status === "pending"
        ? "Registration Pending"
        : stateGrowGroup?.status === "rejected" && "REGISTER HERE"
    );

    setBtnGrowGroupDisabled(stateGrowGroup?.status === "pending");
  }, [stateGrowGroup]);

  let userTimezone = moment.tz.guess();
  let bulStatus =
    eventCouncil?.status === "active" || eventCouncil?.status === "closed";

  if (userTimezone.includes("_")) {
    userTimezone = userTimezone?.split("_")?.join(" ");
  }

  let clockAnimation;
  let clockAnimation2;

  useEffect(() => {
    if (event?.relationEventCouncil !== -1 || event?.relationEventCouncil) {
      const _event = allCouncilEventss?.find(
        (eve) => eve?.id === Number(event?.relationEventCouncil)
      );
      setEvent2(_event);
    }
    setStateGrowGroup(
      event?.arrayUsersIdGrowGroups?.find(
        (item) => item?.userId === userProfile?.id
      ) || {}
    );
  }, [allCouncilEventss, event, setEvent2, userProfile.id]);

  useEffect(() => {
    if (!bulChangeEvent) {
      setEventR({ ...eventData, usersData: allEventsUsersSketch[event.id] });
    }
  }, [eventData, allEventsUsersSketch, event.id, bulChangeEvent]);

  useEffect(() => {
    let objectAllCategories = {};

    allCategories.forEach((category) => {
      objectAllCategories[`${category?.value}`] = category?.title;
    });

    setDataCategoriesState(objectAllCategories);
  }, [allCategories, setDataCategoriesState]);

  useEffect(() => {
    instanceStripe();
  }, []);

  const instanceStripe = async () => {
    setStripe(await stripePromise);
  };

  const onAttend2 = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    const userTimezone = moment.tz.guess();

    if (event.ticket === "premium") {
      if (userProfile && userProfile.memberShip === "premium") {
        onEventChanged(
          event,
          true
          // imageEventSelect1
        );
      } else {
        setShowFirewall(true);
      }
    } else if (event.ticket === "fee") {
      setLoading(true);

      let sessionData = await getCheckoutSession({
        prices: [
          {
            price_data: {
              currency: "usd",
              product_data: {
                name: event.title,
              },
              unit_amount: `${event.ticketFee}00`,
            },
          },
        ],
        isPaidEvent: true,
        event: {
          ...event,
          userTimezone,
        },
        callback_url: window.location.href,
      });

      stripe.redirectToCheckout({ sessionId: sessionData.data.id });
    } else {
      onEventChanged(
        event,
        true
        // imageEventSelect1
      );
    }
    if (window.location.pathname.includes("channels")) {
      window.open(event.externalLink, "_blank");
    }
  };

  const onCancelAttend = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onEventChanged(event, false);
  };

  // const onClickClaimDigitalCertificate = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();

  //   window.open(
  //     `${INTERNAL_LINKS.CERTIFICATE}/${this.props.data.id}`,
  //     "_blank"
  //   );
  // };

  // const onClickConfirm = (e) => {
  //   Emitter.emit(EVENT_TYPES.OPEN_ATTENDANCE_DISCLAIMER, event);
  // };

  // const onClickClaimCredits = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();

  //   onConfirmCredit(event);
  // };

  // const onClickDownloadCalendar = (startDate, endDate, day, titleSession) => {
  //   const userTimezone = moment.tz.guess();

  //   window.open(
  //     `${process.env.REACT_APP_API_ENDPOINT}/public/event/ics/${event?.id}?day=${day}&title=${titleSession}&userTimezone=${userTimezone}&startTimeD=${startDate}&endTimeD=${endDate}`,
  //     "_blank"
  //   );
  // };

  // const onClickAddGoogleCalendar = (startDate, endDate, titleSession, link) => {
  //   const getEventLink = (eventD, linkD) => {
  //     if (eventD?.bulLinkForEachDay === "true") {
  //       return linkD;
  //     } else {
  //       return eventD.link;
  //     }
  //   };

  //   let textSelectGoogleCalendar =
  //     titleSession !== undefined ? titleSession : event?.title;

  //   let googleCalendarUrl = `http://www.google.com/calendar/event?action=TEMPLATE&text=${textSelectGoogleCalendar}&dates=${startDate}/${endDate}&details=Link to connect: ${getEventLink(
  //     event,
  //     link
  //   )}&location=${
  //     event?.location
  //   }&trp=false&sprop=https://www.hackinghrlab.io/&sprop=name:`;

  //   window.open(googleCalendarUrl, "_blank");
  // };

  // const onClickAddYahooCalendar = (startDate, endDate, titleSession, link) => {
  //   const getEventLink = (eventD, linkD) => {
  //     if (eventD?.bulLinkForEachDay === "true") {
  //       return linkD;
  //     } else {
  //       return eventD.link;
  //     }
  //   };

  //   let textSelectYahooCalendar =
  //     titleSession !== undefined ? titleSession : event?.title;

  //   let yahooCalendarUrl = `https://calendar.yahoo.com/?v=60&st=${startDate}&et=${endDate}&DESC=Link to connect: ${getEventLink(
  //     event,
  //     link
  //   )}&title=${textSelectYahooCalendar}&in_loc=${event?.location}`;

  //   window.open(yahooCalendarUrl, "_blank");
  // };

  // const handleOnClick = ({ item, key, domEvent }) => {
  //   domEvent.stopPropagation();
  //   domEvent.preventDefault();

  //   if (item?.props?.value !== undefined) {
  //     const [startTime, endTime, day, titleData, link] = item?.props?.value;

  //     const { timezone } = event;

  //     const convertedStartTime = convertToLocalTime(startTime, timezone).format(
  //       "YYYYMMDDTHHmmss"
  //     );

  //     const convertedEndTime = convertToLocalTime(endTime, timezone).format(
  //       "YYYYMMDDTHHmmss"
  //     );

  //     switch (key) {
  //       case "1":
  //         onClickDownloadCalendar(
  //           convertToLocalTime(startTime, timezone),
  //           convertToLocalTime(endTime, timezone),
  //           day,
  //           titleData
  //         );
  //         break;
  //       case "2":
  //         onClickAddGoogleCalendar(
  //           convertedStartTime,
  //           convertedEndTime,
  //           titleData,
  //           link
  //         );
  //         break;
  //       case "3":
  //         onClickAddYahooCalendar(
  //           convertedStartTime,
  //           convertedEndTime,
  //           titleData,
  //           link
  //         );
  //         break;
  //       default:
  //       //
  //     }
  //   }
  // };

  // const downloadDropdownOptions = (
  //   startTimeD,
  //   endTimeDa,
  //   dayDDA,
  //   titleData,
  //   link
  // ) => {
  //   return (
  //     <Menu onClick={handleOnClick}>
  //       <Menu.Item key="1" value={[startTimeD, endTimeDa, dayDDA, titleData]}>
  //         Download ICS File
  //       </Menu.Item>
  //       <Menu.Item
  //         key="2"
  //         value={[startTimeD, endTimeDa, dayDDA, titleData, link]}
  //       >
  //         Add to Google Calendar
  //       </Menu.Item>
  //       <Menu.Item
  //         key="3"
  //         value={[startTimeD, endTimeDa, dayDDA, titleData, link]}
  //       >
  //         Add to Yahoo Calendar
  //       </Menu.Item>
  //     </Menu>
  //   );
  // };

  const planUpgrade = (e) => {
    e.preventDefault();
    e.stopPropagation();
    Emitter.emit(EVENT_TYPES.OPEN_PAYMENT_MODAL);
  };

  useEffect(() => {
    if (event?.description && event?.description?.blocks) {
      setEditor("draft");
    } else {
      setEditor("froala");
    }
  }, [event]);

  useEffect(() => {
    if (event && updatedEvent && event?.id === updatedEvent?.id) {
      if (!isGrowGroups) {
        setEvent({
          ...updatedEvent,
          day: moment(updatedEvent?.date, DataFormat).date(),
          month: MONTH_NAMES[moment(updatedEvent?.date, DataFormat).month()],
        });
        setBulChangeEvent(false);
      } else {
        setGrowGroup(
          {
            ...updatedEvent,
            day: moment(updatedEvent?.date, DataFormat).date(),
            month: MONTH_NAMES[moment(updatedEvent?.date, DataFormat).month()],
          },
          { users: updatedEvent.users }
        );
        setBulChangeEvent(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedEvent, event, setBulChangeEvent]);

  const functionOrderPanels = (panels) => {
    if (panels !== undefined) {
      let arrayFixed = [];
      let num = -1;
      let titlesDateReady;

      const arrayOrderTime = panels.sort((a, b) => {
        let aTime = convertToLocalTime(
          a?.startDate,
          eventCouncil?.timezone
        ).format("YYYY");
        let bTime = convertToLocalTime(b?.startDate).format("YYYY");

        let aTimeRest = convertToLocalTime(
          a?.startDate,
          eventCouncil?.timezone
        ).format("MMDDHHmm");
        let bTimeRest = convertToLocalTime(
          b?.startDate,
          eventCouncil?.timezone
        ).format("MMDDHHmm");

        return Number(bTime - bTimeRest) - Number(aTime - aTimeRest);
      });

      for (let i = 0; i < arrayOrderTime?.length; i++) {
        let dateNow = arrayOrderTime[i]?.startDate;
        let timezone = eventCouncil?.timezone;
        if (
          titlesDateReady !==
          convertToLocalTime(dateNow, timezone)?.format()?.substring(0, 10)
        ) {
          titlesDateReady = convertToLocalTime(dateNow, timezone)
            ?.format()
            ?.substring(0, 10);
          num++;
          if (!arrayFixed[num]) {
            arrayFixed.push([]);
          }
          arrayFixed[num].push(arrayOrderTime[i]);
        } else {
          arrayFixed[num].push(arrayOrderTime[i]);
        }
      }

      return arrayFixed;
    } else {
      return panels;
    }
  };

  const content = (panels) => {
    let startTimeTRA = convertToLocalTime(
      panels?.startDate,
      eventCouncil?.timezone
    );
    let endTimeTRA = convertToLocalTime(
      panels?.endDate,
      eventCouncil?.timezone
    );

    let linkToConnect = ''

    if (eventData?.bulLinkForEachDay === "true") {
      let day = moment(panels?.startDate).utc().format("DD");
      let daySelectec = eventData?.startAndEndTimes?.filter(
        (item) => (item?.startTime === null || item?.startTime === undefined
            ? 0
            : Number(moment(item?.startTime).utc().format("DD"))) === Number(day)
      );

      linkToConnect = daySelectec[0]?.link;
    } else {
      linkToConnect = eventData?.link;
    }

    return (
      <div className="content-collapse" key={panels?.id}>
        <p style={{ margin: "0px", marginTop: "3px" }}>
          <b>Session</b>: {panels?.panelName}
        </p>
        <p style={{ margin: "0px", marginTop: "3px" }}>
          <b>Session Date</b>:{` ${startTimeTRA?.format("LL")}`}
        </p>
        <p
          className="title-collapse"
          style={{ margin: "0px", marginTop: "3px" }}
        >
          <b>Session Start Time:</b> {startTimeTRA?.format("HH:mm a")}{" "}
          {moment.tz.guess()}
        </p>
        <p
          className="title-collapse"
          style={{ margin: "0px", marginTop: "3px" }}
        >
          <b>Session End Time:</b> {endTimeTRA?.format("HH:mm a")}{" "}
          {moment.tz.guess()}
        </p>
        {panels?.typePanel?.length !== 0 && (
          <p
            className="title-collapse"
            style={{ margin: "0px", marginTop: "3px" }}
          >
            <b>Session Type:</b>{" "}
            {EventTypesData !== undefined &&
              panels?.typePanel?.map((category, index) => (
                <span key={index}>
                  {capitalizeWord(EventTypesData[category])}{" "}
                  {panels?.typePanel[index + 1] && `| `}
                </span>
              ))}
          </p>
        )}
        {panels?.hrCreditsBoolean !== undefined &&
          panels?.hrCreditsBoolean !== "" && (
            <p
              className="title-collapse"
              style={{ margin: "0px", marginTop: "3px" }}
            >
              <b>Does this session offer HR credits?: </b>{" "}
              {panels?.hrCreditsBoolean === "No"
                ? "NO"
                : `YES (${panels?.numberOfHRCredits} SHRM + ${panels?.numberOfHRCredits} HRCI ${(panels?.numberOfIHRIMCredits > 0) ? `+ ${panels?.numberOfIHRIMCredits} IHRIM ` :  ''} | Credit Type: ${panels?.typeHrCredits})`}
            </p>
          )}
        {panels?.descriptionPanel !== undefined &&
          panels?.descriptionPanel !== "" && (
            <p
              className="title-collapse"
              style={{ margin: "0px", marginTop: "20px", marginBottom: "30px" }}
            >
              <b>Session Description: </b> {panels?.descriptionPanel}
            </p>
          )}
        <Space direction="vertical">
          <div className="d-flex calendar">
            <Space
              size="middle"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              {/* {`${startTimeTRA.format("MMMM DD")} From ${startTimeTRA.format(
                "HH:mm a"
              )} to ${endTimeTRA.format("HH:mm a")} (${userTimezone})`} */}
              <Dropdown
                overlay={downloadDropdownOptions(
                  startTimeTRA,
                  endTimeTRA,
                  1,
                  linkToConnect,
                  event,
                  eventCouncil,
                  panels?.panelName,
                  panels?.startDate,
                  panels?.endDate
                )}
              >
                <a
                  href="/#"
                  className="ant-dropdown-link"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <Space>
                    {"Download Calendar"}
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
            </Space>
          </div>
        </Space>
        <div className="ajust-contain-here">
          {panels?.CouncilEventPanelists?.filter(
            (user) => user?.isModerator === false
          )?.map((user) => {
            // return <MemberSpeakers key={user?.id} usersPanel={user} />;
            return <CardUser key={user?.id} instructor={user?.User} />;
          })}
        {/* </div>
        <div className="ajust-contain-2"> */}
          {panels?.CouncilEventPanelists?.filter(
            (user) => user?.isModerator === true
          )?.map((user) => {
            // return <MemberSpeakers key={user?.id} usersPanel={user} />;
            return <CardUser key={user?.id} instructor={user?.User} />;
          })}
        </div>
      </div>
    );
  };

  const activeCollapse = (e) => {
    let targetContainer =
      e?.target?.parentElement?.parentElement?.parentElement?.children[1]
        ?.children[0];
    let targetHeight =
      e?.target?.parentElement?.parentElement?.parentElement?.children[1];
    let targetContainerHeight = targetContainer?.clientHeight;

    targetHeight.style.cssText = `height: ${targetContainerHeight}px;`;

    if (e.target.className === "arrow-title") {
      clearTimeout(clockAnimation);
      clockAnimation2 = setTimeout(() => {
        targetHeight.style.cssText = `height: auto;`;
      }, 500);
      targetContainer.style.cssText = "position:relative;";
      e.target.className = "arrow-title-change";
      targetContainer.className = "container-collapse-title";
    } else {
      clockAnimation = setTimeout(() => {
        targetContainer.style.cssText = "position:absolute;";
      }, 490);
      clearTimeout(clockAnimation2);
      setTimeout(() => {
        targetHeight.style.cssText = `height: 0px;`;
      }, 10);
      e.target.className = "arrow-title";
      targetContainer.className = "container-collapse-title-change";
    }
  };

  let allPanelsConcil = functionOrderPanels(
    eventCouncil?.CouncilEventPanels
  )?.map((panels, index) => {
    let startTime = convertToLocalTime(
      panels[0]?.startDate,
      eventCouncil?.timezone
    );

    return (
      <div key={index} id={index}>
        <div className="title-date">
          <div className="container-arrow-title">
            <img
              src={Arrow}
              className="arrow-title"
              alt="arrow-title"
              onClick={(e) => activeCollapse(e)}
            />
          </div>
          {startTime?.format("dddd, MMMM DD")}
          <sup>{startTime?.format("Do").slice(-2)}</sup>
        </div>
        <div className="data-height" style={{ height: "0px" }}>
          <div
            className="container-collapse-title-change"
            style={{ position: "absolute" }}
          >
            {panels?.map((panel, index) => {
              return (
                <CollapseComponent
                  key={index}
                  index={panel?.id}
                  informationCollapse={content(panel)}
                  buttons={<div></div>}
                  className={"container-panel"}
                  bulShowMore={false}
                  bulMessage={panel?.type === "Simulations" ? false : true}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  });

  const allHrCredits = () => {
    let numberOfHRCredits = 0;
    let numberIHRIM = 0

    for (let i = 0; i < eventCouncil?.CouncilEventPanels?.length; i++) {
      if (eventCouncil?.CouncilEventPanels[i]?.hrCreditsBoolean !== "No") {
        numberOfHRCredits = numberOfHRCredits + eventCouncil?.CouncilEventPanels[i]?.numberOfHRCredits;
        numberIHRIM = numberIHRIM + eventCouncil?.CouncilEventPanels[i]?.numberOfIHRIMCredits 
      }
    }

    return `${numberOfHRCredits} SHRM + ${numberOfHRCredits} HRCI ${(numberIHRIM > 0) ? `+ ${numberIHRIM} IHRIM ` :  ''}`;
  };

  const thereAreIHRIM = () => {
    let numberIHRIM = 0

    for (let i = 0; i < eventCouncil?.CouncilEventPanels?.length; i++) {
      if (eventCouncil?.CouncilEventPanels[i]?.hrCreditsBoolean !== "No") {
        numberIHRIM = numberIHRIM + eventCouncil?.CouncilEventPanels[i]?.numberOfIHRIMCredits 
      }
    }

    return (numberIHRIM > 0)
  }

  const fixUser = (data) => {
    return data?.filter((item, index) => {
      return data.indexOf(item) === index;
    });
  };

  const getRandomNumber = () => Math.floor(Math.random() * 1000);

  const filterOnlySpeakersPanels = (data) => {
    let newArray = [];

    data.forEach((e) => {
      newArray = [...newArray, ...e?.CouncilEventPanelists];
    });

    newArray = newArray?.filter((item, index) => {
      return newArray?.map((a) => a?.UserId).indexOf(item?.UserId) === index;
    });

    return newArray;
  };

  const searchTimeZone = (timezone) => {
    let currentTimezone = TimeZoneList.find((item) => item.value === timezone);

    if (currentTimezone) {
      currentTimezone = currentTimezone.utc[0];
    } else {
      currentTimezone = timezone;
    }

    return currentTimezone;
  };

  const newFilterEventsForDays = (data) => {
    let newData = data?.filter((e) => {
      let dateFilter =
        e.channel !== null
          ? moment(e?.endDate, "YYYY-MM-DD hh:mm a")?.format("YYYYMMDDHHmm")
          : moment(
              e?.startAndEndTimes[e.startAndEndTimes?.length - 1]?.endTime,
              "YYYY-MM-DD hh:mm a"
            )?.format("YYYYMMDDHHmm");

      let dateNow = moment(moment.utc(), "YYYY-MM-DD hh:mm a")
        .tz(searchTimeZone(e?.timezone))
        ?.format("YYYYMMDDHHmm");

      return Number(dateNow) <= Number(dateFilter);
    });

    newData = newData?.filter((e) => {
      return e.id !== event.id;
    });

    let arrayFixed = [];
    let num = -1;
    let titlesDateReady;

    for (let i = 0; i < newData?.length; i++) {
      let dateNow = newData[i].startDate;
      let timezone = newData[i].timeZone;
      if (
        titlesDateReady !==
        convertToLocalTime(dateNow, timezone).format().substring(0, 10)
      ) {
        titlesDateReady = convertToLocalTime(dateNow, timezone)
          .format()
          .substring(0, 10);
        num++;
        if (!arrayFixed[num]) {
          arrayFixed.push([]);
        }
        arrayFixed[num].push(newData[i]);
      } else {
        arrayFixed[num].push(newData[i]);
      }
    }

    return arrayFixed;
  };

  const onEventChanged = (event, going, img) => {
    event.going = going;

    onAttend(event, img);
  };

  const filterInvalidDates = (data) => {
    let newTime = data.filter((time) => {
      return time !== null;
    });

    return newTime;
  };

  const TabData = [
    {
      title: "Description",
      key: 1,
      content: () => (
        <>
          <div className="event-details-content">
            <div
              className="event-details-content-actions"
              style={{ marginTop: "-50px" }}
            >
              {/* {(event?.channel === "" || event?.channel === undefined || Number(event?.channel) > 0) ? (

              <DateAvatar day={convertToLocalTime(event?.startDate, event?.timezone).format("DD") || 0} month={event?.month || ""} />
            ) : (
              <DateAvatar day={moment(event?.date, DataFormat).date() || 0} month={event?.month || ""} />
            )} */}
              {/* {(event?.status === "past" && userProfile?.id !== undefined) && (
              <div className="claim-buttons">
                <CustomButton
                  className="claim-digital-certificate"
                  text="Confirm I attended this event"
                  size="md"
                  type="primary outlined"
                  onClick={onClickConfirm}
                />
              </div>
            )} */}
              {event?.status === "confirmed" &&
                userProfile?.id !== undefined && (
                  <React.Fragment>
                    {(userProfile || {})?.memberShip === "premium" ? (
                      <React.Fragment>
                        {/* <CustomButton
                      className="claim-digital-certificate"
                      text="Claim digital certificate"
                      size="lg"
                      type="primary outlined"
                      onClick={onClickClaimDigitalCertificate}
                    /> */}
                        {/* <CustomButton
                      text="Confirm I attended this event"
                      size="lg"
                      type="primary"
                      onClick={onClickClaimCredits}
                    /> */}
                      </React.Fragment>
                    ) : (
                      <CustomButton
                        text="Upgrade to PREMIUM"
                        size="md"
                        type="primary"
                        onClick={planUpgrade}
                      />
                    )}
                  </React.Fragment>
                )}
              {/* {(event.status === "attend" && userProfile?.id !== undefined) && (
              <CustomButton
                text="REGISTER HERE"
                size="lg"
                type="primary"
                onClick={onAttend}
                loading={loading}
              />
            )} */}
              {/* {(event?.channel === "" || event?.channel === undefined || Number(event?.channel) > 0) && (
              <a
                href={(userProfile?.id !== undefined) ? event?.link : "#"}
                style={userProfile?.id !== undefined ? { margin: "0px", padding: "0px" } : { display: "none" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <CustomButton
                  text="Attend"
                  size="md"
                  type="primary"
                />
              </a>
            )}
            {(event?.status === "going" && userProfile?.id !== undefined) && (
              <React.Fragment>
                <div className="going-label">
                  <CheckOutlined />
                  <span>I'm going</span>
                </div>
                <CustomButton
                  className="not-going-btn"
                  text="Not going"
                  size="lg"
                  type="remove"
                  remove={true}
                  onClick={onCancelAttend}
                />
              </React.Fragment>
            )} */}
            </div>

            <h1 className="event-title">{event?.title}</h1>
            <div className="d-flex items-center event-info">
              {event?.channel === "" ||
              event?.channel === undefined ||
              Number(event?.channel) > 0 ? (
                <h5 className="event-card-topic-title">
                  {`${!isGrowGroups ? "Event date" : "Date"}${
                    event?.startDate !== event?.endDate ? "s" : ""
                  }:`}
                  <span>{event?.period2}</span>
                </h5>
              ) : (
                <h5 className="event-card-topic-title">
                  {`${!isGrowGroups ? "Event date" : "Date"}${
                    event?.startDate !== event?.endDate ? "s" : ""
                  }:`}
                  <span>{event?.period}</span>
                </h5>
              )}
              {/* <div className="d-flex items-center">
              <h3 className="event-date">{event.period}</h3>
            </div> */}
              {/* {event?.status === "going" && (
                <Space direction="vertical">
                  {!isEmpty(event?.startAndEndTimes) &&
                    filterInvalidDates(event?.startAndEndTimes)?.map(
                      (time, index) => {
                        const startTime = convertToLocalTime(
                          time?.startTime,
                          event?.timezone
                        );
                        const endTime = convertToLocalTime(
                          time?.endTime,
                          event?.timezone
                        );

                        return (
                          <Dropdown
                            key={time?.startTime}
                            overlay={downloadDropdownOptions(
                              startTime,
                              endTime,
                              index,
                              time?.link,
                              event,
                              eventCouncil,
                              undefined,
                              time?.startTime,
                              time?.endTime
                            )}
                          >
                            <a
                              href="/#"
                              className="ant-dropdown-link"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                            >
                              {event?.startAndEndTimes?.length > 1
                                ? `Download Calendar Day ${index + 1}: ${moment(
                                    startTime
                                  ).format("MMM DD")} `
                                : "Download Calendar"}
                              <DownOutlined />
                            </a>
                          </Dropdown>
                        );
                      }
                    )}
                </Space>
              )} */}
              {/* {isGrowGroups && stateGrowGroup?.status === "accepted" && (
                <Space direction="vertical">
                  {!isEmpty(event?.startAndEndTimes) &&
                    filterInvalidDates(event?.startAndEndTimes)?.map(
                      (time, index) => {
                        const startTime = convertToLocalTime(
                          time?.startTime,
                          event?.timezone
                        );
                        const endTime = convertToLocalTime(
                          time?.endTime,
                          event?.timezone
                        );

                        return (
                          <Dropdown
                            key={time?.startTime}
                            overlay={downloadDropdownOptions(
                              startTime,
                              endTime,
                              index,
                              time?.link,
                              event,
                              eventCouncil,
                              undefined,
                              time?.startTime,
                              time?.endTime
                            )}
                          >
                            <a
                              href="/#"
                              className="ant-dropdown-link"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                            >
                              {event?.startAndEndTimes?.length > 1
                                ? `Download Calendar Day ${index + 1}: ${moment(
                                    startTime
                                  ).format("MMM DD")} `
                                : "Download Calendar"}
                              <DownOutlined />
                            </a>
                          </Dropdown>
                        );
                      }
                    )}
                </Space>
              )} */}
            </div>
            {event?.location && (
              <>
                <h5 className="event-card-topic-title">
                  Event Type:{" "}
                  <span>
                    {event?.location?.map((loc, index) => {
                      if (loc === "online") {
                        return (
                          <React.Fragment key={index}>
                            Online {event?.location[index + 1] ? "and " : ""}
                          </React.Fragment>
                        );
                      }

                      return (
                        <React.Fragment key={index}>
                          In Person {event?.location[index + 1] ? "and " : ""}
                        </React.Fragment>
                      );
                    })}
                  </span>
                </h5>
              </>
            )}
            {event?.ticket && (
              <h5 className="event-card-topic-title">
                Event tickets:
                <span>
                  {event?.ticket === "fee"
                    ? `$${event?.ticketFee} Registration fee`
                    : event?.ticket === "premium"
                    ? "Only PREMIUM members"
                    : capitalizeWord(event?.ticket)}
                </span>
              </h5>
            )}

            {event?.type && (
              <h5 className="event-card-topic-title">
                Content delivery format:
                {event?.type?.map((tp, index) => (
                  <span key={index}>
                    {capitalizeWord(tp)} {event?.type[index + 1] && `|`}
                  </span>
                ))}
              </h5>
            )}

            {event?.categories &&
              event?.categories?.length > 0 &&
              dataCategoriesState !== undefined && (
                <h5 className="event-card-topic-title">
                  {!isGrowGroups ? "Event topics:" : "Topics:"}
                  {event?.categories.map((tp, index) => {
                    return (
                      <span key={index}>
                        {capitalizeWord(dataCategoriesState[tp])}{" "}
                        {event?.categories[index + 1] && `|`}
                      </span>
                    );
                  })}
                </h5>
              )}

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              {/* {event?.status === "going" && userProfile?.id !== undefined && (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "auto",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="going-group-part">
                    <div className="going-label" style={{ marginRight: "0px" }}>
                      <CheckOutlined />
                      <span>I'm going</span>
                    </div>
                    <CustomButton
                      className="not-going-btn"
                      text="Not going"
                      size="sm"
                      type="remove"
                      remove={true}
                      onClick={(e) => onCancelAttend(e)}
                    />
                  </div>
                </div>
              )} */}
              {/* {isGrowGroups &&
                userProfile?.id !== undefined &&
                stateGrowGroup?.status === "accepted" && (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      height: "auto",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="going-group-part">
                      <div
                        className="going-label"
                        style={{ marginRight: "0px" }}
                      >
                        <CheckOutlined />
                        <span>I'm going</span>
                      </div>
                      <CustomButton
                        className="not-going-btn"
                        text="Not going"
                        size="sm"
                        type="remove"
                        remove={true}
                        onClick={(e) => onCancelAttend(e)}
                      />
                    </div>
                  </div>
                )} */}

              {/* {event?.status === "attend" && userProfile?.id !== undefined && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "40px",
                  }}
                >
                  <CustomButton
                    text="REGISTER HERE"
                    size="lg"
                    type="primary"
                    onClick={(e) => {
                      if (!event?.bulCloseEvent) {
                        onAttend2(e);
                        setCurrentTab("5");
                      } else {
                        setPopUp(true);
                      }
                    }}
                    loading={loading}
                  />
                </div>
              )} */}

              {/* {userProfile?.id !== undefined &&
                isGrowGroups &&
                stateGrowGroup?.status !== "accepted" && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "40px",
                    }}
                  >
                    <CustomButton
                      text={btnTextGrowGroup}
                      size="lg"
                      disabled={btnGrowGroupDisabled}
                      type="primary"
                      onClick={(e) => {
                        if (!event?.bulCloseEvent) {
                          onAttend2(e);
                        } else {
                          setPopUp(true);
                        }
                      }}
                      style={{}}
                      loading={loading}
                    />
                  </div>
                )} */}

              {/* {isMobile &&
              event?.status === "attend" &&
              userProfile?.id !== undefined &&
              !isGrowGroups ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    // marginBottom: "40px",
                    alignItems: "center",
                  }}
                >
                  <CustomButton
                    text="REGISTER HERE"
                    size="lg"
                    type="primary"
                    onClick={(e) => {
                      if (!event?.bulCloseEvent) {
                        onAttend2(e);
                        setCurrentTab("5");
                      } else {
                        setPopUp(true);
                      }
                    }}
                    loading={loading}
                  />
                </div>
              ) : (
                isMobile &&
                event?.status === "going" &&
                userProfile?.id !== undefined &&
                !isGrowGroups && (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      height: "auto",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="going-group-part"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "7px",
                        alignItems: "center",
                        width: "256px",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          background: "#00b574",
                          color: "white",
                          height: "40px",
                          // width: "400px",
                          padding: "10px",
                          fontWeight: "700",
                          fontSize: "15px",
                          borderRadius: "5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          // marginRight: "10px",
                        }}
                        // className="btn-hidden-on-hover"
                      >
                        You Are Now Registered!
                      </div>
                      <CustomButton
                        className="not-going-btn"
                        text="Not going"
                        size="sm"
                        type="remove"
                        remove={true}
                        onClick={(e) => {
                          onCancelAttend(e);
                          setCurrentTab("0");
                        }}
                      />
                    </div>
                  </div>
                )
              )} */}
            </div>
          </div>
          {event?.description && (
            <div className="event-details-description">
              <h1 className="event-title">Description</h1>
              {editor === "froala" ? (
                <div
                  className="event-description"
                  dangerouslySetInnerHTML={{
                    __html: (event?.description || {})?.html || "",
                  }}
                />
              ) : (
                <RichEdit data={event?.description} />
              )}
            </div>
          )}
        </>
      ),
    },
    {
      title: "Agenda",
      key: 2,
      content: () => (
        <>
          {event?.relationEventCouncil !== -1 &&
            event !== undefined &&
            bulStatus && (
              <div
                className="event-details-description"
                style={{
                  borderTopLeftRadius: "8px",
                  borderTopRightRadius: "8px",
                  // marginTop: "15px",
                  // marginBottom: "40px",
                  paddingBottom: "50px",
                }}
              >
                {/* <h1 className="event-title">Agenda</h1> */}
                {allPanelsConcil}
              </div>
            )}
        </>
      ),
    },
    {
      title: "Speakers",
      key: 3,
      content: () => (
        <>
          {event?.relationEventCouncil !== -1 &&
            event !== undefined &&
            bulStatus && (
              <div
                className="event-details-description"
                style={{
                  borderTopLeftRadius: "8px",
                  borderTopRightRadius: "8px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "auto",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  {/* <h1 className="event-title">Speakers</h1> */}
                </div>
                <div className="event-peoples">
                  {filterOnlySpeakersPanels(
                    eventCouncil?.CouncilEventPanels
                  )?.map((data, index) => {
                    return (
                      // <div key={index} style={{ width: "250px" }}>
                        // {/* <MemberSpeakers key={data?.id} usersPanel={data} /> */}
                        <CardUser key={data?.id} instructor={data?.User} />
                      // </div>
                    );
                  })}
                </div>
              </div>
            )}
        </>
      ),
    },
    {
      title: "Attendees",
      key: 4,
      content: () => (
        <>
          {event?.usersData?.length > 0 && (
            <div
              className="event-details-description"
              style={{
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
              }}
            >
              {/* <h1 className="event-title" style={{ paddingBottom: "10px" }}>
                Attendees
              </h1> */}
              <div
                style={{
                  width: "100%",
                  height: "auto",
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {event?.usersData &&
                  [...event?.usersData, { type: "finish" }].map((u, index) => {
                    if (
                      u?.type === "finish" &&
                      fixUser(event?.users)?.length > 16 &&
                      event?.usersData?.length === 16
                    ) {
                      return (
                        <Avatar
                          key={index}
                          shape="square"
                          size={45}
                          style={{
                            fontSize: "1rem",
                            borderRadius: "15px",
                            position: "relative",
                            border: "4px solid white",
                            boxSizing: "content-box",
                            fontWeight: "bold",
                            left: `${-15 * index}px`,
                            color: "black",
                            zIndex: `${1 * index}`,
                          }}
                        >
                          +{fixUser(event?.users)?.length - 16}
                        </Avatar>
                      );
                    } else {
                      if (u?.type === "finish") {
                        return (
                          <div key={index} style={{ display: "none" }}></div>
                        );
                      } else {
                        return u?.img ? (
                          <Avatar
                            key={index}
                            shape="square"
                            style={{
                              borderRadius: "15px",
                              position: "relative",
                              left: `${-15 * index}px`,
                              boxSizing: "content-box",
                              border: "4px solid white",
                              zIndex: `${1 * index}`,
                            }}
                            size={45}
                            src={u?.img}
                          />
                        ) : (
                          <Avatar
                            key={index}
                            shape="square"
                            size={45}
                            style={{
                              fontSize: "1rem",
                              borderRadius: "15px",
                              position: "relative",
                              boxSizing: "content-box",
                              fontWeight: "bold",
                              border: "4px solid white",
                              left: `${-15 * index}px`,
                              zIndex: `${1 * index}`,
                            }}
                          >
                            {u?.abbrName}
                          </Avatar>
                        );
                      }
                    }
                  })}
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      title: "HR Credits",
      key: 5,
      content: () => (
        <>
          {event?.relationEventCouncil !== -1 &&
            event !== undefined &&
            bulStatus && (
              <div
                className="event-details-description"
                style={{
                  borderTopRightRadius: "8px",
                  borderTopLeftRadius: "8px",
                  // marginTop: "15px",
                  // marginBottom: "40px",
                  paddingBottom: "50px",
                }}
              >
                {/* <h1 className="event-title">HR Credits</h1> */}
                <p style={{ marginTop: "20px", marginBottom: "20px" }}>
                  Hacking HR is recognized as an approved training provider with the <b>Society for Human Resource Management</b> (SHRM) and the <b>HR Certification Institute</b> (HRCI). Members of these organizations are eligible to receive <b>personal development credits</b> (PDCs) upon completion of the learning sessions in this event. The content of this event is free (live or the post-event recorded videos), but to get access to the HR (SHRM/HRCI) credits and our digital certificate of participation you must have a premium account in the Hacking HR LAB. The codes for the HR credits are generally available a week later in the Hacking HR LAB {'>'} My Learning {'>'} Event Videos. Thank you!
                </p>
                <h5
                  className="event-card-topic-title"
                  style={{ marginTop: "20px" }}
                >
                  This event offers:
                  <span>{allHrCredits()}</span>
                </h5>
                <div
                  style={{
                    width: "100%",
                    height: "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "20px",
                    flexDirection: 'row',
                    flexWrap:'wrap'
                  }}
                >
                <div className="container-logo-hr-hr-e"></div>
                {thereAreIHRIM() && <div className="container-logo-hr-hr-e2"></div>}
              </div>
              </div>
            )}
        </>
      ),
      // disabled: menteeProfile?.topics?.length < 1,
    },
    (event?.status !== "attend" && event?.status !== "confirmed") && {
      title: "Download Calendars",
      key: 6,
      content: () => (
        <>
          {(event?.status !== "attend" && event?.status !== "confirmed") && (
            <div
              className="event-details-description"
              style={{
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
              }}
            >
              <h1 className="event-title">Calendar Invites</h1>
              <Space direction="vertical">
                {!isEmpty(event?.startAndEndTimes) &&
                  filterInvalidDates(event?.startAndEndTimes)?.map(
                    (time, index) => {
                      const startTime = convertToLocalTime(
                        time?.startTime,
                        event?.timezone
                      );
                      const endTime = convertToLocalTime(
                        time?.endTime,
                        event?.timezone
                      );

                      return (
                        <Dropdown
                          key={time?.startTime}
                          overlay={downloadDropdownOptions(
                            startTime,
                            endTime,
                            index,
                            time?.link,
                            event,
                            eventCouncil,
                            undefined,
                            time?.startTime,
                            time?.endTime,
                          )}
                        >
                          <a
                            href="/#"
                            className="ant-dropdown-link"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            {event?.startAndEndTimes?.length > 1
                              ? `Download Calendar Day ${index + 1}: ${moment(
                                  startTime
                                ).format("MMM DD")} `
                              : "Download Calendar"}
                            <DownOutlined />
                          </a>
                        </Dropdown>
                      );
                    }
                  )}
              </Space>
            </div>
          )}
        </>
      ),
    },
  ].filter(Boolean);

  return (
    <div className="container-event-page">
      <div className="container-space-left">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          {isGrowGroups ? <h2>Other GrowGroups</h2> : <h2>Other Events</h2>}
        </div>
        {newFilterEventsForDays(!isGrowGroups ? allEvents : allGrows)?.map(
          (eventArray, index) => {
            return (
              <React.Fragment key={index}>
                {/* <div style={{width:'100%',height:'auto',display:'flex',justifyContent:'center'}}>
                  <h3
                    style={{paddingTop:'10px',paddingBottom:'10px',width:'100%',maxWidth:'1050px'}}
                  >{moment(eventArray[0].startDate, "YYYY-MM-DD hh:mm a")?.format('MMMM Do YYYY')}</h3>
                </div> */}
                {eventArray.map((event, index) => {
                  return (
                    <div
                      style={{
                        width: "100%",
                        height: "auto",
                        marginBottom: "20px",
                      }}
                      key={`${index}-${getRandomNumber()}`}
                    >
                      <EventCard
                        edit={false}
                        data={event}
                        userProfile={userProfile}
                        onAttend={(going, img) =>
                          onEventChanged(event, going, img)
                        }
                        onClick={() => {
                          if(Number(event.id) === 184){
                            window.open('https://www.thepeoplesummit.io/', "_blank");
                          }else{
                            setCurrentTab("0");
                            setBulChangeEvent(true);
                            setEventR({
                              ...event,
                              usersData: allEventsUsersSketch[event.id],
                            });
                          }
                          
                        }}
                        boxes={{}}
                        onMenuClick={() => {}}
                        onConfirmAttendance={() => {}}
                        onConfirmCredit={() => {}}
                        isGrowGroups={isGrowGroups}
                        setActiveMessages={() => {}}
                        isMobile={isMobile}
                        usersData={allEventsUsersSketch[event.id]}
                        setLoading={() => {}}
                        esIncoming={true}
                        caseLeft={true}
                      />
                    </div>
                  );
                })}
              </React.Fragment>
            );
          }
        )}
      </div>
      <div
        className="event-details-page"
        style={visible ? { display: "block" } : { display: "none" }}
      >
        {!isGrowGroups &&
          (isMobile ? (
            <div className="container-back" onClick={onClose}>
              <div className="container-back__content-top">
                <div className="container-back__content-top-back">
                  <img src={IconBack} alt="icon-back" />
                </div>
                <h4>Back to Events</h4>
              </div>
            </div>
          ) : (
            <div className="container-position">
              <div className="container-back22" onClick={onClose}>
                <div className="container-back__content-top">
                  <div className="container-back__content-top-back">
                    <img src={IconBack} alt="icon-back" />
                  </div>
                  <h4>Back to Events</h4>
                </div>
              </div>
            </div>
          ))}
        {isGrowGroups && (
          <div className="container-back">
            <div className="container-back__content-top" onClick={onClose}>
              <div className="container-back__content-top-back">
                <img src={IconBack} alt="icon-back" />
              </div>
              <h4>Back to GrowGroups</h4>
            </div>
          </div>
        )}
        {showFirewall && (
          <div
            className="event-details-firewall"
            onClick={() => setShowFirewall(false)}
          >
            <div className="upgrade-notification-panel" onClick={planUpgrade}>
              <h3>
                Upgrade to a PREMIUM Membership and get unlimited access to the
                LAB features
              </h3>
            </div>
          </div>
        )}
        {isGrowGroups && (
          <div className="event-details-headerr">
            {!isEmpty(event?.images) && (
              <img src={event?.images[0]} alt="event-img" />
            )}
            {isEmpty(event?.images) && event?.image2 && (
              <img src={event?.image2} alt="event-img" />
            )}
            {isEmpty(event?.images) && !event?.image2 && event?.image && (
              <img src={event?.image} alt="event-img" />
            )}
          </div>
        )}

        {isGrowGroups && (
          <div className="event-details-content">
            <div
              className="event-details-content-actions"
              style={{ marginTop: "-50px" }}
            >
              {/* {(event?.channel === "" || event?.channel === undefined || Number(event?.channel) > 0) ? (
              <DateAvatar day={convertToLocalTime(event?.startDate, event?.timezone).format("DD") || 0} month={event?.month || ""} />
            ) : (
              <DateAvatar day={moment(event?.date, DataFormat).date() || 0} month={event?.month || ""} />
            )} */}
              {/* {(event?.status === "past" && userProfile?.id !== undefined) && (
              <div className="claim-buttons">
                <CustomButton
                  className="claim-digital-certificate"
                  text="Confirm I attended this event"
                  size="md"
                  type="primary outlined"
                  onClick={onClickConfirm}
                />
              </div>
            )} */}
              {event?.status === "confirmed" &&
                userProfile?.id !== undefined && (
                  <React.Fragment>
                    {(userProfile || {})?.memberShip === "premium" ? (
                      <React.Fragment>
                        {/* <CustomButton
                      className="claim-digital-certificate"
                      text="Claim digital certificate"
                      size="lg"
                      type="primary outlined"
                      onClick={onClickClaimDigitalCertificate}
                    /> */}
                        {/* <CustomButton
                      text="Confirm I attended this event"
                      size="lg"
                      type="primary"
                      onClick={onClickClaimCredits}
                    /> */}
                      </React.Fragment>
                    ) : (
                      <CustomButton
                        text="Upgrade to PREMIUM"
                        size="md"
                        type="primary"
                        onClick={planUpgrade}
                      />
                    )}
                  </React.Fragment>
                )}
              {/* {(event.status === "attend" && userProfile?.id !== undefined) && (
              <CustomButton
                text="REGISTER HERE"
                size="lg"
                type="primary"
                onClick={onAttend}
                loading={loading}
              />
            )} */}
              {/* {(event?.channel === "" || event?.channel === undefined || Number(event?.channel) > 0) && (
              <a
                href={(userProfile?.id !== undefined) ? event?.link : "#"}
                style={userProfile?.id !== undefined ? { margin: "0px", padding: "0px" } : { display: "none" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <CustomButton
                  text="Attend"
                  size="md"
                  type="primary"
                />
              </a>
            )}
            {(event?.status === "going" && userProfile?.id !== undefined) && (
              <React.Fragment>
                <div className="going-label">
                  <CheckOutlined />
                  <span>I'm going</span>
                </div>
                <CustomButton
                  className="not-going-btn"
                  text="Not going"
                  size="lg"
                  type="remove"
                  remove={true}
                  onClick={onCancelAttend}
                />
              </React.Fragment>
            )} */}
            </div>

            <h1 className="event-title">{event?.title}</h1>
            <div className="d-flex items-center event-info">
              {event?.channel === "" ||
              event?.channel === undefined ||
              Number(event?.channel) > 0 ? (
                <h5 className="event-card-topic-title">
                  {`${!isGrowGroups ? "Event date" : "Date"}${
                    event?.startDate !== event?.endDate ? "s" : ""
                  }:`}
                  <span>{event?.period2}</span>
                </h5>
              ) : (
                <h5 className="event-card-topic-title">
                  {`${!isGrowGroups ? "Event date" : "Date"}${
                    event?.startDate !== event?.endDate ? "s" : ""
                  }:`}
                  <span>{event?.period}</span>
                </h5>
              )}
              {/* <div className="d-flex items-center">
              <h3 className="event-date">{event.period}</h3>
            </div> */}
              {(event?.status !== "attend" && event?.status !== "confirmed") && (
                <Space direction="vertical">
                  {!isEmpty(event?.startAndEndTimes) &&
                    filterInvalidDates(event?.startAndEndTimes)?.map(
                      (time, index) => {
                        const startTime = convertToLocalTime(
                          time?.startTime,
                          event?.timezone
                        );
                        const endTime = convertToLocalTime(
                          time?.endTime,
                          event?.timezone
                        );

                        return (
                          <Dropdown
                            key={time?.startTime}
                            overlay={downloadDropdownOptions(
                              startTime,
                              endTime,
                              index,
                              time?.link,
                              event,
                              eventCouncil,
                              undefined,
                              time?.startTime,
                              time?.endTime
                            )}
                          >
                            <a
                              href="/#"
                              className="ant-dropdown-link"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                            >
                              {event?.startAndEndTimes?.length > 1
                                ? `Download Calendar Day ${index + 1}: ${moment(
                                    startTime
                                  ).format("MMM DD")} `
                                : "Download Calendar"}
                              <DownOutlined />
                            </a>
                          </Dropdown>
                        );
                      }
                    )}
                </Space>
              )}
              {isGrowGroups && stateGrowGroup?.status === "accepted" && (
                <Space direction="vertical">
                  {!isEmpty(event?.startAndEndTimes) &&
                    filterInvalidDates(event?.startAndEndTimes)?.map(
                      (time, index) => {
                        const startTime = convertToLocalTime(
                          time?.startTime,
                          event?.timezone
                        );
                        const endTime = convertToLocalTime(
                          time?.endTime,
                          event?.timezone
                        );

                        return (
                          <Dropdown
                            key={time?.startTime}
                            overlay={downloadDropdownOptions(
                              startTime,
                              endTime,
                              index,
                              time?.link,
                              event,
                              eventCouncil,
                              undefined,
                              time?.startTime,
                              time?.endTime,
                            )}
                          >
                            <a
                              href="/#"
                              className="ant-dropdown-link"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                            >
                              {event?.startAndEndTimes?.length > 1
                                ? `Download Calendar Day ${index + 1}: ${moment(
                                    startTime
                                  ).format("MMM DD")} `
                                : "Download Calendar"}
                              <DownOutlined />
                            </a>
                          </Dropdown>
                        );
                      }
                    )}
                </Space>
              )}
            </div>
            {event?.location && !isGrowGroups && (
              <>
                <h5 className="event-card-topic-title">
                  Event Type:{" "}
                  <span>
                    {event?.location?.map((loc, index) => {
                      if (loc === "online") {
                        return (
                          <React.Fragment key={index}>
                            Online {event?.location[index + 1] ? "and " : ""}
                          </React.Fragment>
                        );
                      }

                      return (
                        <React.Fragment key={index}>
                          In Person {event?.location[index + 1] ? "and " : ""}
                        </React.Fragment>
                      );
                    })}
                  </span>
                </h5>
              </>
            )}
            {event?.ticket && !isGrowGroups && (
              <h5 className="event-card-topic-title">
                Event tickets:
                <span>
                  {event?.ticket === "fee"
                    ? `$${event?.ticketFee} Registration fee`
                    : event?.ticket === "premium"
                    ? "Only PREMIUM members"
                    : capitalizeWord(event?.ticket)}
                </span>
              </h5>
            )}

            {event?.type && (
              <h5 className="event-card-topic-title">
                Content delivery format:
                {event?.type?.map((tp, index) => (
                  <span key={index}>
                    {capitalizeWord(tp)} {event?.type[index + 1] && `|`}
                  </span>
                ))}
              </h5>
            )}

            {event?.categories &&
              event?.categories?.length > 0 &&
              dataCategoriesState !== undefined && (
                <h5 className="event-card-topic-title">
                  {!isGrowGroups ? "Event topics:" : "Topics:"}
                  {event?.categories.map((tp, index) => {
                    return (
                      <span key={index}>
                        {capitalizeWord(dataCategoriesState[tp])}{" "}
                        {event?.categories[index + 1] && `|`}
                      </span>
                    );
                  })}
                </h5>
              )}

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              {/* {event?.status === "going" &&
                userProfile?.id !== undefined &&
                !isGrowGroups && (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      height: "auto",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="going-group-part">
                      <div
                        className="going-label"
                        style={{ marginRight: "0px" }}
                      >
                        <CheckOutlined />
                        <span>I'm going</span>
                      </div>
                      <CustomButton
                        className="not-going-btn"
                        text="Not going"
                        size="sm"
                        type="remove"
                        remove={true}
                        onClick={(e) => onCancelAttend(e)}
                      />
                    </div>
                  </div>
                )} */}
              {isGrowGroups &&
                userProfile?.id !== undefined &&
                stateGrowGroup?.status === "accepted" && (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      height: "auto",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="going-group-part">
                      <div
                        className="going-label"
                        style={{ marginRight: "0px" }}
                      >
                        <CheckOutlined />
                        <span>I'm going</span>
                      </div>
                      <CustomButton
                        className="not-going-btn"
                        text="Not going"
                        size="sm"
                        type="remove"
                        remove={true}
                        onClick={(e) => onCancelAttend(e)}
                      />
                    </div>
                  </div>
                )}

              {event?.status === "attend" &&
                userProfile?.id !== undefined &&
                !isGrowGroups && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "40px",
                    }}
                  >
                    <CustomButton
                      text="REGISTER HERE"
                      size="lg"
                      type="primary"
                      onClick={(e) => {
                        if (!event?.bulCloseEvent) {
                          onAttend2(e);
                        } else {
                          setPopUp(true);
                        }
                      }}
                      loading={loading}
                    />
                  </div>
                )}

              {userProfile?.id !== undefined &&
                isGrowGroups &&
                stateGrowGroup?.status !== "accepted" && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "40px",
                    }}
                  >
                    <CustomButton
                      text={btnTextGrowGroup}
                      size="lg"
                      disabled={btnGrowGroupDisabled}
                      type="primary"
                      onClick={(e) => {
                        if (!event?.bulCloseEvent) {
                          onAttend2(e);
                        } else {
                          setPopUp(true);
                        }
                      }}
                      loading={loading}
                    />
                  </div>
                )}
            </div>
          </div>
        )}

        {isGrowGroups && event?.description && (
          <div className="event-details-description">
            <h1 className="event-title">Description</h1>
            {editor === "froala" ? (
              <div
                className="event-description"
                dangerouslySetInnerHTML={{
                  __html: (event?.description || {})?.html || "",
                }}
              />
            ) : (
              <RichEdit data={event?.description} />
            )}
          </div>
        )}

        {!isGrowGroups && (
          <>
            <div className="event-details-headerr">
              {!isEmpty(event?.images) && (
                <img src={event?.images[0]} alt="event-img" />
              )}
              {isEmpty(event?.images) && event?.image2 && (
                <img src={event?.image2} alt="event-img" />
              )}
              {isEmpty(event?.images) && !event?.image2 && event?.image && (
                <img src={event?.image} alt="event-img" />
              )}

              {
                // !isMobile &&
                event?.status === "attend" &&
                userProfile?.id !== undefined &&
                !isGrowGroups ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      // marginBottom: "40px",
                      alignItems: "center",
                      position: "absolute",
                      bottom: "10px",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        padding: "10px",
                        borderRadius: "8px",
                        backdropFilter: "blur(5px)",
                      }}
                    >
                      <CustomButton
                        text="REGISTER HERE"
                        size="lg"
                        type="primary"
                        onClick={(e) => {
                          if (!event?.bulCloseEvent) {
                            onAttend2(e);
                            setCurrentTab("5");
                          } else {
                            setPopUp(true);
                          }
                        }}
                        loading={loading}
                      />
                    </div>
                  </div>
                ) : (
                  // !isMobile &&
                  (event?.status !== "attend" && event?.status !== "confirmed") &&
                  userProfile?.id !== undefined &&
                  !isGrowGroups && (
                    <div
                      style={{
                        position: "absolute",
                        bottom: "10px",
                        display: "flex",
                        width: "100%",
                        height: "auto",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          padding: "10px",
                          borderRadius: "8px",
                          backdropFilter: "blur(5px)",
                        }}
                      >
                        <div
                          className="going-group-part container-hidden-on-hover"
                          style={{
                            display: "flex",
                            gap: "7px",
                            height: "45px",
                            alignItems: "center",
                            width: "256px",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              background: "#00b574",
                              color: "white",
                              height: "40px",
                              // width: "400px",
                              padding: "10px",
                              fontWeight: "700",
                              fontSize: "20px",
                              borderRadius: "5px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              // marginRight: "10px",
                            }}
                            className="btn-hidden-on-hover"
                          >
                            You Are Now Registered!
                          </div>
                          {/* <div
                          style={{
                            padding: "10px",
                            borderRadius: "8px",
                            backdropFilter: "blur(5px)",
                          }}
                        > */}
                          <CustomButton
                            className="not-going-btn btn-show-on-hover"
                            text="Not going"
                            size="sm"
                            type="remove"
                            remove={true}
                            onClick={(e) => {
                              onCancelAttend(e);
                              setCurrentTab("0");
                            }}
                          />
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  )
                )
              }
            </div>
          </>
        )}

        {!isGrowGroups && (
          <Tabs
            data={TabData}
            current={currentTab}
            onChange={setCurrentTab}
            tabBarExtraContent={
              undefined
              // !isMobile &&
              // event?.status === "attend" &&
              // userProfile?.id !== undefined &&
              // !isGrowGroups ? (
              //   <div
              //     style={{
              //       display: "flex",
              //       justifyContent: "center",
              //       // marginBottom: "40px",
              //       alignItems: "center",
              //     }}
              //   >
              //     <CustomButton
              //       text="REGISTER HERE"
              //       size="lg"
              //       type="primary"
              //       onClick={(e) => {
              //         if (!event?.bulCloseEvent) {
              //           onAttend2(e);
              //           setCurrentTab("5");
              //         } else {
              //           setPopUp(true);
              //         }
              //       }}
              //       loading={loading}
              //     />
              //   </div>
              // ) : (
              //   !isMobile &&
              //   event?.status === "going" &&
              //   userProfile?.id !== undefined &&
              //   !isGrowGroups && (
              //     <div
              //       style={{
              //         display: "flex",
              //         width: "100%",
              //         height: "auto",
              //         justifyContent: "center",
              //         alignItems: "center",
              //       }}
              //     >
              //       <div
              //         className="going-group-part container-hidden-on-hover"
              //         style={{
              //           display: "flex",
              //           gap: "7px",
              //           alignItems: "center",
              //           width: "256px",
              //           justifyContent: "center",
              //         }}
              //       >
              //         <div
              //           style={{
              //             background: "#00b574",
              //             color: "white",
              //             height: "40px",
              //             // width: "400px",
              //             padding: "10px",
              //             fontWeight: "700",
              //             fontSize: "20px",
              //             borderRadius: "5px",
              //             display: "flex",
              //             justifyContent: "center",
              //             alignItems: "center",
              //             // marginRight: "10px",
              //           }}
              //           className="btn-hidden-on-hover"
              //         >
              //           You Are Now Registered!
              //         </div>
              //         <CustomButton
              //           className="not-going-btn btn-show-on-hover"
              //           text="Not going"
              //           size="sm"
              //           type="remove"
              //           remove={true}
              //           onClick={(e) => {
              //             onCancelAttend(e);
              //             setCurrentTab("0");
              //           }}
              //         />
              //       </div>
              //     </div>
              //   )
              // )
            }
            // tabPosition={"bottom"}
          />
        )}

        {isGrowGroups && event?.usersData?.length > 0 && (
          <div className="event-details-description">
            <h1 className="event-title" style={{ paddingBottom: "10px" }}>
              Attendees
            </h1>
            <div
              style={{
                width: "100%",
                height: "auto",
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {event?.usersData &&
                [...event?.usersData, { type: "finish" }].map((u, index) => {
                  if (
                    u?.type === "finish" &&
                    fixUser(event?.users)?.length > 16 &&
                    event?.usersData?.length === 16
                  ) {
                    return (
                      <Avatar
                        key={index}
                        shape="square"
                        size={45}
                        style={{
                          fontSize: "1rem",
                          borderRadius: "15px",
                          position: "relative",
                          border: "4px solid white",
                          boxSizing: "content-box",
                          fontWeight: "bold",
                          left: `${-15 * index}px`,
                          color: "black",
                          zIndex: `${1 * index}`,
                        }}
                      >
                        +{fixUser(event?.users)?.length - 16}
                      </Avatar>
                    );
                  } else {
                    if (u?.type === "finish") {
                      return (
                        <div key={index} style={{ display: "none" }}></div>
                      );
                    } else {
                      return u?.img ? (
                        <Avatar
                          key={index}
                          shape="square"
                          style={{
                            borderRadius: "15px",
                            position: "relative",
                            left: `${-15 * index}px`,
                            boxSizing: "content-box",
                            border: "4px solid white",
                            zIndex: `${1 * index}`,
                          }}
                          size={45}
                          src={u?.img}
                        />
                      ) : (
                        <Avatar
                          key={index}
                          shape="square"
                          size={45}
                          style={{
                            fontSize: "1rem",
                            borderRadius: "15px",
                            position: "relative",
                            boxSizing: "content-box",
                            fontWeight: "bold",
                            border: "4px solid white",
                            left: `${-15 * index}px`,
                            zIndex: `${1 * index}`,
                          }}
                        >
                          {u?.abbrName}
                        </Avatar>
                      );
                    }
                  }
                })}
            </div>
          </div>
        )}
        {isGrowGroups && false && (
          <div className="event-details-description">
            <div
              style={{
                width: "100%",
                height: "auto",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <h1 className="event-title">Facilitators</h1>
            </div>
            <div className="event-peoples">
              {event?.EventInstructors?.map((eventInstructor, index) => {
                const instructor = eventInstructor?.Instructor;

                return (
                  <div key={index} className="event-instructor">
                    <Avatar
                      src={instructor?.image}
                      alt="instructor-image"
                      size={128}
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        display: "flex",
                      }}
                    />
                    <div className="event-instructor-name">
                      {instructor?.name}
                    </div>
                    <Tooltip title={instructor?.description}>
                      <div className="event-instructor-name truncate">
                        {instructor?.description}
                      </div>
                    </Tooltip>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {isGrowGroups && (
          <div className="event-details-description">
            <div
              style={{
                width: "100%",
                height: "auto",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <h1 className="event-title">Facilitators</h1>
            </div>
            <div className="event-peoples">
              {instructorsGrow?.map((instructor, index) => {
                const { img, firstName } = instructor;

                return (
                  <div
                    key={index}
                    className="event-instructor"
                    style={{ marginRight: "5px" }}
                  >
                    <Avatar
                      src={img || ""}
                      alt="instructor-image"
                      size={60}
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        display: "flex",
                      }}
                    />
                    <div className="event-instructor-name">{firstName}</div>
                    {/* <Tooltip title={instructor?.description}>
                      <div className="event-instructor-name truncate">
                        {instructor?.description}
                      </div>
                    </Tooltip> */}
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {/* {event?.relationEventCouncil !== -1 &&
          event !== undefined &&
          bulStatus &&
          !isGrowGroups && (
            <div className="event-details-description">
              <div
                style={{
                  width: "100%",
                  height: "auto",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <h1 className="event-title">Speakers</h1>
              </div>
              <div className="event-peoples">
                {filterOnlySpeakersPanels(
                  eventCouncil?.CouncilEventPanels
                )?.map((data, index) => {
                  return (
                    <div key={index} style={{ width: "250px" }}>
                      <MemberSpeakers key={data?.id} usersPanel={data} />
                    </div>
                  );
                })}
              </div>
            </div>
          )} */}
        {/* {(event?.status === "attend" && userProfile?.id !== undefined) && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CustomButton
              text="REGISTER HERE"
              size="lg"
              type="primary"
              onClick={(e) => {
                if (!event?.bulCloseEvent) {
                  onAttend(e)
                } else {
                  setPopUp(true)
                }
              }}
              style={{}}
              loading={loading}
            />
          </div>
        )}
        {(event?.status === "going" && userProfile?.id !== undefined) && (
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '40px' }}>
            <div style={{
              background: '#00b574', color: 'white', height: '40px', width: '400px', padding: '10px', fontWeight: '700', fontSize: '20px', borderRadius: '5px',
              display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '10px'
            }}>
              You are now registered for this event!
            </div>
          </div>
        )} */}
        {/* {event?.relationEventCouncil !== -1 &&
          event !== undefined &&
          bulStatus &&
          !isGrowGroups && (
            <div
              className="event-details-description"
              style={{
                marginTop: "15px",
                marginBottom: "40px",
                paddingBottom: "50px",
              }}
            >
              <h1 className="event-title">Agenda</h1>
              {allPanelsConcil}
            </div>
          )} */}
        {/* {event?.relationEventCouncil !== -1 &&
          event !== undefined &&
          bulStatus &&
          !isGrowGroups && (
            <div
              className="event-details-description"
              style={{
                marginTop: "15px",
                marginBottom: "40px",
                paddingBottom: "50px",
              }}
            >
              <h1 className="event-title">HR Credits</h1>
              <p style={{ marginTop: "20px", marginBottom: "20px" }}>
                Hacking HR is recognized as an approved training provider with the <b>Society for Human Resource Management</b> (SHRM) and the <b>HR Certification Institute</b> (HRCI). Members of these organizations are eligible to receive <b>personal development credits</b> (PDCs) upon completion of the learning sessions in this event. The content of this event is free (live or the post-event recorded videos), but to get access to the HR (SHRM/HRCI) credits and our digital certificate of participation you must have a premium account in the Hacking HR LAB. The codes for the HR credits are generally available a week later in the Hacking HR LAB {'>'} My Learning {'>'} Event Videos. Thank you!
              </p>
              <h5
                className="event-card-topic-title"
                style={{ marginTop: "20px" }}
              >
                This event offers:
                <span>{allHrCredits()}</span>
              </h5>
            </div>
          )} */}
      </div>
      <div className="container-space-right"></div>
      <Modal
        className={clsx("custom-modal")}
        wrapClassName={clsx("custom-modal-wrap")}
        centered
        onCancel={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setPopUp(false);
        }}
        visible={popUp}
        closable={true}
        footer={[]}
        width="500px"
        style={{ top: "100px" }}
        title={
          <div
            className="custom-modal-title"
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <div className="custom-modal-logo">
              <img src={IconLogo} alt="custom-logo" />
            </div>
          </div>
        }
        closeIcon={<CloseCircleFilled className="custom-modal-close" />}
      >
        <div className="library-claim-modal">
          <p style={{ textAlign: "center", fontSize: "18px" }}>
            This event is sold out.
          </p>
        </div>
      </Modal>
    </div>
  );
};

EventPage.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool,
  eventData: PropTypes.object,
  onClose: PropTypes.func,
  onConfirmCredit: PropTypes.func,
  filter: PropTypes.object,
};

EventPage.defaultProps = {
  title: "",
  visible: false,
  eventPage: {},
  onClose: () => {},
  onConfirmCredit: () => {},
  filter: {},
};

const mapStateToProps = (state) => ({
  userProfile: homeSelector(state).userProfile,
  allUsers: homeSelector(state).allUsers,
  updatedEvent: eventSelector(state).updatedEvent,
  channel: channelSelector(state).selectedChannel,
  allCategories: categorySelector(state).categories,
  // allEventsUsersSketch: eventSelector(state).allEventsUsersSketch,
  isMobile: envSelector(state).isMobile,
  ...councilEventSelector,
});

const mapDispatchToProps = {
  getChannelEvents,
  ...homeActions,
  ...eventActions,
  ...councilEventActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventPage);
