import { Form, Button } from "antd";
import DiscussionBoardComment from "./DiscussionBoardComment";
import DiscussionBoardCommentReply from "./DiscussionBoardCommentReply";
import React, { useState } from "react";
import TextArea from "antd/lib/input/TextArea";
import { useCreateCertificateCommentReply } from "./hooks/useCreateCertificateCommentReply";
import { useMutation } from "@tanstack/react-query";
import httpClientCron from "../../api/module/httpClientCron";

export default function DiscussionBoardCommentWrapper({
  comment,
  index,
  userProfile,
  handleReplyClick,
  certificateUserId,
  isGroupDiscussionBoard,
  scrollToBottom
}) {
  const [isReplying, setIsReplying] = useState(false);
  const [content, setContent] = useState("");

  const createCommentReply = useMutation({
    mutationFn: async ({ isGroupDiscussionBoard, id, data }) => {
      if (isGroupDiscussionBoard) {
        const response = await httpClientCron.post(
          `private/certificate-group-comment/${id}/reply`,
          data
        );

        return response.data;
      } else {
        const response = await httpClientCron.post(
          `private/certificate-comment/${id}/reply`,
          data
        );

        return response.data;
      }
    },
    onSuccess: () => {
      setIsReplying(false);
      setContent("");
    },
  });

  const replies = isGroupDiscussionBoard
    ? comment.CertificateGroupCommentReplies
    : comment.CertificateCommentReplies;

  return (
    <DiscussionBoardComment
      key={comment.content}
      comment={comment}
      index={index}
      userProfile={userProfile}
      handleReplyClick={() => {
        setIsReplying((prev) => !prev);
      }}
      certificateUserId={certificateUserId}
      isGroupDiscussionBoard={isGroupDiscussionBoard}
    >
      {replies.map((reply, replyIndx) => {
        return (
          <DiscussionBoardCommentReply
            key={reply.content}
            comment={reply}
            index={index}
            userProfile={userProfile}
            handleReplyClick={handleReplyClick}
            certificateUserId={certificateUserId}
            isGroupDiscussionBoard={isGroupDiscussionBoard}
          />
        );
      })}
      {isReplying && (
        <div
          style={{
            padding: "1rem",
          }}
        >
          <Form.Item>
            <TextArea
              rows={2}
              onChange={(e) => setContent(e.target.value)}
              value={content}
              placeholder="Add comment"
            />
          </Form.Item>
          <div
            style={{
              display: "flex",
              justifyItems: "center",
              alignItems: "center",
            }}
          >
            <Button
              htmlType="submit"
              loading={createCommentReply.isLoading}
              onClick={() =>
                createCommentReply.mutate({
                  data: { content },
                  id: comment.id,
                  isGroupDiscussionBoard,
                })
              }
              type="primary"
              style={{ marginTop: ".5rem", marginRight: "1rem" }}
            >
              Reply
            </Button>
            <Button
              htmlType="button"
              onClick={() => {
                setIsReplying(false);
                setContent("");
              }}
              type="secondary"
              style={{ marginTop: ".5rem" }}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
    </DiscussionBoardComment>
  );
}
