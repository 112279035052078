import httpClient from "./httpClient";
export const createBlogPost = ({ blogpost }) => {
  return httpClient.post(`private/blogpost`, { ...blogpost });
};

export const saveBlogPost = ({ blogpost }) => {
  return httpClient.post(`private/blogpostSave`, { ...blogpost });
};

export const searchBlogPost = ({ filters = {}, page }) => {
  let params = {
    ...filters,
    page: page || 1,
  };

  let data = {...filters}

  delete params.taxonomys

  const parsedFilter = Object.keys(params)
    .map((item) => `${item}=${params[item]}`)
    .join("&");

  return httpClient.get(`public/blogpost?${parsedFilter}`,{
    params:{
      taxonomys: data?.taxonomys ? ((Object.keys(data?.taxonomys).length > 0) ? data?.taxonomys : null ) : null
    }
  });
};

export const searchBlogPostForLinkBuilder = (text) => {
  return httpClient.get(`public/blogpostForLink?text=${text}`);
};

export const getBlogPostByChannelId = ({ ChannelId }) => {
  return httpClient.get(`public/blogpost/${ChannelId}`);
};

export const getBlogPostByUser = () => {
  return httpClient.get(`private/blogpostt/UserId`);
}

export const getBlogPost = (blogPostId,idUser) => {
  return httpClient.get(`public/blogpost/blog/${blogPostId}?idUser=${idUser}`);
};

export const updateBlogPost = ({ blogPostId, data }) => {
  return httpClient.put(`private/blogpost/${blogPostId}`, {
    ...data,
  });
};

export const deleteBlogPost = ({ blogPostId }) => {
  return httpClient.delete(`private/blogpost/${blogPostId}`);
};

export const getImagesCargEndPoint = (data) => {
  return httpClient.post(`private/blogpostCargImages`, data);
}
