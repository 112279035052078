import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ChatTab from "./ChatTab";
import "../style.scss";
import { Skeleton, Tabs } from "antd";
import Search from "antd/es/input/Search";
import debounce from "lodash/debounce";
import { CloseOutlined } from "@ant-design/icons";
import { useChatMessageStore } from "../store";
import { httpClientCron } from "../../../api/module/httpClientCron";
import SearchUserList from "../SearchUsersList";
import GroupChatTab from "../GroupChatTab";

export default function LastChatMessages({ userProfile, slug }) {
  const queryClient = useQueryClient();
  const [text, setText] = useState("");
  const [users, setUsers] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const chatMessageStore = useChatMessageStore();

  const { data: lastMessages, isLoading } = useQuery({
    queryKey: ["LAST_CHAT_MESSAGES", userProfile.id],
    queryFn: async () => {
      const { data } = await httpClientCron.get(`private/last-chat-messages`);

      return data;
    },
    enabled: !!userProfile.id,
  });

  const updateSeenMutation = useMutation({
    mutationFn: async ({ chatMessageId }) => {
      await httpClientCron.patch(`private/chat-message/${chatMessageId}/seen`);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["LAST_CHAT_MESSAGES", userProfile.id] });
    },
  });

  const onFieldClear = (event) => {
    event.stopPropagation();
    setUsers([]);
    setText("");
    setIsSearching(false);
  };

  const onChange = (event) => {
    const value = event.currentTarget.value;

    setText(value);

    if (!value) {
      setIsSearching(false);
      setUsers([]);
    }
  };

  const getUsersMutation = useMutation({
    mutationFn: async ({ slug, text }) => {
      const { data } = await httpClientCron.post(`private/chat-message/certificate/${slug}/users`, { text });

      return data;
    },
    onSuccess: (data) => {
      setUsers(data);
    },
  });

  const onSearch = (value) => {

    if (!value) return;

    setIsSearching(true);

    getUsersMutation.mutate({
      slug,
      text: value,
    });
  };

  const searchUserList = (
    <div
      style={{
        overflowY: "auto",
        height: "calc(100vh - 325px)",
      }}
    >
      <SearchUserList
        isLoading={getUsersMutation.isLoading}
        users={users}
        setText={setText}
        setIsSearching={setIsSearching}
        setUsers={setUsers}
      />
    </div>
  );

  const items = [
    // {
    //   key: "1",
    //   label: "Chats",
    //   children: (
    //     <>
    //       {!isSearching && (
    //         <ChatTab
    //           isLoading={isLoading}
    //           lastMessages={lastMessages}
    //           userProfile={userProfile}
    //           updateSeenMutation={updateSeenMutation}
    //         />
    //       )}
    //       {searchUserList}
    //     </>
    //   ),
    // },
    {
      key: "2",
      label: "Group",
      children: (
        <>
          {!isSearching && (
            <GroupChatTab userProfile={userProfile} />
          )}
          {searchUserList}
        </>
      ),
    },
  ];

  return (
    <div className="chat-message-container">
      <div>
        {/*<Search*/}
        {/*  style={{ width: "100%", padding: "0 16px", margin: "1rem 0" }}*/}
        {/*  placeholder="Search user"*/}
        {/*  onSearch={debounce(onSearch, 300)}*/}
        {/*  allowClear={{ clearIcon: <CloseOutlined onClick={onFieldClear} /> }}*/}
        {/*  enterButton="Search"*/}
        {/*  size="large"*/}
        {/*  value={text}*/}
        {/*  onChange={onChange}*/}
        {/*/>*/}
        {/*<div className="chatbox">*/}
        {/*  <Tabs defaultActiveKey="2" items={items} />*/}
        {/*</div>*/}
        <GroupChatTab userProfile={userProfile} />
      </div>
    </div>
  );
}