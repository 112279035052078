import React from "react";
// import { connect } from "react-redux";
import { INTERNAL_LINKS } from "enum";
import { Route } from "react-router-dom";
import { Sidebar } from "components";

const EventsCouncilShift = () => {

    return (
        <>
            {(localStorage.getItem("community") !== null) &&
                <Route
                    path={INTERNAL_LINKS.EVENTS_COUNCIL}
                    render={(props) => <Sidebar typeStyle={'black'} {...props} />}
                />
            }

        </>
    );
};

// const mapStateToProps = (state) => ({
// });

// const mapDispatchToProps = {
// };

export default EventsCouncilShift;