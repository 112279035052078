import React from "react";
import { INTERNAL_LINKS } from "enum";
import { Route } from "react-router-dom";
import { LandingHeader, MainHeader } from "components";

const startRouteBlogsNew1 = () => {
  return (
    <>
      {localStorage.getItem("community") !== null ? (
        <Route
          path={`${INTERNAL_LINKS.BLOGS_NEW}/:id`}
          render={(props) => <MainHeader typeStyle={'black'} {...props} />}
        />
      ) : (
        <Route
          path={`${INTERNAL_LINKS.BLOGS_NEW}/:id`}
          exact
          render={(props) => <LandingHeader {...props} />}
        />
      )}
    </>
  );
};

export default startRouteBlogsNew1;
