import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
// import ReactDOM from 'react-dom';

import "./style.scss";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

// const TeleportTarget = ({ image }) => {
//     const [crop, setCrop] = useState({ x: 0, y: 0, width: 100, height: 100 });
//     const handleCropChange = (e) => {
//         const { name, value } = e.target;
//         setCrop((prevCrop) => ({ ...prevCrop, [name]: parseInt(value, 10) || 0 }));
//       };
//     return (
//         <div id="crop-elementaaaaa" style={{position:'fixed',width:'100%',height:'100vh',display:'flex',justifyContent:'center',alignItems:'center',top: '0px',left: '0px',background: 'rgba(0,0,0,0.65)',zIndex: '1000',flexDirection:'column'}}>
//             <div style={{position:'relative',width:'100%', height:'auto'}}>
//                 <img src={image} alt="Preview" className="preview-image" style={{ width: '100%', height: 'auto' }} />
//                 <div
//                     style={{
//                         width: `${crop.width}%`,
//                         height: `${crop.height}%`,
//                         top: `${crop.y}%`,
//                         left: `${crop.x}%`,
//                         background:'red',
//                         position:'absolute'
//                     }}
//                 >
//                     <div className="handles">
//                     <div className="handle top-left" />
//                     <div className="handle top-right" />
//                     <div className="handle bottom-left" />
//                     <div className="handle bottom-right" />
//                     </div>
//                 </div>
//             </div>
            
//             <div className="crop-controls">
//                 <label>
//                 Width:
//                 <input type="number" name="width" value={crop.width} onChange={handleCropChange} />
//                 </label>
//                 <label>
//                 Height:
//                 <input type="number" name="height" value={crop.height} onChange={handleCropChange} />
//                 </label>
//                 <label>
//                 Top:
//                 <input type="number" name="y" value={crop.y} onChange={handleCropChange} />
//                 </label>
//                 <label>
//                 Left:
//                 <input type="number" name="x" value={crop.x} onChange={handleCropChange} />
//                 </label>
//             </div>
//         </div>
//     );
//   };

const ImageUploadPersonalized = ({
  className,
  value,
  aspect,
  onChange,
  type = "classic",
  textScale = "",
}) => {
    // const [image, setImage] = useState(null);
    const [src, setSrc] = useState("");
    const [urlStatic, setUrlStatic] = useState("");
    const containerRef = useRef(null)

    const imageUpload = async (file) => {

        if (file) {
            // const reader = new FileReader();
            // reader.onload = (event) => {
            //   setImage(event.target.result);
            // };
            // reader.readAsDataURL(file);
            let type = file.type;

            const fileBlob = new Blob([file], { type: type });

            setUrlStatic(URL.createObjectURL(fileBlob));
            const base64FormatFile = await getBase64(file);
            onChange(base64FormatFile, file);
            setSrc(base64FormatFile);
        }
        
    };
    

    useEffect(() => {
        setSrc(value);
        setUrlStatic(value);
        return () => {};
    }, [value]);

    return (
        <div
        className='image-upload-2'
        style={src ? { backgroundImage: `url("${src}")` } : {}}
        ref={containerRef}
        >
            <input type="file" style={{background:'transparent', width:'100%',height:'100%',position:'absolute', left:'0px',top:'0px', opacity:'0'}} onChange={(e) => {
                // setFileList(e.target.files[0])
                imageUpload(e.target.files[0])
            }}>
            </input>
            {urlStatic === "" ? (
                <div style={{ width: "100%", height: "auto", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", }}>
                    <div className="icon-image-upload"></div>
                    <p style={{ fontSize: "16px", textAlign: "center", padding:'0px',margin:'0px' }}>Upload image</p>
                    {textScale !== "" && (
                        <p style={{ fontSize: "14px", textAlign: "center" , padding:'0px',margin:'0px'}}>
                        {textScale}
                        </p>
                    )}
                </div>
            ) : (
                <img
                    style={{
                        height: `auto`,
                        width: `${(containerRef.current ? (containerRef.current.clientHeight * 0.85) : 90) * aspect}px`,
                        borderRadius: "10px",
                    }}
                    alt="img-profile"
                    src={urlStatic}
                />
            )}
            {/* {image && (
                // Crear un portal para renderizar el contenido en el TeleportTarget
                ReactDOM.createPortal(
                    <TeleportTarget  image={image} />,
                    document.body // Aquí especificamos el contenedor en el que se debe renderizar el portal
                )
            )} */}
        </div>
    );
};

ImageUploadPersonalized.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  aspect: PropTypes.number,
  onChange: PropTypes.func,
};

ImageUploadPersonalized.defaultProps = {
  className: "",
  value: "",
  aspect: 1 / 1,
  onChange: () => {},
};

export default ImageUploadPersonalized;
