import { create } from "zustand";

export const useChatMessageStore = create(set => ({
  chatMessageWindows: [],
  chatTabItemIds: [],
  groupChatTabItemIds: [],
  onlineUsers: [],
  messages: {},
  updateMessageWindowName: ({ name, id }) => set(state => {
    const windows =  [...state.chatMessageWindows]

    windows.forEach(window => {
      if (window.id === id) {
        window["name"] = name
      }
    })

    return {
      chatMessageWindows: windows
    }
  }),
  setMessage: ({ content, id, isEditing = false }) => set(state => {


    return {
      messages: {
        ...state.messages,
        [id || -1]: {
          content,
          isEditing
        }
      },
    };
  }),
  setOnlineUsers: (users) => set(state => ({ onlineUsers: users })),
  setChatMessageWindows: (data) => set(state => {

    const exists = state.chatMessageWindows.some(item => data.id === item.id && item.type === data.type);

    const window = {
      img: data.img,
      name: data.name,
      id: data.id,
      titleProfessions: data.titleProfessions,
      type: data.type,
    };

    if (!exists) {
      return {
        chatMessageWindows: [...state.chatMessageWindows, window],
      };
    }

    return {
      chatMessageWindows: state.chatMessageWindows,
    };
  }),
  removeMessageWindow: (id) => set(state => {
    const windows = [...state.chatMessageWindows];

    const filteredWindows = windows.filter(item => item.id !== id);

    return { chatMessageWindows: filteredWindows };
  }),
  setTyping: ({ id, isTyping }) => set(state => {
    const windows = [...state.chatMessageWindows];
    const windowIndex = windows.findIndex(item => item.id === id);

    if (windowIndex === -1) {
      return { chatMessageWindows: state.chatMessageWindows };
    }

    windows[windowIndex] = {
      ...windows[windowIndex],
      isTyping,
    };

    return { chatMessageWindows: windows };
  }),
  pushChatTabItemId: ({ id }) => set(state => {
    const ids = [...new Set([...state.chatTabItemIds, id])];

    return {
      chatTabItemIds: ids,
    };
  }),
  deleteChatTabItemId: ({ id }) => set(state => {
    const ids = [...state.chatTabItemIds];

    return { chatTabItemIds: ids.filter(i => i !== id) };
  }),
  pushGroupChatTabItemId: ({ id }) => set(state => {
    const ids = [...new Set([...state.groupChatTabItemIds, id])];

    return {
      groupChatTabItemIds: ids,
    };
  }),
  deleteGroupChatTabItemId: ({ id }) => set(state => {
    const ids = [...state.groupChatTabItemIds];

    return { groupChatTabItemIds: ids.filter(i => i !== id) };
  })
}));