import React, { useEffect, useRef, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { setLoading } from "redux/actions/home-actions";
import { getEvent } from "redux/actions/event-actions";
import { homeSelector } from "redux/selectors/homeSelector";
import { eventSelector } from "redux/selectors/eventSelector";
import { certificateSelector } from "redux/selectors/certificateSelector"
import { skillCohortSelector } from "redux/selectors/skillCohortSelector";
import { challengeSelector } from "redux/selectors/challengeSelector";
import moment from "moment";
import html2canvas from "html2canvas";

import IconLoading from "images/icon-loading.gif";

import {
    getOneChallenge,
} from "redux/actions/challenge-actions";

import CertificateLearning from "./CertificateLearning";
import CertificateProjectX from "./CertificateProjectX";
import CertificateChallenge from "./CertificateChallenge";
import CertificateCrashCourse from "./CertificateCrashCourse";

import {
    getCertificate
} from "redux/actions/certificate-ations";

import {
    getSkillCohort
} from "redux/actions/skillCohort-actions";
import { INTERNAL_LINKS } from "enum";
import { Avatar } from "antd";
import {
    CustomButton,
} from "components";

import IconBack from "images/icon-back.svg";

import "./style.scss";


const CertificatePage = ({
    history,
    getCertificate,
    getEvent,
    // event,
    // certificate,
    // userCertificate,
    // skillCohort,
    getSkillCohort,
    // challenge,
    user,
    getOneChallenge,
}) => {
    const containerCertificate = useRef(null)
    const [loading2, setLoading2] = useState(false);
    const [event, setEventSelected] = useState({})
    const [url,setUrl] = useState('')
    const [certificateSelectState, setCertificateSelectState] = useState(undefined)
    const [containerCertificateS, setContainerCertificateS] = useState(<div></div>)
    const [containerDataS, setContainerDataS] = useState('')
    const [certificate, setAcertificate] = useState({}) 
    const [userCertificate, setAuserCertificate] = useState({})
    const [skillCohort, setAskillCohort] = useState({}) 
    const [challenge, setAchallenge] = useState({}) 
    const win = window.location.pathname

    useEffect(() => {
        let code = win.substring(8, win.length)
        getCertificate(code, (err,data1,data2) => {
            if (err) {
                history.push(INTERNAL_LINKS.NOT_FOUND);
            }
            setAcertificate(data1)
            setAuserCertificate(data2)
        })

    }, [win, getCertificate, history])

    useEffect(() => {
        if (certificate?.type === 'learning') {
            getEvent(certificate?.idTypeCertificate,(data) => {
                console.log(data.title)
                setEventSelected(data)
            });
        }
        if (certificate?.type === 'skillCohort') {
            getSkillCohort(certificate?.idTypeCertificate,(data) => {
                setAskillCohort(data)
            })
        }
        if (certificate?.type === 'challenge') {
            getOneChallenge(certificate?.idTypeCertificate, (data) => {
                setAchallenge(data)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [certificate])

    const calcDuration = (data) => {
        let duration = 0

        if (data !== undefined) {
            data.forEach((date) => {
                duration = (date !== null)
                    ? duration + moment(date?.endTime, 'YYYY-MM-DDTHH:mm:ssZ').diff(date?.startTime, 'hours')
                    : duration
            })
        }

        return duration
    }

    const certificateSelect = (eventD,challengeD,skillCohortD) => {
        if (eventD.id !== undefined) {
            if (certificate.type === 'learning') {
                return (
                    <CertificateLearning
                        event={eventD}
                        userCertificate={userCertificate}
                        father={containerCertificate}
                        calcDuration={calcDuration}
                        setCanvas={(data) => setContainerDataS(data?.toDataURL("image/png"))}
                        functionCargCertificate={(data) => setCertificateSelectState(data)}
                    />
                )
            }
        }
        if (skillCohortD.id !== undefined) {
            if (certificate.type === 'skillCohort') {
                return (
                    <CertificateProjectX
                        skillCohort={skillCohortD}
                        userCertificate={userCertificate}
                        father={containerCertificate}
                        setCanvas={(data) => setContainerDataS(data?.toDataURL("image/png"))}
                        functionCargCertificate={(data) => setCertificateSelectState(data)}
                    />
                )
            }
        }
        if (challengeD.id !== undefined) {
            if (certificate.type === 'challenge') {
                return (
                    <CertificateChallenge
                        challenge={challengeD}
                        userCertificate={userCertificate}
                        father={containerCertificate}
                        setCanvas={(data) => setContainerDataS(data?.toDataURL("image/png"))}
                        functionCargCertificate={(data) => setCertificateSelectState(data)}
                    />
                )
            }
        }
        if (certificate.type === 'crashcourse') {
            return <CertificateCrashCourse />
        }
    }

    const durationType = () => {
        if (certificate.type === 'learning') {
            return <p className="p-duration">Duration: {calcDuration(event?.startAndEndTimes)} Hours</p>
        }
        if (certificate.type === 'skillCohort') {
            return <p className="p-duration">Date:
                {`${moment(skillCohort?.startDate, "YYYY-MM-DDTHH:mm:ss").format("ll")} 
                - ${moment(skillCohort?.endDate, "YYYY-MM-DDTHH:mm:ss").format("ll")}`}
            </p>
        }
    }

    useEffect(() => {
        setContainerCertificateS(certificateSelect(event,challenge,skillCohort))
    },[certificate,event,challenge,skillCohort])

    const titleType = () => {
        if (certificate.type === 'learning') {
            return event?.title
        }
        if (certificate.type === 'skillCohort') {
            return skillCohort?.title
        }
        if (certificate.type === 'challenge') {
            return challenge?.title
        }
    }

    const textButton = () => {
        if (certificate.type === 'learning') {
            return "Download Certificate"
        }
        if (certificate.type === 'skillCohort') {
            return "Download Certificate"
        }
        if (certificate.type === 'challenge') {
            return "Download Challenge Badge"
        }
    }

    const downloadCertificate = async () => {
        if (certificateSelectState !== undefined) {
            setLoading2(true);
            const domElement = certificateSelectState.current;
            const canvas = await html2canvas(domElement, {
                scale: 4,
            });

            let a = document.createElement("a");
            a.href = canvas.toDataURL("image/png");
            a.download = `${titleType()} - ${userCertificate?.firstName} ${userCertificate?.lastName} Certificate.png`;
            a.click();
            setLoading2(false);
        }
    }

    useEffect(() => {
        if(containerCertificate.current){
            containerCertificate.current.innerHTML = ''
        }
    },[])

    return (<>
        {certificate.type !== "crashcourse" && <div className="container-verify-centificate">
            {localStorage.getItem("community") !== null &&
                <Link to={INTERNAL_LINKS.MY_LEARNINGS} >
                    <div className="verify-page__content-top">
                        <div className="verify-page__content-top-back">
                            <img src={IconBack} alt="icon-back" />
                        </div>
                        <h4>Back to My Learning</h4>
                    </div>
                </Link>
            }
            <div className="box-title-verify-certificate">
                <p className="course-certificate-p">Course Certificate</p>
                <p className="name-course">{titleType()}</p>
            </div>
            <div className="container-profile-certificate">
                <div className="container-user">
                    <div className="container-data-member-certificate">
                        {userCertificate?.img !== undefined ? (
                            <Avatar size={150} style={{minWidth:'150px'}} src={userCertificate?.img} />
                        ) : (
                            <Avatar size={150} style={{minWidth:'150px',fontSize: "4rem" }}>
                                {userCertificate?.abbrName}
                            </Avatar>
                        )}
                        <div className="container-p-certficate">
                            <p className="p-title-conference">Completed by <b>{userCertificate?.firstName} {userCertificate?.lastName}</b></p>
                            {durationType()}
                        </div>
                    </div>
                </div>
                <div className="container-certificate" >
                    <div>
                        {containerDataS ? (
                            <img src={containerDataS} alt="ceritficate-case" style={{width:'100%',height:'100%'}}></img>
                        ) : (
                            <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <img src={IconLoading} style={{width:'40px',height:'40px'}} alt="loader-digital-certificate"/>
                            </div>
                        )}
                    </div>
                    {containerCertificateS}
                    {(user?.id === userCertificate?.id) && <CustomButton
                        type="primary"
                        size="sm"
                        text={textButton()}
                        style={{
                            position: "absolute",
                            left: '50%',
                            top: 'calc(100% + 20px)',
                            transform: "translateX(-50%)"
                        }}
                        onClick={() => downloadCertificate()}
                        loading={loading2}
                    />}
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center',marginTop:'20px'}}>
                        <p style={{fontSize:'18px',fontWeight:'bold',margin:'0px',padding:'0px',marginLeft:'10px', whiteSpace:'nowrap'}}>Unique digital certificate ID: </p> <p style={{margin:'0px',padding:'0px', fontSize:'14px',paddingLeft:'10px'}}>{certificate.code}</p>
                    </div>
                    <div  style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center'}}>
                        <p style={{fontSize:'18px',fontWeight:'bold',margin:'0px',padding:'0px',marginLeft:'10px', whiteSpace:'nowrap'}}>Unique URL: </p> <p style={{margin:'0px',padding:'0px', fontSize:'14px',paddingLeft:'10px'}}>{process.env.REACT_APP_DOMAIN_URL}/verify/{certificate.code}</p>
                    </div>
                </div>
            </div>
        </div>}
        {certificate.type === "crashcourse" && containerCertificateS}
    </>);
};

CertificatePage.propTypes = {
    title: PropTypes.string,
};

CertificatePage.defaultProps = {
    title: "",
};

const mapStateToProps = (state) => ({
    user: homeSelector(state).userProfile,
    // event: eventSelector(state).updatedEvent,
    // certificate: certificateSelector(state).certificate,
    challenge: challengeSelector(state).challenge,
    // userCertificate: certificateSelector(state).userCertificate,
    skillCohort: skillCohortSelector(state).skillCohort,
});

const mapDispatchToProps = {
    getCertificate,
    setLoading,
    getEvent,
    getSkillCohort,
    getOneChallenge
};

export default connect(mapStateToProps, mapDispatchToProps)(CertificatePage);