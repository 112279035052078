import { handleActions } from "redux-actions";
import { Map } from "immutable";

// Action Type Imports
import { constants as eventCodeConstanst } from "../actions/event-verfication-code";

export const reducers = {
    [eventCodeConstanst.SET_EVENT_VERIFICATION]: (state, { payload }) => {
        if (payload.data.length !== 0) {
            return state.merge({
                eventVerificationCode: payload.data
            });
        } else {
            return state.merge({
                eventVerificationCode: []
            });
        }

    },
};

export const initialState = () =>
    Map({
        eventVerificationCode: [],
    });

export default handleActions(reducers, initialState());
