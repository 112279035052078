import React, { useState } from "react";
import moment from "moment-timezone";
import {
  Comment,
  Avatar,
  Button,
  Form,
  Tooltip,
  Popconfirm,
  Space,
} from "antd";
import {
  DislikeFilled,
  DislikeOutlined,
  LikeFilled,
  LikeOutlined,
} from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";
import httpClientCron from "../../api/module/httpClientCron";
import TextArea from "antd/lib/input/TextArea";
import TimezoneList from "enum/TimezoneList";
import "./style.scss";

export default function DiscussionBoardComment({
  comment,
  index,
  handleReplyClick,
  userProfile,
  certificateUserId,
  children,
  isGroupDiscussionBoard,
  scrollToBottom,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [content, setContent] = useState(comment.content || "");

  let currentTimezone = TimezoneList.find(
    (item) => item.value === userProfile.timezone
  );
  currentTimezone = currentTimezone.utc[0];

  const deleteCommentMutation = useMutation({
    mutationFn: ({ id, isGroupDiscussionBoard }) => {
      if (isGroupDiscussionBoard) {
        return httpClientCron.delete(`private/certificate-group-comment/${id}`);
      } else {
        return httpClientCron.delete(`private/certificate-comment/${id}`);
      }
    },
  });

  const likeCommentMutation = useMutation({
    mutationFn: ({ id, isGroupDiscussionBoard }) => {
      if (isGroupDiscussionBoard) {
        return httpClientCron.patch(
          `private/certificate-group-comment/${id}/like`
        );
      } else {
        return httpClientCron.patch(`private/certificate-comment/${id}/like`);
      }
    },
  });

  const dislikeCommentMutation = useMutation({
    mutationFn: ({ id, isGroupDiscussionBoard }) => {
      if (isGroupDiscussionBoard) {
        return httpClientCron.patch(
          `private/certificate-group-comment/${id}/dislike`
        );
      } else {
        return httpClientCron.patch(
          `private/certificate-comment/${id}/dislike`
        );
      }
    },
  });

  // const deleteReplyMutation = useMutation({
  //   mutationFn: (certificateCommentReplyId) => {
  //     return httpClientCron.delete(
  //       `private/certificate-reply/${certificateCommentReplyId}`
  //     );
  //   },
  // });

  const actions = [<span onClick={() => handleReplyClick()}>Reply To</span>];

  if (userProfile.id === comment.CertificateUser?.UserId) {
    actions.push(<span onClick={() => setIsEditing(true)}>Edit</span>);
    actions.push(
      <Popconfirm
        title="Delete the comment"
        description="Are you sure to delete this comment?"
        onConfirm={() =>
          deleteCommentMutation.mutate({
            id: comment.id,
            isGroupDiscussionBoard,
          })
        }
        okText="Delete"
        cancelText="Cancel"
      >
        <span>Delete</span>
      </Popconfirm>
    );
  }

  const repliesLength = isGroupDiscussionBoard
    ? comment.CertificateGroupCommentReplies.length
    : comment.CertificateCommentReplies.length;

  if (repliesLength > 0) {
    actions.splice(
      0,
      0,
      <span onClick={() => handleReplyClick(comment)}>
        {repliesLength.length} replies
      </span>
    );
  }

  const hasLiked = comment.likes.some(
    (_certificateUserId) => _certificateUserId === certificateUserId
  );

  const hasDisliked = comment.dislikes.some(
    (_certificateUserId) => _certificateUserId === certificateUserId
  );

  const updateCommentMutation = useMutation({
    mutationFn: ({ id, isGroupDiscussionBoard }) => {
      if (!content) return;

      if (isGroupDiscussionBoard) {
        return httpClientCron.patch(
          `private/certificate-group-comment/${id}/edit`,
          {
            content,
          }
        );
      } else {
        return httpClientCron.patch(`private/certificate-comment/${id}/edit`, {
          content,
        });
      }
    },
    onSuccess: () => {
      setIsEditing(false);
      setContent("");
    },
  });

  if (isEditing) {
    return (
      <div
        style={{
          padding: "1rem",
        }}
      >
        <Form.Item>
          <TextArea
            rows={2}
            onChange={(e) => setContent(e.target.value)}
            value={content}
            placeholder="Add comment"
          />
        </Form.Item>
        <div
          style={{
            display: "flex",
            justifyItems: "center",
            alignItems: "center",
          }}
        >
          <Button
            htmlType="submit"
            loading={updateCommentMutation.isLoading}
            onClick={() =>
              updateCommentMutation.mutate({
                id: comment.id,
                isGroupDiscussionBoard,
              })
            }
            type="primary"
            style={{ marginTop: ".5rem", marginRight: "1rem" }}
          >
            Save
          </Button>
          <Button
            htmlType="button"
            onClick={() => {
              setIsEditing(false);
              setContent(comment.content);
            }}
            type="secondary"
            style={{ marginTop: ".5rem" }}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Comment
      // className="comment-container"
      style={{
        borderTop: index === 0 ? "none" : "1px solid #e1e2ee",
        paddingTop: index === 0 ? 0 : 0,
      }}
      key={comment.id}
      author={
        <span style={{ display: "flex", alignItems: "start" }}>
          <Space>
            <h5>{`${comment.CertificateUser.User.firstName} ${comment.CertificateUser.User.lastName}`}</h5>
            <span style={{ fontSize: "12px", fontWeight: "lighter" }}>
              {moment
                .utc(comment.createdAt)
                .tz(currentTimezone)
                .format("YYYY-MM-DD HH:mm")}
            </span>
          </Space>
        </span>
      }
      avatar={
        <Avatar
          src={comment.CertificateUser.User.img}
          alt={`${comment.CertificateUser.User.firstName} ${comment.CertificateUser.User.lastName}`}
        />
      }
      content={comment.content}
      actions={[
        <Tooltip key="comment-basic-like" title="Like">
          <span
            onClick={() =>
              likeCommentMutation.mutate({
                id: comment.id,
                isGroupDiscussionBoard,
              })
            }
            style={{
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            {hasLiked ? (
              <LikeFilled style={{ width: "1.25rem" }} />
            ) : (
              <LikeOutlined style={{ width: "1.25rem" }} />
            )}
            <span className="comment-action">{comment.likes.length}</span>
          </span>
        </Tooltip>,
        <Tooltip key="comment-basic-dislike" title="Dislike">
          <span
            onClick={() =>
              dislikeCommentMutation.mutate({
                id: comment.id,
                isGroupDiscussionBoard,
              })
            }
            style={{
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            {hasDisliked ? (
              <DislikeFilled style={{ width: "1.25rem" }} />
            ) : (
              <DislikeOutlined style={{ width: "1.25rem" }} />
            )}
            <span className="comment-action">{comment.dislikes.length}</span>
          </span>
        </Tooltip>,
        ...actions,
      ]}
    >
      {children}
    </Comment>
  );
}
