import React from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  // Menu,
  Space,
  Modal,
  Avatar,
  Tooltip,
} from "antd";
import {
  CheckOutlined,
  DownOutlined,
  CloseCircleFilled,
} from "@ant-design/icons";
import draftToHtml from "draftjs-to-html";
import moment from "moment-timezone";
import TimeZoneList from "enum/TimezoneList";
import { loadStripe } from "@stripe/stripe-js";

import { getCheckoutSession } from "api/module/stripe";
import html2canvas from "html2canvas";
import BoxItered from "./BoxItered";
import downloadDropdownOptions from "components/downloadDropdownOptions";

import clsx from "clsx";
import { withRouter, Link } from "react-router-dom";

import { CustomButton } from "components";
import IconCalendar from "images/icon-calendar.svg";
import { EVENT_TYPES, INTERNAL_LINKS, CARD_TYPE } from "enum";
import Emitter from "services/emitter";
import CardMenu from "../CardMenu";
import { ReactComponent as IconPlus } from "images/icon-plus.svg";
import IconMenu from "images/icon-menu.svg";
// import IconLoader from "images/icon-loading.gif";
import {
  // capitalizeWord,
  convertToLocalTime,
} from "utils/format";
// import ImgHHRLogo from "images/img-certificate-logo.png";
import IconLogo from "images/logo-sidebar.svg";
import IconTicket from "images/ticket-event.svg";

import "./style.scss";
// import { isEmpty } from "lodash";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_KEY);

class EventCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showFirewall: false,
      firewallText: "",
      stripe: null,
      loading: false,
      linkProfile: "",
      showDownLoadCertificate: false,
      popUp: false,
      loadingTicket: false,
      selectCalendar: false,
      heightCard: "0px",
    };
  }

  componentDidMount() {
    const instanceStripe = async () => {
      this.setState({
        stripe: await stripePromise,
      });
    };

    instanceStripe();

    const newImagesLoad = () => {
      const canvas = document.getElementById(
        `container-information-event-${this.props.data.id}-1`
      );

      if (canvas !== null) {
        // canvas.style.cssText = `width: ${this.props.data.widthTicket}px; height: 604px;`
      } else {
        setTimeout(() => {
          newImagesLoad();
        }, 200);
      }
    };

    newImagesLoad();

    const seeHeightCard = () => {
      const cardCalendar = document.getElementById(
        `calendars-event-car-${this.props.data.id}`
      );

      if (cardCalendar !== null && cardCalendar !== undefined) {
        this.setState({
          heightCard: `${cardCalendar.clientHeight + 20}px`,
        });
      } else {
        setTimeout(() => {
          seeHeightCard();
        }, 200);
      }
    };

    seeHeightCard();
  }

  onAttend = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const userProfile = this.props.userProfile;

    if (userProfile.percentOfCompletion !== 100) {
      this.props.setActiveMessages(
        "You must complete your profile before registering for this event."
      );
      // this.setState({
      //   firewallText:
      //     "You must complete your profile before registering for this event.",
      // });
      return this.setState({ showFirewall: true });
    }

    if (this.props.data.ticket === "premium") {
      if (userProfile && userProfile.memberShip === "premium") {
        this.props.onAttend(
          true
          // imageEventSelect1
        );
      } else {
        this.props.setActiveMessages(
          "Upgrade to a PREMIUM Membership and get unlimited access to the LAB features"
        );
        // this.setState({
        //   firewallText:
        //     "Upgrade to a PREMIUM Membership and get unlimited access to the LAB features",
        // });
        this.setState({ showFirewall: true });
      }
    } else if (this.props.data.ticket === "fee") {
      this.setState({
        loading: true,
      });

      const userTimezone = moment.tz.guess();

      try {
        const sessionData = await getCheckoutSession({
          prices: [
            {
              price_data: {
                currency: "usd",
                product_data: {
                  name: this.props.data.title,
                },
                unit_amount: `${this.props.data.ticketFee}00`,
              },
            },
          ],
          isPaidEvent: true,
          event: { ...this.props.data, userTimezone },
          callback_url: window.location.href,
        });

        this.state.stripe.redirectToCheckout({
          sessionId: sessionData.data.id,
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      this.props.onAttend(
        true
        // imageEventSelect1
      );
    }
    if (window.location.pathname.includes("channels")) {
      window.open(this.props.data?.externalLink, "_blank");
    }
  };

  onCancelAttend = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onAttend(false);
  };

  openEventDetails = () => {
    this.props.onClick({ ...this.props.data, usersData: this.props.usersData });
  };

  onClickConfirm = (e) => {
    e.preventDefault();
    e.stopPropagation();

    Emitter.emit(EVENT_TYPES.OPEN_ATTENDANCE_DISCLAIMER, this.props.data);

    this.props.onConfirmAttendance(this.props.data);
  };

  // onClickClaimDigitalCertificate = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();

  //   window.open(
  //     `${INTERNAL_LINKS.CERTIFICATE}/${this.props.data.id}`,
  //     "_blank"
  //   );
  // };

  // onClickClaimCredits = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();

  //   this.props.onConfirmCredit(this.props.data);
  // };

  planUpgrade = (e) => {
    e.preventDefault();
    e.stopPropagation();
    Emitter.emit(EVENT_TYPES.OPEN_PAYMENT_MODAL);
  };

  // onClickDownloadCalendar = (day) => {
  //   const userTimezone = moment.tz.guess();

  //   window.open(
  //     `${process.env.REACT_APP_API_ENDPOINT}/public/event/ics/${this.props.data.id}?day=${day}&userTimezone=${userTimezone}`,
  //     "_blank"
  //   );
  // };

  getDescriptionHTML = (item) => {
    let description = "";

    if (item.description && item.description.blocks) {
      description = draftToHtml(item.description);
    } else if (item.description && item.description.html) {
      description = item.description.html;
    }

    return encodeURIComponent(description);
  };

  // onClickAddGoogleCalendar = (startDate, endDate, link) => {
  //   const getEventLink = (event, linkD) => {
  //     if (event.bulLinkForEachDay === "true") {
  //       return linkD;
  //     } else {
  //       return event.link;
  //     }
  //   };

  //   let googleCalendarUrl = `http://www.google.com/calendar/event?action=TEMPLATE&text=${
  //     this.props.data.title
  //   }&dates=${startDate}/${endDate}&details=Link to connect: ${getEventLink(
  //     this.props.data,
  //     link
  //   )}&location=${
  //     this.props.data.location
  //   }&trp=false&sprop=https://www.hackinghrlab.io/&sprop=name:`;

  //   window.open(googleCalendarUrl, "_blank");
  // };

  // onClickAddYahooCalendar = (startDate, endDate, link) => {
  //   const getEventLink = (event, linkD) => {
  //     if (event.bulLinkForEachDay === "true") {
  //       return linkD;
  //     } else {
  //       return event.link;
  //     }
  //   };

  //   let yahooCalendarUrl = `https://calendar.yahoo.com/?v=60&st=${startDate}&et=${endDate}&DESC=Link to connect: ${getEventLink(
  //     this.props.data,
  //     link
  //   )}&title=${this.props.data.title}&in_loc=${this.props.data.location}`;
  //   window.open(yahooCalendarUrl, "_blank");
  // };

  // handleOnClick = ({ item, key, domEvent }) => {
  //   domEvent.stopPropagation();
  //   domEvent.preventDefault();

  //   const [startTime, endTime, day, link] = item.props.value;

  //   const convertedStartTime = startTime.format("YYYYMMDDTHHmmss");

  //   const convertedEndTime = endTime.format("YYYYMMDDTHHmmss");

  //   switch (key) {
  //     case "1":
  //       this.onClickDownloadCalendar(day);
  //       break;
  //     case "2":
  //       this.onClickAddGoogleCalendar(
  //         convertedStartTime,
  //         convertedEndTime,
  //         link
  //       );
  //       break;
  //     case "3":
  //       this.onClickAddYahooCalendar(
  //         convertedStartTime,
  //         convertedEndTime,
  //         link
  //       );
  //       break;
  //     default:
  //   }
  // };

  // downloadDropdownOptions = (startTime, endTime, day, link) => {
  //   return (
  //     <Menu onClick={this.handleOnClick}>
  //       <Menu.Item key="1" value={[startTime, endTime, day]}>
  //         Download ICS File
  //       </Menu.Item>
  //       <Menu.Item key="2" value={[startTime, endTime, day, link]}>
  //         Add to Google Calendar
  //       </Menu.Item>
  //       <Menu.Item key="3" value={[startTime, endTime, day, link]}>
  //         Add to Yahoo Calendar
  //       </Menu.Item>
  //     </Menu>
  //   );
  // };

  searchTimeZone = (timezone) => {
    let currentTimezone = TimeZoneList.find((item) => item.value === timezone);

    if (currentTimezone) {
      currentTimezone = currentTimezone.utc[0];
    } else {
      currentTimezone = timezone;
    }

    return currentTimezone;
  };

  showButtonFunction = (startAndEndTimes, timezone, organizer, title) => {
    let bul = false;

    if (organizer === "Hacking HR") {
      let dateNow = moment(moment.utc(), "YYYY-MM-DD hh:mm a")
        .tz(
          this.searchTimeZone(
            timezone === "null" ? "Pacific Daylight Time" : timezone
          )
        )
        ?.format("YYYYMMDDHHmm");

      startAndEndTimes.forEach((time) => {
        if (bul === false) {
          if (
            Number(dateNow) >=
              Number(
                moment(time?.startTime, "YYYY-MM-DD hh:mm a")
                  ?.subtract(5, "minute")
                  ?.format("YYYYMMDDHHmm")
              ) &&
            Number(dateNow) <=
              Number(
                moment(time?.endTime, "YYYY-MM-DD hh:mm a")
                  ?.add(1, "minute")
                  ?.format("YYYYMMDDHHmm")
              )
          ) {
            bul = true;
          }
        }
      });
    }

    return bul;
  };

  filterInvalidDates = (data) => {
    let newTime = data?.filter((time) => {
      return time !== null;
    });

    return newTime;
  };

  inspectBul = (bul, e) => {
    if (!bul) {
      this.onAttend(e);
    } else {
      this.setState({
        popUp: true,
      });
    }
  };

  setBulPopup = (bul) => {
    this.setState({
      popUp: bul,
    });
  };

  // bulSituationCodeEvent = (bul, event) => {
  //   return bul
  //     ? (event.id !== undefined)
  //       ? true
  //       : false
  //     : true
  // }

  // bulSituationCodeEvent2 = (bul, event) => {
  //   return bul
  //     ? (event.id !== undefined)
  //       ? false
  //       : true
  //     : false
  // }

  donwloadEventCertificate = async () => {
    const certificateRef1 = document.getElementById(
      `container-information-event-${this.props.data.id}-1`
    );

    if (certificateRef1 !== undefined && certificateRef1 !== null) {
      this.setState({
        loadingTicket: true,
      });
      const canvas1 = await html2canvas(certificateRef1, {
        scale: 4,
      });
      canvas1.style.cssText = `width: 100%; `;
      let a = document.createElement("a");
      a.href = canvas1.toDataURL("image/png");
      a.download = `Ticket.png`;
      a.click();
      this.setState({
        loadingTicket: false,
      });
    } else {
      setTimeout(() => {
        this.donwloadEventCertificate();
      }, 200);
    }
  };

  dataURLtoFile(dataurl, filename) {
    if (dataurl !== undefined && dataurl !== null && dataurl !== "") {
      let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    } else {
      return "";
    }
  }

  imageRectificate(image, type) {
    let fileBlobTicket = new Blob(
      [this.dataURLtoFile(image, moment().format())],
      { type: type }
    );

    return URL.createObjectURL(fileBlobTicket);
  }

  changeVsibilityTicket() {
    this.setState({
      showDownLoadCertificate: true,
    });
  }

  functionOrder(boxesG) {
    let newBoxes;

    if (boxesG.length > 0) {
      newBoxes = ["", "", "", ""];
    } else {
      newBoxes = [];
    }

    boxesG.forEach((data) => {
      if (data.textName === "$#duration$#") {
        newBoxes[0] = data;
        return;
      }
      if (data.textName === "$#nameevent$#") {
        newBoxes[1] = data;
        return;
      }
      if (data.textName === "$#user$#") {
        newBoxes[2] = data;
        return;
      }
      if (data.textName === "$#durationHour$#") {
        newBoxes[3] = data;
        return;
      }

      newBoxes.push(data);
    });

    return newBoxes;
  }

  getEventLinkS = (bulLinkForEachDayD, linkA, startAndEndTimesD) => {
    if (bulLinkForEachDayD === "true") {
      let day = moment().utc().format("DD");

      let linkD = startAndEndTimesD?.filter(
        (item) =>
          (item?.startTime === null || item?.startTime === undefined
            ? 0
            : Number(moment(item?.startTime).utc().format("DD"))) ===
          Number(day)
      );

      return linkD[0]?.link;
    } else {
      return linkA;
    }
  };

  functionOnClickCalendar = () => {
    this.setState({
      selectCalendar: !this.state.selectCalendar,
    });
  };

  functionFilterEventCouncil = () => {
    if (
      this.props.data?.relationEventCouncil !== -1 ||
      this.props.data?.relationEventCouncil
    ) {
      const _event = this.props.allCouncilEvents?.find(
        (eve) => eve?.id === Number(this.props.data?.relationEventCouncil)
      );
      return _event;
    }
    return "";
  };

  render() {
    const {
      data: {
        startDate,
        endDate,
        title,
        // type,
        ticket,
        // ticketFee,
        // location,
        status,
        // image2,
        // images,
        period,
        // showClaim,
        startAndEndTimes,
        timezone,
        channel,
        link,
        channelSelect,
        period2,
        bulLinkForEachDay,
        organizer,
        id,
        bulCodeEvent,
        bulTicketCustomized,
        imageTicket,
        widthTicket,
        ImageTicketType,
        bulCloseEvent,
        users,
        arrayUsersIdGrowGroups,
        //councilEvents://
        eventName,
      },
      className,
      edit,
      type: cardType,
      onMenuClick,
      userProfile,
      lengthLibraries,
      // isMobile,
      onPast,
      esIncoming,
      boxes,
      usersData,
      caseLeft,
      // confirmUserEvent,
      // setBulInviteModal,
      addEventVerification,
      isGrowGroups = false,
      callForSpeaker = false,
    } = this.props;

    let usersGrowGroup = arrayUsersIdGrowGroups
      ?.filter((item) => item.status === "accepted")
      .map((item) => item.userId);

    let newArrayUsers = users?.filter((item, index) => {
      return users.indexOf(item) === index;
    });

    let newArrayUsersGrow = usersGrowGroup?.filter((item, index) => {
      return usersGrowGroup.indexOf(item) === index;
    });

    let userTimezone = moment.tz.guess();

    if (userTimezone.includes("_")) {
      userTimezone = userTimezone.split("_").join(" ");
    }

    let newBoxes = (boxes[id] || []).map((data) => {
      let rowSelect = 1;
      let rowSelect2 = 1;
      let columnSelect = 1;
      let columnSelect2 = 1;

      if (data.TopBox !== "") {
        rowSelect = data.TopBox.match(/\d{1,2}\//)[0].match(/\d{1,2}/)[0];
        rowSelect2 = data.TopBox.match(/\/\d{1,2}/)[0].match(/\d{1,2}/)[0];
      }
      if (data.LeftBox !== "") {
        columnSelect = data.LeftBox.match(/\d{1,2}\//)[0].match(/\d{1,2}/)[0];
        columnSelect2 = data.LeftBox.match(/\/\d{1,2}/)[0].match(/\d{1,2}/)[0];
      }

      return {
        showColor: "false",
        bulShowBox: data.bulShowBox,
        boldTextBox: data.boldTextBox,
        fontSizeBox: data.fontSizeBox,
        lineHeightBox: data.lineHeightBox,
        lineTextBox: data.lineTextBox,
        colorTextBox: data.colorTextBox,
        rowSelect: rowSelect,
        columnSelect: columnSelect,
        rowSelect2: rowSelect2,
        columnSelect2: columnSelect2,
        textName: data.textName,
        LeftBox: data.LeftBox,
        TopBox: data.TopBox,
      };
    });

    // if (title === 'Eventon2') {
    //   console.log(boxes[id])
    // }

    let stateGrowGroup = arrayUsersIdGrowGroups?.find(
      (item) => item?.userId === userProfile?.id
    );

    let btnTextGrowGroup =
      stateGrowGroup?.status === undefined
        ? "Register"
        : stateGrowGroup?.status === "pending"
        ? "Registration Pending"
        : stateGrowGroup?.status === "rejected" && "Register";

    let btnGrowGroupDisabled = stateGrowGroup?.status === "pending";

    return (
      <div
        className={
          channel === "" || channel === undefined || Number(channel) > 0
            ? "event-card-channel"
            : clsx("event-card2", className)
        }
        style={{ width: "100%" }}
        onClick={this.openEventDetails}
      >
        {/* {this.state.showFirewall && (
          <div
            className="event-card-firewall"
            onClick={() => this.setState({ showFirewall: false })}
          >
            <div
              className="upgrade-notification-panel"
              onClick={this.planUpgrade}
            >
              <h3>{this.state.firewallText}</h3>
            </div>
          </div>
        )} */}
        {cardType === CARD_TYPE.ADD ? (
          <div className="event-card-plus">
            <IconPlus />
          </div>
        ) : (
          <>
            {/* <div className="event-card-img">
              {!isEmpty(images) && (
                <img src={images[0]} alt="card-img" style={{ width: "100%" }} />
              )}
              {isEmpty(images) && image2 && <img src={image2} alt="card-img" />}
            </div> */}
            <div className="event-card-contentt">
              {!caseLeft && (
                <div
                  className="content-day"
                  style={!caseLeft ? {} : { width: "100%" }}
                >
                  <p className="top-date">
                    {moment(startDate)?.format("MMMM")}
                  </p>
                  <p className="top-date">
                    {moment(startDate)?.format("DD")}
                    {moment(startDate)?.format("DD") !==
                      moment(endDate)?.format("DD") &&
                      `-${moment(endDate)?.format("DD")}`}
                  </p>
                </div>
              )}
              <div
                className="content-titles"
                style={
                  !caseLeft
                    ? { alignItems: "flex-start" }
                    : { alignItems: "center" }
                }
              >
                {!caseLeft ? (
                  <h2 style={{ marginBottom: "20px" }}>
                    {typeof title === "string" ? title : eventName}
                  </h2>
                ) : (
                  <h3 style={{ marginBottom: "20px", textAlign: "center" }}>
                    {title}
                  </h3>
                )}

                {callForSpeaker === false &&
                (channel === "" ||
                  channel === undefined ||
                  Number(channel) > 0) ? (
                  !isGrowGroups && (
                    <h5 className="event-card-topic-title">
                      Event by: <span>{channelSelect?.name}</span>
                    </h5>
                  )
                ) : (
                  <div
                    style={
                      !caseLeft
                        ? {
                            width: "auto",
                            height: "auto",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            flexWrap: "wrap",
                          }
                        : {
                            width: "auto",
                            height: "auto",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            flexWrap: "wrap",
                            marginTop: "20px",
                          }
                    }
                  >
                    {!isGrowGroups && (
                      <h5 className="event-card-topic-title">
                        Event by: Hacking HR
                      </h5>
                    )}
                    {/* <p style={{
                      margin:'0px',
                      padding:'8px',
                      fontSize:'14px',
                      borderRadius:'14px',
                      background:'#FFD1C2',
                      marginLeft:'10px',
                      display:'flex',
                      flexDirection:'row',
                      justifyContent:'center',
                      alignItems:'center'
                    }}>
                      <img alt="calendar" src={IconCalendar} style={{width:'20px',height:'20px',marginRight:'5px'}} />
                      {convertToLocalTime(
                        startAndEndTimes[0]?.startTime,
                        timezone
                      )?.format("h:mm a")} - {convertToLocalTime(
                        startAndEndTimes[0]?.endTime,
                        timezone
                      ).format("h:mm a")} {userTimezone}
                    </p> */}
                    {callForSpeaker === false && !isGrowGroups ? (
                      channel === "" ||
                      channel === undefined ||
                      Number(channel) > 0 ? (
                        <h5 className="event-card-topic-title">
                          {`Event date${startDate !== endDate ? "s" : ""}:`}
                          <span>{period2}</span>
                        </h5>
                      ) : (
                        <h5 className="event-card-topic-title">
                          {`Event date${startDate !== endDate ? "s" : ""}:`}
                          <span>{period}</span>
                        </h5>
                      )
                    ) : null}

                    {callForSpeaker === false && isGrowGroups ? (
                      channel === "" ||
                      channel === undefined ||
                      Number(channel) > 0 ? (
                        <h5 className="event-card-topic-title">
                          {`Date${startDate !== endDate ? "s" : ""}:`}
                          <span>{period2}</span>
                        </h5>
                      ) : (
                        <h5 className="event-card-topic-title">
                          {`Date${startDate !== endDate ? "s" : ""}:`}
                          <span>{period}</span>
                        </h5>
                      )
                    ) : null}

                    {callForSpeaker && (
                      <h5 className="event-card-topic-title">
                        {`Event date${startDate !== endDate ? "s" : ""}:`}
                        <span>
                          {startDate !== endDate
                            ? `${moment(startDate).format("LL")} - ${moment(
                                endDate
                              ).format("LL")}`
                            : `${moment(startDate).format("LL")}`}
                        </span>
                      </h5>
                    )}
                  </div>
                )}
              </div>

              {!caseLeft && !isGrowGroups && (
                <div className="content-users">
                  {usersData &&
                    [...usersData.slice(0, 3), { type: "finish" }].map(
                      (u, index) => {
                        if (u?.type === "finish" && newArrayUsers.length > 3) {
                          return (
                            <Avatar
                              key={index}
                              shape="square"
                              size={45}
                              style={{
                                fontSize: "1rem",
                                borderRadius: "15px",
                                position: "absolute",
                                border: "4px solid white",
                                boxSizing: "content-box",
                                fontWeight: "bold",
                                left: `${30 * index}px`,
                                color: "black",
                                zIndex: `${1 * index}`,
                              }}
                            >
                              +{newArrayUsers.length - 3}
                            </Avatar>
                          );
                        } else {
                          if (u?.type === "finish") {
                            return (
                              <div
                                key={index}
                                style={{ display: "none" }}
                              ></div>
                            );
                          } else {
                            return u?.img ? (
                              <Avatar
                                key={index}
                                shape="square"
                                style={{
                                  borderRadius: "15px",
                                  position: "absolute",
                                  left: `${30 * index}px`,
                                  boxSizing: "content-box",
                                  border: "4px solid white",
                                  zIndex: `${1 * index}`,
                                }}
                                size={45}
                                src={u?.img}
                              />
                            ) : (
                              <Avatar
                                key={index}
                                shape="square"
                                size={45}
                                style={{
                                  fontSize: "1rem",
                                  borderRadius: "15px",
                                  position: "absolute",
                                  boxSizing: "content-box",
                                  fontWeight: "bold",
                                  border: "4px solid white",
                                  left: `${30 * index}px`,
                                  zIndex: `${1 * index}`,
                                }}
                              >
                                {u?.abbrName}
                              </Avatar>
                            );
                          }
                        }
                      }
                    )}
                </div>
              )}

              {isGrowGroups && !caseLeft && (
                <div className="content-users">
                  {usersData &&
                    [...usersData.slice(0, 3), { type: "finish" }].map(
                      (u, index) => {
                        if (
                          u?.type === "finish" &&
                          newArrayUsersGrow.length > 3
                        ) {
                          return (
                            <Avatar
                              key={index}
                              shape="square"
                              size={45}
                              style={{
                                fontSize: "1rem",
                                borderRadius: "15px",
                                position: "absolute",
                                border: "4px solid white",
                                boxSizing: "content-box",
                                fontWeight: "bold",
                                left: `${30 * index}px`,
                                color: "black",
                                zIndex: `${1 * index}`,
                              }}
                            >
                              +{newArrayUsersGrow.length - 3}
                            </Avatar>
                          );
                        } else {
                          if (u?.type === "finish") {
                            return (
                              <div
                                key={index}
                                style={{ display: "none" }}
                              ></div>
                            );
                          } else {
                            return u?.img ? (
                              <Avatar
                                key={index}
                                shape="square"
                                style={{
                                  borderRadius: "15px",
                                  position: "absolute",
                                  left: `${30 * index}px`,
                                  boxSizing: "content-box",
                                  border: "4px solid white",
                                  zIndex: `${1 * index}`,
                                }}
                                size={45}
                                src={u?.img}
                              />
                            ) : (
                              <Avatar
                                key={index}
                                shape="square"
                                size={45}
                                style={{
                                  fontSize: "1rem",
                                  borderRadius: "15px",
                                  position: "absolute",
                                  boxSizing: "content-box",
                                  fontWeight: "bold",
                                  border: "4px solid white",
                                  left: `${30 * index}px`,
                                  zIndex: `${1 * index}`,
                                }}
                              >
                                {u?.abbrName}
                              </Avatar>
                            );
                          }
                        }
                      }
                    )}
                </div>
              )}

              {!caseLeft && (
                <div className="content-going">
                  {lengthLibraries > 0 &&
                    lengthLibraries !== undefined &&
                    onPast && (
                      <Link
                        to={
                          userProfile?.id !== undefined
                            ? `${INTERNAL_LINKS.MY_LEARNINGS}?id=${id}&tab=1`
                            : "#"
                        }
                        style={
                          userProfile?.id !== undefined
                            ? {
                                margin: "0px",
                                padding: "0px",
                                textDecoration: "none",
                              }
                            : { display: "none" }
                        }
                      >
                        <CustomButton
                          text="Event videos"
                          size="sm"
                          type="primary"
                          style={{
                            marginTop: "0px",
                            width: "150px",
                            textDecoration: "none",
                            marginRight: "20px",
                          }}
                        />
                      </Link>
                    )}
                  {status === "attend" &&
                    userProfile?.id !== undefined &&
                    !isGrowGroups && (
                      <CustomButton
                        text="Register here"
                        size="sm"
                        type="primary"
                        style={{ marginRight: "0px", width: "150px" }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          this.inspectBul(bulCloseEvent, e);
                        }}
                        loading={this.state.loading}
                      />
                    )}

                  {status === "attend" &&
                    userProfile?.id !== undefined &&
                    isGrowGroups &&
                    stateGrowGroup?.status !== "accepted" && (
                      <CustomButton
                        text={btnTextGrowGroup}
                        size="sm"
                        type="primary"
                        style={{ marginRight: "0px", width: "150px" }}
                        disabled={btnGrowGroupDisabled}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          this.inspectBul(bulCloseEvent, e);
                        }}
                        loading={this.state.loading}
                      />
                    )}

                  {callForSpeaker === true && (
                    <CustomButton
                      text="Join as Speaker"
                      size="sm"
                      type="primary"
                      style={{ marginRight: "0px", width: "150px" }}
                      onClick={(e) => {
                        e.preventDefault();
                        return this.props.onClickCallSpeaker();
                      }}
                      loading={this.state.loading}
                    />
                  )}

                  {(channel === "" ||
                    channel === undefined ||
                    Number(channel) > 0) && (
                    <a
                      href={userProfile?.id !== undefined ? link : "#"}
                      style={
                        userProfile?.id !== undefined
                          ? { margin: "0px", padding: "0px" }
                          : { display: "none" }
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <CustomButton
                        text="Attend"
                        size="sm"
                        type="primary"
                        style={{ width: "150px" }}
                      />
                    </a>
                  )}
                  {status !== "attend" &&
                    userProfile?.id !== undefined &&
                    !isGrowGroups && (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          height: "auto",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className="going-group-part">
                          <div
                            className="going-label"
                            style={{ marginRight: "0px" }}
                          >
                            <CheckOutlined />
                            <span>I'm going</span>
                          </div>
                          <CustomButton
                            className="not-going-btn"
                            text="Not going"
                            size="sm"
                            type="remove"
                            remove={true}
                            onClick={this.onCancelAttend}
                          />
                        </div>
                      </div>
                    )}
                  {userProfile?.id !== undefined &&
                    isGrowGroups &&
                    stateGrowGroup?.status === "accepted" && (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          height: "auto",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className="going-group-part">
                          <div
                            className="going-label"
                            style={{ marginRight: "0px" }}
                          >
                            <CheckOutlined />
                            <span>I'm going</span>
                          </div>
                          <CustomButton
                            className="not-going-btn"
                            text="Not going"
                            size="sm"
                            type="remove"
                            remove={true}
                            onClick={this.onCancelAttend}
                          />
                        </div>
                      </div>
                    )}
                  {(callForSpeaker === true && this.showButtonFunction(
                    this.filterInvalidDates(startAndEndTimes),
                    timezone,
                    organizer,
                    title
                  )) &&
                    esIncoming && (
                      <CustomButton
                        text="Connect to this event"
                        size="sm"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          if (
                            ticket !== "free" &&
                            userProfile?.memberShip !== "premium"
                          ) {
                            this.props.setActiveMessages(
                              "Upgrade to a PREMIUM Membership and get unlimited access to the LAB features"
                            );
                            return;
                          }

                          window.open(
                            this.getEventLinkS(
                              bulLinkForEachDay,
                              link,
                              startAndEndTimes
                            ),
                            "_blank"
                          );
                        }}
                        type="primary"
                        style={{ width: "150px", marginTop: "10px" }}
                      />
                    )}

                  {(callForSpeaker === false &&
                    this.showButtonFunction(
                      this.filterInvalidDates(startAndEndTimes),
                      timezone,
                      organizer,
                      title
                    ) &&
                    esIncoming) && (
                      <CustomButton
                        text="Connect to this event"
                        size="sm"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          if (
                            ticket !== "free" &&
                            userProfile?.memberShip !== "premium"
                          ) {
                            this.props.setActiveMessages(
                              "Upgrade to a PREMIUM Membership and get unlimited access to the LAB features"
                            );
                            return;
                          }

                          window.open(
                            this.getEventLinkS(
                              bulLinkForEachDay,
                              link,
                              startAndEndTimes
                            ),
                            "_blank"
                          );
                        }}
                        type="primary"
                        style={{ width: "150px", marginTop: "10px" }}
                      />
                    )}

                  {bulCodeEvent === "true" && onPast && (
                    <CustomButton
                      text="Confirm I attended"
                      size="sm"
                      type="primary"
                      style={{
                        marginTop: "10px",
                        width: "150px",
                        textDecoration: "none",
                      }}
                      onClick={(e) => {
                        addEventVerification(e);
                      }}
                    />
                  )}

                  {/* <CustomButton
                  text="Invite Friends"
                  size="sm"
                  type="primary"
                  style={{ marginRight: "0px", width: '150px', marginTop: '10px' }}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setBulInviteModal(true)
                  }}
                  loading={this.state.loading}
                /> */}
                </div>
              )}

              {!caseLeft && (
                <div className="content-going">
                  <Tooltip title="Calendars">
                    <div
                      style={{
                        width: "50px",
                        height: "50px",
                        background: !this.state.selectCalendar
                          ? "white"
                          : "#FFD1C2",
                        borderRadius: "15px",
                        border: "1px solid #e1e2ee",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        transition: "all 1s",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        if (this.state.heightCard !== "0px") {
                          this.functionOnClickCalendar();
                        }
                      }}
                    >
                      <img
                        alt="calendar"
                        src={IconCalendar}
                        style={{
                          width: "30px",
                          height: "30px",
                          marginRight: "0px",
                        }}
                      />
                    </div>
                  </Tooltip>
                  {(status === "going" || (status === "past" && esIncoming)) &&
                    userProfile?.id !== undefined &&
                    bulTicketCustomized === "true" && (
                      <Tooltip title="Download event ticket">
                        <div
                          style={{
                            width: "50px",
                            height: "50px",
                            background: "white",
                            borderRadius: "15px",
                            border: "1px solid #e1e2ee",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            transition: "all 1s",
                            marginTop: "10px",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            this.changeVsibilityTicket();
                            setTimeout(() => {
                              this.donwloadEventCertificate();
                            }, 1000);
                          }}
                        >
                          <img
                            alt="ticket"
                            src={IconTicket}
                            style={{
                              width: "30px",
                              height: "30px",
                              marginRight: "0px",
                            }}
                          />
                        </div>
                      </Tooltip>
                    )}
                </div>
              )}

              <div
                className="content-calendar"
                style={{
                  height: !this.state.selectCalendar
                    ? "0px"
                    : this.state.heightCard,
                }}
              >
                {!isGrowGroups && (
                  <>
                    {status === "going" ||
                    (status === "past" &&
                      esIncoming &&
                      userProfile?.id !== undefined) ? (
                      <div
                        style={{
                          width: "100%",
                          height: "auto",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                        id={`calendars-event-car-${id}`}
                      >
                        <h5 className="event-card-topic-title">
                          {startAndEndTimes?.length > 1
                            ? " Calendar downloads: "
                            : " Calendar download: "}
                        </h5>
                        <Space
                          direction="vertical"
                          style={{ marginBottom: "1rem", width: "60%" }}
                        >
                          {this.filterInvalidDates(startAndEndTimes)?.map(
                            (time, index) => {
                              const startTime = convertToLocalTime(
                                time?.startTime,
                                timezone
                              );
                              const endTime = convertToLocalTime(
                                time?.endTime,
                                timezone
                              );
                              return (
                                <div className="d-flex" key={index}>
                                  <Space
                                    size="middle"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "flex-start",
                                    }}
                                  >
                                    {`${startTime?.format(
                                      "MMMM DD"
                                    )} From ${startTime?.format(
                                      "HH:mm a"
                                    )} to ${endTime?.format(
                                      "HH:mm a"
                                    )} (${userTimezone})`}
                                    <Dropdown
                                      overlay={downloadDropdownOptions(
                                        startTime,
                                        endTime,
                                        index,
                                        time?.link,
                                        this.props.data,
                                        this.functionFilterEventCouncil(),
                                        undefined,
                                        time?.startTime,
                                        time?.endTime,
                                      )}
                                    >
                                      <a
                                        href="/#"
                                        className="ant-dropdown-link"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                        }}
                                      >
                                        {startAndEndTimes.length > 1
                                          ? `Download Calendar Day ${
                                              index + 1
                                            }: ${moment(startTime)?.format(
                                              "MMM DD"
                                            )} `
                                          : "Download Calendar"}
                                        <DownOutlined />
                                      </a>
                                    </Dropdown>
                                  </Space>
                                </div>
                              );
                            }
                          )}
                        </Space>
                        {/* <div style={isMobile ? {
                      background: '#00b574', color: 'white', height: '80px', width: '250px', padding: '10px', fontWeight: '700', fontSize: '20px', borderRadius: '5px',
                      display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '10px'
                    } : {
                      background: '#00b574', color: 'white', height: '40px', width: '400px', padding: '10px', fontWeight: '700', fontSize: '20px', borderRadius: '5px',
                      display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '10px'
                    }}>
                      You are now registered for this event!
                    </div> */}
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "auto",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                        id={`calendars-event-car-${id}`}
                      >
                        <h5 className="event-card-topic-title">
                          {startAndEndTimes?.length > 1
                            ? " Calendar days: "
                            : " Calendar day: "}
                        </h5>
                        <Space
                          direction="vertical"
                          style={{ marginBottom: "1rem" }}
                        >
                          {this.filterInvalidDates(startAndEndTimes)?.map(
                            (time, index) => {
                              const startTime = convertToLocalTime(
                                time?.startTime,
                                timezone
                              );
                              const endTime = convertToLocalTime(
                                time?.endTime,
                                timezone
                              );

                              return (
                                <div className="d-flex" key={index}>
                                  <Space
                                    size="middle"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "flex-start",
                                    }}
                                  >
                                    {`${startTime?.format(
                                      "MMMM DD"
                                    )} From ${startTime?.format(
                                      "HH:mm a"
                                    )} to ${endTime?.format(
                                      "HH:mm a"
                                    )} (${userTimezone})`}
                                  </Space>
                                </div>
                              );
                            }
                          )}
                        </Space>
                      </div>
                    )}
                  </>
                )}

                {isGrowGroups && (
                  <>
                    {stateGrowGroup?.status === "accepted" &&
                    userProfile?.id !== undefined ? (
                      <div
                        style={{
                          width: "100%",
                          height: "auto",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                        id={`calendars-event-car-${id}`}
                      >
                        <h5 className="event-card-topic-title">
                          {startAndEndTimes?.length > 1
                            ? " Calendar downloads growgroups: "
                            : " Calendar download growgroups: "}
                        </h5>
                        <Space
                          direction="vertical"
                          style={{ marginBottom: "1rem", width: "60%" }}
                        >
                          {this.filterInvalidDates(startAndEndTimes)?.map(
                            (time, index) => {
                              const startTime = convertToLocalTime(
                                time?.startTime,
                                timezone
                              );
                              const endTime = convertToLocalTime(
                                time?.endTime,
                                timezone
                              );
                              return (
                                <div className="d-flex" key={index}>
                                  <Space
                                    size="middle"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "flex-start",
                                    }}
                                  >
                                    {`${startTime?.format(
                                      "MMMM DD"
                                    )} From ${startTime?.format(
                                      "HH:mm a"
                                    )} to ${endTime?.format(
                                      "HH:mm a"
                                    )} (${userTimezone})`}
                                    <Dropdown
                                      overlay={downloadDropdownOptions(
                                        startTime,
                                        endTime,
                                        index,
                                        time?.link,
                                        this.props.data,
                                        this.functionFilterEventCouncil(),
                                        undefined,
                                        time?.startTime,
                                        time?.endTime,
                                      )}
                                    >
                                      <a
                                        href="/#"
                                        className="ant-dropdown-link"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                        }}
                                      >
                                        {startAndEndTimes.length > 1
                                          ? `Download Calendar Day ${
                                              index + 1
                                            }: ${moment(startTime)?.format(
                                              "MMM DD"
                                            )} `
                                          : "Download Calendar"}
                                        <DownOutlined />
                                      </a>
                                    </Dropdown>
                                  </Space>
                                </div>
                              );
                            }
                          )}
                        </Space>
                        {/* <div style={isMobile ? {
                      background: '#00b574', color: 'white', height: '80px', width: '250px', padding: '10px', fontWeight: '700', fontSize: '20px', borderRadius: '5px',
                      display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '10px'
                    } : {
                      background: '#00b574', color: 'white', height: '40px', width: '400px', padding: '10px', fontWeight: '700', fontSize: '20px', borderRadius: '5px',
                      display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '10px'
                    }}>
                      You are now registered for this event!
                    </div> */}
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "auto",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                        id={`calendars-event-car-${id}`}
                      >
                        <h5 className="event-card-topic-title">
                          {startAndEndTimes?.length > 1
                            ? " Calendar days growgroups: "
                            : " Calendar day growgroups: "}
                        </h5>
                        <Space
                          direction="vertical"
                          style={{ marginBottom: "1rem" }}
                        >
                          {this.filterInvalidDates(startAndEndTimes)?.map(
                            (time, index) => {
                              const startTime = convertToLocalTime(
                                time?.startTime,
                                timezone
                              );
                              const endTime = convertToLocalTime(
                                time?.endTime,
                                timezone
                              );

                              return (
                                <div className="d-flex" key={index}>
                                  <Space
                                    size="middle"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "flex-start",
                                    }}
                                  >
                                    {`${startTime?.format(
                                      "MMMM DD"
                                    )} From ${startTime?.format(
                                      "HH:mm a"
                                    )} to ${endTime?.format(
                                      "HH:mm a"
                                    )} (${userTimezone})`}
                                  </Space>
                                </div>
                              );
                            }
                          )}
                        </Space>
                      </div>
                    )}
                  </>
                )}
              </div>

              {/* {channel === "" ||
                channel === undefined ||
                Number(channel) > 0 ? (
                <h5 className="event-card-topic-title">
                  {`Event date${startDate !== endDate ? "s" : ""}:`}
                  <span>{period2}</span>
                </h5>
              ) : (
                <h5 className="event-card-topic-title">
                  {`Event date${startDate !== endDate ? "s" : ""}:`}
                  <span>{period}</span>
                </h5>
              )} */}

              {/* {location && (
                <h5 className="event-card-topic-title">
                  Event type:{" "}
                  <span>
                    {location.map((loc, index) => {
                      if (loc === "online") {
                        return <>Online {location[index + 1] ? "and " : ""}</>;
                      }

                      return <>In Person {location[index + 1] ? "and " : ""}</>;
                    })}
                  </span>
                </h5>
              )} */}

              {/* {ticket && (
                <h5 className="event-card-topic-title">
                  Event tickets:{" "}
                  <span>
                    {ticket === "fee"
                      ? `$${ticketFee} Registration fee`
                      : ticket === "premium"
                        ? "Only PREMIUM members"
                        : capitalizeWord(ticket)}
                  </span>
                </h5>
              )} */}

              {/* <h5 className="event-card-topic-title">
                Content delivery format:{" "}
                {type &&
                  type.map((tp, index) => (
                    <span>
                      {capitalizeWord(tp)} {type[index + 1] && `|`}
                    </span>
                  ))}
              </h5> */}

              {/* {((status === "going" || (status === "past" && esIncoming))
                && userProfile?.id !== undefined) ? (
                <>
                  <h5 className="event-card-topic-title">
                    {startAndEndTimes?.length > 1
                      ? " Calendar downloads: "
                      : " Calendar download: "}
                  </h5>
                  <Space direction="vertical" style={{ marginBottom: "1rem", width: '60%' }}>
                    {this.filterInvalidDates(startAndEndTimes)?.map((time, index) => {
                      const startTime = convertToLocalTime(
                        time?.startTime,
                        timezone
                      );
                      const endTime = convertToLocalTime(
                        time?.endTime,
                        timezone
                      );
                      return (
                        <div className="d-flex" key={index}>
                          <Space
                            size="middle"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            {`${startTime?.format(
                              "MMMM DD"
                            )} From ${startTime?.format(
                              "HH:mm a"
                            )} to ${endTime?.format(
                              "HH:mm a"
                            )} (${userTimezone})`}
                            <Dropdown
                              overlay={downloadDropdownOptions(
                                startTime,
                                endTime,
                                index,
                                time?.link,
                                this.props.data,
                                this.functionFilterEventCouncil(),
                                undefined,
                                time?.startTime,
                                time?.endTime,
                              )}
                            >
                              <a
                                href="/#"
                                className="ant-dropdown-link"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                }}
                              >
                                {startAndEndTimes.length > 1
                                  ? `Download Calendar Day ${index + 1
                                  }: ${moment(startTime)?.format("MMM DD")} `
                                  : "Download Calendar"}
                                <DownOutlined />
                              </a>
                            </Dropdown>
                          </Space>
                        </div>
                      );
                    })}
                  </Space>
                  <div style={isMobile ? {
                    background: '#00b574', color: 'white', height: '80px', width: '250px', padding: '10px', fontWeight: '700', fontSize: '20px', borderRadius: '5px',
                    display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '10px'
                  } : {
                    background: '#00b574', color: 'white', height: '40px', width: '400px', padding: '10px', fontWeight: '700', fontSize: '20px', borderRadius: '5px',
                    display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '10px'
                  }}>
                    You are now registered for this event!
                  </div>

                  {(bulTicketCustomized === 'true') && <div style={isMobile ? {
                    position: 'relative', background: '#fe5621', color: 'white', height: '80px', width: '250px', padding: '10px', fontWeight: '700', fontSize: '20px', borderRadius: '5px',
                    display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '10px', marginTop: '15px'
                  } : {
                    position: 'relative', background: '#fe5621', color: 'white', height: '40px', width: '400px', padding: '10px', fontWeight: '700', fontSize: '20px', borderRadius: '5px',
                    display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '10px', marginTop: '15px'
                  }} onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    this.changeVsibilityTicket()
                    setTimeout(() => {
                      this.donwloadEventCertificate()
                    }, 1000);

                  }}>
                    Download your ticket here! {this.state.loadingTicket && <div style={{ position: "absolute", right: "0px", top: '0px', width: '45px', height: '100%', padding: '5px', background: 'white', display: 'flex', justifyContent: 'center', borderRadius: '0px 5px 5px 0px' }}><img alt="carg" width='35px' src={IconLoader}></img></div>}
                  </div>}
                </>
              ) : (

                <>
                  <h5 className="event-card-topic-title">
                    {startAndEndTimes?.length > 1
                      ? " Calendar days: "
                      : " Calendar day: "}
                  </h5>
                  <Space direction="vertical" style={{ marginBottom: "1rem" }}>
                    {this.filterInvalidDates(startAndEndTimes)?.map((time, index) => {
                      const startTime = convertToLocalTime(
                        time?.startTime,
                        timezone
                      );
                      const endTime = convertToLocalTime(
                        time?.endTime,
                        timezone
                      );

                      return (
                        <div className="d-flex" key={index}>
                          <Space
                            size="middle"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            {`${startTime?.format(
                              "MMMM DD"
                            )} From ${startTime?.format(
                              "HH:mm a"
                            )} to ${endTime?.format(
                              "HH:mm a"
                            )} (${userTimezone})`}

                          </Space>
                        </div>
                      );
                    })}
                  </Space>
                </>

              )} */}

              {/* <div className="event-card-content-footer" style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                marginTop: '8px',
              }}>
                <div className="event-card-content-footer-actions" style={{
                  width: '100%'
                }}>
                //esto no va
                  {  {(!["going", "attend"].includes(status) && showClaim === 1 && userProfile?.id !== undefined) && (
                    <div className="claim-buttons">
                      <CustomButton
                        className="claim-digital-certificate"
                        text="Confirm I attended this event"
                        size="md"
                        type="primary outlined"
                        onClick={this.onClickClaimCredits}
                      />
                    </div>
                  )} }
                  // hasta aqui
                  {(status === "attend" && userProfile?.id !== undefined) && (
                    <CustomButton
                      text="REGISTER HERE"
                      size="md"
                      type="primary"
                      style={{ marginRight: "20px", width: '200px' }}
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        this.inspectBul(bulCloseEvent, e)
                      }}
                      loading={this.state.loading}
                    />
                  )}

                  {(channel === "" || channel === undefined || Number(channel) > 0) && (
                    <a
                      href={userProfile?.id !== undefined ? link : "#"}
                      style={userProfile?.id !== undefined ? { margin: "0px", padding: "0px" } : { display: "none" }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <CustomButton
                        text="Attend"
                        size="md"
                        type="primary"
                        style={{ marginTop: '1rem' }}
                      />
                    </a>
                  )}

                  {(this.showButtonFunction(this.filterInvalidDates(startAndEndTimes), timezone, organizer, title) && esIncoming) && (
                    <div
                      href="#"
                      style={userProfile?.id !== undefined ? ({ margin: "0px", padding: "0px",cursor: 'pointer' }) : { display: "none" }}
                      target="_blank"
                      onClick={(e) => {

                        e.preventDefault()
                        e.stopPropagation()
                        if (ticket !== 'free' && userProfile?.memberShip !== 'premium') {
                          this.props.setActiveMessages("Upgrade to a PREMIUM Membership and get unlimited access to the LAB features")
                        }

                        window.open(this.getEventLinkS(bulLinkForEachDay, link, startAndEndTimes), "_blank")
                      }}
                      rel="noopener noreferrer"
                    >
                      <div style={isMobile ? {
                        background: '#fe5621', color: 'white', height: '80px', width: '250px', padding: '10px', fontWeight: '700', fontSize: '20px', borderRadius: '5px',
                        display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '10px', marginTop: '20px'
                      } : {
                        background: '#fe5621', color: 'white', height: '40px', width: '400px', padding: '10px', fontWeight: '700', fontSize: '20px', borderRadius: '5px',
                        display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '10px', marginTop: '20px'
                      }}>
                        Click here to connect to this event
                      </div>
                    </div>
                  )}

                  {((lengthLibraries > 0 && lengthLibraries !== undefined) && onPast) && (
                    <Link
                      to={(userProfile?.id !== undefined) ? `${INTERNAL_LINKS.MY_LEARNINGS}?id=${id}&tab=1` : "#"}
                      style={(userProfile?.id !== undefined) ? { margin: "0px", padding: "0px", textDecoration: 'none' } : { display: "none" }}
                    >
                      <CustomButton
                        text="Event videos"
                        size="md"
                        type="primary"
                        style={{ marginTop: '1rem', width: '200px', textDecoration: 'none', marginRight: '20px' }}
                      />
                    </Link>
                  )}

                  {(bulCodeEvent === 'true' && onPast) && (

                    <CustomButton
                      text="Confirm I attended this event"
                      size="md"
                      type="primary"
                      style={{ marginTop: '1rem', width: '275px', textDecoration: 'none' }}
                      onClick={(e) => {
                        addEventVerification(e)
                      }}
                    />
                  )}

                  {(status === "going" && userProfile?.id !== undefined) && (
                    <div style={{ marginTop: '20px', display: 'flex', width: '100%', height: "auto", justifyContent: "center", alignItems: 'center' }}>
                      <div className="going-group-part">
                        <div className="going-label">
                          <CheckOutlined />
                          <span>I'm going</span>
                        </div>
                        <CustomButton
                          className="not-going-btn"
                          text="Not going"
                          size="md"
                          type="remove"
                          remove={true}
                          onClick={this.onCancelAttend}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div> */}
            </div>
            {edit && (
              <CardMenu onClick={onMenuClick}>
                <div className="event-card-menu">
                  <img src={IconMenu} alt="icon-menu" />
                </div>
              </CardMenu>
            )}
          </>
        )}

        <Modal
          className={clsx("custom-modal")}
          wrapClassName={clsx("custom-modal-wrap")}
          centered
          onCancel={(e) => {
            e.preventDefault();
            e.stopPropagation();
            this.setBulPopup(false);
          }}
          visible={this.state.popUp}
          closable={true}
          footer={[]}
          width="500px"
          style={{ top: "100px" }}
          title={
            <div
              className="custom-modal-title"
              style={{
                paddingLeft: "20px",
                paddingRight: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
              }}
            >
              <div className="custom-modal-logo">
                <img src={IconLogo} alt="custom-logo" />
              </div>
            </div>
          }
          closeIcon={<CloseCircleFilled className="custom-modal-close" />}
        >
          <div className="library-claim-modal">
            <p style={{ textAlign: "center", fontSize: "18px" }}>
              This event is sold out.
            </p>
          </div>
        </Modal>

        {/* <div
          className="event-compro-img-aaa"
          id={`container-information-event-${id}-1`}
          style={{ backgroundImage: `url(${this.dataURLtoFile(ticketImage)})` }}
        >
          <div className="certificate">
            <div className="certificate-top">
              <div className="certificate-logo">
                <img src={ImgHHRLogo} alt="sidebar-logo" />
              </div>
            </div>
            <div className="container-background-event-data">
              <p className="header-event-format">I am attending Hacking HR’s event</p>
              <p className="certificate-username">{title}</p>
              <div className="certificate-bottom">
                <div className="certificate-bottom-sign">
                  <p className="certificate-text1 date">
                    {(moment(startDate, "YYYY-MM-DDTHH:mm:ss").format("ll") !== moment(endDate, "YYYY-MM-DDTHH:mm:ss").format("ll")) ? `
                      From 
                      ${moment(startDate, "YYYY-MM-DDTHH:mm:ss").format("ll")} 
                      to 
                      ${moment(endDate, "YYYY-MM-DDTHH:mm:ss").format("ll")}
                    ` : `
                      ${moment(startDate, "YYYY-MM-DDTHH:mm:ss").format("ll")} 
                    `}
                  </p>
                  <p className="certificate-text1">Register here: {process.env.REACT_APP_DOMAIN_URL}/{id}</p>
                </div>
              </div>
            </div>
          </div>
        </div>  */}

        {this.state.showDownLoadCertificate && (
          <div
            style={{
              position: "absolute",
              left: "-10000px",
              width: "650px",
              padding: "24px",
            }}
          >
            <div
              id={`container-information-event-${id}-1`}
              // className="container-ticket-eeee"
              style={{
                height: "604px",
                width: `${widthTicket}px`,
                position: "relative",
              }}
            >
              <img
                className="challenge-compro-img-aaaa"
                src={`${this.imageRectificate(imageTicket, ImageTicketType)}`}
                alt="badge"
              />
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "grid",
                  gridTemplateColumns:
                    "2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222%",
                  gridTemplateRows:
                    "2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222% 2.222%",
                  position: "absolute",
                  top: "0px",
                }}
                draggable="false"
              >
                {this.functionOrder(newBoxes).map((data) => {
                  const calcDuration = (data) => {
                    let duration = 0;

                    if (data !== undefined) {
                      data.forEach((date) => {
                        duration =
                          date !== null
                            ? duration +
                              moment(
                                date?.endTime,
                                "YYYY-MM-DDTHH:mm:ssZ"
                              ).diff(date?.startTime, "hours")
                            : duration;
                      });
                    }

                    return duration;
                  };

                  const textNameFunction = () => {
                    if (data.textName === "$#duration$#") {
                      return `${moment(startDate, "YYYY-MM-DDTHH:mm:ss").format(
                        "ll"
                      )} - ${moment(endDate, "YYYY-MM-DDTHH:mm:ss").format(
                        "ll"
                      )}`;
                    } else if (data.textName === "$#nameevent$#") {
                      return title;
                    } else if (data.textName === "$#user$#") {
                      return userProfile.firstName + " " + userProfile.lastName;
                    } else if (data.textName === "$#durationHour$#") {
                      return `Duration: ${calcDuration(
                        startAndEndTimes
                      )} hours`;
                    } else {
                      return data.textName;
                    }
                  };

                  return (
                    <BoxItered
                      box={{
                        showColor: "false",
                        bulShowBox: data.bulShowBox,
                        boldTextBox: data.boldTextBox,
                        fontSizeBox: data.fontSizeBox,
                        lineHeightBox: data.lineHeightBox,
                        lineTextBox: data.lineTextBox,
                        colorTextBox: data.colorTextBox,
                        textName: textNameFunction(),
                      }}
                      columnSelect={data.columnSelect}
                      columnSelect2={data.columnSelect2}
                      rowSelect={data.rowSelect}
                      rowSelect2={data.rowSelect2}
                    />
                  );
                })}
                {[
                  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
                  19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33,
                  34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45,
                ].map((data, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `1/1`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="1"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `2/2`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="2"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `3/3`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="3"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `4/4`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="4"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `5/5`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="5"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `6/6`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="6"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `7/7`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="7"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `8/8`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="8"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `9/9`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="9"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `10/10`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="10"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `11/11`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="11"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `12/12`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="12"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `13/13`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="13"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `14/14`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="14"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `15/15`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="15"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `16/16`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="16"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `17/17`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="17"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `18/18`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="18"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `19/19`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="19"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `20/20`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="20"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `21/21`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="21"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `22/22`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="22"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `23/23`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="23"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `24/24`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="24"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `25/25`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="25"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `26/26`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="26"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `27/27`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="27"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `28/28`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="28"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `29/29`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="29"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `30/30`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="30"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `31/31`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="31"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `32/32`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="32"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `33/33`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="33"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `34/34`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="34"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `35/35`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="35"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `36/36`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="36"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `37/37`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="37"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `38/38`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="38"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `39/39`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="39"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `40/40`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="40"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `41/41`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="41"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `42/42`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="42"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `43/43`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="43"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `44/44`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="44"
                      ></div>
                      <div
                        style={{
                          gridColumn: `${data}/${data}`,
                          gridRow: `45/45`,
                          border: `0.25px solid transparent`,
                          background: "rgba(217, 217, 217, 0.04)",
                          zIndex: "10",
                        }}
                        id={`${data}`}
                        className="45"
                      ></div>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

EventCard.propTypes = {
  data: PropTypes.object,
  className: PropTypes.string,
  edit: PropTypes.bool,
  caseLeft: PropTypes.bool,
  type: PropTypes.string,
  onClick: PropTypes.func,
  onAttend: PropTypes.func,
  onMenuClick: PropTypes.func,
  onConfirmAttendance: PropTypes.func,
  onConfirmCredit: PropTypes.func,
  userProfile: PropTypes.object,
  isMobile: PropTypes.bool,
  onPast: PropTypes.bool,
  isGrowGroups: PropTypes.bool,
  confirmUserEvent: PropTypes.object,
  addEventVerification: PropTypes.func,
  esIncoming: PropTypes.bool,
  boxes: PropTypes.object,
};

EventCard.defaultProps = {
  data: {},
  className: "",
  edit: false,
  caseLeft: false,
  type: CARD_TYPE.VIEW,
  userProfile: {},
  onClick: () => {},
  onAttend: () => {},
  onMenuClick: () => {},
  onConfirmAttendance: () => {},
  onConfirmCredit: () => {},
  setActiveMessages: () => {},
  boxes: {},
  isMobile: false,
  onPast: false,
  isGrowGroups: false,
  confirmUserEvent: {},
  addEventVerification: () => {},
  esIncoming: false,
};

export default withRouter(EventCard);
