import { put, fork, takeLatest, call } from "redux-saga/effects";
import { notification } from "antd";

import {
    constants as eventCodeConstanst,
    actions as eventCodeActions,
} from "../actions/event-verfication-code";
import { actions as homeActions } from "../actions/home-actions";

import {
    addEventVerificationEndPoint,
    getEventVerificationEndPoint,
} from "../../api";

export function* addEventVerificationSagas({ payload }) {
    // yield put(homeActions.setLoading(true));

    const { body } = payload

    try {
        const response = yield call(addEventVerificationEndPoint, body);

        if (response.status === 200) {
            if (payload.callback) {
                payload.callback()
            }

            notification.success({
                message: "Digital Certificate of Participation and more information sent via email.",
            });

        }
    } catch (error) {
        console.log(error)
        if (payload.callback) {
            payload.callback()
        }
        notification.error({
            message: "",
            description: error?.response?.data?.msg,
        });
    } finally {
        if (payload.callback) {
            payload.callback()
        }
        // yield put(homeActions.setLoading(false));
    }
}

export function* getEventVerificationSagas() {
    yield put(homeActions.setLoading(true));

    try {
        const response = yield call(getEventVerificationEndPoint);

        if (response.status === 200) {
            yield put(
                eventCodeActions.setEventVerification(response.data.EventWorkshopVerifyData)
            );
        }
    } catch (error) {
        console.log(error)
        notification.error({
            message: "ERROR:",
            description: error?.response?.data?.msg,
        });
    } finally {
        yield put(homeActions.setLoading(false));
    }
}

function* watchEnv() {
    yield takeLatest(eventCodeConstanst.ADD_EVENT_VERIFICATION, addEventVerificationSagas);
    yield takeLatest(eventCodeConstanst.GET_EVENT_VERIFICATION, getEventVerificationSagas);
}

export const eventVerificationCodeSagas = [fork(watchEnv)];
