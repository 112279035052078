import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { 
  // Link, 
  useLocation } from "react-router-dom";

// import { isEmpty } from "lodash";

import { homeSelector } from "redux/selectors/homeSelector";
import { councilSelector } from "redux/selectors/councilSelector";
// import { INTERNAL_LINKS } from "enum";
// import qs from "query-string";

// import FilterDrawer from "./CouncilPanel";
import { CustomButton, CustomCheckbox, Tabs } from "components";
// import NoItemsMessageCard from "components/NoItemsMessageCard";
import CouncilMembers from "./CouncilMembers";
import CallForSpeakers from "./CallForSpeakers.js";

// import { actions as councilConversation } from "redux/actions/councilConversation-actions";
// import { councilConversationSelector } from "redux/selectors/councilConversationSelector";
import { speakerAllPanelSpeakerSelector } from "redux/selectors/speakerSelector";
// import { eventSelector } from "redux/selectors/eventSelector";
import { councilEventSelector } from "redux/selectors/councilEventSelector";

import { sendEmailsForPartOfCouncil } from "redux/actions/council-actions";
import { getUser } from "redux/actions/home-actions";
import { getAllParafs } from "redux/actions/speaker-actions";
// import { getAllEvent } from "redux/actions/event-actions";
import { getCouncilEvents } from "redux/actions/council-events-actions";

// import IconBack from "images/icon-back.svg";

import "./style.scss";
import { Modal, Space } from "antd";
import ModalCompleteYourProfile2 from "pages/LandingPague/ModalCompleteYourProfile2";

const CouncilPage = ({
  // allEvents,
  userProfile,
  // getCouncilConversations,
  // getAllEvent,
  // allCouncilEvents,
  allCouncilEventsT,
  getCouncilEvents,
  // councilConversation,
  // getCouncilConversation,
  sendEmailsForPartOfCouncil,
  getAllParafs,
  allParrafs,
}) => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const [bulModal, setBulModal] = useState(false);
  const [bulModal2, setBulModal2] = useState(false);
  const [bulModal5, setBulModal5] = useState(false);
  const [checked, setChecked] = useState(false);
  const [message, setMessage] = useState(false);
  const [changueModal2, setChangueModal2] = useState(false);
  const [changueModal3, setChangueModal3] = useState(false);
  const [isPendingCouncil, setIsPendingCouncil] = useState(
    userProfile?.isPendingCouncil
  );

  const [currentTab, setCurrentTab] = useState(query.get("tab") || "0");

  const [filter,] = useState([]);

  // const [classCouncilContainer, setClassCouncilContainer] = useState(
  //   "search-results-containerr"
  // );
  // const location = useLocation();

  // const parsed = qs.parse(location.search);

  const firstName = userProfile?.firstName;

  useEffect(() => {
    // if (!allEvents || allEvents.length === 0) {
    //   getAllEvent();
    // }
    if (!allCouncilEventsT || allCouncilEventsT.length === 0) {
      getCouncilEvents(() => {},'without-panels');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsPendingCouncil(userProfile?.isPendingCouncil);
  }, [userProfile]);

  useEffect(() => {
    getAllParafs("Experts");
  }, [getAllParafs]);

  useEffect(() => {
    if (bulModal === true && firstName !== "" && firstName !== undefined) {
      setTimeout(() => {
        if (
          userProfile.percentOfCompletion === "100" ||
          userProfile.percentOfCompletion === 100
        ) {
          setBulModal2(true);
          setBulModal5(false);
        } else {
          if (!bulModal2) {
            setBulModal5(true);
            setBulModal2(false);
          }
        }
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstName, setBulModal2, setBulModal5, userProfile]);

  useEffect(() => {
    if (changueModal2 === true && firstName !== "" && firstName !== undefined) {
      setTimeout(() => {
        setBulModal5(false);
        if (
          userProfile.percentOfCompletion === "100" ||
          userProfile.percentOfCompletion === 100
        ) {
          setBulModal2(true);
        }
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstName, changueModal2, setBulModal2, setBulModal5]);

  useEffect(() => {
    if (changueModal3 === true && firstName !== "" && firstName !== undefined) {
      setTimeout(() => {
        if (
          userProfile.percentOfCompletion === "100" ||
          userProfile.percentOfCompletion === 100
        ) {
          setBulModal2(true);
        } else {
          if (!bulModal2) {
            setBulModal5(true);
          }
        }
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstName, changueModal3, setBulModal2, setBulModal5, userProfile]);

  // useEffect(() => {
  //   getCouncilConversations(filter);

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [filter]);

  // useEffect(() => {
  //   if (councilConversation?.id) {
  //     window.history.replaceState(
  //       null,
  //       "Page",
  //       `${INTERNAL_LINKS.COUNCIL}?id=${councilConversation.id}`
  //     );
  //   }
  // }, [councilConversation]);

  // useEffect(() => {
  //   if (parsed.id) {
  //     getCouncilConversation(parsed.id);
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [parsed.id]);

  // const onFilterChange = (values) => {
  //   setFilter(values);
  // };

  const TabData = [
    // {
    //   title: "Conversations",
    //   content: () =>
    //     isEmpty(councilConversation) ? (
    //       <NoItemsMessageCard />
    //     ) : (
    //       <CouncilConversationsCard councilConversation={councilConversation} />
    //     ),
    // },
    // {
    //   title: "Resources",
    //   content: () => (
    //     <CouncilList
    //       type="article"
    //       refresh={currentTab === "0"}
    //       setCurrentValue={setCurrentTab}
    //       filter={filter}
    //     />
    //   ),
    // },
    {
      title: "Council Members",
      content: () => <CouncilMembers />,
    },
    {
      title: "Call for Speakers",
      content: () => (
        <CallForSpeakers
          type="article"
          data={allCouncilEventsT}
          // data={allEvents}
          refresh={currentTab === "0"}
          setCurrentValue={setCurrentTab}
          filter={filter}
        />
      ),
    },
  ];

  const displayGeneralInformation = () => {
    return (
      <div className="display-general-information">
        <div className="information">
          <Space direction="vertical" size="large">
            <div className="container-parraf" style={{ marginTop: "30px" }}>
              {allParrafs.map((parraf) => {
                return (
                  <div dangerouslySetInnerHTML={{ __html: parraf.text }}></div>
                );
              })}
            </div>
          </Space>
          <div style={{ display: "flex", justifyContent: "end" }}>
            {isPendingCouncil === "pending" ? (
              <CustomButton text="Pending" type="primary" size="lg" disabled />
            ) : (
              <CustomButton
                text="Apply Now"
                type={"primary"}
                onClick={() => {
                  // if (localStorage.getItem("community") === null) {
                  // setBulModal(true);
                  // } else {
                  getUser();
                  setChangueModal3(true);
                  setBulModal(true);
                  // }
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {userProfile.councilMember || userProfile.role === "admin" ? (
        <div
          className="council-page"
          style={{ overflow: "auto", height: "100%" }}
        >
          {/* <div className="container-space-left"></div> */}

          {/* <div className="council-filter-panel"> */}
          {/* <FilterDrawer onChange={onFilterChange} /> */}
          {/* </div> */}
          <div className="search-results-containerr" style={{paddingBottom: '0px',paddingTop: '0px'}}>
            {/* <Row>
              <Col span={24}>
                <div className="search-results-container-mobile-header">
                  <h3 className="filters-btn" onClick={showFilterPanel}>
                    Filters
                  </h3>
                </div>
              </Col>
            </Row> */}
            <div className="council-page__container">
              <div className="council-page__results">
                <div className="council-page__row">
                  {/* <div className="council-page__info-column"></div> */}
                  <div className="council-page__content">
                    {/* <div className="council-filter-panel">
                      <Link to={INTERNAL_LINKS.HOME}>
                        <div className="council-page__content-top">
                          <div className="council-page__content-top-back">
                            <img src={IconBack} alt="icon-back" />
                          </div>
                          <h4>Back</h4>
                        </div>
                      </Link>
                    </div> */}
                    <Tabs
                      data={TabData}
                      current={currentTab}
                      onChange={setCurrentTab}
                      // functionClass={setClassCouncilContainer}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="container-space-right"></div> */}
        </div>
      ) : (
        <div style={{ height: "90vh", overflow: "scroll" }}>
          {displayGeneralInformation()}
        </div>
      )}
      <Modal
        visible={bulModal2 || bulModal5}
        footer={null}
        width={400}
        bodyStyle={{
          marginBottom: "40px",
          padding: "20px",
          display: "flex",
          justifyContent: "center",
        }}
        className="modal-container-login"
        onCancel={() => {
          // setBulModal(false);
          setBulModal2(false);
          setChecked(false);
          setBulModal5(false);
          setChangueModal3(false);
          setBulModal(false);
        }}
      >
        {bulModal2 && (
          <div
            style={{
              width: "100%",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                marginTop: "100px",
                width: "470px",
                background: "white",
                height: "700px",
                borderRadius: "5px",
                display: "flex",
                padding: "40px",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <p style={{ margin: "5px" }}>Hi {userProfile.firstName},</p>
              <p style={{ margin: "5px" }}>
                We are so excited you want to join us at the Hacking HR’s
                Experts Council!
              </p>
              <p style={{ margin: "5px" }}>
                Our goal is to provide insights, ideas, recommendations, and
                expertise about very specific topics to the thousands of members
                of the Hacking HR community and the extended HR community.
              </p>
              <p style={{ margin: "5px" }}>
                In addition, Founding Members in the Council will always be our
                priority to invite for our Hacking HR events, particularly our
                annual global online conference, and also podcasts, and much
                more!
              </p>
              <p style={{ margin: "5px" }}>
                The time commitment is as much or as little as you want and can.
                We will always invite you to everything and you choose when you
                are available or interested to join us.
              </p>
              <p style={{ margin: "5px" }}>
                Please note that to be considered for the Council you have to be
                an HR leader (director, VP, SVP or CHRO/CPO) in a company with
                more than 50 employees. We are not including consultants or HR
                tech vendors at the moment.{" "}
              </p>
              <p style={{ margin: "5px" }}>
                Confirm below and we will receive your information via email and
                get back to you soon!
              </p>
              <p style={{ margin: "5px" }}>Thank you!</p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  marginTop: "20px",
                }}
              >
                <CustomCheckbox
                  size="sm"
                  checked={checked}
                  onChange={(e) => setChecked(e.target.checked)}
                >
                  Confirm here
                </CustomCheckbox>
                {message && (
                  <p
                    style={{
                      color: "#fe5621",
                      margin: "5px",
                    }}
                  >
                    You need to confirm
                  </p>
                )}
                <CustomButton
                  className="button-speaker font-size-more"
                  text="Join the Hacking HR Experts Council"
                  size="md"
                  style={{
                    padding: "10px",
                    height: "40px",
                    width: "320px",
                    marginTop: "20px",
                  }}
                  type={"primary"}
                  onClick={() => {
                    if (!checked) {
                      setMessage(true);
                      setTimeout(() => {
                        setMessage(false);
                      }, 1000);
                    } else {
                      sendEmailsForPartOfCouncil(userProfile, () => {
                        setBulModal(false);
                        setBulModal2(false);
                        setBulModal5(false);
                        setChecked(false);
                        setIsPendingCouncil("pending");
                      });
                    }
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {bulModal5 && (
          <ModalCompleteYourProfile2
            style={{ marginTop: "100px" }}
            userProfile={userProfile}
            onOk={() => {
              setChangueModal2(true);
            }}
          />
        )}
      </Modal>
    </>
  );
};

const mapStateToProps = (state, props) => ({
  userProfile: homeSelector(state).userProfile,
  allParrafs: speakerAllPanelSpeakerSelector(state).allParrafs,
  // allEvents: eventSelector(state).allEvents,
  // allCouncilEvents: councilEventSelector(state).allCouncilEvents,
  allCouncilEventsT: councilEventSelector(state).allCouncilEventsT,
  councilResources: councilSelector(state).councilResources,
  // ...councilConversationSelector(state),
});

const mapDispatchToProps = {
  getUser,
  sendEmailsForPartOfCouncil,
  getAllParafs,
  // getAllEvent,
  getCouncilEvents,
  // ...councilConversation,
};

export default connect(mapStateToProps, mapDispatchToProps)(CouncilPage);
