import React, { useEffect, useState } from "react";
import "./style.scss";
import { connect } from "react-redux";
import moment from "moment";
// import CertificateIcon from "images/certificatesIcon.js";
import { homeSelector } from "redux/selectors/homeSelector";
import { getUser } from "redux/actions/home-actions";
import { actions as courseActions } from "redux/actions/course-actions";
import IconLogo from "images/logo-sidebar.svg";
import { courseSelector } from "redux/selectors/courseSelector";
import { Avatar, Divider, notification, Modal } from "antd";
import iconDown from "images/icon-linkedin.svg";
import ButtonsCalendar from "./Button";
import { ModalCompleteYourProfile } from "components";
import { CustomButton, Tabs } from "components";

import { convertToLocalTime } from "utils/format";

import CollapseCertificate from "./CollapseCertificate";
import CollapseModules from "./CollapseModules";
import CardUser from "./CardUser";
import {
  certificateApplyEnrollmentApi,
  certificateWithdrawEnrollmentApi,
} from "api/module/certificate";

const CertificatesPage = ({
  userProfile,
  courseSelected,
  getCourseNew,
  getCertificateUserBySlug,
  certificateUsersInfo,
  match,
}) => {
  const [currentTab, setCurrentTab] = useState("0");
  const [enrolled, setEnrolled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openModal2, closeModal2] = useState(false);
  const [bulCompleteProfile, setBulCompleteProfile] = useState(false);
  // const [modal, setModal] = useState(false);
  const [withDraw, setWithDraw] = useState({
    r1: false,
    r2: false,
    r3: false,
  });
  const [enrollmentStatus, setEnrollmentStatus] = useState(null);
  const [btnText, setBtnText] = useState("");
  const [btnStyleKey, setBtnStyleKey] = useState("");

  const [showEnrollmentModal, setShowEnrollmentModal] = useState(false);

  useEffect(() => {
    getCourseNew(
      -1,
      window.location.pathname.slice(14, window.location.pathname.length)
    );
  }, [getCourseNew]);

  useEffect(() => {
    if (
      courseSelected &&
      courseSelected?.CertificateUsers &&
      userProfile &&
      userProfile?.id !== -1
    ) {
      const fetchedUser = (courseSelected?.CertificateUsers || [])?.find(
        (user) => user.UserId === userProfile.id
      );

      if (!fetchedUser) {
        setBtnText("ENROLL NOW");
        setBtnStyleKey("enrollNow");
      } else {
        const status = fetchedUser?.status;

        if (status) {
          setEnrollmentStatus(status);
        }

        if (status === "active") {
          setEnrolled(true);
          setBtnText("ENROLLED");
        } else if (status === "pending") {
          setBtnText("PENDING APPLICATION");
          setEnrolled(false);
        } else {
          setEnrolled(false);
        }

        setBtnStyleKey(status);
      }
    }
  }, [courseSelected, userProfile]);

  useEffect(() => {
    if (match.params.id) {
      console.log(match.params.id);
      getCertificateUserBySlug(match.params.id);
    }
  }, [getCertificateUserBySlug, match.params.id]);

  // const handleClick = (e) => {
  //   e.preventDefault();
  //   // setModal(true);
  // };

  const orderClassCertificates = (data) => {
    return Array.isArray(data)
      ? (data || []).sort((a, b) => {
          let aTime = convertToLocalTime(a?.startTime, a?.timezone).format(
            "YYYYMMDDHHmm"
          );
          let bTime = convertToLocalTime(b?.startTime, b?.timezone).format(
            "YYYYMMDDHHmm"
          );

          return Number(aTime) - Number(bTime);
        })
      : [];
  };

  const classesContainer = orderClassCertificates(
    courseSelected?.CourseClasses
  )?.map((e, index) => {
    let startDate = convertToLocalTime(e?.startDate, e?.timezone);
    let startTime = convertToLocalTime(e?.startTime, e?.timezone);
    let endTime = convertToLocalTime(e?.endTime, e?.timezone);

    return (
      <CollapseModules
        title={e?.title}
        key={index}
        content={
          <div className="container-collapse-module">
            <div className="container-collapse-module-content">
              <div className="left-segment-collapse">
                <div
                  style={{
                    margin: "0px",
                    marginTop: "5px",
                    marginBottom: "5px",
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: e?.descriptionForCertificate,
                    }}
                  />
                </div>
                <>
                  <p
                    style={{
                      margin: "0px",
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    <b>Class Date</b>:
                    {` ${(startDate || moment())?.format("LL")}`}
                  </p>
                  <p
                    style={{
                      margin: "0px",
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    <b>Class Start Time:</b>{" "}
                    {(startTime || moment())?.format("HH:mm a")}{" "}
                    {moment.tz.guess()}
                  </p>
                  <p
                    style={{
                      margin: "0px",
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    <b>Class End Time:</b>{" "}
                    {(endTime || moment())?.format("HH:mm a")}{" "}
                    {moment.tz.guess()}
                  </p>
                  {enrolled && (
                    <ButtonsCalendar panels={e} slug={courseSelected?.slug} />
                  )}
                </>
              </div>
              <div className="right-segment-collapse">
                {(e?.CertificateClassInstructors || []).map((ins, index) => {
                  return (
                    <div className="module-card-user-collapse" key={index}>
                      <Avatar src={ins?.img} size={120}></Avatar>
                      <Divider className="module-divider-collapse" />
                      <h3 className="module-name-collapse">{`${ins?.firstName} ${ins?.lastName}`}</h3>
                      <p className="module-text-content-collapse"></p>
                      <a
                        href={ins?.linkedin || "#"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="module-icon-linkedin"
                          src={iconDown}
                          alt="linkedin"
                        />
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="container-background-subclass">
              {e?.guestLectures?.map((panel, index2) => {
                let startDate2 = convertToLocalTime(
                  panel?.startDate,
                  e?.timezone
                );
                let startTime2 = convertToLocalTime(
                  panel?.startTime,
                  e?.timezone
                );
                let endTime2 = convertToLocalTime(panel?.endTime, e?.timezone);
                return (
                  <CollapseModules
                    title={panel?.title}
                    background={"transparent"}
                    key={index2}
                    content={
                      <div className="container-collapse-module">
                        <div className="container-collapse-module-content">
                          <div className="left-segment-collapse">
                            <div
                              style={{
                                margin: "0px",
                                marginTop: "5px",
                                marginBottom: "5px",
                              }}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: panel?.description,
                                }}
                              />
                            </div>
                            <p
                              style={{
                                margin: "0px",
                                marginTop: "5px",
                                marginBottom: "5px",
                              }}
                            >
                              <b>Class Date</b>:{` ${startDate2?.format("LL")}`}
                            </p>
                            <p
                              style={{
                                margin: "0px",
                                marginTop: "5px",
                                marginBottom: "5px",
                              }}
                            >
                              <b>Class Start Time:</b>{" "}
                              {startTime2?.format("HH:mm a")}{" "}
                              {moment.tz.guess()}
                            </p>
                            <p
                              style={{
                                margin: "0px",
                                marginTop: "5px",
                                marginBottom: "5px",
                              }}
                            >
                              <b>Class End Time:</b>{" "}
                              {endTime2?.format("HH:mm a")} {moment.tz.guess()}
                            </p>
                            {enrolled && (
                              <ButtonsCalendar
                                panels={panel}
                                slug={courseSelected?.slug}
                              />
                            )}
                          </div>
                          <div className="right-segment-collapse">
                            {panel?.intructors.map((ins, index) => {
                              return (
                                <div
                                  className="module-card-user-collapse"
                                  key={index}
                                >
                                  <Avatar src={ins?.img} size={120}></Avatar>
                                  <Divider className="module-divider-collapse" />
                                  <h3 className="module-name-collapse">{`${ins?.firstName} ${ins?.lastName}`}</h3>
                                  <p className="module-text-content-collapse"></p>
                                  <a
                                    href={ins?.linkedin || "#"}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      className="module-icon-linkedin"
                                      src={iconDown}
                                      alt="linkedin"
                                    />
                                  </a>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    }
                  />
                );
              })}
            </div>
          </div>
        }
      />
    );
  });

  const scheduleContainer = orderClassCertificates(
    courseSelected?.CourseClasses || []
  )?.map((e, index) => {
    let startDate = convertToLocalTime(e?.startDate, e?.timezone);
    let startTime = convertToLocalTime(e?.startTime, e?.timezone);
    let endTime = convertToLocalTime(e?.endTime, e?.timezone);

    return (
      <CollapseModules
        title={
          <>
            {startDate.format("dddd, MMMM DD")}
            <sup>{startTime.format("Do").slice(-2)}</sup>
          </>
        }
        key={index}
        background={"transparent"}
        content={
          <div className={"container-collapse-module"}>
            <div className={"container-collapse-module-content"}>
              <div className={"left-segment-collapse"}>
                <h2>{e.title}</h2>
                <div
                  style={{
                    margin: "0px",
                    marginTop: "5px",
                    marginBottom: "5px",
                  }}
                >
                  <div
                    className={"container-descriptionnn"}
                    dangerouslySetInnerHTML={{
                      __html: e?.descriptionForCertificate,
                    }}
                  />
                </div>
                {e?.title !== "Preparation Week" && (
                  <>
                    <p
                      style={{
                        margin: "0px",
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <b>Class Date</b>:
                      {` ${(startDate || moment())?.format("LL")}`}
                    </p>
                    <p
                      style={{
                        margin: "0px",
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <b>Class Start Time:</b>{" "}
                      {(startTime || moment())?.format("HH:mm a")}{" "}
                      {moment.tz.guess()}
                    </p>
                    <p
                      style={{
                        margin: "0px",
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <b>Class End Time:</b>{" "}
                      {(endTime || moment())?.format("HH:mm a")}{" "}
                      {moment.tz.guess()}
                    </p>
                    {enrolled && (
                      <ButtonsCalendar panels={e} slug={courseSelected?.slug} />
                    )}
                  </>
                )}
              </div>
              <div className={"right-segment-collapse"}>
                {(e?.CertificateClassInstructors || []).map((ins, index) => {
                  return (
                    <div className={"module-card-user-collapse"} key={index}>
                      <Avatar src={ins?.img} size={120}></Avatar>
                      <Divider className={"module-divider-collapse"} />
                      <h3
                        className={"module-name-collapse"}
                      >{`${ins?.firstName} ${ins?.lastName}`}</h3>
                      <p style={{ fontSize: "15px" }}>
                        {ins?.titleProfessions}
                      </p>
                      <a
                        href={ins?.linkedin || "#"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className={"module-icon-linkedin"}
                          src={iconDown}
                          alt="linkedin"
                        />
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              className={"container-background-subclass"}
              style={{ background: "#fafafa" }}
            >
              {e?.guestLectures?.map((panel, index2) => {
                let startDate2 = convertToLocalTime(
                  panel?.startDate,
                  e?.timezone
                );
                let startTime2 = convertToLocalTime(
                  panel?.startTime,
                  e?.timezone
                );
                let endTime2 = convertToLocalTime(panel?.endTime, e?.timezone);
                return (
                  <CollapseModules
                    title={
                      <>
                        {startDate2.format("dddd, MMMM DD")}
                        <sup>{startTime2.format("Do").slice(-2)}</sup>
                      </>
                    }
                    background={"transparent"}
                    key={index2}
                    content={
                      <div className={"container-collapse-module"}>
                        <div className={"container-collapse-module-content"}>
                          <div className={"left-segment-collapse"}>
                            <h2>{panel?.title}</h2>
                            <div
                              style={{
                                margin: "0px",
                                marginTop: "5px",
                                marginBottom: "5px",
                              }}
                            >
                              <div
                                className={"container-descriptionnn"}
                                dangerouslySetInnerHTML={{
                                  __html: panel?.description,
                                }}
                              />
                            </div>
                            <p
                              style={{
                                margin: "0px",
                                marginTop: "5px",
                                marginBottom: "5px",
                              }}
                            >
                              <b>Class Date</b>:{` ${startDate2?.format("LL")}`}
                            </p>
                            <p
                              style={{
                                margin: "0px",
                                marginTop: "5px",
                                marginBottom: "5px",
                              }}
                            >
                              <b>Class Start Time:</b>{" "}
                              {startTime2?.format("HH:mm a")}{" "}
                              {moment.tz.guess()}
                            </p>
                            <p
                              style={{
                                margin: "0px",
                                marginTop: "5px",
                                marginBottom: "5px",
                              }}
                            >
                              <b>Class End Time:</b>{" "}
                              {endTime2?.format("HH:mm a")} {moment.tz.guess()}
                            </p>
                            {enrolled && (
                              <ButtonsCalendar
                                panels={panel}
                                slug={courseSelected?.slug}
                              />
                            )}
                          </div>
                          <div className={"right-segment-collapse"}>
                            {panel?.intructors.map((ins, index) => {
                              return (
                                <div
                                  className={"module-card-user-collapse"}
                                  key={index}
                                >
                                  <Avatar src={ins?.img} size={120}></Avatar>
                                  <Divider
                                    className={"module-divider-collapse"}
                                  />
                                  <h3
                                    className={"module-name-collapse"}
                                  >{`${ins?.firstName} ${ins?.lastName}`}</h3>
                                  <p style={{ fontSize: "15px" }}>
                                    {ins?.titleProfessions}
                                  </p>
                                  <a
                                    href={ins?.linkedin || "#"}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      className={"module-icon-linkedin"}
                                      src={iconDown}
                                      alt="linkedin"
                                    />
                                  </a>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    }
                  />
                );
              })}
            </div>
          </div>
        }
      />
    );
  });

  const instructors = courseSelected?.CourseClasses?.reduce(
    (acc, currClass) => {
      const { CertificateClassInstructors, ...rest } = currClass;

      currClass.CertificateClassInstructors.forEach((instructor) => {
        const existingInstructor = acc.find(
          (inst) => +inst.instructor.UserId === +instructor.UserId
        );

        if (existingInstructor) {
          existingInstructor.classes.push(rest);
        } else {
          acc.push({
            instructor,
            classes: [rest],
          });
        }
      });

      return acc;
    },
    []
  );

  const instructorContainer = (instructors || []).map((item, index) => {
    const { instructor, classes } = item;

    // const sortedClasses = classes.sort((a, b) => moment.utc(a.startTime) - moment.utc(b.startTime))

    return (
      <CardUser
        instructor={{
          ...instructor,
          classes: classes,
        }}
        key={index}
      />
    );
  });

  const cohortContainer = certificateUsersInfo?.certificateUsers?.map(
    (e, index) => {
      return <CardUser instructor={e} key={index} btnClass={false} />;
    }
  );

  const TabData = [
    {
      title: "Description",
      content: () => (
        <div className="container-content-tabs">
          <div
            className="description-camp"
            style={{ margin: "0px" }}
            dangerouslySetInnerHTML={{
              __html: courseSelected?.descriptionCertificate,
            }}
          ></div>
          {classesContainer}
        </div>
      ),
    },
    {
      title: "Schedule",
      content: () => (
        <div className="container-content-tabs">{scheduleContainer}</div>
      ),
    },
    {
      title: "Faculty",
      content: () => (
        <div className="container-content-tabs-intructors">
          {instructorContainer}
        </div>
      ),
    },
    enrolled && {
      title: "Cohort Participants",
      content: () => (
        <div className="container-content-tabs-cohort-participants">
          {cohortContainer}
        </div>
      ),
    },
  ].filter(Boolean);

  const btnStyle = {
    active: {
      padding: "10px 25px",
      height: "auto",
      fontWeight: "400",
      background: "#00b574",
      borderColor: "#00b574",
      color: "#fff",
      maxWidth: "230px",
    },
    withdraw: {
      padding: "10px 25px",
      height: "auto",
      fontWeight: "400",
      background: "#EA6434",
      borderColor: "#EA6434",
      color: "#fff",
      maxWidth: "230px",
    },
    pending: {
      padding: "10px 25px",
      height: "auto",
      fontWeight: "400",
      background: "#e61e47",
      borderColor: "#e61e47",
      color: "#fff",
      maxWidth: "300px",
    },
    open: {
      padding: "10px 25px",
      height: "auto",
      fontWeight: "400",
      background: "#e61e47",
      borderColor: "#e61e47",
      color: "#fff",
      width: "230px",
    },
    enrollNow: {
      padding: "10px 25px",
      height: "auto",
      fontWeight: "400",
      background: "transparent",
      borderColor: "#EA6434",
      color: "#fff",
      maxWidth: "230px",
    },
  };

  const certificateApplyEnrollment = async (e) => {
    if (courseSelected?.open) {
      setShowEnrollmentModal(false);
      return;
    }

    if (userProfile.memberShip !== "premium") {
      return notification.error({
        message: "You must be a premium user.",
      });
    }

    try {
      setLoading(true);
      setShowEnrollmentModal(false);
      await certificateApplyEnrollmentApi({
        certificateId: courseSelected?.id,
      });

      notification.info({
        message: "Success",
      });

      setBtnStyleKey("pending");
      setBtnText("PENDING APPLICATION");
      setEnrollmentStatus("pending");
      getCourseNew(courseSelected?.id, courseSelected?.slug);
    } catch (error) {
      console.error({ error });
      notification.error({
        message: "Something went wrong.",
      });
    } finally {
      setShowEnrollmentModal(false);
      setLoading(false);
    }
  };

  const enrollmentCondition = async (e) => {
    if (!courseSelected?.open) {
      if (userProfile?.id !== -1) {
        if (userProfile?.memberShip === "premium") {
          setLoading(true);
          try {
            await certificateWithdrawEnrollmentApi({
              certificateId: courseSelected.id,
            });
            notification.success({
              message:
                "You have successfully withdrawn from this Certificate Program.",
            });

            setEnrolled(false);

            setBtnText("ENROLL NOW");
            setBtnStyleKey("enrollNow");
            setEnrollmentStatus(null);

            getCourseNew(courseSelected?.id, courseSelected?.slug);
            setLoading(false);
          } catch (error) {
            console.log(error);
            setLoading(false);
          }
        } else {
          notification.error({
            message: "You must be a premium user.",
          });
        }
      }
    } else {
      closeModal2(true);
    }
  };

  const enrollBtn = (isWhiteBg = false) => {
    const color = !enrollmentStatus ? "#EA6434" : "#fff";

    return courseSelected?.open ? (
      <CustomButton
        text="CLOSED"
        type="primary outline"
        loading={loading}
        style={btnStyle["open"]}
      />
    ) : (
      <>
        {enrollmentStatus === "rejected" ? (
          <CustomButton
            text="Rejected"
            type="primary outline"
            loading={loading}
            style={{
              padding: "10px 25px",
              height: "auto",
              fontWeight: "400",
              background: "#EA6434",
              borderColor: "#EA6434",
              color: "#fff",
              width: "230px",
            }}
          />
        ) : (
          <>
            {btnText && (
              <CustomButton
                text={btnText}
                type="primary outline"
                loading={loading}
                style={{
                  ...btnStyle[btnStyleKey],
                  ...(isWhiteBg
                    ? {
                        color: color,
                      }
                    : {}),
                }}
                onMouseEnter={() => {
                  if (enrollmentStatus === "active") {
                    setBtnText("WITHDRAW");
                    setBtnStyleKey("withdraw");
                  }
                }}
                onMouseLeave={() => {
                  if (enrollmentStatus === "active") {
                    setBtnText("ENROLLED");
                    setBtnStyleKey("active");
                  }
                }}
                onClick={async (e) => {
                  if (
                    !userProfile?.completed ||
                    userProfile?.percentOfCompletion !== 100
                  ) {
                    setBulCompleteProfile(true);
                    return;
                  }

                  if (enrollmentStatus === "pending") return;

                  if (enrollmentStatus === "active") {
                    await enrollmentCondition(e);
                  } else {
                    setShowEnrollmentModal(true);
                  }
                }}
              />
            )}
          </>
        )}
      </>
    );
  };

  return (
    <div className="container-certificates">
      <Modal
        visible={showEnrollmentModal}
        onCancel={() => {
          setShowEnrollmentModal(false);
        }}
        width={600}
        okText="SUBMIT APPLICATION"
        onOk={certificateApplyEnrollment}
      >
        <div
          style={{
            marginTop: "1.5rem",
          }}
        >
          <p>
            Thank you for your interest to enroll in this Certificate Program.
            We are excited you are joining us.
          </p>
          <p
            style={{
              marginTop: "1rem",
            }}
          >
            Please review the full program calendar and information below and
            click on SUBMIT APPLICATION for our team to review. You will receive
            a response within the next 48 hours.
          </p>
          <div
            style={{
              marginTop: "1rem",
            }}
          >
            {orderClassCertificates(courseSelected?.CourseClasses || []).map(
              (certificateClass) => {
                let startTime = convertToLocalTime(
                  certificateClass?.startDate,
                  certificateClass?.timezone
                );
                let endTime = convertToLocalTime(
                  certificateClass?.endDate,
                  certificateClass?.timezone
                );
                return (
                  <div
                    key={certificateClass.id}
                    style={{
                      marginTop: "1rem",
                    }}
                  >
                    <h2>{certificateClass.title}</h2>
                    <p style={{ margin: "0px", marginTop: "3px" }}>
                      <b>Class Date</b>:{` ${startTime?.format("LL")}`}
                    </p>
                    <p
                      // className={Style["title-collapse"]}
                      style={{ margin: "0px", marginTop: "3px" }}
                    >
                      <b>Class Start Time:</b> {startTime?.format("HH:mm a")}{" "}
                      {moment.tz.guess()}
                    </p>
                    <p
                      // className={Style["title-collapse"]}
                      style={{ margin: "0px", marginTop: "3px" }}
                    >
                      <b>Class End Time:</b> {endTime?.format("HH:mm a")}{" "}
                      {moment.tz.guess()}
                    </p>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </Modal>
      <div className="container-firstsegment-certificate">
        <div className="container-left-certificate">
          <div
            className="description-camp"
            dangerouslySetInnerHTML={{ __html: courseSelected?.summary }}
          ></div>
          <div className="container-buttons-certificate">
            {/* <div className="button-enroll-now">ENROLL NOW</div> */}
            {/* <div className="button-donwload-syllabus">DOWNLOAD SYLLABUS</div> */}
            {enrollBtn(true)}
            {courseSelected?.syllabusLink && (
              <a
                href={courseSelected?.syllabusLink || "#"}
                rel="noopener noreferrer"
                target="_blank"
              >
                <CustomButton
                  text="VIEW SYLLABUS"
                  type="primary-retart"
                  style={{
                    // padding: "10px 25px", height: "auto", fontWeight: "400", width: "230px"
                    padding: "10px 25px",
                    height: "auto",
                    borderRadius: "10px",
                    color: "white",
                    width: "230px",
                  }}
                />
              </a>
            )}
            <CustomButton
              text="View: Program Guidelines"
              type="tertiary"
              style={{
                // padding: "10px 25px", height: "auto", fontWeight: "400", width: "230px"
                padding: "10px 25px",
                background: "#d1d5db",
                height: "auto",
                borderRadius: "10px",
                color: "#374151",
              }}
              onClick={() => {
                window.open(
                  courseSelected.certificateProgramGuidelinesLink,
                  "_blank"
                );
              }}
            />
          </div>
          <CollapseCertificate
            title="What You Will Learn"
            widthTitle="360px"
            content={
              <div className="container-will-learn-collapse">
                {(courseSelected?.youWillLearn?.length > 0
                  ? courseSelected?.youWillLearn
                  : []
                )?.map((e, index) => {
                  return (
                    <div key={index} className="container-will-learn-collapse">
                      <p>{e}</p>
                    </div>
                  );
                })}
              </div>
            }
          />
          {/* <CollapseCertificate
          title='Requirements'
          widthTitle='310px'
          content={
            <div className="container-will-requirements-collapse">
              {(courseSelected?.requirements?.length > 0 ? courseSelected?.requirements : [])?.map(e => {
                return (<div className="container-will-requirements-collapse"><div></div><p>{e}</p></div>)
              })}
            </div>
          }
        /> */}
          <CollapseCertificate
            title="Certificate Program Content"
            widthTitle="480px"
            paddingB={true}
            content={
              <div className="container-tabs-certificates">
                <Tabs
                  data={TabData}
                  current={currentTab}
                  onChange={(e) => setCurrentTab(e)}
                />
              </div>
            }
          />
        </div>
        <div className="container-right-certificatep">
          <div className="container-banner-logo2" style={{maxWidth:'350px'}}></div>
          <div className="container-card-certificate2" style={{maxWidth:'350px'}}>
            <div className="container-image">
              <img src={courseSelected?.image}></img>
            </div>
            <div className="container-content">
              <div className="container-align-content">
                {(courseSelected?.features?.length > 0
                  ? courseSelected?.features
                  : []
                )?.map((e, index) => {
                  return (
                    <div key={index} className="container-will-learn">
                      <div></div>
                      <p>{e}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {courseSelected?.hrCreditsBoolean === "Yes" && (
            <div
              className="container-card-certificate2"
              style={{ padding: "25px", marginTop: "20px",marginBottom:"70px",maxWidth:'350px' }}
            >
              <h2 style={{ marginBottom: "10px", paddingBottom: "10px" }}>
                HR Certification Credits Offered
              </h2>
              <p
                style={{
                  padding: "0px",
                  paddingBottom: "20px",
                  margin: "0px",
                  fontSize: "14px",
                  lineHeight: "16px",
                }}
              >
                Hacking HR is recognized as an approved training provider with
                the <b>Society for Human Resource Management</b> (SHRM) and the{" "}
                <b>HR Certification Institute</b> (HRCI). Members of these
                organizations are eligible to receive{" "}
                <b>personal development credits</b> (PDCs) upon successful
                completion of this Certificate Program. The codes to claim the
                SHRM and HRCI credits will be offered ONLY at the end of the
                program, once all the requirements have been fully met.
              </p>
              <p
                style={{
                  padding: "0px",
                  margin: "0px",
                  fontSize: "14px",
                  lineHeight: "16px",
                }}
              >
                <b>This Certificate Program offers:</b>{" "}
                {courseSelected?.shrmcertificates} SHRM +{" "}
                {courseSelected?.hrcicertificates} HRCI {(Number(courseSelected?.ihrimcertificates || 0) > 0) 
                  ? (`+ ${courseSelected?.ihrimcertificates} IHRIM`)
                  : ''
                }
              </p>
              <div
                style={{
                  width: "100%",
                  height: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "20px",
                  flexDirection: 'row',
                  flexWrap:'wrap'
                }}
              >
                <div className="container-logo-hr-hr"></div>
                {(Number(courseSelected?.ihrimcertificates || 0) > 0) && <div className="container-logo-hr-hr-e2"></div>}
              </div>
            </div>
          )}
        </div>
        <Modal
          className="modal-login"
          width={"480px"}
          footer={null}
          style={{ height: "auto", top: "50px" }}
          visible={openModal2}
          onCancel={() => closeModal2(false)}
        >
          <div className="login-page-2">
            <div
              className="login-dialog"
              style={{ paddingTop: "40px", paddingBottom: "40px" }}
            >
              <div className="login-dialog-header" style={{ padding: "10px" }}>
                <h3 style={{ textAlign: "center" }}>
                  Enrollment for this Certificate Program has closed
                </h3>
                <div
                  className="login-dialog-logo"
                  style={{ borderRadius: "50%", background: "white" }}
                >
                  <img src={IconLogo} alt="login-logo" />
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {bulCompleteProfile && (
          <div className="complete-profile">
            <ModalCompleteYourProfile
              userProfile={userProfile}
              get={getUser}
              setBulKnowRegister={(data) => {
                console.log("aaa", data);
              }}
              setBulCompleteProfile={setBulCompleteProfile}
              onClose={() => setBulCompleteProfile(false)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  courseSelected: courseSelector(state).courseSelected,
  userProfile: homeSelector(state).userProfile,
  certificateUsersInfo: courseSelector(state).certificateUsersInfo,
});

const mapDispatchToProps = {
  ...courseActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(CertificatesPage);
