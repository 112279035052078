import React from "react";
import INTERNAL_LINKS from "./InternalLinks";

// import IconHome from "images/icon-home.svg";
import IconLibrary from "images/icon-library.js";
import IconMicrophone from "images/microphone.js";
// import IconMentoring from "images/icon-mentoring.svg";
import IconCalendar from "images/icon-calendar.js";
// import IconMedal from "images/icon-medal.svg";
import IconReader from "images/icon-reader.js";
import IconStar from "images/icon-star.js";
// import IconJourney from "images/icon-learning-journey.svg";
import IconBookmark from "images/icon-bookmark.js";
import IconHeadsetOutline from "images/icon-headset-outline.js";
// import IconStoreFrontOutline from "images/icon-storefront-outline.svg";
import IconConferenceVideo from "images/icon-sidebar-video.js";
import IconTvOutline from "images/icon-tv-outline.js";
// import IconGlobal from "images/icon-global.svg";
import IconFlask from "images/icon-flask-outline.js";
import IconHRBusiness from "images/HRBPs.js";
import IconExpertsCouncil from "images/senior.js";
// import IconBriefcase from "images/icon-briefcase-outline.svg";
// import IconGlobal from "images/icon-global.svg";
// import iconBonfire from "images/icon-bonfire.svg";
import GradIcon from "images/grad.js";
import MentorinIcon from "images/MentoringNew.js";
import CertificateIcon from "images/certificatesIcon.js";
import CoheteIcon from "images/cohete.js";
// import iconSchool from "images/icon-school.svg";

export default {
  TOP_MENUS: [
      {
        label: "Home",
        icon: "",
        //IconHome
        case: "sub",
        url: INTERNAL_LINKS.HOME,
      },
      {
        label: "Events",
        icon: "",
        //IconCalendar
        case: "sub",
        url: INTERNAL_LINKS.EVENTS,
      },
      {
        label: "Learning Content",
        icon: "",
        case: "sub",
        url: "#",
        height: "308px",
        sons: [
          {
            label: "Certificates",
            icon: (color) => (<CertificateIcon color={color} />),
            case: "normal",
            url: INTERNAL_LINKS.CERTIFICATES,
          },
          {
            label: "My Learning",
            icon: (color) => (<GradIcon color={color} />),
            case: "normal",
            url: INTERNAL_LINKS.MY_LEARNINGS,
          },
          {
            label: "Conference Library",
            icon: (color) => (<IconConferenceVideo color={color} />),
            case: "normal",
            url: INTERNAL_LINKS.CONFERENCE_LIBRARY,
          },
          {
            label: "Live Chats",
            icon: (color) => (<IconMicrophone color={color} />),
            case: "normal",
            url: INTERNAL_LINKS.LIVE_CHAT,
          },
          {
            label: "Learning Library",
            icon: (color) => (<IconLibrary color={color} />),
            case: "normal",
            url: INTERNAL_LINKS.LEARNING_LIBRARY,
          },
          {
            label: "Blogs",
            icon: (color) => (<IconReader color={color} />),
            case: "normal",
            url: INTERNAL_LINKS.BLOGS,
          },
          {
            label: "ProjectX",
            icon: (color) => (<IconFlask color={color} />),
            case: "normal",
            url: INTERNAL_LINKS.PROJECTX,
          },
          {
            label: "Podcast",
            icon: (color) => (<IconHeadsetOutline color={color} />),
            case: "normal",
            url: INTERNAL_LINKS.PODCAST,
          },
        ],
      },
      {
        label: "Community",
        icon: "",
        case: "sub",
        url: "#",
        height: "215px",
        sons: [
          {
            label: "Channels",
            icon: (color) => (<IconTvOutline color={color} />),
            case: "normal",
            url: INTERNAL_LINKS.CHANNELS,
          },
          {
            label: "GrowGroups",
            icon: (color) => (<IconCalendar color={color} />),
            case: "normal",
            url: INTERNAL_LINKS.GROUPGROWS,
          },
          {
            label: "Mentoring (Beta Version)",
            icon: (color) => (<MentorinIcon color={color} />),
            case: "normal",
            url: INTERNAL_LINKS.MENTORING,
          },
          {
            label: "HRBPs Council",
            icon: (color) => (<IconHRBusiness color={color} />),
            case: "normal",
            url: INTERNAL_LINKS.BUSINESS_PARTNER,
          },
          {
            label: "Senior Leaders Council",
            icon: (color) => (<IconExpertsCouncil color={color} />),
            case: "normal",
            url: INTERNAL_LINKS.COUNCIL,
          },
        ],
      },
  ],
  TOP_MENUS_FUNCTION: (certificates,_,userProfile) => {
    return [
      {
        label: "Home",
        icon: "",
        //IconHome
        case: "sub",
        url: INTERNAL_LINKS.HOME,
      },
      {
        label: "Events",
        icon: "",
        //IconCalendar
        case: "sub",
        url: INTERNAL_LINKS.EVENTS,
      },
      {
        label: "Learning Content",
        icon: "",
        case: "sub",
        url: "#",
        height: 235,
        sons: [
          {
            label: "Certificates",
            icon: (color) => (<CertificateIcon color={color} />),
            case: "normal",
            url: INTERNAL_LINKS.CERTIFICATES,
            // height: certificates.length * 45,
            // sons: certificates.map(e => {
            //   return {
            //     label: e.title,
            //     icon: () => (<div></div>),
            //     case: "normal",
            //     url: `${INTERNAL_LINKS.CERTIFICATES}/${e.slug}`,
            //   }
            // })
          },
          {
            label: "Crash Courses",
            icon: (color) => (<CoheteIcon color={color} />),
            case: "normal",
            url:  INTERNAL_LINKS.LEADERSHIP_PROGRAMS,
            noredirect: ((userProfile || {}).email !== "enrique@hackinghr.io" 
              && (userProfile || {}).email !== "morecontrol64@gmail.com"
              && (userProfile || {}).email !== "jessa@hackinghr.io"
              && (userProfile || {}).email !== "danielle@hackinghr.io"
              && (userProfile || {}).email !== "brizia.ceja@gmail.com"
            )
          },
          {
            label: "My Learning",
            icon: (color) => (<GradIcon color={color} />),
            case: "normal",
            url: INTERNAL_LINKS.MY_LEARNINGS,
          },
          {
            label: "Conference Library",
            icon: (color) => (<IconConferenceVideo color={color} />),
            case: "normal",
            url: INTERNAL_LINKS.CONFERENCE_LIBRARY,
          },
          {
            label: "Live Chats",
            icon: (color) => (<IconMicrophone color={color} />),
            case: "normal",
            url: INTERNAL_LINKS.LIVE_CHAT,
          },
          {
            label: "Learning Library",
            icon: (color) => (<IconLibrary color={color} />),
            case: "normal",
            url: INTERNAL_LINKS.LEARNING_LIBRARY,
          },
          {
            label: "Blogs",
            icon: (color) => (<IconReader color={color} />),
            case: "normal",
            url: INTERNAL_LINKS.BLOGS,
          },
          // {
          //   label: "ProjectX",
          //   icon: (color) => (<IconFlask color={color} />),
          //   case: "normal",
          //   url: INTERNAL_LINKS.PROJECTX,
          // },
          // {
          //   label: "Podcast",
          //   icon: (color) => (<IconHeadsetOutline color={color} />),
          //   case: "normal",
          //   url: INTERNAL_LINKS.PODCAST,
          // },
        ],
      },
      {
        label: "Community",
        icon: "",
        case: "sub",
        url: "#",
        height: 215,
        sons: [
          {
            label: "Channels",
            icon: (color) => (<IconTvOutline color={color} />),
            case: "normal",
            url: INTERNAL_LINKS.CHANNELS,
          },
          {
            label: "GrowGroups",
            icon: (color) => (<IconCalendar color={color} />),
            case: "normal",
            url: INTERNAL_LINKS.GROUPGROWS,
          },
          {
            label: "Mentoring (Beta Version)",
            icon: (color) => (<MentorinIcon color={color} />),
            case: "normal",
            url: INTERNAL_LINKS.MENTORING,
          },
          {
            label: "HRBPs Council",
            icon: (color) => (<IconHRBusiness color={color} />),
            case: "normal",
            url: INTERNAL_LINKS.BUSINESS_PARTNER,
          },
          {
            label: "Senior Leaders Council",
            icon: (color) => (<IconExpertsCouncil color={color} />),
            case: "normal",
            url: INTERNAL_LINKS.COUNCIL,
          },
        ],
      },
    ]
  },
  BOTTOM_MENUS: [
    {
      label: "Favorites",
      icon: IconStar,
      case: "normal",
      url: INTERNAL_LINKS.FAVORITES,
    },
    {
      label: "Read later",
      icon: (color) => <IconBookmark color={color} />,
      case: "normal",
      url: INTERNAL_LINKS.READ_LATER,
    },
  ],
};

// {
//   label: "Talent Marketplace",
//   icon: IconBriefcase,
//   url: INTERNAL_LINKS.TALENT_MARKETPLACE,
// },
// {
//   label: "Global Conference",
//   icon: IconGlobal,
//   url: INTERNAL_LINKS.GLOBAL_CONFERENCE,
// },
// {
//   label: "Simulation Sprints",
//   icon: iconSchool,
//   url: INTERNAL_LINKS.SIMULATION_SPRINTS,
// },
// {
//   label: "Bonfires",
//   icon: iconBonfire,
//   url: INTERNAL_LINKS.BONFIRES,
// },
// {
//   label: "Classes",
//   icon: IconMedal,
//   url: INTERNAL_LINKS.CLASSES,
// },
// {
//   label: "Learning Journeys",
//   icon: IconJourney,
//   url: INTERNAL_LINKS.JOURNEY,
// },
// {
//   label: "HR Marketplace",
//   icon: IconStoreFrontOutline,
//   url: INTERNAL_LINKS.MARKETPLACE,
// },
