import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Avatar,  Modal } from "antd";
import moment from "moment";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { setLoading } from "redux/actions/home-actions";
import {
  setFollowChannel,
  unsetFollowChannel,
} from "redux/actions/channel-actions";
import {
  setBlogPostLike,
  deleteBlogPostLike,
} from "redux/actions/blog-post-action";
import { homeSelector } from "redux/selectors/homeSelector";
import { CustomButton } from "components";
import { getBlogPost, getChannel } from "api";
import { transformNames } from "utils/format";
import { INTERNAL_LINKS } from "enum";
import Login from "../Login2";

import IconBack from "images/icon-back.svg";
import IconLogo from "images/logo-sidebar.svg";
import { ReactComponent as IconHeartOutline } from "images/icon-heart-outline.svg";
import "./style.scss";

const Blog = ({
  setLoading,
  userProfile,
  setFollowChannel,
  unsetFollowChannel,
  setBlogPostLike,
  deleteBlogPostLike,
}) => {
  const history = useHistory();
  const { id } = useParams();
  const location = useLocation();
  const [blog, setBlog] = useState({});
  const [channel, setChannel] = useState({});
  const [bulModal, setBulModal] = useState(false)
  const [blogPreviuw, setBlogPreviuw] = useState({})
  const [redirect, setRedirect] = useState(false)
  let idProd = [205,30974,209,40826,41804]

  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("previuw"))
    setBlogPreviuw(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (redirect) {
      setRedirect(false)
      history.push(`${INTERNAL_LINKS.BLOGS}/${id}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirect])

  useEffect(() => {
    const getBlog = async () => {
      setLoading(true);
      if (isNaN(Number(id))) {
        return history.push("/blogs");
      }
      const { data, status } = await getBlogPost(id);

      if (status === 200) {
        return setBlog(data?.blogPost);
      }

      return history.push("/blogs");
    };

    getBlog();
    setLoading(false);
  }, [id, history, setLoading]);

  useEffect(() => {
    const getChannelApi = async () => {
      const { data, status } = await getChannel({ id: blog?.ChannelId });

      if (status === 200) {
        return setChannel(data?.channel);
      }
    };

    if (blog?.id && !channel?.id) {
      getChannelApi();
    }
  }, [blog, channel]);

  const followChannel = () => {
    if(localStorage.getItem("community") !== null){
      if (channel?.followedUsers?.includes(userProfile?.id)) {
        unsetFollowChannel(channel, () => {
          setChannel({
            ...channel,
            followedUsers: channel?.followedUsers?.filter(
              (userId) => userId !== userProfile?.id
            ),
          });
        });
      } else {
        setFollowChannel(channel, () => {
          setChannel({
            ...channel,
            followedUsers: [...channel.followedUsers, userProfile?.id],
          });
        });
      }
    }else{
      setBulModal(true)
    }
  };

  const handleLike = () => {
    if(localStorage.getItem("community") !== null){
      if (!blog?.BlogPostLikes.some((like) => like.UserId === userProfile?.id)) {
        setBlogPostLike(
          {
            BlogPostId: blog?.id,
            blogPostOwnerUserId: blog?.UserId,
          },
          (like) => {
            setBlog({
              ...blog,
              BlogPostLikes: [...blog?.BlogPostLikes, like],
            });
          }
        );
      } else {
        deleteBlogPostLike(blog?.id, () => {
          setBlog({
            ...blog,
            BlogPostLikes: blog?.BlogPostLikes.filter(
              (likes) => likes?.UserId !== userProfile?.id
            ),
          });
        });
      }
    }else{
      setBulModal(true)
    }
  };

  const avatarCase = () => {
    if (!blogPreviuw?.title) {
      return (<Avatar size={40}>DP</Avatar>)
    } else {
      return (<Avatar size={40} src={userProfile?.img} />)
    }
  }

  if (!blog?.id && !blogPreviuw?.title) {
    return <></>;
  }

  if (blog?.status === "draft") {
    history.goBack();
    return <></>;
  }
  return (
    <div className="blog-page">
      <div
        onClick={() => {
          if(localStorage.getItem("returnBlogging") === 'true'){
            localStorage.setItem("returnBlogging", 'false')
            history.push("/blog-user");
            return
          }
          if (location.pathname.includes("channels")) {
            return history.replace({
              pathname: location.pathname.slice(0, 11),
              search: `?tab=blogs`,
            });
          }
          if(localStorage.getItem("community") !== null){
            history.push("/blogs");
          }else{
            history.push("/blog");
          }
          
        }}
      >
        <div className="blog-page-link">
          <div className="blog-page-link-back">
            <img src={IconBack} alt="icon-back" />
          </div>
          <h4>Back to Blogs</h4>
        </div>
      </div>
      <div className="blog-page-container">
        <div className="blog-page-header">
          <div className="blog-page-title">
            <h1>{!blog?.id ? blogPreviuw?.title : blog?.title}</h1>
          </div>

          <div className="blog-page-date">
            {moment(!blog?.id ? moment() : blog?.createdAt).format("MMMM DD, YYYY")}
          </div>

          {(blog?.imageUrl || blogPreviuw?.imageUrl) && (
            <div className="blog-page-image">
              <img src={!blog?.id ? blogPreviuw?.imageUrl : blog?.imageUrl} alt={"cover"} />
            </div>
          )}

          <div className="blog-page-info">
            <div className="blog-page-author">
              {!idProd.includes(blog?.User?.id) ? (<>{(blog?.User?.img) ? (
                <Avatar size={40} src={(blog?.User?.img)} />
              ) : (
                avatarCase()
              )}</>) : (
                <img width={40} height={40} src={IconLogo} alt="user-avatar" style={{objectFit: 'cover',borderRadius: '50%'}}/>
              )}
              <div>
                <span className="author-name">{!idProd.includes(blog?.User?.id) ? (<>{transformNames(!blog?.id ? userProfile?.firstName : blog?.User?.firstName)}{" "}
                  {transformNames(!blog?.id ? userProfile?.lastName : blog?.User?.lastName)}</>) : (<>Hacking HR Team</>)}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="blog-page-content">
          <div dangerouslySetInnerHTML={{ __html: !blog?.id ? blogPreviuw?.description?.html : blog?.description?.html }} />
        </div>

        <div className="likes-container">
          <div className="likes">
            <IconHeartOutline />
          </div>

          <span>{!blog?.id ? 0 : blog?.BlogPostLikes?.length}</span>
        </div>

        <div className="btn-like-container">
          <div>
            {blog?.id && <span onClick={() => handleLike()} className="btn-like">
              <IconHeartOutline
                className={`icon-like ${blog?.BlogPostLikes?.some(
                  (like) => like.UserId === userProfile?.id
                ) && "svg-fill-color"
                  } `}
              />
              Like
            </span>}

            
            {(blog?.id && blog?.ChannelId !== 84) && <CustomButton
              htmlType="button"
              text={
                channel?.followedUsers?.includes(userProfile?.id)
                  ? "Unfollow Channel"
                  : "Follow Channel"
              }
              type={
                channel?.followedUsers?.includes(userProfile?.id)
                  ? "secondary"
                  : "primary"
              }
              size="sm"
              loading={false}
              onClick={followChannel}
              style={{ marginLeft: "10px" }}
            />}
          </div>
          {/* <div className="blog-page-categories">
            {blog.categories.map((category) => (
              <SpecialtyItem key={category} title={category} active={false} />
            ))}
          </div> */}
          <Modal
            visible={bulModal}
            footer={null}
            width={400}
            bodyStyle={{ overflow: "auto", padding: "20px" }}
            className="modal-container-login"
            onCancel={() => setBulModal(false)}
          >
            <Login
              login={true}
              signup={false}
              type={'ladingPague'}
              history={null}
              confirm={() => {
                setTimeout(() => {
                  setRedirect(true)
                }, 100);
              }}
              match={{ params: {} }}
              modal={setBulModal}
              onClose={() => setBulModal(false)}
            />
          </Modal>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userProfile: homeSelector(state).userProfile,
});

const mapDispatchToProps = {
  setLoading,
  setFollowChannel,
  unsetFollowChannel,
  setBlogPostLike,
  deleteBlogPostLike,
};

export default connect(mapStateToProps, mapDispatchToProps)(Blog);
