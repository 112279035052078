import { createAction } from "redux-actions";

const GET_CHALLENGE = "GET_CHALLENGE"
const GET_CHALLENGE_ONE = "GET_CHALLENGE_ONE"
const SET_CHALLENGE = "SET_CHALLENGE"
const SET_CHALLENGE_ONE = "SET_CHALLENGE_ONE"
const JOIN_OR_LEAVE_CHALLENGE = "JOIN_OR_LEAVE_CHALLENGE"
const SEND_INVITATION = "SEND_INVITATION"
const SET_STATUS_CHALLENGE = "SET_STATUS_CHALLENGE"
const GET_STATUS_CHALLENGE = "GET_STATUS_CHALLENGE"
const SETT_STATUS_CHALLENGE = "SETT_STATUS_CHALLENGE"
const GET_ALL_COMMENTS_CHALLENGE = "GET_ALL_COMMENTS_CHALLENGE";
const ADD_COMMENT_CHALLENGE = "ADD_COMMENT_CHALLENGE";
const DELETE_COMMENT_CHALLENGE = "DELETE_COMMENT_CHALLENGE";
const SET_ALL_COMMENTS_CHALLENGE = "SET_ALL_COMMENTS_CHALLENGE";

export const constants = {
    GET_CHALLENGE,
    GET_CHALLENGE_ONE,
    SET_CHALLENGE,
    SET_CHALLENGE_ONE,
    JOIN_OR_LEAVE_CHALLENGE,
    SEND_INVITATION,
    SET_STATUS_CHALLENGE,
    GET_STATUS_CHALLENGE,
    SETT_STATUS_CHALLENGE,
    GET_ALL_COMMENTS_CHALLENGE,
    ADD_COMMENT_CHALLENGE,
    DELETE_COMMENT_CHALLENGE,
    SET_ALL_COMMENTS_CHALLENGE
};

// ------------------------------------
// Actions
// ------------------------------------
export const getChallenge = createAction(GET_CHALLENGE, (callback) => ({
    callback
}))

export const getOneChallenge = createAction(GET_CHALLENGE_ONE, (id, callback) => ({
    id, callback
}))

export const setChallenge = createAction(SET_CHALLENGE, (challenge) => ({
    challenge
}))

export const setChallengeOne = createAction(SET_CHALLENGE_ONE, (challenge) => ({
    challenge
}))

export const joinOrLeaveChallenge = createAction(JOIN_OR_LEAVE_CHALLENGE, (id, callback) => ({
    id, callback
}))

export const sendInvitation = createAction(SEND_INVITATION, (emails, id, callback) => ({
    emails, id, callback
}))

export const setStatusChallenge = createAction(
    SET_STATUS_CHALLENGE,
    (data, callback) => ({ data, callback })
)

export const getStatusChallenge = createAction(
    GET_STATUS_CHALLENGE,
    (data, callback) => ({ data, callback })
)

export const settStatusChallenge = createAction(
    SETT_STATUS_CHALLENGE,
    (activitiesStatus) => ({ activitiesStatus })
)

export const getAllCommentsChallenge = createAction(GET_ALL_COMMENTS_CHALLENGE, (filter) => ({
    filter,
}));

export const addCommentChallenge = createAction(ADD_COMMENT_CHALLENGE, (comment) => ({ comment }));

export const deleteCommentChallenge = createAction(DELETE_COMMENT_CHALLENGE, (comment) => ({
    comment,
}));

export const setAllCommentsChallenge = createAction(
    SET_ALL_COMMENTS_CHALLENGE,
    (comments) => ({
        comments,
    })
);

export const actions = {
    getChallenge,
    getOneChallenge,
    setChallenge,
    setChallengeOne,
    joinOrLeaveChallenge,
    sendInvitation,
    setStatusChallenge,
    getStatusChallenge,
    settStatusChallenge,
    getAllCommentsChallenge,
    addCommentChallenge,
    deleteCommentChallenge,
    setAllCommentsChallenge
};
