import httpClient from "./httpClient";

export const getAnnualConferenceAllClasses = () => {
  return httpClient.get(`admin/conference-classes`);
};

export const getAnnualConferenceClasses = (annualConferenceId, type) => {
  return httpClient.get(`private/conference-classes/${annualConferenceId}`,{params:{
    type:type
  }});
};

export const getAnnualConferenceClass = ({ id }) => {
  return httpClient.get(`private/conference-class/${id}`);
};

export const addAnnualConferenceClass = ({ annualConferenceClass }) => {
  return httpClient.post(`admin/conference-classes`, annualConferenceClass);
};

export const updateAnnualConferenceClass = ({ annualConferenceClass }) => {
  return httpClient.put(
    `admin/conference-classes/${annualConferenceClass.id}`,
    annualConferenceClass
  );
};

export const deleteAnnualConferenceClass = ({ annualConferenceClass }) => {
  return httpClient.delete(
    `admin/conference-classes/${annualConferenceClass.id}`,{params:{
      type: annualConferenceClass.type
    }}
  );
};
