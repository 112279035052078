import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Tabs } from "components";

import "./style.scss";

import FindMentors from "./FindMentors";
import MenteeProfile from "./MenteeProfile";
import RequestFromMentors from "./RequestFromMentors";
import YourCurrentMentors from "./YourCurrentMentors";
import PopupPremiumUser from "components/Popups/PopupPremiumUser";

const Mentee = ({
  userProfile,
  mentorProfile,
  menteeProfile,
  createMentee,
  updateMente,
  getOneMentee,
  getOneMentor,
  getUser,
  mentorListFind,
  menteeListFind,
  createRequest,
  updateRequest,
  requestListMentor,
  requestListMentee,
  notificationsCount,
  notifications,
  markNotificationToRead,
  getNotifications,
  subCurrentTab
}) => {
  const [currentTab, setCurrentTab] = useState("0");
  const [showMessage, setShowMessage] = useState(false);
  const location = useLocation();
  const history = useHistory();

  useEffect(()=>{
    if(subCurrentTab !== null && location.search === '?key=mentee-notification'){
      setCurrentTab(subCurrentTab)

      setTimeout(()=>{
        const newSearch = location.search.replace('?key=mentee-notification', '');
        history.replace({ search: newSearch });
      },1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[subCurrentTab, location])

  const TabData = [
    {
      title: "Mentee Profile",
      key: 1,
      content: () => (
        <MenteeProfile
          userProfile={userProfile}
          mentorProfile={mentorProfile}
          menteeProfile={menteeProfile}
          updateMente={updateMente}
          createMentee={createMentee}
          getOneMentee={getOneMentee}
          getUser={getUser}
        />
      ),
    },
    menteeProfile?.status === "active" && {
      title: (
        <span
          style={{ padding: "17px 0" }}
          onClick={() =>
            menteeProfile?.topics?.length < 1 ? setShowMessage(true) : null
          }
        >
          Find Mentors
        </span>
      ),
      key: 2,
      content: () => (
        <FindMentors
          userProfile={userProfile}
          mentorProfile={mentorProfile}
          menteeProfile={menteeProfile}
          getOneMentee={getOneMentee}
          getOneMentor={getOneMentor}
          getUser={getUser}
          mentorListFind={mentorListFind}
          menteeListFind={menteeListFind}
          createRequest={createRequest}
          updateRequest={updateRequest}
        />
      ),
      disabled: menteeProfile?.topics?.length < 1,
    },
    menteeProfile?.status === "active" && {
      title: (
        <span
          style={{ padding: "17px 0" }}
          onClick={() =>
            menteeProfile?.topics?.length < 1 ? setShowMessage(true) : null
          }
        >
          Request from Mentors
        </span>
      ),
      key: 3,
      notificationsCount: notificationsCount,
      content: () => (
        <RequestFromMentors
          mentorProfile={mentorProfile}
          menteeProfile={menteeProfile}
          updateRequest={updateRequest}
          userProfile={userProfile}
          getOneMentee={getOneMentee}
          requestListMentor={requestListMentor}
          requestListMentee={requestListMentee}
          getUser={getUser}
          notifications={notifications}
          markNotificationToRead={markNotificationToRead}
          getNotifications={getNotifications}
        />
      ),
      disabled: menteeProfile?.topics?.length < 1,
    },
    menteeProfile?.status === "active" && {
      title: (
        <span
          style={{ padding: "17px 0" }}
          onClick={() =>
            menteeProfile?.topics?.length < 1 ? setShowMessage(true) : null
          }
        >
          Your Current Mentors
        </span>
      ),
      key: 4,
      content: () => (
        <YourCurrentMentors
          mentorProfile={mentorProfile}
          menteeProfile={menteeProfile}
          updateRequest={updateRequest}
          userProfile={userProfile}
          getOneMentee={getOneMentee}
          requestListMentor={requestListMentor}
          requestListMentee={requestListMentee}
          getUser={getUser}
        />
      ),
      disabled: menteeProfile?.topics?.length < 1,
    },
  ].filter(Boolean);

  return (
    <>
      <Tabs data={TabData} current={currentTab} onChange={setCurrentTab} />
      {showMessage && (
        <PopupPremiumUser
          onclick={() => setShowMessage(false)}
          text="You must complete your profile as Mentee"
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Mentee);
