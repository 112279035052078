import httpClient from "./httpClient";

export const getEditorSignature = () => {
    return httpClient.get("public/env/editor");
};

export const addEventVerificationEndPoint = (payload) => {
    return httpClient.post("private/verify-code-event-add", { ...payload })
}
export const getEventVerificationEndPoint = () => {
    return httpClient.get("private/verify-code-event-get")
}