import httpClient from "./httpClient";

export const getAllCrashCoursesEndpoint = () => {
  return httpClient.get(`private/crash-course`);
};

export const getCrashCourseBySlugEndpoint = ({ slug }) => {
  return httpClient.get(`private/crash-course-by-slug/?slug=${slug}`, { slug });
};

export const getCrashCourseEndpoint = ({ id }) => {
  return httpClient.get(`private/crash-course/${id}`);
};

export const getCrashCourseSlidesEndpoint = ({ crashCourseId }) => {
  return httpClient.get(`private/crash-course-slides/${crashCourseId}`);
};

export const getCrashCourseProgressEndpoint = ({ crashCourseId }) => {
  return httpClient.get(`private/crash-course-progress/${crashCourseId}`);
};

export const createCrashCourseProgressEndpoint = ({data}) => {
  return httpClient.post(`private/crash-course-progress/`, data);
};

export const updateCrashCourseProgressEndpoint = ({data}) => {
  return httpClient.put(`private/update-crash-course-progress/`, data);
};
