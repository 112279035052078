import React, { useEffect, useState } from "react";
import ProfileViewPanel from "containers/ProfileDrawer/ProfileViewPanel";
import { getOneUserById } from "api";
import { useParams } from 'react-router-dom';

import "./style.scss";

const ProfilePage = () => {
    const { id } = useParams();
    console.log(id)

    const [userProfileToShow, setUserProfileToShow] = useState({});

    const getOneUser = async (id) => {
        const response = await getOneUserById(id);
        setUserProfileToShow(response?.data?.user || {});
    };

    useEffect(() => {
        if(id){
            getOneUser(id)
        }
    },[id])

    return (<div className="container-profile-page">
        {(Object.keys(userProfileToShow).length > 0) && <ProfileViewPanel
            user={userProfileToShow}
            caseMentoring={false}
            mentor={""}
            mentee={""}
            style={{width:'100%'}}
        />}
    </div>)
};
export default ProfilePage;