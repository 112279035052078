import { createAction } from "redux-actions";

const CREATE_CRASH_COURSE_USER_PROGRESS = 'CREATE_CRASH_COURSE_USER_PROGRESS';
const GET_CRASH_COURSE_USER_PROGRESS = "GET_CRASH_COURSE_USER_PROGRESS";
const SET_CRASH_COURSE_USER_PROGRESS = "SET_CRASH_COURSE_USER_PROGRESS";
const SET_PROGRESS = "SET_PROGRESS";
const SET_LOADING = "SET_COURSE_LOADING";
const UPDATE_CRASH_COURSE_USER_PROGRESS = "UPDATE_CRASH_COURSE_USER_PROGRESS";

export const constants = {
  CREATE_CRASH_COURSE_USER_PROGRESS,
  GET_CRASH_COURSE_USER_PROGRESS,
  SET_CRASH_COURSE_USER_PROGRESS,
  SET_PROGRESS,
  SET_LOADING,
  UPDATE_CRASH_COURSE_USER_PROGRESS,
};

// ------------------------------------
// Actions
// ------------------------------------
export const createCrashCourseUserProgress = createAction(
  CREATE_CRASH_COURSE_USER_PROGRESS,
  (data) => ({ data })
);
export const updateCrashCourseUserProgress = createAction(
  UPDATE_CRASH_COURSE_USER_PROGRESS,
  (data) => ({ data })
);
export const getCrashCourseUserProgress = createAction(
  GET_CRASH_COURSE_USER_PROGRESS,
  (crashCourseId,callback) => ({ crashCourseId,callback })
);
export const setCrashCourseUserProgress = createAction(
  SET_CRASH_COURSE_USER_PROGRESS,
  (crashCourseProgress) => ({ crashCourseProgress })
);
// export const setProgress = createAction(SET_PROGRESS, (data) => ({ data }));
export const setLoading = createAction(SET_LOADING, (loading) => ({ loading }));

export const actions = {
  createCrashCourseUserProgress,
  updateCrashCourseUserProgress,
  getCrashCourseUserProgress,
  setCrashCourseUserProgress,
  // setProgress,
  setLoading,
};
