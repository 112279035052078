import { createAction } from "redux-actions";

// Action Types
const UPDATE_PANEL_SPEAKERS = "UPDATE_PANEL_SPEAKERS";
const GET_PANEL_SPEAKERS = "GET_PANEL_SPEAKERS";
const GET_USERS_SPEAKERS = "GET_USERS_SPEAKERS";
const REMOVE_USERS_PANEL = "REMOVE_USERS_PANEL";
const UPDATE_USERS_SPEAKERS = "UPDATE_USERS_SPEAKERS";
const ADD_SPEAKER_TO_PANEL = "ADD_SPEAKER_TO_PANEL";
const ADD_PANEL_SPEAKERS = "ADD_PANEL_SPEAKERS";
const UPDATE_PANEL_ADD_SPEAKERS = "UPDATE_PANEL_ADD_SPEAKERS";
const REGISTER_USER_IF_NOT_REGISTER_CONFERENCE_2023 =
  "REGISTER_USER_IF_NOT_REGISTER_CONFERENCE_2023";
const EDIT_PANELS_SPEAKERS = "EDIT_PANELS_SPEAKERS";
const GET_ALL_PANELS_OF_ONE_USER = "GET_ALL_PANELS_OF_ONE_USER";
const GET_ALL_PANELS_OF_ONE_USER_BY_ID = "GET_ALL_PANELS_OF_ONE_USER_BY_ID";
const SET_ALL_PANELS_OF_A_CONFERENCE = "SET_ALL_PANELS_OF_A_CONFERENCE";
const GET_ALL_PANELS_OF_A_CONFERENCE = "GET_ALL_PANELS_OF_A_CONFERENCE";
const UPDATE_ALL_PANELS_OF_ONE_USER = "UPDATE_ALL_PANELS_OF_ONE_USER";
const SET_BUL_REGISTER = "SET_BUL_REGISTER";
const ADDED_TO_MY_PERSONAL_AGENDA = "ADDED_TO_MY_PERSONAL_AGENDA";
const GET_ALL_SPONSORS = "GET_ALL_SPONSORS";
const UPDATE_ALL_SPONSOR_2023 = "UPDATE_ALL_SPONSOR_2023";
const SET_ACTIVE_BUTTON = "SET_ACTIVE_BUTTON";
const GET_ALL_PARRAF_CONFERENCE = "GET_ALL_PARRAF_CONFERENCE";
const UPDATE_PARRAF = "UPDATE_PARRAF";
const GET_SPEAKER_MEMBER = "GET_SPEAKER_MEMBER";
const SET_SPEAKER_MEMBER = "SET_SPEAKER_MEMBER";
const MY_PANELS_USER = "MY_PANELS_USER";
const MY_PANELS_USER_UPDATE = "MY_PANELS_USER_UPDATE";
const FILTERS_PANELS_SPEAKERS = "FILTERS_PANELS_SPEAKERS";
const SET_STATUS_USER_SESSION_LIBRARY = "SET_STATUS_USER_SESSION_LIBRARY";
const GET_STATUS_USER_SESSION_LIBRARY = "GET_STATUS_USER_SESSION_LIBRARY";
const SETT_STATUS_USER_SESSION_LIBRARY = "SETT_STATUS_USER_SESSION_LIBRARY";
const JOIN_SPEAKERS_WAIT_SAGAS = "JOIN_SPEAKERS_WAIT_SAGAS";
const CHANGUE_STATUS = "CHANGUE_STATUS";
const UPDATE_USERS_SPEAKERS_ADMIN = "UPDATE_USERS_SPEAKERS_ADMIN";
const DELETE_PANELS_SPEAKERS = "DELETE_PANELS_SPEAKERS";
const JOIN_EXTERNAL_WAIT_SAGAS = "JOIN_EXTERNAL_WAIT_SAGAS";

export const constants = {
  GET_PANEL_SPEAKERS,
  UPDATE_PANEL_SPEAKERS,
  GET_USERS_SPEAKERS,
  REMOVE_USERS_PANEL,
  UPDATE_USERS_SPEAKERS,
  ADD_PANEL_SPEAKERS,
  UPDATE_PANEL_ADD_SPEAKERS,
  SET_ALL_PANELS_OF_A_CONFERENCE,
  GET_ALL_PANELS_OF_A_CONFERENCE,
  EDIT_PANELS_SPEAKERS,
  ADD_SPEAKER_TO_PANEL,
  REGISTER_USER_IF_NOT_REGISTER_CONFERENCE_2023,
  GET_ALL_PANELS_OF_ONE_USER,
  GET_ALL_PANELS_OF_ONE_USER_BY_ID,
  UPDATE_ALL_PANELS_OF_ONE_USER,
  SET_BUL_REGISTER,
  ADDED_TO_MY_PERSONAL_AGENDA,
  GET_ALL_SPONSORS,
  UPDATE_ALL_SPONSOR_2023,
  SET_ACTIVE_BUTTON,
  GET_ALL_PARRAF_CONFERENCE,
  UPDATE_PARRAF,
  GET_SPEAKER_MEMBER,
  SET_SPEAKER_MEMBER,
  MY_PANELS_USER,
  MY_PANELS_USER_UPDATE,
  FILTERS_PANELS_SPEAKERS,
  SET_STATUS_USER_SESSION_LIBRARY,
  JOIN_SPEAKERS_WAIT_SAGAS,
  GET_STATUS_USER_SESSION_LIBRARY,
  SETT_STATUS_USER_SESSION_LIBRARY,
  CHANGUE_STATUS,
  UPDATE_USERS_SPEAKERS_ADMIN,
  DELETE_PANELS_SPEAKERS,
  JOIN_EXTERNAL_WAIT_SAGAS
};

// ------------------------------------
// Actions
// ------------------------------------

export const addUserSpeakerToPanel = createAction(
  ADD_SPEAKER_TO_PANEL,
  (data, callback) => ({ data, callback })
);

export const joinSpeakersEventWait = createAction(
  JOIN_SPEAKERS_WAIT_SAGAS,
  (
    councilEventPanelId,
    UserId,
    status,
    isAddedByAdmin = false,
    isModerator = false,
    councilEventId,
    callback
  ) => ({
    councilEventPanelId,
    UserId,
    status,
    isAddedByAdmin,
    isModerator,
    councilEventId,
    callback
  })
);

export const joinExternalAwait = createAction(
  JOIN_EXTERNAL_WAIT_SAGAS,
  (
    councilEventPanelId,
    UserId,
    status,
    isAddedByAdmin = false,
    isModerator = false,
    councilEventId,
    prevUser,
    callback
  ) => ({
    councilEventPanelId,
    UserId,
    status,
    isAddedByAdmin,
    isModerator,
    councilEventId,
    prevUser,
    callback
  })
)

export const addPanelSpeakers = createAction(
  ADD_PANEL_SPEAKERS,
  (panels, callback) => ({ panels, callback })
);

export const updatePanelSpeakers = createAction(
  UPDATE_PANEL_SPEAKERS,
  (panelsSpeakers, filters) => ({ panelsSpeakers, filters })
);

export const updatePanelSpeakersAdd = createAction(
  UPDATE_PANEL_ADD_SPEAKERS,
  (panelsSpeakers) => ({ panelsSpeakers })
);

export const editPanelSpeakers = createAction(
  EDIT_PANELS_SPEAKERS,
  (panels, callback) => ({ panels, callback })
);

export const getAllPanelsOfOneUserSpeakers = createAction(
  MY_PANELS_USER,
  (callback) => ({ callback })
);

export const setAllPanelsOfOneGlobalConference = createAction(
  SET_ALL_PANELS_OF_A_CONFERENCE,
  (data) => ({ data })
);

export const getAllPanelsOfOneGlobalConference = createAction(
  GET_ALL_PANELS_OF_A_CONFERENCE,
  (id, isAdmin, callback) => ({ id, isAdmin, callback })
);

export const updateAllPanelsOfOneUserSpeakers = createAction(
  MY_PANELS_USER_UPDATE,
  (userSpeakers) => ({ userSpeakers })
);

export const getAllPanelSpeakers = createAction(
  GET_PANEL_SPEAKERS,
  (type, filters) => ({ type, filters })
);

export const getAllUserSpeaker = createAction(GET_USERS_SPEAKERS, (UserId) => ({
  UserId,
}));

export const updateAllUserSpeakers = createAction(
  UPDATE_USERS_SPEAKERS,
  (userSpeakers) => ({ userSpeakers })
);

export const removeUserSpeakerToPanel = createAction(
  REMOVE_USERS_PANEL,
  (data, callback) => ({ data, callback })
);

export const registerUserIfNotAreRegisterConference2023 = createAction(
  REGISTER_USER_IF_NOT_REGISTER_CONFERENCE_2023,
  (callback) => ({ callback })
);

export const getPanelsForId = createAction(
  GET_ALL_PANELS_OF_ONE_USER_BY_ID,
  (PanelId, callback) => ({ PanelId, callback })
);

export const getAllPanelsOfOneUser = createAction(
  GET_ALL_PANELS_OF_ONE_USER,
  (UserId, callback) => ({ UserId, callback })
);

export const updateAllPanelsOfOneUser = createAction(
  UPDATE_ALL_PANELS_OF_ONE_USER,
  (userSpeakers) => ({ userSpeakers })
);

export const setBulRegister = createAction(SET_BUL_REGISTER, (bul) => ({
  bul,
}));

export const setActiveBoton = createAction(SET_ACTIVE_BUTTON, (bul) => ({
  bul,
}));

export const addedToPersonalAgenda = createAction(
  ADDED_TO_MY_PERSONAL_AGENDA,
  (data, callback) => ({ data, callback })
);

export const getAllSponsors = createAction(GET_ALL_SPONSORS, () => ({}));

export const updateAllSponsors2023 = createAction(
  UPDATE_ALL_SPONSOR_2023,
  (sponsor) => ({ sponsor })
);

export const getAllParafs = createAction(GET_ALL_PARRAF_CONFERENCE, (type) => ({
  type,
}));

export const updateParraf = createAction(UPDATE_PARRAF, (parraf) => ({
  parraf,
}));

export const getAllMemberSpeakerPanel = createAction(
  GET_SPEAKER_MEMBER,
  () => ({})
);

export const setAllMemberSpeakerPanel = createAction(
  SET_SPEAKER_MEMBER,
  (member) => ({ member })
);

export const setStatusUserSessionLibrary = createAction(
  SET_STATUS_USER_SESSION_LIBRARY,
  (data, callback) => ({ data, callback })
);

export const getStatusUserSessionLibrary = createAction(
  GET_STATUS_USER_SESSION_LIBRARY,
  (data, callback) => ({ data, callback })
);

export const settStatusUserSessionLibrary = createAction(
  SETT_STATUS_USER_SESSION_LIBRARY,
  (SpeakerPanelStatus) => ({ SpeakerPanelStatus })
);

export const changeStatus = createAction(
  CHANGUE_STATUS,
  (status, PanelId, callback) => ({ status, PanelId, callback })
);

export const getAllPanelSpeakersAdmin = createAction(
  UPDATE_USERS_SPEAKERS_ADMIN,
  (type) => ({ type })
);

export const deletePanelSpeakers = createAction(
  DELETE_PANELS_SPEAKERS,
  (PanelId, callback) => ({ PanelId, callback })
)

export const actions = {
  setActiveBoton,
  addedToPersonalAgenda,
  joinSpeakersEventWait,
  addPanelSpeakers,
  updatePanelSpeakersAdd,
  editPanelSpeakers,
  getPanelsForId,
  setAllPanelsOfOneGlobalConference,
  getAllPanelsOfOneGlobalConference,
  updatePanelSpeakers,
  getAllPanelSpeakers,
  getAllUserSpeaker,
  removeUserSpeakerToPanel,
  updateAllUserSpeakers,
  addUserSpeakerToPanel,
  registerUserIfNotAreRegisterConference2023,
  getAllPanelsOfOneUser,
  updateAllPanelsOfOneUser,
  setAllMemberSpeakerPanel,
  getAllMemberSpeakerPanel,
  getAllSponsors,
  updateAllSponsors2023,
  getAllParafs,
  updateParraf,
  getAllPanelsOfOneUserSpeakers,
  updateAllPanelsOfOneUserSpeakers,
  setStatusUserSessionLibrary,
  settStatusUserSessionLibrary,
  getStatusUserSessionLibrary,
  changeStatus,
  getAllPanelSpeakersAdmin,
  deletePanelSpeakers,
  joinExternalAwait
};
